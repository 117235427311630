import React from 'react';
import { useProjectPane } from '@/modules/project/hook';
import { PANE_STATUS_REGISTER } from '@/utils/constants/common';
import ProjectRegister from '@/components/project/ProjectRegister';

function ProjectPane() {
  return (
    <div className="d-flex flex-column h-100">
      <ProjectContent />
    </div>
  );
}

function ProjectContent() {
  const { paneStatus } = useProjectPane();

  return <>{paneStatus === PANE_STATUS_REGISTER && <ProjectRegister />}</>;
}

export default ProjectPane;
