import React, { createContext, Dispatch, ReactNode, useReducer } from 'react';

import { PANE_STATUS_REGISTER } from '@/utils/constants/common';
import { Action, ProjectState } from '@/modules/project/types';
import { projectReducer } from '@/modules/project/reducer';

export const ProjectStateContext = createContext<ProjectState | null>(null);
type ProjectDispatch = Dispatch<Action>;
export const ProjectDispatchContext = createContext<ProjectDispatch | null>(
  null
);

const initialState: ProjectState = {
  paneStatus: PANE_STATUS_REGISTER,
  register: {
    step: 1,
    info: {
      projectName: '',
      note: '',
      imgId: '',
      produceEndFlag: true,
    },
    floor: {
      selectedFloorId: '',
      list: [],
    },
  },
  edit: {
    reloadFlag: undefined,
  },
  openLayers: {
    map: null,
    drawSource: null,
    geofencingLayer: null,
    geoImage: {
      layer: null,
      draw: () => {
        //
      },
      drawFromSquare: () => {
        //
      },
      remove: () => {
        //
      },
      adjustment: undefined,
    },
    transform: {
      draw: undefined,
      remove: undefined,
    },
    draw: {
      square: null,
    },
  },
  uploadedAssetList: [],
  selectedBuilding: null,
  selectedFloorPlanId: '',
  buildingInfoList: [],
  floorPlanList: [],
  loading: false,
  afterAssetUpload: false,
};

export function ProjectContextProvider({ children }: { children: ReactNode }) {
  const [project, dispatch] = useReducer(projectReducer, initialState);

  return (
    <ProjectDispatchContext.Provider value={dispatch}>
      <ProjectStateContext.Provider value={project}>
        {children}
      </ProjectStateContext.Provider>
    </ProjectDispatchContext.Provider>
  );
}
