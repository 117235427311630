import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ParamsAlarmLocationRoute } from '@/modules/param/types';
import { fetchProject } from '@/api/project';
import {
  ProjectDetail,
  ProjectSpace,
  ProjectSpaceFloor,
} from '@/modules/project/types';
import MainContainer from '@/components/common/MainContainer';
import LayoutContainer from '@/components/common/LayoutContainer';
import { useTranslation } from 'react-i18next';
import { CommonUtils } from '@/utils';
import Preloader from '@/components/common/Preloader';
import LayoutColumnGroup from '@/components/common/LayoutColumnGroup';
import LayoutColumn from '@/components/common/LayoutColumn';
import OpenLayersMap from '@/components/common/OpenLayersMap';
import Map from 'ol/Map';
import GeofenceOpenLayersMap from '@/components/common/GeofenceOpenLayersMap';
import { useFetchFloorObject, useFloorObject } from '@/modules/floor/hook';
import { useHomeFloor } from '@/modules/home/hook';

function AlarmLocation() {
  const {
    project_id: paramProjectId,
    mapping_id: paramMappingId,
    map_id: paramMapId,
  } = useParams<ParamsAlarmLocationRoute>();
  const { t } = useTranslation();
  const { handleChangeFloor } = useHomeFloor();
  useFetchFloorObject();
  const [project, setProject] = useState<ProjectDetail | null>(null);
  const [building, setBuilding] = useState<ProjectSpace | null>(null);
  const [floor, setFloor] = useState<ProjectSpaceFloor | null>(null);
  const [map, setMap] = useState<Map | null>(null);

  useEffect(() => {
    if (paramProjectId && paramMappingId && paramMapId) {
      handleFetchProject();
    } else {
      window.close();
    }
  }, [paramProjectId, paramMappingId, paramMapId]);

  const handleFetchProject = async () => {
    const project = await fetchProject(paramProjectId);
    if (project) {
      const building = project.buildings.find(
        ({ mappingId }) => mappingId === paramMappingId
      );
      const floor = building?.floors.find(({ mapId }) => mapId === paramMapId);

      if (project && building && floor) {
        setProject(project);
        setBuilding(building);
        handleChangeFloor(floor);
        setFloor(floor);
        return;
      }
    }

    window.close();
  };

  if (!project || !building || !floor) {
    return <Preloader />;
  }

  return (
    <MainContainer>
      <LayoutContainer position={'top'}>
        <div className="navbar">
          <a className="sidebar-brand p-0 d-flex justify-content-center cursor-default">
            <img src="/static/images/logo.svg" className="img-fluid" />
          </a>
          <ul className="location-list">
            <li>{project?.projectName}</li>
            <li>{building?.metaName}</li>
            <li>{floor?.mapName}</li>
            <li>{CommonUtils.convertFloorFormat(floor?.mapFloor || 0)}</li>
          </ul>
          <a
            className="btn btn-accent px-4 ml-3"
            onClick={() => window.close()}
          >
            {t('닫기')}
          </a>
        </div>
      </LayoutContainer>
      <LayoutContainer position={'cover'}>
        <LayoutColumnGroup>
          <LayoutColumn align={'mid'}>
            <OpenLayersMap
              center={[building?.lng, building?.lat]}
              mapId={floor.mapId}
              onInitMap={setMap}
            />
            <GeofenceOpenLayersMap map={map} />
          </LayoutColumn>
          <LayoutColumn align={'right'} show={true}>
            <div className="cd-panel__container">
              <div className="cd-panel__content">
                <div className="container-fluid border-bottom-1 py-4">
                  <div className="d-flex align-items-center">
                    <h3 className="mb-0 text-identity">
                      지게차(220414_1) 이탈
                    </h3>
                  </div>
                </div>
                <div className="container-fluid py-4">
                  <div className="mb-4">
                    <small className="d-flex text-64 mb-2">대상</small>
                    <p className="m-0">지게차(220414_1)</p>
                  </div>
                  <div className="mb-4">
                    <small className="d-flex text-64 mb-2">장소/위치</small>
                    <p className="m-0">Safety Zone 2</p>
                  </div>
                  <div className="mb-4">
                    <small className="d-flex text-64 mb-2">이탈 시간</small>
                    <p className="m-0">2022.04.13, 18:32:23</p>
                  </div>
                  <div className="mb-4">
                    <small className="d-flex text-64 mb-2">
                      현장 알림 시간
                    </small>
                    <p className="m-0">2022-04-13 18:32:24 (1초)</p>
                  </div>
                  <div className="mb-4">
                    <small className="d-flex text-64 mb-2">
                      플랫폼 알림 시간
                    </small>
                    <p className="m-0">2022-04-13 18:32:26 (3초)</p>
                  </div>
                  <div className="mb-4">
                    <small className="d-flex text-64 mb-2">속도</small>
                    <p className="m-0">20 km/h</p>
                  </div>
                  <div className="mb-4">
                    <small className="d-flex text-64 mb-2">주변 인원</small>
                    <p className="m-0">2명</p>
                  </div>
                </div>
              </div>
            </div>
          </LayoutColumn>
        </LayoutColumnGroup>
      </LayoutContainer>
    </MainContainer>
  );
}

export default AlarmLocation;
