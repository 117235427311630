import React, { useEffect, useRef, useState } from 'react';
import { StatLidarVisitStatus } from '@/modules/statistics/types';
import { useTranslation } from 'react-i18next';
import { fetchLidarVisitStatus } from '@/api/statistics';
import { useHomeSelected } from '@/modules/home/hook';
import Flatpickr from 'react-flatpickr';
import { getLanguageCode } from '@/locales/i18n';
import { key as LocaleKey } from 'flatpickr/dist/types/locale';
import moment from 'moment';
import { CommonUtils } from '@/utils';
import VisitStatusChart from '@/components/statistics/StatisticsContent/VisitStatus/Chart';

function VisitStatus() {
  const { t } = useTranslation();
  const { project, building, floor } = useHomeSelected();
  const flatpickrRef = useRef<Flatpickr>(null);
  const todayRef = useRef<string>(moment().format('YYYY-MM-DD'));
  const [period, setPeriod] = useState<string[]>([
    todayRef.current,
    todayRef.current,
  ]);
  const [selectedMapId, setSelectedMapId] = useState(floor?.mapId || '');
  const [visitStatus, setVisitStatus] = useState<StatLidarVisitStatus>({
    inCount: 0,
    outCount: 0,
    overSpeedCount: 0,
    crashCount: 0,
  });

  useEffect(() => {
    if (selectedMapId) {
      handleFetchVisitStatus(selectedMapId);
    }
  }, [selectedMapId, period]);

  const handleFetchVisitStatus = async (mapId: string) => {
    project &&
      building &&
      setVisitStatus(
        await fetchLidarVisitStatus({
          projectId: project.projectId,
          mappingId: building.mappingId,
          mapId,
          searchStartDates: period[0],
          searchEndDates: period[1],
        })
      );
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center mb-4">
        <div className="flex">
          <h3 className="d-flex align-items-center mb-0">{t('방문 현황')}</h3>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-3">
          <div className="form-group mb-0">
            <label className="form-label">{t('기간')}</label>
            <div className="date-picker-cover">
              <Flatpickr
                options={{
                  mode: 'range',
                  altInput: true,
                  altInputClass: 'form-line flatpickr-input',
                  altFormat: 'Y-m-d',
                  locale: getLanguageCode() as LocaleKey,
                }}
                ref={flatpickrRef}
                value={period}
                onChange={(dates) => {
                  if (dates.length === 2) {
                    setPeriod(
                      dates.map((date) => moment(date).format('YYYY-MM-DD'))
                    );
                  }
                }}
                onClose={(dates) => {
                  if (dates.length < 2) {
                    flatpickrRef.current?.flatpickr.setDate(period);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="form-group mb-0">
            <label className="form-label">{t('위치')}</label>
            <select
              name="category"
              className="form-line"
              value={selectedMapId}
              onChange={(e) => setSelectedMapId(e.target.value)}
            >
              {building &&
                building.floors.map(({ mapId, mapFloor }) => (
                  <option key={mapId} value={mapId}>
                    {CommonUtils.convertFloorFormat(mapFloor)}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
      <ul className="list-row column-4 border-bottom-1 mb-4 pb-4">
        <li className="border-right-1">
          <div className="d-flex flex-column align-items-center  py-2">
            <img src="/static/images/icon/ic_enter.svg" className="mb-2" />
            <span className="text-64 mb-2">{t('입장')}</span>
            <h5 className="m-0">{visitStatus.inCount}</h5>
          </div>
        </li>
        <li className="border-right-1">
          <div className="d-flex flex-column align-items-center py-2">
            <img src="/static/images/icon/ic_exit.svg" className="mb-2" />
            <span className="text-64 mb-2">{t('퇴장')}</span>
            <h5 className="m-0">{visitStatus.outCount}</h5>
          </div>
        </li>
        <li className="border-right-1">
          <div className="d-flex flex-column align-items-center py-2">
            <img
              src="/static/images/icon/ic_speed_warning.svg"
              className="mb-2"
            />
            <span className="text-64 mb-2">{t('과속 탐지')}</span>
            <h5 className="m-0">
              <span className="text-identity">
                {visitStatus.overSpeedCount}
              </span>
            </h5>
          </div>
        </li>
        <li>
          <div className="d-flex flex-column align-items-center py-2">
            <img src="/static/images/icon/ic_collision.svg" className="mb-2" />
            <span className="text-64 mb-2">{t('충돌 감지')}</span>
            <h5 className="m-0">
              <span className="text-identity">{visitStatus.crashCount}</span>
            </h5>
          </div>
        </li>
      </ul>
      <VisitStatusChart mapId={selectedMapId} period={period} />
    </>
  );
}

export default VisitStatus;
