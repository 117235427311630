import axios from 'axios';
import { Config } from '@/config';
import { ApiResult, ListResult } from '@/modules/common';
import { StatusCodes } from 'http-status-codes';
import { MessageHistory, MessageHistoryDetail } from '@/modules/message/types';

type RequestFetchMessages = {
  projectId: string;
  parentId?: string;
  searchStartDates?: string;
  searchEndDates?: string;
  targetId?: string;
  parentType?: 'MESSAGE' | 'ACCIDENT' | 'SENSOR' | 'LIDAR_DETECTION';
};

export async function fetchMessages(params: RequestFetchMessages) {
  let messageHistories: MessageHistory[] = [];

  try {
    const response = await axios.get(`${Config.platform_api.uri}/messages`, {
      params: {
        ...params,
        usePaging: false,
      },
    });
    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      messageHistories = result.data as MessageHistory[];
    }
  } catch (e) {}

  return messageHistories;
}

export async function fetchPagingMessages(
  params: RequestFetchMessages & {
    page: number;
    pageSize: number;
  }
) {
  let listResult: ListResult<MessageHistory[]> = {
    content: [],
    totalElements: 0,
  };

  try {
    const response = await axios.get(`${Config.platform_api.uri}/messages`, {
      params: {
        ...params,
        usePaging: true,
      },
    });
    const result: ApiResult<ListResult<MessageHistory[]>> = response.data;
    if (result.status === StatusCodes.OK && result.data) {
      listResult = {
        content: result.data.content,
        totalElements: result.data.totalElements,
      };
    }
  } catch (e) {}

  return listResult;
}

export async function fetchMessage(projectId: string, messageId: string) {
  let messageHistoryDetail: MessageHistoryDetail | null = null;
  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/messages/${messageId}`,
      {
        params: {
          projectId,
        },
      }
    );
    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      messageHistoryDetail = result.data as MessageHistoryDetail;
    }
  } catch (e) {}

  return messageHistoryDetail;
}

type RequestPostMessages = {
  projectId: string;
  parentId: string;
  parentType: 'message' | 'accident';
  messageTitle: string;
  messageContent: string;
  linkUrl?: string;
  imgId: string;
  targetIds: string[];
};

export async function postMessages(data: RequestPostMessages) {
  try {
    const response = await axios.post(
      `${Config.platform_api.uri}/messages`,
      data
    );
    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      return Boolean(result.data);
    }
  } catch (e) {}

  return false;
}
