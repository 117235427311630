import React from 'react';
import { ModalBody, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import EventInfoNav from '@/components/event/EventInfoNav';
import {
  EVENT_INFO_SETTING,
  EVENT_RECEIVE_SETTING,
  EventInput,
} from '@/modules/event/types';
import EventInfoForm from '@/components/event/EventInfoForm';

type EventInfoModalBodyProps = {
  eventInput: EventInput;
  onChangeEventInput: (eventInput: EventInput) => void;
};

function EventInfoModalBody({
  eventInput,
  onChangeEventInput,
}: EventInfoModalBodyProps) {
  return (
    <>
      <TabContainer defaultActiveKey={EVENT_INFO_SETTING}>
        <EventInfoNav />
        <ModalBody>
          <TabContent>
            <TabPane eventKey={EVENT_INFO_SETTING}>
              <EventInfoForm
                eventInput={eventInput}
                onChangeEventInput={onChangeEventInput}
              />
            </TabPane>
            <TabPane eventKey={EVENT_RECEIVE_SETTING}></TabPane>
          </TabContent>
        </ModalBody>
      </TabContainer>
    </>
  );
}

export default EventInfoModalBody;
