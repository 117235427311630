import {
  ASYNC_DEFAULT_STATE,
  COOKIE_KEY_REMEMBER_EMAIL,
  COOKIE_KEY_WHETHER_TO_SIGN_IN,
  COOKIE_MAX_AGE_REMEMBER_EMAIL,
  COOKIE_MAX_AGE_WHETHER_TO_SIGN_IN,
  SESSION_STORAGE_KEY_USER_INFO,
} from '@/utils/constants/common';
import React, { useEffect, useReducer } from 'react';
import { getUser, signInReducer } from '@/modules/access/signin/reducer';
import {
  ACCESS_SIGN_IN_INPUT_CHANGE,
  ACCESS_SIGN_IN_REMEMBER_EMAIL_CHECK_CHANGE,
  ACCESS_SIGN_IN_REMEMBER_EMAIL_SET,
  ACCESS_SIGN_IN_VALIDATION_SET,
  SignInState,
} from '@/modules/access/signin/types';
import { useCookies } from 'react-cookie';
import { ValidUtils } from '@/utils';
import { fetchUserInfo } from '@/api/user';
import { useUser } from '@/modules/user/hook';

export function useSignIn() {
  const initialState: SignInState = {
    inputs: {
      email: '',
      password: '',
    },
    validInputs: {
      email: true,
      password: true,
    },
    remember: false,
    api: ASYNC_DEFAULT_STATE,
  };
  const [state, dispatch] = useReducer(signInReducer, initialState);

  const { email, password } = state.inputs;
  const { email: validEmail, password: validPassword } = state.validInputs;
  const remember = state.remember;
  const { loading, data: user, error } = state.api;
  const { handleSetUser } = useUser();

  const [cookies, setCookie, removeCookie] = useCookies([
    COOKIE_KEY_REMEMBER_EMAIL,
    COOKIE_KEY_WHETHER_TO_SIGN_IN,
  ]);

  useEffect(() => {
    const rememberEmail = cookies[COOKIE_KEY_REMEMBER_EMAIL];
    if (typeof rememberEmail !== 'undefined') {
      dispatch({
        type: ACCESS_SIGN_IN_REMEMBER_EMAIL_SET,
        email: rememberEmail,
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      handleSetCookieEmail();
    }
  }, [user]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const inputs = state.inputs;

    let validEmail = false;
    let validPassword = false;
    if (inputs.email && ValidUtils.validateEmail(inputs.email)) {
      validEmail = true;
    }

    if (inputs.password) {
      validPassword = true;
    }

    dispatch({
      type: ACCESS_SIGN_IN_VALIDATION_SET,
      validEmail,
      validPassword,
    });

    if (validEmail && validPassword) {
      getUser(dispatch, {
        userId: inputs.email,
        password: inputs.password,
      });
    }
  };

  const handleSetCookieEmail = () => {
    if (state.remember) {
      setCookie(COOKIE_KEY_REMEMBER_EMAIL, state.inputs.email, {
        maxAge: COOKIE_MAX_AGE_REMEMBER_EMAIL,
      });
    } else {
      removeCookie(COOKIE_KEY_REMEMBER_EMAIL);
    }

    setCookie(COOKIE_KEY_WHETHER_TO_SIGN_IN, true, {
      maxAge: COOKIE_MAX_AGE_WHETHER_TO_SIGN_IN,
    });
  };

  const handleRememberChange = (checked: boolean) => {
    dispatch({
      type: ACCESS_SIGN_IN_REMEMBER_EMAIL_CHECK_CHANGE,
      checked,
    });
  };

  const handleInputsChange = (key: string, value: string) => {
    dispatch({
      type: ACCESS_SIGN_IN_INPUT_CHANGE,
      key,
      value,
    });
  };

  const handleSignInUserInfoGet = async () => {
    const data = await fetchUserInfo(email);
    const userInfo = {
      refreshToken: user.refreshToken,
      accessToken: user.accessToken,
    };
    sessionStorage.setItem(
      SESSION_STORAGE_KEY_USER_INFO,
      JSON.stringify(userInfo)
    );
    if (data) {
      handleSetUser({
        userName: data.username,
        ...data,
      });
    }
  };

  return {
    email,
    password,
    validEmail,
    validPassword,
    remember,
    loading,
    user,
    error,
    handleSubmit,
    handleInputsChange,
    handleRememberChange,
    handleSignInUserInfoGet,
  };
}
