import React, { useEffect } from 'react';
import MaterialIcon from '@/components/common/MaterialIcon';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormLabel from '@/components/common/FormLabel';
import FormGroup from '@/components/common/FormGroup';
import { Alert } from 'react-bootstrap';
import { useSignIn } from '@/modules/access/signin/hook';

function SignInForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    email,
    password,
    validEmail,
    validPassword,
    remember,
    loading,
    user,
    error,
    handleInputsChange,
    handleRememberChange,
    handleSubmit,
    handleSignInUserInfoGet,
  } = useSignIn();

  useEffect(() => {
    if (user) {
      handleSetStorageItem();
    }
  }, [user]);

  const handleSetStorageItem = async () => {
    await handleSignInUserInfoGet();
    history.replace('/home');
  };

  return (
    <>
      <img src="/static/images/logo.svg" className="id-logo" alt="WATA" />
      <h5 className={location.pathname === '/signin' ? 'mb-5' : 'mb-4'}>
        AI Cloud Spatial Awareness platform
      </h5>
      <form onSubmit={handleSubmit} noValidate={true}>
        <div className="form-group mb-4">
          <FormLabel textKey={'이메일'} htmlFor={'email'} />
          <input
            id="email"
            type="email"
            className="form-line"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputsChange(e.target.id, e.target.value);
            }}
            placeholder={t('이메일을 입력하세요.')}
            autoComplete={'off'}
          />
          {!validEmail && (
            <div className="invalid-feedback">
              {t('이메일을 입력해 주세요.')}
            </div>
          )}
        </div>
        <div className="form-group">
          <FormLabel textKey={'비밀번호'} htmlFor={'password'} />
          <input
            id="password"
            type="password"
            className="form-line"
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputsChange(e.target.id, e.target.value);
            }}
            placeholder={t('비밀번호를 입력하세요.')}
          />
          {!validPassword && (
            <div className="invalid-feedback">
              {t('비밀번호를 입력해 주세요.')}
            </div>
          )}
        </div>
        <FormGroup>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="remember"
              checked={remember}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleRememberChange(e.target.checked);
              }}
            />
            <label className="custom-control-label" htmlFor="remember">
              {t('이메일 기억하기')}
            </label>
          </div>
        </FormGroup>
        {error && (
          <Alert className="alert-soft-accent">
            <div className="d-flex flex-wrap align-items-center">
              <div className="mr-8pt">
                <MaterialIcon name={'error_outline'} />
              </div>
              <div className="flex" style={{ minWidth: '180px' }}>
                <small className="text-black-100">
                  {t('가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.')}
                </small>
              </div>
            </div>
          </Alert>
        )}

        <div className="form-group text-center mb-32pt">
          <button
            className="btn btn-block btn-lg btn-accent"
            disabled={loading}
            type="submit"
          >
            <MaterialIcon name="power_settings_new" align="left" />
            {t('로그인')}
          </button>
          <br />
        </div>
        <ul className="signin-help">
          <li>
            {t('비밀번호를 잊으셨나요?')}
            <Link className="text-body text-underline" to={'/find/password'}>
              {t('비밀번호 찾기')}
            </Link>
            <br />
          </li>
          <li>
            {t('아직 계정이 없으신가요?')}
            <Link className="text-body text-underline" to={'/signup'}>
              {t('계정생성')}
            </Link>
          </li>
        </ul>
      </form>
    </>
  );
}

export default SignInForm;
