import React, { useState } from 'react';
import FormGroup from '@/components/common/FormGroup';
import FormLabel from '@/components/common/FormLabel';
import { useTranslation } from 'react-i18next';
import InvalidAlert from '@/components/InvalidAlert';
import { putMyInfoPassword } from '@/api/user';

type HomeAccountPasswordEditProps = {
  password: string;
  onSuccess: () => void;
};

function HomeAccountPasswordEdit({
  password,
  onSuccess,
}: HomeAccountPasswordEditProps) {
  const { t } = useTranslation();
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [inputs, setInputs] = useState<{
    newPassword: string;
    confirmPassword: string;
  }>({
    newPassword: '',
    confirmPassword: '',
  });

  const handleSubmit = async () => {
    setShowInvalidMessage(false);
    const { newPassword, confirmPassword } = inputs;
    let message = '';
    if (!newPassword || !confirmPassword) {
      message = '비밀번호를 입력해 주세요';
    } else if (newPassword !== confirmPassword) {
      message = '비밀번호가 일치하지 않습니다';
    }

    if (message) {
      setInvalidMessage(message);
      setShowInvalidMessage(true);
      return;
    }

    const result = await putMyInfoPassword({
      password,
      newPassword,
    });

    if (result) {
      onSuccess();
    } else {
      setShowAlertModal(true);
    }
  };

  return (
    <div className="col-md-8 d-flex flex-column p-5 border-left-1">
      <h3 className="mb-5">{t('비밀번호 변경')}</h3>
      <div className="mb-4">
        <h4 className="mb-4">{t('기본정보')}</h4>
        <FormGroup>
          <FormLabel textKey={'새 비밀번호'} essential={true} />
          <input
            type="password"
            className="form-line"
            placeholder={t('새 비밀번호를 입력해주세요')}
            onChange={(e) =>
              setInputs({
                ...inputs,
                newPassword: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <FormLabel textKey={'새 비밀번호 확인'} essential={true} />
          <input
            type="password"
            className="form-line"
            placeholder={t('새 비밀번호를 입력해주세요')}
            onChange={(e) =>
              setInputs({
                ...inputs,
                confirmPassword: e.target.value,
              })
            }
          />
        </FormGroup>
      </div>
      {showInvalidMessage && (
        <InvalidAlert
          messageKey={invalidMessage}
          alertContainerPadding={false}
        />
      )}
      <div className="d-flex">
        <a
          className="btn btn-accent py-3 font-size-14pt w-25"
          onClick={handleSubmit}
        >
          {t('저장')}
        </a>
      </div>
    </div>
  );
}

export default HomeAccountPasswordEdit;
