import React, {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  useReducer,
} from 'react';
import { sensorReducer } from './reducer';
import { SensorState, Action } from './types';

export const SensorStateContext = createContext<SensorState | null>(null);
type SensorDispatch = Dispatch<Action>;
export const SensorDispatchContext = createContext<SensorDispatch | null>(null);

const initialState: SensorState = {
  sensorSearchOption: {
    mapId: '',
    searchKeyword: '',
    sensorClassificationTypeList: [],
    // Todo: 페이징 기능 개발 이후 진행
    // pageNumber: 1,
    // pagesize: 8,
  },
  pagedSensorList: {
    status: 0,
    sensorList: [],
    totCnt: 0,
    totPageCnt: 0,
    pagesize: 0,
    nowpage: 1,
    cel_hum_cnt: 0,
    environment_cnt: 0,
    ble_cnt: 0,
  },
  selectedSensor: '',
};

export function SensorContextProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [sensor, dispatch] = useReducer(sensorReducer, initialState);

  return (
    <SensorDispatchContext.Provider value={dispatch}>
      <SensorStateContext.Provider value={sensor}>
        {children}
      </SensorStateContext.Provider>
    </SensorDispatchContext.Provider>
  );
}
