import {
  Action,
  MONITORING_CHANGE_DIRECT_FLAG,
  MONITORING_CHANGE_MONITORING_TYPE,
  MONITORING_INIT_SELECTED_GEOFENCE_FEATURE_LIST,
  MONITORING_INIT_SELECTED_LIDAR_OBJECT_LIST,
  MONITORING_REMOVE_SELECTED_LIDAR_OBJECT,
  MONITORING_SET_APP_END_POINT_USER_DATA,
  MONITORING_SET_LIDAR_OBJECT_LIST,
  MONITORING_SET_SELECTED_GEOFENCE_FEATURE,
  MONITORING_SET_SELECTED_GEOFENCE_FEATURE_LIST,
  MONITORING_SET_SELECTED_LIDAR_OBJECT,
  MonitoringLidar,
  MonitoringState,
} from '@/modules/monitoring/types';
import { Feature } from 'ol';

export function monitoringReducer(state: MonitoringState, action: Action) {
  switch (action.type) {
    case MONITORING_CHANGE_DIRECT_FLAG:
      return {
        ...state,
        directFlag: action.directFlag,
      };
    case MONITORING_CHANGE_MONITORING_TYPE:
      return {
        ...state,
        monitoringType: action.monitoringType,
      };
    case MONITORING_SET_APP_END_POINT_USER_DATA:
      return {
        ...state,
        appEndPointUserList: action.appEndPointUserList,
        appEndPointUserAccumulateNumber: action.accumulateNumber,
      };
    case MONITORING_SET_LIDAR_OBJECT_LIST:
      return {
        ...state,
        lidarObjectList: action.lidarObjectList,
      };
    case MONITORING_SET_SELECTED_GEOFENCE_FEATURE:
      const selectedGeofenceFeature = action.selectedGeofenceFeature;
      const id = selectedGeofenceFeature.getId();
      const selectedGeofenceFeatureList = state.selectedGeofenceFeatureList;
      const isExist = selectedGeofenceFeatureList.find((storeFeature) => {
        if (storeFeature) {
          return storeFeature.getId() === id;
        }
      });

      let featureList: Feature[] = [];
      if (!isExist) {
        featureList = [...selectedGeofenceFeatureList, selectedGeofenceFeature];
      } else {
        featureList = selectedGeofenceFeatureList.filter((storeFeature) => {
          if (storeFeature) {
            return storeFeature.getId() !== id;
          }
        });
      }

      return {
        ...state,
        selectedGeofenceFeatureList: featureList,
      };
    case MONITORING_SET_SELECTED_GEOFENCE_FEATURE_LIST:
      return {
        ...state,
        selectedGeofenceFeatureList: action.selectedGeofenceFeatureList,
      };
    case MONITORING_INIT_SELECTED_GEOFENCE_FEATURE_LIST:
      return {
        ...state,
        selectedGeofenceFeatureList: [],
      };
    case MONITORING_SET_SELECTED_LIDAR_OBJECT:
      const lidarObject = action.lidarObject;
      const serialId = lidarObject.serialId;
      const selectedLidarObjectList = state.selectedLidarObjectList;

      let lidarObjectList: MonitoringLidar[] = [];
      const isExistLidar = selectedLidarObjectList.find(
        (storeLidarObject) => storeLidarObject.serialId === serialId
      );
      if (!isExistLidar) {
        lidarObjectList = [...selectedLidarObjectList, lidarObject];
      } else {
        lidarObjectList = selectedLidarObjectList.filter(
          (storeLidarObject) => storeLidarObject.serialId !== serialId
        );
      }

      if (lidarObjectList.length > 2) {
        lidarObjectList = [lidarObject];
      }

      return {
        ...state,
        selectedLidarObjectList: lidarObjectList,
      };
    case MONITORING_REMOVE_SELECTED_LIDAR_OBJECT:
      return {
        ...state,
        selectedLidarObjectList: state.selectedLidarObjectList.filter(
          (storeLidarObject) =>
            storeLidarObject.serialId !== action.lidarObject.serialId
        ),
      };
    case MONITORING_INIT_SELECTED_LIDAR_OBJECT_LIST:
      return {
        ...state,
        selectedLidarObjectList: [],
      };
  }
}
