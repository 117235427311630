import React from 'react';
import { CommonUtils } from '@/utils';
import MonitoringGeoObjectList from '@/components/monitoring/MonitoringGeoObjectList';
import { useHomeSelected } from '@/modules/home/hook';
import LeftPaneBreadcrumb from '@/components/common/LeftPaneBreadcrumb';
import { useMonitoring } from '@/modules/monitoring/hook';
import { MONITORING_TYPE_APP } from '@/modules/monitoring/types';
import MonitoringAppEndPointUserList from '@/components/monitoring/MonitoringAppEndPointUserList';

function MonitoringLeftPane() {
  const { project, building, floor } = useHomeSelected();
  const { monitoringType } = useMonitoring();

  if (!project || !building || !floor) {
    return <></>;
  }

  return (
    <>
      <div className="container-fluid border-bottom-1 py-4">
        <LeftPaneBreadcrumb showFloor={false} />
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <h3 className="mb-0">{floor.mapName}</h3>
            <span className="text-64">
              {CommonUtils.convertFloorFormat(floor.mapFloor)}
            </span>
          </div>
          {/*
          <a className="btn-add-item text-identity ml-auto mr-n3">
            <span className="material-icons">edit</span>
            수정
          </a>
          */}
        </div>
      </div>
      {monitoringType === MONITORING_TYPE_APP ? (
        <MonitoringAppEndPointUserList />
      ) : (
        <MonitoringGeoObjectList />
      )}
    </>
  );
}

export default MonitoringLeftPane;
