import { fetchCategoryList } from '@/api/assets';
import {
  AssetCategoryType,
  AssetExcelType,
  SelectOptionType,
} from '@/modules/assets/types';
import {
  useSelectedFloorPlanId,
  useUploadAssetListExcel,
} from '@/modules/project/hook';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AssetExcelComboBox from './AssetExcelComboBox';
import ExcelUploadTextInput from './ExcelUploadTextInput';

type ExcelUploadRowProps = {
  uploadedAsset: AssetExcelType;
};

function ExcelUploadRow({ uploadedAsset }: ExcelUploadRowProps): ReactElement {
  const [typeList, setTypeList] = useState<SelectOptionType[]>([]);
  const [classificationList, setClassificationList] = useState<
    SelectOptionType[]
  >([]);
  const { t } = useTranslation();

  const { selectedFloorPlanId } = useSelectedFloorPlanId();
  const { uploadedAssetList, setUploadedAssetList } = useUploadAssetListExcel();

  // 자산 유형 조회
  const handleFetchTypeList = async () => {
    const result = await fetchCategoryList();
    result &&
      setTypeList(
        result.map((resultItem) => {
          return { id: resultItem.categoryId, name: resultItem.categoryName };
        })
      );
  };

  // 자산 분류 조회
  const handleFetchClassificationList = async (id: string) => {
    const result = await fetchCategoryList();
    result &&
      setClassificationList(
        result
          .filter((resultItem) => resultItem.categoryId === id)[0]
          .subCategories.map((subCategory) => ({
            id: subCategory.categoryId,
            name: subCategory.categoryName,
          }))
      );
  };

  useEffect(() => {
    handleFetchTypeList();
  }, [selectedFloorPlanId]);

  useEffect(() => {
    const categoryId = uploadedAsset.categories.filter(
      (category) => category.depth === 1
    )[0].categoryId;

    if (categoryId) {
      handleFetchClassificationList(categoryId);
    } else {
      const categories: AssetCategoryType[] = uploadedAsset.categories.map(
        (category) =>
          category.depth === 2
            ? {
                ...category,
                categoryId: '',
              }
            : category
      );

      setUploadedAssetList(
        uploadedAssetList.map((assetListItem) =>
          assetListItem.id === uploadedAsset.id
            ? { ...assetListItem, categories }
            : assetListItem
        )
      );
    }
  }, [
    uploadedAsset.categories.filter((category) => category.depth === 1)[0]
      .categoryId,
  ]);

  // 특정 열 삭제
  const deleteRow = () => {
    setUploadedAssetList(
      uploadedAssetList
        .filter((assetListItem) => assetListItem.id !== uploadedAsset.id)
        .map((assetListItem, index) => ({ ...assetListItem, field: index }))
    );
  };

  // 자산 유형 선택 시
  const onChangeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { categories } = uploadedAsset;

    const categoryList: AssetCategoryType[] = categories.map((category) =>
      category.depth === 1
        ? {
            ...category,
            categoryId: e.target.value,
          }
        : { ...category, categoryId: '' }
    );

    setUploadedAssetList(
      uploadedAssetList.map((assetListItem) =>
        assetListItem.id === uploadedAsset.id
          ? {
              ...assetListItem,
              categories: categoryList,
              assetTypeErrorMessage: e.target.value
                ? ''
                : assetListItem.assetTypeErrorMessage,
            }
          : assetListItem
      )
    );
  };

  // 자산 분류 선택 시
  const onChangeClassification = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const categoryList: AssetCategoryType[] = uploadedAsset.categories.map(
      (category) =>
        category.depth === 2
          ? {
              ...category,
              categoryId: e.target.value,
            }
          : category
    );

    setUploadedAssetList(
      uploadedAssetList.map((assetListItem) =>
        assetListItem.id === uploadedAsset.id
          ? {
              ...assetListItem,
              categories: categoryList,
              assetClassificationErrorMessage: e.target.value
                ? ''
                : assetListItem.assetClassificationErrorMessage,
            }
          : assetListItem
      )
    );
  };

  // 자산명 입력 시
  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadedAssetList(
      uploadedAssetList.map((assetListItem) =>
        assetListItem.id === uploadedAsset.id
          ? {
              ...assetListItem,
              assetInfoName: e.target.value,
              assetNameErrorMessage: e.target.value
                ? ''
                : assetListItem.assetNameErrorMessage,
            }
          : assetListItem
      )
    );
  };

  // 식별값 입력 시
  const onChangeSerialId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadedAssetList(
      uploadedAssetList.map((assetListItem) =>
        assetListItem.id === uploadedAsset.id
          ? {
              ...assetListItem,
              serialId: e.target.value,
              serialIdErrorMessage: e.target.value
                ? ''
                : assetListItem.serialIdErrorMessage,
            }
          : assetListItem
      )
    );
  };

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center justify-content-center h-100">
          <a className="text-dark" onClick={deleteRow}>
            <span className="material-icons">remove_circle</span>
          </a>
        </div>
      </td>
      <AssetExcelComboBox
        defaultValue={
          uploadedAsset.categories.filter((category) => category.depth === 1)[0]
            .categoryId
        }
        emptyValueText={t('자산 유형을 선택하세요.')}
        onChange={onChangeType}
        errorMessage={uploadedAsset.assetTypeErrorMessage}
        optionList={typeList}
      />
      <AssetExcelComboBox
        defaultValue={
          uploadedAsset.categories.filter((category) => category.depth === 1)[0]
            .categoryId
            ? uploadedAsset.categories.filter(
                (category) => category.depth === 2
              )[0].categoryId
            : ''
        }
        emptyValueText={t('분류를 선택하세요')}
        onChange={onChangeClassification}
        errorMessage={uploadedAsset.assetClassificationErrorMessage}
        optionList={classificationList}
      />
      <ExcelUploadTextInput
        value={uploadedAsset.assetInfoName}
        onChange={onChangeName}
        errorMessage={uploadedAsset.assetNameErrorMessage}
      />
      <ExcelUploadTextInput
        value={uploadedAsset.serialId}
        onChange={onChangeSerialId}
        errorMessage={uploadedAsset.serialIdErrorMessage}
      />
    </tr>
  );
}

export default ExcelUploadRow;
