import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionCollapse,
  AccordionToggle,
  Nav,
  TabContainer,
  TabContent,
  TabPane,
} from 'react-bootstrap';
import classNames from 'classnames';
import {
  STAT_RANK_STAY,
  STAT_RANK_VISIT,
  StatAppRankStatus,
  StatAppVisitStatus,
} from '@/modules/statistics/types';
import { fetchAppRankStatus, fetchAppVisitStatus } from '@/api/statistics';
import {
  useHomeBuilding,
  useHomeFloor,
  useHomeProject,
} from '@/modules/home/hook';
import moment from 'moment';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TableEmptyRow from '@/components/common/TableEmptyRow';
import MonitoringAppEndPointUserRealtimeStatus from '@/components/monitoring/MonitoringAppEndPointUserRealtimeStatus';
import { MonitoringRightChildPaneProps } from '@/components/monitoring/MonitoringRightPane';

export type MonitoringAppRightPaneProps = MonitoringRightChildPaneProps;

function MonitoringAppRightPane({
  objectFeatureFilterList,
}: MonitoringAppRightPaneProps) {
  return (
    <>
      <MonitoringAppEndPointUserRealtimeStatus
        objectFeatureFilterList={objectFeatureFilterList}
      />
      <Accordion className="js-accordion m-0">
        <VisitStatus />
        <RankStatus />
      </Accordion>
    </>
  );
}

type VisitSearchCondition = 'today' | 'yesterday' | 'before yesterday';

function VisitStatus() {
  const { t } = useTranslation();
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const [isOpen, setOpen] = useState(true);
  const [visitStatusList, setVisitStatusList] = useState<StatAppVisitStatus[]>(
    []
  );
  const [searchCondition, setSearchCondition] = useState<VisitSearchCondition>(
    'today'
  );

  useEffect(() => {
    handleFetchVisitStatus();
  }, [searchCondition, floor]);

  const handleFetchVisitStatus = async () => {
    const [searchStartDates, searchEndDates] = calcSearchDates();
    project &&
      building &&
      floor &&
      setVisitStatusList(
        await fetchAppVisitStatus({
          projectId: project.projectId,
          mappingId: building.mappingId,
          mapId: floor.mapId,
          searchStartDates,
          searchEndDates,
        })
      );
  };

  const calcSearchDates = () => {
    const today = moment();
    let calcStartDate = today;
    let calcEndDate = today;
    if (searchCondition === 'yesterday') {
      calcStartDate = calcEndDate = today.add(-1, 'days');
    } else if (searchCondition === 'before yesterday') {
      calcStartDate = calcEndDate = today.add(-2, 'days');
    }

    return [
      calcStartDate.format('YYYY-MM-DD'),
      calcEndDate.format('YYYY-MM-DD'),
    ];
  };

  return (
    <>
      <div
        className={classNames('accordion__item', {
          open: isOpen,
        })}
      >
        <div className="d-flex align-items-center pl-4 py-2">
          <h4 className="m-0">{t('방문 현황')}</h4>
          <div className="d-flex align-items-center ml-auto">
            <div className="swipe-wrap">
              <ul className="swipe-list">
                <li>
                  <a
                    className={classNames({
                      active: searchCondition === 'today',
                    })}
                    onClick={() => setSearchCondition('today')}
                  >
                    {t('오늘')}
                  </a>
                </li>
                <li>
                  <a
                    className={classNames({
                      active: searchCondition === 'yesterday',
                    })}
                    onClick={() => setSearchCondition('yesterday')}
                  >
                    {t('어제')}
                  </a>
                </li>
                <li>
                  <a
                    className={classNames({
                      active: searchCondition === 'before yesterday',
                    })}
                    onClick={() => setSearchCondition('before yesterday')}
                  >
                    {t('그제')}
                  </a>
                </li>
              </ul>
            </div>
            <AccordionToggle
              eventKey={'VISIT'}
              as={'a'}
              className="accordion__toggle"
              onClick={() => setOpen(!isOpen)}
            >
              <span className="accordion__toggle-icon material-icons text-64">
                keyboard_arrow_down
              </span>
            </AccordionToggle>
          </div>
        </div>
        <AccordionCollapse
          in={isOpen}
          eventKey={'VISIT'}
          className="accordion__menu"
        >
          <div className="accordion__menu-link">
            <div className="d-flex align-items-center justify-content-center w-100">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'line',
                    width: 320,
                  },
                  title: null,
                  credits: {
                    enabled: false,
                  },
                  plotOptions: {
                    series: {
                      animation: false,
                    },
                    line: {
                      marker: {
                        enabled: false,
                      },
                    },
                  },
                  xAxis: {
                    categories: visitStatusList.map(({ hour }) => hour),
                  },
                  yAxis: {
                    minRange: 1,
                    min: 0,
                    title: null,
                  },
                  tooltip: {
                    pointFormat: '{series.name}: <b>{point.y}</b>',
                  },
                  legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'top',
                    floating: false,
                  },
                  series: [
                    {
                      name: t('오늘 방문'),
                      data: visitStatusList.map(({ todayCount }) => todayCount),
                      color: '#2E80DF',
                    },
                    {
                      name: t('평균'),
                      data: visitStatusList.map(
                        ({ countAverage }) => countAverage
                      ),
                      color: '#D6E597',
                    },
                  ],
                }}
              />
            </div>
          </div>
        </AccordionCollapse>
      </div>
    </>
  );
}

function RankStatus() {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(true);

  return (
    <>
      <div
        className={classNames('accordion__item', {
          open: isOpen,
        })}
      >
        <div className="d-flex align-items-center pl-4 py-2">
          <h4 className="m-0">{t('Top3')}</h4>
          <div className="d-flex align-items-center ml-auto">
            <AccordionToggle
              eventKey={'VISIT'}
              as={'a'}
              className="accordion__toggle"
              onClick={() => setOpen(!isOpen)}
            >
              <span className="accordion__toggle-icon material-icons text-64">
                keyboard_arrow_down
              </span>
            </AccordionToggle>
          </div>
        </div>
        <AccordionCollapse
          in={isOpen}
          eventKey={'VISIT'}
          className="accordion__menu"
        >
          <div className="accordion__menu-link">
            <div className="d-flex flex-column w-100">
              <TabContainer defaultActiveKey={STAT_RANK_VISIT}>
                <Nav className="tab-menu the-num02 w-100 mb-4">
                  <Nav.Link
                    eventKey={STAT_RANK_VISIT}
                    className={'btn-style_underline'}
                  >
                    <span className="menu-cover">
                      <span>{t('방문 Top')}</span>
                    </span>
                  </Nav.Link>
                  <Nav.Link
                    eventKey={STAT_RANK_STAY}
                    className={'btn-style_underline'}
                  >
                    <span className="menu-cover">
                      <span>{t('체류 Top')}</span>
                    </span>
                  </Nav.Link>
                </Nav>
                <TabContent>
                  <TabPane eventKey={STAT_RANK_VISIT}>
                    <RankVisit />
                  </TabPane>
                  <TabPane eventKey={STAT_RANK_STAY}>
                    <RankStay />
                  </TabPane>
                </TabContent>
              </TabContainer>
            </div>
          </div>
        </AccordionCollapse>
      </div>
    </>
  );
}

function RankVisit() {
  const { t } = useTranslation();
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const [rankList, setRankList] = useState<StatAppRankStatus[]>([]);

  useEffect(() => {
    handleFetchRank();
  }, [floor]);

  const handleFetchRank = async () => {
    project &&
      building &&
      floor &&
      setRankList(
        await fetchAppRankStatus('visits', {
          projectId: project.projectId,
          mappingId: building.mappingId,
          mapId: floor.mapId,
        })
      );
  };

  return (
    <table className="table table-head-light table-high border-bottom-0 mb-0">
      <thead>
        <tr>
          <th>{t('순위')}</th>
          <th>{t('지오펜스')}</th>
          <th>{t('방문고객(명)')}</th>
        </tr>
      </thead>
      <tbody>
        {!rankList.length && (
          <TableEmptyRow colSpan={3} textKey={'데이터가 없습니다.'} />
        )}
        {rankList.map(({ areaName, totalCount }, i) => (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{areaName}</td>
            <td>{totalCount}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function RankStay() {
  const { t } = useTranslation();
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const [rankList, setRankList] = useState<StatAppRankStatus[]>([]);

  useEffect(() => {
    handleFetchRank();
  }, [floor]);

  const handleFetchRank = async () => {
    project &&
      building &&
      floor &&
      setRankList(
        await fetchAppRankStatus('residences', {
          projectId: project.projectId,
          mappingId: building.mappingId,
          mapId: floor.mapId,
        })
      );
  };

  return (
    <table className="table table-head-light table-high border-bottom-0 mb-0">
      <thead>
        <tr>
          <th>{t('순위')}</th>
          <th>{t('지오펜스')}</th>
          <th>{t('평균 체류 시간(분)')}</th>
        </tr>
      </thead>
      <tbody>
        {!rankList.length && (
          <TableEmptyRow colSpan={3} textKey={'데이터가 없습니다.'} />
        )}
        {rankList.map(({ areaName, residenceAverage }, i) => (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{areaName}</td>
            <td>{moment.utc(residenceAverage * 1000).format('mm:ss')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default React.memo(MonitoringAppRightPane);
