import React from 'react';
import { useInfoPane } from '@/modules/setup/hook';
import {
  INFO_PANE_ACCIDENT_REGISTER,
  INFO_PANE_CAMPAIGN_REGISTER,
  INFO_PANE_LIDAR_LINK,
} from '@/modules/setup/types';
import CampaignRegisterInfoPane from '@/components/campaign/CampaignRegisterInfoPane';
import AccidentRegisterInfoPane from '@/components/accident/AccidentRegisterInfoPane';
import MonitoringLinkInfoPane from '@/components/monitoring/MonitoringLinkInfoPane';

function InfoPane() {
  const { show, view } = useInfoPane();

  if (!show) {
    return <></>;
  }

  return (
    <div className="info-holder">
      <div className="info-panel">
        {view === INFO_PANE_CAMPAIGN_REGISTER && <CampaignRegisterInfoPane />}
        {view === INFO_PANE_ACCIDENT_REGISTER && <AccidentRegisterInfoPane />}
        {view === INFO_PANE_LIDAR_LINK && <MonitoringLinkInfoPane />}
      </div>
    </div>
  );
}

export default InfoPane;
