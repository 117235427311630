import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from '@/components/common/FormGroup';
import FormLabel from '@/components/common/FormLabel';
import { useUser } from '@/modules/user/hook';
import InvalidAlert from '@/components/InvalidAlert';
import { fetchUserInfoMySelf, putMyInfo } from '@/api/user';
import AlertModal from '@/components/common/modal/AlertModal';

type HomeAccountProfileEditProps = {
  onChangeViewType: () => void;
};

function HomeAccountProfileEdit({
  onChangeViewType,
}: HomeAccountProfileEditProps) {
  const { t } = useTranslation();
  const {
    user: { userId, userName, companyName, department },
    handleSetUser,
  } = useUser();
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [inputs, setInputs] = useState<{
    userName: string;
    companyName: string;
    department: string;
  }>({
    userName,
    companyName,
    department,
  });

  const handleSubmit = async () => {
    setShowInvalidMessage(false);
    if (!inputs.userName) {
      setShowInvalidMessage(true);
      return;
    }

    const result = await putMyInfo({
      userName: inputs.userName,
      companyName: inputs.companyName,
      department: inputs.department,
    });

    if (result) {
      const userInfo = await fetchUserInfoMySelf();
      if (userInfo) {
        handleSetUser({
          userName: userInfo.username,
          ...userInfo,
        });
      }
      onChangeViewType();
    } else {
      setShowAlertModal(true);
    }
  };

  return (
    <>
      <div className="mb-4">
        <h4 className="mb-4">{t('기본정보')}</h4>
        <FormGroup>
          <FormLabel textKey={'이메일'} />
          <input
            type="text"
            className="form-line"
            value={userId}
            disabled={true}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel textKey={'이름'} />
          <input
            type="text"
            className="form-line"
            value={inputs.userName}
            onChange={(e) =>
              setInputs({
                ...inputs,
                userName: e.target.value,
              })
            }
          />
        </FormGroup>
      </div>
      <div className="mb-4">
        <h5 className="mb-4">{t('소속')}</h5>
        <FormGroup>
          <FormLabel textKey={'회사'} />
          <input
            type="text"
            className="form-line"
            value={inputs.companyName}
            onChange={(e) =>
              setInputs({
                ...inputs,
                companyName: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <FormLabel textKey={'부서'} />
          <input
            type="text"
            className="form-line"
            value={inputs.department}
            onChange={(e) =>
              setInputs({
                ...inputs,
                department: e.target.value,
              })
            }
          />
        </FormGroup>
      </div>
      {showInvalidMessage && (
        <InvalidAlert
          messageKey={'이름을 입력해 주세요'}
          alertContainerPadding={false}
        />
      )}
      <div className="d-flex">
        <a
          className="btn btn-light py-3 font-size-14pt w-15"
          onClick={onChangeViewType}
        >
          {t('취소')}
        </a>
        <a
          className="btn btn-accent py-3 font-size-14pt w-15 ml-3"
          onClick={handleSubmit}
        >
          {t('저장')}
        </a>
      </div>
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {t('서버와 통신 중 오류가 발생했습니다')}
      </AlertModal>
    </>
  );
}

export default HomeAccountProfileEdit;
