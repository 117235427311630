import React from 'react';
import { ValidUtils } from '@/utils';

type ProfileAvatarProps = {
  profileImgUrl: string;
  userName: string;
};

function ProfileAvatar({ profileImgUrl, userName }: ProfileAvatarProps) {
  return (
    <>
      {!profileImgUrl && (
        <span className="avatar-title rounded-circle">
          {userName
            ? ValidUtils.validateHangul(userName || '')
              ? userName.length > 0
                ? userName.substring(0, 1)
                : ''
              : userName.length > 1
              ? userName.substring(0, 1)
              : ''
            : '-'}
        </span>
      )}
      {profileImgUrl && (
        <img src={profileImgUrl} className="avatar-img rounded-circle" />
      )}
    </>
  );
}

export default ProfileAvatar;
