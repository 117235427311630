import React from 'react';
import { MonitoringGeoObjectGeofence } from '@/modules/monitoring/types';
import { useMonitoringGeofence } from '@/modules/monitoring/hook';
import classNames from 'classnames';
import { FLOOR_OBJECT_TYPE_GEOFENCE } from '@/modules/floor/types';
import { WKT } from 'ol/format';

type MonitoringGeoObjectGeofenceItemProps = {
  geoObjectGeofence: MonitoringGeoObjectGeofence;
};

function MonitoringGeoObjectGeofenceItem({
  geoObjectGeofence: { zone_id, zone_name, geomstr },
}: MonitoringGeoObjectGeofenceItemProps) {
  const {
    selectedGeofenceFeatureList,
    handleSetGeofenceFeature,
  } = useMonitoringGeofence();

  const handleClickGeofence = () => {
    const feature = new WKT().readFeature(geomstr);
    feature.setId(zone_id);
    feature.set('name', zone_name);
    feature.set('type', FLOOR_OBJECT_TYPE_GEOFENCE);
    handleSetGeofenceFeature(feature);
  };

  return (
    <tr
      className={classNames({
        selected: selectedGeofenceFeatureList.find(
          (selectedGeofenceFeature) =>
            selectedGeofenceFeature.getId() === zone_id
        ),
      })}
      onClick={handleClickGeofence}
    >
      <td className="text-center">
        <img src={'/static/images/poi/object_geofence.svg'} />
      </td>
      <td>{zone_name}</td>
      <td></td>
    </tr>
  );
}

export default MonitoringGeoObjectGeofenceItem;
