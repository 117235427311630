import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useDropdown } from '@/modules/common';
import {
  FUNCTION_DELETE,
  LOCAL_STORAGE_KEY_PROJECT_ID,
} from '@/utils/constants/common';
import MaterialIcon from '@/components/common/MaterialIcon';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import ListSearch from '@/components/ListSearch';
import Toastr from 'toastr';
import { GroupManagementView } from '@/components/GroupManagement';
import {
  deleteGroupDeleteProject,
  deleteInviteUserDeleteGroup,
  fetchGroup,
  fetchGroupAddableInviteUsers,
  fetchGroupInviteUsers,
  fetchGroupProjects,
  putGroupProjectRoleType,
} from '@/api/group';
import {
  Group,
  GroupChangeRoleProject,
  GroupDetail,
  GroupProject,
  GroupProjectRoleType,
  InviteUserDetail,
} from '@/modules/group/types';
import { CommonUtils } from '@/utils';
import GroupManagementAddUserModal from '@/components/GroupManagementAddUserModal';
import ProfileAvatar from '@/components/common/ProfileAvatar';
import moment from 'moment';
import NoticeContainerModal from '@/components/NoticeContainerModal';
import GroupManagementAddProjectModal from '@/components/GroupManagementAddProjectModal';
import { useHomeData, useHomeMenu } from '@/modules/home/hook';
import { MENU_IDX_GROUP_SETTINGS_USER } from '@/modules/setup/types';
import Pagination from '@/components/common/Pagination';

type GroupManagementDetailProps = {
  groupSeq: number;
  reloadFlag: boolean;
  onClickBack: (view: GroupManagementView) => void;
  onClickDelete: (group: Group) => void;
};

function GroupManagementDetail({
  groupSeq,
  reloadFlag,
  onClickBack,
  onClickDelete,
}: GroupManagementDetailProps) {
  const { t } = useTranslation();
  const dropdown = useRef<HTMLDivElement>(null);
  const { handleToggle } = useDropdown(dropdown);
  const [userListReloadFlag, setUserListReloadFlag] = useState(false);
  const [projectListReloadFlag, setProjectListReloadFlag] = useState(false);
  const [
    addableProjectListReloadFlag,
    setAddableProjectListReloadFlag,
  ] = useState(false);
  const [showGroupUserModal, setShowGroupUserModal] = useState(false);
  const [showGroupProjectModal, setShowGroupProjectModal] = useState(false);
  const [groupDetail, setGroupDetail] = useState<GroupDetail>(
    {} as GroupDetail
  );
  const [addableInviteUserList, setAddableInviteUserList] = useState<
    InviteUserDetail[]
  >([]);

  const handleFetchGroup = async () => {
    const result = await fetchGroup(groupSeq);
    if (result) {
      setGroupDetail(result);
    } else {
      onClickBack('list');
    }
  };

  const handleFetchAddableInviteUserList = async () => {
    const result = await fetchGroupAddableInviteUsers(groupSeq);
    setAddableInviteUserList(result);
  };

  useEffect(() => {
    handleReloadGroup();
  }, [groupSeq, reloadFlag]);

  const handleClickBack = () => {
    onClickBack('list');
  };

  const handleOptions = (eventKey: string | null) => {
    switch (eventKey) {
      case FUNCTION_DELETE:
        onClickDelete(groupDetail);
        break;
    }
  };

  const handleShowGroupUserModal = () => {
    setShowGroupUserModal(true);
  };

  const handleHideGroupUserModal = () => {
    setShowGroupUserModal(false);
  };

  const handleShowGroupProjectModal = () => {
    setShowGroupProjectModal(true);
  };

  const handleHideGroupProjectModal = () => {
    setShowGroupProjectModal(false);
  };

  const handleReloadGroup = () => {
    handleFetchGroup();
    handleFetchAddableInviteUserList();
    setAddableProjectListReloadFlag(!addableProjectListReloadFlag);
  };

  if (CommonUtils.isEmptyObject(groupDetail)) {
    return <></>;
  }

  return (
    <>
      <div className="col-md-4">
        <div className="cell">
          <div className="d-flex align-items-center mb-5">
            <a className="circle-pin pr-2" onClick={handleClickBack}>
              <MaterialIcon name={'arrow_back'} />
            </a>
            <Dropdown
              onToggle={handleToggle}
              onSelect={handleOptions}
              className={'ml-auto'}
            >
              <Dropdown.Toggle
                as={'a'}
                data-caret="false"
                className="text-muted"
              >
                <MaterialIcon name={'more_vert'} />
              </Dropdown.Toggle>
              <Dropdown.Menu align={'right'} ref={dropdown}>
                <Dropdown.Item
                  className="text-danger"
                  eventKey={FUNCTION_DELETE}
                >
                  {t('그룹 삭제')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="group-title d-flex mb-5">
            <div className="flex">
              <h2 className="font-weight-light mb-2">
                {groupDetail.groupName}
              </h2>
              <p className="font-size-16pt text-50 mb-0">
                {groupDetail.groupDesc}
              </p>
            </div>
          </div>
          <div className="group-state mb-5">
            <ul className="col-half">
              <li>
                <p>{t('그룹 구성원')}</p>
                <div className="d-flex flex align-items-center">
                  <em>{`${groupDetail.userCnt}/${groupDetail.maxCount}`}</em>
                  <span className="ml-auto d-flex">
                    <OverlayTrigger
                      placement={'left'}
                      overlay={
                        <Tooltip id={'addGroupUser'}>
                          {t('구성원 추가')}
                        </Tooltip>
                      }
                    >
                      <a
                        className="font-weight-lighter"
                        onClick={handleShowGroupUserModal}
                      >
                        <span className="material-icons">add</span>
                      </a>
                    </OverlayTrigger>
                  </span>
                </div>
              </li>
              <li>
                <p>{t('프로젝트 액세스')}</p>
                <div className="d-flex flex align-items-center">
                  <em>{groupDetail.projectCnt}</em>
                  <span className="ml-auto d-flex">
                    <OverlayTrigger
                      placement={'left'}
                      overlay={
                        <Tooltip id={'addProjects'}>
                          {t('프로젝트 추가')}
                        </Tooltip>
                      }
                    >
                      <a
                        className="font-weight-lighter"
                        onClick={handleShowGroupProjectModal}
                      >
                        <span className="material-icons">add</span>
                      </a>
                    </OverlayTrigger>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="form-group text-center mb-32pt">
            <button
              className="btn btn-block btn-lg btn-accent"
              type="button"
              onClick={handleClickBack}
            >
              {t('목록으로')}
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <GroupUserList
          groupSeq={groupSeq}
          reloadFlag={userListReloadFlag}
          onClickAdd={handleShowGroupUserModal}
          onReloadGroup={handleReloadGroup}
        />
        <GroupProjectList
          groupSeq={groupSeq}
          reloadFlag={projectListReloadFlag}
          onClickAdd={handleShowGroupProjectModal}
          onReloadGroup={handleReloadGroup}
        />
      </div>
      <GroupManagementAddUserModal
        inviteUserList={addableInviteUserList}
        groupSeq={groupSeq}
        show={showGroupUserModal}
        onHide={handleHideGroupUserModal}
        onReload={() => {
          setUserListReloadFlag(!userListReloadFlag);
          handleReloadGroup();
        }}
      />
      <GroupManagementAddProjectModal
        groupSeq={groupSeq}
        reloadFlag={addableProjectListReloadFlag}
        show={showGroupProjectModal}
        onHide={handleHideGroupProjectModal}
        onReload={() => {
          setProjectListReloadFlag(!projectListReloadFlag);
          handleReloadGroup();
        }}
      />
    </>
  );
}

type GroupUserListProps = {
  groupSeq: number;
  reloadFlag: boolean;
  onClickAdd: () => void;
  onReloadGroup: () => void;
};

function GroupUserList({
  groupSeq,
  reloadFlag,
  onClickAdd,
  onReloadGroup,
}: GroupUserListProps) {
  const { t } = useTranslation();
  const { handleSetMenu } = useHomeMenu();
  const { handleSetDataUser } = useHomeData();
  const [load, setLoad] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [inviteUserList, setInviteUserList] = useState<InviteUserDetail[]>([]);
  const [showGroupDeleteUserModal, setShowGroupDeleteUserModal] = useState(
    false
  );
  const [
    groupDeleteUserData,
    setGroupDeleteUserData,
  ] = useState<InviteUserDetail>({} as InviteUserDetail);

  useEffect(() => {
    handleFetchGroupInviteUserList();
  }, [reloadFlag, page, searchKeyword]);

  const handleFetchGroupInviteUserList = async () => {
    setLoad(false);
    const result = await fetchGroupInviteUsers(groupSeq, page, searchKeyword);
    setInviteUserList(result.content);
    setTotalCount(result.totalElements);
    setLoad(true);
  };

  const handleSubmitSearch = (text: string) => {
    setSearchKeyword(text);
  };

  const handleClickItem = (userId: string) => {
    handleSetDataUser(userId);
    handleSetMenu(MENU_IDX_GROUP_SETTINGS_USER);
  };

  const handleClickDelete = (data: InviteUserDetail) => {
    setGroupDeleteUserData(data);
    setShowGroupDeleteUserModal(true);
  };

  const handleDeleteInviteUser = async () => {
    const result = await deleteInviteUserDeleteGroup({
      groupSeq,
      userId: groupDeleteUserData.userId,
      seq: groupDeleteUserData.seq,
    });

    if (result) {
      onReloadGroup();
      setShowGroupDeleteUserModal(false);
      handleFetchGroupInviteUserList();
    }
  };

  return (
    <>
      <div className="cell mb-4">
        <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt sort-wrap">
          <div className="flex title-row">
            <h3 className="mb-0">{t('구성원')}</h3>
          </div>
          <ListSearch onSubmit={handleSubmitSearch} />
          <a className="btn btn-accent btn-rounded ml-4" onClick={onClickAdd}>
            {t('구성원 추가')}
          </a>
        </div>
        <table className="table mb-4 table-nowrap">
          <thead>
            <tr>
              <th>
                <a>{t('사용자')}</a>
              </th>
              <th>
                <a>{t('마지막 활성 상태')}</a>
              </th>
              <th>
                <a>{t('상태')}</a>
              </th>
              <th>
                <a>{t('작업')}</a>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="list">
            {!load && <></>}
            {load && inviteUserList.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <div className="text-center py-4">
                    <span className="material-icons-outlined text-50 font-size-32pt">
                      info
                    </span>
                    <p className="m-0 text-50">
                      {t('아직 구성원이 없습니다. 구성원을 추가해 보세요.')}
                    </p>
                  </div>
                </td>
              </tr>
            )}
            {load &&
              inviteUserList.map((inviteUser) => (
                <GroupUserItem
                  key={inviteUser.invitationSeq}
                  {...inviteUser}
                  onClickItem={handleClickItem}
                  onClickDelete={() => handleClickDelete(inviteUser)}
                />
              ))}
          </tbody>
        </table>
        {load && inviteUserList.length > 0 && (
          <Pagination
            page={page}
            totalCount={totalCount}
            onChangePage={setPage}
          />
        )}
      </div>
      <NoticeContainerModal
        show={showGroupDeleteUserModal}
        onHide={() => setShowGroupDeleteUserModal(false)}
        onConfirm={handleDeleteInviteUser}
      >
        <div className="py-4">
          <div className="text-center">
            <h3>{t('선택한 구성원을 제외하시겠습니까?')}</h3>
          </div>
          <div className="d-flex justify-content-center">
            <div className="media flex-nowrap align-items-center border-1 p-3">
              <span className="avatar avatar-sm mr-2">
                <ProfileAvatar
                  profileImgUrl={groupDeleteUserData.profileImgUrl}
                  userName={groupDeleteUserData.userName}
                />
              </span>
              <div className="media-body">
                <strong className="text-dark">
                  {groupDeleteUserData.userName}
                </strong>
                <div className="text-muted small">
                  {groupDeleteUserData.userId}
                </div>
              </div>
            </div>
          </div>
        </div>
      </NoticeContainerModal>
    </>
  );
}

type GroupUserItemProps = InviteUserDetail & {
  onClickItem: (userId: string) => void;
  onClickDelete: () => void;
};

function GroupUserItem({
  userId,
  userName,
  profileImgUrl,
  latestAccessDate,
  onClickItem,
  onClickDelete,
  activeFlag,
}: GroupUserItemProps) {
  const { t } = useTranslation();
  const dropdown = useRef<HTMLDivElement>(null);
  const { handleToggle } = useDropdown(dropdown);

  const handleOptions = (eventKey: string | null) => {
    switch (eventKey) {
      case FUNCTION_DELETE:
        onClickDelete();
        break;
    }
  };

  const handleClickItem = () => {
    onClickItem(userId);
  };

  return (
    <tr
      className={classNames({
        disabled: !activeFlag,
      })}
    >
      <td>
        <a
          className="media flex-nowrap align-items-center"
          onClick={handleClickItem}
        >
          <span className="avatar avatar-sm mr-2">
            <ProfileAvatar profileImgUrl={profileImgUrl} userName={userName} />
          </span>
          <div className="media-body">
            <strong className="text-dark">{userName}</strong>
            <div className="text-muted small">{userId}</div>
          </div>
        </a>
      </td>
      <td>
        <span
          className={classNames({
            'text-30': !activeFlag,
          })}
        >
          {latestAccessDate && moment(latestAccessDate).format('YYYY.MM.DD')}
        </span>
      </td>
      <td>
        <span
          className={classNames({
            'text-30': !activeFlag,
          })}
        >
          {activeFlag && t('활성')}
          {!activeFlag && t('액세스 일시 중단')}
        </span>
      </td>
      <td>
        <a className="text-underline text-primary" onClick={handleClickItem}>
          {t('세부사항 보기')}
        </a>
      </td>
      <td className="text-right">
        <Dropdown
          onToggle={handleToggle}
          onSelect={handleOptions}
          className={'ml-auto'}
        >
          <Dropdown.Toggle as={'a'} data-caret="false" className="text-muted">
            <MaterialIcon name={'more_vert'} />
          </Dropdown.Toggle>
          <Dropdown.Menu align={'right'} ref={dropdown}>
            <Dropdown.Item className="text-danger" eventKey={FUNCTION_DELETE}>
              {t('그룹에서 구성원 제거')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
}

type GroupProjectListProps = {
  groupSeq: number;
  reloadFlag: boolean;
  onClickAdd: () => void;
  onReloadGroup: () => void;
};

function GroupProjectList({
  groupSeq,
  reloadFlag,
  onClickAdd,
  onReloadGroup,
}: GroupProjectListProps) {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [projectList, setProjectList] = useState<GroupProject[]>([]);
  const [
    showGroupDeleteProjectModal,
    setShowGroupDeleteProjectModal,
  ] = useState(false);
  const [
    groupDeleteProjectData,
    setGroupDeleteProjectData,
  ] = useState<GroupProject>({} as GroupProject);

  useEffect(() => {
    handleFetchGroupProjectList();
  }, [reloadFlag, page, searchKeyword]);

  const handleFetchGroupProjectList = async () => {
    setLoad(false);
    const result = await fetchGroupProjects(groupSeq, page, searchKeyword);
    setProjectList(result.content);
    setTotalCount(result.totalElements);
    setLoad(true);
  };

  const handleSubmitSearch = (text: string) => {
    setSearchKeyword(text);
  };

  const handleClickDelete = (data: GroupProject) => {
    setGroupDeleteProjectData(data);
    setShowGroupDeleteProjectModal(true);
  };

  const handleDeleteProject = async () => {
    const result = await deleteGroupDeleteProject({
      groupSeq,
      seq: groupDeleteProjectData.seq,
      projectId: groupDeleteProjectData.projectId,
    });

    if (result) {
      onReloadGroup();
      setShowGroupDeleteProjectModal(false);
      handleFetchGroupProjectList();
    }
  };

  const handleChangeRoleType = async (
    changeRoleProject: GroupChangeRoleProject
  ) => {
    const result = await putGroupProjectRoleType(groupSeq, changeRoleProject);
    if (result) {
      Toastr.remove();
      Toastr.success(t('권한 설정을 변경하였습니다'), '', {
        positionClass: 'toast-bottom-right',
      });
      setProjectList(
        projectList.map((project) => {
          if (project.projectId === changeRoleProject.projectId) {
            project.roleType = changeRoleProject.roleType;
          }

          return project;
        })
      );
    }
  };

  return (
    <>
      <div className="cell mb-4">
        <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt sort-wrap">
          <div className="flex title-row">
            <h3 className="mb-0">{t('프로젝트 액세스 권한')}</h3>
          </div>
          <ListSearch onSubmit={handleSubmitSearch} />
          <a className="btn btn-accent btn-rounded ml-4" onClick={onClickAdd}>
            {t('프로젝트 추가')}
          </a>
        </div>
        <table className="table mb-4 table-nowrap">
          <thead>
            <tr>
              <th>
                <a>{t('프로젝트')}</a>
              </th>
              <th>
                <a>{t('권한')}</a>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="list">
            {!load && <></>}
            {load && projectList.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <div className="text-center py-4">
                    <span className="material-icons-outlined text-50 font-size-32pt">
                      info
                    </span>
                    <p className="m-0 text-50">
                      {t('아직 연결된 프로젝트가 없습니다.')}
                    </p>
                  </div>
                </td>
              </tr>
            )}
            {load &&
              projectList.map((project) => (
                <GroupProjectItem
                  key={project.projectId}
                  {...project}
                  onClickDelete={() => {
                    handleClickDelete(project);
                  }}
                  onChangeRoleType={handleChangeRoleType}
                />
              ))}
          </tbody>
        </table>
        {load && projectList.length > 0 && (
          <Pagination
            page={page}
            totalCount={totalCount}
            onChangePage={setPage}
          />
        )}
      </div>
      <NoticeContainerModal
        show={showGroupDeleteProjectModal}
        onHide={() => setShowGroupDeleteProjectModal(false)}
        onConfirm={handleDeleteProject}
      >
        <div className="py-4">
          <div className="text-center">
            <h3>{t('선택한 프로젝트를 제외하시겠습니까?')}</h3>
          </div>
          <div className="d-flex justify-content-center">
            <div className="media flex-nowrap align-items-center border-1 p-3">
              <a className="avatar mr-12pt">
                <img
                  src={groupDeleteProjectData.thumbnail}
                  className="avatar-img rounded"
                />
              </a>
              <div className="flex list-els">
                <a className="card-title">
                  {groupDeleteProjectData.projectName}
                </a>
              </div>
            </div>
          </div>
        </div>
      </NoticeContainerModal>
    </>
  );
}

type GroupProjectItemProps = GroupProject & {
  onClickDelete: () => void;
  onChangeRoleType: (changeRoleProject: GroupChangeRoleProject) => void;
};

function GroupProjectItem({
  seq,
  projectId,
  projectName,
  thumbnail,
  roleType,
  onClickDelete,
  onChangeRoleType,
}: GroupProjectItemProps) {
  const { t } = useTranslation();
  const dropdown = useRef<HTMLDivElement>(null);
  const { handleToggle } = useDropdown(dropdown);

  const handleOptions = (eventKey: string | null) => {
    switch (eventKey) {
      case FUNCTION_DELETE:
        onClickDelete();
        break;
    }
  };

  const handleClickItem = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY_PROJECT_ID, projectId);
    window.open(location.origin, '_black');
  };

  return (
    <tr>
      <td>
        <div
          className="flex d-flex align-items-center mr-16pt"
          onClick={handleClickItem}
        >
          <a className="avatar mr-12pt">
            <img src={thumbnail} className="avatar-img rounded" />
          </a>
          <div className="flex list-els">
            <a className="card-title">{projectName}</a>
          </div>
        </div>
      </td>
      <td>
        <select
          className="form-control custom-select"
          value={roleType}
          onChange={(e) => {
            onChangeRoleType({
              projectId,
              seq,
              roleType: e.target.value as GroupProjectRoleType,
            });
          }}
        >
          <option value="user">{t('사용자')}</option>
          <option value="admin">{t('관리자')}</option>
        </select>
      </td>
      <td className="text-right">
        <Dropdown
          onToggle={handleToggle}
          onSelect={handleOptions}
          className={'ml-auto'}
        >
          <Dropdown.Toggle as={'a'} data-caret="false" className="text-muted">
            <MaterialIcon name={'more_vert'} />
          </Dropdown.Toggle>
          <Dropdown.Menu align={'right'} ref={dropdown}>
            <Dropdown.Item className="text-danger" eventKey={FUNCTION_DELETE}>
              {t('그룹에서 프로젝트 제거')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
}

export default GroupManagementDetail;
