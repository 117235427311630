import { Feature } from 'ol';
import { ProcessSpaceInfo } from '@/api/space';
import { LngLat } from '@/modules/common';

export type JapanLngLat = {
  経度: number;
  緯度: number;
};

export type ZipCodeAddress = {
  xpos: number;
  ypos: number;
  pre: string;
  city: string;
  addr: string;
};

export type DrawGeoImage = {
  url: string;
  imageCenter: number[];
  imageScale: number[];
  imageRotate: number;
  opacity?: number;
};

export const SPACE_COUNTRY_CODE_JP = 81;
export const SPACE_COUNTRY_CODE_KR = 82;
export const SPACE_COUNTRY_CODE_ETC = 999999;

export type SpaceCountryCode =
  | typeof SPACE_COUNTRY_CODE_JP
  | typeof SPACE_COUNTRY_CODE_KR
  | typeof SPACE_COUNTRY_CODE_ETC;

export type ConnectedSpaceInfo = ProcessSpaceInfo & {
  mappingId: string;
};

export type UploadFloorInfo = {
  map_id: string;
  filename: string;
};

export type AreaInfo = {
  id: string;
  areaname: string;
  geomstr: string;
  feature: Feature | null;
};

export type PoiInfo = {
  poi_category: string;
  poi_id: string;
  poi_name: string;
  geom_type: string;
  geomstr: string;
  filenm: string;
  xcoord: number;
  ycoord: number;
};

export type RegisterSelectedAddress = {
  selectedDo: string;
  selectedSubDistrict: string;
  selectedLngLat: LngLat | null;
  detailedAddress: string;
};

export type SpaceState = {
  register: {
    address: RegisterSelectedAddress & {
      showPane: boolean;
    };
  };
};

export const SPACE_CHANGE_SHOW_ADDRESS_PANE = 'SPACE/CHANGE_SHOW_ADDRESS_PANE' as const;
export const SPACE_SET_ADDRESS = 'SPACE/SET_ADDRESS' as const;

export type Action =
  | {
      type: typeof SPACE_CHANGE_SHOW_ADDRESS_PANE;
      show: boolean;
    }
  | {
      type: typeof SPACE_SET_ADDRESS;
      selectedAddress: RegisterSelectedAddress;
    };
