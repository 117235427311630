import axios from 'axios';
import { Config } from '@/config';
import { PAGING_SIZE } from '@/utils/constants/common';
import { ApiResult, ListResult } from '@/modules/common';
import { StatusCodes } from 'http-status-codes';
import { Campaign } from '@/modules/campaign/types';

export async function fetchCampaigns(params: {
  page: number;
  projectId: string;
  mappingId: string;
  mapId: string;
  showType2: string;
}) {
  let listResult: ListResult<Campaign[]> = {
    content: [],
    totalElements: 0,
  };

  try {
    const response = await axios.get(`${Config.platform_api.uri}/events`, {
      params: {
        ...params,
        pageSize: PAGING_SIZE,
      },
    });
    const result: ApiResult<ListResult<Campaign[]>> = response.data;
    if (result.status === StatusCodes.OK && result.data) {
      listResult = {
        content: result.data.content,
        totalElements: result.data.totalElements,
      };
    }
  } catch (e) {}

  return listResult;
}

export async function fetchCampaign(campaignId: string) {
  let campaign: Campaign | null = null;
  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/events/${campaignId}`
    );
    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      campaign = result.data as Campaign;
    }
  } catch (e) {}

  return campaign;
}

export type RequestPostCampaign = {
  projectId: string;
  targetMappingId: string;
  targetMapId: string;
  targetAreaId?: string;
  targetGeomType?: 'OUTER' | 'INNER';
  imgId?: string;
  eventTitle: string;
  eventSubject: string;
  eventContent: string;
  linkUrl: string;
  startDate: string;
  endDate: string;
  activeFlag: boolean;
  geomType?: 'CIRCLE' | 'POLYGON';
  geom?: string;
  lat?: number;
  lng?: number;
  radius?: number;
  outerKey?: string;
};

export async function postCampaign(data: RequestPostCampaign) {
  try {
    const response = await axios.post(
      `${Config.platform_api.uri}/events`,
      data
    );
    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      return Boolean(result.data);
    }
  } catch (e) {}

  return false;
}

export type RequestPutCampaign = {
  targetAreaId?: string;
  targetGeomType?: 'OUTER' | 'INNER';
  imgId?: string;
  eventTitle: string;
  eventSubject: string;
  eventContent: string;
  linkUrl: string;
  startDate: string;
  endDate: string;
  geomType?: 'CIRCLE' | 'POLYGON';
  geom?: string;
  lat?: number;
  lng?: number;
  radius?: number;
  outerKey?: string;
};

export async function putCampaign(eventId: string, data: RequestPutCampaign) {
  try {
    const response = await axios.put(
      `${Config.platform_api.uri}/events/${eventId}`,
      data
    );
    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      return Boolean(result.data);
    }
  } catch (e) {}

  return false;
}

export async function putCampaignActive(campaignId: string, active: boolean) {
  try {
    let flag = 'off';
    if (active) {
      flag = 'on';
    }

    const response = await axios.put(
      `${Config.platform_api.uri}/events/${flag}/${campaignId}`
    );

    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      return Boolean(result.data);
    }
  } catch (e) {}

  return false;
}

// 캠페인 삭제
export const deleteCampaign = async (eventId: string): Promise<ApiResult> => {
  const response = await axios.delete(
    `${Config.platform_api.uri}/events/${eventId}`
  );

  const result: ApiResult = response.data;
  if (response.status === StatusCodes.OK) {
    return result.data;
  }

  throw new Error('Server Error');
};
