import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

type TableEmptyRowProps = {
  colSpan: number;
  textKey: string;
};

function TableEmptyRow({ textKey }: TableEmptyRowProps): ReactElement {
  const { t } = useTranslation();

  return (
    <tr>
      <td colSpan={3}>
        <div className="text-center py-4">
          <span className="material-icons-outlined text-50 font-size-32pt">
            info
          </span>
          <p className="m-0 text-50">{t(textKey)}</p>
        </div>
      </td>
    </tr>
  );
}

export default TableEmptyRow;
