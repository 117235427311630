import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Group, GroupProject, InviteUserDetail } from '@/modules/group/types';
import { useDropdown } from '@/modules/common';
import {
  deleteInviteUserDeleteGroup,
  fetchInviteUser,
  fetchInviteUserGroups,
  fetchInviteUserProjects,
} from '@/api/group';
import {
  FUNCTION_CONTROL_ACCESS,
  FUNCTION_DELETE,
  FUNCTION_DETAIL,
  LOCAL_STORAGE_KEY_PROJECT_ID,
} from '@/utils/constants/common';
import MaterialIcon from '@/components/common/MaterialIcon';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { UserManagementView } from '@/components/UserManagement';
import ProfileAvatar from '@/components/common/ProfileAvatar';
import { UserManagementAccessData } from '@/components/UserManagementAccessModal';
import NoticeContainerModal from '@/components/NoticeContainerModal';
import classNames from 'classnames';
import { UserManagementAddGroupData } from '@/components/UserManagementAddGroupModal';
import { CommonUtils } from '@/utils';
import { MENU_IDX_GROUP_SETTINGS_GROUP } from '@/modules/setup/types';
import { useHomeData, useHomeMenu } from '@/modules/home/hook';
import Pagination from '@/components/common/Pagination';

type UserManagementDetailProps = {
  userId: string;
  reloadFlag: boolean;
  onClickBack: (view: UserManagementView) => void;
  onClickAccessControl: (data: UserManagementAccessData) => void;
  onClickAddGroup: (data: UserManagementAddGroupData) => void;
};

function UserManagementDetail({
  userId,
  reloadFlag,
  onClickBack,
  onClickAccessControl,
  onClickAddGroup,
}: UserManagementDetailProps) {
  const { t } = useTranslation();
  const [innerReloadFlag, setInnerReloadFlag] = useState(false);
  const [inviteUserDetail, setInviteUserDetail] = useState<InviteUserDetail>(
    {} as InviteUserDetail
  );
  const dropdown = useRef<HTMLDivElement>(null);
  const { handleToggle } = useDropdown(dropdown);

  useEffect(() => {
    if (userId) {
      handleFetchInviteUserDetail();
    }
  }, [userId, reloadFlag]);

  const handleFetchInviteUserDetail = async () => {
    const result = await fetchInviteUser(userId);
    if (result) {
      setInviteUserDetail(result);
    } else {
      onClickBack('list');
    }
  };

  const handleClickBack = () => {
    onClickBack('list');
  };

  const handleOptions = (eventKey: string | null) => {
    switch (eventKey) {
      case FUNCTION_CONTROL_ACCESS:
        onClickAccessControl({
          userName: inviteUserDetail.userName,
          userId: inviteUserDetail.userId,
          profileImgUrl: inviteUserDetail.profileImgUrl,
          activeFlag: inviteUserDetail.activeFlag,
          invitationSeq: inviteUserDetail.invitationSeq,
        });
        break;
    }
  };

  if (CommonUtils.isEmptyObject(inviteUserDetail)) {
    return <></>;
  }

  return (
    <>
      <div className="col-md-4">
        <div className="cell">
          <div className="d-flex align-items-center mb-5">
            <a className="circle-pin pr-2" onClick={handleClickBack}>
              <MaterialIcon name={'arrow_back'} />
            </a>
            <Dropdown
              onToggle={handleToggle}
              onSelect={handleOptions}
              className={'ml-auto'}
            >
              <Dropdown.Toggle
                as={'a'}
                data-caret="false"
                className="text-muted"
              >
                <MaterialIcon name={'more_vert'} />
              </Dropdown.Toggle>
              <Dropdown.Menu align={'right'} ref={dropdown}>
                <Dropdown.Item eventKey={FUNCTION_CONTROL_ACCESS}>
                  {inviteUserDetail.activeFlag && t('액세스 일시 중단')}
                  {!inviteUserDetail.activeFlag && t('액세스 허용')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="group-title d-flex mb-5 align-items-center">
            <span className="avatar avatar-lg mr-3">
              <ProfileAvatar
                profileImgUrl={inviteUserDetail.profileImgUrl}
                userName={inviteUserDetail.userName}
              />
            </span>
            <div className="flex">
              <h2 className="mb-0">{inviteUserDetail.userName}</h2>
              <p className="text-50 mb-0">
                {inviteUserDetail.latestAccessDate &&
                  t(
                    '{{year}}년 {{month}}월 {{day}}일에 마지막 활성이었습니다.',
                    {
                      replace: {
                        year: moment(inviteUserDetail.latestAccessDate).format(
                          'YYYY'
                        ),
                        month: moment(inviteUserDetail.latestAccessDate).format(
                          'M'
                        ),
                        day: moment(inviteUserDetail.latestAccessDate).format(
                          'D'
                        ),
                      },
                    }
                  )}
              </p>
            </div>
          </div>
          <div
            className={classNames('group-state', {
              'mb-5': false,
            })}
          >
            <ul className="col-wide">
              <li>
                <p>{t('이름')}</p>
                <h5>{inviteUserDetail.userName}</h5>
              </li>
              <li>
                <p>{t('이메일')}</p>
                <h5>{inviteUserDetail.userId}</h5>
              </li>
              <li>
                <p>{t('회사명')}</p>
                <h5>{inviteUserDetail.companyName}</h5>
              </li>
              <li>
                <p>{t('부서')}</p>
                <h5>{inviteUserDetail.department}</h5>
              </li>
            </ul>
          </div>
          {/*
          <div className="mb-32pt">
            <a className="btn btn-block btn-lg btn-accent">
              {t('정보 수정')}
            </a>
          </div>
          */}
        </div>
      </div>
      <div className="col-md-8">
        <UserGroupList
          userId={inviteUserDetail.userId}
          invitationSeq={inviteUserDetail.invitationSeq}
          onClickAddGroup={onClickAddGroup}
          reloadFlag={reloadFlag}
          onChangeInnerReloadFlag={() => setInnerReloadFlag(!innerReloadFlag)}
        />
        <UserProjectList
          userId={inviteUserDetail.userId}
          reloadFlag={reloadFlag}
          innerReloadFlag={innerReloadFlag}
        />
      </div>
    </>
  );
}

type UserProjectListProps = {
  userId: string;
  reloadFlag: boolean;
  innerReloadFlag: boolean;
};

function UserProjectList({
  userId,
  reloadFlag,
  innerReloadFlag,
}: UserProjectListProps) {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [projectList, setProjectList] = useState<GroupProject[]>([]);

  const handleFetchProjectList = async () => {
    setLoad(false);
    const result = await fetchInviteUserProjects(userId, page);
    setProjectList(result.content);
    setTotalCount(result.totalElements);
    setLoad(true);
  };

  useEffect(() => {
    handleFetchProjectList();
  }, [userId, page, reloadFlag, innerReloadFlag]);

  return (
    <div className="cell mb-4">
      <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt sort-wrap">
        <div className="flex title-row">
          <h3 className="mb-0">{t('프로젝트')}</h3>
          <small className="text-muted text-headings text-uppercase">
            {t('사용자의 역할을 관리하고 액세스 권한을 부여합니다')}
          </small>
        </div>
      </div>
      <table className="table mb-4 table-nowrap">
        <thead>
          <tr>
            <th>
              <a>{t('프로젝트')}</a>
            </th>
            <th>
              <a>{t('그룹')}</a>
            </th>
            <th>
              <a>{t('권한')}</a>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody className="list">
          {!load && <></>}
          {load && projectList.length === 0 && (
            <tr>
              <td colSpan={4}>
                <div className="text-center py-4">
                  <span className="material-icons-outlined text-50 font-size-32pt">
                    info
                  </span>
                  <p className="m-0 text-50">
                    {t('아직 연결된 프로젝트가 없습니다.')}
                  </p>
                </div>
              </td>
            </tr>
          )}
          {load &&
            projectList.map((project) => (
              <UserProjectItem
                key={`${project.groupSeq}/${project.projectId}`}
                {...project}
              />
            ))}
        </tbody>
      </table>
      {load && projectList.length > 0 && (
        <Pagination
          page={page}
          totalCount={totalCount}
          onChangePage={setPage}
        />
      )}
    </div>
  );
}

type UserProjectItemProps = GroupProject;

function UserProjectItem({
  projectId,
  projectName,
  groupName,
  thumbnail,
  roleType,
}: UserProjectItemProps) {
  const { t } = useTranslation();
  const dropdown = useRef<HTMLDivElement>(null);
  const { handleToggle } = useDropdown(dropdown);

  const handleOptions = (eventKey: string | null) => {
    switch (eventKey) {
      case FUNCTION_DELETE:
        break;
    }
  };

  const handleClickItem = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY_PROJECT_ID, projectId);
    window.open(location.origin, '_black');
  };

  return (
    <tr>
      <td>
        <div
          className="flex d-flex align-items-center mr-16pt"
          onClick={handleClickItem}
        >
          <a className="avatar mr-12pt">
            <img src={thumbnail} className="avatar-img rounded" />
          </a>
          <div className="flex list-els">
            <a className="card-title">{projectName}</a>
          </div>
        </div>
      </td>
      <td>{groupName}</td>
      <td>
        <strong>
          {roleType === 'user' && t('사용자')}
          {roleType === 'admin' && t('관리자')}
        </strong>
      </td>
      <td className="text-right">
        {/*
        <Dropdown
          onToggle={handleToggle}
          onSelect={handleOptions}
          className={'ml-auto'}
        >
          <Dropdown.Toggle as={'a'} data-caret="false" className="text-muted">
            <MaterialIcon name={'more_vert'} />
          </Dropdown.Toggle>
          <Dropdown.Menu align={'right'} ref={dropdown}>
            <Dropdown.Item className="text-danger" eventKey={FUNCTION_DELETE}>
              {t('프로젝트에서 사용자 제외')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        */}
      </td>
    </tr>
  );
}

type UserGroupListProps = {
  userId: string;
  invitationSeq: number;
  reloadFlag: boolean;
  onClickAddGroup: (data: UserManagementAddGroupData) => void;
  onChangeInnerReloadFlag: () => void;
};

function UserGroupList({
  userId,
  invitationSeq,
  reloadFlag,
  onClickAddGroup,
  onChangeInnerReloadFlag,
}: UserGroupListProps) {
  const { t } = useTranslation();
  const { handleSetMenu } = useHomeMenu();
  const { handleSetDataGroup } = useHomeData();
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [groupList, setGroupList] = useState<Group[]>([]);
  const [showUserDeleteGroupModal, setShowUserDeleteGroupModal] = useState(
    false
  );
  const [userDeleteGroupData, setUserDeleteGroupData] = useState<{
    groupSeq: number;
    seq: number;
    groupName: string;
  }>({
    groupSeq: -1,
    seq: -1,
    groupName: '',
  });

  const handleClickItem = (groupSeq: number) => {
    handleSetDataGroup(groupSeq);
    handleSetMenu(MENU_IDX_GROUP_SETTINGS_GROUP);
  };

  const handleShowUserDeleteGroupModal = (data: {
    groupSeq: number;
    seq: number;
    groupName: string;
  }) => {
    setUserDeleteGroupData(data);
    setShowUserDeleteGroupModal(true);
  };

  const handleUserDeleteGroup = async () => {
    const { groupSeq, seq } = userDeleteGroupData;
    const result = await deleteInviteUserDeleteGroup({
      groupSeq,
      seq,
      userId,
    });

    if (result) {
      handleFetchGroupList();
      setShowUserDeleteGroupModal(false);
      onChangeInnerReloadFlag();
    }
  };

  const handleClickAddGroup = () => {
    onClickAddGroup({
      userId,
      invitationSeq,
    });
  };

  const handleFetchGroupList = async () => {
    setLoad(false);
    const result = await fetchInviteUserGroups(userId, page);
    setGroupList(result.content);
    setTotalCount(result.totalElements);
    setLoad(true);
  };

  useEffect(() => {
    handleFetchGroupList();
  }, [userId, page, reloadFlag]);

  return (
    <>
      <div className="cell mb-4">
        <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt sort-wrap">
          <div className="flex title-row">
            <h3 className="mb-0">{t('그룹')}</h3>
            <small className="text-muted text-headings text-uppercase">
              {t(
                '그룹을 사용하여 이 사용자의 권한, 제한 사항 및 프로젝트 액세스를 관리합니다.'
              )}
            </small>
          </div>
          <a
            className="btn btn-accent btn-rounded ml-4"
            onClick={handleClickAddGroup}
          >
            {t('그룹에 추가')}
          </a>
        </div>
        <table className="table mb-4 table-nowrap">
          <colgroup>
            <col width="*" />
            <col width="5%" />
          </colgroup>
          <thead>
            <tr>
              <th>
                <a>{t('그룹')}</a>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="list">
            {!load && <></>}
            {load && groupList.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <div className="text-center py-4">
                    <span className="material-icons-outlined text-50 font-size-32pt">
                      info
                    </span>
                    <p className="m-0 text-50">
                      {t('아직 소속한 그룹이 없습니다.')}
                    </p>
                  </div>
                </td>
              </tr>
            )}
            {load &&
              groupList.map((group) => (
                <UserGroupItem
                  key={group.groupSeq}
                  {...group}
                  onClickItem={handleClickItem}
                  onDelete={handleShowUserDeleteGroupModal}
                />
              ))}
          </tbody>
        </table>
        {load && groupList.length > 0 && (
          <Pagination
            page={page}
            totalCount={totalCount}
            onChangePage={setPage}
          />
        )}
      </div>
      <NoticeContainerModal
        show={showUserDeleteGroupModal}
        onHide={() => setShowUserDeleteGroupModal(false)}
        onConfirm={handleUserDeleteGroup}
      >
        <div className="py-4">
          <div className="text-center">
            <h3>{t('선택한 그룹에서 사용자를 제외하시겠습니까?')}</h3>
            <p className="m-0 text-50 font-size-16pt">
              <mark>{userDeleteGroupData.groupName}</mark>
              {t('그룹에서 해당 사용자가 제외됩니다.')}
            </p>
          </div>
        </div>
      </NoticeContainerModal>
    </>
  );
}

type UserGroupItemProps = Group & {
  onClickItem: (groupSeq: number) => void;
  onDelete: (data: {
    groupSeq: number;
    seq: number;
    groupName: string;
  }) => void;
};

function UserGroupItem({
  groupSeq,
  seq,
  groupName,
  groupDesc,
  onClickItem,
  onDelete,
}: UserGroupItemProps) {
  const { t } = useTranslation();
  const dropdown = useRef<HTMLDivElement>(null);
  const { handleToggle } = useDropdown(dropdown);

  const handleOptions = (eventKey: string | null) => {
    switch (eventKey) {
      case FUNCTION_DETAIL:
        onClickItem(groupSeq);
        break;
      case FUNCTION_DELETE:
        onDelete({
          groupSeq,
          seq,
          groupName,
        });
        break;
    }
  };

  return (
    <tr>
      <td>
        <a className="media-body" onClick={() => onClickItem(groupSeq)}>
          <strong className="text-dark">{groupName}</strong>
          <div className="text-muted small">{groupDesc}</div>
        </a>
      </td>
      <td className="text-right">
        <Dropdown
          onToggle={handleToggle}
          onSelect={handleOptions}
          className={'ml-auto'}
        >
          <Dropdown.Toggle as={'a'} data-caret="false" className="text-muted">
            <MaterialIcon name={'more_vert'} />
          </Dropdown.Toggle>
          <Dropdown.Menu align={'right'} ref={dropdown}>
            <Dropdown.Item eventKey={FUNCTION_DETAIL}>
              {t('세부사항 보기')}
            </Dropdown.Item>
            <Dropdown.Item className="text-danger" eventKey={FUNCTION_DELETE}>
              {t('그룹에서 사용자 제외')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
}

export default UserManagementDetail;
