import React, { useEffect, useRef } from 'react';
import { useLeftPane } from '@/modules/setup/hook';
import { MENU_IDX_PROJECT_MANAGEMENT, MenuIdx } from '@/modules/setup/types';
import HomeLeftPane from '@/components/home/HomeLeftPane';
import ProjectPane from '@/components/project/ProjectPane';

type LeftPaneProps = {
  menuIdx?: MenuIdx;
};

function LeftPane({ menuIdx }: LeftPaneProps) {
  const { show } = useLeftPane();
  const divElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const divElement = divElementRef.current;

    if (divElement) {
      if (show) {
        divElement.style.visibility = 'visible';
        divElement.style.transform = 'translateZ(0)';
        divElement.style.boxShadow =
          '0 3px 3px -2px rgba(39, 44, 51, .1), 0 3px 4px 0 rgba(39, 44, 51, .04), 0 1px 8px 0 rgba(39, 44, 51, .02)';
      } else {
        divElement.style.transform = '';
        divElement.style.boxShadow = '';
      }
    }
  }, [show]);

  let isHome = true;
  switch (menuIdx) {
    case MENU_IDX_PROJECT_MANAGEMENT:
      isHome = false;
      break;
  }

  return (
    <div className="side-contents" ref={divElementRef}>
      {menuIdx === MENU_IDX_PROJECT_MANAGEMENT && <ProjectPane />}
      {isHome && <HomeLeftPane />}
    </div>
  );
}

export default LeftPane;
