import React, { useEffect, useState } from 'react';
import Map from 'ol/Map';
import OpenLayersMap from '@/components/common/OpenLayersMap';
import HomeMonitoringMapControl from '@/components/home/HomeMonitoringMapControl';
import { useHomeMenu, useHomeSelected } from '@/modules/home/hook';
import BottomToolbar from '@/components/common/BottomToolbar';
import DrawOpenLayersMap from '@/components/common/DrawOpenLayersMap';
import { useOpenLayersResource } from '@/modules/openlayers/hook';
import MonitoringOpenLayersMap from '@/components/monitoring/MonitoringOpenLayersMap';
import {
  useLayerPopup,
  useLeftPane,
  useLeftSidebar,
  useRightPane,
} from '@/modules/setup/hook';
import LayerPopup from '@/components/common/LayerPopup';
import GeofenceOpenLayersMap from '@/components/common/GeofenceOpenLayersMap';
import { useFetchFloorObject } from '@/modules/floor/hook';
import MapAlertContainer from './MapAlertContainer';

function HomeOpenLayersMap() {
  const {
    show,
    handleChangeShow: handleChangeLayerPopupShow,
  } = useLayerPopup();
  const { activeMenuIdx } = useHomeMenu();
  const { expand } = useLeftSidebar();
  const {
    show: showLeftPane,
    handleChangeShow: handleChangeLeftShow,
  } = useLeftPane();
  const {
    show: showRightPane,
    handleChangeShow: handleChangeRightShow,
  } = useRightPane();
  const { building, floor } = useHomeSelected();
  useFetchFloorObject();
  const { handleSetMap, handleInitResource } = useOpenLayersResource();
  const [map, setMap] = useState<Map | null>(null);

  useEffect(() => {
    return () => {
      handleInitResource();
      handleChangeLayerPopupShow(false);
      handleChangeLeftShow(true);
      handleChangeRightShow(true);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      map?.updateSize();
    }, 200);
  }, [activeMenuIdx, expand]);

  useEffect(() => {
    map?.updateSize();
  }, [showLeftPane, showRightPane]);

  const handleInitMap = (initMap: Map) => {
    setMap(initMap);
    handleSetMap(initMap);
  };

  const handleClickLocationControl = () => {
    building && map && map.getView().setCenter([building.lng, building.lat]);
  };

  return (
    <>
      <HomeMonitoringMapControl />
      <OpenLayersMap
        center={[building?.lng || 0, building?.lat || 0]}
        mapId={floor?.mapId}
        onInitMap={handleInitMap}
      />
      <MonitoringOpenLayersMap map={map} />
      <DrawOpenLayersMap map={map} />
      <GeofenceOpenLayersMap map={map} />
      {map && (
        <BottomToolbar
          map={map}
          showRotateControl={true}
          showLocationControl={true}
          showThreeDimensionControl={true}
          showFloorPlanControl={true}
          onClickLocationControl={handleClickLocationControl}
        />
      )}
      {show && <LayerPopup />}
      <MapAlertContainer />
    </>
  );
}

export default React.memo(HomeOpenLayersMap);
