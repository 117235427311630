import Select, { ValueType } from 'react-select';
import SelectMultiValue from '@/components/SelectMultiValue';
import React, { useEffect, useState } from 'react';
import { ReactSelectOption } from '@/modules/common';

type SelectMultiProps = {
  options: ReactSelectOption[];
  value?: string[];
  onChangeValue: (selectedValue: string[]) => void;
};

function SelectMulti({ options, value, onChangeValue }: SelectMultiProps) {
  const [settingValue, setSettingValue] = useState<ReactSelectOption[]>([]);

  useEffect(() => {
    setSettingValue(options.filter((option) => value?.includes(option.value)));
  }, [value]);

  return (
    <Select
      isMulti={true}
      placeholder={''}
      {...(value && { value: settingValue })}
      onChange={(data, actionMeta) => {
        const selectedValue: string[] = [];
        const changeData = data as ValueType<{ value: string }, false>[];
        changeData.forEach((changeDatum) => {
          if (changeDatum) {
            selectedValue.push(changeDatum.value);
          }
        });
        onChangeValue(selectedValue);
      }}
      styles={{
        multiValue: (base, props) => {
          return {
            ...base,
            backgroundColor: '#F5F7FA',
          };
        },

        menu: (base, props) => {
          return {
            ...base,
            zIndex: 99,
          };
        },
        multiValueRemove: (base, props) => {
          return {
            ...base,
            ':hover': {
              backgroundColor: 'transparent',
            },
          };
        },
      }}
      components={{
        MultiValue: SelectMultiValue,
      }}
      filterOption={(option, rawInput) => {
        if (option.data.name.indexOf(rawInput) > -1) {
          return true;
        }
        return false;
      }}
      options={options}
    />
  );
}

export default SelectMulti;
