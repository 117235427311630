import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postResetPasswordMail } from '@/api/user';
import { ValidUtils } from '@/utils';
import { Alert } from 'react-bootstrap';
import MaterialIcon from '@/components/common/MaterialIcon';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { getLanguageCode } from '@/locales/i18n';

function FindPasswordForm() {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (emailValid && !loading) {
      setLoading(true);
      const result = await postResetPasswordMail(email, getLanguageCode());
      if (result) {
        setSuccess(true);
        setTimeout(() => {
          history.replace('/signin');
        }, 1000);
      }
    }
  };

  useEffect(() => {
    setEmailValid(ValidUtils.validateEmail(email));
  }, [email]);

  return (
    <>
      <h2 className="mb-2">{t('비밀번호 찾기')}</h2>
      <p className="mb-5 text-50">
        {t('이메일 주소를 입력하시면 비밀번호 재설정 링크를 보내드립니다.')}
      </p>
      <form onSubmit={handleSubmit} noValidate={true}>
        <div className="form-group mb-4">
          <label className="form-label essential text-50" htmlFor="email">
            {t('이메일')}
          </label>
          <input
            id="email"
            type="email"
            className="form-line"
            autoComplete={'off'}
            placeholder={t('이메일을 입력하세요.')}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        {error && (
          <Alert className="alert-soft-accent">
            <div className="d-flex flex-wrap align-items-center">
              <div className="mr-8pt">
                <MaterialIcon name={'error_outline'} />
              </div>
              <div className="flex" style={{ minWidth: '180px' }}>
                <small className="text-black-100">
                  {t('가입하지 않은 이메일이거나, 잘못된 이메일입니다.')}
                </small>
              </div>
            </div>
          </Alert>
        )}
        {success && (
          <Alert className="alert-soft-success">
            <div className="d-flex flex-wrap align-items-center">
              <div className="mr-8pt">
                <MaterialIcon name={'done'} />
              </div>
              <div className="flex" style={{ minWidth: '180px' }}>
                <small className="text-black-100">
                  {t('메일이 발송되었습니다.')}
                </small>
              </div>
            </div>
          </Alert>
        )}
        <div className="form-group text-center mb-32pt">
          <button
            className={classNames('btn btn-block btn-lg btn-accent', {
              disabled: !emailValid,
              'is-loading': loading,
            })}
            style={{
              cursor: !emailValid ? 'default' : 'pointer',
            }}
            type="submit"
          >
            <MaterialIcon name={'send'} align={'left'} /> {t('메일 보내기')}
          </button>
        </div>
        <ul className="signin-help">
          <li>
            {t('아직 계정이 없으신가요?')}
            <Link className="text-body text-underline" to={'/signup'}>
              {t('계정생성')}
            </Link>
          </li>
        </ul>
      </form>
    </>
  );
}

export default FindPasswordForm;
