import { ProjectionLike, transform } from 'ol/proj';

export class OpenLayersUtils {
  static convertCoordinates(
    coordinates: number[],
    source: ProjectionLike = 'EPSG:4326',
    destination: ProjectionLike = 'EPSG:3857',
    reverse = false
  ) {
    if (coordinates.length < 2) {
      return coordinates;
    }

    return transform(
      reverse
        ? [coordinates[1], coordinates[0]]
        : [coordinates[0], coordinates[1]],
      source,
      destination
    );
  }
}
