import { useUser } from '@/modules/user/hook';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';
import { useDropdown } from '@/modules/common/hook';
import { languages, Languages } from '@/locales/i18n';
import i18n from 'i18next';
import { Dropdown } from 'react-bootstrap';
import { useLogout } from '@/modules/access/logout/hook';
import { useHomeMenu, useHomeSelected } from '@/modules/home/hook';
import ProfileAvatar from '@/components/common/ProfileAvatar';
import {
  MENU_IDX_ACCOUNT,
  MENU_IDX_MESSAGE,
  RIGHT_PANE_VIEW_MESSAGE,
} from '@/modules/setup/types';
import { useRightPane } from '@/modules/setup/hook';

function StatusNavbar() {
  const { t } = useTranslation();
  const { user } = useUser();
  const history = useHistory();
  const { handleLogout, logoutStatusCode } = useLogout();
  const { project, building, floor } = useHomeSelected();
  const { handleChangeShow } = useRightPane();
  const { activeMenuIdx, handleSetMenu } = useHomeMenu();
  const dropdown = useRef<HTMLDivElement>(null);
  const { handleToggle } = useDropdown(dropdown);

  useEffect(() => {
    if (logoutStatusCode) {
      history.replace('/signin');
    }
  }, [logoutStatusCode]);

  const handleClickMyPage = () => {
    handleSetMenu(MENU_IDX_ACCOUNT);
    history.push('/home');
  };

  const handleClickLogout = () => {
    handleLogout();
  };

  const handleChangeLanguage = (language: Languages) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  const handleClickMessage = () => {
    if (activeMenuIdx !== MENU_IDX_MESSAGE) {
      handleChangeShow(true, RIGHT_PANE_VIEW_MESSAGE);
    }
  };

  return (
    <>
      <ul className="top-menu">
        <li>
          <div className="form-group m-0 ml-4 form-lang">
            <select
              className="form-line custom-line"
              defaultValue={localStorage.getItem('language') || 'ko'}
              onChange={(e) =>
                handleChangeLanguage(e.target.value as Languages)
              }
            >
              {languages.map((language) => (
                <option key={language} value={language}>
                  {t(`language_code_${language}`)}
                </option>
              ))}
            </select>
          </div>
        </li>
        {project && building && floor && (
          <li>
            <div className="alert-ico-wrap">
              <a onClick={handleClickMessage}>
                <span className="alert-ico material-icons-outlined">
                  notifications
                </span>
                {/*<span className="alert-num">0</span>*/}
              </a>
            </div>
          </li>
        )}
        <li>
          <Dropdown className={'nav-item'} onToggle={handleToggle}>
            <Dropdown.Toggle
              as={'a'}
              data-caret="false"
              className={'d-flex align-items-center'}
            >
              <span className="avatar avatar-md">
                <ProfileAvatar
                  profileImgUrl={user.profileImgUrl || ''}
                  userName={user?.userName}
                />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu ref={dropdown} align={'right'}>
              <Dropdown.Header>
                <strong>{t('Account')}</strong>
              </Dropdown.Header>
              <Dropdown.Item onClick={handleClickMyPage}>
                {t('프로필')}
              </Dropdown.Item>
              <Dropdown.Item onClick={handleClickLogout}>
                {t('로그아웃')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </>
  );
}

export default StatusNavbar;
