import React from 'react';
import { useUser } from '@/modules/user/hook';
import { useTranslation } from 'react-i18next';
import { Config } from '@/config';
import { CommonUtils } from '@/utils';

type GeofenceEditButtonProps = {
  projectName: string;
  buildingName: string;
  floorName: string;
  floor: number;
  mapId: string;
};

function GeofenceEditButton({
  projectName,
  buildingName,
  floorName,
  floor,
  mapId,
}: GeofenceEditButtonProps) {
  const { t } = useTranslation();
  const { user } = useUser();

  const handleClickButton = () => {
    window.location.href = `watageofenceeditor://?userId=${
      user.userId
    }&serverUrl=${
      Config.space_api.uri
    }&mapId=${mapId}&projectName=${CommonUtils.convertDeeplinkText(
      projectName
    )}&buildingName=${CommonUtils.convertDeeplinkText(
      buildingName
    )}&floorName=${CommonUtils.convertDeeplinkText(
      floorName
    )}&floorValue=${floor}`;
  };

  return (
    <a
      className="btn-ico-sm mr-3"
      onClick={(e) => {
        e.stopPropagation();
        handleClickButton();
      }}
    >
      <span className="icon material-icons-outlined">open_in_browser</span>
      <span className="text">
        <span>{t('앱 편집')}</span>
      </span>
    </a>
  );
}

export default GeofenceEditButton;
