import {
  useHomeBuilding,
  useHomeFloor,
  useHomeProject,
} from '@/modules/home/hook';
import {
  SensorType,
  SENSOR_BLE_TYPE,
  SENSOR_CONNECTION_DISCONNECTED_STATUS,
  SENSOR_CONNECTION_ERROR_STATUS,
  SENSOR_CONNECTION_NORMAL_STATUS,
  SENSOR_ENVIRONMENT_TYPE,
  SENSOR_TEMPERATURE_TYPE,
} from '@/modules/sensor/types';
import { useUser } from '@/modules/user/hook';
import { CommonUtils } from '@/utils';
import React, { ReactElement } from 'react';
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { useTranslation } from 'react-i18next';

type SensorLeftPaneDetailModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  sensor: SensorType;
};

function SensorLeftPaneDetailModal({
  show,
  setShow,
  sensor,
}: SensorLeftPaneDetailModalProps): ReactElement {
  const { t } = useTranslation();

  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const {
    user: { userId },
  } = useUser();

  // 센서 정보 modal에서 수정 버튼 클릭 시
  const openSensorUpdateWindow = () =>
    project?.projectId &&
    building?.mappingId &&
    floor?.mapId &&
    CommonUtils.openSensorUpdateWindow(
      userId,
      project.projectId,
      building.metaId,
      floor.mapId,
      sensor.sensor_serial
    );

  let batteryColor = '';
  switch (sensor.battery_status) {
    case 1:
      batteryColor = 'text-dark';
      break;
    case 2: {
      batteryColor = 'text-red';
      break;
    }
    case 3: {
      batteryColor = 'text-yellow';
      break;
    }
    case 4: {
      batteryColor = 'text-green';
      break;
    }
    case 5: {
      batteryColor = 'text-blue';
      break;
    }
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-dialog-scrollable"
    >
      <ModalHeader>
        <div className="d-flex">
          <span className="material-icons-outlined text-40 mr-2">
            {sensor.sensor_type === SENSOR_ENVIRONMENT_TYPE && 'air'}
            {sensor.sensor_type === SENSOR_TEMPERATURE_TYPE && 'thermostat'}
            {sensor.sensor_type === SENSOR_BLE_TYPE && 'sensors'}
          </span>
          <h4 className="modal-title" id="sdetailItemLabel05">
            {sensor.util_name}
          </h4>
        </div>
        <a className="btn-ico-sm" onClick={openSensorUpdateWindow}>
          <span className="icon material-icons-outlined">open_in_browser</span>
          <span className="text">
            <span>{t('수정')}</span>
          </span>
        </a>
      </ModalHeader>
      <div className="nav tab-menu the-num02" role="tablist">
        <a
          className="btn-style_underline active"
          data-toggle="tab"
          role="tab"
          aria-selected="true"
        >
          <span className="menu-cover">
            <span>{t('센서 정보')}</span>
          </span>
        </a>
        {/* Todo: 기능 개발 이후 진행 */}
        {/* <a
            href="#tab10"
            data-toggle="tab"
            role="tab"
            aria-selected="false"
          >
            <span className="menu-cover">
              <span>히스토리</span>
            </span>
          </a> */}
      </div>
      <ModalBody>
        <div className="tab-content" style={{ height: '550px' }}>
          <div id="tab09" className="tab-pane active" role="tabpanel">
            <div className="mb-4">
              <small className="d-flex text-64 mb-2">{t('센서유형')}</small>
              <p className="m-0">
                {sensor.sensor_type === SENSOR_ENVIRONMENT_TYPE &&
                  t('환경센서')}
                {sensor.sensor_type === SENSOR_TEMPERATURE_TYPE &&
                  t('온습도센서')}
                {sensor.sensor_type === SENSOR_BLE_TYPE && t('측위센서')}
              </p>
            </div>
            <div className="mb-4">
              <small className="d-flex text-64 mb-2">{t('식별값')}</small>
              <p className="m-0">{sensor.sensor_serial}</p>
            </div>
            <div className="mb-4">
              <small className="d-flex text-64 mb-2">{t('상태')}</small>
              {sensor.connection_condition ===
                SENSOR_CONNECTION_NORMAL_STATUS && (
                <p className="m-0">
                  {t('연결')}
                  <span className="ml-2">
                    {sensor.sensor_type === SENSOR_ENVIRONMENT_TYPE &&
                      sensor.sensing1}
                    {sensor.sensor_type === SENSOR_TEMPERATURE_TYPE &&
                      `${sensor.sensing1}\u00b0, ${sensor.sensing2}%`}
                  </span>
                </p>
              )}
              {sensor.connection_condition ===
                SENSOR_CONNECTION_ERROR_STATUS && (
                <p className="m-0 text-yellow">{t('불안정')}</p>
              )}
              {sensor.connection_condition ===
                SENSOR_CONNECTION_DISCONNECTED_STATUS && (
                <p className="m-0 text-40">{t('연결끊김')}</p>
              )}
            </div>
            <div className="mb-4">
              <small className="d-flex text-64 mb-2">{t('배터리')}</small>
              <p className={`m-0 ${batteryColor}`}>{sensor.battery}%</p>
            </div>
            <div className="mb-4">
              <small className="d-flex text-64 mb-2">{t('현재 위치')}</small>
              <p className="m-0">{sensor.area_name}</p>
            </div>
            <div className="mb-4">
              <small className="d-flex text-64 mb-2">{t('수집 시간')}</small>
              {/* Todo: 기능 개발 이후 진행 */}
              <p className="m-0">
                {t('%분 전', {
                  '%': sensor.timediff,
                })}
              </p>
            </div>
            <div className="mb-4">
              <small className="d-flex text-64 mb-2">{t('이벤트')}</small>
              {/* Todo: 기능 개발 이후 진행 */}
              {/* <a href="#n">
                  <small className="badge-basics">환경 개선 필요</small>
                </a> */}
              <p className="m-0">-</p>
            </div>
          </div>
          {/* Todo: 기능 개발 이후 진행 */}
          {/* <div id="tab10" className="tab-pane" role="tabpanel">
              <div className="d-flex justify-content-end">
                <a href="#n" className="btn-ico-sm">
                  <span className="icon material-icons-outlined">timeline</span>
                  <span className="text">
                    <span>{t('지도 보기')}</span>
                  </span>
                </a>
              </div>
              <div className="route-list mb-4">
                <ul className="route-user01">
                  <li className="circle-red">
                    <div className="route-inner">
                      <div className="route-title">
                        <span className="text">8&#176;, 16%</span>
                      </div>
                      <span className="text-64 ml-auto">2022.01.29 12:23</span>
                    </div>
                  </li>
                  <li className="circle-green">
                    <div className="route-inner">
                      <div className="route-title">
                        <span className="text">20&#176;, 20%</span>
                      </div>
                      <span className="text-64 ml-auto">2022.01.29 12:23</span>
                    </div>
                  </li>
                  <li className="circle-dark">
                    <div className="route-inner">
                      <div className="route-title">
                        <span className="text">신호없음</span>
                      </div>
                      <span className="text-64 ml-auto">2022.01.29 12:20</span>
                    </div>
                  </li>
                  <li className="circle-blue">
                    <div className="route-inner">
                      <div className="route-title">
                        <span className="text">23&#176;, 20%</span>
                      </div>
                      <span className="text-64 ml-auto">2022.01.29 12:15</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-light"
          data-dismiss="modal"
          onClick={() => setShow(false)}
        >
          {t('닫기')}
        </button>
        {/* Todo: 기능 개발 이후 진행 */}
        {/* <button type="button" className="btn btn-accent" data-dismiss="modal">
            {t('이벤트 설정')}
          </button> */}
      </ModalFooter>
    </Modal>
  );
}

export default SensorLeftPaneDetailModal;
