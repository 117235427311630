import React, { useState } from 'react';
import {
  PANE_STATUS_EDIT,
  PANE_STATUS_LIST,
  PANE_STATUS_REGISTER,
  PaneStatus,
} from '@/utils/constants/common';
import ProjectFloorPlanList from '@/components/project/ProjectFloorPlanList';
import { ConnectedSpaceInfo } from '@/modules/space/types';
import ProjectFloorPlanRegister from '@/components/project/ProjectFloorPlanRegister';
import { ResponseFloorInfo } from '@/api/space';
import ProjectFloorPlanEdit from '@/components/project/ProjectFloorPlanEdit';

type ProjectFloorPlanProps = {
  loadSpaceInfoList: boolean;
  spaceInfoList: ConnectedSpaceInfo[];
};

function ProjectFloorPlan({
  loadSpaceInfoList,
  spaceInfoList,
}: ProjectFloorPlanProps) {
  const [paneStatus, setPaneStatus] = useState<PaneStatus>(PANE_STATUS_LIST);
  const [
    selectedSpaceInfo,
    setSelectedSpaceInfo,
  ] = useState<ConnectedSpaceInfo | null>(null);
  const [floorInfo, setFloorInfo] = useState<ResponseFloorInfo | null>(null);

  return (
    <>
      {paneStatus === PANE_STATUS_LIST && (
        <ProjectFloorPlanList
          loadSpaceInfoList={loadSpaceInfoList}
          spaceInfoList={spaceInfoList}
          selectedSpaceInfo={selectedSpaceInfo}
          onChangeSpaceInfo={setSelectedSpaceInfo}
          onChangeFloorInfo={setFloorInfo}
          onChangeStatus={setPaneStatus}
        />
      )}
      {paneStatus === PANE_STATUS_REGISTER && (
        <ProjectFloorPlanRegister
          spaceInfo={selectedSpaceInfo}
          onChangeStatus={setPaneStatus}
        />
      )}
      {paneStatus === PANE_STATUS_EDIT && (
        <ProjectFloorPlanEdit
          spaceInfo={selectedSpaceInfo}
          floorInfo={floorInfo}
          onChangeStatus={setPaneStatus}
        />
      )}
    </>
  );
}

export default ProjectFloorPlan;
