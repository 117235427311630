import React, { ReactElement } from 'react';

type ExcelUploadTextInputProps = {
  errorMessage: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

function ExcelUploadTextInput({
  errorMessage,
  value,
  onChange,
}: ExcelUploadTextInputProps): ReactElement {
  return (
    <td>
      <input
        type="text"
        className={`form-line ${errorMessage ? `is-invalid` : ``}`}
        value={value}
        onChange={onChange}
      />
      {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </td>
  );
}

ExcelUploadTextInput.displayName = 'ExcelUploadTextInput';

export default ExcelUploadTextInput;
