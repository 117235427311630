import React, { useEffect, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { Chart } from 'highcharts';
import { useTranslation } from 'react-i18next';
import { useHomeSelected } from '@/modules/home/hook';
import {
  fetchLidarVisitStatus,
  fetchLidarVisitStatusByHour,
} from '@/api/statistics';
import { StatLidarVisitStatusByHour } from '@/modules/statistics/types';

type VisitStatusChartProps = {
  mapId: string;
  period: string[];
};

function VisitStatusChart({ mapId, period }: VisitStatusChartProps) {
  const { t } = useTranslation();
  const { project, building } = useHomeSelected();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [visitStatusByHourList, setVisitStatusByHourList] = useState<
    StatLidarVisitStatusByHour[]
  >([]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (mapId) {
      handleFetchVisitStatusByHour(mapId);
    }
  }, [mapId, period]);

  const handleFetchVisitStatusByHour = async (mapId: string) => {
    project &&
      building &&
      setVisitStatusByHourList(
        await fetchLidarVisitStatusByHour({
          projectId: project.projectId,
          mappingId: building.mappingId,
          mapId,
          searchStartDates: period[0],
          searchEndDates: period[1],
        })
      );
  };

  const handleWindowResize = () => {
    const chartList = Highcharts.charts.filter((chart) => chart);
    chartList.forEach((chart) => {
      if (chart && chartContainerRef.current) {
        chart.setSize(
          chartContainerRef.current.offsetWidth,
          chart.chartHeight,
          false
        );
      }
    });
  };

  return (
    <>
      <h4 className="mb-3">{t('시간대별 방문 횟수')}</h4>
      <div
        className="d-flex align-items-center justify-content-center bg-light"
        style={{
          height: '280px',
        }}
        ref={chartContainerRef}
      >
        {chartContainerRef.current && (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: 'column',
                height: 280,
                events: {
                  load: (event: any) => {
                    if (chartContainerRef.current) {
                      const chart = event.target as Chart;
                      chart.setSize(
                        chartContainerRef.current.offsetWidth,
                        chart.chartHeight,
                        false
                      );
                    }
                  },
                },
              },
              title: null,
              credits: {
                enabled: false,
              },
              plotOptions: {
                series: {
                  animation: false,
                },
                column: {
                  // stacking: 'normal',
                },
              },
              xAxis: {
                categories: visitStatusByHourList.map(({ hour }) => hour),
              },
              yAxis: {
                minRange: 1,
                min: 0,
                title: null,
                // reversedStacks: false,
              },
              tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b>',
              },
              legend: {
                // layout: 'horizontal',
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                floating: false,
              },
              series: [
                {
                  name: t('입장'),
                  data: visitStatusByHourList.map(({ inCount }) => inCount),
                  color: '#3667C6',
                },
                {
                  name: t('퇴장'),
                  data: visitStatusByHourList.map(({ outCount }) => outCount),
                  color: '#F6B306',
                },
              ],
            }}
          />
        )}
      </div>
    </>
  );
}

export default VisitStatusChart;
