import { Alert } from 'react-bootstrap';
import MaterialIcon from '@/components/common/MaterialIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import NewlineText from '@/components/common/NewlineText';

type InvalidAlertProps = {
  messageKey?: string;
  alertContainerPadding?: boolean;
};

function InvalidAlert({
  messageKey = '필수 값을 입력해 주세요',
  alertContainerPadding = true,
}: InvalidAlertProps) {
  const { t } = useTranslation();

  return (
    <Alert
      className={classNames('alert-soft-accent', {
        'invalid-alert': alertContainerPadding,
      })}
    >
      <div className="d-flex flex-wrap align-items-center">
        <div className="mr-8pt">
          <MaterialIcon name={'error_outline'} />
        </div>
        <div className="flex">
          <small className="text-black-100">
            <NewlineText text={t(messageKey)} />
          </small>
        </div>
      </div>
    </Alert>
  );
}

export default InvalidAlert;
