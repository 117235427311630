export const ASSETS_HUMAN_CATEGORY_ID = 'd964356a73af4793a4e050cd040c549e';
export const ASSETS_EQUIPMENT_CATEGORY_ID = '05e2a4d733984b68a16f80505ee738ca';
export const ASSETS_LOGISTICS_CATEGORY_ID = 'b8180deace8a462cb456e29395f95fa7';

// 자산 카테고리 관련 type
export type AssetCategoryType = {
  projectId: string;
  mappingId: string;
  mapId: string;
  fileKey: string;
  fileUrl: string;
  originalFileName: string;
  imgId: string;
  seq: number;
  categoryId: string;
  assetInfoId: string;
  categoryName: string;
  depth: number;
  count: number;
  parentId: string;
  registId: string;
  updateId: string;
  registDate: string;
  updateDate: string;
  activeFlag: boolean;
  deleteFlag: boolean;
  subCategories: AssetCategoryType[];
};

// 자산 관련 type
export type AssetType = {
  projectId: string;
  mappingId: string;
  mapId: string;
  fileKey: string;
  fileUrl: string;
  originalFileName: string;
  imgId: string;
  assetId: string;
  serialId: string;
  assetInfoId: string;
  assetInfoName: string;
  positionGeom: string;
  lat: number;
  lng: number;
  registId: string;
  updateId: string;
  registDate: string;
  updateDate: string;
  activeFlag: boolean;
  deleteFlag: boolean;
  categories: AssetCategoryType[];
};

// 엑셀 등록 관련된 type
export type AssetExcelType = {
  id: number;
  field: number;
  // 자산 유형 관련 에러 메시지
  assetTypeErrorMessage: string;
  // 자산 분류 관련 에러 메시지
  assetClassificationErrorMessage: string;
  // 자산명 관련 에러 메시지
  assetNameErrorMessage: string;
  // 식별값 관련 에러 메시지
  serialIdErrorMessage: string;
} & AssetType;

// 자산 검색 조건 관련 type
export type AssetSearchOptionType = {
  projectId: string;
  mappingId: string;
  mapId: string;
  categoryIdList: string[];
  searchKeyword: string;
  page: number;
};

// 자산 목록 관련 type
export type PagedAssetListType = {
  content: AssetType[];
  totalPages: number;
  totalElements: number;
  number: number;
  numberOfElements: number;
  size: number;
};

// 콤보박스 옵션 관련 type
export type SelectOptionType = {
  id: string;
  name: string;
};

export type CategoryType = {
  categoryId: string;
};

// 엑셀 업로드 시 자산 관련 type
export type AssetExcelUploadType = {
  projectId: string;
  mappingId: string;
  mapId: string;
  assetInfoName: string;
  serialId: string;
  lat: number;
  lng: number;
  categories: CategoryType[];
};

// 자산 업로드 이후 결과 관련 type
export type AssetListRegisterResultType = {
  field: number;
  message: string;
};

// 자산 상태값 관련 type
export type AssetState = {
  // 자산 검색 조건 관련 상태값
  assetSearchOption: AssetSearchOptionType;
  // 조회된 자산 관련 상태값
  pagedAssetList: PagedAssetListType;
};

export const ASSET_SET_SEARCH_OPTION = 'ASSET/SET_SEARCH_OPTION' as const;
export const ASSET_SET_PAGED_ASSET_LIST = 'ASSET/SET_PAGED_ASSET_LIST' as const;

export type Action =
  | {
      type: typeof ASSET_SET_SEARCH_OPTION;
      assetSearchOption: AssetSearchOptionType;
    }
  | {
      type: typeof ASSET_SET_PAGED_ASSET_LIST;
      pagedAssetList: PagedAssetListType;
    };
