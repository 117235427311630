import { Dispatch } from 'react';

export default function createAsyncDispatcher(
  type: string,
  promiseFn: (...rest: Array<any>) => Promise<any>
) {
  const SUCCESS = `${type}_SUCCESS`;
  const ERROR = `${type}_ERROR`;

  return async (dispatch: Dispatch<any>, ...rest: Array<any>) => {
    dispatch({ type });
    try {
      const data = await promiseFn(...rest);
      dispatch({
        type: SUCCESS,
        data,
      });
    } catch (_e) {
      const error = _e as Error;
      dispatch({
        type: ERROR,
        error,
      });
    }
  };
}
