const Config = {
  // platform_api: {
  //   uri: 'https://dev-lms-api.watalbs.com/monitoring',
  // },
  // trace_api: {
  //   uri: 'https://dev-lms-api.watalbs.com/trace',
  // },
  // space_api: {
  //   uri: 'https://dev-lms-api.watalbs.com/wtm',
  // },
  // address_api: {
  //   uri: 'https://dev-lms-api.watalbs.com/wtm',
  //   zip_code_uri: 'https://dev-lms-api.watalbs.com/wtm',
  // },
  // map_server: {
  //   geo_server_uri: 'https://dev-lms-api.watalbs.com',
  //   indoor_edit_uri: 'https://dev-lms.watalbs.com/watta_map',
  //   indoor_edit_host: 'dev-lms-api.watalbs.com',
  // },
  // web_socket_host: 'wss://dev-lms-api.watalbs.com',
  platform_api: {
    uri: 'https://stage-lms-api.watalbs.com/monitoring',
  },
  trace_api: {
    uri: 'https://stage-lms-api.watalbs.com/trace',
  },
  space_api: {
    uri: 'https://stage-lms-api.watalbs.com/wtm',
  },
  address_api: {
    uri: 'https://stage-lms-api.watalbs.com/wtm',
    zip_code_uri: 'https://stage-lms-api.watalbs.com/wtm',
  },
  map_server: {
    geo_server_uri: 'https://stage-lms-api.watalbs.com',
    indoor_edit_uri: 'https://stage-lms.watalbs.com/watta_map',
    indoor_edit_host: 'stage-lms-api.watalbs.com',
  },
  web_socket_host: 'wss://stage-lms-api.watalbs.com',
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : // tslint:disable-next-line:no-shadowed-variable
    T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>;
};

export type BaseConfigType = DeepPartial<typeof Config>;
export default Config;
