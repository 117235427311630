import React from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  EVENT_INFO_SETTING,
  EVENT_RECEIVE_SETTING,
} from '@/modules/event/types';

function EventInfoNav() {
  const { t } = useTranslation();

  return (
    <Nav className="tab-menu the-num02 border-bottom-1">
      <Nav.Link
        eventKey={EVENT_INFO_SETTING}
        style={{
          // TODO: 지워야함
          flex: '0 0 100%',
          maxWidth: '100%',
        }}
        className="btn-style_underline"
      >
        <span className="menu-cover">
          <span>{t('알림 정보')}</span>
        </span>
      </Nav.Link>
      {/*
      <Nav.Link eventKey={EVENT_RECEIVE_SETTING}>
        <span className="menu-cover">
          <span>{t('수신 설정')}</span>
        </span>
      </Nav.Link>
      */}
    </Nav>
  );
}

export default EventInfoNav;
