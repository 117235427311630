import React, { ReactNode } from 'react';

type MainContainerProps = {
  children?: ReactNode;
};

function MainContainer({ children }: MainContainerProps) {
  return (
    <div className={'h-100'}>
      <div className="pl-container">{children}</div>
    </div>
  );
}

export default MainContainer;
