import { StatusCodes } from 'http-status-codes';

// 센서 타입 별 필터 적용을 위한 상수
export const SENSOR_TEMPERATURE_TYPE = 'cel_hum';
export const SENSOR_ENVIRONMENT_TYPE = 'environment';
export const SENSOR_BLE_TYPE = 'ble';
export const SENSOR_ALL_TYPE = 'all';

// 센서 분류 관련 type
export type SensorClassificationType =
  | typeof SENSOR_TEMPERATURE_TYPE
  | typeof SENSOR_ENVIRONMENT_TYPE
  | typeof SENSOR_BLE_TYPE
  | typeof SENSOR_ALL_TYPE;

// 센서 연결 상태
export const SENSOR_CONNECTION_NORMAL_STATUS = 'normal';
export const SENSOR_CONNECTION_ERROR_STATUS = 'comm_error';
export const SENSOR_CONNECTION_DISCONNECTED_STATUS = 'disconnected';

// 센서 연결 상태 관련 type
export type SensorConnectionType =
  | typeof SENSOR_CONNECTION_NORMAL_STATUS
  | typeof SENSOR_CONNECTION_ERROR_STATUS
  | typeof SENSOR_CONNECTION_DISCONNECTED_STATUS;

export type SensorType = {
  sensor_type: SensorClassificationType | string;
  sensor_serial: string;
  util_name: string;
  battery: string;
  battery_status: number;
  sensing1: string;
  sensing2: string;
  timediff: number;
  connection_condition: SensorConnectionType;
  area_name: string;
  geomstr: string;
};

// 센서 검색 조건 관련 type
export type SensorSearchOptionType = {
  mapId: string;
  searchKeyword: string;
  sensorClassificationTypeList: SensorClassificationType[];
  // pageNumber: number;
  // pagesize: number;
};

// 센서 상태 관련 type
export type SensorStatusType = {
  sensor_type: string;
  sensor_serial: string;
  util_name: string;
  battery: string;
  battery_status: number;
  map_id: string;
  timediff: number;
  connection_condition: string;
  area_name: string;
  geomstr: string;
};

// 조회된 센서 관련 type
export type PagedSensorListType = {
  status: StatusCodes;
  sensorList: SensorType[];
  totCnt: number;
  totPageCnt: number;
  pagesize: number;
  nowpage: number;
  cel_hum_cnt: number;
  environment_cnt: number;
  ble_cnt: number;
};

// 센서 상태값 관련 type
export type SensorState = {
  sensorSearchOption: SensorSearchOptionType;
  pagedSensorList: PagedSensorListType;
  selectedSensor: string;
};

export const SENSOR_SET_SEARCH_OPTION = 'SENSOR/SET_SEARCH_OPTION' as const;
export const SENSOR_SET_PAGED_SENSOR_LIST = 'SENSOR/SET_PAGED_SENSOR_LIST' as const;
export const SENSOR_SET_SELECTED_SENSOR = 'SENSOR/SENSOR_SET_SELECTED_SENSOR' as const;

export type Action =
  | {
      type: typeof SENSOR_SET_SEARCH_OPTION;
      sensorSearchOption: SensorSearchOptionType;
    }
  | {
      type: typeof SENSOR_SET_PAGED_SENSOR_LIST;
      pagedSensorList: PagedSensorListType;
    }
  | {
      type: typeof SENSOR_SET_SELECTED_SENSOR;
      selectedSensor: string;
    };
