import { useCallback, useContext, useEffect, useRef } from 'react';
import {
  OpenLayersDispatchContext,
  OpenLayersStateContext,
} from '@/modules/openlayers/context';
import {
  DrawResource,
  OPEN_LAYERS_SET_DRAW_INTERACTION,
  OPEN_LAYERS_SET_DRAW_RESOURCE,
  OPEN_LAYERS_SET_GEOFENCE_RESOURCE,
  OPEN_LAYERS_SET_INIT_STATE,
  OPEN_LAYERS_SET_MAP,
  OPEN_LAYERS_SET_MONITORING_LIDAR_RESOURCE,
} from '@/modules/openlayers/types';
import { Feature, Map } from 'ol';
import GeometryType from 'ol/geom/GeometryType';
import { useFloorObject } from '@/modules/floor/hook';
import { EventsKey } from 'ol/events';
import { unByKey } from 'ol/Observable';
import { Polygon } from 'ol/geom';
import * as turf from '@turf/turf';
import VectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { asArray } from 'ol/color';
import { GeofenceInfo } from '@/modules/floor/types';

function useOpenLayersState() {
  const state = useContext(OpenLayersStateContext);
  if (!state) throw new Error('OpenLayersProvider not found');
  return state;
}

function useOpenLayersDispatch() {
  const dispatch = useContext(OpenLayersDispatchContext);
  if (!dispatch) throw new Error('OpenLayersProvider not found');
  return dispatch;
}

export function useOpenLayersResource() {
  const { map } = useOpenLayersState();
  const dispatch = useOpenLayersDispatch();

  const handleSetMap = (map: Map | null) => {
    dispatch({
      type: OPEN_LAYERS_SET_MAP,
      map,
    });
  };

  const handleSetDrawResource = (drawResource: DrawResource) => {
    dispatch({
      type: OPEN_LAYERS_SET_DRAW_RESOURCE,
      drawResource,
    });
  };

  const handleSetGeofenceResource = (layer: VectorLayer) => {
    dispatch({
      type: OPEN_LAYERS_SET_GEOFENCE_RESOURCE,
      layer,
    });
  };

  const handleSetMonitoringLidarResource = (personLayer: VectorLayer) => {
    dispatch({
      type: OPEN_LAYERS_SET_MONITORING_LIDAR_RESOURCE,
      personLayer,
    });
  };

  const handleInitResource = () => {
    dispatch({
      type: OPEN_LAYERS_SET_INIT_STATE,
    });
  };

  return {
    map,
    handleSetMap,
    handleSetDrawResource,
    handleSetGeofenceResource,
    handleSetMonitoringLidarResource,
    handleInitResource,
  };
}

export function useOpenLayersDraw() {
  const { map, draw } = useOpenLayersState();
  const dispatch = useOpenLayersDispatch();
  const { areaList } = useFloorObject();
  const selectEventsKeyRef = useRef<EventsKey | null>(null);
  const drawEndRef = useRef(true);

  useEffect(() => {
    return () => {
      handleDrawCancel();
    };
  }, [draw?.interaction]);

  const handleDrawGeometry = useCallback(
    (
      geometryType: GeometryType,
      callback?: (feature: Feature | null) => void
    ) => {
      if (draw.resource) {
        handleDrawCancel();
        const drawInteraction = draw.resource.drawGeometry(geometryType);
        drawEndRef.current = false;
        drawInteraction.on('drawend', (e) => {
          drawEndRef.current = true;
          handleDrawCancel(drawInteraction);
          callback?.call(null, e.feature);
        });
        dispatch({
          type: OPEN_LAYERS_SET_DRAW_INTERACTION,
          drawInteraction,
        });
        map?.addInteraction(drawInteraction);
        document.addEventListener(
          'keyup',
          (e) => {
            if (!drawEndRef.current && e.key === 'Escape') {
              handleDrawCancel(drawInteraction);
              callback?.call(null, null);
            }
          },
          {
            once: true,
          }
        );
      }
    },
    [map, draw.resource]
  );

  const handleDrawCancel = useCallback(
    (interaction = draw.interaction) => {
      handleUnByKeySelectEventsKey();
      draw.resource?.drawLayer.getSource().clear();
      interaction && map?.removeInteraction(interaction);
    },
    [draw.resource, draw.interaction]
  );

  const handleUnByKeySelectEventsKey = () => {
    if (selectEventsKeyRef.current) {
      unByKey(selectEventsKeyRef.current);
      selectEventsKeyRef.current = null;
    }
  };

  const handleDrawAreaFeature = useCallback(
    (callback?: (feature: Feature | null) => void) => {
      handleDrawCancel();
      selectEventsKeyRef.current = map?.once('click', (e) => {
        let insideFeature: Feature | null = null;
        const coordinate = e.coordinate;
        areaList.some(({ feature: areaFeature }) => {
          if (areaFeature) {
            const geometry = areaFeature.getGeometry();
            const polygonGeometry = geometry as Polygon;
            const polygon = turf.polygon(polygonGeometry.getCoordinates());
            const clickedPoint = turf.point(coordinate);
            if (turf.inside(clickedPoint, polygon)) {
              setTimeout(() => {
                draw.resource?.drawLayer.getSource().addFeature(areaFeature);
              });
              insideFeature = areaFeature;
              return true;
            }
          }
        });
        selectEventsKeyRef.current = null;
        callback?.call(null, insideFeature);
      }) as EventsKey;
    },
    []
  );

  return {
    drawLayer: draw.resource?.drawLayer,
    handleDrawGeometry,
    handleDrawCancel,
    handleDrawAreaFeature,
  };
}

export function useOpenLayersGeofence() {
  const { geofence } = useOpenLayersState();

  const getGeofenceStyle = useCallback(
    (feature: Feature, selected: boolean) => {
      const item = feature.get('geofence_info');
      if (item) {
        const geofenceInfo = item as GeofenceInfo;
        const lineColor = asArray(geofenceInfo.line_color);
        const areaColor = asArray(geofenceInfo.area_color);
        return new Style({
          stroke: new Stroke({
            lineDash: selected ? undefined : [10],
            color: `rgba(${lineColor[0]}, ${lineColor[1]}, ${
              lineColor[2]
            }, ${0.8})`,
          }),
          fill: new Fill({
            color: `rgba(${areaColor[0]}, ${areaColor[1]}, ${areaColor[2]}, ${
              selected ? 0.4 : 0.1
            })`,
          }),
        });
      } else {
        return new Style({
          stroke: new Stroke({
            lineDash: selected ? undefined : [10],
            color: 'rgba(174, 5, 233, 0.8)',
          }),
          fill: new Fill({
            color: `rgba(174, 5, 233, ${selected ? 0.4 : 0.1})`,
          }),
        });
      }
    },
    []
  );

  return {
    geofence,
    getGeofenceStyle,
  };
}

export function useOpenLayersEvent(): {
  getCampaignStyle: (selected: boolean) => Style;
  getAccidentStyle: (selected: boolean) => Style;
  getEndedEventStyle: (selected: boolean) => Style;
} {
  const getCampaignStyle = useCallback((selected: boolean) => {
    return new Style({
      stroke: new Stroke({
        lineDash: selected ? undefined : [10],
        color: selected ? 'rgba(35, 191, 51, 1)' : 'rgba(35, 191, 51, 1)',
      }),
      fill: new Fill({
        color: selected ? `rgba(35, 191, 51, 0.24)` : `rgba(35, 191, 51, 0.08)`,
      }),
    });
  }, []);

  const getAccidentStyle = useCallback((selected: boolean) => {
    return new Style({
      stroke: new Stroke({
        lineDash: selected ? undefined : [10],
        width: selected ? 3 : 1,
        color: 'rgba(232, 59, 39, 0.8)',
      }),
      fill: new Fill({
        color: 'rgba(232, 59, 39, 0.24)',
      }),
    });
  }, []);

  const getEndedEventStyle = useCallback(
    (selected: boolean) =>
      new Style({
        stroke: new Stroke({
          width: 2,
          color: selected ? 'rgba(174, 174, 174, 1)' : 'transparent',
        }),
        fill: new Fill({
          color: selected ? `rgba(174, 174, 174, 0.24)` : `transparent`,
        }),
      }),
    []
  );

  return {
    getCampaignStyle,
    getAccidentStyle,
    getEndedEventStyle,
  };
}

export function useOpenLayersArea() {
  const getAreaStyle = useCallback(() => {
    return new Style({
      stroke: new Stroke({
        color: 'rgba(0, 178, 255, 0.8)',
      }),
      fill: new Fill({
        color: `rgba(0, 178, 255, 0.32)`,
      }),
    });
  }, []);

  return {
    getAreaStyle,
  };
}

export function useOpenLayersForklift() {
  const getSpeedTextStyle = useCallback((isSpeeding: boolean) => {
    return isSpeeding ? '#E83B3B' : '#333333';
  }, []);

  const getCircleStyle = useCallback((isLeave: boolean) => {
    return new Style({
      fill: new Fill({
        color: isLeave ? 'rgba(232, 59, 59, 0.24)' : 'rgba(50, 161, 255, 0.16)',
      }),
      stroke: new Stroke({
        color: isLeave ? 'rgba(232, 59, 59, 0.8)' : 'rgba(50, 161, 255, 0.64)',
        width: isLeave ? 2 : 1,
      }),
    });
  }, []);

  return {
    getSpeedTextStyle,
    getCircleStyle,
  };
}

export function useOpenLayersMonitoring() {
  const {
    monitoring: { lidar },
  } = useOpenLayersState();

  return {
    lidar,
  };
}
