import { useTranslation } from 'react-i18next';
import { useMonitoringLidar } from '@/modules/monitoring/hook';
import React, { useEffect, useState } from 'react';
import {
  MonitoringLidar,
  MonitoringLidarCounting,
} from '@/modules/monitoring/types';
import { Polygon } from 'ol/geom';
import * as turf from '@turf/turf';
import AlertModal from '@/components/common/modal/AlertModal';
import { Feature } from 'ol';

function MonitoringLidarRealtimeStatus({
  className = 'cover',
  objectFeatureFilterList,
}: {
  className?: string;
  objectFeatureFilterList: Feature[];
}) {
  const { t } = useTranslation();
  const { lidarObjectList } = useMonitoringLidar();
  const [isRefresh, setRefresh] = useState<undefined | boolean>(undefined);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [lidarCounting, setLidarCounting] = useState<MonitoringLidarCounting>({
    CAR: 0,
    CYCLE: 0,
    PED: 0,
    FORKLIFT: 0,
    tagTotalCount: 0,
  });

  useEffect(() => {
    if (typeof isRefresh !== 'undefined') {
      //
    }
  }, [isRefresh]);

  useEffect(() => {
    let lidarCounting: MonitoringLidarCounting = {
      PED: 0,
      CAR: 0,
      CYCLE: 0,
      FORKLIFT: 0,
      tagTotalCount: 0,
    };

    if (lidarObjectList.length) {
      lidarCounting = {
        PED: handleFilterLidarObject(
          lidarObjectList.filter(({ lidarType }) => lidarType === 'PED')
        ),
        CAR: lidarObjectList.filter(({ lidarType }) => lidarType === 'CAR')
          .length,
        CYCLE: lidarObjectList.filter(({ lidarType }) => lidarType === 'CYCLE')
          .length,
        FORKLIFT: lidarObjectList.filter(
          ({ lidarType }) => lidarType === 'FORKLIFT'
        ).length,
        tagTotalCount: lidarObjectList.length,
      };
    }

    setLidarCounting(lidarCounting);
  }, [objectFeatureFilterList, lidarObjectList]);

  const handleFilterLidarObject = (lidarPersonList: MonitoringLidar[]) => {
    let filterValue = !objectFeatureFilterList.length
      ? lidarPersonList.length
      : 0;

    objectFeatureFilterList.forEach((objectFeature) => {
      const polygonGeometry = objectFeature.getGeometry() as Polygon;
      const turfPolygon = turf.polygon(polygonGeometry.getCoordinates());
      lidarPersonList.forEach(({ lidarType, lng, lat }) => {
        if (lidarType === 'PED') {
          const point = turf.point([lng, lat]);
          if (turf.inside(point, turfPolygon)) {
            filterValue++;
          }
        }
      });
    });

    return filterValue;
  };

  return (
    <>
      <div className={className}>
        <div className="d-flex align-items-center mb-3">
          <h4 className="m-0">{t('실시간 현황')}</h4>
          {/*
          <a
            className="btn-refresh"
            onClick={() => {
              if (typeof isRefresh === 'undefined') {
                setRefresh(false);
              } else {
                setRefresh(!isRefresh);
              }
            }}
          >
            <MaterialIcon name={'refresh'} outlined={true} />
          </a>
          */}
        </div>
        <ul className="list-row column-1 w-100 m-0 py-3 border-bottom-1">
          <li className="w-100">
            <p className="mb-2 text-64">{t('현재')}</p>
            <strong className="font-size-16pt">{lidarCounting.PED}</strong>
          </li>
        </ul>
        <ul className="list-row column-2 m-0 py-3 border-bottom-1">
          <li className="border-right-1">
            <p className="mb-2 text-64">{t('사람')}</p>
            <strong className="font-size-16pt">{lidarCounting.PED}</strong>
          </li>
          <li>
            <p className="mb-2 text-64">{t('지게차')}</p>
            <strong className="font-size-16pt">{lidarCounting.FORKLIFT}</strong>
          </li>
        </ul>
        <ul className="list-row column-2 m-0 py-3">
          <li className="border-right-1">
            <p className="mb-2 text-64">{t('자동차')}</p>
            <strong className="font-size-16pt">{lidarCounting.CAR}</strong>
          </li>
          <li>
            <p className="mb-2 text-64">{t('오토바이')}</p>
            <strong className="font-size-16pt">{lidarCounting.CYCLE}</strong>
          </li>
        </ul>
      </div>
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {t('서버와 통신 중 오류가 발생했습니다')}
      </AlertModal>
    </>
  );
}

export default MonitoringLidarRealtimeStatus;
