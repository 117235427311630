import React, { useState } from 'react';
import { useProjectRegister } from '@/modules/project/hook';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import AlertModal from '@/components/common/modal/AlertModal';
import NewlineText from '@/components/common/NewlineText';
import { ScrollVisibilityApiType, useDrag } from '@/modules/common';
import HorizontalScroll from '@/components/common/HorizontalScroll';

function ProjectRegisterHeader() {
  const { registerStep } = useProjectRegister();
  const { dragStop } = useDrag();
  const [itemList] = useState<
    {
      step: number;
      textKey: string;
    }[]
  >([
    {
      step: 1,
      textKey: '프로젝트 정보',
    },
    {
      step: 2,
      textKey: '건물 등록',
    },
    {
      step: 3,
      textKey: '평면도 등록',
    },
    {
      step: 4,
      textKey: '자산 등록',
    },
  ]);

  const handleMoveHorizontalScroll = (apiObj: ScrollVisibilityApiType) => {
    apiObj.scrollToItem(apiObj.getItemById(String(registerStep)), 'auto');
  };

  return (
    <>
      <div
        className={classNames('row-tab', {
          'mb-4': registerStep === 1,
        })}
        onMouseLeave={dragStop}
      >
        <ul className="list-unstyled pr-0">
          <HorizontalScroll onInit={handleMoveHorizontalScroll}>
            {itemList.map(({ step, textKey }) => (
              <RegisterItem
                key={step}
                itemId={step.toString()}
                step={step}
                textKey={textKey}
              />
            ))}
          </HorizontalScroll>
        </ul>
      </div>
    </>
  );
}

type RegisterItemProps = {
  itemId: string;
  step: number;
  textKey: string;
};

function RegisterItem({ step, textKey }: RegisterItemProps) {
  const { t } = useTranslation();
  const {
    projectInfo: { projectId },
    registerStep,
    handleChangeRegisterStep,
  } = useProjectRegister();
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleClickRegisterCategory = (step: number) => {
    if (!projectId) {
      setShowAlertModal(true);
      return;
    }

    handleChangeRegisterStep(step);
  };

  return (
    <>
      <li
        className={classNames({
          active: registerStep === step,
        })}
      >
        <a onClick={() => handleClickRegisterCategory(step)}>{t(textKey)}</a>
      </li>
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        <NewlineText
          text={t('프로젝트 정보가 필요합니다.\r\n저장 후 시도해 주세요.')}
        />
      </AlertModal>
    </>
  );
}

export default React.memo(ProjectRegisterHeader);
