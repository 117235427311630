import { useCallback, useContext, useEffect } from 'react';
import {
  FloorDispatchContext,
  FloorStateContext,
} from '@/modules/floor/context';
import {
  FLOOR_SET_AREA_LIST,
  FLOOR_SET_GEOFENCE_LIST,
} from '@/modules/floor/types';
import { useHomeFloor } from '@/modules/home/hook';
import { fetchArea, fetchGeofence } from '@/api/space';

function useFloorState() {
  const state = useContext(FloorStateContext);
  if (!state) throw new Error('FloorProvider not found');
  return state;
}

function useFloorDispatch() {
  const dispatch = useContext(FloorDispatchContext);
  if (!dispatch) throw new Error('FloorProvider not found');
  return dispatch;
}

export function useFloorObject() {
  const { areaList, geofenceList } = useFloorState();

  return {
    areaList,
    geofenceList,
  };
}

export function useFetchFloorObject() {
  const { floor } = useHomeFloor();
  const dispatch = useFloorDispatch();

  useEffect(() => {
    handleSetAreaList();
    handleSetGeofenceList();
  }, [floor]);

  const handleSetAreaList = async () => {
    floor &&
      dispatch({
        type: FLOOR_SET_AREA_LIST,
        areaList: await fetchArea({ mapid: floor.mapId }),
      });
  };

  const handleSetGeofenceList = async () => {
    floor &&
      dispatch({
        type: FLOOR_SET_GEOFENCE_LIST,
        geofenceList: await fetchGeofence({ mapid: floor.mapId }),
      });
  };
}
