import React, { ReactElement } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { ScrollVisibilityApiType, useDrag } from '@/modules/common';

type HorizontalScrollProps = {
  children: ReactElement[];
  onInit?: (apiObj: ScrollVisibilityApiType) => void;
};

function HorizontalScroll({ children, onInit }: HorizontalScrollProps) {
  const { dragStart, dragStop, dragMove } = useDrag();

  const handleDrag = ({ scrollContainer }: ScrollVisibilityApiType) => (
    ev: React.MouseEvent
  ) =>
    dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });

  return (
    <ScrollMenu
      onWheel={(apiObj: ScrollVisibilityApiType, ev: React.WheelEvent) => {
        const isTouchpad =
          Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

        if (isTouchpad) {
          ev.stopPropagation();
          return;
        }

        if (ev.deltaY < 0) {
          apiObj.scrollNext();
        } else if (ev.deltaY > 0) {
          apiObj.scrollPrev();
        }
      }}
      onMouseDown={() => dragStart}
      onMouseUp={() => dragStop}
      onMouseMove={handleDrag}
      onInit={(apiObj: ScrollVisibilityApiType) => {
        onInit?.call(null, apiObj);
      }}
    >
      {children}
    </ScrollMenu>
  );
}

export default HorizontalScroll;
