import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import HomeMessageDetailInfo from '@/components/home/Message/Detail/Info';
import { fetchMessage } from '@/api/message';
import { useHomeProject } from '@/modules/home/hook';
import { MessageHistoryDetail } from '@/modules/message/types';
import AlertModal from '@/components/common/modal/AlertModal';
import Preloader from '@/components/common/Preloader';

type HomeMessageDetailProps = {
  messageId: string;
  onClickList: () => void;
};

function HomeMessageDetail({ messageId, onClickList }: HomeMessageDetailProps) {
  const { t } = useTranslation();
  const { project } = useHomeProject();
  const [
    messageDetail,
    setMessageDetail,
  ] = useState<MessageHistoryDetail | null>(null);
  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    if (project && messageId) {
      handleFetchMessage(project.projectId, messageId);
    }
  }, [project, messageId]);

  const handleFetchMessage = async (projectId: string, messageId: string) => {
    const data = await fetchMessage(
      'c2463a68a460408685f0f5f606be8593',
      messageId
    );
    if (data) {
      setMessageDetail(data);
    } else {
      setShowAlertModal(true);
    }
  };

  return (
    <>
      {messageDetail ? (
        <>
          <div className="d-flex flex-row align-items-center mb-4">
            <div className="flex">
              <h3
                className={classNames('d-flex align-items-center mb-0', {
                  'text-identity':
                    messageDetail.lidarEvent.eventType !== 'IN' &&
                    messageDetail.lidarEvent.eventType !== 'OUT',
                })}
              >
                {messageDetail.messageTitle}
              </h3>
            </div>
          </div>
          <HomeMessageDetailInfo messageDetail={messageDetail} />
          <div className="d-flex justify-content-center">
            <a
              onClick={onClickList}
              className="btn btn-accent font-size-14pt py-3 px-5"
            >
              {t('목록')}
            </a>
          </div>
        </>
      ) : (
        <Preloader />
      )}
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {t('서버와 통신 중 오류가 발생했습니다')}
      </AlertModal>
    </>
  );
}

export default HomeMessageDetail;
