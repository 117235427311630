import React, { useState } from 'react';
import { useUser } from '@/modules/user/hook';
import ProfileAvatar from '@/components/common/ProfileAvatar';
import { useTranslation } from 'react-i18next';
import HomeAccountProfile from '@/components/home/HomeAccountProfile';
import classNames from 'classnames';
import HomeAccountPasswordConfirmModal from '@/components/home/HomeAccountPasswordConfirmModal';
import HomeAccountPasswordEdit from '@/components/home/HomeAccountPasswordEdit';

function HomeAccount() {
  const { t } = useTranslation();
  const {
    user: { userName, profileImgUrl },
  } = useUser();
  const [viewType, setViewType] = useState<'profile' | 'password'>('profile');
  const [showPasswordConfirmModal, setShowPasswordConfirmModal] = useState(
    false
  );
  const [password, setPassword] = useState('');

  const handleMatchedPassword = (inputPassword: string) => {
    setPassword(inputPassword);
    setViewType('password');
  };

  return (
    <>
      <div className="contents-section account-wrap">
        <div className="cell">
          <div className="row">
            <div className="col-md-4 d-flex flex-column p-5">
              <div className="avatar-box">
                <div className="avatar avatar-xxxl mb-4">
                  <ProfileAvatar
                    profileImgUrl={profileImgUrl || ''}
                    userName={userName}
                  />
                </div>
              </div>
              <div className="nav-profile">
                <div className="d-inline-flex flex-column">
                  <a
                    className={classNames({
                      active: viewType === 'profile',
                    })}
                    onClick={() => setViewType('profile')}
                  >
                    {t('내 프로필')}
                  </a>
                  <a
                    className={classNames({
                      active: viewType === 'password',
                    })}
                    onClick={() => {
                      setPassword('');
                      setShowPasswordConfirmModal(true);
                    }}
                  >
                    {t('비밀번호 변경')}
                  </a>
                </div>
              </div>
            </div>
            {viewType === 'profile' ? (
              <HomeAccountProfile />
            ) : (
              <HomeAccountPasswordEdit
                password={password}
                onSuccess={() => setViewType('profile')}
              />
            )}
          </div>
        </div>
      </div>
      {showPasswordConfirmModal && (
        <HomeAccountPasswordConfirmModal
          show={true}
          onHide={() => setShowPasswordConfirmModal(false)}
          onMatchedPassword={handleMatchedPassword}
        />
      )}
    </>
  );
}

export default HomeAccount;
