import React, { useEffect, useState } from 'react';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import { ModalProps } from '@/modules/common';
import { useTranslation } from 'react-i18next';
import CampaignInfoForm from '@/components/campaign/CampaignInfoForm';
import { Campaign, CampaignInput } from '@/modules/campaign/types';
import moment from 'moment';
import AlertModal from '@/components/common/modal/AlertModal';
import InvalidAlert from '@/components/InvalidAlert';
import { fetchCampaign, putCampaign, RequestPutCampaign } from '@/api/campaign';
import { useHomeSelected } from '@/modules/home/hook';
import {
  GEOMETRY_TARGET_TYPE_INNER,
  GEOMETRY_TYPE_CIRCLE,
} from '@/utils/constants/common';
import { Feature } from 'ol';
import { Circle } from 'ol/geom';
import { useOpenLayersDraw } from '@/modules/openlayers/hook';

type CampaignEditModalProps = ModalProps & {
  campaign: Campaign;
  feature: Feature;
  onChangeFeature: () => void;
};

function CampaignEditModal({
  campaign: {
    eventId,
    eventTitle,
    startDate,
    endDate,
    eventContent,
    imgId,
    areaId,
    linkUrl,
  },
  feature,
  onChangeFeature,
  onHide,
  onReload,
}: CampaignEditModalProps) {
  const { t } = useTranslation();
  const { project, building, floor } = useHomeSelected();
  const { drawLayer } = useOpenLayersDraw();
  const [innerShow, setInnerShow] = useState(true);
  const [campaignInput, setCampaignInput] = useState<CampaignInput>({
    title: '',
    period: [
      moment().format('YYYY-MM-DD'),
      moment().add(7, 'days').format('YYYY-MM-DD'),
    ],
    content: '',
    imgId: '',
    zoneId: '',
    campaignType: linkUrl ? 'url' : 'message',
    linkUrl: '',
    originalFileName: '',
  });
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    const source = drawLayer?.getSource();
    if (source) {
      !source.hasFeature(feature) && source.addFeature(feature);
      setInnerShow(true);
    }
  }, [feature]);

  useEffect(() => {
    if (eventId && imgId) {
      handleFetchCampaign();
    } else {
      setCampaignInput({
        ...campaignInput,
        title: eventTitle,
        period: [
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD'),
        ],
        content: eventContent,
        zoneId: areaId,
        linkUrl,
      });
    }
  }, [eventId, imgId]);

  const handleFetchCampaign = async () => {
    const data = await fetchCampaign(eventId);
    data
      ? setCampaignInput({
          ...campaignInput,
          title: eventTitle,
          period: [
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
          ],
          content: eventContent,
          zoneId: areaId,
          linkUrl,
          imgId,
          originalFileName: data.originalFileName,
        })
      : setShowAlertModal(true);
  };

  const handleClickSubmit = async () => {
    setShowInvalidMessage(false);
    const {
      campaignType,
      title,
      content,
      period,
      imgId,
      zoneId,
      linkUrl,
    } = campaignInput;

    let isValid = false;
    if (campaignType === 'message') {
      isValid = Boolean(title && content);
    } else if (campaignType === 'url') {
      isValid = Boolean(title && linkUrl);
    }

    if (!isValid) {
      setShowInvalidMessage(true);
      return;
    }

    let result = false;
    if (project && building && floor) {
      const circleGeometry = feature.getGeometry() as Circle;
      const [lng, lat] = circleGeometry.getCenter();
      const requestPutEvent: RequestPutCampaign = {
        startDate: moment(period[0]).format('yyyy-MM-DDTHH:mm:ss'),
        endDate: moment(period[1]).format('yyyy-MM-DDTHH:mm:ss'),
        eventTitle: title,
        eventSubject: title,
        eventContent: content,
        imgId,
        targetGeomType: GEOMETRY_TARGET_TYPE_INNER,
        geomType: GEOMETRY_TYPE_CIRCLE,
        lng,
        lat,
        radius: circleGeometry.getRadius(),
        linkUrl,
      };

      result = await putCampaign(eventId, requestPutEvent);
    }

    if (result) {
      onReload?.call(null);
      onHide();
    } else {
      setShowAlertModal(true);
    }
  };

  return (
    <>
      <Modal
        show={innerShow}
        onHide={() => {
          //
        }}
        dialogClassName="modal-dialog-scrollable"
      >
        <ModalHeader>
          <ModalTitle as={'h4'}>{t('캠페인 수정')}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <CampaignInfoForm
            campaignInput={campaignInput}
            feature={feature}
            onChangeCampaignInput={setCampaignInput}
            onChangeFeature={() => {
              setInnerShow(false);
              onChangeFeature();
            }}
          />
        </ModalBody>
        {showInvalidMessage && (
          <InvalidAlert messageKey={'필수 값을 입력해 주세요'} />
        )}
        <ModalFooter>
          <button type="button" className="btn btn-light" onClick={onHide}>
            {t('닫기')}
          </button>
          <button
            type="button"
            className="btn btn-accent"
            onClick={handleClickSubmit}
          >
            {t('수정')}
          </button>
        </ModalFooter>
      </Modal>
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {t('서버와 통신 중 오류가 발생했습니다')}
      </AlertModal>
    </>
  );
}

export default CampaignEditModal;
