import { usePagedAssetList } from '@/modules/assets/hook';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AssetLeftPaneDetailModal from './AssetLeftPaneDetailModal';
import AssetLeftPaneTableRow from './AssetLeftPaneTableRow';
import TableEmptyRow from '@/components/common/TableEmptyRow';
import Preloader from '@/components/common/Preloader';

type AssetLeftPaneTableProps = {
  loading: boolean;
};

function AssetLeftPaneTable({
  loading,
}: AssetLeftPaneTableProps): ReactElement {
  const { t } = useTranslation();
  const {
    pagedAssetList: { content: assetList },
  } = usePagedAssetList();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [assetInfoId, setAssetInfoId] = useState<string>('');

  return (
    <>
      <div className="table-bumper">
        <table className="table table-high table-line-none table-sticky mb-0">
          <colgroup>
            <col width="30%" />
            <col width="50%" />
            <col width="20%" />
          </colgroup>
          <thead>
            <tr>
              <th style={{ width: '30%' }} className="text-center">
                {t('자산ID')}
              </th>
              <th className="text-center" style={{ width: '50%' }}>
                {t('자산명')}
              </th>
              <th style={{ width: '20%' }} className="text-center">
                {t('상태')}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={3}>
                  <Preloader />
                </td>
              </tr>
            ) : (
              <>
                {assetList.length ? (
                  assetList.map((asset) => (
                    <AssetLeftPaneTableRow
                      key={asset.assetInfoId}
                      asset={asset}
                      setShowModal={setShowModal}
                      setAssetInfoId={setAssetInfoId}
                    />
                  ))
                ) : (
                  <TableEmptyRow
                    colSpan={3}
                    textKey={t('등록된 자산이 없습니다.')}
                  />
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {showModal && (
        <AssetLeftPaneDetailModal
          show={true}
          setShow={setShowModal}
          assetInfoId={assetInfoId}
        />
      )}
    </>
  );
}

export default AssetLeftPaneTable;
