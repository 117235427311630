import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type FormLabelProps = {
  textKey: string;
  essential?: boolean;
  htmlFor?: string;
  className?: string;
  fontSizeApply?: boolean;
  fontSize?: number;
};

function FormLabel({
  textKey,
  essential,
  htmlFor,
  className,
  fontSizeApply = true,
  fontSize = 50,
}: FormLabelProps) {
  const { t } = useTranslation();

  return (
    <label
      htmlFor={htmlFor}
      className={classNames('form-label', className, {
        essential: essential,
        [`text-${fontSize}`]: fontSizeApply,
      })}
    >
      {t(textKey)}
    </label>
  );
}

export default FormLabel;
