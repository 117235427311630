import React, { useState } from 'react';
import { MonitoringAppEndPointUser } from '@/modules/monitoring/types';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import { ModalProps } from '@/modules/common';
import { useTranslation } from 'react-i18next';
import MonitoringMessageSendModal from '@/components/monitoring/MonitoringMessageSendModal';
import MaterialIcon from '@/components/common/MaterialIcon';

type MonitoringAppEndPointUserDetailModalProps = ModalProps & {
  appEndPointUser: MonitoringAppEndPointUser;
};

function MonitoringAppEndPointUserDetailModal({
  onHide,
  appEndPointUser,
}: MonitoringAppEndPointUserDetailModalProps) {
  const { t } = useTranslation();
  const [showMessageSendModal, setShowMessageSendModal] = useState(false);

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        dialogClassName="modal-dialog-scrollable"
      >
        <ModalHeader>
          <div className="d-flex">
            <MaterialIcon
              outlined={true}
              className={'text-40 mr-2'}
              name={'person'}
            />
            <ModalTitle as={'h4'}>{appEndPointUser.siteUserName}</ModalTitle>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="mb-4">
            <small className="d-flex text-64 mb-2">{t('사용자 아이디')}</small>
            <p className="m-0">{appEndPointUser.siteUserId}</p>
          </div>
          <div className="mb-4">
            <small className="d-flex text-64 mb-2">{t('사용자명')}</small>
            <p className="m-0">{appEndPointUser.siteUserName}</p>
          </div>
          <div className="mb-4">
            <small className="d-flex text-64 mb-2">{t('그룹')}</small>
            <p className="m-0">-</p>
          </div>
          <div className="mb-4">
            <small className="d-flex text-64 mb-2">{t('현재 위치')}</small>
            <p className="m-0">{appEndPointUser.zoneName || '-'}</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-light" onClick={onHide}>
            {t('닫기')}
          </button>
          <button
            type="button"
            className="btn btn-accent btn-second-modal within-first-modal"
            onClick={() => setShowMessageSendModal(true)}
          >
            {t('메세지 발송')}
          </button>
        </ModalFooter>
      </Modal>
      {showMessageSendModal && (
        <MonitoringMessageSendModal
          show={true}
          onHide={() => setShowMessageSendModal(false)}
          appEndPointUser={appEndPointUser}
        />
      )}
    </>
  );
}

export default MonitoringAppEndPointUserDetailModal;
