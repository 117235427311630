import React from 'react';
import { matchPath, Route, Switch } from 'react-router-dom';
import SignInForm from '@/components/SignInForm';
import SignUpForm from '@/components/SignUpForm';
import FindPasswordForm from '@/components/FindPasswordForm';
import ResetPasswordForm from '@/components/ResetPasswordForm';
import InviteForm from '@/components/InviteForm';
import { languages, Languages } from '@/locales/i18n';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

function Access() {
  const { t } = useTranslation();

  const isSign = matchPath(location.pathname, {
    path: ['/signin', '/signup', '/invite/:key'],
    exact: true,
  });

  const handleChangeLanguage = (language: Languages) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  return (
    <div className="layout-sticky-subnav layout-default">
      <div
        className="form-group float-right"
        style={{
          width: '100px',
          position: 'absolute',
          top: '20px',
          right: '20px',
          zIndex: 99,
        }}
      >
        <select
          className="form-line custom-line"
          defaultValue={localStorage.getItem('language') || 'ko'}
          onChange={(e) => handleChangeLanguage(e.target.value as Languages)}
        >
          {languages.map((language) => (
            <option key={language} value={language}>
              {t(`language_code_${language}`)}
            </option>
          ))}
        </select>
      </div>
      <div className="mdk-header-layout js-mdk-header-layout sign-up">
        <div className="flex-parent">
          <div className="flex-child">
            <div className="layout-login__form">
              {isSign && <SignContent />}
              {!isSign && <FindContent />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SignContent() {
  return (
    <>
      <Switch>
        <Route path={'/signin'} component={SignInForm} />
        <Route path={'/signup'} component={SignUpForm} />
        <Route path={'/invite/:key'} component={InviteForm} />
      </Switch>
    </>
  );
}

function FindContent() {
  return (
    <>
      <Switch>
        <Route path={'/find/password'} component={FindPasswordForm} />
        <Route path={'/reset/password/:key'} component={ResetPasswordForm} />
      </Switch>
    </>
  );
}

export default Access;
