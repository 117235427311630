import { Config } from '@/config';
import { ApiResult } from '@/modules/common';
import {
  PagedSensorListType,
  SensorSearchOptionType,
  SENSOR_ALL_TYPE,
} from '@/modules/sensor/types';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';

// 센서 목록 조회
export const fetchPagedSensorList = async ({
  mapId,
  searchKeyword,
  sensorClassificationTypeList,
}: // Todo: 페이징 작업 이후 진행
// pageNumber,
// pagesize,
SensorSearchOptionType): Promise<PagedSensorListType> => {
  const response = await axios
    .create({
      withCredentials: false,
    })
    .get(`${Config.space_api.uri}/assetTempHumNow`, {
      params: {
        mapid: mapId,
        utilnm: searchKeyword,
        sensortype: sensorClassificationTypeList.length
          ? sensorClassificationTypeList.toString().replaceAll(',', '|')
          : SENSOR_ALL_TYPE,
        // Todo: 페이징 작업 이후 진행
        // pageno: pageNumber,
        // pagesize,
      },
    });
  const result: ApiResult = response.data;
  if (response.status === StatusCodes.OK) {
    return (result as unknown) as PagedSensorListType;
  }

  throw new Error('Server Error');
};
