import React, { forwardRef } from 'react';

type AssetTextInputProps = {
  labelText: string;
  placeholder: string;
  ref: React.LegacyRef<HTMLInputElement>;
};

const AssetTextInput = forwardRef(
  (
    { labelText, placeholder }: AssetTextInputProps,
    ref: React.LegacyRef<HTMLInputElement>
  ) => {
    return (
      <div className="form-group mb-32pt">
        <label className="form-label essential">{labelText}</label>
        <input
          type="text"
          className="form-line"
          placeholder={placeholder}
          ref={ref}
        />
      </div>
    );
  }
);

AssetTextInput.displayName = 'AssetTextInput';

export default AssetTextInput;
