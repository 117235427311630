import React, { ReactElement, useState } from 'react';
import { ModalProps } from '@/modules/common';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  TabContainer,
  TabContent,
  TabPane,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import AlertModal from '@/components/common/modal/AlertModal';
import { useTranslation } from 'react-i18next';
import {
  Event,
  EVENT_INFO_SETTING,
  EVENT_RECEIVE_SETTING,
} from '@/modules/event/types';
import EventInfoNav from '@/components/event/EventInfoNav';
import { useEventText } from '@/modules/event/hook';
import { deleteEvent, putEventActive } from '@/api/event';

type EventDetailModalProps = ModalProps & {
  event: Event;
  onDelete: () => void;
};

function EventDetailModal({
  event,
  onHide,
  onReload,
  onDelete,
}: EventDetailModalProps): ReactElement {
  const { t } = useTranslation();
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleChangeActive = async () => {
    const result = await putEventActive(event.eventId, !event.activeFlag);
    if (result) {
      onHide();
      onReload?.call(null);
    } else {
      setShowAlertModal(true);
    }
  };

  const handleDeleteEvent = async () => {
    (await deleteEvent(event?.eventId))
      ? (onHide(), onReload && onReload(), onDelete())
      : setShowAlertModal(true);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        dialogClassName="modal-dialog-scrollable"
      >
        <ModalHeader>
          <div className="d-flex">
            <ModalTitle as={'h4'}>{t('알림 상세')}</ModalTitle>
          </div>
          <div className="d-flex ml-auto">
            <a className="btn-ico-sm btn-del" onClick={handleDeleteEvent}>
              <span className="icon material-icons-outlined">
                delete_forever
              </span>
              <span className="text">
                <span>{t('삭제')}</span>
              </span>
            </a>
          </div>
        </ModalHeader>
        <TabContainer defaultActiveKey={EVENT_INFO_SETTING}>
          <EventInfoNav />
          <ModalBody>
            <TabContent>
              <TabPane eventKey={EVENT_INFO_SETTING}>
                <EventInfo event={event} />
              </TabPane>
              <TabPane eventKey={EVENT_RECEIVE_SETTING}></TabPane>
            </TabContent>
          </ModalBody>
        </TabContainer>
        <ModalFooter>
          <button type="button" className="btn btn-light" onClick={onHide}>
            {t('닫기')}
          </button>
          <button
            type="button"
            className="btn btn-darken"
            onClick={handleChangeActive}
          >
            {event.activeFlag ? t('알림 종료') : t('알림 시작')}
          </button>
        </ModalFooter>
      </Modal>
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {t('서버와 통신 중 오류가 발생했습니다')}
      </AlertModal>
    </>
  );
}

type EventInfoProps = {
  event: Event;
};

function EventInfo({
  event: {
    eventTitle,
    eventTargetType,
    eventDetailCategory,
    targetZoneName,
    targetCount,
  },
}: EventInfoProps) {
  const { t } = useTranslation();
  const { getEventTargetTypeText, getEventDetailCategoryText } = useEventText();

  return (
    <>
      <div className="mb-4">
        <small className="d-flex text-64 mb-2">{t('알림 유형')}</small>
        <p className="m-0">{getEventTargetTypeText(eventTargetType)}</p>
      </div>
      <div className="mb-4">
        <small className="d-flex text-64 mb-2">{t('알림 명')}</small>
        <p className="m-0">{eventTitle}</p>
      </div>
      <div className="mb-4">
        <small className="d-flex text-64 mb-2">{t('지오펜스')}</small>
        <p className="m-0">{targetZoneName}</p>
      </div>
      <div className="mb-4">
        <small className="d-flex text-64 mb-2">{t('알림 분류')}</small>
        <p className="m-0">{getEventDetailCategoryText(eventDetailCategory)}</p>
      </div>
      <div className="mb-4">
        <small className="d-flex text-64 mb-2">{t('알림 옵션')}</small>
        <p className="m-0">{targetCount}</p>
      </div>
    </>
  );
}

export default EventDetailModal;
