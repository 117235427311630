import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type ProjectRegisterFooterProps = {
  type: 'save' | 'edit' | 'send';
  loading?: boolean;
  onClickCancel: () => void;
  onClickConfirm: () => void;
};

function ManageFooter({
  type,
  loading,
  onClickCancel,
  onClickConfirm,
}: ProjectRegisterFooterProps) {
  const { t } = useTranslation();
  return (
    <div className="pub-con">
      <div className="pub-inner">
        <div className="btn-wrap">
          <a className="btn btn-light mr-4" onClick={onClickCancel}>
            {t('취소')}
          </a>
          <a
            className={classNames('btn btn-accent ml-0', {
              'is-loading': loading,
            })}
            onClick={onClickConfirm}
          >
            {type === 'save' && t('저장')}
            {type === 'edit' && t('수정')}
            {type === 'send' && t('전송')}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ManageFooter;
