import {
  Action,
  SPACE_CHANGE_SHOW_ADDRESS_PANE,
  SPACE_SET_ADDRESS,
  SpaceState,
} from '@/modules/space/types';

export function spaceReducer(state: SpaceState, action: Action) {
  switch (action.type) {
    case SPACE_CHANGE_SHOW_ADDRESS_PANE:
      return {
        ...state,
        register: {
          ...state.register,
          address: {
            ...state.register.address,
            showPane: action.show,
          },
        },
      };
    case SPACE_SET_ADDRESS:
      return {
        ...state,
        register: {
          ...state.register,
          address: {
            ...state.register.address,
            ...action.selectedAddress,
          },
        },
      };
  }
}
