import {
  useAfterAssetUpload,
  useProjectRegister,
  useSelectedBuilding,
  useSelectedFloorPlanId,
  useUploadAssetListExcel,
} from '@/modules/project/hook';
import { useFloatPane } from '@/modules/setup/hook';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { postAssetList } from '@/api/assets';
import { AssetListRegisterResultType } from '@/modules/assets/types';
import { StatusCodes } from 'http-status-codes';

function RegisterAssetListButtonContainer(): ReactElement {
  const { t } = useTranslation();
  const { handleChangeShow } = useFloatPane();
  const { uploadedAssetList, setUploadedAssetList } = useUploadAssetListExcel();
  const { selectedBuilding } = useSelectedBuilding();
  const { selectedFloorPlanId } = useSelectedFloorPlanId();
  const {
    projectInfo: { projectId },
  } = useProjectRegister();
  const { setAfterAssetUpload } = useAfterAssetUpload();

  // 등록 이전 유효성 검사 (유효성 검사 통과하지 못한 경우 각 항목에 대한 에러 메시지 상태값 추가)
  const checkValidation = () => {
    let isValid = true;
    setUploadedAssetList(
      uploadedAssetList.map((uploadedAsset) => {
        if (
          !uploadedAsset.categories.filter(
            (category) => category.depth === 1
          )[0].categoryId
        ) {
          uploadedAsset.assetTypeErrorMessage = t(
            '자산 유형은 공백일 수 없습니다.'
          );
          isValid = false;
        } else {
          uploadedAsset.assetTypeErrorMessage = '';
          isValid = true;
        }

        if (
          !uploadedAsset.categories.filter(
            (category) => category.depth === 2
          )[0].categoryId
        ) {
          uploadedAsset.assetClassificationErrorMessage = t(
            '자산 분류는 공백일 수 없습니다.'
          );
          isValid = false;
        } else {
          uploadedAsset.assetClassificationErrorMessage = '';
          isValid = true;
        }

        if (!uploadedAsset.assetInfoName) {
          uploadedAsset.assetNameErrorMessage = t(
            '자산명은 공백일 수 없습니다.'
          );
          isValid = false;
        } else {
          uploadedAsset.assetNameErrorMessage = '';
          isValid = true;
        }

        if (!uploadedAsset.serialId) {
          uploadedAsset.serialIdErrorMessage = t(
            '식별값은 공백일 수 없습니다.'
          );
          isValid = false;
        } else {
          uploadedAsset.serialIdErrorMessage = '';
          isValid = true;
        }

        return uploadedAsset;
      })
    );

    if (
      !(selectedBuilding && selectedBuilding.mappingId && selectedFloorPlanId)
    ) {
      isValid = false;
      alert(t('건물과 평면도를 모두 선택해주세요'));
    }

    return isValid;
  };

  // Backend API에 알맞는 형태로 가공
  const parseAssetList = () =>
    uploadedAssetList.map((uploadedAsset) => ({
      projectId: projectId ? projectId : '',
      mappingId: selectedBuilding?.mappingId ? selectedBuilding.mappingId : '',
      mapId: selectedFloorPlanId,
      assetInfoName: uploadedAsset.assetInfoName,
      serialId: uploadedAsset.serialId,
      lat: 0,
      lng: 0,
      categories: [
        {
          categoryId: uploadedAsset.categories.filter(
            (category) => category.depth === 1
          )[0].categoryId,
        },
        {
          categoryId: uploadedAsset.categories.filter(
            (category) => category.depth === 2
          )[0].categoryId,
        },
      ],
    }));

  // 자산 일괄 등록
  const registerAssetList = async () => {
    const isValid = checkValidation();
    if (isValid) {
      const parsedAssetList = parseAssetList();
      const result = await postAssetList(parsedAssetList);
      if (result.status === StatusCodes.BAD_REQUEST) {
        const data: AssetListRegisterResultType[] = result.data.data;
        setUploadedAssetList(
          uploadedAssetList.map((uploadedAsset) => {
            const matchedIndex = data.findIndex(
              ({ field }) => field === uploadedAsset.field
            );
            matchedIndex !== -1
              ? (uploadedAsset.serialIdErrorMessage =
                  data[matchedIndex].message)
              : (uploadedAsset.serialIdErrorMessage = '');
            return uploadedAsset;
          })
        );
      } else if (result.status === StatusCodes.OK) {
        handleChangeShow(false);
        setAfterAssetUpload(true);
        alert(t('자산 등록이 완료되었습니다.'));
      }
    }
  };

  return (
    <div className="btn-wrap">
      <a className="btn btn-light mr-4" onClick={() => handleChangeShow(false)}>
        {t('취소')}
      </a>
      <a className="btn btn-accent ml-0" onClick={registerAssetList}>
        {t('등록')}
      </a>
    </div>
  );
}

export default RegisterAssetListButtonContainer;
