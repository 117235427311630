import { useContext } from 'react';
import { UserDispatchContext, UserStateContext } from '@/modules/user/context';
import { User, USER_REFRESH, USER_SET } from '@/modules/user/types';
import { fetchImage } from '@/api/common';

export function useUserState() {
  const state = useContext(UserStateContext);
  if (!state) throw new Error('UserProvider not found');
  return state;
}

export function useUserDispatch() {
  const dispatch = useContext(UserDispatchContext);
  if (!dispatch) throw new Error('UserProvider not found');
  return dispatch;
}

export function useUser() {
  const state = useUserState();
  const dispatch = useUserDispatch();
  const user = state;

  const handleRefresh = () => {
    dispatch({
      type: USER_REFRESH,
    });
  };

  const handleSetUser = (data: User) => {
    if (data.profileImgId) {
      fetchImage(data.profileImgId).then((res) => {
        data.profileImgUrl = res;
        dispatch({
          type: USER_SET,
          data,
        });
      });
    } else {
      dispatch({
        type: USER_SET,
        data,
      });
    }
  };

  return {
    user,
    handleSetUser,
    handleRefresh,
  };
}
