import { fetchAssetCountList, fetchPagedAssetList } from '@/api/assets';
import { useAssetSearchOption, usePagedAssetList } from '@/modules/assets/hook';
import {
  AssetCategoryType,
  AssetSearchOptionType,
  ASSETS_EQUIPMENT_CATEGORY_ID,
  ASSETS_HUMAN_CATEGORY_ID,
  ASSETS_LOGISTICS_CATEGORY_ID,
} from '@/modules/assets/types';
import {
  useHomeBuilding,
  useHomeFloor,
  useHomeProject,
} from '@/modules/home/hook';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type AssetLeftPaneSearchBarProps = {
  setLoading: (loading: boolean) => void;
};

function AssetLeftPaneSearchBar({
  setLoading,
}: AssetLeftPaneSearchBarProps): ReactElement {
  const { t } = useTranslation();
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const { assetSearchOption, setAssetSearchOption } = useAssetSearchOption();
  const { setPagedAssetList } = usePagedAssetList();

  const inputRef = useRef<HTMLInputElement>(null);

  const [assetCountList, setAssetCountList] = useState<AssetCategoryType[]>([]);
  const [assetAllCount, setAssetAllCount] = useState<AssetCategoryType>({
    projectId: '',
    mappingId: '',
    mapId: '',
    fileKey: '',
    fileUrl: '',
    originalFileName: '',
    imgId: '',
    seq: 0,
    categoryId: '',
    assetInfoId: '',
    categoryName: '',
    depth: 0,
    count: 0,
    parentId: '',
    registId: '',
    updateId: '',
    registDate: '',
    updateDate: '',
    activeFlag: false,
    deleteFlag: false,
    subCategories: [],
  });

  // 자산 종류 별 개수 조회
  const handleFetchAssetCountList = async (
    projectId: string,
    mappingId: string,
    mapId: string,
    searchKeyword: string
  ) => {
    const fetchedAssetCountList =
      project &&
      building &&
      floor &&
      (await fetchAssetCountList(projectId, mappingId, mapId, searchKeyword));
    fetchedAssetCountList &&
      setAssetCountList(
        fetchedAssetCountList.filter(
          (assetCount) => assetCount.categoryId !== null
        )
      );
    fetchedAssetCountList &&
      setAssetAllCount(
        fetchedAssetCountList.filter(
          (assetCount) => assetCount.categoryId === null
        )[0]
      );
  };

  // 자산 목록 가져오기
  const handleFetchPagedAssetList = async (option: AssetSearchOptionType) => {
    setLoading(true);
    option.projectId &&
      option.mappingId &&
      option.mapId &&
      setPagedAssetList(await fetchPagedAssetList(option));
    setLoading(false);
  };

  useEffect(() => {
    project &&
      building &&
      floor &&
      setAssetSearchOption({
        ...assetSearchOption,
        projectId: project.projectId,
        mappingId: building.mappingId,
        mapId: floor.mapId,
      });
  }, [project, building, floor]);

  useEffect(() => {
    assetSearchOption.projectId &&
      assetSearchOption.mappingId &&
      assetSearchOption.mapId &&
      handleFetchAssetCountList(
        assetSearchOption.projectId,
        assetSearchOption.mappingId,
        assetSearchOption.mapId,
        assetSearchOption.searchKeyword
      );
    handleFetchPagedAssetList(assetSearchOption);
  }, [assetSearchOption]);

  useEffect(() => {
    return () =>
      setAssetSearchOption({
        projectId: '',
        mappingId: '',
        mapId: '',
        categoryIdList: [],
        searchKeyword: '',
        page: 1,
      });
  }, []);

  // 검색어 입력 시
  const setSearchKeyword = () => {
    const searchKeyword = inputRef.current?.value;
    setAssetSearchOption({
      ...assetSearchOption,
      searchKeyword: searchKeyword ? searchKeyword : '',
    });
  };

  // 필터 선택 시
  const onClickFilter = (categoryId: string) => {
    assetSearchOption.categoryIdList.includes(categoryId)
      ? setAssetSearchOption({
          ...assetSearchOption,
          categoryIdList: assetSearchOption.categoryIdList.filter(
            (currentCategoryId) => currentCategoryId !== categoryId
          ),
        })
      : setAssetSearchOption({
          ...assetSearchOption,
          categoryIdList: assetSearchOption.categoryIdList.concat(categoryId),
        });
  };

  return (
    <div className="plf-floor-add border-bottom-0">
      <div className="d-flex align-items-sm-center pb-3">
        <div className="swipe-wrap">
          <ul className="swipe-list">
            <li>
              <a
                className={
                  !assetSearchOption.categoryIdList.length ? `active` : ``
                }
                onClick={() =>
                  setAssetSearchOption({
                    ...assetSearchOption,
                    categoryIdList: [],
                  })
                }
              >
                {t('전체')}({assetAllCount.count})
              </a>
            </li>
            {assetCountList.map((asset) => (
              <li key={asset.categoryId}>
                <a
                  className={
                    assetSearchOption.categoryIdList.includes(asset.categoryId)
                      ? `active`
                      : ``
                  }
                  onClick={() => onClickFilter(asset.categoryId)}
                >
                  {asset.categoryId === ASSETS_HUMAN_CATEGORY_ID && t('사람')}
                  {asset.categoryId === ASSETS_EQUIPMENT_CATEGORY_ID &&
                    t('장비')}
                  {asset.categoryId === ASSETS_LOGISTICS_CATEGORY_ID &&
                    t('물류')}
                  ({asset.count})
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="search-box">
        <input
          type="search"
          className="form-line"
          placeholder={t('자산명을 입력해 주세요.')}
          ref={inputRef}
          onKeyDown={(e) => e.key === 'Enter' && setSearchKeyword()}
        />
        <a className="btn-search circle-pin" onClick={setSearchKeyword}>
          <span className="material-icons">search</span>
        </a>
      </div>
    </div>
  );
}

export default AssetLeftPaneSearchBar;
