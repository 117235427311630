import React, { useEffect, useState } from 'react';
import { useHomeSelected } from '@/modules/home/hook';
import { fetchEventCategoryCount } from '@/api/event';
import {
  EVENT_STATUS_ACTIVE,
  EVENT_STATUS_INACTIVE,
  EVENT_STATUS_WILL,
  EventMainCategory,
  EventStatus,
  EventStatusCount,
} from '@/modules/event/types';
import MaterialIcon from '@/components/common/MaterialIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type EventFilterProps = {
  eventMainCategory: EventMainCategory;
  filterEventStatusList: EventStatus[];
  reload: boolean;
  showSearch?: boolean;
  onSubmitSearchKeyword?: (searchKeyword: string) => void;
  onChangeFilterEventStatusList: (filterEventStatusList: EventStatus[]) => void;
};

function EventFilter({
  eventMainCategory,
  filterEventStatusList,
  reload,
  showSearch = true,
  onSubmitSearchKeyword,
  onChangeFilterEventStatusList,
}: EventFilterProps) {
  const { t } = useTranslation();
  const { project, building, floor } = useHomeSelected();
  const [eventStatusCountList, setEventStatusCountList] = useState<
    EventStatusCount[]
  >([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
    handleFetchEventStatusCount();
  }, [reload]);

  const handleFetchEventStatusCount = async () => {
    project &&
      building &&
      floor &&
      setEventStatusCountList(
        await fetchEventCategoryCount({
          projectId: project.projectId,
          mappingId: building.mappingId,
          mapId: floor.mapId,
          searchKeyword,
          eventCategory: eventMainCategory,
        })
      );
  };

  const handleChangeFilterEventStatus = (eventStatus: '' | EventStatus) =>
    eventStatus
      ? onChangeFilterEventStatusList([eventStatus])
      : onChangeFilterEventStatusList([]);

  const handleSubmitSearchKeyword = () => {
    onSubmitSearchKeyword?.call(null, searchKeyword);
  };

  return (
    <div className="plf-floor-add border-bottom-0">
      <div className="d-flex align-items-sm-center pb-3">
        <div className="swipe-wrap">
          <ul className="swipe-list">
            <li>
              <a
                className={classNames({
                  active: !filterEventStatusList.length,
                })}
                onClick={() => handleChangeFilterEventStatus('')}
              >
                {t('전체')}
              </a>
            </li>
            {eventMainCategory === 'POSITION' && (
              <li>
                <a
                  className={classNames({
                    active: filterEventStatusList.includes(EVENT_STATUS_WILL),
                  })}
                  onClick={() =>
                    handleChangeFilterEventStatus(EVENT_STATUS_WILL)
                  }
                >
                  {t('예정')}(
                  {eventStatusCountList.find(
                    ({ eventStatus }) =>
                      eventStatus.toLowerCase() === EVENT_STATUS_WILL
                  )?.targetCount || 0}
                  )
                </a>
              </li>
            )}
            <li>
              <a
                className={classNames({
                  active: filterEventStatusList.includes(EVENT_STATUS_ACTIVE),
                })}
                onClick={() =>
                  handleChangeFilterEventStatus(EVENT_STATUS_ACTIVE)
                }
              >
                {t('진행중')}(
                {eventStatusCountList.find(
                  ({ eventStatus }) =>
                    eventStatus.toLowerCase() === EVENT_STATUS_ACTIVE
                )?.targetCount || 0}
                )
              </a>
            </li>
            <li>
              <a
                className={classNames({
                  active: filterEventStatusList.includes(EVENT_STATUS_INACTIVE),
                })}
                onClick={() =>
                  handleChangeFilterEventStatus(EVENT_STATUS_INACTIVE)
                }
              >
                {t('종료됨')}(
                {eventStatusCountList.find(
                  ({ eventStatus }) =>
                    eventStatus.toLowerCase() === EVENT_STATUS_INACTIVE
                )?.targetCount || 0}
                )
              </a>
            </li>
          </ul>
        </div>
      </div>
      {showSearch && (
        <div className="search-box mb-0">
          <input
            type="search"
            className="form-line"
            placeholder={t('알림명을 입력해 주세요')}
            autoComplete={'off'}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSubmitSearchKeyword();
              }
            }}
          />
          <a
            className="btn-search circle-pin"
            onClick={handleSubmitSearchKeyword}
          >
            <MaterialIcon name={'search'} />
          </a>
        </div>
      )}
    </div>
  );
}

export default React.memo(EventFilter);
