import React, { createContext, Dispatch, ReactNode, useReducer } from 'react';
import {
  Action,
  MONITORING_TYPE_LIDAR,
  MonitoringState,
} from '@/modules/monitoring/types';
import { monitoringReducer } from '@/modules/monitoring/reducer';

export const MonitoringStateContext = createContext<MonitoringState | null>(
  null
);
type MonitoringDispatch = Dispatch<Action>;
export const MonitoringDispatchContext = createContext<MonitoringDispatch | null>(
  null
);

export const initialState: MonitoringState = {
  directFlag: false,
  monitoringType: MONITORING_TYPE_LIDAR,
  appEndPointUserList: [],
  appEndPointUserAccumulateNumber: 0,
  lidarObjectList: [],
  selectedGeofenceFeatureList: [],
  selectedLidarObjectList: [],
};

export function MonitoringContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [monitoring, dispatch] = useReducer(monitoringReducer, initialState);

  return (
    <MonitoringDispatchContext.Provider value={dispatch}>
      <MonitoringStateContext.Provider value={monitoring}>
        {children}
      </MonitoringStateContext.Provider>
    </MonitoringDispatchContext.Provider>
  );
}
