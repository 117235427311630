import React, { useState } from 'react';
import SignUpStep from '@/components/SignUpStep';
import SignUpTerms from '@/components/SignUpTerms';
import SignUpAuthentication from '@/components/SignUpAuthentication';
import SignUpInfo from '@/components/SignUpInfo';
import SignUpComplete from '@/components/SignUpComplete';

export type SignUpFormProps = {
  onChangeStep: (step: number) => void;
};

function SignUpForm() {
  const [step, setStep] = useState(1);
  const [signUpEmail, setSignUpEmail] = useState('');

  return (
    <>
      {step === 1 && <SignUpTerms onClickAgree={() => setStep(2)} />}
      {step === 2 && (
        <SignUpAuthentication
          onChangeStep={setStep}
          signUpEmail={signUpEmail}
          onChangeSignUpEmail={setSignUpEmail}
        />
      )}
      {step === 3 && (
        <SignUpInfo signUpEmail={signUpEmail} onChangeStep={setStep} />
      )}
      {step === 4 && <SignUpComplete />}
      <SignUpStep step={step} />
    </>
  );
}

export default SignUpForm;
