import React, { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { Action, User, UserState } from '@/modules/user/types';
import { userReducer } from '@/modules/user/reducer';

export const UserStateContext = createContext<UserState | null>(null);
type UserDispatch = Dispatch<Action>;
export const UserDispatchContext = createContext<UserDispatch | null>(null);

export const initialState: User = {
  userName: '',
  userId: '',
  userType: 'NORMAL',
  profileImgId: '',
  companyName: '',
  department: '',
  businessType: '',
  companyScale: '',
  businessField: '',
};

export function UserContextProvider({ children }: { children: ReactNode }) {
  const [user, dispatch] = useReducer(userReducer, initialState);

  return (
    <UserDispatchContext.Provider value={dispatch}>
      <UserStateContext.Provider value={user}>
        {children}
      </UserStateContext.Provider>
    </UserDispatchContext.Provider>
  );
}
