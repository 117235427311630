import React, { ReactElement } from 'react';
import {
  useHomeBuilding,
  useHomeFloor,
  useHomeProject,
} from '@/modules/home/hook';
import { useHistory } from 'react-router';

type LeftPaneBreadcrumbProps = {
  showFloor?: boolean;
};

function LeftPaneBreadcrumb({
  showFloor = true,
}: LeftPaneBreadcrumbProps): ReactElement {
  const history = useHistory();
  const { project, handleChangeProject } = useHomeProject();
  const { building, handleChangeBuilding } = useHomeBuilding();
  const { floor } = useHomeFloor();

  const onClickProject = () => {
    handleChangeProject('');
  };

  const onClickBuilding = () => {
    handleChangeBuilding(null);
    history.replace(`/home/${project?.projectId}`);
  };

  return (
    <div className="mb-3">
      <a className="badge-gray" onClick={onClickProject}>
        {project?.projectName}
      </a>{' '}
      <a className="badge-gray" onClick={onClickBuilding}>
        {building?.metaName}
      </a>{' '}
      {showFloor && <small className="badge-white">{floor?.mapName}</small>}
    </div>
  );
}

export default LeftPaneBreadcrumb;
