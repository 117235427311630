import React from 'react';
import InputCustomRadio from '@/components/common/InputCustomRadio';
import { EventCondition, EventDetailCategory } from '@/modules/event/types';

type EventConditionFormProps = {
  eventDetailCategory: EventDetailCategory;
  eventCondition: EventCondition;
  onChangeEventCondition: (eventCondition: EventCondition) => void;
};

function EventConditionForm({
  eventDetailCategory,
  eventCondition,
  onChangeEventCondition,
}: EventConditionFormProps) {
  return (
    <>
      {eventDetailCategory === 'MORE_OR_LESS' && (
        <>
          <InputCustomRadio
            radioKey={'more'}
            radioName={'eventCondition'}
            textKey={'이상'}
            checked={eventCondition === 'MORE'}
            onChangeChecked={(checked) =>
              checked && onChangeEventCondition('MORE')
            }
          />
          <InputCustomRadio
            radioKey={'less'}
            radioName={'eventCondition'}
            textKey={'이하'}
            checked={eventCondition === 'LESS'}
            onChangeChecked={(checked) =>
              checked && onChangeEventCondition('LESS')
            }
          />
        </>
      )}
      {eventDetailCategory === 'IN_OR_OUT' && (
        <InputCustomRadio
          radioKey={'in'}
          radioName={'eventCondition'}
          textKey={'출입 알림'}
          checked={eventCondition === 'IN'}
          onChangeChecked={(checked) => checked && onChangeEventCondition('IN')}
        />
      )}
      {eventDetailCategory === 'CHECK_IN_OR_OUT' && (
        <>
          <InputCustomRadio
            radioKey={'in'}
            radioName={'eventCondition'}
            textKey={'재실'}
            checked={eventCondition === 'IN'}
            onChangeChecked={(checked) =>
              checked && onChangeEventCondition('IN')
            }
          />
          <InputCustomRadio
            radioKey={'out'}
            radioName={'eventCondition'}
            textKey={'퇴실'}
            checked={eventCondition === 'OUT'}
            onChangeChecked={(checked) =>
              checked && onChangeEventCondition('OUT')
            }
          />
        </>
      )}
    </>
  );
}

export default EventConditionForm;
