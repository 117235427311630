import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import MaterialIcon from '@/components/common/MaterialIcon';
import React from 'react';
import { ModalContainerProps } from '@/modules/common';

type NoticeModalProps = ModalContainerProps;

function NoticeContainerModal({
  children,
  show,
  onHide,
  onConfirm,
}: NoticeModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={() => {
        //
      }}
      centered={true}
    >
      <ModalHeader>
        <ModalTitle as={'h5'}>{t('알림')}</ModalTitle>
        <button type="button" className="close custom-close" onClick={onHide}>
          <span>
            <MaterialIcon name={'clear'} />
          </span>
        </button>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-link" onClick={onHide}>
          {t('취소')}
        </button>
        <button type="button" className="btn btn-accent" onClick={onConfirm}>
          {t('확인')}
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default NoticeContainerModal;
