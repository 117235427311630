import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageHistory } from '@/modules/message/types';
import { fetchMessages } from '@/api/message';
import { useHomeProject } from '@/modules/home/hook';
import moment from 'moment';
import AlertModal from '@/components/common/modal/AlertModal';
import FormGroup from '@/components/common/FormGroup';
import FormLabel from '@/components/common/FormLabel';

type AccidentMessageHistoryProps = {
  accidentId: string;
  registDate: string;
  reload: boolean;
};

function AccidentMessageHistory({
  accidentId,
  registDate,
  reload,
}: AccidentMessageHistoryProps) {
  const { t } = useTranslation();
  const { project } = useHomeProject();
  const [load, setLoad] = useState(false);
  const [messageHistoryList, setMessageHistoryList] = useState<
    MessageHistory[]
  >([]);
  const [messageHistory, setMessageHistory] = useState<MessageHistory | null>(
    null
  );

  useEffect(() => {
    handleFetchMessageList();
  }, [reload]);

  const handleFetchMessageList = async () => {
    setLoad(false);
    project &&
      accidentId &&
      setMessageHistoryList(
        await fetchMessages({
          projectId: project.projectId,
          parentId: accidentId,
          parentType: 'ACCIDENT',
        })
      );
    setLoad(true);
  };

  return (
    <>
      <h4>{t('메세지')}</h4>
      <ul className="list-col history-box bg-light">
        {!load && <></>}
        {load && (
          <>
            {!messageHistoryList.length && (
              <li>
                <a
                  className="d-flex"
                  style={{
                    cursor: 'default',
                  }}
                >
                  {moment(registDate).format('YYYY.MM.DD HH:mm:ss')}{' '}
                  <span className="text-identity ml-1">{t('최초')}</span>
                  <span className="ml-auto">{t('재실 인원이 없습니다')}</span>
                </a>
              </li>
            )}
            {messageHistoryList.map((messageHistory, i) => (
              <MessageHistoryItem
                key={messageHistory.messageId}
                idx={i}
                {...messageHistory}
                onClickItem={() => setMessageHistory(messageHistory)}
              />
            ))}
          </>
        )}
      </ul>
      {messageHistory && (
        <AlertModal
          show={true}
          titleKey={'메세지 내용'}
          onHide={() => setMessageHistory(null)}
        >
          <FormGroup marginBottomClassName={'mb-0'}>
            <FormLabel
              textKey={'내용'}
              essential={true}
              fontSizeApply={false}
            />
            <textarea
              rows={5}
              placeholder={t('내용을 입력해 주세요')}
              value={messageHistory.messageContent}
              disabled={true}
            ></textarea>
          </FormGroup>
        </AlertModal>
      )}
    </>
  );
}

type MessageHistoryItemProps = MessageHistory & {
  idx: number;
  onClickItem: () => void;
};

function MessageHistoryItem({
  idx,
  registDate,
  onClickItem,
}: MessageHistoryItemProps) {
  const { t } = useTranslation();
  return (
    <li>
      <a className="d-flex" onClick={onClickItem}>
        {moment(registDate).format('YYYY.MM.DD HH:mm:ss')}{' '}
        {idx === 0 && <span className="text-identity ml-1">{t('최초')}</span>}
        <span className="ml-auto">
          {/*<span className="text-identity">0건</span>*/} {t('발송됨')}
        </span>
      </a>
    </li>
  );
}

export default AccidentMessageHistory;
