import React, { useEffect, useRef, useState } from 'react';
import { PANE_STATUS_LIST, PaneStatus } from '@/utils/constants/common';
import MaterialIcon from '@/components/common/MaterialIcon';
import FormGroup from '@/components/common/FormGroup';
import FormLabel from '@/components/common/FormLabel';
import ManageFooter from '@/components/common/ManageFooter';
import { useTranslation } from 'react-i18next';
import {
  ConnectedSpaceInfo,
  SPACE_COUNTRY_CODE_ETC,
  SPACE_COUNTRY_CODE_JP,
  SPACE_COUNTRY_CODE_KR,
  SpaceCountryCode,
} from '@/modules/space/types';
import classNames from 'classnames';
import { useSpaceRegister } from '@/modules/space/hook';
import KakaoPostcodeModal from '@/components/common/modal/KakaoPostcodeModal';
import { Point } from 'ol/geom';
import { useOpenLayers } from '@/modules/project/hook';
import { LngLat } from '@/modules/common/types';
import { Collection, Feature } from 'ol';
import { Icon, Style } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { fetchGoogleLocation } from '@/api/common';
import { Translate } from 'ol/interaction';
import InvalidAlert from '@/components/InvalidAlert';
import { ValidUtils } from '@/utils';
import { postUpdateSpace } from '@/api/space';
import { useUser } from '@/modules/user/hook';
import AlertModal from '@/components/common/modal/AlertModal';
import NewlineText from '@/components/common/NewlineText';

const iconFeature = new Feature();
iconFeature.setId('address_marker');
const iconStyle = new Style({
  image: new Icon({
    src: '/static/images/ic-marker-24-px.svg',
    anchor: [0.5, 45], // 0.5 default, 45 image height
    anchorXUnits: IconAnchorUnits.FRACTION,
    anchorYUnits: IconAnchorUnits.PIXELS,
  }),
});
iconFeature.setStyle(iconStyle);
const collection = new Collection<Feature>();
collection.push(iconFeature);
const translate = new Translate({
  features: collection,
});

type ProjectBuildingEditProps = {
  spaceInfo: ConnectedSpaceInfo | null;
  onReloadSpaceInfoList: () => void;
  onChangeStatus: (status: PaneStatus) => void;
};

function ProjectBuildingEdit({
  spaceInfo,
  onReloadSpaceInfoList,
  onChangeStatus,
}: ProjectBuildingEditProps) {
  const { t } = useTranslation();
  const mountedRef = useRef(false);
  const { user } = useUser();
  const {
    selectedDo,
    selectedSubDistrict,
    selectedLngLat,
    detailedAddress,
    handleSetAddress,
    handleChangeShowAddressPane,
  } = useSpaceRegister();
  const { map, drawSource } = useOpenLayers();
  const [inputs, setInputs] = useState<{
    buildingName: string;
    countryCode: SpaceCountryCode;
    address: string;
  }>({
    buildingName: '',
    countryCode: SPACE_COUNTRY_CODE_KR,
    address: '',
  });
  const [lngLat, setLngLat] = useState<LngLat | null>(null);
  const [showKRPostCodeModal, setShowKRPostCodeModal] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    translate.on('translateend', (e) => {
      const coordinate = e.coordinate;
      setLngLat({
        lng: coordinate[0],
        lat: coordinate[1],
      });
    });
    map?.addInteraction(translate);

    return () => {
      handleSetAddress({
        selectedDo: '',
        selectedSubDistrict: '',
        selectedLngLat: null,
        detailedAddress: '',
      });
      drawSource?.clear();
    };
  }, []);

  useEffect(() => {
    const mounted = mountedRef.current;
    const countryCode = inputs.countryCode;
    if (!mounted && countryCode === spaceInfo?.countryCode) {
      mountedRef.current = true;
    }
    if (inputs.address) {
      setInputs({
        ...inputs,
        address: '',
      });
    }
    handleSetAddress({
      selectedDo: '',
      selectedSubDistrict: '',
      selectedLngLat: null,
      detailedAddress: '',
    });
    const view = map?.getView();
    if (view) {
      let center: number[] = [];
      if (countryCode === SPACE_COUNTRY_CODE_JP) {
        center = [15558454.49842193, 4257326.124240982];
      } else if (countryCode === SPACE_COUNTRY_CODE_KR) {
        center = [14134326.456645714, 4516772.805272909];
      }

      if (center.length >= 2) {
        view.setZoom(7);
        view.setCenter(center);
      }
    }
  }, [inputs.countryCode]);

  useEffect(() => {
    drawSource?.clear();
    if (selectedLngLat) {
      const coordinate = [selectedLngLat.lng, selectedLngLat.lat];
      const featureById = drawSource?.getFeatureById('address_marker');
      if (featureById) {
        drawSource?.removeFeature(featureById);
      }

      iconFeature.setGeometry(new Point(coordinate));
      drawSource?.addFeature(iconFeature);
      map?.getView().setCenter(coordinate);
      map?.getView().setZoom(10);
    }

    setInputs({
      ...inputs,
      address: detailedAddress,
    });
    setLngLat(selectedLngLat);
  }, [selectedDo, selectedSubDistrict, selectedLngLat, detailedAddress]);

  useEffect(() => {
    if (spaceInfo) {
      const mounted = mountedRef.current;
      setInputs({
        ...inputs,
        buildingName: spaceInfo.spaceName,
        countryCode: spaceInfo.countryCode,
      });

      if (mounted) {
        handleSetAddress({
          selectedDo: spaceInfo.country,
          selectedSubDistrict: spaceInfo.city,
          selectedLngLat: {
            lng: spaceInfo.lng,
            lat: spaceInfo.lat,
          },
          detailedAddress: spaceInfo.address,
        });
      }
    }
  }, [spaceInfo, mountedRef.current]);

  const handleClickCancel = () => {
    onChangeStatus(PANE_STATUS_LIST);
  };

  const handleClickSubmit = async () => {
    setShowInvalidMessage(false);
    const { buildingName, address, countryCode } = inputs;
    let message = '';

    if (!buildingName) {
      message = t('건물명을 입력해 주세요.');
    } else {
      const lengthValid = buildingName.length >= 3 && buildingName.length <= 70;
      const nameValid = ValidUtils.validBuildingName(
        buildingName.replaceAll(' ', '')
      );
      if (!lengthValid || !nameValid) {
        message = t(
          '건물명은 3~70자 이내 한글/영문/일어/숫자와 특수문자(-,#,&,(,))로만 작성해 주세요.'
        );
      }
    }

    if (!message && (!address || !lngLat)) {
      message = t('주소를 입력해 주세요.');
    }

    if (message) {
      setInvalidMessage(message);
      setShowInvalidMessage(true);
      return;
    }

    const spaceId = spaceInfo?.spaceId;
    if (spaceId) {
      const result = await postUpdateSpace({
        metaid: spaceId,
        metaname: buildingName,
        countrycode: countryCode,
        addres1: address,
        country: selectedDo,
        city: selectedSubDistrict,
        lng: lngLat?.lng || 0,
        lat: lngLat?.lat || 0,
        userid: user.userId,
      });

      const featureById = drawSource?.getFeatureById('address_marker');
      if (featureById) {
        map?.removeInteraction(translate);
        drawSource?.removeFeature(featureById);
      }

      if (result) {
        onReloadSpaceInfoList();
        onChangeStatus(PANE_STATUS_LIST);
      } else {
        message = t('서버와 통신 중 오류가 발생했습니다');
      }
    }

    if (message) {
      setAlertMessage(message);
      setShowAlertModal(true);
      return;
    }
  };

  const handleClickAddressSearch = () => {
    const countryCode = inputs.countryCode;
    if (countryCode === SPACE_COUNTRY_CODE_JP) {
      handleChangeShowAddressPane(true);
    } else if (countryCode === SPACE_COUNTRY_CODE_KR) {
      setShowKRPostCodeModal(true);
    }
  };

  const handleClickManualAddressSearch = () => {
    setShowInvalidMessage(false);
    if (inputs.address) {
      handleFetchAddressLngLat();
    } else {
      setInvalidMessage(t('검색하려는 주소를 입력해 주세요.'));
      setShowInvalidMessage(true);
    }
  };

  const handleFetchAddressLngLat = async () => {
    const lngLat = await fetchGoogleLocation(
      selectedDo + selectedSubDistrict + inputs.address
    );
    if (lngLat) {
      setLngLat({
        lng: lngLat.lng,
        lat: lngLat.lat,
      });
      const lngLatArray = Object.values(lngLat);

      iconFeature.setGeometry(new Point(lngLatArray));
      drawSource?.addFeature(iconFeature);
      map?.getView().setZoom(13);
      map?.getView().setCenter(lngLatArray);
    }
  };

  return (
    <>
      <div className="container-fluid border-bottom-1 mb-4 py-4">
        <div className="d-flex align-items-center">
          <a className="circle-pin pr-2" onClick={handleClickCancel}>
            <MaterialIcon name={'arrow_back'} />
          </a>
          <h3 className="mb-0">{t('건물 수정')}</h3>
        </div>
      </div>
      <div className="container-fluid">
        <FormGroup>
          <FormLabel textKey={'건물명'} essential={true} />
          <input
            type="text"
            className="form-line"
            placeholder={t('건물명을 입력해 주세요.')}
            value={inputs.buildingName}
            maxLength={70}
            onChange={(e) => {
              setInputs({
                ...inputs,
                buildingName: e.target.value,
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel textKey={'국가'} />
          <select
            className="form-line"
            value={inputs.countryCode}
            onChange={(e) => {
              setInputs({
                ...inputs,
                countryCode: Number(e.target.value) as SpaceCountryCode,
              });
            }}
          >
            <option value={SPACE_COUNTRY_CODE_KR}>{t('대한민국')}</option>
            <option value={SPACE_COUNTRY_CODE_JP}>{t('일본')}</option>
            <option value={SPACE_COUNTRY_CODE_ETC}>{t('기타 국가')}</option>
          </select>
        </FormGroup>
        {inputs.countryCode !== SPACE_COUNTRY_CODE_ETC && (
          <>
            <div className="form-group">
              <FormLabel textKey={'주소'} essential={true} />
              <input
                type="text"
                className="form-line"
                style={{
                  paddingRight: '75px',
                }}
                readOnly={true}
                value={selectedDo + selectedSubDistrict}
                disabled={!selectedDo && !selectedSubDistrict}
              />
              <a
                className="btn-abs btn-light btn-sm"
                onClick={handleClickAddressSearch}
              >
                {t('주소 검색')}
              </a>
            </div>
            <p className="form-info mb-32pt">
              {t('주소 검색 버튼을 선택해 주소를 검색 후 등록해 주세요')}
            </p>
            <div className="form-group">
              <FormLabel textKey={'상세 주소'} />
              <input
                type="text"
                className="form-line"
                style={{
                  paddingRight: '75px',
                }}
                value={inputs.address}
                disabled={!selectedDo && !selectedSubDistrict}
                onChange={(e) => {
                  setInputs({
                    ...inputs,
                    address: e.target.value,
                  });
                }}
                onKeyPress={(e) => {
                  if (
                    inputs.countryCode !== SPACE_COUNTRY_CODE_KR &&
                    e.key === 'Enter'
                  ) {
                    handleClickManualAddressSearch();
                  }
                }}
              />
              {inputs.countryCode !== SPACE_COUNTRY_CODE_KR && (
                <a
                  className={classNames('btn-abs btn-light btn-sm', {
                    disabled: !inputs.address,
                  })}
                  onClick={() => {
                    handleClickManualAddressSearch();
                  }}
                >
                  {t('위치 검색')}
                </a>
              )}
            </div>
            <p className="form-info mb-32pt">
              {t('주소를 검색 후 입력해 주세요.')}
            </p>
          </>
        )}
        {inputs.countryCode === SPACE_COUNTRY_CODE_ETC && (
          <div className="form-group">
            <FormLabel textKey={'주소'} essential={true} />
            <input
              type="text"
              className="form-line"
              style={{
                paddingRight: '75px',
              }}
              value={inputs.address}
              onChange={(e) => {
                setInputs({
                  ...inputs,
                  address: e.target.value,
                });
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleClickManualAddressSearch();
                }
              }}
            />
            <a
              className={classNames('btn-abs btn-light btn-sm', {
                disabled: !inputs.address,
              })}
              onClick={() => {
                handleClickManualAddressSearch();
              }}
            >
              {t('위치 검색')}
            </a>
          </div>
        )}
      </div>
      {showInvalidMessage && <InvalidAlert messageKey={invalidMessage} />}
      <ManageFooter
        type={'edit'}
        onClickCancel={handleClickCancel}
        onClickConfirm={handleClickSubmit}
      />
      <KakaoPostcodeModal
        show={showKRPostCodeModal}
        onHide={() => setShowKRPostCodeModal(false)}
        onComplete={(data) => {
          handleSetAddress({
            selectedDo: data.address,
            selectedSubDistrict: data.extra,
            selectedLngLat: data.lngLat,
            detailedAddress: '',
          });
        }}
      />
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        <NewlineText text={alertMessage} />
      </AlertModal>
    </>
  );
}

export default ProjectBuildingEdit;
