import {
  ACCIDENT_EMERGENCY,
  ACCIDENT_FIRE,
  ACCIDENT_INFECTIOUS_DISEASE,
  ACCIDENT_RESCUE,
  AccidentCategory,
} from '@/modules/accident/types';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export function useAccidentData() {
  const { t } = useTranslation();

  const getAccidentBasicInfo = useCallback(
    (accidentCategory: AccidentCategory) => {
      let icon = '';
      let title = '';
      let content = '';

      switch (accidentCategory) {
        case ACCIDENT_EMERGENCY:
          icon = 'warning';
          title = t('긴급 상황');
          content = t(
            '긴급상황이 발생하였습니다. 신속하게 대피해 주시기 바랍니다.'
          );
          break;
        case ACCIDENT_FIRE:
          icon = 'local_fire_department';
          title = t('화재');
          content = t(
            '화재가 발생하였습니다. 신속하게 대피해 주시기 바랍니다.'
          );
          break;
        case ACCIDENT_RESCUE:
          icon = 'support';
          title = t('구조 요청');
          content = t(
            '구조요청이 발생하였습니다. 재실중인 임직원은 다음의 안내에 구조요청을 확인 바랍니다.'
          );
          break;
        case ACCIDENT_INFECTIOUS_DISEASE:
          icon = 'coronavirus';
          title = t('감염병');
          content = t(
            '감염병 상황이 발생하였습니다. 신속하게 대피해 주시기 바랍니다.'
          );
          break;
      }

      return {
        icon,
        title,
        content,
      };
    },
    []
  );

  return {
    getAccidentBasicInfo,
  };
}
