import React from 'react';
import { useTranslation } from 'react-i18next';

type LeftPaneHeaderProps = {
  titleKey: string;
  onClickBack: () => void;
};

function LeftPaneHeader({ titleKey, onClickBack }: LeftPaneHeaderProps) {
  const { t } = useTranslation();

  return (
    <div className="container-fluid border-bottom-1 mb-4 py-4">
      <div className="d-flex align-items-center">
        <a className="circle-pin pr-2" onClick={onClickBack}>
          <i className="material-icons">arrow_back</i>
        </a>
        <h3 className="mb-0">{t(titleKey)}</h3>
      </div>
    </div>
  );
}

export default LeftPaneHeader;
