import { SelectOptionType } from '@/modules/assets/types';
import React, { ReactElement } from 'react';

type AssetComboBoxProps = {
  labelText: string;
  emptyValueText?: string;
  disabled?: boolean;
  optionList?: SelectOptionType[];
  value?: string;
  setValue?: (option: string) => void;
};

function AssetComboBox({
  labelText,
  emptyValueText,
  disabled,
  optionList,
  value,
  setValue,
}: AssetComboBoxProps): ReactElement {
  // 값 선택 시
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      optionList &&
        setValue &&
        setValue(
          optionList.filter((option) => option.id === e.target.value)[0].id
        );
    } else {
      setValue && setValue('');
    }
  };

  return (
    <div className="form-group mb-32pt">
      <label className="form-label essential">{labelText}</label>
      <select
        name="category"
        className="form-line"
        disabled={disabled}
        value={value ? value : ''}
        onChange={onChange}
      >
        <option value="">{emptyValueText}</option>
        {optionList &&
          optionList.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
      </select>
    </div>
  );
}

export default AssetComboBox;
