import React, { ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLeftSidebar } from '@/modules/setup/hook';
import { useHomeMenu, useHomeProject } from '@/modules/home/hook';
import { useMonitoringMenu } from '@/modules/monitoring/hook';

type TopNavbarProps = {
  home?: boolean;
  children?: ReactNode;
};

function TopNavbar({ home = false, children }: TopNavbarProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { expand, handleChangeExpand } = useLeftSidebar();
  const { handleChangeProject } = useHomeProject();
  const { handleChangeDirectFlag } = useMonitoringMenu();

  const handleClickLogo = () => {
    if (home) {
      handleChangeDirectFlag(false);
      handleChangeProject('');
    } else {
      history.replace('/home');
    }
  };

  return (
    <div className="navbar">
      <span
        className="material-icons mr-4 nav-toggle"
        onClick={() => handleChangeExpand(!expand)}
      >
        menu
      </span>
      <OverlayTrigger
        placement={'right'}
        overlay={
          <Tooltip id={'go'}>
            {home ? t('대쉬보드로 이동') : t('홈으로 이동')}
          </Tooltip>
        }
      >
        <a
          className="sidebar-brand p-0 navbar-height d-flex justify-content-center"
          onClick={handleClickLogo}
        >
          <img src="/static/images/logo.svg" alt="logo" className="img-fluid" />
        </a>
      </OverlayTrigger>
      {children}
    </div>
  );
}

export default TopNavbar;
