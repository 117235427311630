import axios from 'axios';
import { Config } from '@/config';
import { StatusCodes } from 'http-status-codes';
import { ZipCodeAddress } from '@/modules/space/types';

export async function fetchDo() {
  const axiosInstance = axios.create({
    withCredentials: false,
  });
  const response = await axiosInstance.get(`${Config.address_api.uri}/admDo`);
  return response.data;
}

export async function fetchSubDistrict(doName: string) {
  const axiosInstance = axios.create({
    withCredentials: false,
  });
  const response = await axiosInstance.get(
    `${Config.address_api.uri}/admSi?donm=${doName}`
  );
  return response.data;
}

export async function fetchJapanAddress(address: string) {
  const axiosInstance = axios.create({
    withCredentials: false,
  });
  const response = await axiosInstance.get(
    `${Config.address_api.uri}/jpaddress`,
    {
      params: {
        addr: address,
      },
    }
  );

  if (response && response.status === StatusCodes.OK) {
    const data = response.data;
    return data.result;
  }

  throw new Error('Server Error');
}

export async function fetchJapanAddressByZipCode(zipCode: string) {
  if (!zipCode) {
    return null;
  }

  const axiosInstance = axios.create({
    withCredentials: false,
  });
  const response = await axiosInstance.get(
    `${Config.address_api.zip_code_uri}/zipToCoords`,
    {
      params: {
        zipcode: zipCode,
      },
    }
  );

  if (response.status === StatusCodes.OK && response.data) {
    return response.data as ZipCodeAddress;
  }

  return null;
}
