import React, { useEffect, useState } from 'react';
import {
  GEO_IMAGE_DEFAULT_OPACITY,
  PANE_STATUS_LIST,
  PaneStatus,
} from '@/utils/constants/common';
import MaterialIcon from '@/components/common/MaterialIcon';
import { useTranslation } from 'react-i18next';
import { ConnectedSpaceInfo } from '@/modules/space/types';
import FormGroup from '@/components/common/FormGroup';
import FormLabel from '@/components/common/FormLabel';
import { fetchImage, postFile } from '@/api/common';
import { useOpenLayers, useProjectRegister } from '@/modules/project/hook';
import ManageFooter from '@/components/common/ManageFooter';
import InvalidAlert from '@/components/InvalidAlert';
import { postFloorFile, postFloorInfo } from '@/api/space';
import { useUser } from '@/modules/user/hook';
import AlertModal from '@/components/common/modal/AlertModal';
import {
  PROJECT_ONBOARDING_CATEGORY_FLOOR_PLAN_IMAGE,
  ProjectStepData,
} from '@/modules/project/types';
import { putProjectStepData } from '@/api/project';
import { CommonUtils } from '@/utils';
import UploadProgressStatusIndicator from '../common/UploadProgressStatusIndicator';

type ProjectFloorPlanRegisterProps = {
  spaceInfo: ConnectedSpaceInfo | null;
  onChangeStatus: (status: PaneStatus) => void;
};

function ProjectFloorPlanRegister({
  spaceInfo,
  onChangeStatus,
}: ProjectFloorPlanRegisterProps) {
  const { t } = useTranslation();
  const { user } = useUser();
  const { projectInfo, handleSetOnboarding } = useProjectRegister();
  const {
    map,
    geoImage,
    transform,
    handleChangeGeoImageAdjustment,
  } = useOpenLayers();

  const [inputs, setInputs] = useState<{
    floorPlanName: string;
    floor: number;
  }>({
    floorPlanName: '',
    floor: 1,
  });
  const [file, setFile] = useState<File | null>(null);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [progressStatus, setProgressStatus] = useState<number>(0);

  useEffect(() => {
    return () => handleInitialGeoImage();
  }, []);

  useEffect(() => {
    const view = map?.getView();
    if (spaceInfo && view) {
      view.setCenter([spaceInfo.lng, spaceInfo.lat]);
      view.setZoom(13);
    }
  }, [spaceInfo]);

  const handleInitialGeoImage = () => {
    handleChangeGeoImageAdjustment(undefined);
    geoImage.layer.setOpacity(1);
    geoImage.remove();
  };

  const handlePostImage = async (file: File) => {
    const imgId = await postFile(
      file,
      CommonUtils.uploadProgressConfig(setProgressStatus)
    );
    if (imgId) {
      setFile(file);
      const imgUrl = await fetchImage(imgId);

      geoImage.draw(
        {
          imageCenter: [spaceInfo?.lng || 0, spaceInfo?.lat || 0],
          imageScale: [1, 1],
          imageRotate: 0,
          url: imgUrl,
          opacity: GEO_IMAGE_DEFAULT_OPACITY,
        },
        () => {
          map?.getView().fit(geoImage.layer.getExtent());
          handleChangeGeoImageAdjustment(true);
          transform.draw?.call(null);
        }
      );
    }
  };

  const handleCancelImage = () => {
    setFile(null);
    handleInitialGeoImage();
    setProgressStatus(0);
  };

  const handleClickAdjustment = () => {
    handleChangeGeoImageAdjustment(true);
    transform.draw?.call(null);
  };

  const handleClickCancel = () => {
    onChangeStatus(PANE_STATUS_LIST);
    setProgressStatus(0);
  };

  const handleClickSubmit = async () => {
    setShowInvalidMessage(false);
    const { floorPlanName, floor } = inputs;
    let message = '';
    if (!floorPlanName) {
      message = t('평면도명을 입력해 주세요.');
    } else if (
      spaceInfo?.floorList.find(({ floorValue }) => floorValue === floor)
    ) {
      message = t('이미 등록된 평면도가 있습니다.');
    }

    if (message) {
      setInvalidMessage(message);
      setShowInvalidMessage(true);
      return;
    }

    let isComplete = false;
    const spaceId = spaceInfo?.spaceId;
    if (spaceId) {
      let projectStepData: ProjectStepData = {};
      if (!file) {
        isComplete = await postFloorInfo({
          metaid: spaceId,
          floor: floor,
          mapname: floorPlanName,
          userid: user.userId,
        });
      } else {
        if (file) {
          const layer = geoImage.layer;
          const source = layer.getSource();
          const center = source.getCenter();
          const rotation = source.getRotation();
          const scale = source.getScale();
          const opacity = layer.getOpacity();

          const result = await postFloorFile({
            metaid: spaceId,
            floor: floor,
            mapname: floorPlanName,
            userid: user.userId,
            file,
            cx: center[0],
            cy: center[1],
            imgRotate: rotation,
            imgScalex: scale[0],
            imgScaley: scale[1],
            opacity,
            div: 'insert',
          });

          if (result) {
            if (
              projectInfo.onboardingData &&
              !projectInfo.onboardingData.includes(
                PROJECT_ONBOARDING_CATEGORY_FLOOR_PLAN_IMAGE
              )
            ) {
              projectStepData = {
                ...projectStepData,
                onboardingData: JSON.stringify([
                  ...projectInfo.onboardingData,
                  PROJECT_ONBOARDING_CATEGORY_FLOOR_PLAN_IMAGE,
                ]),
              };
            }
            isComplete = true;
          }
        }
      }

      if (projectInfo.produceStepData) {
        projectStepData = {
          ...projectStepData,
          produceStepData: null,
        };
      }

      if (CommonUtils.isEmptyObject(projectStepData)) {
        isComplete = true;
      } else {
        const projectId = projectInfo.projectId;
        const putStepDataResult =
          projectId && (await putProjectStepData(projectId, projectStepData));

        if (putStepDataResult) {
          projectStepData.onboardingData &&
            handleSetOnboarding(JSON.parse(projectStepData.onboardingData));
          isComplete = true;
        }
      }
    }

    if (isComplete) {
      onChangeStatus(PANE_STATUS_LIST);
    } else {
      setAlertMessage(t('서버와 통신 중 오류가 발생했습니다'));
      setShowAlertModal(true);
    }
  };

  return (
    <>
      <div className="container-fluid border-bottom-1 mb-4 py-4">
        <div className="d-flex align-items-center">
          <a className="circle-pin pr-2" onClick={handleClickCancel}>
            <MaterialIcon name={'arrow_back'} />
          </a>
          <h3 className="mb-0">{t('평면도 등록')}</h3>
        </div>
      </div>
      <div className="container-fluid">
        <FormGroup>
          <label className="form-label">{t('평면도 등록 건물')}</label>
          <input
            type="text"
            className="form-line"
            placeholder={spaceInfo?.spaceName}
            disabled={true}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel
            essential={true}
            fontSizeApply={false}
            textKey={'평면도명'}
          />
          <input
            type="text"
            className="form-line"
            placeholder={t('평면도명을 입력해 주세요.')}
            value={inputs.floorPlanName}
            onChange={(e) =>
              setInputs({
                ...inputs,
                floorPlanName: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <FormLabel essential={true} fontSizeApply={false} textKey={'층'} />
          <input
            type="number"
            className="form-line"
            value={inputs.floor}
            onKeyDown={(e) => {
              const key = e.key;
              if (key === '.' || key === '0') {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let value = e.target.value;
              if (value.match(/\./)) {
                value = value.replace(/./g, '');
              }
              let number = 1;
              if (value) {
                number = Number(value);
                if (!number) {
                  if (inputs.floor > 0) {
                    number = -1;
                  } else {
                    number = 1;
                  }
                }
              }

              setInputs({
                ...inputs,
                floor: number,
              });
            }}
          />
        </FormGroup>
        <FormGroup className={'over-txt'}>
          <FormLabel textKey={'평면도 이미지'} />
          {!file && (
            <>
              {progressStatus ? (
                <UploadProgressStatusIndicator
                  progressStatus={progressStatus}
                />
              ) : (
                <>
                  <input type="text" className="form-line" disabled={true} />
                  <div className="btn-abs btn-file-att">
                    <label
                      className="btn-light btn-sm m-0"
                      htmlFor="thumbnailFile"
                    >
                      {t('파일 선택')}
                    </label>
                    <input
                      type="file"
                      id="thumbnailFile"
                      accept={'image/*'}
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length) {
                          handlePostImage(e.target.files[0]);
                        } else {
                          handleCancelImage();
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
          {file && (
            <>
              <div className="form-line">
                <div className="att-file">
                  <p className="file-name">{file.name}</p>
                  <div className="bundle">
                    {typeof geoImage.adjustment !== 'undefined' &&
                      geoImage.adjustment === false && (
                        <a
                          className="btn-light btn-sm"
                          onClick={handleClickAdjustment}
                        >
                          {t('조정')}
                        </a>
                      )}
                    <a onClick={handleCancelImage}>
                      <span className="material-icons ml-2">close</span>
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
        </FormGroup>
      </div>
      {showInvalidMessage && <InvalidAlert messageKey={invalidMessage} />}
      <ManageFooter
        type={'save'}
        onClickCancel={handleClickCancel}
        onClickConfirm={handleClickSubmit}
      />
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {alertMessage}
      </AlertModal>
    </>
  );
}

export default ProjectFloorPlanRegister;
