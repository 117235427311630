export const MENU_IDX_DASHBOARD = 0;
export const MENU_IDX_PROJECT = 1;
export const MENU_IDX_SPACE = 2;
export const MENU_IDX_GROUP_SETTINGS = 3;
export const MENU_IDX_GROUP_SETTINGS_USER = 4;
export const MENU_IDX_GROUP_SETTINGS_GROUP = 5;
export const MENU_IDX_ACCOUNT = 6;
export const MENU_IDX_MONITORING = 7;
export const MENU_IDX_PROJECT_MANAGEMENT = 8;
export const MENU_IDX_ASSETS = 9;
export const MENU_IDX_ACCIDENT = 10;
export const MENU_IDX_CAMPAIGN = 11;
export const MENU_IDX_EVENT = 12;
export const MENU_IDX_SENSOR = 13;
export const MENU_IDX_MESSAGE = 14;
export const MENU_IDX_STATISTICS = 15;

export const MENU_NAME_DASHBOARD = 'home';
export const MENU_NAME_MONITORING = 'monitoring';
export const MENU_NAME_ASSETS = 'assets';
export const MENU_NAME_ACCIDENT = 'accident';
export const MENU_NAME_CAMPAIGN = 'campaign';
export const MENU_NAME_EVENT = 'event';
export const MENU_NAME_SENSOR = 'sensor';
export const MENU_NAME_STATISTICS = 'statistics';
export const MENU_NAME_MESSAGE = 'message';

export type MenuName =
  | typeof MENU_NAME_DASHBOARD
  | typeof MENU_NAME_MONITORING
  | typeof MENU_NAME_ACCIDENT
  | typeof MENU_NAME_CAMPAIGN
  | typeof MENU_NAME_ASSETS
  | typeof MENU_NAME_EVENT
  | typeof MENU_NAME_SENSOR
  | typeof MENU_NAME_STATISTICS
  | typeof MENU_NAME_MESSAGE;

export type MenuIdx =
  | typeof MENU_IDX_DASHBOARD
  | typeof MENU_IDX_PROJECT
  | typeof MENU_IDX_SPACE
  | typeof MENU_IDX_GROUP_SETTINGS
  | typeof MENU_IDX_GROUP_SETTINGS_USER
  | typeof MENU_IDX_GROUP_SETTINGS_GROUP
  | typeof MENU_IDX_ACCOUNT
  | typeof MENU_IDX_MONITORING
  | typeof MENU_IDX_PROJECT_MANAGEMENT
  | typeof MENU_IDX_ACCIDENT
  | typeof MENU_IDX_CAMPAIGN
  | typeof MENU_IDX_EVENT
  | typeof MENU_IDX_ASSETS
  | typeof MENU_IDX_SENSOR
  | typeof MENU_IDX_MESSAGE
  | typeof MENU_IDX_STATISTICS;

export const MENU_MAPPING: {
  [menuName: string]: MenuIdx;
} = {
  [MENU_NAME_DASHBOARD]: MENU_IDX_DASHBOARD,
  [MENU_NAME_MONITORING]: MENU_IDX_MONITORING,
  [MENU_NAME_CAMPAIGN]: MENU_IDX_CAMPAIGN,
  [MENU_NAME_ASSETS]: MENU_IDX_ASSETS,
  [MENU_NAME_ACCIDENT]: MENU_IDX_ACCIDENT,
  [MENU_NAME_EVENT]: MENU_IDX_EVENT,
  [MENU_NAME_SENSOR]: MENU_IDX_SENSOR,
  [MENU_NAME_STATISTICS]: MENU_IDX_STATISTICS,
  [MENU_NAME_MESSAGE]: MENU_IDX_MESSAGE,
};

export type Menu = {
  menuIdx: MenuIdx;
  iconName: string;
  titleKey: string;
  active: boolean;
  display: boolean;
  wide: boolean;
};

export const RIGHT_PANE_VIEW_MESSAGE = 0;

export type RightPaneView = undefined | typeof RIGHT_PANE_VIEW_MESSAGE;

export const FLOAT_PANE_STATISTICS = 0;
export type FloatPaneView = undefined | typeof FLOAT_PANE_STATISTICS;

export const INFO_PANE_CAMPAIGN_REGISTER = 0;
export const INFO_PANE_ACCIDENT_REGISTER = 1;
export const INFO_PANE_LIDAR_LINK = 2;
export type InfoPaneView =
  | undefined
  | typeof INFO_PANE_CAMPAIGN_REGISTER
  | typeof INFO_PANE_ACCIDENT_REGISTER
  | typeof INFO_PANE_LIDAR_LINK;

export type Pane = {
  left: {
    show: boolean;
  };
  float: {
    show: boolean;
    view: FloatPaneView;
  };
  right: {
    show: boolean;
    view: RightPaneView;
  };
  info: {
    show: boolean;
    view: InfoPaneView;
  };
};

export type LayerPopup = {
  show: boolean;
};

export type SetupState = {
  menus: Menu[];
  sidebar: {
    left: {
      expand: boolean;
    };
  };
  pane: Pane;
  layerPopup: LayerPopup;
  windowPopupList: Window[];
  pushReceiveFlag: boolean;
};

export const SET_UP_CHANGE_LEFT_PANE_SHOW = 'SET_UP/CHANGE_LEFT_PANE_SHOW' as const;
export const SET_UP_MENU_ACTIVE = 'SET_UP/MENU_ACTIVE' as const;
export const SET_UP_MENU_RESET_ACTIVE = 'SET_UP/MENU_RESET_ACTIVE' as const;
export const SET_UP_INIT = 'SET_UP/INIT' as const;
export const SET_UP_CHANGE_FLOAT_PANE_SHOW = 'SET_UP/CHANGE_FLOAT_PANE_SHOW' as const;
export const SET_UP_CHANGE_RIGHT_PANE_SHOW = 'SET_UP/CHANGE_RIGHT_PANE_SHOW' as const;
export const SET_UP_CHANGE_LEFT_SIDE_BAR_EXPAND = 'SET_UP/CHANGE_LEFT_SIDE_BAR_EXPAND' as const;
export const SET_UP_CHANGE_LAYER_POPUP_SHOW = 'SET_UP/CHANGE_LAYER_POPUP_SHOW' as const;
export const SET_UP_CHANGE_INFO_PANE_SHOW = 'SET_UP/CHANGE_INFO_PANE_SHOW' as const;
export const SET_UP_CHANGE_PUSH_RECEIVE_FLAG = 'SET_UP/CHANGE_PUSH_RECEIVE_FLAG' as const;
export const SET_UP_SET_WINDOW_POPUP = 'SET_UP/SET_WINDOW_POPUP' as const;

export type Action =
  | {
      type: typeof SET_UP_CHANGE_LEFT_PANE_SHOW;
      show: boolean;
    }
  | {
      type: typeof SET_UP_MENU_ACTIVE;
      menuIdx: number;
    }
  | {
      type: typeof SET_UP_MENU_RESET_ACTIVE;
    }
  | {
      type: typeof SET_UP_INIT;
    }
  | {
      type: typeof SET_UP_CHANGE_FLOAT_PANE_SHOW;
      show: boolean;
      view?: FloatPaneView;
    }
  | {
      type: typeof SET_UP_CHANGE_RIGHT_PANE_SHOW;
      show: boolean;
      view?: RightPaneView;
    }
  | {
      type: typeof SET_UP_CHANGE_LEFT_SIDE_BAR_EXPAND;
      expand: boolean;
    }
  | {
      type: typeof SET_UP_CHANGE_LAYER_POPUP_SHOW;
      show: boolean;
    }
  | {
      type: typeof SET_UP_CHANGE_INFO_PANE_SHOW;
      show: boolean;
      view?: InfoPaneView;
    }
  | {
      type: typeof SET_UP_CHANGE_PUSH_RECEIVE_FLAG;
    }
  | {
      type: typeof SET_UP_SET_WINDOW_POPUP;
      windowPopup: Window;
    };
