export type UserSessionStorage = {
  accessToken: string;
  refreshToken: string;
};

export type CompanyInfo = {
  companyName: string;
  department: string;
  businessType: string;
  companyScale: string;
  businessField: string;
};

export type UserType = 'OWNER' | 'NORMAL';

export type User = CompanyInfo & {
  userName: string;
  userId: string;
  userType: UserType;
  profileImgId: string;
  profileImgUrl?: string;
};

export type UserInfo = CompanyInfo & {
  username: string;
  userId: string;
  userType: UserType;
  profileImgId: string;
};

export type UserState = User;

export const USER_REFRESH = 'USER/REFRESH' as const;
export const USER_SET = 'USER/SET' as const;

export type Action =
  | {
      type: typeof USER_REFRESH;
    }
  | {
      type: typeof USER_SET;
      data: User;
    };
