import React, { useEffect } from 'react';
import { CommonUtils } from '@/utils';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type PaginationProps = {
  page: number;
  pageSize?: number;
  totalCount: number;
  onChangePage?: (page: number) => void;
};

function Pagination({
  page,
  pageSize,
  totalCount,
  onChangePage,
}: PaginationProps) {
  const { t } = useTranslation();
  const { pages, totalPage } = CommonUtils.calcSelectPages(
    page,
    totalCount,
    pageSize
  );

  const handleDirection = (weight: number) => {
    onChangePage?.call(null, page + weight);
  };

  useEffect(() => {
    if (totalPage) {
      if (page > totalPage) {
        onChangePage?.call(null, 1);
      }
    }
  }, [totalPage]);

  if (!totalCount) {
    return <></>;
  }

  return (
    <div className="pagination align-items-center justify-content-center">
      <a
        className={classNames('page-link btn-prev', {
          disabled: page === 1,
        })}
        onClick={() => handleDirection(-1)}
      >
        <span className="material-icons">chevron_left</span>
        <span>{t('이전')}</span>
      </a>
      <div className="d-flex align-items-center justify-content-center px-3">
        <select
          className="form-line"
          value={page}
          onChange={(e) => onChangePage?.call(null, Number(e.target.value))}
        >
          {pages.map((pageNumber) => (
            <option key={pageNumber} value={pageNumber}>
              {pageNumber}
            </option>
          ))}
        </select>
        <em>{totalPage}</em>
      </div>
      <a
        className={classNames('page-link btn-next', {
          disabled: page === totalPage,
        })}
        onClick={() => handleDirection(1)}
      >
        <span>{t('다음')}</span>
        <span className="material-icons">chevron_right</span>
      </a>
    </div>
  );
}

export default React.memo(Pagination);
