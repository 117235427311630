import React, { useEffect, useRef } from 'react';
import { Feature, Map } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import {
  fetchMonitoringFloorLidarStatus,
  initMonitoringLidarWebSocket,
} from '@/api/monitoring';
import { useHomeMenu, useHomeSelected } from '@/modules/home/hook';
import {
  MONITORING_LIDAR_INTERVAL,
  OPEN_LAYERS_Z_INDEX_DRAWING,
} from '@/utils/constants/common';
import {
  MONITORING_TYPE_LIDAR,
  MonitoringLidar,
  MonitoringLidarPosition,
  MonitoringLidarType,
  MonitoringWebSocketData,
} from '@/modules/monitoring/types';
import { OpenLayersUtils } from '@/utils/OpenLayersUtils';
import * as TWEEN from '@tweenjs/tween.js';
import { Circle, LineString, Point } from 'ol/geom';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { useMonitoring, useMonitoringLidar } from '@/modules/monitoring/hook';
import {
  useOpenLayersForklift,
  useOpenLayersResource,
} from '@/modules/openlayers/hook';
import { useInfoPane } from '@/modules/setup/hook';
import {
  INFO_PANE_LIDAR_LINK,
  MENU_IDX_MONITORING,
  MenuIdx,
} from '@/modules/setup/types';
import _ from 'lodash';
import { Client } from '@stomp/stompjs';
import { Config } from '@/config';

let paintedLidarList: MonitoringLidar[] = [];
let timer: ReturnType<typeof setInterval> | null = null;
let isRequestAnimationFrame = false;
let isTimerPause = true;

type MonitoringLidarDrawingProps = {
  map: Map | null;
};

function getWebSocketClient() {
  const client = new Client({
    brokerURL: `${Config.web_socket_host}/trace/ws`,
    reconnectDelay: 5000,
    debug: (msg) => {
      // console.log(msg);
    },
    onStompError: (receipt) => {
      console.log('Broker reported error: ' + receipt.headers['message']);
      console.log('Additional details: ' + receipt.body);
    },
  });

  return client;
}

function MonitoringLidarDrawing({ map }: MonitoringLidarDrawingProps) {
  const { activeMenuIdx } = useHomeMenu();
  const { project, building, floor } = useHomeSelected();
  const { monitoringType } = useMonitoring();
  const { handleChangeShow } = useInfoPane();
  const {
    selectedLidarObjectList,
    handleSetLidarObjectList,
    handleSelectLidarObject,
    handleRemoveSelectedLidarObject,
    handleInitSelectedLidarObjectList,
  } = useMonitoringLidar();
  const { handleSetMonitoringLidarResource } = useOpenLayersResource();
  const { getSpeedTextStyle, getCircleStyle } = useOpenLayersForklift();
  const lidarPositionLayerRef = useRef<VectorLayer | null>(null);
  const personLayerRef = useRef<VectorLayer | null>(null);
  const bikeLayerRef = useRef<VectorLayer | null>(null);
  const carLayerRef = useRef<VectorLayer | null>(null);
  const forkliftLayerRef = useRef<VectorLayer | null>(null);
  const localSelectedLidarObjectListRef = useRef<MonitoringLidar[]>([]);
  const clientRef = useRef<Client | null>(null);
  const menuIdxRef = useRef<MenuIdx>();
  let subscribeTime = -1;

  useEffect(() => {
    menuIdxRef.current = activeMenuIdx;
  }, [activeMenuIdx]);

  useEffect(() => {
    if (map) {
      const lidarPositionSource = new VectorSource({ wrapX: false });
      const lidarPositionLayer = new VectorLayer({
        source: lidarPositionSource,
        visible: true,
      });
      lidarPositionLayerRef.current = lidarPositionLayer;
      const personSource = new VectorSource({ wrapX: false });
      const personLayer = new VectorLayer({
        source: personSource,
        visible: true,
        zIndex: OPEN_LAYERS_Z_INDEX_DRAWING,
      });
      personLayerRef.current = personLayer;
      const bikeSource = new VectorSource({ wrapX: false });
      const bikeLayer = new VectorLayer({
        source: bikeSource,
        visible: true,
        zIndex: OPEN_LAYERS_Z_INDEX_DRAWING,
      });
      bikeLayerRef.current = bikeLayer;
      const carSource = new VectorSource({ wrapX: false });
      const carLayer = new VectorLayer({
        source: carSource,
        visible: true,
        zIndex: OPEN_LAYERS_Z_INDEX_DRAWING,
      });
      carLayerRef.current = carLayer;
      const forkliftSource = new VectorSource({ wrapX: false });
      const forkliftLayer = new VectorLayer({
        source: forkliftSource,
        visible: true,
        zIndex: OPEN_LAYERS_Z_INDEX_DRAWING,
      });
      forkliftLayerRef.current = forkliftLayer;
      map.addLayer(lidarPositionLayer);
      map.addLayer(personLayer);
      map.addLayer(bikeLayer);
      map.addLayer(carLayer);
      map.addLayer(forkliftLayer);

      handleSetMonitoringLidarResource(personLayer);

      /*
      map.on('click', (e) => {
        if (menuIdxRef.current === MENU_IDX_MONITORING) {
          map.forEachFeatureAtPixel(e.pixel, (featureLike, layer) => {
            const lidar = featureLike.get('realtime_lidar') as MonitoringLidar;
            if (lidar) {
              const localSelectedLidarObjectList =
                localSelectedLidarObjectListRef.current;
              const lidarType = lidar.lidarType;
              if (lidarType === 'PED') {
                const source = getLidarVectorSource(lidarType);
                const feature = source?.getFeatureById(lidar.serialId);
                if (feature) {
                  if (localSelectedLidarObjectList.length === 1) {
                    const firstSelectedLidarObject =
                      localSelectedLidarObjectList[0];
                    const firstSelectedFeature = source?.getFeatureById(
                      firstSelectedLidarObject.serialId
                    );
                    if (firstSelectedFeature) {
                      const point1 = firstSelectedFeature.getGeometry() as Point;
                      const point2 = feature.getGeometry() as Point;
                      const coordinates1 = point1.getCoordinates();
                      const coordinates2 = point2.getCoordinates();
                      const linkFeature = new Feature(
                        new LineString([coordinates1, coordinates2])
                      );
                      linkFeature.setId('link-lidar-object');
                      linkFeature.setStyle(
                        new Style({
                          stroke: new Stroke({
                            color: '#3B11B2',
                            width: 2,
                          }),
                        })
                      );
                      source?.addFeature(linkFeature);
                    }
                  }
                  // handleSelectLidarObject(lidar);
                  return true;
                }
              }
            }
          });
        }
      });
      */
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      handleDisconnectWebSocket();
      if (monitoringType === MONITORING_TYPE_LIDAR) {
        handleConnectWebSocket();
      } else {
        handleDisconnectWebSocket();
      }
    }

    return () => {
      handleDisconnectWebSocket();
    };
  }, [monitoringType, floor, map]);

  useEffect(() => {
    /*
    const length = selectedLidarObjectList.length;
    const personLayer = personLayerRef.current;
    if (!length) {
      handleUnLink();
      if (personLayer) {
        personLayer
          .getSource()
          .getFeatures()
          .forEach((feature) => {
            const featureStyle = feature.getStyle();
            if (featureStyle && featureStyle instanceof Style) {
              const style = featureStyle as Style;
              style.setImage(
                new Icon({
                  src: `/static/images/${getLidarIconUrlV2('PED', false)}`,
                })
              );
            }
          });
      }
    } else {
      if (personLayer) {
        personLayer
          .getSource()
          .getFeatures()
          .forEach((feature) => {
            const id = feature.getId();
            const featureStyle = feature.getStyle();
            if (featureStyle && featureStyle instanceof Style) {
              const style = featureStyle as Style;
              style.setImage(
                new Icon({
                  src: `/static/images/${getLidarIconUrlV2(
                    'PED',
                    Boolean(
                      selectedLidarObjectList.find(
                        ({ serialId }) => serialId === id
                      )
                    )
                  )}`,
                })
              );
            }
          });
      }
      if (length === 2) {
        handleChangeShow(true, INFO_PANE_LIDAR_LINK);
      } else {
        handleUnLink();
      }
    }
    localSelectedLidarObjectListRef.current = selectedLidarObjectList;

     */
  }, [selectedLidarObjectList]);

  const handleUnLink = () => {
    const personLayer = personLayerRef.current;
    if (personLayer) {
      const source = personLayer.getSource();
      const linkFeature = source.getFeatureById('link-lidar-object');
      linkFeature && source.removeFeature(linkFeature);
    }
    handleChangeShow(false);
  };

  const handleClearData = () => {
    handleSetLidarObjectList([]);
    const personLayer = personLayerRef.current;
    if (personLayer) {
      personLayer.getSource().clear();
    }
    const bikeLayer = bikeLayerRef.current;
    if (bikeLayer) {
      bikeLayer.getSource().clear();
    }
    const carLayer = carLayerRef.current;
    if (carLayer) {
      carLayer.getSource().clear();
    }
    const forkliftLayer = forkliftLayerRef.current;
    if (forkliftLayer) {
      forkliftLayer.getSource().clear();
    }
  };

  const handleInitData = (isStart: boolean) => {
    if (!isStart) {
      handleSetLidarObjectList([]);
    }
    const lidarPositionLayer = lidarPositionLayerRef.current;
    if (lidarPositionLayer) {
      lidarPositionLayer.getSource().clear();
      lidarPositionLayer.setVisible(isStart);
    }
    const personLayer = personLayerRef.current;
    if (personLayer) {
      personLayer.getSource().clear();
      personLayer.setVisible(isStart);
    }
    const bikeLayer = bikeLayerRef.current;
    if (bikeLayer) {
      bikeLayer.getSource().clear();
      bikeLayer.setVisible(isStart);
    }
    const carLayer = carLayerRef.current;
    if (carLayer) {
      carLayer.getSource().clear();
      carLayer.setVisible(isStart);
    }
    const forkliftLayer = forkliftLayerRef.current;
    if (forkliftLayer) {
      forkliftLayer.getSource().clear();
      forkliftLayer.setVisible(isStart);
    }

    handleInitSelectedLidarObjectList();
  };

  const handleTweenAnimate = () => {
    if (isRequestAnimationFrame) {
      requestAnimationFrame(handleTweenAnimate);
    }
    TWEEN.update();
  };

  const handleCheckSubscribe = () => {
    if (subscribeTime > -1) {
      const currentTime = new Date().getTime();
      const seconds = Math.floor((currentTime - subscribeTime) / 1000);
      if (seconds >= 2) {
        handleClearData();
      }
    }
  };

  const handleSubscribe = () => {
    clientRef.current?.subscribe(
      `/sub/realtime/monitoring/${project?.projectId}/${building?.mappingId}/${floor?.mapId}`,
      (message) => {
        try {
          subscribeTime = new Date().getTime();
          const body = message.body;
          const data = JSON.parse(body) as MonitoringWebSocketData;
          const realtimeData = data.realtimeData;
          if (realtimeData) {
            handleSetLidarObjectList(realtimeData);
            const lidarList: MonitoringLidar[] = [];
            realtimeData.forEach((lidar, i) => {
              if (i === 0) {
                handleDrawFeatureLidarPosition({
                  lidarLng: lidar.lidarLng,
                  lidarLat: lidar.lidarLat,
                });
              }

              const [lng, lat] = OpenLayersUtils.convertCoordinates([
                lidar.lng,
                lidar.lat,
              ]);
              lidar.lng = lng;
              lidar.lat = lat;
              lidarList.push(lidar);
            });
            handleRemoveFeatureLidar(lidarList);
            handleDrawFeatureLidar(lidarList);
          }
        } catch (e) {
          console.error(e);
        }
      }
    );
  };

  const handleConnectWebSocket = () => {
    if (!clientRef.current) {
      handleInitData(true);
      const client = getWebSocketClient();
      client.onConnect = async () => {
        if (project && building && floor) {
          const result = await initMonitoringLidarWebSocket(
            project.projectId,
            building.mappingId,
            floor.mapId
          );
          if (result) {
            isRequestAnimationFrame = true;
            requestAnimationFrame(handleTweenAnimate);
            if (!timer) {
              isTimerPause = false;
              timer = setInterval(handleCheckSubscribe, 2000);
            }
            handleSubscribe();
          }
        }
      };
      client.activate();
      clientRef.current = client;
    }
  };

  const handleDisconnectWebSocket = () => {
    const client = clientRef.current;
    if (client) {
      client.onDisconnect = () => {
        isRequestAnimationFrame = false;
        handleInitData(false);
        if (timer) {
          isTimerPause = true;
          clearInterval(timer);
          timer = null;
        }
      };
      client.deactivate();
      clientRef.current = null;
    }
  };

  const handleStartInterval = () => {
    if (!timer) {
      isTimerPause = false;
      isRequestAnimationFrame = true;
      requestAnimationFrame(handleTweenAnimate);
      handleInitData(true);
      handleFetchLidarList();
      timer = setInterval(handleFetchLidarList, MONITORING_LIDAR_INTERVAL);
    }
  };

  const handleStopInterval = () => {
    if (timer) {
      isTimerPause = true;
      isRequestAnimationFrame = false;
      clearInterval(timer);
    }
    handleInitData(false);
    timer = null;
  };

  const handleFetchLidarList = async () => {
    if (project && building && floor && !isTimerPause) {
      const floorLidarStatus = await fetchMonitoringFloorLidarStatus({
        projectId: project.projectId,
        mappingId: building.mappingId,
        mapId: floor.mapId,
      });

      if (floorLidarStatus) {
        handleDrawFeatureLidarPosition(floorLidarStatus.realtimeLIDARPosition);
        const floorLidar = floorLidarStatus.realtimeUsersByLIDARForFloor;
        const lidarList: MonitoringLidar[] = [];
        for (const lidarId in floorLidar) {
          const lidar = floorLidar[lidarId];
          const [lng, lat] = OpenLayersUtils.convertCoordinates([
            lidar.lng,
            lidar.lat,
          ]);
          lidar.lng = lng;
          lidar.lat = lat;
          lidarList.push(lidar);
        }

        handleRemoveFeatureLidar(lidarList);
        handleDrawFeatureLidar(lidarList);
      }
    }
  };

  const handleDrawFeatureLidarPosition = ({
    lidarLng,
    lidarLat,
  }: MonitoringLidarPosition) => {
    const lidarPositionLayer = lidarPositionLayerRef.current;
    if (lidarPositionLayer) {
      const source = lidarPositionLayer.getSource();
      const feature = source.getFeatureById('lidar_position');
      if (lidarLng && lidarLat) {
        const [lng, lat] = OpenLayersUtils.convertCoordinates([
          lidarLng,
          lidarLat,
        ]);
        if (feature) {
          feature.setGeometry(new Point([lng, lat]));
        } else {
          const newFeature = new Feature(new Point([lng, lat]));
          newFeature.setId('lidar_position');
          const style = new Style({
            image: new Icon({
              src: `/static/images/lidar_pin.svg`,
              anchor: [0.5, 34], // 0.5 default, 34 image height
              anchorXUnits: IconAnchorUnits.FRACTION,
              anchorYUnits: IconAnchorUnits.PIXELS,
            }),
          });
          newFeature.setStyle(style);
          source.addFeature(newFeature);
        }
      }
    }
  };

  const handleRemoveFeatureLidar = (lidarList: MonitoringLidar[]) => {
    const removeLidarList = paintedLidarList.filter(
      ({ serialId: paintedLidarId }) =>
        !lidarList.find(({ serialId }) => paintedLidarId === serialId)
    );

    removeLidarList.forEach((lidar) => {
      // handleRemoveSelectedLidarObject(lidar);
      const serialId = lidar.serialId;
      const lidarType = lidar.lidarType;
      const source = getLidarVectorSource(lidarType);
      if (source) {
        const featureById = source.getFeatureById(serialId);
        if (featureById) {
          source.removeFeature(featureById);
        }

        // TODO: 사람도 임시 포함
        if (lidarType === 'PED' || lidarType === 'FORKLIFT') {
          const directionFeature = source.getFeatureById(
            `${serialId}-lidar-direction`
          );
          directionFeature && source.removeFeature(directionFeature);
          const circleFeature = source.getFeatureById(
            `${serialId}-lidar-circle`
          );
          circleFeature && source.removeFeature(circleFeature);
        }
      }
    });
  };

  const handleDrawFeatureLidar = (lidarList: MonitoringLidar[]) => {
    lidarList.forEach((lidar) => {
      // handleDrawBasicFeature(lidar);
      if (lidar.lidarType === 'CYCLE' || lidar.lidarType === 'CAR') {
        handleDrawBasicFeature(lidar);
      } else {
        handleDrawCustomFeature(lidar);
      }
    });
    paintedLidarList = lidarList;
  };

  const handleDrawCustomFeature = (lidar: MonitoringLidar) => {
    const serialId = lidar.serialId;
    const lidarType = lidar.lidarType;
    // const isShowArrow = lidarType === 'FORKLIFT';
    const isShowArrow = false;
    const source = getLidarVectorSource(lidarType);
    if (source) {
      const iconFeatureById = source.getFeatureById(serialId);
      const directionFeatureById = source.getFeatureById(
        `${serialId}-lidar-direction`
      );
      const circleFeatureById = source.getFeatureById(
        `${serialId}-lidar-circle`
      );
      const coordinates = {
        x: lidar.lng,
        y: lidar.lat,
      };
      if (iconFeatureById && directionFeatureById && circleFeatureById) {
        const directionStyle = directionFeatureById.getStyle();
        if (directionStyle && directionStyle instanceof Style) {
          const style = directionStyle as Style;
          // isShowArrow && style.getImage().setRotation(Math.PI);
          // style.getText().getFill().setColor(getSpeedTextStyle(true));
          style
            .getText()
            .setText(`${Math.round(lidar.velocity * 100) / 100} km/h`);
        }
        // circleFeatureById.setStyle(getCircleStyle(false));

        const linkFeatureById = source.getFeatureById('link-lidar-object');
        let selectedIndex = -1;
        if (linkFeatureById) {
          selectedIndex = localSelectedLidarObjectListRef.current.findIndex(
            (selectedLidarObject) => selectedLidarObject.serialId === serialId
          );
        }

        const point = iconFeatureById.getGeometry() as Point;
        const curCoordinates = point.getCoordinates();
        new TWEEN.Tween({ x: curCoordinates[0], y: curCoordinates[1] })
          .to(coordinates, 100)
          .onUpdate(({ x, y }) => {
            const coordinate = [x, y];
            (iconFeatureById.getGeometry() as Point).setCoordinates(coordinate);
            (directionFeatureById.getGeometry() as Point).setCoordinates(
              coordinate
            );
            (circleFeatureById.getGeometry() as Circle).setCenter(coordinate);

            if (linkFeatureById && selectedIndex > -1) {
              const lineString = linkFeatureById.getGeometry() as LineString;
              const newCoordinates = _.clone(lineString.getCoordinates());
              newCoordinates.splice(selectedIndex, 1, coordinate);
              lineString.setCoordinates(newCoordinates);
            }
          })
          .start();
      } else {
        const directionFeature = new Feature(
          new Point([coordinates.x, coordinates.y])
        );
        directionFeature.setId(`${serialId}-lidar-direction`);
        const directionStyle = new Style({
          text: new Text({
            text: `${Math.round(lidar.velocity * 100) / 100} km/h`,
            offsetY: -35,
            fill: new Fill({
              color: getSpeedTextStyle(false),
            }),
            stroke: new Stroke({
              color: '#FFFFFF',
              width: 4,
            }),
            font: 'bold 14px Spoqa Han Sans Neo',
          }),
        });
        if (isShowArrow) {
          directionStyle.setImage(
            new Icon({
              src: `/static/images/object_direction.svg`,
              rotateWithView: true,
              // rotation: Math.PI,
            })
          );
        }
        directionFeature.setStyle(directionStyle);
        source.addFeature(directionFeature);
        const circleFeature = new Feature(
          new Circle([coordinates.x, coordinates.y], 2)
        );
        circleFeature.setStyle(getCircleStyle(false));
        circleFeature.setId(`${serialId}-lidar-circle`);
        circleFeature.set('realtime_lidar', lidar);
        source.addFeature(circleFeature);
        const iconFeature = new Feature(
          new Point([coordinates.x, coordinates.y])
        );
        iconFeature.setStyle(
          new Style({
            image: new Icon({
              src: `/static/images/${getLidarIconUrlV2(lidarType, false)}`,
            }),
          })
        );
        iconFeature.setId(serialId);
        iconFeature.set('realtime_lidar', lidar);
        source.addFeature(iconFeature);
      }
    }
  };

  const handleDrawBasicFeature = (lidar: MonitoringLidar) => {
    const serialId = lidar.serialId;
    const source = getLidarVectorSource(lidar.lidarType);
    if (source) {
      const featureById = source.getFeatureById(serialId);
      const coordinates = {
        x: lidar.lng,
        y: lidar.lat,
      };

      if (featureById) {
        const point = featureById.getGeometry() as Point;
        const curCoordinates = point.getCoordinates();
        new TWEEN.Tween({ x: curCoordinates[0], y: curCoordinates[1] })
          .to(coordinates, 100)
          .onUpdate(({ x, y }) => {
            featureById.setGeometry(new Point([x, y]));
          })
          .start();
      } else {
        const iconFeature = new Feature(
          new Point([coordinates.x, coordinates.y])
        );
        iconFeature.setId(serialId);
        iconFeature.set('realtime_lidar', lidar);
        const iconImageUrl = getLidarIconUrl(lidar.lidarType);
        const iconStyle = new Style({
          image: new Icon({
            src: `/static/images/${iconImageUrl}`,
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchor: [0.5, 34], // 0.5 default, 34 image height
            anchorYUnits: IconAnchorUnits.PIXELS,
          }),
        });
        iconFeature.setStyle(iconStyle);
        source.addFeature(iconFeature);
      }
    }
  };

  const getLidarVectorSource = (lidarType: MonitoringLidarType) => {
    let source: VectorSource | null = null;
    switch (lidarType) {
      case 'PED':
        source = personLayerRef.current?.getSource() || null;
        break;
      case 'CAR':
        source = carLayerRef.current?.getSource() || null;
        break;
      case 'CYCLE':
        source = bikeLayerRef.current?.getSource() || null;
        break;
      case 'FORKLIFT':
        source = forkliftLayerRef.current?.getSource() || null;
        break;
    }

    return source;
  };

  const getLidarIconUrl = (lidarType: MonitoringLidarType) => {
    let imageUrl = '';
    switch (lidarType) {
      case 'PED':
        imageUrl = 'person_pin.svg';
        break;
      case 'CAR':
        imageUrl = 'car_pin.svg';
        break;
      case 'CYCLE':
        imageUrl = 'bike_pin.svg';
        break;
    }

    return imageUrl;
  };

  const getLidarIconUrlV2 = (
    lidarType: MonitoringLidarType,
    selected: boolean
  ) => {
    let imageUrl = '';
    switch (lidarType) {
      case 'PED':
        imageUrl = selected ? 'human_active.svg' : 'human.svg';
        break;
      case 'CAR':
        imageUrl = 'car_pin.svg';
        break;
      case 'CYCLE':
        imageUrl = 'bike_pin.svg';
        break;
      case 'FORKLIFT':
        imageUrl = 'forklift.svg';
        break;
    }

    return imageUrl;
  };

  return <></>;
}

export default React.memo(MonitoringLidarDrawing);
