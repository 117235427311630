import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProjectRegister } from '@/modules/project/hook';
import ProjectInfoForm from '@/components/project/ProjectInfoForm';
import { postProject, putProject } from '@/api/project';
import {
  PROJECT_ONBOARDING_CATEGORY_PROJECT_INFO,
  PROJECT_POSITIONING_TYPE_HYBRID,
  PROJECT_POSITIONING_TYPE_LIDAR,
  PROJECT_VIEW_TYPE_2D_3D,
  ProjectPositioningType,
  ProjectThumbnail,
  ProjectViewType,
} from '@/modules/project/types';
import InvalidAlert from '@/components/InvalidAlert';
import { useHistory } from 'react-router-dom';
import ManageFooter from '@/components/common/ManageFooter';
import { ValidUtils } from '@/utils';
import ConfirmModal from '@/components/common/modal/ConfirmModal';
import ProjectRegisterHeader from '@/components/project/ProjectRegisterHeader';

function ProjectInfoRegister() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    projectInfo,
    handleChangeRegisterStep,
    handleSetProjectInfo,
  } = useProjectRegister();
  const originalProjectNameRef = useRef('');
  const projectNameRef = useRef('');
  const [thumbnail, setThumbnail] = useState<ProjectThumbnail>({
    imgId: '',
    projectImgFileName: '',
  });
  const [note, setNote] = useState('');
  const [positioningType, setPositioningType] = useState<
    ProjectPositioningType[]
  >([PROJECT_POSITIONING_TYPE_HYBRID, PROJECT_POSITIONING_TYPE_LIDAR]);
  const [viewType, setViewType] = useState<ProjectViewType>(
    PROJECT_VIEW_TYPE_2D_3D
  );
  const [invalidMessage, setInvalidMessage] = useState('');
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    originalProjectNameRef.current = projectInfo.projectName || '';
    setNote(projectInfo.note || '');
    setThumbnail({
      imgId: projectInfo.imgId || '',
      projectImgFileName: projectInfo.projectImgFileName || '',
    });
    projectInfo.positioningType &&
      setPositioningType(
        projectInfo.positioningType.split(',') as ProjectPositioningType[]
      );
    projectInfo.viewType &&
      setViewType(projectInfo.viewType as ProjectViewType);

    return () => {
      const originalProjectName = originalProjectNameRef.current;
      const projectName = projectNameRef.current;

      if (originalProjectName !== projectName) {
        handleSetProjectInfo({
          ...projectInfo,
          projectName: originalProjectName,
        });
      }
    };
  }, []);

  useEffect(() => {
    if (projectInfo.projectName) {
      projectNameRef.current = projectInfo.projectName;
    }
  }, [projectInfo.projectName]);

  const handleSubmit = async () => {
    const projectName = projectInfo.projectName;
    setShowInvalidMessage(false);
    let message = '';
    if (!projectName) {
      message = t('프로젝트명을 입력해 주세요.');
    } else {
      const lengthValid = projectName.length >= 3 && projectName.length <= 70;
      const nameValid = ValidUtils.validProjectName(
        projectName.replaceAll(' ', '')
      );
      if (!lengthValid || !nameValid) {
        message = t(
          '프로젝트명은 3~70자 이내 한글/영문/숫자로만 작성해 주세요.'
        );
      }
    }

    if (!message && !positioningType.length) {
      message = '측위 방식을 선택해 주세요.';
    }

    if (message) {
      setInvalidMessage(message);
      setShowInvalidMessage(true);
      return;
    }

    let projectId = projectInfo.projectId;
    let isPass = false;
    if (projectId) {
      const result = await putProject(projectId, {
        projectName,
        note,
        imgId: thumbnail.imgId,
        positioningType: positioningType.join(','),
        viewType,
      });

      if (result) {
        handleSetProjectInfo({
          ...projectInfo,
          projectName,
          imgId: thumbnail.imgId,
          projectImgFileName: thumbnail.projectImgFileName,
          note,
          positioningType: positioningType.join(','),
          viewType,
        });
        isPass = true;
      }
    } else {
      const registerProjectId = await postProject({
        projectName,
        note,
        imgId: thumbnail.imgId,
        positioningType: positioningType.join(','),
        viewType,
      });

      if (registerProjectId) {
        projectId = registerProjectId;
        handleSetProjectInfo({
          ...projectInfo,
          projectId,
          projectName,
          imgId: thumbnail.imgId,
          projectImgFileName: thumbnail.projectImgFileName,
          note,
          produceEndFlag: false,
          produceStepData: {
            step: 2,
          },
          onboardingData: [PROJECT_ONBOARDING_CATEGORY_PROJECT_INFO],
          positioningType: positioningType.join(','),
          viewType,
        });
        isPass = true;
      }
    }

    if (isPass) {
      if (projectName) {
        originalProjectNameRef.current = projectName;
      }
      history.replace(`/project/${projectId}`);
      handleChangeRegisterStep(2);
    }
  };

  return (
    <>
      <ProjectRegisterHeader />
      <ProjectInfoForm
        thumbnail={thumbnail}
        note={note}
        positioningType={positioningType}
        viewType={viewType}
        onChangeThumbnail={setThumbnail}
        onChangeNote={setNote}
        onChangePositioningType={setPositioningType}
        onChangeViewType={setViewType}
      />
      {showInvalidMessage && <InvalidAlert messageKey={invalidMessage} />}
      <ManageFooter
        type={'save'}
        onClickCancel={() => setShowConfirmModal(true)}
        onClickConfirm={handleSubmit}
      />
      <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onClickConfirm={() => history.replace('/home')}
      >
        <p
          className="m-0"
          dangerouslySetInnerHTML={{
            __html: t(
              '화면 이동시 작성중인 정보는 저장되지 않습니다.<br/>진행 중인 내용을 취소하고 화면을 이동하시겠습니까?'
            ),
          }}
        ></p>
      </ConfirmModal>
    </>
  );
}

export default ProjectInfoRegister;
