import React, { ReactNode } from 'react';
import classNames from 'classnames';

type LayoutColumnGroupProps = {
  className?: string;
  children?: ReactNode;
};

function LayoutColumnGroup({ className, children }: LayoutColumnGroupProps) {
  return (
    <div className={classNames('pl-col-group', className)}>{children}</div>
  );
}

export default LayoutColumnGroup;
