import React, { useEffect, useRef, useState } from 'react';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import Map from 'ol/Map';
import { useFloorObject } from '@/modules/floor/hook';
import {
  useOpenLayersGeofence,
  useOpenLayersResource,
} from '@/modules/openlayers/hook';
import { Select } from 'ol/interaction';
import { Feature } from 'ol';
import { useMonitoringGeofence } from '@/modules/monitoring/hook';
import { useHomeMenu } from '@/modules/home/hook';
import { MENU_IDX_MONITORING, MenuIdx } from '@/modules/setup/types';

type GeofenceOpenLayersMapProps = {
  map: Map | null;
};

function GeofenceOpenLayersMap({ map }: GeofenceOpenLayersMapProps) {
  const { activeMenuIdx } = useHomeMenu();
  const { geofenceList } = useFloorObject();
  const { handleSetGeofenceResource } = useOpenLayersResource();
  const { getGeofenceStyle } = useOpenLayersGeofence();
  const { handleSetGeofenceFeature } = useMonitoringGeofence();
  const [geofenceLayer, setGeofenceLayer] = useState<VectorLayer | null>(null);
  const menuIdxRef = useRef<MenuIdx>();

  useEffect(() => {
    menuIdxRef.current = activeMenuIdx;
  }, [activeMenuIdx]);

  useEffect(() => {
    if (map) {
      const geofenceSource = new VectorSource({ wrapX: false });
      const geofenceLayer = new VectorLayer({
        source: geofenceSource,
        style: (featureLike) => getGeofenceStyle(featureLike as Feature, true),
      });
      setGeofenceLayer(geofenceLayer);
      map.addLayer(geofenceLayer);
      const select = new Select({
        layers: [geofenceLayer],
        condition: (e) => {
          const menuIdx = menuIdxRef.current;
          if (menuIdx === MENU_IDX_MONITORING && e.type === 'click') {
            return true;
          }
          return false;
        },
      });
      select.getFeatures().on('add', (e) => {
        const feature = e.element as Feature;
        handleSetGeofenceFeature(feature);
        select.getFeatures().clear();
      });
      map.addInteraction(select);
      handleSetGeofenceResource(geofenceLayer);
    }
  }, [map]);

  useEffect(() => {
    if (geofenceLayer) {
      const source = geofenceLayer.getSource();
      source.clear();

      geofenceList.forEach((geofenceInfo) => {
        const feature = geofenceInfo.feature;
        feature && source.addFeature(feature);
      });
    }
  }, [geofenceLayer, geofenceList]);

  return <></>;
}

export default React.memo(GeofenceOpenLayersMap);
