import {
  Action,
  User,
  USER_REFRESH,
  USER_SET,
  UserSessionStorage,
  UserState,
} from '@/modules/user/types';
import { SESSION_STORAGE_KEY_USER_INFO } from '@/utils/constants/common';
import axios from 'axios';

export function userReducer(state: UserState, action: Action) {
  switch (action.type) {
    case USER_SET:
      return {
        ...action.data,
      };
    case USER_REFRESH:
      const item = sessionStorage.getItem(SESSION_STORAGE_KEY_USER_INFO);
      if (item) {
        const parse: UserSessionStorage = JSON.parse(item);
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${parse.accessToken}`;
      }
      return state;
  }
}
