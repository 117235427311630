import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOpenLayersDraw } from '@/modules/openlayers/hook';
import { useInfoPane } from '@/modules/setup/hook';

function CampaignRegisterInfoPane() {
  const { t } = useTranslation();
  const { handleChangeShow } = useInfoPane();
  const { handleDrawCancel } = useOpenLayersDraw();

  const handleClickCancel = () => {
    handleDrawCancel();
    handleChangeShow(false);
  };

  return (
    <>
      <div className="title">
        <h4>
          <span className="text-identity">{t('캠페인 위치 지정 중')}</span>
        </h4>
      </div>
      <div className="cover">
        <a className="btn-cancel" onClick={handleClickCancel}>
          {t('취소')}
        </a>
      </div>
    </>
  );
}

export default CampaignRegisterInfoPane;
