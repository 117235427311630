import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageHistory } from '@/modules/message/types';
import { useHomeMenu, useHomeProject } from '@/modules/home/hook';
import { fetchPagingMessages } from '@/api/message';
import moment from 'moment';
import { getLanguageCode } from '@/locales/i18n';
import Preloader from '@/components/common/Preloader';
import { usePush, useRightPane } from '@/modules/setup/hook';
import MaterialIcon from '@/components/common/MaterialIcon';
import { MENU_IDX_MESSAGE, MENU_IDX_MONITORING } from '@/modules/setup/types';
import classNames from 'classnames';

function MessageRightPane() {
  const { t } = useTranslation();
  const { activeMenuIdx, handleSetMenu } = useHomeMenu();
  const { project } = useHomeProject();
  const { handleChangeShow } = useRightPane();
  const { pushReceiveFlag } = usePush();
  const [loading, setLoading] = useState(false);
  const [messageHistoryList, setMessageHistoryList] = useState<
    MessageHistory[]
  >([]);
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    handleFetchMessages();
  }, [pushReceiveFlag]);

  const handleFetchMessages = async () => {
    setLoading(true);
    if (project) {
      const { content } = await fetchPagingMessages({
        projectId: project.projectId,
        parentType: 'LIDAR_DETECTION',
        page: 1,
        pageSize: 10,
      });
      if (!mountedRef.current) {
        return;
      }
      setMessageHistoryList(content);
    }
    setLoading(false);
  };

  const handleClosePane = () => {
    handleChangeShow(activeMenuIdx === MENU_IDX_MONITORING);
  };

  const handleClickShowDetailMessage = () => {
    handleSetMenu(MENU_IDX_MESSAGE);
  };

  return (
    <>
      <div className="container-fluid border-bottom-1 py-4">
        <div className="d-flex align-items-center">
          <h3 className="mb-0">{t('알림')}</h3>
          <a className="ml-auto" onClick={handleClosePane}>
            <MaterialIcon name={'close'} />
          </a>
        </div>
      </div>
      <div className="container-fluid py-4">
        <div className="d-flex flex-column w-100">
          {loading && <Preloader />}
          {!loading && (
            <>
              <ul className="list-col mb-0">
                {!messageHistoryList.length && (
                  <div className="text-center py-4">
                    <span className="material-icons-outlined text-50 font-size-32pt">
                      info
                    </span>
                    <p className="m-0 text-50">{t('데이터가 없습니다.')}</p>
                  </div>
                )}
                {messageHistoryList.map((messageHistory) => (
                  <MessageItem
                    key={messageHistory.messageId}
                    {...messageHistory}
                  />
                ))}
              </ul>
              <div className="py-3">
                <a
                  className="btn btn-accent w-100 py-12pt"
                  onClick={handleClickShowDetailMessage}
                >
                  {t('더보기')}
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

type MessageItemProps = MessageHistory;

function MessageItem({
  messageTitle,
  messageContent,
  registDate,
  parentType,
}: MessageItemProps) {
  const { t } = useTranslation();

  const calcRegistDate = () =>
    useMemo(() => {
      const today = new Date();
      const timeValue = new Date(registDate);
      const betweenTime = Math.floor(
        (today.getTime() - timeValue.getTime()) / 1000 / 60
      );

      if (betweenTime < 1)
        return t(`%분 전`, {
          '%': 0,
        });
      if (betweenTime < 60) {
        return t(`%분 전`, {
          '%': betweenTime,
        });
      }

      const betweenTimeHour = Math.floor(betweenTime / 60);
      if (betweenTimeHour < 24) {
        return t(`%시간 전`, {
          '%': betweenTimeHour,
        });
      }

      return moment(registDate).format('YYYY-MM-DD HH:mm:ss');
    }, [registDate, getLanguageCode()]);

  return (
    <li>
      <div className="d-flex align-items-center mb-2">
        <h5
          className={classNames('m-0', {
            'text-identity':
              messageTitle.indexOf('과속') > -1 ||
              messageTitle.indexOf('충돌') > -1,
          })}
        >
          {parentType === 'SENSOR' ? t('센서 알림') : messageTitle}
        </h5>
      </div>
      <p className="text-dark mb-0">{messageContent}</p>
      <small className="text-64">{calcRegistDate()}</small>
    </li>
  );
}

export default MessageRightPane;
