import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type EmptyDataProps = {
  fontAwesomeIcon: IconProp;
  textKey: string;
};

function EmptyData({ fontAwesomeIcon, textKey }: EmptyDataProps) {
  const { t } = useTranslation();

  return (
    <div className="empty-state">
      <p className="empty-icon m-0">
        <FontAwesomeIcon icon={fontAwesomeIcon} />
      </p>
      <em className="empty-txt m-0">{t(textKey)}</em>
    </div>
  );
}

export default EmptyData;
