import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Project, Projects } from '@/modules/project/types';
import ListDropdown from '@/components/common/ListDropdown';
import { deleteProject, fetchProjects } from '@/api/project';
import { ListResult } from '@/modules/common';
import Preloader from '@/components/common/Preloader';
import { FUNCTION_DELETE, FUNCTION_EDIT } from '@/utils/constants/common';
import Pagination from '@/components/common/Pagination';
import { CommonUtils } from '@/utils';
import AlertModal from '@/components/common/modal/AlertModal';
import { useProjectEdit, useProjectRoute } from '@/modules/project/hook';
import ConfirmModal from '@/components/common/modal/ConfirmModal';
import NewlineText from '@/components/common/NewlineText';
import TableEmptyRow from '@/components/common/TableEmptyRow';

type HomeProjectListProps = {
  onReload: () => void;
};

function HomeProjectList({ onReload }: HomeProjectListProps) {
  const { t } = useTranslation();
  const { handleRouteProject } = useProjectRoute();
  const { reloadFlag, handleChangeReloadFlag } = useProjectEdit();
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [projectList, setProjectList] = useState<Projects>([]);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const deleteProjectIdRef = useRef('');
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      handleChangeReloadFlag(undefined);
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    handleFetchProjectList();
  }, [page]);

  const handleFetchProjectList = async () => {
    setLoad(false);
    setProjectList([]);
    setTotalCount(0);
    const result = await fetchProjects(page, '');
    if (!mountedRef.current) {
      return;
    }
    const listResult = result as ListResult<Projects>;
    setProjectList(listResult.content);
    setTotalCount(listResult.totalElements);
    setLoad(true);
  };

  const handleDeleteProject = async (projectId: string) => {
    const result = await deleteProject(projectId);
    if (result) {
      onReload();
      handleFetchProjectList();
      handleChangeReloadFlag(
        typeof reloadFlag === 'undefined' ? true : !reloadFlag
      );
    } else {
      setShowAlertModal(true);
    }
  };

  const handleSelectOptions = (eventKey: string | null, projectId: string) => {
    switch (eventKey) {
      case FUNCTION_EDIT:
        handleRouteProject(projectId, true);
        break;
      case FUNCTION_DELETE:
        deleteProjectIdRef.current = projectId;
        setShowConfirmModal(true);
        break;
    }
  };

  const handleConfirmDeleteProject = () => {
    const deleteProjectId = deleteProjectIdRef.current;
    if (deleteProjectId) {
      handleDeleteProject(deleteProjectId);
      deleteProjectIdRef.current = '';
    }
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center mb-4">
        <div className="flex">
          <h3 className="d-flex align-items-center mb-0 ">
            {t('프로젝트 목록')}
          </h3>
        </div>
      </div>
      <table className="table mb-4 table-nowrap table-center">
        <colgroup>
          <col width="*" />
          <col width="13%" />
          <col width="13%" />
          <col width="5%" />
        </colgroup>
        <thead>
          <tr>
            <th>{t('프로젝트')}</th>
            <th>{t('최근 수정일')}</th>
            <th>{t('생성일')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!load && (
            <tr>
              <td colSpan={4}>
                <Preloader />
              </td>
            </tr>
          )}
          {load && (
            <>
              {!projectList.length && (
                <TableEmptyRow
                  colSpan={4}
                  textKey={'등록된 프로젝트가 없습니다.'}
                />
              )}
              {projectList.map((project) => (
                <ProjectItem
                  key={project.projectId}
                  {...project}
                  onClickItem={() =>
                    handleRouteProject(
                      project.projectId,
                      !project.produceEndFlag
                    )
                  }
                  onSelectOptions={(eventKey) =>
                    handleSelectOptions(eventKey, project.projectId)
                  }
                />
              ))}
            </>
          )}
        </tbody>
      </table>
      <Pagination page={page} totalCount={totalCount} onChangePage={setPage} />
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {t('서버와 통신 중 오류가 발생했습니다')}
      </AlertModal>
      <ConfirmModal
        title={t('프로젝트 삭제')}
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onClickConfirm={handleConfirmDeleteProject}
      >
        <NewlineText
          text={t(
            '프로젝트를 삭제할 시 프로젝트에 포함된 건물 및 평면도 정보와 위치 데이터도 삭제됩니다.\n프로젝트를 삭제하시겠습니까?'
          )}
        />
      </ConfirmModal>
    </>
  );
}

type ProjectItemProps = Project & {
  onClickItem: () => void;
  onSelectOptions: (eventKey: string) => void;
};

function ProjectItem({
  projectName,
  imgUrl,
  updateDate,
  registDate,
  produceEndFlag,
  onClickItem,
  onSelectOptions,
}: ProjectItemProps) {
  const { t } = useTranslation();
  return (
    <tr>
      <td onClick={onClickItem}>
        <div className="d-flex align-items-center mr-16pt">
          <a className="icon-thumb mr-12pt">
            {imgUrl && <img src={imgUrl} />}
            {!imgUrl && <span className="material-icons">factory</span>}
          </a>
          <div className="flex list-els">
            <a
              className="card-title"
              style={{
                color: 'inherit',
                display: 'flex',
              }}
            >
              <h4 className="m-0">{projectName}</h4>
              {!produceEndFlag && (
                <small className="badge-text-accent ml-2">{t('편집중')}</small>
              )}
            </a>
          </div>
        </div>
      </td>
      <td>{CommonUtils.convertDateFormat(updateDate, 'YYYY.MM.DD')}</td>
      <td>{CommonUtils.convertDateFormat(registDate, 'YYYY.MM.DD')}</td>
      <td className="text-right">
        <ListDropdown
          onSelect={(eventKey) => {
            if (eventKey) {
              onSelectOptions(eventKey);
            }
          }}
          showEdit={produceEndFlag}
        />
      </td>
    </tr>
  );
}

export default HomeProjectList;
