import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  PROJECT_ONBOARDING_CATEGORY_BUILDING_INFO,
  PROJECT_ONBOARDING_CATEGORY_EDITOR_PLACE_OBJECT,
  PROJECT_ONBOARDING_CATEGORY_FLOOR_PLAN_IMAGE,
  PROJECT_ONBOARDING_CATEGORY_PROJECT_INFO,
  ProjectOnboardingCategory,
} from '@/modules/project/types';
import { useProjectRegister } from '@/modules/project/hook';

function ProjectRegisterOnboarding() {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const {
    projectInfo: { onboardingData },
  } = useProjectRegister();
  const [onboardingList, setOnboardingList] = useState<
    {
      category: ProjectOnboardingCategory;
      text: string;
      completed: boolean;
    }[]
  >([
    {
      category: PROJECT_ONBOARDING_CATEGORY_PROJECT_INFO,
      text: t('프로젝트 정보를 입력해 주세요.'),
      completed: false,
    },
    {
      category: PROJECT_ONBOARDING_CATEGORY_BUILDING_INFO,
      text: t('건물 정보를 등록해 주세요.'),
      completed: false,
    },
    {
      category: PROJECT_ONBOARDING_CATEGORY_FLOOR_PLAN_IMAGE,
      text: t('평면도 이미지를 업로드해 주세요.'),
      completed: false,
    },
    {
      category: PROJECT_ONBOARDING_CATEGORY_EDITOR_PLACE_OBJECT,
      text: t('지도 편집 툴로 오브젝트를 배치해 주세요.'),
      completed: false,
    },
  ]);

  useEffect(() => {
    if (onboardingData) {
      setOnboardingList(
        onboardingList.map((onboardingItem) => {
          if (onboardingData.includes(onboardingItem.category)) {
            onboardingItem.completed = true;
          }
          return onboardingItem;
        })
      );
    }
  }, [onboardingData]);

  return (
    <div className="alert-set-guide" style={{ maxWidth: '100%' }}>
      <Alert show={show}>
        <div className="alert-header">
          <h4>{t('프로젝트 설정 가이드')}</h4>
          <button
            type="button"
            className="alert-close"
            onClick={() => setShow(false)}
          >
            <span className="material-icons">close</span>
          </button>
        </div>
        <div className="alert-contents">
          {onboardingList.map(({ text, completed }, i) => (
            <div key={i} className="form-group mb-3 d-flex align-items-center">
              <div
                className={classNames(
                  'custom-control custom-checkbox checkbox-md checkbox-rounded d-flex',
                  {
                    'cmp-guide': completed,
                  }
                )}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  disabled={true}
                  checked={completed}
                />
                <label className="custom-control-label">{text}</label>
              </div>
            </div>
          ))}
        </div>
      </Alert>
    </div>
  );
}

export default React.memo(ProjectRegisterOnboarding);
