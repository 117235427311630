import {
  ASSETS_EQUIPMENT_CATEGORY_ID,
  ASSETS_HUMAN_CATEGORY_ID,
  ASSETS_LOGISTICS_CATEGORY_ID,
  AssetType,
} from '@/modules/assets/types';
import React, { ReactElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type AssetLeftPaneTableRowProps = {
  asset: AssetType;
  setShowModal: (showModal: boolean) => void;
  setAssetInfoId: (assetInfoId: string) => void;
};

function AssetLeftPaneTableRow({
  asset,
  setShowModal,
  setAssetInfoId,
}: AssetLeftPaneTableRowProps): ReactElement {
  const category = asset.categories.filter(
    (category) => category.depth === 2
  )[0];

  // 모달 표기
  const showModal = () => {
    setShowModal(true);
    setAssetInfoId(asset.assetInfoId);
  };

  return (
    <tr>
      <td width="30%" className="text-center">
        <a onClick={showModal} className="d-inline-flex">
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="asset-serial-id">{asset.serialId}</Tooltip>}
          >
            <span className="ellipsis">{asset.serialId}</span>
          </OverlayTrigger>
        </a>
      </td>
      <td width="50%">
        <a
          onClick={showModal}
          className="d-flex"
          data-toggle="modal"
          data-target="#detailItem"
        >
          <div className="d-inline-flex align-items-center">
            <span className="material-icons-outlined text-40">
              {category.categoryId === ASSETS_LOGISTICS_CATEGORY_ID &&
                'inventory_2'}
              {category.categoryId === ASSETS_EQUIPMENT_CATEGORY_ID &&
                'handyman'}
              {category.categoryId === ASSETS_HUMAN_CATEGORY_ID && 'person'}
              {/* Todo: 카테고리 확장 이후 진행 */}
              {/* {categoryName === '환경센서' && 'air'}
              {categoryName === '온습도센서' && 'thermostat'}
              {categoryName === '배터리' && 'battery_0_bar'} */}
            </span>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="asset-info_name">{asset.assetInfoName}</Tooltip>
              }
            >
              <span className="ml-2 ellipsis">{asset.assetInfoName}</span>
            </OverlayTrigger>
          </div>
        </a>
      </td>
      <td className="text-center" width="20%">
        <ul className="state-item">
          <li className="arry01">
            <span
              // Todo: 기능 개발 이후 실제 상태 (asset.activeFlag) 표기하도록 변경 필요
              className={`state-dot level-item0${true ? `5` : `1`}`}
            ></span>
          </li>
          <li className="arry02">
            {/* Todo: 기능 개발 이후 실제 위치 표기하도록 변경 필요 */}
            {/* <a onClick={showModal} className="d-flex">
            <span className="material-icons-sharp">place</span>
              <span className="material-icons-outlined">place</span>
            </a> */}
          </li>
        </ul>
      </td>
    </tr>
  );
}

export default AssetLeftPaneTableRow;
