import { AssetType } from '@/modules/assets/types';
import React, { ReactElement } from 'react';
import AssetTableHeader from '@/components/project/AssetRegisterTab/AssetList/AssetTableHeader';
import TableEmptyRow from '@/components/common/TableEmptyRow';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Preloader from '@/components/common/Preloader';
import { useTranslation } from 'react-i18next';

type AssetTableProps = {
  fetchedAssetList: AssetType[];
  loading: boolean;
};

function AssetTable({
  fetchedAssetList,
  loading,
}: AssetTableProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="table-bumper">
      <table className="table table-high table-line-none table-sticky mb-5">
        <AssetTableHeader />
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={3}>
                <Preloader />
              </td>
            </tr>
          ) : (
            <>
              {!fetchedAssetList.length && (
                <TableEmptyRow
                  colSpan={3}
                  textKey={t('등록된 자산이 없습니다.')}
                />
              )}
              {fetchedAssetList.map((asset) => (
                <tr key={asset.assetInfoId}>
                  <td width="33%" className="ellipsis">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="asset-serial-id">{asset.serialId}</Tooltip>
                      }
                    >
                      <span className="ellipsis">{asset.serialId}</span>
                    </OverlayTrigger>
                  </td>
                  <td width="33%" className="ellipsis">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="asset-info-name">
                          {asset.assetInfoName}
                        </Tooltip>
                      }
                    >
                      <span className="ellipsis">{asset.assetInfoName}</span>
                    </OverlayTrigger>
                  </td>
                  <td className="text-center" width="33%">
                    {
                      asset.categories.filter(
                        (assetCategory) => assetCategory.depth === 1
                      )[0].categoryName
                    }
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default AssetTable;
