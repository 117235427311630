import { useContext } from 'react';
import {
  AssetSearchOptionType,
  ASSET_SET_PAGED_ASSET_LIST,
  ASSET_SET_SEARCH_OPTION,
  PagedAssetListType,
} from '../assets/types';
import { AssetDispatchContext, AssetStateContext } from './context';

function useAssetState() {
  const state = useContext(AssetStateContext);
  if (!state) throw new Error('AssetState is not found');
  return state;
}

function useAssetDispatch() {
  const dispatch = useContext(AssetDispatchContext);
  if (!dispatch) throw new Error('AssetDispatch is not found');
  return dispatch;
}

// 자산 검색 조건 관련 custom hook
export function useAssetSearchOption(): {
  assetSearchOption: AssetSearchOptionType;
  setAssetSearchOption: (assetSearchOption: AssetSearchOptionType) => void;
} {
  const { assetSearchOption } = useAssetState();
  const dispatch = useAssetDispatch();

  const setAssetSearchOption = (assetSearchOption: AssetSearchOptionType) => {
    dispatch({
      type: ASSET_SET_SEARCH_OPTION,
      assetSearchOption,
    });
  };

  return {
    assetSearchOption,
    setAssetSearchOption,
  };
}

// 검색된 자산 관련 custom hook
export function usePagedAssetList(): {
  pagedAssetList: PagedAssetListType;
  setPagedAssetList: (pagedAssetList: PagedAssetListType) => void;
} {
  const { pagedAssetList } = useAssetState();
  const dispatch = useAssetDispatch();

  const setPagedAssetList = (pagedAssetList: PagedAssetListType) => {
    dispatch({
      type: ASSET_SET_PAGED_ASSET_LIST,
      pagedAssetList,
    });
  };

  return {
    pagedAssetList,
    setPagedAssetList,
  };
}
