import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TermModal from '@/components/TermModal';
import NewlineText from '@/components/common/NewlineText';
import { Alert } from 'react-bootstrap';
import MaterialIcon from '@/components/common/MaterialIcon';
import { SignUpFormProps } from '@/components/SignUpForm';

type SignUpTermsProps = {
  onClickAgree: () => void;
};

function SignUpTerms({ onClickAgree }: SignUpTermsProps) {
  const { t } = useTranslation();

  const [isShowAlert, setShowAlert] = useState(false);

  const [terms, setTerms] = useState<{
    service: boolean;
    privacy: boolean;
    location: boolean;
  }>({
    service: false,
    privacy: false,
    location: true,
  });

  useEffect(() => {
    if (terms.service && terms.privacy && terms.location) {
      setShowAlert(false);
    }
  }, [terms]);

  const [modalData, setModalData] = useState<{
    show: boolean;
    title: string;
    content: string;
  }>({
    show: false,
    title: '',
    content: '',
  });

  const handleChangeAllTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setTerms({
      service: checked,
      privacy: checked,
      location: checked,
    });
  };

  const handleChangeTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerms({
      ...terms,
      [e.target.id]: e.target.checked,
    });
  };

  const handleShowModal = (title: string, content: string) => {
    setModalData({
      show: true,
      title,
      content,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      show: false,
      title: '',
      content: '',
    });
  };

  const handleClickAgree = () => {
    if (terms.service && terms.privacy && terms.location) {
      onClickAgree();
    } else {
      setShowAlert(true);
    }
  };

  return (
    <>
      <div className="title-group mb-5">
        <h3 className="m-0">{t('서비스 약관 동의')}</h3>
        <p className="mt-2 text-50 font-size-16pt">
          {t('와따 플랫폼 서비스 이용 약관에 동의해 주세요.')}
        </p>
      </div>
      <div className="form-group mb-4 border-bottom-1">
        <div className="custom-control custom-checkbox checkbox-lg checkbox-rounded">
          <input
            id="all"
            type="checkbox"
            className="custom-control-input"
            checked={terms.service && terms.privacy && terms.location}
            onChange={handleChangeAllTerm}
          />
          <label
            htmlFor="all"
            className="custom-control-label font-weight-bolder"
          >
            {t('모두 동의합니다')}
          </label>
        </div>
        <p className="desc_check_all text-50">
          {t(
            '서비스 약관, 개인 정보 수집 및 이용, 위치정보 이용 약관에 모두 동의합니다.'
          )}
        </p>
      </div>
      <div className="form-group mb-4 d-flex align-items-center">
        <div className="custom-control custom-checkbox checkbox-lg checkbox-rounded d-flex">
          <input
            id="service"
            type="checkbox"
            className="custom-control-input"
            checked={terms.service}
            onChange={handleChangeTerm}
          />
          <label htmlFor="service" className="custom-control-label">
            {t('[필수] 서비스 약관 동의')}
          </label>
        </div>
        <a
          className="d-flex ml-auto"
          onClick={() =>
            handleShowModal(t('서비스 약관'), t('서비스 약관 내용'))
          }
        >
          <i className="material-icons font-size-16pt">arrow_forward_ios</i>
        </a>
      </div>
      <div className="form-group mb-4 d-flex align-items-center">
        <div className="custom-control custom-checkbox checkbox-lg checkbox-rounded">
          <input
            id="privacy"
            type="checkbox"
            className="custom-control-input"
            checked={terms.privacy}
            onChange={handleChangeTerm}
          />
          <label htmlFor="privacy" className="custom-control-label">
            {t('[필수] 개인 정보 수집 및 이용 동의')}
          </label>
        </div>
        <a
          className="d-flex ml-auto"
          onClick={() =>
            handleShowModal(
              t('개인 정보 수집 및 이용'),
              t('개인 정보 수집 및 이용 내용')
            )
          }
        >
          <i className="material-icons font-size-16pt">arrow_forward_ios</i>
        </a>
      </div>
      {/*
      <div className="form-group mb-5 d-flex align-items-center">
        <div className="custom-control custom-checkbox checkbox-lg checkbox-rounded">
          <input
            id="location"
            type="checkbox"
            className="custom-control-input"
            checked={terms.location}
            onChange={handleChangeTerm}
          />
          <label htmlFor="location" className="custom-control-label">
            {t('[필수] 위치정보 이용약관 동의')}
          </label>
        </div>
        <a
          className="d-flex ml-auto"
          onClick={() => handleShowModal(t('위치정보 이용약관'), '')}
        >
          <i className="material-icons font-size-16pt">arrow_forward_ios</i>
        </a>
      </div>
      */}
      {isShowAlert && (
        <Alert className="alert-soft-accent">
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-8pt">
              <MaterialIcon name={'error_outline'} />
            </div>
            <div className="flex" style={{ minWidth: '180px' }}>
              <small className="text-black-100">
                {t('서비스 약관에 동의해 주세요.')}
              </small>
            </div>
          </div>
        </Alert>
      )}
      <div className="form-group text-center mb-32pt">
        <button
          className="btn btn-block btn-lg btn-accent"
          type="button"
          onClick={handleClickAgree}
        >
          {t('동의')}
        </button>
      </div>
      <TermModal
        show={modalData.show}
        title={modalData.title}
        content={modalData.content}
        onHide={handleCloseModal}
        positiveButton={true}
        onPositiveButtonClick={handleCloseModal}
      />
    </>
  );
}

export default SignUpTerms;
