import React from 'react';
import {
  MONITORING_GEO_OBJECT_TYPE_AREA,
  MonitoringGeoObjectRemains,
} from '@/modules/monitoring/types';

type MonitoringGeoObjectRemainItemProps = {
  geoObjectRemain: MonitoringGeoObjectRemains;
};

function MonitoringGeoObjectRemainItem({
  geoObjectRemain: { gname, drawstyle, gtype },
}: MonitoringGeoObjectRemainItemProps) {
  return (
    <tr>
      <td className="text-center">
        {gtype.toUpperCase() === MONITORING_GEO_OBJECT_TYPE_AREA ? (
          <img src={'/static/images/poi/object_area.svg'} />
        ) : (
          <img src={drawstyle} />
        )}
      </td>
      <td>{gname}</td>
      <td></td>
    </tr>
  );
}

export default MonitoringGeoObjectRemainItem;
