import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '@/components/common/MaterialIcon';
import HistoryButton from '@/components/home/Message/Detail/Info/HistoryButton';
import { MessageHistoryDetail } from '@/modules/message/types';
import moment from 'moment';

type AccessInfoProps = {
  messageDetail: MessageHistoryDetail;
};

function AccessInfo({
  messageDetail: {
    lidarEvent: { lidarId, velocity, logDate },
  },
}: AccessInfoProps) {
  const { t } = useTranslation();

  return (
    <div className="row mb-4">
      <div className="col-12 col-xl-6">
        <dl className="basic-info">
          <dt>{t('대상')}</dt>
          <dd>{lidarId}</dd>
          {/*<dt>{t('장소/위치')}</dt>*/}
          {/*<dd>{areaName}</dd>*/}
          <dt>{t('속도')}</dt>
          <dd>{`${velocity} km/h`}</dd>
        </dl>
      </div>
      <div className="col-12 col-xl-6">
        <dl className="basic-info">
          <dt>{t('시간')}</dt>
          <dd>{moment(logDate).format('YYYY-MM-DD HH:mm:ss')}</dd>
          {/*
          <dt>{t('현장 알림 시간')}</dt>
          <dd>2022.04.13 18:32:24 (1초)</dd>
          <dt>{t('플랫폼 알림 시간')}</dt>
          <dd>2022.04.13 18:32:26 (3초)</dd>
          */}
        </dl>
      </div>
    </div>
  );
}

export default AccessInfo;
