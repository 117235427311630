import React, {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  useReducer,
} from 'react';
import { assetReducer } from './reducer';
import { AssetState, Action } from './types';

export const AssetStateContext = createContext<AssetState | null>(null);
type AssetDispatch = Dispatch<Action>;
export const AssetDispatchContext = createContext<AssetDispatch | null>(null);

const initialState: AssetState = {
  assetSearchOption: {
    projectId: '',
    mappingId: '',
    mapId: '',
    categoryIdList: [],
    searchKeyword: '',
    page: 1,
  },
  pagedAssetList: {
    content: [],
    totalPages: 0,
    totalElements: 0,
    number: 0,
    numberOfElements: 0,
    size: 0,
  },
};

export function AssetContextProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [asset, dispatch] = useReducer(assetReducer, initialState);

  return (
    <AssetDispatchContext.Provider value={dispatch}>
      <AssetStateContext.Provider value={asset}>
        {children}
      </AssetStateContext.Provider>
    </AssetDispatchContext.Provider>
  );
}
