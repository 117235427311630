import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchMonitoringObjects } from '@/api/monitoring';
import { useHomeFloor } from '@/modules/home/hook';
import {
  MONITORING_GEO_OBJECT_TYPE_AREA,
  MONITORING_GEO_OBJECT_TYPE_GEOFENCE,
  MONITORING_GEO_OBJECT_TYPE_POI,
  MONITORING_GEO_OBJECT_TYPE_UTIL,
  MonitoringGeoObjectGroup,
  MonitoringGeoObjectType,
} from '@/modules/monitoring/types';
import MonitoringGeoObjectGroupItem from '@/components/monitoring/MonitoringGeoObjectGroupItem';
import Preloader from '@/components/common/Preloader';
import MonitoringGeoObjectFilter from '@/components/monitoring/MonitoringGeoObjectFilter';

function MonitoringGeoObjectList() {
  const { floor } = useHomeFloor();
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filterList, setFilterList] = useState<MonitoringGeoObjectType[]>([]);
  const [
    geoObjectGroup,
    setGeoObjectGroup,
  ] = useState<MonitoringGeoObjectGroup>({
    area_group: [],
    geofence_group: [],
    geofences: [],
    remains: [],
  });

  useEffect(() => {
    handleFetchObjectList();
  }, [floor, filterList, searchKeyword]);

  const handleFetchObjectList = async () => {
    const isAll = filterList.length === 0;
    setLoading(true);
    floor &&
      setGeoObjectGroup(
        await fetchMonitoringObjects({
          mapid: floor.mapId,
          searchnm: searchKeyword,
          area: isAll
            ? true
            : filterList.includes(MONITORING_GEO_OBJECT_TYPE_AREA),
          geofence: isAll
            ? true
            : filterList.includes(MONITORING_GEO_OBJECT_TYPE_GEOFENCE),
          poi: isAll
            ? true
            : filterList.includes(MONITORING_GEO_OBJECT_TYPE_POI),
          util: isAll
            ? true
            : filterList.includes(MONITORING_GEO_OBJECT_TYPE_UTIL),
        })
      );
    setLoading(false);
  };

  return (
    <>
      <MonitoringGeoObjectFilter
        filterList={filterList}
        onChangeFilterList={setFilterList}
        onSubmit={setSearchKeyword}
      />
      <GeoObjectList loading={loading} geoObjectGroup={geoObjectGroup} />
    </>
  );
}

type GeoObjectListProps = {
  loading: boolean;
  geoObjectGroup: MonitoringGeoObjectGroup;
};

function GeoObjectList({ loading, geoObjectGroup }: GeoObjectListProps) {
  const { t } = useTranslation();

  return (
    <div className="table-bumper">
      <table className="table table-high table-line-none table-sticky m-0">
        <thead>
          <tr>
            <th className="text-center">
              <a className="sort">{t('분류')}</a>
            </th>
            <th colSpan={2} className="text-center">
              <a className="sort">{t('오브젝트명')}</a>
            </th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={3}>
                <Preloader />
              </td>
            </tr>
          )}
          {!loading && (
            <MonitoringGeoObjectGroupItem geoObjectGroup={geoObjectGroup} />
          )}
        </tbody>
      </table>
    </div>
  );
}

export default MonitoringGeoObjectList;
