import axios from 'axios';
import { Config } from '@/config';
import { PAGING_SIZE } from '@/utils/constants/common';
import { ApiResult, ListResult } from '@/modules/common';
import { StatusCodes } from 'http-status-codes';
import { Accident, AccidentCategory } from '@/modules/accident/types';

export async function fetchEmergencyAccidents(projectId: string) {
  const response = await axios.get(
    `${Config.platform_api.uri}/realtime/accidents-all`,
    {
      params: {
        projectId,
      },
    }
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return result.data as Accident[];
  }

  throw new Error('Server Error');
}

type RequestFetchActivation = {
  projectId: string;
  mappingId: string;
  mapId: string;
};

export async function fetchActivationAccidents({
  projectId,
  mappingId,
  mapId,
}: RequestFetchActivation) {
  const response = await axios.get(
    `${Config.platform_api.uri}/realtime/accidents`,
    {
      params: {
        projectId,
        mappingId,
        mapId,
      },
    }
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return result.data as Accident[];
  }

  throw new Error('Server Error');
}

type RequestFetchAccidents = {
  page: number;
  projectId: string;
  mappingId: string;
  mapId: string;
  showType2: string;
};

export async function fetchAccidents(params: RequestFetchAccidents) {
  let accidentList: ListResult<Accident[]> = {
    content: [],
    totalElements: 0,
  };

  try {
    const response = await axios.get(`${Config.platform_api.uri}/accidents`, {
      params: {
        ...params,
        pageSize: PAGING_SIZE,
      },
    });
    const result: ApiResult<ListResult<Accident[]>> = response.data;
    if (result.status === StatusCodes.OK && result.data) {
      accidentList = {
        content: result.data.content,
        totalElements: result.data.totalElements,
      };
    }
  } catch (e) {}

  return accidentList;
}

export async function fetchAccident(eventId: string) {
  const response = await axios.get(
    `${Config.platform_api.uri}/accidents/${eventId}`
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return result.data as Accident;
  }

  throw new Error('Server Error');
}

export type RequestPostAccident = {
  projectId: string;
  targetMappingId: string;
  targetMapId: string;
  targetAreaId?: string;
  targetGeomType?: 'OUTER' | 'INNER';
  imgId?: string;
  eventTitle?: string;
  eventContent?: string;
  activeFlag: boolean;
  geomType?: 'CIRCLE' | 'POLYGON';
  geom?: string;
  lat?: number;
  lng?: number;
  radius?: number;
  outerKey?: string;
  eventDetailCategory: AccidentCategory;
};

export async function postAccident(data: RequestPostAccident) {
  try {
    const response = await axios.post(
      `${Config.platform_api.uri}/accidents`,
      data
    );
    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      return result.data as string;
    }
  } catch (e) {}

  return null;
}

export type RequestPutAccident = {
  targetAreaId?: string;
  targetGeomType?: 'OUTER' | 'INNER';
  imgId?: string;
  eventTitle?: string;
  eventContent?: string;
  geomType?: 'CIRCLE' | 'POLYGON';
  geom?: string;
  lat?: number;
  lng?: number;
  radius?: number;
  outerKey?: string;
  eventDetailCategory: AccidentCategory;
};

export async function putAccident(eventId: string, data: RequestPutAccident) {
  const response = await axios.put(
    `${Config.platform_api.uri}/accidents/${eventId}`,
    data
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return result.data;
  }

  throw new Error('Server Error');
}

export async function putAccidentInactive(eventId: string) {
  const response = await axios.put(
    `${Config.platform_api.uri}/accidents/off/${eventId}`
  );

  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return result.data;
  }

  throw new Error('Server Error');
}

export async function deleteAccident(eventId: string) {
  const response = await axios.delete(
    `${Config.platform_api.uri}/accidents/${eventId}`
  );

  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return result.data;
  }

  throw new Error('Server Error');
}
