import React, { CSSProperties } from 'react';

type InputNumberProps = {
  className?: string;
  style?: CSSProperties;
  value?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
};

function InputNumber({
  className,
  style,
  value,
  min = 0,
  max,
  disabled,
  onChange,
}: InputNumberProps) {
  return (
    <input
      type="number"
      className={className}
      style={style}
      min={min}
      max={max}
      value={value}
      disabled={disabled}
      onKeyDown={(e) => {
        const key = e.key;
        if (key === '.') {
          e.preventDefault();
        }
      }}
      onChange={(e) => {
        let value = e.target.value;
        if (value.match(/\./)) {
          value = value.replace(/./g, '');
        }
        let number = Number(value);
        if (number < min) {
          number = min;
        } else if (typeof max !== 'undefined' && number > max) {
          number = max;
        }

        e.target.value = number.toString();

        onChange?.call(null, number);
      }}
      autoComplete={'off'}
    />
  );
}

export default InputNumber;
