import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ko from './files/ko.json';
import ja from './files/ja.json';
import en from './files/en.json';

const resources = {
  ja: { translation: ja },
  ko: { translation: ko },
  en: { translation: en },
};

const userLanguage = window.navigator.language || window.navigator.userLanguage;

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || userLanguage || 'ko',
  fallbackLng: 'ko',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export function getLanguageCode() {
  const language = i18n.language;
  return language.length > 2 ? language.slice(0, 2) : language;
}

export default i18n;
export const languages = ['ko', 'ja'] as const;
export type Languages = typeof languages[number];
