import React, { useEffect, useState } from 'react';
import { ConnectedSpaceInfo } from '@/modules/space/types';
import {
  FUNCTION_DELETE,
  FUNCTION_EDIT,
  PANE_STATUS_EDIT,
  PANE_STATUS_REGISTER,
  PaneStatus,
} from '@/utils/constants/common';
import { useTranslation } from 'react-i18next';
import Preloader from '@/components/common/Preloader';
import ProjectRegisterHeader from '@/components/project/ProjectRegisterHeader';
import {
  fetchFloors,
  postDeleteFloorPlan,
  ResponseFloorInfo,
} from '@/api/space';
import { useUser } from '@/modules/user/hook';
import NewlineText from '@/components/common/NewlineText';
import AlertModal from '@/components/common/modal/AlertModal';
import { CommonUtils } from '@/utils';
import ListDropdown from '@/components/common/ListDropdown';
import classNames from 'classnames';
import { Config } from '@/config';
import { useOpenLayers, useProjectRegister } from '@/modules/project/hook';
import MapEditButton from '@/components/common/MapEditButton';

type ProjectFloorPlanListProps = {
  loadSpaceInfoList: boolean;
  spaceInfoList: ConnectedSpaceInfo[];
  selectedSpaceInfo: ConnectedSpaceInfo | null;
  onChangeSpaceInfo: (spaceInfo: ConnectedSpaceInfo | null) => void;
  onChangeFloorInfo: (floorInfo: ResponseFloorInfo) => void;
  onChangeStatus: (status: PaneStatus) => void;
};

function ProjectFloorPlanList({
  loadSpaceInfoList,
  spaceInfoList,
  selectedSpaceInfo,
  onChangeSpaceInfo,
  onChangeFloorInfo,
  onChangeStatus,
}: ProjectFloorPlanListProps) {
  const { t } = useTranslation();
  const {
    user: { userId },
  } = useUser();
  const {
    projectInfo: { projectId },
    floorInfo: { list: floorList, selectedFloorId },
    handleSetFloorList,
    handleChangeSelectedFloorId,
  } = useProjectRegister();
  const { map, geoImage } = useOpenLayers();
  const [load, setLoad] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    if (spaceInfoList.length) {
      onChangeSpaceInfo(spaceInfoList[0]);
    }
  }, [spaceInfoList]);

  useEffect(() => {
    handleFetchFloorPlanList();
    handleChangeSelectedFloorId('');
    geoImage.remove();
  }, [selectedSpaceInfo]);

  useEffect(() => {
    geoImage.remove();
    const floor =
      selectedFloorId &&
      floorList.find(({ map_id }) => map_id === selectedFloorId);
    floor && handleSelectFloor(floor);
  }, [selectedFloorId]);

  useEffect(() => {
    return () => {
      geoImage.remove();
      handleSetFloorList([]);
      handleChangeSelectedFloorId('');
    };
  }, []);

  const handleFetchFloorPlanList = async () => {
    setLoad(false);
    if (selectedSpaceInfo) {
      const result = await fetchFloors(userId, selectedSpaceInfo.spaceId);
      if (result) {
        handleSetFloorList(result);
      } else {
        setShowAlertModal(true);
      }
    } else {
      handleSetFloorList([]);
    }
    setLoad(true);
  };

  const handleSelectFloor = ({
    filename,
    cx,
    cy,
    scalex,
    scaley,
    rotation,
    opacity,
  }: ResponseFloorInfo) => {
    if (filename) {
      geoImage.draw(
        {
          url: `${Config.space_api.uri}/bprint/${filename}`,
          imageCenter: [Number(cx), Number(cy)],
          imageScale: [Number(scalex), Number(scaley)],
          imageRotate: Number(rotation),
          opacity: Number(opacity),
        },
        () => {
          map?.getView().fit(geoImage.layer.getExtent());
        }
      );
    } else {
      if (selectedSpaceInfo) {
        map
          ?.getView()
          .setCenter([selectedSpaceInfo.lng, selectedSpaceInfo.lat]);
      }
    }
  };

  const handleClickEdit = (floorInfo: ResponseFloorInfo) => {
    onChangeFloorInfo(floorInfo);
    onChangeStatus(PANE_STATUS_EDIT);
  };

  const handleClickDelete = async (mapId: string) => {
    const result = await postDeleteFloorPlan({
      userid: userId,
      del: true,
      mapid: mapId,
    });

    if (result) {
      if (selectedFloorId === mapId) {
        geoImage.remove();
        handleChangeSelectedFloorId('');
      }
      handleFetchFloorPlanList();
    } else {
      setShowAlertModal(true);
    }
  };

  const handleClickFloorPlanRegister = () => {
    onChangeStatus(PANE_STATUS_REGISTER);
  };

  if (!loadSpaceInfoList) {
    return <Preloader />;
  }

  return (
    <>
      <ProjectRegisterHeader />
      {spaceInfoList.length > 0 && (
        <div className="plf-floor-add">
          <div className="form-cell">
            <select
              name="category"
              className="form-line"
              value={selectedSpaceInfo?.spaceId}
              onChange={(e) => {
                onChangeSpaceInfo(
                  spaceInfoList.find(
                    ({ spaceId }) => spaceId === e.target.value
                  ) || null
                );
              }}
            >
              <option value="">{t('건물을 선택해 주세요.')}</option>
              {spaceInfoList.map(({ spaceId, spaceName }) => (
                <option key={spaceId} value={spaceId}>
                  {spaceName}
                </option>
              ))}
            </select>
          </div>
          {selectedSpaceInfo && (
            <div className="btn-cell">
              <a
                className="add-item text-identity"
                onClick={handleClickFloorPlanRegister}
              >
                <span className="material-icons">add</span>
                {t('평면도 등록')}
              </a>
            </div>
          )}
        </div>
      )}
      <div className="bg-light h-100">
        <div className="plf-floor-card p-4 position-relative">
          {spaceInfoList.length === 0 && (
            <>
              <a className="plf-area-card add-item cursor-none no-highlight">
                <p>{t('건물을 등록해 주세요.')}</p>
              </a>
            </>
          )}
          {spaceInfoList.length > 0 && selectedSpaceInfo && (
            <>
              {!load && <Preloader />}
              {load && (
                <>
                  {floorList.length === 0 && (
                    <a
                      className="plf-area-card add-item"
                      onClick={handleClickFloorPlanRegister}
                    >
                      <p>{t('평면도를 등록해 주세요')}</p>
                    </a>
                  )}
                  {floorList.map((floorInfo) => (
                    <FloorPlanItem
                      key={floorInfo.map_id}
                      projectId={projectId || ''}
                      metaId={selectedSpaceInfo?.spaceId || ''}
                      clickedFloorId={selectedFloorId}
                      floorInfo={floorInfo}
                      onClickItem={() =>
                        handleChangeSelectedFloorId(floorInfo.map_id)
                      }
                      onClickEdit={() => handleClickEdit(floorInfo)}
                      onClickDelete={() => handleClickDelete(floorInfo.map_id)}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        <NewlineText text={t('서버와 통신 중 오류가 발생했습니다')} />
      </AlertModal>
    </>
  );
}

type FloorPlanItemProps = {
  projectId: string;
  metaId: string;
  clickedFloorId: string;
  floorInfo: ResponseFloorInfo;
  onClickItem: () => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
};

function FloorPlanItem({
  projectId,
  metaId,
  clickedFloorId,
  floorInfo: {
    map_id,
    map_name,
    map_floor,
    poi_cnt,
    area_cnt,
    util_cnt,
    asset_cnt,
    orifilename,
  },
  onClickItem,
  onClickEdit,
  onClickDelete,
}: FloorPlanItemProps) {
  const { t } = useTranslation();

  const handleSelectFunction = (eventKey: string | null) => {
    switch (eventKey) {
      case FUNCTION_EDIT:
        onClickEdit();
        break;
      case FUNCTION_DELETE:
        onClickDelete();
        break;
    }
  };

  return (
    <div
      className={classNames('plf-area-card', {
        active: map_id === clickedFloorId,
      })}
      onClick={onClickItem}
    >
      <div className="plf-area-top">
        <span
          className={classNames('material-icons-outlined mr-1', {
            'text-danger': orifilename,
          })}
        >
          upload_file
        </span>
        <h4>{map_name}</h4>
        <div className="left-cell">
          <span className="floor-num">
            {CommonUtils.convertFloorFormat(map_floor)}
          </span>
          <ListDropdown onSelect={handleSelectFunction} />
        </div>
      </div>
      <div className="plf-area-content">
        <ul className="plf-floor-card-info">
          <li>
            <span>{t('POI')}</span>
            <em>{poi_cnt}</em>
          </li>
          <li>
            <span>{t('지오펜스')}</span>
            <em>{area_cnt}</em>
          </li>
          <li>
            <span>{t('측위 장비')}</span>
            <em>{util_cnt}</em>
          </li>
          <li>
            <span>{t('자원')}</span>
            <em>{asset_cnt}</em>
          </li>
        </ul>
        {projectId && metaId && (
          <div className="d-flex justify-content-end">
            <MapEditButton
              projectId={projectId}
              metaId={metaId}
              mapId={map_id}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectFloorPlanList;
