import React, { createContext, Dispatch, ReactNode, useReducer } from 'react';
import {
  Action,
  MENU_IDX_PROJECT_MANAGEMENT,
  SetupState,
} from '@/modules/setup/types';
import { setupReducer } from '@/modules/setup/reducer';

export const SetupStateContext = createContext<SetupState | null>(null);
type SetupDispatch = Dispatch<Action>;
export const SetupDispatchContext = createContext<SetupDispatch | null>(null);

export const initialState: SetupState = {
  menus: [
    {
      menuIdx: MENU_IDX_PROJECT_MANAGEMENT,
      iconName: 'topic',
      titleKey: '프로젝트',
      active: false,
      display: false,
      wide: false,
    },
  ],
  sidebar: {
    left: {
      expand: true,
    },
  },
  pane: {
    left: {
      show: true,
    },
    float: {
      show: false,
      view: undefined,
    },
    right: {
      show: false,
      view: undefined,
    },
    info: {
      show: false,
      view: undefined,
    },
  },
  layerPopup: {
    show: false,
  },
  windowPopupList: [],
  pushReceiveFlag: false,
};

export function SetupContextProvider({ children }: { children: ReactNode }) {
  const [setup, dispatch] = useReducer(setupReducer, initialState);

  return (
    <SetupDispatchContext.Provider value={dispatch}>
      <SetupStateContext.Provider value={setup}>
        {children}
      </SetupStateContext.Provider>
    </SetupDispatchContext.Provider>
  );
}
