import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import MaterialIcon from '@/components/common/MaterialIcon';
import { ModalProps } from '@/modules/common';

type ConfirmModalProps = ModalProps & {
  title?: string;
  children?: ReactNode;
  onClickConfirm: () => void;
};

function ConfirmModal({
  title,
  children,
  show,
  onHide,
  onClickConfirm,
}: ConfirmModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={() => {
        //
      }}
      centered={true}
    >
      <ModalHeader>
        <ModalTitle as={'h4'}>
          {title && title}
          {!title && t('알림')}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <p className="m-0">{children}</p>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-light" onClick={onHide}>
          {t('취소')}
        </button>
        <button
          type="button"
          className="btn btn-accent"
          onClick={() => {
            onClickConfirm();
            onHide();
          }}
        >
          {t('확인')}
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmModal;
