import React from 'react';
import InputCustomRadio from '@/components/common/InputCustomRadio';
import {
  EVENT_TARGET_TYPE_ASSET,
  EVENT_TARGET_TYPE_GEO,
  EventDetailCategory,
  EventTargetType,
} from '@/modules/event/types';

type EventCategoryFormProps = {
  eventTargetType: EventTargetType;
  eventDetailCategory: EventDetailCategory;
  onChangeEventDetailCategory: (
    eventDetailCategory: EventDetailCategory
  ) => void;
};

function EventCategoryForm({
  eventTargetType,
  eventDetailCategory,
  onChangeEventDetailCategory,
}: EventCategoryFormProps) {
  return (
    <>
      {eventTargetType === EVENT_TARGET_TYPE_GEO && (
        <>
          <InputCustomRadio
            textKey={'인원수 알림'}
            radioKey={'moreOrLess'}
            radioName={'eventDetailCategory'}
            checked={eventDetailCategory === 'MORE_OR_LESS'}
            onChangeChecked={(checked) =>
              checked && onChangeEventDetailCategory('MORE_OR_LESS')
            }
          />
          <InputCustomRadio
            textKey={'공간 출입 알림'}
            radioKey={'inOrOut'}
            radioName={'eventDetailCategory'}
            checked={eventDetailCategory === 'IN_OR_OUT'}
            onChangeChecked={(checked) =>
              checked && onChangeEventDetailCategory('IN_OR_OUT')
            }
          />
        </>
      )}
      {eventTargetType === EVENT_TARGET_TYPE_ASSET && (
        <InputCustomRadio
          textKey={'통제'}
          radioKey={'checkInOrOut'}
          radioName={'eventDetailCategory'}
          checked={eventDetailCategory === 'CHECK_IN_OR_OUT'}
          onChangeChecked={(checked) =>
            checked && onChangeEventDetailCategory('CHECK_IN_OR_OUT')
          }
        />
      )}
    </>
  );
}

export default EventCategoryForm;
