import React, { ReactElement } from 'react';

type PreloaderProps = {
  width?: string;
  height?: string;
  strokeWidth?: string;
  deletePadding?: boolean;
};

function Preloader({
  width = '48px',
  height = '48px',
  strokeWidth = '8px',
  deletePadding = false,
}: PreloaderProps): ReactElement {
  return (
    <div className="preloader-container">
      <div className={`preloader-spinner ${deletePadding ? `p-0` : ``}`}>
        <svg
          className="spinner-container"
          width={width}
          height={height}
          viewBox="0 0 52 52"
        >
          <circle
            className="path"
            cx="26px"
            cy="26px"
            r="20px"
            fill="none"
            strokeWidth={strokeWidth}
          />
        </svg>
      </div>
    </div>
  );
}

export default Preloader;
