import React, { useEffect, useState } from 'react';
import { PANE_STATUS_LIST, PaneStatus } from '@/utils/constants/common';
import MaterialIcon from '@/components/common/MaterialIcon';
import { useTranslation } from 'react-i18next';
import { fetchAllSpaces, ResponseSpaceMetaInfo } from '@/api/space';
import { useUser } from '@/modules/user/hook';
import { ConnectedSpaceInfo } from '@/modules/space/types';
import classNames from 'classnames';
import AlertModal from '@/components/common/modal/AlertModal';
import { postProjectConnectSpace, putProjectStepData } from '@/api/project';
import { useProjectRegister } from '@/modules/project/hook';
import Preloader from '@/components/common/Preloader';
import {
  PROJECT_ONBOARDING_CATEGORY_BUILDING_INFO,
  ProjectOnboarding,
  ProjectStepData,
} from '@/modules/project/types';
import { CommonUtils } from '@/utils';

type ProjectBuildingConnectProps = {
  spaceInfoList: ConnectedSpaceInfo[];
  onReloadSpaceInfoList: () => void;
  onChangeStatus: (status: PaneStatus) => void;
};

function ProjectBuildingConnect({
  spaceInfoList,
  onReloadSpaceInfoList,
  onChangeStatus,
}: ProjectBuildingConnectProps) {
  const { t } = useTranslation();
  const {
    user: { userId },
  } = useUser();
  const {
    projectInfo: { projectId, produceStepData, onboardingData },
    handleSetOnboarding,
  } = useProjectRegister();
  const [load, setLoad] = useState(false);
  const [spaceList, setSpaceList] = useState<ResponseSpaceMetaInfo[]>([]);
  const [connectSpaceList, setConnectSpaceList] = useState<
    ResponseSpaceMetaInfo[]
  >([]);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    handleFetchAllSpaceList();
  }, []);

  const handleFetchAllSpaceList = async () => {
    setLoad(false);
    const result = await fetchAllSpaces(userId);
    if (result) {
      setSpaceList(
        result.filter(
          (space) =>
            !spaceInfoList.find(
              (connectedSpace) => connectedSpace.spaceId === space.meta_id
            )
        )
      );
    }
    setLoad(true);
  };

  const handleClickCancel = () => {
    onChangeStatus(PANE_STATUS_LIST);
  };

  const handleClickSubmit = async () => {
    if (!connectSpaceList.length) {
      setAlertMessage(t('건물을 선택해 주세요.'));
      setShowAlertModal(true);
      return;
    }

    if (projectId) {
      let isComplete = true;
      let isExistFloor = false;
      for (const { meta_id, floor_cnt } of connectSpaceList) {
        const result = await postProjectConnectSpace(projectId, meta_id);
        if (!result) {
          isComplete = false;
          break;
        }

        if (!isExistFloor && floor_cnt) {
          isExistFloor = true;
        }
      }

      if (!isComplete) {
        setAlertMessage(t('서버와 통신 중 오류가 발생했습니다'));
        setShowAlertModal(true);
      } else {
        const projectStepData: ProjectStepData = {};

        if (produceStepData && isExistFloor) {
          projectStepData.produceStepData = null;
        }

        let updateOnboarding: ProjectOnboarding | null = null;
        if (
          onboardingData &&
          !onboardingData.includes(PROJECT_ONBOARDING_CATEGORY_BUILDING_INFO)
        ) {
          updateOnboarding = [
            ...onboardingData,
            PROJECT_ONBOARDING_CATEGORY_BUILDING_INFO,
          ];

          projectStepData.onboardingData = JSON.stringify(updateOnboarding);
        }

        if (!CommonUtils.isEmptyObject(projectStepData)) {
          const result = await putProjectStepData(projectId, projectStepData);
          if (result && updateOnboarding) {
            handleSetOnboarding(updateOnboarding);
          }
        }

        onReloadSpaceInfoList();
        onChangeStatus(PANE_STATUS_LIST);
      }
    }
  };

  const handleClickSpaceItem = (clickedSpace: ResponseSpaceMetaInfo) => {
    if (
      !connectSpaceList.find(({ meta_id }) => clickedSpace.meta_id === meta_id)
    ) {
      setConnectSpaceList([...connectSpaceList, clickedSpace]);
    } else {
      setConnectSpaceList(
        connectSpaceList.filter(
          ({ meta_id }) => clickedSpace.meta_id !== meta_id
        )
      );
    }
  };

  return (
    <>
      <div className="container-fluid border-bottom-1 py-4 bg-white">
        <div className="d-flex align-items-center">
          <a className="circle-pin pr-2" onClick={handleClickCancel}>
            <MaterialIcon name={'arrow_back'} />
          </a>
          <h3 className="mb-0">{t('건물 가져오기')}</h3>
        </div>
      </div>
      {/*
      <div className="container-fluid border-bottom-1 bg-white">
        <div className="d-flex align-items-sm-center">
          <div className="item-sort">
            <a className="items active">{t('전체')}</a>
          </div>
        </div>
      </div>
      */}
      <div className="bg-light bg-light-h-100">
        <div className="plf-floor-card p-4 position-relative">
          {!load && <Preloader />}
          {load && (
            <>
              {spaceList.length === 0 && (
                <a className="plf-area-card add-item cursor-none no-highlight">
                  <p>{t('등록된 데이터가 없습니다.')}</p>
                </a>
              )}
              {spaceList.map((space) => (
                <BuildingItem
                  key={space.meta_id}
                  {...space}
                  active={
                    connectSpaceList.find(
                      ({ meta_id }) => space.meta_id === meta_id
                    )
                      ? true
                      : false
                  }
                  onClickItem={() => handleClickSpaceItem(space)}
                />
              ))}
            </>
          )}
        </div>
      </div>
      <div className="pub-con">
        <div className="pub-inner">
          <div className="btn-wrap">
            <a className="btn btn-light mr-4" onClick={handleClickCancel}>
              {t('취소')}
            </a>
            <a
              className={classNames('btn btn-accent ml-0')}
              onClick={handleClickSubmit}
            >
              {!connectSpaceList.length && t('선택 가져오기')}
              {connectSpaceList.length > 0 &&
                t('({{number}}) 선택 가져오기', {
                  replace: {
                    number: connectSpaceList.length,
                  },
                })}
            </a>
          </div>
        </div>
      </div>
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {alertMessage}
      </AlertModal>
    </>
  );
}

type BuildingItemProps = ResponseSpaceMetaInfo & {
  active: boolean;
  onClickItem: () => void;
};

function BuildingItem({
  meta_name,
  country_txt,
  city_txt,
  address_txt1,
  floor_cnt,
  active,
  onClickItem,
}: BuildingItemProps) {
  return (
    <div
      className={classNames('plf-area-card', {
        active,
      })}
      onClick={onClickItem}
    >
      <div className="plf-area-top">
        <h4>{meta_name}</h4>
      </div>
      <div
        className={classNames('plf-area-content', {
          'text-50': !active,
        })}
      >
        <p className="desc">
          {`${country_txt} ${city_txt} ${address_txt1}`.trim()}
        </p>
        <div className="d-flex align-items-center">
          <span className="material-icons-outlined font-size-16pt mr-1">
            map
          </span>
          {floor_cnt}
        </div>
      </div>
    </div>
  );
}

export default ProjectBuildingConnect;
