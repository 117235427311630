import React, { ReactElement } from 'react';
import { useHomeMenu } from '@/modules/home/hook';
import { MENU_IDX_MONITORING } from '@/modules/setup/types';
import MonitoringRightFloatingPane from '@/components/monitoring/MonitoringRightFloatingPane';

function RightFloatingPane(): ReactElement {
  const { activeMenuIdx } = useHomeMenu();

  return (
    <>
      {activeMenuIdx === MENU_IDX_MONITORING && <MonitoringRightFloatingPane />}
    </>
  );
}

export default RightFloatingPane;
