import React, { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { Action, SpaceState } from '@/modules/space/types';
import { spaceReducer } from '@/modules/space/reducer';

export const SpaceStateContext = createContext<SpaceState | null>(null);
type SpaceDispatch = Dispatch<Action>;
export const SpaceDispatchContext = createContext<SpaceDispatch | null>(null);

const initialState: SpaceState = {
  register: {
    address: {
      showPane: false,
      selectedDo: '',
      selectedSubDistrict: '',
      selectedLngLat: null,
      detailedAddress: '',
    },
  },
};

export function SpaceContextProvider({ children }: { children: ReactNode }) {
  const [space, dispatch] = useReducer(spaceReducer, initialState);

  return (
    <SpaceDispatchContext.Provider value={dispatch}>
      <SpaceStateContext.Provider value={space}>
        {children}
      </SpaceStateContext.Provider>
    </SpaceDispatchContext.Provider>
  );
}
