import React from 'react';
import FloorControl from '@/components/common/FloorControl';
import { useHomeBuilding, useHomeFloor } from '@/modules/home/hook';
import _ from 'lodash';

function HomeMonitoringMapControl() {
  const { building } = useHomeBuilding();
  const { floor, handleChangeFloor } = useHomeFloor();

  const handleClickFloor = (floorId: string) => {
    const changeFloor = building?.floors.find(({ mapId }) => mapId === floorId);
    changeFloor && handleChangeFloor(changeFloor);
  };

  return (
    <>
      <FloorControl
        floorList={
          _.cloneDeep(building?.floors || [])
            .reverse()
            .map(({ mapId, mapFloor }) => {
              return {
                floorId: mapId,
                floorValue: mapFloor,
              };
            }) || []
        }
        selectedFloorId={floor?.mapId || ''}
        onClickFloor={handleClickFloor}
      />
    </>
  );
}

export default HomeMonitoringMapControl;
