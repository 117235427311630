import React, { CSSProperties } from 'react';
import classNames from 'classnames';

type align = 'left' | 'right';
type IconProps = {
  className?: string;
  name: string;
  align?: align;
  style?: CSSProperties;
  outlined?: boolean;
};

function MaterialIcon({
  className,
  name,
  align,
  style,
  outlined = false,
}: IconProps) {
  let alignClass = '';

  if (align === 'left') {
    alignClass = 'icon--left';
  } else if (align === 'right') {
    alignClass = 'icon--right';
  }

  return (
    <span
      style={style}
      className={classNames(
        {
          'material-icons': !outlined,
          'material-icons-outlined': outlined,
        },
        alignClass,
        className
      )}
    >
      {name}
    </span>
  );
}

export default MaterialIcon;
