import { AsyncState } from '@/modules/common';
import { User } from '@/modules/user/types';

export type SignIn = {
  userId: string;
  password: string;
};

export type SignInState = {
  inputs: {
    email: string;
    password: string;
  };
  validInputs: {
    email: boolean;
    password: boolean;
  };
  remember: boolean;
  api: AsyncState<number>;
};

export const ACCESS_SIGN_IN = 'ACCESS/SIGN_IN' as const;
export const ACCESS_SIGN_IN_SUCCESS = 'ACCESS/SIGN_IN_SUCCESS' as const;
export const ACCESS_SIGN_IN_ERROR = 'ACCESS/SIGN_IN_ERROR' as const;
export const ACCESS_SIGN_IN_REMEMBER_EMAIL_SET = 'ACCESS/SIGN_IN_REMEMBER_EMAIL_SET' as const;
export const ACCESS_SIGN_IN_VALIDATION_SET = 'ACCESS/SIGN_IN_VALIDATION_SET' as const;
export const ACCESS_SIGN_IN_REMEMBER_EMAIL_CHECK_CHANGE = 'ACCESS/SIGN_IN_REMEMBER_EMAIL_CHECK_CHANGE' as const;
export const ACCESS_SIGN_IN_INPUT_CHANGE = 'ACCESS/SIGN_IN_INPUT_CHANGE' as const;

export type Action =
  | {
      type: typeof ACCESS_SIGN_IN;
    }
  | {
      type: typeof ACCESS_SIGN_IN_SUCCESS;
      data: User;
    }
  | {
      type: typeof ACCESS_SIGN_IN_ERROR;
      error: Error | null;
    }
  | {
      type: typeof ACCESS_SIGN_IN_REMEMBER_EMAIL_SET;
      email: string;
    }
  | {
      type: typeof ACCESS_SIGN_IN_VALIDATION_SET;
      validEmail: boolean;
      validPassword: boolean;
    }
  | {
      type: typeof ACCESS_SIGN_IN_REMEMBER_EMAIL_CHECK_CHANGE;
      checked: boolean;
    }
  | {
      type: typeof ACCESS_SIGN_IN_INPUT_CHANGE;
      key: string;
      value: string;
    };
