import { PaneStatus, PANE_STATUS_LIST } from '@/utils/constants/common';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import AssetComboBox from '@/components/project/AssetRegisterTab/AssetRegister/AssetComboBox';
import AssetTextInput from '@/components/project/AssetRegisterTab/AssetRegister/AssetTextInput';
import { useTranslation } from 'react-i18next';
import {
  useBuildingInfoList,
  useFloorPlanList,
  useProjectRegister,
  useSelectedBuilding,
  useSelectedFloorPlanId,
} from '@/modules/project/hook';
import { fetchCategoryList, postAsset } from '@/api/assets';
import { SelectOptionType } from '@/modules/assets/types';

type AssetRegisterProps = {
  setPaneStatus: (paneStatus: PaneStatus) => void;
};

function AssetRegister({ setPaneStatus }: AssetRegisterProps): ReactElement {
  const { t } = useTranslation();
  const { buildingInfoList } = useBuildingInfoList();
  const { floorPlanList } = useFloorPlanList();

  const {
    projectInfo: { projectId },
  } = useProjectRegister();
  const { selectedBuilding } = useSelectedBuilding();
  const { selectedFloorPlanId } = useSelectedFloorPlanId();

  const assetNameRef = useRef<HTMLInputElement>(null);
  const identificationValueRef = useRef<HTMLInputElement>(null);

  const [typeId, setTypeId] = useState<string>('');
  const [classificationId, setClassificationId] = useState<string>('');

  const [buildingSelectOptionList, setBuildingSelectOptionList] = useState<
    SelectOptionType[]
  >([]);
  const [floorPlanSelectOptionList, setFloorPlanSelectOptionList] = useState<
    SelectOptionType[]
  >([]);
  const [typeList, setTypeList] = useState<SelectOptionType[]>([]);
  const [classificationList, setClassificationList] = useState<
    SelectOptionType[]
  >([]);

  // 콤보박스 항목 표기를 위한 자산 유형 조회
  const handleFetchTypeList = async () => {
    const result = await fetchCategoryList();
    result &&
      setTypeList(
        result.map((resultItem) => ({
          id: resultItem.categoryId,
          name: resultItem.categoryName,
        }))
      );
  };

  // 콤보박스 항목 표기를 위한 자산 분류 조회
  const handleFetchClassificationList = async (id: string) => {
    const result = await fetchCategoryList();
    result &&
      setClassificationList(
        result
          .filter((resultItem) => resultItem.categoryId === id)[0]
          .subCategories.map((subCategory) => ({
            id: subCategory.categoryId,
            name: subCategory.categoryName,
          }))
      );
  };

  useEffect(() => {
    setBuildingSelectOptionList(
      buildingInfoList.map((buildingInfo) => ({
        id: buildingInfo.mappingId,
        name: buildingInfo.spaceName,
      }))
    );
    setFloorPlanSelectOptionList(
      floorPlanList.map((floorPlan) => ({
        id: floorPlan.map_id,
        name: floorPlan.map_name,
      }))
    );
  }, [buildingInfoList, floorPlanList]);

  // 등록 전 유효성 검사
  const checkValidation = () => {
    if (
      projectId &&
      selectedBuilding?.spaceId &&
      selectedFloorPlanId &&
      assetNameRef.current?.value &&
      identificationValueRef.current?.value &&
      typeId &&
      classificationId
    ) {
      return true;
    } else {
      return false;
    }
  };

  // 등록하기 버튼 클릭 이후
  const handleClickRegisterAsset = async () => {
    const validResult = checkValidation();
    if (!validResult) {
      alert(t('자산의 속성을 모두 입력해 주세요.'));
      return false;
    }
    const result =
      validResult &&
      projectId &&
      assetNameRef.current &&
      identificationValueRef.current &&
      selectedBuilding &&
      (await postAsset(
        projectId,
        selectedBuilding.mappingId,
        selectedFloorPlanId,
        assetNameRef.current?.value,
        identificationValueRef.current?.value,
        typeId,
        classificationId
      ));
    if (result) {
      alert(t('자산등록이 완료되었습니다.'));
      setPaneStatus(PANE_STATUS_LIST);
    } else {
      alert(t('해당 식별값으로 등록된 자산이 있습니다.'));
    }
  };

  useEffect(() => {
    handleFetchTypeList();
  }, [selectedFloorPlanId]);

  useEffect(() => {
    typeId ? handleFetchClassificationList(typeId) : setClassificationId('');
  }, [typeId]);

  return (
    <>
      <div className="container-fluid border-bottom-1 mb-4 py-4">
        <div className="d-flex align-items-center">
          <a
            className="circle-pin pr-2"
            onClick={() => setPaneStatus(PANE_STATUS_LIST)}
          >
            <i className="material-icons">arrow_back</i>
          </a>
          <h3 className="mb-0">{t('자산 등록')}</h3>
        </div>
      </div>
      <div className="container-fluid">
        <AssetComboBox
          labelText={t('건물')}
          value={selectedBuilding ? selectedBuilding.mappingId : ''}
          disabled={true}
          optionList={buildingSelectOptionList}
        />
        <AssetComboBox
          labelText={t('평면도')}
          disabled={true}
          value={selectedFloorPlanId}
          optionList={floorPlanSelectOptionList}
        />
        <AssetComboBox
          labelText={t('유형')}
          disabled={false}
          optionList={typeList}
          value={typeId}
          setValue={setTypeId}
          emptyValueText={t('자산 유형을 선택하세요.')}
        />
        {typeId && (
          <AssetComboBox
            labelText={t('분류')}
            disabled={false}
            optionList={classificationList}
            value={classificationId}
            setValue={setClassificationId}
            emptyValueText={t('자산 분류를 선택하세요')}
          />
        )}
        <AssetTextInput
          labelText={t('자산명')}
          placeholder={t('자산명을 입력해 주세요.')}
          ref={assetNameRef}
        />
        <AssetTextInput
          labelText={t('식별값')}
          placeholder={t('식별값을 입력해 주세요')}
          ref={identificationValueRef}
        />
      </div>
      <div className="pub-con">
        <div className="pub-inner">
          <div className="btn-wrap">
            <a
              className="btn btn-light mr-4"
              onClick={() => setPaneStatus(PANE_STATUS_LIST)}
            >
              {t('취소')}
            </a>
            <a
              className="btn btn-accent ml-0"
              onClick={handleClickRegisterAsset}
            >
              {t('등록하기')}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssetRegister;
