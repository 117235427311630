import React, { useEffect, useRef } from 'react';
import { Image, Tile, Vector as VectorLayer } from 'ol/layer';
import { ImageWMS, TileJSON, Vector as VectorSource } from 'ol/source';
import {
  GEO_IMAGE_DEFAULT_OPACITY,
  MAP_TILE_KEY,
  OPEN_LAYERS_RESOLUTIONS,
} from '@/utils/constants/common';
import { Feature, Map, View } from 'ol';
import { defaults, Draw } from 'ol/interaction';
import {
  useOpenLayers,
  useProjectRegister,
  useSelectedFloorPlanId,
} from '@/modules/project/hook';
import { Config } from '@/config';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { createBox } from 'ol/interaction/Draw';
import GeometryType from 'ol/geom/GeometryType';
import {
  calcGeoImage,
  getGeoImageLayer,
  getGeoImageSource,
  getInteractionTransform,
  transformFromPolygon,
} from '@/utils/ol-ext-bridge';
import { DrawGeoImage } from '@/modules/space/types';
import { defaults as defaultControls } from 'ol/control';
import { useLeftSidebar } from '@/modules/setup/hook';
import { fetchPoi } from '@/api/space';
import { Point } from 'ol/geom';

let map: Map;
let floorLayer: Image;
let poiIconLayer: VectorLayer;
let poiTextLayer: VectorLayer;
function ProjectMapContainer() {
  const { expand } = useLeftSidebar();
  const container = useRef<HTMLDivElement>(null);
  const { registerStep } = useProjectRegister();
  const { selectedFloorPlanId } = useSelectedFloorPlanId();
  const { handleSetOpenLayers } = useOpenLayers();

  useEffect(() => {
    setTimeout(() => {
      map?.updateSize();
    }, 200);
  }, [expand]);

  useEffect(() => {
    if (floorLayer && poiIconLayer && poiTextLayer) {
      if (registerStep === 4) {
        floorLayer.setVisible(true);
        poiIconLayer.setVisible(true);
        poiTextLayer.setVisible(true);
      } else {
        floorLayer.setVisible(false);
        poiIconLayer.setVisible(false);
        poiTextLayer.setVisible(false);
      }
    }
  }, [registerStep, floorLayer, poiIconLayer, poiTextLayer]);

  useEffect(() => {
    if (floorLayer) {
      const floorSource = floorLayer.getSource() as ImageWMS;
      let viewParams = '';
      if (selectedFloorPlanId) {
        floorLayer?.setVisible(true);
        viewParams = `mapid:'${selectedFloorPlanId}'`;
      } else {
        floorLayer?.setVisible(false);
      }
      const floorParams = floorSource.getParams();
      floorParams['viewparams'] = viewParams;
      floorSource.refresh();
    }
  }, [floorLayer, selectedFloorPlanId]);

  useEffect(() => {
    if (poiIconLayer && poiTextLayer) {
      if (selectedFloorPlanId) {
        handleFetchPoi(
          poiIconLayer.getSource(),
          poiTextLayer.getSource(),
          selectedFloorPlanId
        );
      } else {
        poiIconLayer.getSource().clear();
        poiTextLayer.getSource().clear();
      }
    }
  }, [poiIconLayer, poiTextLayer, selectedFloorPlanId]);

  useEffect(() => {
    const brightMap = new Tile({
      source: new TileJSON({
        url: `https://api.maptiler.com/maps/streets/tiles.json?key=${MAP_TILE_KEY}`,
        tileSize: 512,
      }),
    });

    const floorSource = new ImageWMS({
      url: `${Config.map_server.geo_server_uri}/geoserver/watta/wms`,
      params: {
        LAYERS: 'watta:lms_view_area',
        VERSION: '1.1.1',
        FORMAT: 'image/png',
        EXCEPTIONS: 'application/vnd.ogc.se_inimage',
      },
    });

    floorLayer = new Image({
      source: floorSource,
      visible: false,
    });

    const poiIconSource = new VectorSource({ wrapX: false });
    poiIconLayer = new VectorLayer({
      source: poiIconSource,
      visible: false,
    });

    const poiTextSource = new VectorSource({ wrapX: false });
    poiTextLayer = new VectorLayer({
      source: poiTextSource,
      visible: false,
    });

    const drawSource = new VectorSource({ wrapX: false });
    const drawLayer = new VectorLayer({
      source: drawSource,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: '#ff0000',
          width: 2,
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: '#ff0000',
          }),
        }),
      }),
    });

    const geofencingSource = new ImageWMS({
      url: `${Config.map_server.geo_server_uri}/geoserver/watta/wms`,
      params: {
        VERSION: '1.1.1',
        FORMAT: 'image/png',
        EXCEPTIONS: 'application/vnd.ogc.se_inimage',
      },
    });

    const geofencingLayer = new Image({
      visible: false,
      source: geofencingSource,
    });

    map = new Map({
      layers: [
        brightMap,
        floorLayer,
        poiIconLayer,
        poiTextLayer,
        drawLayer,
        geofencingLayer,
      ],
      target: container.current!,
      interactions: defaults({
        doubleClickZoom: false,
      }),
      controls: defaultControls({
        zoom: false,
        rotate: false,
        attribution: false,
      }),
      view: new View({
        resolutions: OPEN_LAYERS_RESOLUTIONS,
        maxResolution: OPEN_LAYERS_RESOLUTIONS[0],
        center: [15558454.49842193, 4257326.124240982],
        zoom: 5,
        projection: 'EPSG:3857',
        zoomFactor: 1,
        constrainResolution: true,
      }),
    });

    const drawSquare = new Draw({
      source: drawSource,
      geometryFunction: createBox(),
      type: GeometryType.CIRCLE,
    });

    const geoImageLayer = getGeoImageLayer(GEO_IMAGE_DEFAULT_OPACITY);
    const [transformLayer, transformInteraction] = getInteractionTransform();
    map.addLayer(transformLayer);

    transformInteraction.on('rotating', function (e: any) {
      transformFromPolygon(geoImageLayer, e.feature);
    });
    transformInteraction.on('translating', (e: any) => {
      transformFromPolygon(geoImageLayer, e.feature);
    });
    transformInteraction.on('scaling', function (e: any) {
      transformFromPolygon(geoImageLayer, e.feature);
    });
    transformInteraction.on(
      ['rotateend', 'translateend', 'scaleend'],
      (e: any) => {
        transformFromPolygon(geoImageLayer, e.feature);
      }
    );

    const drawGeoImageFromSquare = (
      options: DrawGeoImage,
      squareFeature: Feature
    ) => {
      map.removeLayer(geoImageLayer);
      map.addLayer(geoImageLayer);
      map.removeInteraction(transformInteraction);
      map.addInteraction(transformInteraction);
      const cx = options.imageCenter[0];
      if (cx === 0) {
        const center = map.getView().getCenter();
        if (center) {
          options.imageCenter = [center[0], center[1]];
          options.imageScale = [1, 1];
          options.imageRotate = 0;
        }
      }
      geoImageLayer.setSource(getGeoImageSource(options));
      setTimeout(() => {
        transformFromPolygon(geoImageLayer, squareFeature);
        const calcResult = calcGeoImage(geoImageLayer);
        const transformFeature = new Feature(calcResult);
        if (transformLayer.getSource().getFeatures().length === 0) {
          transformLayer.getSource().addFeature(transformFeature);
        } else {
          transformLayer.getSource().clear();
          transformLayer.getSource().addFeature(transformFeature);
        }
      }, 1000);
    };

    const drawGeoImage = async (
      options: DrawGeoImage,
      callback?: () => void
    ) => {
      const cx = options.imageCenter[0];
      if (cx === 0) {
        const center = map.getView().getCenter();
        if (center) {
          options.imageCenter = [center[0], center[1]];
          options.imageScale = [1, 1];
          options.imageRotate = 0;
        }
      }

      const geoImageSource = getGeoImageSource(options);
      geoImageLayer.setSource(geoImageSource);
      geoImageLayer.setOpacity(options.opacity || 1);
      geoImageLayer.once('change', () => {
        callback?.call(null);
      });

      map.removeLayer(geoImageLayer);
      map.addLayer(geoImageLayer);
    };

    const removeGeoImage = () => {
      map.removeInteraction(transformInteraction);
      transformLayer.getSource().clear();
      map.removeLayer(geoImageLayer);
    };

    const drawTransform = () => {
      const calcResult = calcGeoImage(geoImageLayer);
      const transformFeature = new Feature(calcResult);
      transformLayer.getSource().clear();
      transformLayer.getSource().addFeature(transformFeature);
      map.removeInteraction(transformInteraction);
      map.addInteraction(transformInteraction);

      const geometry = transformFeature.getGeometry();
      if (geometry) {
        const extent = geometry.getExtent();
        map.getView().fit(extent, {
          size: map.getSize(),
        });
      }
    };

    const removeTransform = () => {
      map.removeInteraction(transformInteraction);
      transformLayer.getSource().clear();
    };

    handleSetOpenLayers({
      map,
      drawSource,
      geofencingLayer,
      draw: {
        square: drawSquare,
      },
      geoImage: {
        layer: geoImageLayer,
        draw: drawGeoImage,
        drawFromSquare: drawGeoImageFromSquare,
        remove: removeGeoImage,
        adjustment: undefined,
      },
      transform: {
        draw: drawTransform,
        remove: removeTransform,
      },
    });
  }, []);

  const handleFetchPoi = async (
    poiIconSource: VectorSource,
    poiTextSource: VectorSource,
    mapId: string
  ) => {
    poiIconSource.clear();
    poiTextSource.clear();

    const poiList = await fetchPoi({
      mapid: mapId,
    });

    if (poiList) {
      poiList.forEach((poi) => {
        const point = new Point([poi.xcoord, poi.ycoord]);
        const iconFeature = new Feature(point);
        iconFeature.setStyle(
          new Style({
            image: new Icon({
              src: `${Config.map_server.indoor_edit_uri}/edit/images/poi/comm_poi_icon/${poi.filenm}`,
            }),
          })
        );
        poiIconSource.addFeature(iconFeature);
        const textFeature = new Feature(point);
        textFeature.setStyle(
          new Style({
            text: new Text({
              text: poi.poi_name,
              scale: 1,
              textAlign: 'center',
              offsetY: 20,
              fill: new Fill({
                color: '#000000',
              }),
              stroke: new Stroke({
                color: '#FFFFFF',
                width: 2,
              }),
              font: 'bold 12px roboto',
            }),
          })
        );
        poiTextSource.addFeature(textFeature);
      });
    }
  };

  return (
    <>
      <div
        ref={container}
        className="map-container"
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      />
    </>
  );
}

export default ProjectMapContainer;
