import { fetchPagedSensorList } from '@/api/sensor';
import { useHomeFloor, useHomeMenu } from '@/modules/home/hook';
import { useOpenLayersResource } from '@/modules/openlayers/hook';
import {
  usePagedSensorList,
  useSelectedSensor,
  useSensorSearchOption,
} from '@/modules/sensor/hook';
import {
  SensorClassificationType,
  SensorSearchOptionType,
  SENSOR_BLE_TYPE,
  SENSOR_CONNECTION_DISCONNECTED_STATUS,
  SENSOR_CONNECTION_ERROR_STATUS,
  SENSOR_CONNECTION_NORMAL_STATUS,
  SENSOR_ENVIRONMENT_TYPE,
  SENSOR_TEMPERATURE_TYPE,
} from '@/modules/sensor/types';
import { SENSOR_INTERVAL } from '@/utils/constants/common';
import { Overlay } from 'ol';
import { WKT } from 'ol/format';
import { Polygon } from 'ol/geom';
import React, { ReactElement, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import SensorLeftPaneCover from './SensorLeftPaneCover';

type SensorLeftPaneSearchBarProps = {
  setLoading: (loading: boolean) => void;
};

let sensorTimer: ReturnType<typeof setInterval> | null = null;

function SensorLeftPaneSearchBar({
  setLoading,
}: SensorLeftPaneSearchBarProps): ReactElement {
  const { t } = useTranslation();
  const { map } = useOpenLayersResource();

  const inputRef = useRef<HTMLInputElement>(null);

  const { floor } = useHomeFloor();
  const { sensorSearchOption, setSensorSearchOption } = useSensorSearchOption();
  const { pagedSensorList, setPagedSensorList } = usePagedSensorList();
  const { activeMenuIdx } = useHomeMenu();
  const { selectedSensor, setSelectedSensor } = useSelectedSensor();

  // 센서 목록 조회
  const handleFetchPagedSensorList = async (
    option: SensorSearchOptionType,
    isInterval: boolean,
    isActivateSensorEffect: boolean
  ) => {
    !isInterval && !isActivateSensorEffect && setLoading(true);
    option.mapId && setPagedSensorList(await fetchPagedSensorList(option));
    !isInterval && !isActivateSensorEffect && setLoading(false);
  };

  // 오버레이 삭제
  const clearOverlay = () =>
    map
      ?.getOverlays()
      .getArray()
      .forEach((overlay) => map.removeOverlay(overlay));

  useEffect(() => {
    floor &&
      setSensorSearchOption({
        ...sensorSearchOption,
        mapId: floor.mapId,
      });
  }, [floor]);

  useEffect(() => {
    if (sensorSearchOption.mapId) {
      sensorTimer = setInterval(
        () => handleFetchPagedSensorList(sensorSearchOption, true, false),
        SENSOR_INTERVAL
      );
    }
    sensorSearchOption.mapId &&
      handleFetchPagedSensorList(sensorSearchOption, false, false);
    return () => {
      clearOverlay();
      sensorTimer && clearInterval(sensorTimer);
      setSelectedSensor('');
    };
  }, [sensorSearchOption]);

  useEffect(() => {
    handleFetchPagedSensorList(sensorSearchOption, false, true);
  }, [selectedSensor]);

  useEffect(() => {
    clearOverlay();
    pagedSensorList.sensorList.forEach((sensor) => {
      const wkt = new WKT();
      const polygon = wkt.readFeature(sensor.geomstr).getGeometry() as Polygon;
      const [longitude, latitude] = polygon.getCoordinates();

      const container = document.createElement('div');
      container.classList.add('sensor-cover');
      switch (sensor.connection_condition) {
        case SENSOR_CONNECTION_DISCONNECTED_STATUS:
          container.classList.add('disconnect');
          break;
        case SENSOR_CONNECTION_ERROR_STATUS:
          container.classList.add('active-poor');
          break;
        case SENSOR_CONNECTION_NORMAL_STATUS:
          container.classList.add('active-good');
          break;
      }
      sensor.sensor_serial === selectedSensor &&
        container.classList.add('selected');
      container.setAttribute('style', 'cursor: pointer;');
      const sensorOverlay = new Overlay({
        id: sensor.sensor_serial,
        className:
          selectedSensor === sensor.sensor_serial
            ? 'sensor-overlay selected'
            : 'sensor-overlay',
        position: [Number(longitude), Number(latitude)],
        element: container,
      });
      map?.addOverlay(sensorOverlay);

      ReactDOM.render(
        <SensorLeftPaneCover
          sensor={sensor}
          selectedSensor={selectedSensor}
          setSelectedSensor={setSelectedSensor}
        />,
        container
      );
    });

    return () => clearOverlay();
  }, [map, pagedSensorList, selectedSensor]);

  useEffect(() => {
    clearOverlay();
    return () => clearOverlay();
  }, [activeMenuIdx]);

  useEffect(() => {
    return () => {
      sensorTimer && clearInterval(sensorTimer);
      clearOverlay();
      setSensorSearchOption({
        mapId: '',
        searchKeyword: '',
        sensorClassificationTypeList: [],
      });
    };
  }, []);

  // 검색어 입력 시
  const setSearchKeyword = () => {
    clearOverlay();
    const searchKeyword = inputRef.current?.value;
    setSensorSearchOption({
      ...sensorSearchOption,
      searchKeyword: searchKeyword ? searchKeyword : '',
    });
  };

  // 필터 선택 시
  const onClickFilter = (
    sensorClassificationType: SensorClassificationType | ''
  ) => {
    if (!sensorClassificationType) {
      setSensorSearchOption({
        ...sensorSearchOption,
        sensorClassificationTypeList: [],
      });
    } else {
      sensorSearchOption.sensorClassificationTypeList.includes(
        sensorClassificationType
      )
        ? setSensorSearchOption({
            ...sensorSearchOption,
            sensorClassificationTypeList: sensorSearchOption.sensorClassificationTypeList.filter(
              (currentSensorClassificationType) =>
                currentSensorClassificationType !== sensorClassificationType
            ),
          })
        : setSensorSearchOption({
            ...sensorSearchOption,
            sensorClassificationTypeList: sensorSearchOption.sensorClassificationTypeList.concat(
              sensorClassificationType
            ),
          });
    }
  };

  return (
    <div className="plf-floor-add border-bottom-0">
      <div className="d-flex align-items-sm-center pb-3">
        <div className="swipe-wrap">
          <ul className="swipe-list">
            <li>
              <a
                className={
                  !sensorSearchOption.sensorClassificationTypeList.length
                    ? `active`
                    : ``
                }
                onClick={() => onClickFilter('')}
              >
                {t('전체')}({pagedSensorList.totCnt})
              </a>
            </li>
            <li>
              <a
                className={
                  sensorSearchOption.sensorClassificationTypeList.includes(
                    SENSOR_ENVIRONMENT_TYPE
                  )
                    ? `active`
                    : ``
                }
                onClick={() => onClickFilter(SENSOR_ENVIRONMENT_TYPE)}
              >
                {t('환경')}({pagedSensorList.environment_cnt})
              </a>
            </li>
            <li>
              <a
                className={
                  sensorSearchOption.sensorClassificationTypeList.includes(
                    SENSOR_TEMPERATURE_TYPE
                  )
                    ? `active`
                    : ``
                }
                onClick={() => onClickFilter(SENSOR_TEMPERATURE_TYPE)}
              >
                {t('온습도')}({pagedSensorList.cel_hum_cnt})
              </a>
            </li>
            <li>
              <a
                className={
                  sensorSearchOption.sensorClassificationTypeList.includes(
                    SENSOR_BLE_TYPE
                  )
                    ? `active`
                    : ``
                }
                onClick={() => onClickFilter(SENSOR_BLE_TYPE)}
              >
                {t('측위센서')}({pagedSensorList.ble_cnt})
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="search-box">
        <input
          type="search"
          className="form-line"
          placeholder={t('센서명을 입력해 주세요')}
          ref={inputRef}
          onKeyDown={(e) => e.key === 'Enter' && setSearchKeyword()}
        />
        <a
          href="#n"
          className="btn-search circle-pin"
          onClick={setSearchKeyword}
        >
          <span className="material-icons">search</span>
        </a>
      </div>
    </div>
  );
}

export default SensorLeftPaneSearchBar;
