import { Feature } from 'ol';
import { useTranslation } from 'react-i18next';
import { useHomeSelected } from '@/modules/home/hook';
import { useMonitoringApp } from '@/modules/monitoring/hook';
import React, { useEffect, useRef, useState } from 'react';
import {
  MonitoringAppEndPointUser,
  MonitoringFloorAppEndPointUser,
  MonitoringFloorAppEndPointUserStatus,
} from '@/modules/monitoring/types';
import { fetchMonitoringFloorAppEndPointUserStatus } from '@/api/monitoring';
import { Polygon } from 'ol/geom';
import * as turf from '@turf/turf';
import { OpenLayersUtils } from '@/utils/OpenLayersUtils';
import MaterialIcon from '@/components/common/MaterialIcon';
import AlertModal from '@/components/common/modal/AlertModal';

function MonitoringAppEndPointUserRealtimeStatus({
  className = 'cover',
  objectFeatureFilterList,
}: {
  className?: string;
  objectFeatureFilterList: Feature[];
}) {
  const { t } = useTranslation();
  const { project, building, floor } = useHomeSelected();
  const {
    appEndPointUserList,
    appEndPointUserAccumulateNumber,
  } = useMonitoringApp();
  const [isRefresh, setRefresh] = useState<undefined | boolean>(undefined);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [
    appEndPointUserStatus,
    setAppEndPointUserStatus,
  ] = useState<MonitoringFloorAppEndPointUserStatus>({
    activeUsersByFloor: {
      data: {},
      size: 0,
    },
    totalUsersByFloor: {
      count: 0,
    },
  });
  const mountedRef = useRef(false);

  useEffect(() => {
    if (typeof isRefresh !== 'undefined') {
      handleFetchAppEndPointUserStatus();
    }
  }, [isRefresh]);

  useEffect(() => {
    if (mountedRef.current) {
      handleFetchAppEndPointUserStatus();
    } else {
      mountedRef.current = true;
    }
  }, [objectFeatureFilterList]);

  useEffect(() => {
    setAppEndPointUserStatus({
      activeUsersByFloor: {
        data: {},
        size: handleFilerUserList(appEndPointUserList),
      },
      totalUsersByFloor: {
        count: appEndPointUserAccumulateNumber,
      },
    });
  }, [appEndPointUserList, appEndPointUserAccumulateNumber]);

  const handleFetchAppEndPointUserStatus = async () => {
    let data: MonitoringFloorAppEndPointUserStatus | null = null;
    project &&
      building &&
      floor &&
      (data = await fetchMonitoringFloorAppEndPointUserStatus(
        project.projectId,
        building.mappingId,
        floor.mapId
      ));
    if (data) {
      const filterValue = handleFilterUserObject(data.activeUsersByFloor.data);
      data.activeUsersByFloor.size = filterValue;
      setAppEndPointUserStatus(data);
    } else {
      setShowAlertModal(true);
    }
  };

  const handleFilerUserList = (
    appEndPointUserList: MonitoringAppEndPointUser[]
  ) => {
    const filterAppEndPointUserList: MonitoringAppEndPointUser[] = !objectFeatureFilterList.length
      ? appEndPointUserList
      : [];

    objectFeatureFilterList.forEach((objectFeature) => {
      const polygonGeometry = objectFeature.getGeometry() as Polygon;
      const turfPolygon = turf.polygon(polygonGeometry.getCoordinates());
      appEndPointUserList.forEach((appEndPointUser) => {
        const point = turf.point([appEndPointUser.lng, appEndPointUser.lat]);
        if (
          !filterAppEndPointUserList.find(
            ({ accessKey }) => appEndPointUser.accessKey === accessKey
          ) &&
          turf.inside(point, turfPolygon)
        ) {
          filterAppEndPointUserList.push(appEndPointUser);
        }
      });
    });

    return filterAppEndPointUserList.length;
  };

  const handleFilterUserObject = (
    floorAppEndPointUser: MonitoringFloorAppEndPointUser
  ) => {
    const filterAppEndPointUserList: MonitoringAppEndPointUser[] = !objectFeatureFilterList.length
      ? Object.values(floorAppEndPointUser).map(
          (appEndPointUser) => appEndPointUser
        )
      : [];

    objectFeatureFilterList.forEach((objectFeature) => {
      const polygonGeometry = objectFeature.getGeometry() as Polygon;
      const turfPolygon = turf.polygon(polygonGeometry.getCoordinates());
      Object.values(floorAppEndPointUser).forEach((appEndPointUser) => {
        const point = turf.point(
          OpenLayersUtils.convertCoordinates([
            appEndPointUser.lng,
            appEndPointUser.lat,
          ])
        );
        if (
          !filterAppEndPointUserList.find(
            ({ accessKey }) => appEndPointUser.accessKey === accessKey
          ) &&
          turf.inside(point, turfPolygon)
        ) {
          filterAppEndPointUserList.push(appEndPointUser);
        }
      });
    });

    return filterAppEndPointUserList.length;
  };

  return (
    <>
      <div className={className}>
        <div className="d-flex align-items-center mb-3">
          <h4 className="m-0">{t('실시간 현황')}</h4>
          <a
            className="btn-refresh"
            onClick={() => {
              if (typeof isRefresh === 'undefined') {
                setRefresh(false);
              } else {
                setRefresh(!isRefresh);
              }
            }}
          >
            <MaterialIcon name={'refresh'} outlined={true} />
          </a>
        </div>
        <ul className="list-row column-2 m-0 py-3">
          <li className="border-right-1">
            <p className="mb-2 text-64">{t('현재')}</p>
            <strong className="font-size-16pt">
              {appEndPointUserStatus.activeUsersByFloor.size}
            </strong>
          </li>
          <li>
            <p className="mb-2 text-64">{t('금일 누적')}</p>
            <strong className="font-size-16pt">
              {appEndPointUserStatus.totalUsersByFloor.count}
            </strong>
          </li>
        </ul>
      </div>
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {t('서버와 통신 중 오류가 발생했습니다')}
      </AlertModal>
    </>
  );
}

export default MonitoringAppEndPointUserRealtimeStatus;
