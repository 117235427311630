import React, { useEffect, useState } from 'react';
import { Accident } from '@/modules/accident/types';
import { PaneProps } from '@/modules/common';
import MaterialIcon from '@/components/common/MaterialIcon';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  GEOMETRY_TYPE_CIRCLE,
  PANE_STATUS_LIST,
} from '@/utils/constants/common';
import AccidentTime from '@/components/accident/AccidentTime';
import classNames from 'classnames';
import { putAccidentInactive } from '@/api/accident';
import AccidentMessageHistory from '@/components/accident/AccidentMessageHistory';
import AccidentMessageForm from '@/components/accident/AccidentMessageForm';
import InvalidAlert from '@/components/InvalidAlert';
import { postMessages } from '@/api/message';
import { useHomeProject } from '@/modules/home/hook';
import AlertModal from '@/components/common/modal/AlertModal';
import { WKT } from 'ol/format';
import {
  useOpenLayersDraw,
  useOpenLayersEvent,
} from '@/modules/openlayers/hook';
import { Circle, Polygon } from 'ol/geom';
import { Feature } from 'ol';
import { useMonitoringApp } from '@/modules/monitoring/hook';
import { MonitoringAppEndPointUser } from '@/modules/monitoring/types';
import * as turf from '@turf/turf';
import { useAccidentData } from '@/modules/accident/hook';
import { MessageInfo } from '@/modules/message/types';

type AccidentDetailProps = PaneProps & {
  accident: Accident;
};

function AccidentDetail({
  accident: {
    eventId,
    eventDetailCategory,
    geomType,
    geom,
    areaGeom,
    radius,
    lng,
    lat,
    activeFlag,
    registDate,
    updateDate,
  },
  onChangeStatus,
}: AccidentDetailProps) {
  const { t } = useTranslation();
  const { project } = useHomeProject();
  const { drawLayer } = useOpenLayersDraw();
  const { getAccidentStyle } = useOpenLayersEvent();
  const [loading, setLoading] = useState(false);
  const { getAccidentBasicInfo } = useAccidentData();
  const { icon, title, content } = getAccidentBasicInfo(eventDetailCategory);
  const [isMessageHistoryReload, setMessageHistoryReload] = useState(false);
  const [message, setMessage] = useState<MessageInfo>({
    title,
    content,
    linkUrl: '',
    imgId: '',
  });
  const { appEndPointUserList } = useMonitoringApp();
  const [geofenceFeature, setGeofenceFeature] = useState<Feature | null>(null);
  const [targetAppEndPointUserList, setTargetAppEndPointUserList] = useState<
    MonitoringAppEndPointUser[]
  >([]);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    let displayFeature: Feature | null = null;
    let checkFeature: Feature | null = null;
    const wkt = new WKT();
    if (geomType && geom) {
      if (geomType === GEOMETRY_TYPE_CIRCLE && lng && lat && radius) {
        displayFeature = new Feature(new Circle([lng, lat], radius));
      } else {
        displayFeature = wkt.readFeature(geom);
      }
      checkFeature = wkt.readFeature(geom);
    } else if (areaGeom) {
      displayFeature = checkFeature = wkt.readFeature(areaGeom);
    }

    if (displayFeature) {
      displayFeature.setStyle(getAccidentStyle(true));
      drawLayer && drawLayer.getSource().addFeature(displayFeature);
    }

    checkFeature && setGeofenceFeature(checkFeature);

    return () => drawLayer?.getSource().clear();
  }, [geomType, geom, areaGeom, lng, lat, radius, drawLayer]);

  useEffect(() => {
    if (geofenceFeature) {
      const targetList: MonitoringAppEndPointUser[] = [];
      const polygonGeometry = geofenceFeature.getGeometry() as Polygon;
      const turfPolygon = turf.polygon(polygonGeometry.getCoordinates());
      appEndPointUserList.forEach((appEndPointUser) => {
        const point = turf.point([appEndPointUser.lng, appEndPointUser.lat]);
        if (turf.inside(point, turfPolygon)) {
          targetList.push(appEndPointUser);
        }
      });
      setTargetAppEndPointUserList(targetList);
    }
  }, [appEndPointUserList, geofenceFeature]);

  const handleClickCancel = () => {
    onChangeStatus(PANE_STATUS_LIST);
  };

  const handleClickEndAccident = async () => {
    const data = await putAccidentInactive(eventId);
    if (data) {
      onChangeStatus(PANE_STATUS_LIST);
    }
  };

  const handleClickSendMessage = async () => {
    if (loading) {
      return;
    }

    setShowInvalidMessage(false);
    let checkMessage = '';
    if (!message.title || !message.content) {
      checkMessage = '필수 값을 입력해 주세요';
    } else if (!targetAppEndPointUserList.length) {
      checkMessage = '재실 인원이 없습니다';
    }

    if (checkMessage) {
      setInvalidMessage(checkMessage);
      setShowInvalidMessage(true);
      return;
    }

    const targetIds = targetAppEndPointUserList.map(
      ({ accessKey }) => accessKey
    );
    let result = false;
    setLoading(true);
    project &&
      (result = await postMessages({
        projectId: project.projectId,
        parentId: eventId,
        parentType: 'accident',
        messageTitle: message.title,
        messageContent: message.content,
        linkUrl: message.linkUrl,
        imgId: message.imgId,
        targetIds,
      }));

    let resultAlertMessage = '메세지가 전송되었습니다.';
    if (result) {
      setMessageHistoryReload(!isMessageHistoryReload);
    } else {
      resultAlertMessage = '서버와 통신 중 오류가 발생했습니다';
    }

    setAlertMessage(resultAlertMessage);
    setShowAlertModal(true);
    setLoading(false);
  };

  return (
    <>
      <div
        className={classNames('emg-stat', {
          'emg-disabled': !activeFlag,
        })}
      >
        <div className="emg-top d-flex align-items-center">
          <div className="d-flex align-items-start">
            <a className="pr-2" onClick={handleClickCancel}>
              <MaterialIcon name={'arrow_back'} />
            </a>
            <div className="title">
              <h3 className="mb-0">{t('최초 감지 시간')}</h3>
              <span className="mb-0">
                {moment(registDate).format('YYYY.MM.DD HH:mm:ss')}
              </span>
            </div>
          </div>
        </div>
        <AccidentTime
          activeFlag={activeFlag}
          registDate={registDate}
          updateDate={updateDate}
        />
      </div>
      <div className="container-fluid border-bottom-1 py-4">
        <ul
          className={classNames('list-row mb-0', {
            'column-2': activeFlag,
          })}
        >
          <li
            className={classNames('d-flex flex-column align-items-center', {
              'border-right-1': activeFlag,
              'w-100': !activeFlag,
            })}
          >
            <MaterialIcon
              name={icon}
              outlined={true}
              className={'mb-2 text-64'}
            />
            <h5 className="mb-2 text-64">{t('사고 종류')}</h5>
            <strong className="text-identity m-0">{t(title)}</strong>
          </li>
          {activeFlag && (
            <li className="d-flex flex-column align-items-center">
              <MaterialIcon
                name={'group'}
                outlined={true}
                className={'mb-2 text-64'}
              />
              <h5 className="mb-2 text-64">{t('재실 인원')}</h5>
              <strong className="text-identity m-0">
                {targetAppEndPointUserList.length}
              </strong>
            </li>
          )}
        </ul>
        {activeFlag && (
          <a
            className="btn btn-darken w-100 py-3 mt-4"
            onClick={handleClickEndAccident}
          >
            {t('사고 종료')}
          </a>
        )}
      </div>
      <div className="container-fluid py-4">
        <AccidentMessageHistory
          accidentId={eventId}
          registDate={registDate}
          reload={isMessageHistoryReload}
        />
        {activeFlag && (
          <AccidentMessageForm message={message} onChangeInput={setMessage} />
        )}
      </div>
      {showInvalidMessage && <InvalidAlert messageKey={invalidMessage} />}
      {activeFlag && (
        <div className="pub-con">
          <div className="pub-inner">
            <div className="btn-wrap">
              <a
                className={classNames('btn btn-accent w-100', {
                  'is-loading': loading,
                })}
                onClick={handleClickSendMessage}
              >
                {t('메세지 전송')}
              </a>
            </div>
          </div>
        </div>
      )}
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {t(alertMessage)}
      </AlertModal>
    </>
  );
}

export default AccidentDetail;
