import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import MaterialIcon from '@/components/common/MaterialIcon';
import classNames from 'classnames';
import { ModalProps, ReactSelectOption } from '@/modules/common';
import {
  fetchGroupAddableProjects,
  fetchGroupAddableSearchProjects,
  postGroupAddProjects,
} from '@/api/group';
import {
  GroupAddProject,
  GroupProject,
  GroupProjectRoleType,
} from '@/modules/group/types';
import SelectMulti from '@/components/SelectMulti';
import Pagination from '@/components/common/Pagination';

type GroupManagementAddProjectModalProps = ModalProps & {
  groupSeq: number;
  reloadFlag: boolean;
};

function GroupManagementAddProjectModal({
  groupSeq,
  reloadFlag,
  show,
  onHide,
  onReload,
}: GroupManagementAddProjectModalProps) {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchProjectList, setSearchProjectList] = useState<GroupProject[]>(
    []
  );
  const [projectList, setProjectList] = useState<GroupProject[]>([]);
  const [options, setOptions] = useState<ReactSelectOption[]>([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [authorityMap, setAuthorityMap] = useState<
    Map<string, GroupProjectRoleType>
  >(new Map());

  useEffect(() => {
    handleFetchAddableProjectList();
  }, [groupSeq, page, reloadFlag]);

  useEffect(() => {
    const newOptions: ReactSelectOption[] = [];
    searchProjectList.forEach(({ projectId, projectName }) => {
      newOptions.push({
        value: projectId,
        label: projectName,
        name: projectName,
      });
    });
    setOptions(newOptions);
  }, [searchProjectList]);

  useEffect(() => {
    let checked = true;

    if (projectList.length === 0) {
      setCheckedAll(false);
      return;
    }

    projectList.some(({ projectId }) => {
      if (!selectedValue.includes(projectId)) {
        checked = false;
        return true;
      }
    });
    setCheckedAll(checked);
  }, [selectedValue, projectList]);

  const handleFetchAddableProjectList = async () => {
    const result = await fetchGroupAddableProjects(groupSeq, page);
    setProjectList(result.content);
    setTotalCount(result.totalElements);
    const searchProjectListResult = await fetchGroupAddableSearchProjects(
      groupSeq
    );
    setSearchProjectList(searchProjectListResult);
  };

  const handleSubmit = async () => {
    const selectedProjectList: GroupAddProject[] = [];
    selectedValue.forEach((value) => {
      selectedProjectList.push({
        projectId: value,
        roleType: authorityMap.get(value) || 'user',
      });
    });

    const result = await postGroupAddProjects(groupSeq, selectedProjectList);

    if (result) {
      onReload?.call(null);
      onHide();
    }
  };

  const handleChangeCheckAll = (checked: boolean) => {
    let newSelectedValue = [...selectedValue];
    projectList.forEach(({ projectId }) => {
      if (checked) {
        if (!newSelectedValue.includes(projectId)) {
          newSelectedValue = [...newSelectedValue, projectId];
        }
      } else {
        newSelectedValue = newSelectedValue.filter(
          (value) => value !== projectId
        );
      }
    });
    setSelectedValue(newSelectedValue);
  };

  const handleChangeCheck = (checked: boolean, projectId: string) => {
    if (checked) {
      if (!selectedValue.includes(projectId)) {
        setSelectedValue([...selectedValue, projectId]);
      }
    } else {
      setSelectedValue(selectedValue.filter((value) => value !== projectId));
    }
  };

  const handleChangeAuthority = (
    projectId: string,
    authority: GroupProjectRoleType
  ) => {
    authorityMap.set(projectId, authority);
    setAuthorityMap(new Map(authorityMap));
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        //
      }}
      onExited={() => {
        setPage(1);
        setSelectedValue([]);
      }}
      centered={true}
      dialogClassName={'modal-custom'}
    >
      <ModalHeader>
        <button type="button" className="close custom-close" onClick={onHide}>
          <span>
            <MaterialIcon name={'clear'} />
          </span>
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="title-group mb-4">
          <h5 className="modal-title">{t('프로젝트 추가')}</h5>
          <p className="m-0">
            {t('그룹의 프로젝트 액세스 및 역할을 구성할 수 있습니다.')}
          </p>
        </div>
        <div className="form-group mb-5">
          <label className="form-label">{t('프로젝트 검색')}</label>
          <SelectMulti
            options={options}
            value={selectedValue}
            onChangeValue={setSelectedValue}
          />
        </div>
        <table className="table mb-4 table-nowrap">
          <colgroup>
            <col width="2%" />
            <col width="*" />
            <col width="30%" />
          </colgroup>
          <thead>
            <tr>
              <th style={{ width: '18px' }} className="pr-0">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input js-toggle-check-all"
                    id="checkAll"
                    onChange={(e) => handleChangeCheckAll(e.target.checked)}
                    checked={checkedAll}
                  />
                  <label className="custom-control-label" htmlFor="checkAll">
                    <span className="text-hide">Toggle all</span>
                  </label>
                </div>
              </th>
              <th>
                <a>{t('프로젝트')}</a>
              </th>
              <th>
                <a>{t('권한')}</a>
              </th>
            </tr>
          </thead>
          <tbody className="list">
            {projectList.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <div className="text-center py-4">
                    <span className="material-icons-outlined text-50 font-size-32pt">
                      info
                    </span>
                    <p className="m-0 text-50">{t('프로젝트가 없습니다.')}</p>
                  </div>
                </td>
              </tr>
            )}
            {projectList.map((project) => (
              <GroupProjectAddItem
                key={project.projectId}
                {...project}
                checked={selectedValue.includes(project.projectId)}
                authority={authorityMap.get(project.projectId)}
                onChangeCheck={handleChangeCheck}
                onChangeAuthority={handleChangeAuthority}
              />
            ))}
          </tbody>
        </table>
        {projectList.length > 0 && (
          <Pagination
            page={page}
            totalCount={totalCount}
            onChangePage={setPage}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-light" onClick={onHide}>
          {t('취소')}
        </button>
        <button
          type="button"
          className={classNames('btn', {
            'btn-light': selectedValue.length === 0,
            'btn-accent': selectedValue.length > 0,
          })}
          disabled={selectedValue.length === 0}
          onClick={handleSubmit}
        >
          {t('확인')}
        </button>
      </ModalFooter>
    </Modal>
  );
}

type GroupProjectAddItemProps = GroupProject & {
  checked: boolean;
  authority: GroupProjectRoleType | undefined;
  onChangeCheck: (checked: boolean, projectId: string) => void;
  onChangeAuthority: (
    projectId: string,
    authority: GroupProjectRoleType
  ) => void;
};

function GroupProjectAddItem({
  projectId,
  projectName,
  thumbnail,
  checked,
  authority,
  onChangeCheck,
  onChangeAuthority,
}: GroupProjectAddItemProps) {
  const { t } = useTranslation();

  return (
    <tr>
      <td className="pr-0">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input js-check-selected-row"
            id={`check_${projectId}`}
            onChange={(e) => onChangeCheck(e.target.checked, projectId)}
            checked={checked}
          />
          <label
            className="custom-control-label"
            htmlFor={`check_${projectId}`}
          >
            <span className="text-hide">Check</span>
          </label>
        </div>
      </td>
      <td>
        <div className="flex d-flex align-items-center mr-16pt">
          <a className="avatar mr-12pt">
            <img src={thumbnail} className="avatar-img rounded" />
          </a>
          <div className="flex list-els">
            <a className="card-title">{projectName}</a>
          </div>
        </div>
      </td>
      <td>
        <select
          className="form-control custom-select"
          value={authority}
          onChange={(e) => {
            onChangeAuthority(
              projectId,
              e.target.value as GroupProjectRoleType
            );
          }}
        >
          <option value="user">{t('사용자')}</option>
          <option value="admin">{t('관리자')}</option>
        </select>
      </td>
    </tr>
  );
}

export default GroupManagementAddProjectModal;
