import axios from 'axios';
import { Config } from '@/config';
import { StatusCodes } from 'http-status-codes';
import { NodeResult, Pagination, SpaceResult } from '@/api/type';
import {
  AreaInfo,
  PoiInfo,
  SPACE_COUNTRY_CODE_ETC,
  SpaceCountryCode,
  UploadFloorInfo,
} from '@/modules/space/types';
import { WKT } from 'ol/format';
import {
  FLOOR_OBJECT_TYPE_AREA,
  FLOOR_OBJECT_TYPE_GEOFENCE,
  GeofenceInfo,
} from '@/modules/floor/types';
import { Fill, Stroke, Style } from 'ol/style';
import { asArray } from 'ol/color';

export type ResponseSpaceMetaInfo = {
  meta_id: string;
  meta_name: string;
  regist_date: string;
  update_date: string;
  country_code: string;
  country_txt: string;
  city_txt: string;
  address_txt1: string;
  lng: number;
  lat: number;
  thumbnail: string;
  floor_cnt: number;
};

export type RequestSpaceMetaInfo = {
  metaname: string;
  imgId: string;
  countrycode: number;
  addres1: string;
  addres2: string;
  city: string;
  zipcode: string;
  country: string;
  lng: number;
  lat: number;
  userid: string;
};

export type ResponseFloorInfo = {
  cx: number;
  cy: number;
  filename: string;
  orifilename: string;
  map_floor: number;
  map_id: string;
  map_image_id: string;
  map_name: string;
  meta_id: string;
  regist_date: string;
  rotation: number;
  scalex: number;
  scaley: number;
  opacity: number;
  poi_cnt: number;
  area_cnt: number;
  asset_cnt: number;
  util_cnt: number;
};

export type RequestFloorInfo = {
  metaid: string;
  mapname: string;
  userid: string;
  floor: number;
};

export type RequestFloorFile = RequestFloorInfo & {
  file: File | null;
  mapid?: string;
  cx: number;
  cy: number;
  imgRotate: number;
  imgScalex: number;
  imgScaley: number;
  opacity: number;
  div: 'insert' | 'update';
};

export type RequestUpdateFloorInfo = RequestFloorInfo & {
  mapid: string;
  filename: string;
  originalname: string;
  cx: number;
  cy: number;
  imgRotate: number;
  imgScalex: number;
  imgScaley: number;
  opacity: number;
};

export type RequestsetFloorPlan = {
  mapid: string;
  mapname: string;
  userid: string;
  floor: number;
  cx: number;
  cy: number;
  imgRotate: number;
  imgScalex: number;
  imgScaley: number;
};

type RequestFetchSpacesParams = {
  userid: string;
  pageno?: number;
  metaname?: string;
};

export async function fetchSpaces(
  recent: boolean,
  userId: string,
  page: number,
  searchKeyword: string
) {
  const axiosInstance = axios.create({
    withCredentials: false,
  });

  const params: RequestFetchSpacesParams = {
    userid: userId,
  };

  if (!recent) {
    params.pageno = page;
    params.metaname = searchKeyword;
  } else {
    params.pageno = -1;
  }

  const response = await axiosInstance.get(
    `${Config.space_api.uri}/mapmetaInfos`,
    {
      params,
    }
  );

  if (response.status === StatusCodes.OK) {
    const data = response.data;
    const list = data[0] as ResponseSpaceMetaInfo[];
    const { totcount } = data[1][0] as Pagination;

    list.forEach((metaInfo) => {
      metaInfo.thumbnail = `/static/images/slider0${Number(
        Math.floor(Math.random() * (4 - 1) + 1)
      )}.jpg`;
    });

    return {
      list,
      listCount: Number(totcount),
    };
  }

  throw new Error('Server Error');
}

type ResponseSpaceInfo = {
  meta_id: string;
  meta_name: string;
  map_id: string;
  map_floor: number;
  map_name: string;
  lng: number;
  lat: number;
  country_code: SpaceCountryCode;
  country_txt: string;
  city_txt: string;
  address_txt1: string;
  floor_cnt: number;
};

export type ProcessSpaceInfo = {
  spaceId: string;
  spaceName: string;
  lng: number;
  lat: number;
  countryCode: SpaceCountryCode;
  country: string;
  city: string;
  address: string;
  floorCnt: number;
  floorList: {
    floorId: string;
    floorValue: number;
    floorName: string;
  }[];
};

export async function fetchSpaceInfo(spaceId: string) {
  const axiosInstance = axios.create({
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });

  try {
    const response = await axiosInstance.get(
      `${Config.space_api.uri}/mapMetaInfosList`,
      {
        params: {
          metaid: spaceId,
        },
      }
    );

    if (response.status === StatusCodes.OK) {
      const processData: ProcessSpaceInfo = {
        spaceId: '',
        spaceName: '',
        lng: 0,
        lat: 0,
        countryCode: SPACE_COUNTRY_CODE_ETC,
        country: '',
        city: '',
        address: '',
        floorCnt: 0,
        floorList: [],
      };

      const data = response.data.result as ResponseSpaceInfo[];
      data.forEach((datum, i) => {
        if (i === 0) {
          processData.spaceId = datum.meta_id;
          processData.spaceName = datum.meta_name;
          processData.lng = Number(datum.lng);
          processData.lat = Number(datum.lat);
          processData.countryCode = Number(
            datum.country_code
          ) as SpaceCountryCode;
          processData.country = datum.country_txt;
          processData.city = datum.city_txt;
          processData.address = datum.address_txt1;
          processData.floorCnt = Number(datum.floor_cnt);
        }

        if (datum.map_floor != null && datum.map_name) {
          processData.floorList.push({
            floorId: datum.map_id,
            floorValue: datum.map_floor,
            floorName: datum.map_name,
          });
        }
      });

      return processData;
    }
  } catch (e) {}

  return null;
}

export async function fetchSpaceListInfo(spaceIds: string[]) {
  const result: ProcessSpaceInfo[] = [];

  try {
    for (const spaceId of spaceIds) {
      const info = await fetchSpaceInfo(spaceId);
      if (info) {
        result.push(info);
      }
    }
  } catch (e) {}

  return result;
}

export async function fetchAllSpaces(userId: string) {
  const axiosInstance = axios.create({
    withCredentials: false,
  });

  try {
    const response = await axiosInstance.get(
      `${Config.space_api.uri}/mapmetaInfosWFloor`,
      {
        params: {
          userid: userId,
        },
      }
    );

    if (response.status === StatusCodes.OK) {
      return response.data.result as ResponseSpaceMetaInfo[];
    }
  } catch (e) {}

  return null;
}

export async function postSpaceMetaInfo(data: RequestSpaceMetaInfo) {
  const axiosInstance = axios.create({
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });

  try {
    const response = await axiosInstance.post(
      `${Config.space_api.uri}/mapmetaInfo`,
      {
        params: data,
      }
    );
    return response.data as ResponseSpaceMetaInfo[];
  } catch (e) {
    return null;
  }
}

export type RequestUpdateSpace = {
  metaid: string;
  userid: string;
  del?: boolean;
  countrycode?: number;
  addres1?: string;
  city?: string;
  country?: string;
  lng?: number;
  lat?: number;
  metaname?: string;
};

export async function postUpdateSpace(data: RequestUpdateSpace) {
  try {
    const axiosInstance = axios.create({
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

    const response = await axiosInstance.post(
      `${Config.space_api.uri}/updateMapmetaInfo`,
      {
        params: data,
      }
    );
    if (response.status === StatusCodes.OK) {
      const result = response.data as SpaceResult;
      if (result.success === 'ok') {
        return true;
      } else {
        return false;
      }
    }
  } catch (e) {}
  return false;
}

export async function fetchFloors(userId: string, metaId: string) {
  const axiosInstance = axios.create({
    withCredentials: false,
  });

  try {
    const response = await axiosInstance.get(
      `${Config.space_api.uri}/mapInfos`,
      {
        params: {
          userid: userId,
          metaid: metaId,
        },
      }
    );
    if (response.status === StatusCodes.OK && response.data) {
      return response.data as ResponseFloorInfo[];
    }
  } catch (e) {}

  return null;
}

export async function postFloorInfo(data: RequestFloorInfo) {
  try {
    const axiosInstance = axios.create({
      withCredentials: false,
    });

    const response = await axiosInstance.post(
      `${Config.space_api.uri}/mapInfo`,
      {
        params: data,
      }
    );
    const responseData = response.data as {
      lbs_gid: string;
      map_id: string;
    }[];

    if (responseData.length) {
      return Boolean(responseData[0].map_id);
    }
  } catch (e) {}

  return false;
}

export async function postFloorFile(data: RequestFloorFile) {
  try {
    const axiosInstance = axios.create({
      withCredentials: false,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const formData = new FormData();
    if (data.file) {
      formData.append('file', data.file);
    }
    formData.append('metaid', data.metaid);
    formData.append('userid', data.userid);
    formData.append('mapname', data.mapname);
    formData.append('floor', data.floor.toString());
    formData.append('cx', data.cx.toString());
    formData.append('cy', data.cy.toString());
    formData.append('imgRotate', data.imgRotate.toString());
    formData.append('imgScalex', data.imgScalex.toString());
    formData.append('imgScaley', data.imgScaley.toString());
    formData.append('opacity', data.opacity.toString());
    formData.append('div', data.div);
    if (data.mapid) {
      formData.append('mapid', data.mapid);
    }

    const response = await axiosInstance.post(
      `${Config.space_api.uri}/upload`,
      formData
    );

    if (response.status === StatusCodes.OK) {
      const data = response.data as UploadFloorInfo[];
      if (data.length > 0) {
        return data[0];
      }
    }
  } catch (e) {}

  return null;
}

export async function postUpdateFloor(data: RequestUpdateFloorInfo) {
  try {
    const axiosInstance = axios.create({
      withCredentials: false,
    });

    const response = await axiosInstance.post(
      `${Config.space_api.uri}/updatemapimageInfo`,
      {
        params: data,
      }
    );

    if (response.status === StatusCodes.OK) {
      const responseData = response.data as {
        map_id: string;
      }[];
      if (responseData.length) {
        return Boolean(responseData[0].map_id);
      }
    }
  } catch (e) {}

  return false;
}

export type RequestDeleteFloorPlan = {
  mapid: string;
  userid: string;
  del: boolean;
};

export async function postDeleteFloorPlan(data: RequestDeleteFloorPlan) {
  try {
    const axiosInstance = axios.create({
      withCredentials: false,
    });

    const response = await axiosInstance.post(
      `${Config.space_api.uri}/updateMapInfo`,
      {
        params: data,
      }
    );

    if (response.status === StatusCodes.OK) {
      const result = response.data as SpaceResult;
      if (result.success === 'ok') {
        return true;
      } else {
        return false;
      }
    }
  } catch (e) {}
  return false;
}

export async function fetchArea(params: { mapid: string }) {
  let areaList: AreaInfo[] = [];

  try {
    const axiosInstance = axios.create({
      withCredentials: false,
    });
    const response = await axiosInstance.get(
      `${Config.space_api.uri}/areaInfos`,
      {
        params,
      }
    );

    if (response.status === StatusCodes.OK && response.data) {
      const wkt = new WKT();
      const data = response.data as AreaInfo[];
      data.forEach((datum) => {
        const feature = wkt.readFeature(datum.geomstr);
        feature.setId(datum.id);
        feature.set('name', datum.areaname);
        feature.set('type', FLOOR_OBJECT_TYPE_AREA);
        feature.set('area_info', datum);
        datum.feature = feature;
      });
      areaList = data;
    }
  } catch (e) {}

  return areaList;
}

export async function fetchGeofence(params: { mapid: string }) {
  let geofenceList: GeofenceInfo[] = [];

  try {
    const axiosInstance = axios.create({
      withCredentials: false,
    });
    const response = await axiosInstance.get(
      `${Config.space_api.uri}/zoneMngInfos`,
      {
        params,
      }
    );

    if (response.status === StatusCodes.OK && response.data) {
      const data = response.data as NodeResult<GeofenceInfo[]>;
      const wkt = new WKT();
      data.result.forEach((datum) => {
        const feature = wkt.readFeature(datum.geomstr);
        const lineColor = asArray(datum.line_color);
        const areaColor = asArray(datum.area_color);
        feature.setId(datum.zone_id);
        feature.set('name', datum.zone_name);
        feature.set('type', FLOOR_OBJECT_TYPE_GEOFENCE);
        feature.set('geofence_info', datum);
        feature.setStyle(
          new Style({
            stroke: new Stroke({
              lineDash: [10],
              color: `rgba(${lineColor[0]}, ${lineColor[1]}, ${
                lineColor[2]
              }, ${0.8})`,
            }),
            fill: new Fill({
              color: `rgba(${areaColor[0]}, ${areaColor[1]}, ${
                areaColor[2]
              }, ${0.1})`,
            }),
          })
        );
        datum.feature = feature;
      });
      geofenceList = data.result;
    }
  } catch (e) {}

  return geofenceList;
}

export async function fetchPoi(params: { mapid: string }) {
  let poiList: PoiInfo[] = [];

  try {
    const axiosInstance = axios.create({
      withCredentials: false,
    });
    const response = await axiosInstance.get(
      `${Config.space_api.uri}/poiInfos`,
      {
        params,
      }
    );

    if (response.status === StatusCodes.OK && response.data) {
      poiList = response.data as PoiInfo[];
    }
  } catch (e) {}

  return poiList;
}
