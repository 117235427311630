import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAuthNumber, postAuthNumberMail } from '@/api/user';
import { getLanguageCode } from '@/locales/i18n';

type SignUpNumberAuthenticationProps = {
  signUpEmail: string;
  onMatchNumber: () => void;
};

function SignUpNumberAuthentication({
  signUpEmail,
  onMatchNumber,
}: SignUpNumberAuthenticationProps) {
  const { i18n, t } = useTranslation();

  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [authNumber, setAuthNumber] = useState('');
  const [isCheck, setCheck] = useState(false);

  useEffect(() => {
    setCheck(false);
  }, [authNumber]);

  const handleReSendMail = async () => {
    const result = await postAuthNumberMail(signUpEmail, getLanguageCode());
    if (result) {
      setCheck(false);
    }
  };

  const handleClickCheck = async () => {
    if (!authNumber) {
      setFeedbackMessage(t('인증번호를 입력해 주세요'));
      return;
    }

    // TODO: API
    const result = await getAuthNumber(signUpEmail, authNumber);

    if (result) {
      setFeedbackMessage(t('인증번호가 일치합니다.'));
      setCheck(true);
    } else {
      setFeedbackMessage(t('인증번호가 일치하지 않습니다'));
    }
  };

  const handleClickNext = () => {
    if (!authNumber) {
      setFeedbackMessage(t('인증번호를 입력해 주세요'));
      return;
    }
    if (!isCheck) {
      setFeedbackMessage(t('인증번호를 확인해 주세요'));
    } else {
      onMatchNumber();
    }
  };

  return (
    <>
      <div className="title-group mb-5">
        <h3 className="m-0">{t('이메일 인증')}</h3>
      </div>
      <div className="form-group">
        <label className="form-label text-50 essential" htmlFor="authNumber">
          {t('인증번호 확인')}
        </label>
        <div className="over-txt">
          <input
            id="authNumber"
            type="text"
            className="form-line"
            style={{ paddingRight: '6rem' }}
            placeholder={t('인증번호 입력해 주세요.')}
            value={authNumber}
            onChange={(e) => {
              setAuthNumber(e.target.value);
            }}
            autoComplete={'off'}
          />
          <a
            className="btn-abs btn-outline-secondary btn-sm btn-rounded"
            onClick={handleClickCheck}
          >
            {t('인증번호 확인')}
          </a>
        </div>
      </div>
      {feedbackMessage && (
        <div className="invalid-feedback">{feedbackMessage}</div>
      )}
      <div className="support-feedback">
        {t('메일을 받지 못하셨나요?')}
        <a className="text-body text-underline" onClick={handleReSendMail}>
          {t('재전송하기')}
        </a>
      </div>
      <div className="form-group text-center mt-5 mb-32pt">
        <button
          className="btn btn-block btn-lg btn-accent"
          type="button"
          onClick={handleClickNext}
        >
          {t('다음')}
        </button>
      </div>
    </>
  );
}

export default SignUpNumberAuthentication;
