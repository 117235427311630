import MaterialIcon from '@/components/common/MaterialIcon';
import {
  useHomeBuilding,
  useHomeFloor,
  useHomeProject,
} from '@/modules/home/hook';
import { useOpenLayersResource } from '@/modules/openlayers/hook';
import { useSelectedSensor } from '@/modules/sensor/hook';
import {
  SensorType,
  SENSOR_BLE_TYPE,
  SENSOR_CONNECTION_DISCONNECTED_STATUS,
  SENSOR_CONNECTION_ERROR_STATUS,
  SENSOR_CONNECTION_NORMAL_STATUS,
  SENSOR_ENVIRONMENT_TYPE,
  SENSOR_TEMPERATURE_TYPE,
} from '@/modules/sensor/types';
import { useUser } from '@/modules/user/hook';
import { CommonUtils } from '@/utils';
import { WKT } from 'ol/format';
import { Polygon } from 'ol/geom';
import React, { ReactElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type SensorLeftPaneTableRowProps = {
  sensor: SensorType;
  setShowModal: (showModal: boolean) => void;
  setSelectedSensor: (selectedSensor: SensorType) => void;
};

function SensorLeftPaneTableRow({
  sensor,
  setShowModal,
  setSelectedSensor,
}: SensorLeftPaneTableRowProps): ReactElement {
  const { t } = useTranslation();
  const {
    user: { userId },
  } = useUser();
  const { map } = useOpenLayersResource();
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const {
    selectedSensor: selectedSensorId,
    setSelectedSensor: setSelectedSensorId,
  } = useSelectedSensor();

  const showModal = () => {
    setShowModal(true);
    setSelectedSensor(sensor);
  };

  // 해당 센서 포커싱 및 지도 중앙으로 정렬
  const focusCenter = () => {
    const wkt = new WKT();
    const polygon = wkt.readFeature(sensor.geomstr).getGeometry() as Polygon;
    const [longitude, latitude] = polygon.getCoordinates();
    map?.getView().setCenter([Number(longitude), Number(latitude)]);
    selectedSensorId === sensor.sensor_serial
      ? setSelectedSensorId('')
      : setSelectedSensorId(sensor.sensor_serial);
  };

  // 센서 정보 파라미터로 넘기면서 Editor쪽 창 띄우기
  const openSensorUpdateWindow = () =>
    project?.projectId &&
    building?.mappingId &&
    floor?.mapId &&
    CommonUtils.openSensorUpdateWindow(
      userId,
      project.projectId,
      building.metaId,
      floor.mapId,
      sensor.sensor_serial
    );

  return (
    <tr className={selectedSensorId === sensor.sensor_serial ? `selected` : ``}>
      <td className="text-center" width="30%">
        <a
          onClick={showModal}
          className="d-inline-flex"
          data-toggle="modal"
          data-target="#detailItem05"
        >
          {sensor.sensor_type === SENSOR_ENVIRONMENT_TYPE && t('환경센서')}
          {sensor.sensor_type === SENSOR_TEMPERATURE_TYPE && t('온습도센서')}
          {sensor.sensor_type === SENSOR_BLE_TYPE && t('측위센서')}
        </a>
      </td>
      <td width="40%">
        <a
          onClick={showModal}
          className="d-flex"
          data-toggle="modal"
          data-target="#detailItem05"
        >
          <div className="d-inline-flex align-items-center">
            <span
              className={`material-icons-outlined ${
                sensor.connection_condition ===
                  SENSOR_CONNECTION_NORMAL_STATUS ||
                sensor.connection_condition === SENSOR_CONNECTION_ERROR_STATUS
                  ? `text-green`
                  : ``
              }`}
            >
              {sensor.sensor_type === SENSOR_ENVIRONMENT_TYPE && 'air'}
              {sensor.sensor_type === SENSOR_TEMPERATURE_TYPE && 'thermostat'}
              {sensor.sensor_type === SENSOR_BLE_TYPE && 'sensors'}
            </span>
            {sensor.util_name ? (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="sensor-serial-id">{sensor.util_name}</Tooltip>
                }
              >
                <span className="ml-2 ellipsis">{sensor.util_name}</span>
              </OverlayTrigger>
            ) : (
              <span className="ml-2 text-red">{t('등록필요')}</span>
            )}
          </div>
        </a>
      </td>
      <td className="text-center" width="20%">
        <ul className="state-item">
          <li className="arry01">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="sensor-serial-time-diff">
                  {t('%분 전', { '%': sensor.timediff })}
                </Tooltip>
              }
            >
              <span className="text-64">
                {sensor.sensor_type === SENSOR_ENVIRONMENT_TYPE &&
                  Number(sensor.sensing1).toFixed(1)}
                {sensor.sensor_type === SENSOR_TEMPERATURE_TYPE &&
                  `${Number(sensor.sensing1).toFixed(1)}\u00b0`}
                {sensor.sensor_type === SENSOR_BLE_TYPE &&
                  sensor.connection_condition ===
                    SENSOR_CONNECTION_NORMAL_STATUS &&
                  `${t('안정')}`}
                {sensor.sensor_type === SENSOR_BLE_TYPE &&
                  sensor.connection_condition ===
                    SENSOR_CONNECTION_DISCONNECTED_STATUS &&
                  `${t('연결끊김')}`}
                {sensor.sensor_type === SENSOR_BLE_TYPE &&
                  sensor.connection_condition ===
                    SENSOR_CONNECTION_ERROR_STATUS &&
                  `${t('불안정')}`}
              </span>
            </OverlayTrigger>
          </li>
          <li className="arry02">
            {sensor.util_name ? (
              <a onClick={focusCenter} className="d-flex">
                <MaterialIcon
                  name="place"
                  className={`material-icons${
                    selectedSensorId === sensor.sensor_serial
                      ? `-sharp`
                      : `-outlined`
                  }`}
                />
              </a>
            ) : (
              <a onClick={openSensorUpdateWindow} className="d-flex">
                <MaterialIcon
                  name="add_location"
                  className="text-identity"
                  outlined
                />
              </a>
            )}
          </li>
        </ul>
      </td>
    </tr>
  );
}

export default SensorLeftPaneTableRow;
