import { useContext } from 'react';
import {
  SpaceDispatchContext,
  SpaceStateContext,
} from '@/modules/space/context';
import {
  RegisterSelectedAddress,
  SPACE_CHANGE_SHOW_ADDRESS_PANE,
  SPACE_SET_ADDRESS,
} from '@/modules/space/types';

function useState() {
  const state = useContext(SpaceStateContext);
  if (!state) throw new Error('SpaceProvider not found');
  return state;
}

function useDispatch() {
  const dispatch = useContext(SpaceDispatchContext);
  if (!dispatch) throw new Error('SpaceProvider not found');
  return dispatch;
}

export function useSpaceRegister() {
  const state = useState();
  const dispatch = useDispatch();

  const handleChangeShowAddressPane = (show: boolean) => {
    dispatch({
      type: SPACE_CHANGE_SHOW_ADDRESS_PANE,
      show,
    });
  };

  const handleSetAddress = (selectedAddress: RegisterSelectedAddress) => {
    dispatch({
      type: SPACE_SET_ADDRESS,
      selectedAddress,
    });
  };

  const { address } = state.register;
  const {
    selectedDo,
    selectedSubDistrict,
    selectedLngLat,
    detailedAddress,
    showPane,
  } = address;

  return {
    showPane,
    selectedDo,
    selectedSubDistrict,
    selectedLngLat,
    detailedAddress,
    handleChangeShowAddressPane,
    handleSetAddress,
  };
}
