import { useCallback, useContext, useMemo } from 'react';
import { HomeDispatchContext, HomeStateContext } from '@/modules/home/context';
import {
  HOME_CHANGE_BUILDING,
  HOME_CHANGE_FLOOR,
  HOME_CHANGE_PROJECT,
  HOME_DATA_CLEAR,
  HOME_SET_DATA_GROUP,
  HOME_SET_DATA_USER,
  HOME_SET_MENU_ACTIVE,
  Menu,
} from '@/modules/home/types';
import { useUser } from '@/modules/user/hook';
import { MENU_IDX_DASHBOARD, MenuIdx } from '@/modules/setup/types';
import {
  ProjectDetail,
  ProjectSpace,
  ProjectSpaceFloor,
} from '@/modules/project/types';
import { fetchProject } from '@/api/project';
import { useHistory } from 'react-router-dom';

function useHomeState() {
  const state = useContext(HomeStateContext);
  if (!state) throw new Error('HomeProvider not found');
  return state;
}

function useHomeDispatch() {
  const dispatch = useContext(HomeDispatchContext);
  if (!dispatch) throw new Error('HomeProvider not found');
  return dispatch;
}

export function useHomeMenu() {
  const { user } = useUser();
  const { menus } = useHomeState();
  const dispatch = useHomeDispatch();

  const handleSetMenu = (menuIdx: MenuIdx) => {
    dispatch({
      type: HOME_SET_MENU_ACTIVE,
      menuIdx,
    });
  };

  const activeMenu: Menu | undefined = useMemo(() => {
    let findMenu: Menu | undefined = undefined;
    const activeMenu = menus.find(({ active }) => active);
    if (activeMenu) {
      findMenu = activeMenu;
    }

    if (!findMenu) {
      menus.some((menu) => {
        const activeSubMenu = menu.subMenus.find((subMenu) => subMenu.active);
        if (activeSubMenu) {
          findMenu = menu;
          return true;
        }
      });
    }

    return findMenu;
  }, [menus]);

  const activeMenuIdx: MenuIdx = useMemo(() => {
    let menuIdx = -1;
    const activeMenu = menus.find((menu) => menu.active);
    if (activeMenu) {
      menuIdx = activeMenu.menuIdx;
    }

    if (menuIdx === -1) {
      menus.some((menu) => {
        const activeSubMenu = menu.subMenus.find((subMenu) => subMenu.active);
        if (activeSubMenu) {
          menuIdx = activeSubMenu.menuIdx;
          return true;
        }
      });
    }
    return menuIdx as MenuIdx;
  }, [menus]);

  const filterMenus: Menu[] = useMemo(() => {
    if (user.userType) {
      return menus.filter((menu) => {
        if (!menu.display) {
          return false;
        }

        menu.subMenus = menu.subMenus.filter((subMenu) => {
          if (subMenu.allowableUserType.length) {
            return subMenu.allowableUserType.includes(user.userType);
          } else {
            return true;
          }
        });

        if (menu.allowableUserType.length) {
          return menu.allowableUserType.includes(user.userType);
        } else {
          return true;
        }
      });
    }
    return [];
  }, [menus, user.userId, user.userType]);

  return {
    menus: filterMenus,
    activeMenu,
    activeMenuIdx,
    handleSetMenu,
  };
}

export function useHomeSelected() {
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();

  return {
    project,
    building,
    floor,
  };
}

export function useHomeProject() {
  const {
    selected: { project: selectedProject },
  } = useHomeState();
  const dispatch = useHomeDispatch();
  const { handleSetMenu } = useHomeMenu();
  const history = useHistory();

  const handleChangeProject = useCallback(
    async (projectId: string, sendMenuIdx: MenuIdx = MENU_IDX_DASHBOARD) => {
      let project: ProjectDetail | null = null;

      if (projectId) {
        try {
          project = await fetchProject(projectId);
        } catch (e) {}
      }

      dispatch({
        type: HOME_CHANGE_PROJECT,
        project,
      });

      if (!project) {
        handleSetMenu(sendMenuIdx);
        history.replace('/home');
      }
    },
    []
  );

  return {
    project: selectedProject,
    handleChangeProject,
  };
}

export function useHomeBuilding() {
  const {
    selected: { building },
  } = useHomeState();
  const dispatch = useHomeDispatch();

  const handleChangeBuilding = (building: ProjectSpace | null) => {
    dispatch({
      type: HOME_CHANGE_BUILDING,
      building,
    });
  };

  return {
    building,
    handleChangeBuilding,
  };
}

export function useHomeFloor() {
  const {
    selected: { floor },
  } = useHomeState();
  const dispatch = useHomeDispatch();

  const handleChangeFloor = (floor: ProjectSpaceFloor | null) => {
    dispatch({
      type: HOME_CHANGE_FLOOR,
      floor,
    });
  };

  return {
    floor,
    handleChangeFloor,
  };
}

export function useHomeData() {
  const { data } = useHomeState();
  const dispatch = useHomeDispatch();
  const { groupSettingUser, groupSettingGroup } = data;

  const handleSetDataUser = (userId: string) => {
    dispatch({
      type: HOME_SET_DATA_USER,
      userId,
    });
  };

  const handleSetDataGroup = (groupSeq: number) => {
    dispatch({
      type: HOME_SET_DATA_GROUP,
      groupSeq,
    });
  };

  const handleDataClear = () => {
    dispatch({
      type: HOME_DATA_CLEAR,
    });
  };

  return {
    groupSettingUser,
    groupSettingGroup,
    handleSetDataUser,
    handleSetDataGroup,
    handleDataClear,
  };
}
