import {
  useBuildingInfoList,
  useFloorPlanList,
  useOpenLayers,
  useSelectedBuilding,
  useSelectedFloorPlanId,
} from '@/modules/project/hook';
import {
  PANE_STATUS_LIST,
  PANE_STATUS_REGISTER,
  PaneStatus,
} from '@/utils/constants/common';
import React, { ReactElement, useEffect, useState } from 'react';
import AssetList from './AssetList';
import AssetRegister from './AssetRegister';

function AssetRegisterTab(): ReactElement {
  const { map, geoImage } = useOpenLayers();
  const { selectedBuilding, setSelectedBuilding } = useSelectedBuilding();
  const { setBuildingInfoList } = useBuildingInfoList();
  const {
    selectedFloorPlanId,
    setSelectedFloorPlanId,
  } = useSelectedFloorPlanId();
  const { setFloorPlanList } = useFloorPlanList();
  const [paneStatus, setPaneStatus] = useState<PaneStatus>(PANE_STATUS_LIST);

  useEffect(() => {
    if (map) {
      if (selectedBuilding && selectedBuilding.spaceId) {
        map.getView().setCenter([selectedBuilding.lng, selectedBuilding.lat]);
        map.getView().setZoom(13);
      }
    }
  }, [map, selectedBuilding, selectedFloorPlanId]);

  useEffect(() => {
    return () => {
      setBuildingInfoList([]);
      setSelectedBuilding(null);
      setSelectedFloorPlanId('');
      setFloorPlanList([]);
      geoImage.remove();
    };
  }, []);

  return (
    <>
      {paneStatus === PANE_STATUS_LIST && (
        <AssetList setPaneStatus={setPaneStatus} />
      )}
      {paneStatus === PANE_STATUS_REGISTER && (
        <AssetRegister setPaneStatus={setPaneStatus} />
      )}
    </>
  );
}

export default AssetRegisterTab;
