import React, { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { Action, OpenLayersState } from '@/modules/openlayers/types';
import { openLayersReducer } from '@/modules/openlayers/reducer';

export const OpenLayersStateContext = createContext<OpenLayersState | null>(
  null
);
type OpenLayersDispatch = Dispatch<Action>;
export const OpenLayersDispatchContext = createContext<OpenLayersDispatch | null>(
  null
);

export const initialState: OpenLayersState = {
  map: null,
  draw: {
    interaction: null,
    resource: null,
  },
  geofence: {
    layer: null,
  },
  monitoring: {
    lidar: {
      personLayer: null,
    },
  },
};

export function OpenLayersContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [openLayers, dispatch] = useReducer(openLayersReducer, initialState);

  return (
    <OpenLayersDispatchContext.Provider value={dispatch}>
      <OpenLayersStateContext.Provider value={openLayers}>
        {children}
      </OpenLayersStateContext.Provider>
    </OpenLayersDispatchContext.Provider>
  );
}
