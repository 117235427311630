import {
  ACCESS_LOGOUT,
  ACCESS_LOGOUT_ERROR,
  ACCESS_LOGOUT_SUCCESS,
  Action,
  LogoutState,
} from '@/modules/access/logout/types';
import {
  ASYNC_ERROR_STATE,
  ASYNC_LOADING_STATE,
  ASYNC_SUCCESS_STATE,
} from '@/utils/constants/common';
import createAsyncDispatcher from '@/utils/asyncActionUtils';
import { deleteAccessToken } from '@/api/user';

export function logoutReducer(state: LogoutState, action: Action) {
  switch (action.type) {
    case ACCESS_LOGOUT:
      return {
        ...ASYNC_LOADING_STATE,
      };
    case ACCESS_LOGOUT_SUCCESS:
      return {
        ...ASYNC_SUCCESS_STATE(action.data),
      };
    case ACCESS_LOGOUT_ERROR:
      return {
        ...ASYNC_ERROR_STATE(action.error),
      };
  }
}

export const removeAccessToken = createAsyncDispatcher(
  ACCESS_LOGOUT,
  deleteAccessToken
);
