import { SelectOptionType } from '@/modules/assets/types';
import React, { ReactElement } from 'react';

type AssetExcelComboBoxProps = {
  defaultValue?: string;
  emptyValueText: string;
  optionList: SelectOptionType[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  errorMessage: string;
};

function AssetExcelComboBox({
  defaultValue,
  emptyValueText,
  optionList,
  onChange,
  errorMessage,
}: AssetExcelComboBoxProps): ReactElement {
  return (
    <td className="form-group mb-32pt">
      <select
        name="category"
        className={`form-line ${errorMessage ? `is-invalid` : ``}`}
        value={defaultValue}
        onChange={onChange}
      >
        <option value="">{emptyValueText}</option>
        {optionList.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </td>
  );
}

export default AssetExcelComboBox;
