import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHomeFloor } from '@/modules/home/hook';
import Map from 'ol/Map';
import { getGeoImageLayer, getGeoImageSource } from '@/utils/ol-ext-bridge';
import { Config } from '@/config';

type FloorPlanControlProps = {
  map: Map;
};

function FloorPlanControl({ map }: FloorPlanControlProps) {
  const { floor } = useHomeFloor();
  const [isShow, setShow] = useState(false);
  const [geoImageLayer, setGeoImageLayer] = useState<any>(null);

  useEffect(() => {
    setGeoImageLayer(getGeoImageLayer());
  }, [map]);

  const handleClickControl = () => {
    if (!isShow && floor) {
      geoImageLayer.setSource(
        getGeoImageSource({
          url: `${Config.space_api.uri}/bprint/${floor.filename}`,
          imageCenter: [Number(floor.cx), Number(floor.cy)],
          imageScale: [Number(floor.scalex), Number(floor.scaley)],
          imageRotate: Number(floor.rotation),
        })
      );
      geoImageLayer.setOpacity(floor.opacity || 1);
      map.addLayer(geoImageLayer);
    } else {
      map.removeLayer(geoImageLayer);
    }
    setShow(!isShow);
  };

  return (
    <a
      className={classNames('widget-btn', {
        active: isShow,
      })}
      onClick={handleClickControl}
    >
      <img
        src={
          isShow
            ? '/static/images/icon/map_layer_white.svg'
            : '/static/images/icon/map_layer.svg'
        }
      />
    </a>
  );
}

export default FloorPlanControl;
