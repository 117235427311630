import React, { useEffect, useState } from 'react';
import { ModalProps, ReactSelectOption } from '@/modules/common';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import MaterialIcon from '@/components/common/MaterialIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import SelectMulti from '@/components/SelectMulti';
import { Group } from '@/modules/group/types';

export type UserManagementAddGroupData = {
  userId: string;
  invitationSeq: number;
};

type UserManagementAddGroupModalProps = ModalProps & {
  groupList: Group[];
  onSubmit: (selectedValue: number[]) => void;
};

function UserManagementAddGroupModal({
  show,
  groupList,
  onHide,
  onSubmit,
}: UserManagementAddGroupModalProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<ReactSelectOption[]>([]);
  const [selectedValue, setSelectedValue] = useState<number[]>([]);

  const handleConfirm = async () => {
    if (selectedValue.length > 0) {
      onSubmit(selectedValue);
      onHide();
    }
  };

  useEffect(() => {
    const newOptions: ReactSelectOption[] = [];
    groupList.forEach((group) => {
      if (!group.deleteFlag) {
        newOptions.push({
          value: group.groupSeq.toString(),
          label: group.groupName,
          name: group.groupName,
        });
      }
    });
    setOptions(newOptions);
  }, [groupList]);

  return (
    <Modal
      show={show}
      onExited={() => {
        setLoading(false);
        setSelectedValue([]);
      }}
      onHide={() => {
        //
      }}
      centered={true}
      dialogClassName={'modal-custom'}
    >
      <ModalHeader>
        <button type="button" className="close custom-close" onClick={onHide}>
          <span>
            <MaterialIcon name={'clear'} />
          </span>
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="title-group mb-4">
          <ModalTitle as={'h5'}>{t('그룹에 추가')}</ModalTitle>
          <p className="m-0">
            {t(
              '그룹 구성원은 동일한 권한, 제한 및 제품 액세스를 보유하고 있습니다. 기존 그룹을 검색하고 선택하세요.'
            )}
          </p>
        </div>
        <div className="form-group mb-5">
          <label className="form-label">{t('그룹 검색')}</label>
          <SelectMulti
            options={options}
            onChangeValue={(changeValue) => {
              setSelectedValue(changeValue.map((value) => Number(value)));
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-link" onClick={onHide}>
          {t('취소')}
        </button>
        <button
          type="button"
          className={classNames('btn', {
            'is-loading is-loading-sm': loading,
            'btn-light': selectedValue.length === 0,
            'btn-accent': selectedValue.length > 0,
          })}
          disabled={selectedValue.length === 0}
          onClick={handleConfirm}
        >
          {t('확인')}
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default UserManagementAddGroupModal;
