import Preloader from '@/components/common/Preloader';
import { AssetExcelType } from '@/modules/assets/types';
import { useLoading, useUploadAssetListExcel } from '@/modules/project/hook';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ExcelUploadRow from './ExcelUploadRow';
import RegisterAssetListButtonContainer from './RegisterAssetListButtonContainer';

function ExcelUpload(): ReactElement {
  const { t } = useTranslation();

  const { uploadedAssetList, setUploadedAssetList } = useUploadAssetListExcel();
  const { loading } = useLoading();

  // 새로운 열 추가
  const addAssetRow = () => {
    const newAssetObject: AssetExcelType = {
      id: uploadedAssetList.length
        ? uploadedAssetList[uploadedAssetList.length - 1].id + 1
        : 0,
      projectId: '',
      mappingId: '',
      mapId: '',
      fileKey: '',
      fileUrl: '',
      originalFileName: '',
      imgId: '',
      assetId: '',
      serialId: '',
      assetInfoId: '',
      assetInfoName: '',
      positionGeom: '',
      lat: 0,
      lng: 0,
      registId: '',
      updateId: '',
      registDate: '',
      updateDate: '',
      activeFlag: false,
      deleteFlag: false,
      field: uploadedAssetList.length ? uploadedAssetList.length : 0,
      assetTypeErrorMessage: '',
      assetClassificationErrorMessage: '',
      assetNameErrorMessage: '',
      serialIdErrorMessage: '',
      categories: [
        {
          projectId: '',
          mappingId: '',
          mapId: '',
          fileKey: '',
          fileUrl: '',
          originalFileName: '',
          imgId: '',
          seq: 0,
          categoryId: '',
          assetInfoId: '',
          categoryName: '',
          depth: 1,
          count: 0,
          parentId: '',
          registId: '',
          updateId: '',
          registDate: '',
          updateDate: '',
          activeFlag: false,
          deleteFlag: false,
          subCategories: [],
        },
        {
          projectId: '',
          mappingId: '',
          mapId: '',
          fileKey: '',
          fileUrl: '',
          originalFileName: '',
          imgId: '',
          seq: 0,
          categoryId: '',
          assetInfoId: '',
          categoryName: '',
          depth: 2,
          count: 0,
          parentId: '',
          registId: '',
          updateId: '',
          registDate: '',
          updateDate: '',
          activeFlag: false,
          deleteFlag: false,
          subCategories: [],
        },
      ],
    };
    setUploadedAssetList([...uploadedAssetList, newAssetObject]);
  };

  return (
    <div className="inner">
      <h3>{t('자산 일괄 등록')}</h3>
      <div className="d-flex justify-content-end mb-2">
        {t('총')}{' '}
        <span className="text-identity">{uploadedAssetList.length}</span>
        {t('건')}
      </div>
      <table className="table table-high table-align-top table-head-light mb-5">
        <colgroup>
          <col width="8%" />
          <col width="23%" />
          <col width="23%" />
          <col width="23%" />
          <col width="23%" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th className="text-center">{t('자산 유형')}</th>
            <th className="text-center">{t('자산 분류')}</th>
            <th className="text-center">{t('자산명')}</th>
            <th className="text-center">{t('식별값')}</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={6}>
                <Preloader />
              </td>
            </tr>
          ) : (
            <>
              {uploadedAssetList.map((uploadedAsset) => (
                <ExcelUploadRow
                  key={uploadedAsset.id}
                  uploadedAsset={uploadedAsset}
                />
              ))}
              <tr>
                <td>
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <a className="text-identity" onClick={addAssetRow}>
                      <span className="material-icons">add_circle</span>
                    </a>
                  </div>
                </td>
                <td colSpan={5}></td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <RegisterAssetListButtonContainer />
    </div>
  );
}

export default ExcelUpload;
