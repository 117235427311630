import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from '@/components/common/FormGroup';
import FormLabel from '@/components/common/FormLabel';
import { useUser } from '@/modules/user/hook';

type HomeAccountProfileDetailProps = {
  onClickEdit: () => void;
};

function HomeAccountProfileDetail({
  onClickEdit,
}: HomeAccountProfileDetailProps) {
  const { t } = useTranslation();
  const {
    user: { userId, userName, companyName, department },
  } = useUser();

  return (
    <>
      <div className="mb-4">
        <h4 className="mb-4">{t('기본정보')}</h4>
        <FormGroup>
          <FormLabel textKey={'이메일'} />
          <input
            type="text"
            className="form-line"
            value={userId}
            disabled={true}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel textKey={'이름'} />
          <input
            type="text"
            className="form-line"
            value={userName}
            disabled={true}
          />
        </FormGroup>
      </div>
      <div className="mb-4">
        <h5 className="mb-4">{t('소속')}</h5>
        <FormGroup>
          <FormLabel textKey={'회사'} />
          <input
            type="text"
            className="form-line"
            value={companyName}
            disabled={true}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel textKey={'부서'} />
          <input
            type="text"
            className="form-line"
            value={department}
            disabled={true}
          />
        </FormGroup>
      </div>
      <div className="d-flex">
        <a
          className="btn btn-accent py-3 font-size-14pt w-25"
          onClick={onClickEdit}
        >
          {t('프로필 편집')}
        </a>
      </div>
    </>
  );
}

export default HomeAccountProfileDetail;
