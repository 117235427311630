import React, { useEffect, useState } from 'react';
import { PaneProps } from '@/modules/common';
import LeftPaneHeader from '@/components/common/LeftPaneHeader';
import {
  GEOMETRY_TARGET_TYPE_INNER,
  GEOMETRY_TARGET_TYPE_OUTER,
  GEOMETRY_TYPE_CIRCLE,
  GEOMETRY_TYPE_POLYGON,
  PANE_STATUS_LIST,
} from '@/utils/constants/common';
import FormLabel from '@/components/common/FormLabel';
import {
  Accident,
  ACCIDENT_EMERGENCY,
  ACCIDENT_FIRE,
  ACCIDENT_INFECTIOUS_DISEASE,
  ACCIDENT_LOCATION_AREA,
  ACCIDENT_LOCATION_CIRCLE,
  ACCIDENT_LOCATION_POLYGON,
  ACCIDENT_RESCUE,
  AccidentCategory,
  AccidentLocation,
} from '@/modules/accident/types';
import FormGroup from '@/components/common/FormGroup';
import { useTranslation } from 'react-i18next';
import ManageFooter from '@/components/common/ManageFooter';
import { useOpenLayersDraw } from '@/modules/openlayers/hook';
import GeometryType from 'ol/geom/GeometryType';
import { Feature } from 'ol';
import InputNumber from '@/components/common/InputNumber';
import { Circle, Polygon } from 'ol/geom';
import { getCenter } from 'ol/extent';
import { OpenLayersUtils } from '@/utils/OpenLayersUtils';
import InvalidAlert from '@/components/InvalidAlert';
import {
  fetchAccident,
  postAccident,
  RequestPostAccident,
} from '@/api/accident';
import { useHomeSelected } from '@/modules/home/hook';
import { WKT } from 'ol/format';
import AlertModal from '@/components/common/modal/AlertModal';
import { AreaInfo } from '@/modules/floor/types';
import { useAccidentData } from '@/modules/accident/hook';
import { useMonitoringApp } from '@/modules/monitoring/hook';
import { MonitoringAppEndPointUser } from '@/modules/monitoring/types';
import * as turf from '@turf/turf';
import { postMessages } from '@/api/message';
import { useInfoPane } from '@/modules/setup/hook';
import { INFO_PANE_ACCIDENT_REGISTER } from '@/modules/setup/types';

type AccidentRegisterProps = PaneProps & {
  onChangeAccident: (accident: Accident) => void;
};

function AccidentRegister({
  onChangeAccident,
  onChangeStatus,
}: AccidentRegisterProps) {
  const { t } = useTranslation();
  const { project, building, floor } = useHomeSelected();
  const {
    handleDrawGeometry,
    handleDrawCancel,
    handleDrawAreaFeature,
  } = useOpenLayersDraw();
  const { show: showInfo, handleChangeShow } = useInfoPane();
  const { getAccidentBasicInfo } = useAccidentData();
  const { appEndPointUserList } = useMonitoringApp();
  const [category, setCategory] = useState<AccidentCategory>(
    ACCIDENT_EMERGENCY
  );
  const [location, setLocation] = useState<AccidentLocation | ''>('');
  const [feature, setFeature] = useState<Feature | null>(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    return () => handleChangeShow(false);
  }, []);

  useEffect(() => {
    setFeature(null);
    handleDrawCancel();
    if (location) {
      handleChangeShow(true, INFO_PANE_ACCIDENT_REGISTER);
      if (
        location === ACCIDENT_LOCATION_CIRCLE ||
        location === ACCIDENT_LOCATION_POLYGON
      ) {
        const geometryType =
          location === ACCIDENT_LOCATION_CIRCLE
            ? GeometryType.CIRCLE
            : GeometryType.POLYGON;

        handleDrawGeometry(geometryType, handleDrawCallback);
      } else {
        handleDrawAreaFeature(handleDrawCallback);
      }
    } else {
      setShowInvalidMessage(false);
      handleChangeShow(false);
    }
  }, [location]);

  useEffect(() => {
    if (!showInfo) {
      if (!feature) {
        setLocation('');
      }
    }
  }, [showInfo]);

  useEffect(() => {
    setMessage(getAccidentBasicInfo(category).content);
  }, [category]);

  const handleDrawCallback = (drawFeature: Feature | null) => {
    if (drawFeature) {
      setFeature(drawFeature);
      handleChangeShow(false);
      setShowInvalidMessage(false);
    } else {
      setLocation('');
    }
  };

  const handleClickCancel = () => {
    onChangeStatus(PANE_STATUS_LIST);
  };

  const handleClickSubmit = async () => {
    if (loading) {
      return;
    }
    setShowInvalidMessage(false);
    let invalidMessage = '';
    if (!feature) {
      invalidMessage = '사고 위치를 선택해 주세요';
    } else if (!message) {
      invalidMessage = '사고 메세지를 입력해 주세요';
    }

    if (invalidMessage) {
      setInvalidMessage(invalidMessage);
      setShowInvalidMessage(true);
      return;
    }

    if (project && building && floor) {
      const { title } = getAccidentBasicInfo(category);
      const requestPostAccident: RequestPostAccident = {
        activeFlag: true,
        projectId: project.projectId,
        eventTitle: title,
        eventContent: message,
        targetMappingId: building.mappingId,
        targetMapId: floor.mapId,
        eventDetailCategory: category,
      };

      switch (location) {
        case ACCIDENT_LOCATION_CIRCLE:
          const circleGeometry = feature?.getGeometry() as Circle;
          const center = circleGeometry.getCenter();
          requestPostAccident.targetGeomType = GEOMETRY_TARGET_TYPE_INNER;
          requestPostAccident.geomType = GEOMETRY_TYPE_CIRCLE;
          requestPostAccident.lng = center[0];
          requestPostAccident.lat = center[1];
          requestPostAccident.radius = circleGeometry.getRadius();
          break;
        case ACCIDENT_LOCATION_POLYGON:
          const polygonGeometry = feature?.getGeometry() as Polygon;
          requestPostAccident.targetGeomType = GEOMETRY_TARGET_TYPE_INNER;
          requestPostAccident.geomType = GEOMETRY_TYPE_POLYGON;
          requestPostAccident.geom = new WKT().writeGeometry(polygonGeometry);
          break;
        case ACCIDENT_LOCATION_AREA:
          if (feature) {
            if (feature.get('area_info')) {
              const areaInfo = feature.get('area_info') as AreaInfo;
              requestPostAccident.targetGeomType = GEOMETRY_TARGET_TYPE_OUTER;
              requestPostAccident.outerKey = areaInfo.id;
            }
          }
          break;
      }

      setLoading(true);
      const accidentId = await postAccident(requestPostAccident);
      if (accidentId) {
        const targetList: MonitoringAppEndPointUser[] = [];
        const geometry = feature?.getGeometry();
        if (location === ACCIDENT_LOCATION_CIRCLE) {
          const circleGeometry = geometry as Circle;
          const center = circleGeometry.getCenter();
          const radius = circleGeometry.getRadius();
          const turfCenterPoint = turf.toWgs84(turf.point(center));
          appEndPointUserList.forEach((appEndPointUser) => {
            const point = turf.point(
              OpenLayersUtils.convertCoordinates(
                [appEndPointUser.lng, appEndPointUser.lat],
                'EPSG:3857',
                'EPSG:4326'
              )
            );
            const buffer = turf.buffer(turfCenterPoint, radius, {
              units: 'meters',
            });
            const contain = turf.booleanContains(buffer, point);
            if (contain) {
              targetList.push(appEndPointUser);
            }
          });
        } else {
          const polygonGeometry = geometry as Polygon;
          const turfPolygon = turf.polygon(polygonGeometry.getCoordinates());
          appEndPointUserList.forEach((appEndPointUser) => {
            const point = turf.point([
              appEndPointUser.lng,
              appEndPointUser.lat,
            ]);
            if (turf.inside(point, turfPolygon)) {
              targetList.push(appEndPointUser);
            }
          });
        }

        project &&
          targetList.length &&
          (await postMessages({
            projectId: project.projectId,
            parentId: accidentId,
            parentType: 'accident',
            messageTitle: title,
            messageContent: message,
            linkUrl: '',
            imgId: '',
            targetIds: targetList.map(({ accessKey }) => accessKey),
          }));

        const accident = await fetchAccident(accidentId);
        if (accident) {
          onChangeAccident(accident);
        } else {
          setShowAlertModal(true);
        }
      } else {
        setShowAlertModal(true);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <LeftPaneHeader titleKey={'사고 등록'} onClickBack={handleClickCancel} />
      <div className="container-fluid">
        <FormGroup>
          <FormLabel textKey={'사고 종료'} essential={true} />
          <select
            name="category"
            className="form-line"
            value={category}
            onChange={(e) => setCategory(e.target.value as AccidentCategory)}
          >
            <option value={ACCIDENT_EMERGENCY}>{t('긴급 상황')}</option>
            <option value={ACCIDENT_FIRE}>{t('화재')}</option>
            <option value={ACCIDENT_RESCUE}>{t('구조 요청')}</option>
            <option value={ACCIDENT_INFECTIOUS_DISEASE}>{t('감염병')}</option>
          </select>
        </FormGroup>
        <FormGroup>
          <FormLabel textKey={'사고 위치'} essential={true} />
          <select
            name="category"
            className="form-line"
            value={location}
            onChange={(e) => {
              setFeature(null);
              setLocation(e.target.value as AccidentLocation);
            }}
          >
            <option value={''}>{t('사고 위치 선택')}</option>
            <option value={ACCIDENT_LOCATION_CIRCLE}>{t('반경(원)')}</option>
            <option value={ACCIDENT_LOCATION_POLYGON}>
              {t('반경(사각형)')}
            </option>
            <option value={ACCIDENT_LOCATION_AREA}>{t('위치(POINT)')}</option>
          </select>
        </FormGroup>
        {feature && (
          <FormGroup marginBottomClassName={'mb-4'}>
            <FormLabel
              textKey={location === ACCIDENT_LOCATION_CIRCLE ? '반경' : '좌표'}
              essential={true}
            />
            <div className="d-flex align-items-center">
              {location === ACCIDENT_LOCATION_CIRCLE ? (
                <FeatureRadius feature={feature} />
              ) : (
                <FeatureLocation feature={feature} />
              )}
            </div>
          </FormGroup>
        )}
        <FormGroup marginBottomClassName={'mb-4'}>
          <FormLabel textKey={'사고 메세지'} essential={true} />
          <textarea
            rows={5}
            placeholder={t(
              '사고 유형을 선택 하면 설정된 사고 메세지가 표시됩니다'
            )}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </FormGroup>
        {showInvalidMessage && <InvalidAlert messageKey={t(invalidMessage)} />}
      </div>
      <ManageFooter
        type={'send'}
        loading={loading}
        onClickCancel={handleClickCancel}
        onClickConfirm={handleClickSubmit}
      />
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {t('서버와 통신 중 오류가 발생했습니다')}
      </AlertModal>
    </>
  );
}

type FeatureInfo = {
  feature: Feature;
};

function FeatureRadius({ feature }: FeatureInfo) {
  const [radius, setRadius] = useState(0);

  useEffect(() => {
    if (feature) {
      const circleGeometry = feature.getGeometry() as Circle;
      setRadius(circleGeometry.getRadius());
    }
  }, [feature]);

  const handleChangeRadius = (value: number) => {
    setRadius(value);
    const circleGeometry = feature.getGeometry() as Circle;
    circleGeometry.setRadius(value);
  };

  return (
    <>
      <InputNumber
        className={'form-line'}
        min={1}
        value={radius}
        onChange={handleChangeRadius}
      />
      <span className="px-2">meters</span>
    </>
  );
}

function FeatureLocation({ feature }: FeatureInfo) {
  const [location, setLocation] = useState<number[]>([]);

  useEffect(() => {
    if (feature) {
      const extent = feature.getGeometry()?.getExtent();
      extent &&
        setLocation(
          OpenLayersUtils.convertCoordinates(
            getCenter(extent),
            'EPSG:3857',
            'EPSG:4326',
            true
          )
        );
    }
  }, [feature]);

  return (
    <input
      type="text"
      className="form-line"
      autoComplete={'off'}
      readOnly={true}
      value={location.toString()}
    />
  );
}

export default AccidentRegister;
