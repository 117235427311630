import React, { useEffect, useState } from 'react';
import UserManagementList from '@/components/UserManagementList';
import UserManagementDetail from '@/components/UserManagementDetail';
import UserManagementAccessModal, {
  UserManagementAccessData,
} from '@/components/UserManagementAccessModal';
import UserManagementAddGroupModal, {
  UserManagementAddGroupData,
} from '@/components/UserManagementAddGroupModal';
import { Group } from '@/modules/group/types';
import {
  fetchInviteUserAddableGroups,
  postInviteUserAddGroups,
} from '@/api/group';
import { useHomeData } from '@/modules/home/hook';

export type UserManagementView = 'list' | 'detail';

function UserManagement() {
  const [showView, setShowView] = useState<UserManagementView>('list');
  const [inviteUserEmail, setInviteUserEmail] = useState<string>('');
  const [showUserAddGroupModal, setShowUserAddGroupModal] = useState(false);
  const [addableGroupList, setAddableGroupList] = useState<Group[]>([]);
  const [
    userAddGroupData,
    setUserAddGroupData,
  ] = useState<UserManagementAddGroupData | null>(null);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [accessData, setAccessData] = useState<UserManagementAccessData | null>(
    null
  );
  const [reloadFlag, setReloadFlag] = useState(false);
  const { groupSettingUser, handleDataClear } = useHomeData();

  useEffect(() => {
    if (groupSettingUser.userId) {
      setInviteUserEmail(groupSettingUser.userId);
      handleDataClear();
      setShowView('detail');
    }
  }, [groupSettingUser.directFlag]);

  const handleClickInviteUserList = (email: string) => {
    setInviteUserEmail(email);
    setShowView('detail');
  };

  const handleShowAccessModal = (data: UserManagementAccessData) => {
    setAccessData(data);
    setShowAccessModal(true);
  };

  const handleFetchAddableGroupList = async () => {
    if (userAddGroupData && userAddGroupData.userId) {
      const result = await fetchInviteUserAddableGroups(
        userAddGroupData.userId
      );
      setAddableGroupList(result);
    }
  };

  useEffect(() => {
    handleFetchAddableGroupList();
  }, [userAddGroupData]);

  const handleClickAddGroup = (data: UserManagementAddGroupData) => {
    if (data.userId) {
      setUserAddGroupData(data);
      setShowUserAddGroupModal(true);
    }
  };

  const handleUserAddGroup = async (groupSeqList: number[]) => {
    if (userAddGroupData) {
      const { userId, invitationSeq } = userAddGroupData;
      const result = await postInviteUserAddGroups({
        userId,
        invitationSeq,
        groupSeqs: groupSeqList,
      });

      if (result) {
        handleReload();
        setShowUserAddGroupModal(false);
      }
    }
  };

  const handleReload = () => {
    setReloadFlag(!reloadFlag);
  };

  return (
    <>
      <div className="contents-section col-md-16">
        <div className="row align-items-start">
          {showView === 'list' && (
            <UserManagementList
              reloadFlag={reloadFlag}
              onClickItem={handleClickInviteUserList}
              onClickAccessControl={handleShowAccessModal}
              onClickAddGroup={handleClickAddGroup}
            />
          )}
          {showView === 'detail' && (
            <UserManagementDetail
              userId={inviteUserEmail}
              reloadFlag={reloadFlag}
              onClickBack={setShowView}
              onClickAccessControl={handleShowAccessModal}
              onClickAddGroup={handleClickAddGroup}
            />
          )}
        </div>
      </div>
      <UserManagementAddGroupModal
        show={showUserAddGroupModal}
        groupList={addableGroupList}
        onSubmit={handleUserAddGroup}
        onHide={() => setShowUserAddGroupModal(false)}
      />
      <UserManagementAccessModal
        show={showAccessModal}
        data={accessData}
        onHide={() => setShowAccessModal(false)}
        onReload={handleReload}
      />
    </>
  );
}

export default UserManagement;
