import { PAGING_MAX_DISPLAY, PAGING_SIZE } from '@/utils/constants/common';
import moment from 'moment';
import { Config } from '@/config';

export class CommonUtils {
  /**
   * @description 페이징 계산
   * @param curPage
   * @param totalCount
   */
  static calcPages(curPage: number, totalCount: number) {
    let startPage: number, endPage: number;
    const totalPage = Math.ceil(totalCount / PAGING_SIZE);
    if (totalPage <= PAGING_MAX_DISPLAY) {
      startPage = 1;
      endPage = totalPage;
    } else {
      const halfwayPoint = Math.ceil(PAGING_MAX_DISPLAY / 2);
      const pastHalfwayPoint = Math.floor(PAGING_MAX_DISPLAY / 2) + 1;
      const beforeHalfwayPoint = halfwayPoint - 1;
      if (curPage <= pastHalfwayPoint) {
        startPage = 1;
        endPage = PAGING_MAX_DISPLAY;
      } else if (curPage + beforeHalfwayPoint >= totalPage) {
        startPage = totalPage - (PAGING_MAX_DISPLAY - 1);
        endPage = totalPage;
      } else {
        startPage = curPage - halfwayPoint;
        endPage = curPage + beforeHalfwayPoint;
      }
    }

    const pages = [...Array<number>(endPage + 1 - startPage).keys()].map(
      (i) => startPage + i
    );

    return {
      pages,
      totalPage,
    };
  }

  static calcSelectPages(
    curPage: number,
    totalCount: number,
    pageSize = PAGING_SIZE
  ) {
    const startPage = 1,
      totalPage = Math.ceil(totalCount / pageSize);

    const pages = [...Array<number>(totalPage + 1 - startPage).keys()].map(
      (i) => startPage + i
    );

    return {
      pages,
      totalPage,
    };
  }

  /**
   * @description 경과 시간 계산
   * @param inputDate
   */
  static calcElapsedTime(inputDate: string, standardDate?: string) {
    const startDate = moment(inputDate);
    let standardMoment: moment.Moment = moment();
    if (standardDate) {
      standardMoment = moment(standardDate);
    }
    const calcMilliseconds = moment
      .duration(standardMoment.diff(startDate))
      .asMilliseconds();

    if (calcMilliseconds < 0) {
      return `00:00:00`;
    }

    let seconds = calcMilliseconds / 1000;
    let minutes = seconds / 60;
    seconds %= 60;
    let hours = minutes / 60;
    minutes %= 60;

    hours = Math.floor(hours);
    minutes = Math.floor(minutes);
    seconds = Math.floor(seconds);

    return `${CommonUtils.padZeroTime(hours)}:${CommonUtils.padZeroTime(
      minutes
    )}:${CommonUtils.padZeroTime(seconds)}`;
  }

  /**
   * @description 숫자 영 메워넣기
   * @param input
   */
  static padZeroTime(input: number) {
    let output = input.toString();
    if (input < 10) {
      output = `0${input}`;
    }

    return output;
  }

  static isEmptyObject(input: Record<string, any>) {
    return !Object.keys(input).length;
  }

  static isValidationObject(input: Record<string, any>) {
    let result = true;
    Object.values(input).some((x) => {
      if (!x) {
        result = false;
        return true;
      }
    });
    return result;
  }

  static convertDateFormat(input: string, format: string) {
    if (!input) {
      return '';
    }

    return moment(input).format(format);
  }

  static convertFloorFormat(input: number) {
    if (input === null) {
      return '';
    }

    if (input >= 0) {
      return `${input}F`;
    } else {
      return `B${Math.abs(input)}`;
    }
  }

  static getRandomIntInclusive(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static numberWithCommas(value: number) {
    try {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } catch (e) {
      return '0';
    }
  }

  static getKeyByValue(object: any, value: any) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  // 센서 정보 파라미터로 전달 및 Editor쪽 창 열기
  static openSensorUpdateWindow(
    userId: string,
    projectId: string,
    metaId: string,
    mapId: string,
    sensorSerial: string
  ): void {
    const lang = localStorage.getItem('language') || 'ko';
    window.open(
      `${Config.map_server.indoor_edit_uri}/drawMap.jsp?userid=${userId}&projectid=${projectId}&metaid=${metaId}&mapid=${mapId}&lang=${lang}&sensor=${sensorSerial}`
    );
  }

  // 파일 업로드 시 진행 상태 표기를 위한 axios config
  static uploadProgressConfig = (
    setProgressStatus: (progressStatus: number) => void
  ) => (progressEvent: any) => {
    const { loaded, total } = progressEvent;
    setProgressStatus(Math.floor(((loaded / 1000) * 100) / (total / 1000)));
  };

  static humanFileSize(bytes: number, siUnits = true, digits = 1): string {
    const thresh = siUnits ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = siUnits
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let unitIndex = -1;
    const r = 10 ** digits;

    do {
      bytes /= thresh;
      ++unitIndex;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      unitIndex < units.length - 1
    );

    if (unitIndex === 0) {
      digits = 0;
    }

    return bytes.toFixed(digits) + ' ' + units[unitIndex];
  }

  static getDistanceFromLatLonInMeter(
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number
  ) {
    function deg2rad(deg: number) {
      return deg * (Math.PI / 180);
    }
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return Number((d * 1000).toFixed(2));
  }

  static convertDeeplinkText(text: string) {
    return encodeURIComponent(text).replaceAll('%20', '+');
  }
}
