import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMonitoringApp } from '@/modules/monitoring/hook';
import { MonitoringAppEndPointUser } from '@/modules/monitoring/types';
import MonitoringAppEndPointUserDetailModal from '@/components/monitoring/MonitoringAppEndPointUserDetailModal';
import TableEmptyRow from '@/components/common/TableEmptyRow';

function MonitoringAppEndPointUserList() {
  const { t } = useTranslation();
  const { appEndPointUserList } = useMonitoringApp();
  const [
    appEndPointUser,
    setAppEndPointUser,
  ] = useState<MonitoringAppEndPointUser | null>(null);

  return (
    <>
      <div className="table-bumper">
        <table className="table table-high table-line-none table-sticky m-0">
          <thead>
            <tr>
              <th className="text-center">
                <a className="sort">{t('그룹')}</a>
              </th>
              <th className="text-center">
                <a className="sort">{t('사용자명')}</a>
              </th>
            </tr>
          </thead>
          <tbody>
            {!appEndPointUserList.length && (
              <TableEmptyRow
                colSpan={2}
                textKey={'등록된 데이터가 없습니다.'}
              />
            )}
            {appEndPointUserList.map((appEndPointUser) => (
              <AppEndPointUserItem
                key={appEndPointUser.accessKey}
                appEndPointUser={appEndPointUser}
                onClickItem={() => setAppEndPointUser(appEndPointUser)}
              />
            ))}
          </tbody>
        </table>
      </div>
      {appEndPointUser && (
        <MonitoringAppEndPointUserDetailModal
          show={true}
          onHide={() => setAppEndPointUser(null)}
          appEndPointUser={appEndPointUser}
        />
      )}
    </>
  );
}

type AppEndPointUserItemProps = {
  appEndPointUser: MonitoringAppEndPointUser;
  onClickItem: () => void;
};

function AppEndPointUserItem({
  appEndPointUser: { siteUserName },
  onClickItem,
}: AppEndPointUserItemProps) {
  return (
    <tr onClick={onClickItem}>
      <td>-</td>
      <td>{siteUserName || '-'}</td>
    </tr>
  );
}

export default MonitoringAppEndPointUserList;
