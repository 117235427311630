import {
  Action,
  AssetState,
  ASSET_SET_PAGED_ASSET_LIST,
  ASSET_SET_SEARCH_OPTION,
} from '../assets/types';

export function assetReducer(state: AssetState, action: Action): AssetState {
  switch (action.type) {
    case ASSET_SET_SEARCH_OPTION:
      return {
        ...state,
        assetSearchOption: action.assetSearchOption,
      };
    case ASSET_SET_PAGED_ASSET_LIST:
      return {
        ...state,
        pagedAssetList: action.pagedAssetList,
      };
  }
}
