import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonUtils } from '@/utils';
import { fetchGroups, postGroup } from '@/api/group';
import FormLabel from '@/components/common/FormLabel';
import InvalidAlert from '@/components/InvalidAlert';
import ListSearch from '@/components/ListSearch';
import { FUNCTION_DELETE, FUNCTION_EDIT } from '@/utils/constants/common';
import ListDropdown from '@/components/ListDropdown';
import classNames from 'classnames';
import { Group } from '@/modules/group/types';
import GroupManagementEditModal from '@/components/GroupManagementEditModal';
import Pagination from '@/components/common/Pagination';

type GroupManagementListProps = {
  reloadFlag: boolean;
  onClickItem: (groupSeq: number) => void;
  onClickDelete: (group: Group) => void;
};

function GroupManagementList({
  reloadFlag,
  onClickItem,
  onClickDelete,
}: GroupManagementListProps) {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const [groupList, setGroupList] = useState<Group[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState<{
    groupName: string;
    groupUserMaxCount: number;
    groupDescription: string;
  }>({
    groupName: '',
    groupUserMaxCount: 1,
    groupDescription: '',
  });
  const [searchKeyword, setSearchKeyword] = useState('');
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [showGroupEditModal, setShowGroupEditModal] = useState(false);
  const [groupEditData, setGroupEditData] = useState<Group | null>(null);

  useEffect(() => {
    setPage(1);
  }, [searchKeyword]);

  useEffect(() => {
    handleFetchGroupList();
  }, [page, searchKeyword, reloadFlag]);

  useEffect(() => {
    if (CommonUtils.isValidationObject(inputs)) {
      setShowInvalidMessage(false);
    }
  }, [inputs]);

  const handleClickCreateGroup = async () => {
    if (!CommonUtils.isValidationObject(inputs)) {
      setShowInvalidMessage(true);
      return;
    }

    setLoading(true);

    const result = await postGroup({
      groupName: inputs.groupName,
      groupDesc: inputs.groupDescription,
      maxCount: inputs.groupUserMaxCount,
    });

    setLoading(false);

    if (result) {
      setInputs({
        groupName: '',
        groupUserMaxCount: 1,
        groupDescription: '',
      });

      handleFetchGroupList();
    }
  };

  const handleFetchGroupList = async () => {
    setLoad(false);
    const result = await fetchGroups(page, searchKeyword);
    setGroupList(result.content);
    setTotalCount(result.totalElements);
    setLoad(true);
  };

  const handleSubmitSearch = (text: string) => {
    setSearchKeyword(text);
  };

  const handleShowGroupEditModal = (group: Group) => {
    setGroupEditData(group);
    setShowGroupEditModal(true);
  };

  return (
    <>
      <div className="col-md-4">
        <div className="cell">
          <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-5 sort-wrap">
            <div className="flex title-row">
              <h3 className="mb-0">{t('그룹 만들기')}</h3>
              <small className="text-muted text-headings text-uppercase">
                {t(
                  '그룹에 추가된 사용자에게는 동일한 권한, 제한 사항 및 액세스가 제공됩니다.'
                )}
              </small>
            </div>
          </div>
          <div className="form-group mb-4">
            <FormLabel
              textKey={'그룹 이름'}
              essential={true}
              htmlFor={'groupName'}
            />
            <input
              id="groupName"
              type="text"
              className="form-line pr-6"
              placeholder={t('그룹 이름을 입력해 주세요')}
              value={inputs.groupName}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  [e.target.id]: e.target.value,
                })
              }
              autoComplete={'off'}
            />
          </div>
          <div className="form-group mb-5">
            <FormLabel
              textKey={'그룹 구성원'}
              essential={true}
              htmlFor={'groupDescription'}
            />
            <input
              id="groupUserMaxCount"
              type="number"
              min={1}
              className="form-line"
              value={inputs.groupUserMaxCount}
              onKeyDown={(e) => {
                const key = e.key;
                if (key === '.') {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                let value = e.target.value;
                if (value.match(/\./)) {
                  value = value.replace(/./g, '');
                }

                let number = 1;
                if (value) {
                  number = Number(value);
                  if (!number) {
                    number = 1;
                  } else {
                    if (number < 1) {
                      number = 1;
                    }
                  }
                }

                setInputs({
                  ...inputs,
                  [e.target.id]: number,
                });
              }}
            />
          </div>
          <div className="form-group mb-5">
            <FormLabel
              textKey={'그룹 설명'}
              essential={true}
              htmlFor={'groupDescription'}
            />
            <input
              id="groupDescription"
              type="text"
              className="form-line"
              placeholder={t('설명을 입력해 주세요')}
              value={inputs.groupDescription}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  [e.target.id]: e.target.value,
                })
              }
              autoComplete={'off'}
            />
          </div>
          {showInvalidMessage && <InvalidAlert alertContainerPadding={false} />}
          <div className="form-group text-center mb-32pt">
            <button
              className={classNames('btn btn-block btn-lg btn-accent', {
                'is-loading': loading,
              })}
              type="button"
              onClick={handleClickCreateGroup}
            >
              {t('그룹 만들기')}
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="cell">
          <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt sort-wrap">
            <div className="flex title-row">
              <h3 className="mb-0">{t('그룹')}</h3>
              <small className="text-muted text-headings text-uppercase">
                {t(
                  '그룹을 사용하여 조직의 여러 사용자에게 동일한 사용 권한, 제한 사항 및 액세스 권한을 부여할 수 있습니다.'
                )}
              </small>
            </div>
            <ListSearch onSubmit={handleSubmitSearch} />
          </div>
          <table className="table mb-4 table-nowrap">
            <colgroup>
              <col width="*" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="5%" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <a>{t('그룹')}</a>
                </th>
                <th>
                  <a>{t('구성원')}</a>
                </th>
                <th>
                  <a>{t('액세스')}</a>
                </th>
                <th>
                  <a>{t('작업')}</a>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="list">
              {!load && <></>}
              {load && groupList.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <div className="text-center py-4">
                      <span className="material-icons-outlined text-50 font-size-32pt">
                        info
                      </span>
                      <p className="m-0 text-50">
                        {t('생성된 그룹이 없습니다.')}
                      </p>
                    </div>
                  </td>
                </tr>
              )}
              {groupList.map((group) => (
                <GroupItem
                  key={group.groupSeq}
                  {...group}
                  onClick={() => onClickItem(group.groupSeq)}
                  onClickEdit={() => handleShowGroupEditModal(group)}
                  onClickDelete={() => onClickDelete(group)}
                />
              ))}
            </tbody>
          </table>
          {groupList.length > 0 && (
            <Pagination
              page={page}
              totalCount={totalCount}
              onChangePage={setPage}
            />
          )}
        </div>
      </div>
      <GroupManagementEditModal
        group={groupEditData}
        show={showGroupEditModal}
        onHide={() => {
          setGroupEditData(null);
          setShowGroupEditModal(false);
        }}
        onReload={() => handleFetchGroupList()}
      />
    </>
  );
}

type GroupItemProps = Group & {
  onClick: () => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
};

function GroupItem({
  groupName,
  groupDesc,
  maxCount,
  userCnt,
  projectCnt,
  onClick,
  onClickEdit,
  onClickDelete,
}: GroupItemProps) {
  const { t } = useTranslation();

  const handleSelectOptions = (eventKey: string | null) => {
    switch (eventKey) {
      case FUNCTION_EDIT:
        onClickEdit();
        break;
      case FUNCTION_DELETE:
        onClickDelete();
        break;
    }
  };

  return (
    <tr>
      <td>
        <a className="media-body" onClick={onClick}>
          <strong className="text-dark">{groupName}</strong>
          <div className="text-muted small">{groupDesc}</div>
        </a>
      </td>
      <td>{`${userCnt}/${maxCount}`}</td>
      <td>
        <strong className="text-accent">{projectCnt}</strong>
        {t('개의 프로젝트')}
      </td>
      <td>
        <a className="text-underline text-primary" onClick={onClick}>
          {t('세부사항 보기')}
        </a>
      </td>
      <td className="text-right">
        <ListDropdown onSelect={handleSelectOptions} />
      </td>
    </tr>
  );
}

export default GroupManagementList;
