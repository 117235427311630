import axios from 'axios';
import { Config } from '@/config';
import { ApiResult, ListResult } from '@/modules/common';
import { StatusCodes } from 'http-status-codes';
import {
  Event,
  EventCondition,
  EventDetailCategory,
  EventMainCategory,
  EventStatusCount,
} from '@/modules/event/types';
import { PAGING_SIZE } from '@/utils/constants/common';

export async function fetchEvents(params: {
  page: number;
  projectId: string;
  mappingId: string;
  mapId: string;
  searchKeyword: string;
  showType2: string;
}) {
  let listResult: ListResult<Event[]> = {
    content: [],
    totalElements: 0,
  };

  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/events/detection`,
      {
        params: {
          pageSize: PAGING_SIZE,
          ...params,
        },
      }
    );
    const result: ApiResult<ListResult<Event[]>> = response.data;
    if (result.status === StatusCodes.OK && result.data) {
      listResult = {
        content: result.data.content,
        totalElements: result.data.totalElements,
      };
    }
  } catch (e) {}

  return listResult;
}

export async function fetchEventCategoryCount(params: {
  projectId: string;
  mappingId: string;
  mapId: string;
  searchKeyword: string;
  eventCategory: EventMainCategory;
}) {
  let eventCategoryCountList: EventStatusCount[] = [];

  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/events/category-sum`,
      {
        params,
      }
    );

    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      eventCategoryCountList = result.data as EventStatusCount[];
    }
  } catch (e) {}

  return eventCategoryCountList;
}

export async function postEvent(data: {
  projectId: string;
  targetMappingId: string;
  targetMapId: string;
  targetZoneId: string;
  eventTitle: string;
  targetCount: number;
  eventDetailCategory: EventDetailCategory;
  eventCondition: EventCondition;
}) {
  try {
    const response = await axios.post(
      `${Config.platform_api.uri}/events/detection`,
      data
    );
    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      return Boolean(result.data);
    }
  } catch (e) {}

  return false;
}

export async function putEventActive(eventId: string, active: boolean) {
  try {
    let flag = 'off';
    if (active) {
      flag = 'on';
    }

    const response = await axios.put(
      `${Config.platform_api.uri}/events/detection/${flag}/${eventId}`
    );

    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      return Boolean(result.data);
    }
  } catch (e) {}

  return false;
}

// 알림 삭제
export const deleteEvent = async (eventId: string): Promise<ApiResult> => {
  const response = await axios.delete(
    `${Config.platform_api.uri}/events/detection/${eventId}`
  );

  const result: ApiResult = response.data;
  if (response.status === StatusCodes.OK) {
    return result.data;
  }

  throw new Error('Server Error');
};
