import React from 'react';
import { useLeftPane, useRightPane } from '@/modules/setup/hook';

type SidebarButtonProps = {
  showLeftSidebarButton: boolean;
  showRightSidebarButton: boolean;
};

function SidebarButton({
  showLeftSidebarButton,
  showRightSidebarButton,
}: SidebarButtonProps) {
  const {
    show: leftPaneShow,
    handleChangeShow: handleChangeLeftPaneShow,
  } = useLeftPane();
  const {
    show: rightPaneShow,
    handleChangeShow: handleChangeRightPaneShow,
  } = useRightPane();

  return (
    <>
      {showLeftSidebarButton && (
        <a
          className="side-btn btn-col-left toggle-sidebar-left"
          onClick={() => handleChangeLeftPaneShow(!leftPaneShow)}
        >
          <span className="material-icons-outlined">
            {leftPaneShow ? 'chevron_left' : 'chevron_right'}
          </span>
        </a>
      )}
      {showRightSidebarButton && (
        <a
          className="side-btn btn-col-right toggle-sidebar-right"
          onClick={() => handleChangeRightPaneShow(!rightPaneShow)}
        >
          <span className="material-icons-outlined">
            {rightPaneShow ? 'chevron_right' : 'chevron_left'}
          </span>
        </a>
      )}
    </>
  );
}

export default SidebarButton;
