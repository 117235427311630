import React, { ReactElement, useEffect, useState } from 'react';
import { ModalProps } from '@/modules/common';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import AlertModal from '@/components/common/modal/AlertModal';
import { useTranslation } from 'react-i18next';
import { Campaign } from '@/modules/campaign/types';
import MaterialIcon from '@/components/common/MaterialIcon';
import moment from 'moment';
import {
  deleteCampaign,
  fetchCampaign,
  putCampaignActive,
} from '@/api/campaign';
import { fetchImageDownload } from '@/api/common';
import { CommonUtils } from '@/utils';

type CampaignDetailModalProps = ModalProps & {
  campaign: Campaign;
  onClickEdit: () => void;
  onDelete: () => void;
};

function CampaignDetailModal({
  campaign: {
    eventId,
    eventTitle,
    eventContent,
    linkUrl,
    startDate,
    endDate,
    imgId,
    activeFlag,
  },
  onClickEdit,
  onHide,
  onReload,
  onDelete,
}: CampaignDetailModalProps): ReactElement {
  const { t } = useTranslation();
  const [campaignDetail, setCampaignDetail] = useState<Campaign | null>(null);
  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    eventId && imgId && handleFetchCampaign();
  }, [eventId, imgId]);

  const handleFetchCampaign = async () => {
    const data = await fetchCampaign(eventId);
    data ? setCampaignDetail(data) : setShowAlertModal(true);
  };

  const handleChangeActive = async () => {
    const result = await putCampaignActive(eventId, !activeFlag);
    if (result) {
      onHide();
      onReload?.call(null);
    } else {
      setShowAlertModal(true);
    }
  };

  const handleFileDownload = async () => {
    imgId &&
      campaignDetail?.originalFileName &&
      fetchImageDownload(imgId, campaignDetail.originalFileName);
  };

  const handleDeleteCampaign = async () => {
    (await deleteCampaign(eventId))
      ? (onHide(), onReload && onReload(), onDelete())
      : setShowAlertModal(true);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        dialogClassName="modal-dialog-scrollable"
      >
        <ModalHeader>
          <div className="d-flex">
            <ModalTitle as={'h4'}>{t('캠페인 상세')}</ModalTitle>
          </div>
          <div className="d-flex ml-auto">
            <a onClick={handleDeleteCampaign} className="btn-ico-sm btn-del">
              <MaterialIcon
                name={'delete_forever'}
                className={'icon'}
                outlined={true}
              />
              <span className="text">
                <span>{t('삭제')}</span>
              </span>
            </a>
            {activeFlag && (
              <a
                className="btn-ico-sm ml-2"
                onClick={() => {
                  onHide();
                  onClickEdit();
                }}
              >
                <MaterialIcon
                  name={'open_in_browser'}
                  className={'icon'}
                  outlined={true}
                />
                <span className="text">
                  <span>{t('수정')}</span>
                </span>
              </a>
            )}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="mb-4">
            <small className="d-flex text-64 mb-2">{t('캠페인명')}</small>
            <p className="m-0">{eventTitle}</p>
          </div>
          <div className="mb-4">
            <small className="d-flex text-64 mb-2">{t('기간')}</small>
            <p className="m-0">{`${moment(startDate).format(
              'YYYY.MM.DD'
            )} ~ ${moment(endDate).format('YYYY.MM.DD')}`}</p>
          </div>
          {/*
            <div className="mb-4">
              <small className="d-flex text-64 mb-2">{t('위치')}</small>
              <p className="m-0">{areaName}</p>
            </div>
            */}
          {eventContent && (
            <div className="mb-4">
              <small className="d-flex text-64 mb-2">{t('캠페인 내용')}</small>
              <p>{eventContent}</p>
            </div>
          )}
          {linkUrl && (
            <div className="mb-4">
              <small className="d-flex text-64 mb-2">{t('캠페인 URL')}</small>
              <p className={'text-break'}>{linkUrl}</p>
            </div>
          )}
          {imgId && (
            <div className="mb-4">
              <small className="d-flex text-64 mb-2">{t('파일')}</small>
              <div className="d-flex align-items-center mb-2">
                <p className="m-0">{campaignDetail?.originalFileName}</p>
                <div className="d-flex align-items-center ml-auto">
                  <p className="m-0 text-64">
                    {campaignDetail?.fileVolume &&
                      CommonUtils.humanFileSize(campaignDetail.fileVolume)}
                  </p>
                  <a
                    className="d-flex ml-2 circle-pin"
                    onClick={handleFileDownload}
                  >
                    <MaterialIcon name="file_download" outlined />
                  </a>
                </div>
              </div>
              <div className="place-thumb">
                <img src={campaignDetail?.fileUrl} />
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-light" onClick={onHide}>
            {t('닫기')}
          </button>
          <button
            type="button"
            className="btn btn-darken"
            onClick={handleChangeActive}
          >
            {activeFlag ? t('캠페인 종료') : t('캠페인 시작')}
          </button>
        </ModalFooter>
      </Modal>
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {t('서버와 통신 중 오류가 발생했습니다')}
      </AlertModal>
    </>
  );
}

export default CampaignDetailModal;
