import axios from 'axios';
import { Config } from '@/config';
import { StatusCodes } from 'http-status-codes';
import {
  AssetCategoryType,
  AssetExcelUploadType,
  AssetSearchOptionType,
  AssetType,
  PagedAssetListType,
} from '@/modules/assets/types';
import { ApiResult } from '@/modules/common';

// 자산 목록 조회 (검색조건 X)
export const fetchAssetList = async (
  projectId: string,
  mappingId: string,
  mapId: string
): Promise<AssetType[]> => {
  const response = await axios.get(`${Config.platform_api.uri}/assets`, {
    params: { projectId, mappingId, mapId },
  });
  const result: ApiResult = response.data;

  if (response.status === StatusCodes.OK) {
    return result.data as AssetType[];
  }

  throw new Error('Server Error');
};

// 자산 목록 조회 (검색조건 O)
export const fetchPagedAssetList = async ({
  projectId,
  mappingId,
  mapId,
  categoryIdList,
  searchKeyword,
  page,
}: AssetSearchOptionType): Promise<PagedAssetListType> => {
  const response = await axios.get(`${Config.platform_api.uri}/assets`, {
    params: {
      projectId,
      mappingId,
      mapId,
      categoryIds: categoryIdList.toString(),
      searchKeyword,
      usePaging: true,
      page,
      pageSize: 8,
    },
  });
  const result: ApiResult = response.data;

  if (response.status === StatusCodes.OK) {
    return result.data as PagedAssetListType;
  }

  throw new Error('Server Error');
};

// 자산 카운트 조회
export const fetchAssetCountList = async (
  projectId: string,
  mappingId: string,
  mapId: string,
  searchKeyword: string
): Promise<AssetCategoryType[]> => {
  const response = await axios.get(`${Config.platform_api.uri}/assets/counts`, {
    params: {
      projectId,
      mappingId,
      mapId,
      searchKeyword,
    },
  });
  const result: ApiResult = response.data;

  if (response.status === StatusCodes.OK) {
    return result.data as AssetCategoryType[];
  }

  throw new Error('Server Error');
};

// 자산 상세 조회
export const fetchAssetDetail = async (
  assetInfoId: string
): Promise<AssetType> => {
  const response = await axios.get(
    `${Config.platform_api.uri}/assets/${assetInfoId}`
  );
  const result: ApiResult = response.data;

  if (response.status === StatusCodes.OK) {
    return result.data as AssetType;
  }

  throw new Error('Server Error');
};

// 자산 유형, 자산 분류 조회
export const fetchCategoryList = async (): Promise<AssetCategoryType[]> => {
  const response = await axios.get(
    `${Config.platform_api.uri}/assets/categories`
  );
  const result: ApiResult = response.data;

  if (response.status === StatusCodes.OK) {
    return result.data as AssetCategoryType[];
  }

  throw new Error('Server Error');
};

// 자산 단건 등록
export const postAsset = async (
  projectId: string,
  mappingId: string,
  mapId: string,
  assetInfoName: string,
  serialId: string,
  typeId: string,
  classificationId: string
): Promise<boolean> => {
  try {
    const response = await axios.post(`${Config.platform_api.uri}/assets`, {
      projectId,
      mappingId,
      mapId,
      assetInfoName,
      serialId,
      categories: [
        {
          categoryId: typeId,
        },
        {
          categoryId: classificationId,
        },
      ],
    });
    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      return true;
    } else {
      return false;
    }
  } catch (_) {
    return false;
  }
};

// 자산 일괄 등록
export const postAssetList = async (
  uploadedAssetList: AssetExcelUploadType[]
): Promise<ApiResult> => {
  try {
    const response = await axios.post(
      `${Config.platform_api.uri}/assets/bulk`,
      uploadedAssetList
    );
    return response.data as ApiResult;
  } catch (error: any) {
    return error.response as ApiResult;
  }
};
