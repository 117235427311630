import MaterialIcon from '@/components/common/MaterialIcon';
import {
  SensorType,
  SENSOR_BLE_TYPE,
  SENSOR_CONNECTION_DISCONNECTED_STATUS,
  SENSOR_ENVIRONMENT_TYPE,
  SENSOR_TEMPERATURE_TYPE,
} from '@/modules/sensor/types';
import React, { ReactElement } from 'react';

type SensorLeftPaneCoverProps = {
  sensor: SensorType;
  selectedSensor: string;
  setSelectedSensor: (selectedSensor: string) => void;
};

function SensorLeftPaneCover({
  sensor,
  selectedSensor,
  setSelectedSensor,
}: SensorLeftPaneCoverProps): ReactElement {
  const onClickSensor = () => {
    selectedSensor === sensor.sensor_serial
      ? setSelectedSensor('')
      : setSelectedSensor(sensor.sensor_serial);
  };

  // 배터리 상태 별 표기 색상 변경
  const getBatteryStatusString = () => {
    switch (sensor.battery_status) {
      case 1:
        return 'level-text-item01';

      case 2: {
        return 'level-text-item02';
      }
      case 3: {
        return 'level-text-item03';
      }
      case 4: {
        return 'level-text-item04';
      }
      case 5: {
        return 'level-text-item05';
      }
    }
  };

  return (
    <div className="sensor-cell" onClick={onClickSensor}>
      <div className="sensor-circle" onClick={onClickSensor}>
        {sensor.sensor_type === SENSOR_BLE_TYPE && (
          <MaterialIcon name="sensors" outlined />
        )}
        {sensor.sensor_type === SENSOR_TEMPERATURE_TYPE && (
          <MaterialIcon name="thermostat" outlined />
        )}
        {sensor.sensor_type === SENSOR_ENVIRONMENT_TYPE && (
          <MaterialIcon name="air" outlined />
        )}
      </div>
      <p className="desc">
        <strong>
          {sensor.util_name}
          {sensor.connection_condition !==
            SENSOR_CONNECTION_DISCONNECTED_STATUS && (
            <span className={getBatteryStatusString()}> {sensor.battery}%</span>
          )}
        </strong>
        {sensor.connection_condition !==
          SENSOR_CONNECTION_DISCONNECTED_STATUS &&
          sensor.sensor_type === SENSOR_TEMPERATURE_TYPE &&
          ` / ${Number(sensor.sensing1).toFixed(1)}\u00b0, ${Number(
            sensor.sensing2
          ).toFixed(0)}%`}
        {sensor.connection_condition !==
          SENSOR_CONNECTION_DISCONNECTED_STATUS &&
          sensor.sensor_type === SENSOR_ENVIRONMENT_TYPE &&
          ` / ${sensor.sensing1}`}
      </p>
    </div>
  );
}

export default SensorLeftPaneCover;
