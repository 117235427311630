import { AsyncState } from '@/modules/common';

export const COOKIE_KEY_REMEMBER_EMAIL = 'CONTROL_PLATFORM_ID';
export const COOKIE_MAX_AGE_REMEMBER_EMAIL = 60 * 60 * 24 * 30;
export const SESSION_STORAGE_KEY_USER_INFO = 'CONTROL_PLATFORM_USER_INFO';
export const LOCAL_STORAGE_KEY_PROJECT_ID = 'CONTROL_PLATFORM_PROJECT_ID';

export const COOKIE_KEY_VIEWER_COUNTRY = 'CONTROL_PLATFORM_VIEWER_COUNTRY';
export const COOKIE_KEY_WHETHER_TO_SIGN_IN = 'CONTROL_PLATFORM_SIGN_IN';
export const COOKIE_MAX_AGE_WHETHER_TO_SIGN_IN = 60 * 10;

export const MONITORING_APP_INTERVAL = 3000;
export const MONITORING_LIDAR_INTERVAL = 1000;
export const SENSOR_INTERVAL = 30000;

export const PAGING_MAX_DISPLAY = 5;
export const PAGING_SIZE = 8;

export const PANE_STATUS_LIST = 1 as const;
export const PANE_STATUS_DETAIL = 2 as const;
export const PANE_STATUS_REGISTER = 3 as const;
export const PANE_STATUS_EDIT = 4 as const;
export const PANE_STATUS_PROJECT_CONNECT_TO_SPACE = 400;

export type PaneStatus =
  | typeof PANE_STATUS_LIST
  | typeof PANE_STATUS_DETAIL
  | typeof PANE_STATUS_REGISTER
  | typeof PANE_STATUS_EDIT
  | typeof PANE_STATUS_PROJECT_CONNECT_TO_SPACE;

export const FUNCTION_EDIT = 'EDIT';
export const FUNCTION_COPY = 'COPY';
export const FUNCTION_DELETE = 'DELETE';
export const FUNCTION_DETAIL = 'DETAIL';
export const FUNCTION_ADD_USER_TO_GROUP = 'ADD_USER_TO_GROUP';
export const FUNCTION_CONTROL_ACCESS = 'CONTROL_ACCESS';

export const GEOMETRY_TARGET_TYPE_INNER = 'INNER';
export const GEOMETRY_TARGET_TYPE_OUTER = 'OUTER';
export const GEOMETRY_TYPE_POLYGON = 'POLYGON';
export const GEOMETRY_TYPE_CIRCLE = 'CIRCLE';

export const MAP_TILE_KEY = 'cZ7VKzCSU2EFWJEaYgkn';
// export const MAP_TILE_KEY = '0xhNYsuSH6EDmzdFwzdS';

export const GOOGLE_API_KEY = 'AIzaSyBCQzaaxpiFX1YJzMD8K7pPwKFxDItCH_k';

export const GEO_IMAGE_DEFAULT_OPACITY = 0.7;

export const OPEN_LAYERS_Z_INDEX_DRAWING = 999;

export const OPEN_LAYERS_RESOLUTIONS = [
  2048,
  1024,
  512,
  256,
  128,
  64,
  32,
  16,
  8,
  4,
  2,
  1,
  0.5,
  0.25,
  0.125,
  0.0625,
  0.03625,
  0.018125,
  0.0090625,
  0.00453125,
  0.002265625,
  0.0011328125,
  0.00056640625,
];

export const ASYNC_DEFAULT_STATE: AsyncState = {
  loading: false,
  data: null,
  error: null,
};

export const ASYNC_LOADING_STATE: AsyncState = {
  loading: true,
  data: null,
  error: null,
};

export const ASYNC_SUCCESS_STATE = (data: any): AsyncState => ({
  loading: false,
  data,
  error: null,
});

export const ASYNC_ERROR_STATE = (error: any): AsyncState => ({
  loading: false,
  data: null,
  error: error,
});

// FCM 메시지 관련 옵션
export const FIREBASE_APP_OPTIONS = {
  apiKey: 'AIzaSyBVXG4UfDmB_YPDu8qAnjgyYJuhvgM2VPg',
  authDomain: 'inplemap-4b394.firebaseapp.com',
  databaseURL: 'https://project-id.firebaseio.com',
  projectId: 'inplemap-4b394',
  storageBucket: 'inplemap-4b394.appspot.com',
  messagingSenderId: '246253828554',
  appId: '1:246253828554:web:f79a8d91a9ef042df93733',
  measurementId: 'G-SLGEE28FCJ',
};

export const FIREBASE_APP_NAME = 'WATA_CONTROL_PLATFORM_APP';
