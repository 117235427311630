import Preloader from '@/components/common/Preloader';
import TableEmptyRow from '@/components/common/TableEmptyRow';
import { usePagedSensorList } from '@/modules/sensor/hook';
import {
  SensorType,
  SENSOR_ALL_TYPE,
  SENSOR_CONNECTION_DISCONNECTED_STATUS,
} from '@/modules/sensor/types';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SensorLeftPaneDetailModal from './SensorLeftPaneDetailModal';
import SensorLeftPaneTableRow from './SensorLeftPaneTableRow';

type SensorLeftPaneTableProps = {
  loading: boolean;
};

function SensorLeftPaneTable({
  loading,
}: SensorLeftPaneTableProps): ReactElement {
  const { t } = useTranslation();
  const {
    pagedSensorList: { sensorList },
  } = usePagedSensorList();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedSensor, setSelectedSensor] = useState<SensorType>({
    sensor_type: SENSOR_ALL_TYPE,
    sensor_serial: '',
    util_name: '',
    battery: '',
    battery_status: 0,
    sensing1: '',
    sensing2: '',
    timediff: 0,
    connection_condition: SENSOR_CONNECTION_DISCONNECTED_STATUS,
    area_name: '',
    geomstr: '',
  });

  return (
    <>
      <div className="table-bumper">
        <table className="table table-high table-line-none table-sticky mb-0">
          <thead>
            <tr>
              <th className="text-center" style={{ width: '30%' }}>
                {t('센서유형')}
              </th>
              <th className="text-center" style={{ width: '40%' }}>
                {t('센서명')}
              </th>
              <th className="text-center" style={{ width: '20%' }}>
                {t('상태')}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={3}>
                  <Preloader />
                </td>
              </tr>
            ) : (
              <>
                {sensorList.length ? (
                  sensorList.map((sensor) => (
                    <SensorLeftPaneTableRow
                      key={sensor.sensor_serial}
                      sensor={sensor}
                      setShowModal={setShowModal}
                      setSelectedSensor={setSelectedSensor}
                    />
                  ))
                ) : (
                  <TableEmptyRow
                    colSpan={3}
                    textKey={t('등록된 데이터가 없습니다.')}
                  />
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <SensorLeftPaneDetailModal
        show={showModal}
        setShow={setShowModal}
        sensor={selectedSensor}
      />
    </>
  );
}

export default SensorLeftPaneTable;
