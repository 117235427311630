import React from 'react';
import { useTranslation } from 'react-i18next';

type InputCustomRadioProps = {
  radioKey: string;
  radioName: string;
  textKey: string;
  checked?: boolean;
  onChangeChecked?: (checked: boolean) => void;
};

function InputCustomRadio({
  radioKey,
  radioName,
  textKey,
  checked,
  onChangeChecked,
}: InputCustomRadioProps) {
  const { t } = useTranslation();

  return (
    <div className="custom-control custom-radio flex">
      <input
        id={radioKey}
        name={radioName}
        type="radio"
        className="custom-control-input"
        checked={checked}
        onChange={(e) => onChangeChecked?.call(null, e.target.checked)}
      />
      <label htmlFor={radioKey} className="custom-control-label">
        {t(textKey)}
      </label>
    </div>
  );
}

export default InputCustomRadio;
