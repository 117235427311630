import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HomeAccountProfileDetail from '@/components/home/HomeAccountProfileDetail';
import HomeAccountProfileEdit from '@/components/home/HomeAccountProfileEdit';

function HomeAccountProfile() {
  const { t } = useTranslation();
  const [viewType, setViewType] = useState<'detail' | 'edit'>('detail');
  return (
    <div className="col-md-8 d-flex flex-column p-5 border-left-1">
      <h3 className="mb-5">{t('내 프로필')}</h3>
      {viewType === 'detail' ? (
        <HomeAccountProfileDetail onClickEdit={() => setViewType('edit')} />
      ) : (
        <HomeAccountProfileEdit
          onChangeViewType={() => setViewType('detail')}
        />
      )}
    </div>
  );
}

export default HomeAccountProfile;
