import React, { useEffect, useState } from 'react';
import SignUpTerms from '@/components/SignUpTerms';
import SignUpAuthentication from '@/components/SignUpAuthentication';
import SignUpInfo from '@/components/SignUpInfo';
import SignUpComplete from '@/components/SignUpComplete';
import SignUpStep from '@/components/SignUpStep';
import { useParams } from 'react-router';
import { fetchInviteAuthNumber, fetchValidInviteUser } from '@/api/group';
import { useHistory } from 'react-router-dom';

type Params = {
  key: string;
};

function InviteForm() {
  const history = useHistory();
  const { key } = useParams<Params>();
  const [step, setStep] = useState(1);
  const [signUpEmail, setSignUpEmail] = useState('');

  const handleCheckInviteNumber = async () => {
    const email = await fetchInviteAuthNumber(key);
    if (email) {
      setSignUpEmail(email);
    } else {
      history.replace('/signin');
    }
  };

  useEffect(() => {
    if (key) {
      handleCheckInviteNumber();
    }
  }, [key]);

  if (!signUpEmail) {
    return <></>;
  }

  return (
    <>
      {step === 1 && (
        <SignUpTerms
          onClickAgree={() => {
            setStep(3);
          }}
        />
      )}
      {step === 3 && (
        <SignUpInfo signUpEmail={signUpEmail} onChangeStep={setStep} />
      )}
      {step === 4 && <SignUpComplete />}
      <SignUpStep step={step} />
    </>
  );
}

export default InviteForm;
