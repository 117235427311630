import React from 'react';
import MaterialIcon from '@/components/common/MaterialIcon';
import { AccidentCategory } from '@/modules/accident/types';
import { CommonUtils } from '@/utils';
import { useTranslation } from 'react-i18next';
import { useAccidentData } from '@/modules/accident/hook';

type AccidentIconProps = {
  accidentCategory: AccidentCategory;
  className?: string;
};

function AccidentIcon({ accidentCategory, className }: AccidentIconProps) {
  const { t } = useTranslation();
  const { getAccidentBasicInfo } = useAccidentData();
  const { icon, title } = getAccidentBasicInfo(accidentCategory);

  return (
    <>
      <MaterialIcon name={icon} className={className} /> {t(title)}
    </>
  );
}

export default AccidentIcon;
