import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionCollapse, AccordionToggle } from 'react-bootstrap';
import classNames from 'classnames';
import {
  StatLidarForkliftOperationStatus,
  StatLidarVisitCount,
} from '@/modules/statistics/types';
import {
  fetchLidarForkliftOperationStatus,
  fetchLidarVisitCount,
} from '@/api/statistics';
import {
  useHomeBuilding,
  useHomeFloor,
  useHomeProject,
} from '@/modules/home/hook';
import moment from 'moment';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { MonitoringRightChildPaneProps } from '@/components/monitoring/MonitoringRightPane';
import MonitoringLidarRealtimeStatus from '@/components/monitoring/MonitoringLidarRealtimeStatus';

type MonitoringLidarRightPaneProps = MonitoringRightChildPaneProps;

function MonitoringLidarRightPane({
  objectFeatureFilterList,
}: MonitoringLidarRightPaneProps) {
  return (
    <>
      <MonitoringLidarRealtimeStatus
        objectFeatureFilterList={objectFeatureFilterList}
      />
      <Accordion className="js-accordion m-0">
        {/*<OperationStatus />*/}
        {/*<VisitCount />*/}
      </Accordion>
    </>
  );
}

type SearchCondition = 'today' | 'yesterday' | 'before yesterday';

function OperationStatus() {
  const { t } = useTranslation();
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const [isOpen, setOpen] = useState(true);
  const [operationStatusList, setOperationStatusList] = useState<
    StatLidarForkliftOperationStatus[]
  >([]);
  const [searchCondition, setSearchCondition] = useState<SearchCondition>(
    'today'
  );

  useEffect(() => {
    handleFetchOperationStatus();
  }, [searchCondition, floor]);

  const handleFetchOperationStatus = async () => {
    const [searchStartDates, searchEndDates] = calcSearchDates();
    project &&
      building &&
      floor &&
      setOperationStatusList(
        await fetchLidarForkliftOperationStatus({
          projectId: project.projectId,
          mappingId: building.mappingId,
          mapId: floor.mapId,
          searchStartDates,
          searchEndDates,
        })
      );
  };

  const calcSearchDates = () => {
    const today = moment();
    let calcStartDate = today;
    let calcEndDate = today;
    if (searchCondition === 'yesterday') {
      calcStartDate = calcEndDate = today.add(-1, 'days');
    } else if (searchCondition === 'before yesterday') {
      calcStartDate = calcEndDate = today.add(-2, 'days');
    }

    return [
      calcStartDate.format('YYYY-MM-DD'),
      calcEndDate.format('YYYY-MM-DD'),
    ];
  };

  return (
    <>
      <div
        className={classNames('accordion__item', {
          open: isOpen,
        })}
      >
        <div className="d-flex align-items-center pl-4 py-2">
          <h4 className="m-0">{t('지게차 운행 현황')}</h4>
          <div className="d-flex align-items-center ml-auto">
            <div className="swipe-wrap">
              <ul className="swipe-list">
                <li>
                  <a
                    className={classNames({
                      active: searchCondition === 'today',
                    })}
                    onClick={() => setSearchCondition('today')}
                  >
                    {t('오늘')}
                  </a>
                </li>
                <li>
                  <a
                    className={classNames({
                      active: searchCondition === 'yesterday',
                    })}
                    onClick={() => setSearchCondition('yesterday')}
                  >
                    {t('어제')}
                  </a>
                </li>
                <li>
                  <a
                    className={classNames({
                      active: searchCondition === 'before yesterday',
                    })}
                    onClick={() => setSearchCondition('before yesterday')}
                  >
                    {t('그제')}
                  </a>
                </li>
              </ul>
            </div>
            <AccordionToggle
              eventKey={'OPERATION'}
              as={'a'}
              className="accordion__toggle"
              onClick={() => setOpen(!isOpen)}
            >
              <span className="accordion__toggle-icon material-icons text-64">
                keyboard_arrow_down
              </span>
            </AccordionToggle>
          </div>
        </div>
        <AccordionCollapse
          in={isOpen}
          eventKey={'OPERATION'}
          className="accordion__menu"
        >
          <div className="accordion__menu-link">
            <div className="d-flex align-items-center justify-content-center w-100">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'line',
                    width: 320,
                  },
                  title: null,
                  credits: {
                    enabled: false,
                  },
                  plotOptions: {
                    series: {
                      animation: false,
                    },
                    line: {
                      marker: {
                        enabled: false,
                      },
                    },
                  },
                  xAxis: {
                    categories: operationStatusList.map(({ hour }) => hour),
                  },
                  yAxis: {
                    minRange: 1,
                    min: 0,
                    title: null,
                  },
                  tooltip: {
                    pointFormat: '{series.name}: <b>{point.y}</b>',
                  },
                  legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'top',
                    floating: false,
                  },
                  series: [
                    {
                      name: t('운행횟수'),
                      data: operationStatusList.map(
                        ({ operationCount }) => operationCount
                      ),
                      color: '#2E80DF',
                    },
                    {
                      name: t('과속'),
                      data: operationStatusList.map(
                        ({ overSpeedCount }) => overSpeedCount
                      ),
                      color: '#D6E597',
                    },
                  ],
                }}
              />
            </div>
          </div>
        </AccordionCollapse>
      </div>
    </>
  );
}

function VisitCount() {
  const { t } = useTranslation();
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const [isOpen, setOpen] = useState(true);
  const [visitCountList, setVisitCountList] = useState<StatLidarVisitCount[]>(
    []
  );
  const [searchCondition, setSearchCondition] = useState<SearchCondition>(
    'today'
  );

  useEffect(() => {
    handleFetchVisitCount();
  }, [searchCondition, floor]);

  const handleFetchVisitCount = async () => {
    const [searchStartDates, searchEndDates] = calcSearchDates();
    project &&
      building &&
      floor &&
      setVisitCountList(
        await fetchLidarVisitCount({
          projectId: project.projectId,
          mappingId: building.mappingId,
          mapId: floor.mapId,
          searchStartDates,
          searchEndDates,
        })
      );
  };

  const calcSearchDates = () => {
    const today = moment();
    let calcStartDate = today;
    let calcEndDate = today;
    if (searchCondition === 'yesterday') {
      calcStartDate = calcEndDate = today.add(-1, 'days');
    } else if (searchCondition === 'before yesterday') {
      calcStartDate = calcEndDate = today.add(-2, 'days');
    }

    return [
      calcStartDate.format('YYYY-MM-DD'),
      calcEndDate.format('YYYY-MM-DD'),
    ];
  };

  return (
    <>
      <div
        className={classNames('accordion__item', {
          open: isOpen,
        })}
      >
        <div className="d-flex align-items-center pl-4 py-2">
          <h4 className="m-0">{t('방문 현황')}</h4>
          <div className="d-flex align-items-center ml-auto">
            <div className="swipe-wrap">
              <ul className="swipe-list">
                <li>
                  <a
                    className={classNames({
                      active: searchCondition === 'today',
                    })}
                    onClick={() => setSearchCondition('today')}
                  >
                    {t('오늘')}
                  </a>
                </li>
                <li>
                  <a
                    className={classNames({
                      active: searchCondition === 'yesterday',
                    })}
                    onClick={() => setSearchCondition('yesterday')}
                  >
                    {t('어제')}
                  </a>
                </li>
                <li>
                  <a
                    className={classNames({
                      active: searchCondition === 'before yesterday',
                    })}
                    onClick={() => setSearchCondition('before yesterday')}
                  >
                    {t('그제')}
                  </a>
                </li>
              </ul>
            </div>
            <AccordionToggle
              eventKey={'VISIT'}
              as={'a'}
              className="accordion__toggle"
              onClick={() => setOpen(!isOpen)}
            >
              <span className="accordion__toggle-icon material-icons text-64">
                keyboard_arrow_down
              </span>
            </AccordionToggle>
          </div>
        </div>
        <AccordionCollapse
          in={isOpen}
          eventKey={'VISIT'}
          className="accordion__menu"
        >
          <div className="accordion__menu-link">
            <div className="d-flex align-items-center justify-content-center w-100">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'line',
                    width: 320,
                  },
                  title: null,
                  credits: {
                    enabled: false,
                  },
                  plotOptions: {
                    series: {
                      animation: false,
                    },
                    line: {
                      marker: {
                        enabled: false,
                      },
                    },
                  },
                  xAxis: {
                    categories: visitCountList.map(({ hour }) => hour),
                  },
                  yAxis: {
                    minRange: 1,
                    min: 0,
                    title: null,
                  },
                  tooltip: {
                    pointFormat: '{series.name}: <b>{point.y}</b>',
                  },
                  legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'top',
                    floating: false,
                  },
                  series: [
                    {
                      name: t('방문자 수'),
                      data: visitCountList.map(
                        ({ visitorCount }) => visitorCount
                      ),
                      color: '#2E80DF',
                    },
                  ],
                }}
              />
            </div>
          </div>
        </AccordionCollapse>
      </div>
    </>
  );
}

export default React.memo(MonitoringLidarRightPane);
