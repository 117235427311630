import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { useTranslation } from 'react-i18next';
import { ModalProps } from '@/modules/common';
import FormGroup from '@/components/common/FormGroup';
import { useUser } from '@/modules/user/hook';
import InvalidAlert from '@/components/InvalidAlert';
import { fetchMatchingPassword, fetchUser } from '@/api/user';

type HomeAccountPasswordConfirmModalProps = ModalProps & {
  onMatchedPassword: (password: string) => void;
};

function HomeAccountPasswordConfirmModal({
  onHide,
  onMatchedPassword,
}: HomeAccountPasswordConfirmModalProps) {
  const { t } = useTranslation();
  const {
    user: { userId },
  } = useUser();
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [password, setPassword] = useState('');

  const handleClickConfirm = async () => {
    setShowInvalidMessage(false);
    if (!password) {
      setInvalidMessage('비밀번호를 입력해 주세요');
      setShowInvalidMessage(true);
      return;
    }

    const data = await fetchMatchingPassword({
      userId,
      password,
    });

    if (data) {
      onMatchedPassword(password);
      onHide();
    } else {
      setInvalidMessage('비밀번호가 일치하지 않습니다');
      setShowInvalidMessage(true);
    }
  };

  return (
    <Modal
      show={true}
      onHide={() => {
        //
      }}
      centered={true}
    >
      <ModalHeader>
        <ModalTitle as={'h4'}>{t('비밀번호 확인')}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <label className="form-label">{t('현재 비밀번호')}</label>
          <input
            type="password"
            className="form-line"
            placeholder={t('현재 비밀번호를 입력해주세요')}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleClickConfirm();
              }
            }}
          />
        </FormGroup>
      </ModalBody>
      {showInvalidMessage && <InvalidAlert messageKey={invalidMessage} />}
      <ModalFooter>
        <button type="button" className="btn btn-light" onClick={onHide}>
          {t('취소')}
        </button>
        <button
          type="button"
          className="btn btn-accent"
          onClick={handleClickConfirm}
        >
          {t('확인')}
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default HomeAccountPasswordConfirmModal;
