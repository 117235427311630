import React, { useEffect, useState } from 'react';
import {
  PANE_STATUS_DETAIL,
  PANE_STATUS_LIST,
  PANE_STATUS_REGISTER,
  PaneStatus,
} from '@/utils/constants/common';
import AccidentList from '@/components/accident/AccidentList';
import AccidentRegister from '@/components/accident/AccidentRegister';
import { Accident } from '@/modules/accident/types';
import AccidentDetail from '@/components/accident/AccidentDetail';
import { useHomeFloor } from '@/modules/home/hook';

function AccidentLeftPane() {
  const { floor } = useHomeFloor();
  const [paneStatus, setPaneStatus] = useState<PaneStatus>(PANE_STATUS_LIST);
  const [accident, setAccident] = useState<Accident | null>(null);

  useEffect(() => {
    setPaneStatus(PANE_STATUS_LIST);
  }, [floor]);

  useEffect(() => {
    accident
      ? setPaneStatus(PANE_STATUS_DETAIL)
      : setPaneStatus(PANE_STATUS_LIST);
  }, [accident]);

  return (
    <>
      {paneStatus === PANE_STATUS_LIST && (
        <AccidentList
          onClickItem={setAccident}
          onChangeStatus={setPaneStatus}
        />
      )}
      {paneStatus === PANE_STATUS_DETAIL && accident && (
        <AccidentDetail
          accident={accident}
          onChangeStatus={() => setAccident(null)}
        />
      )}
      {paneStatus === PANE_STATUS_REGISTER && (
        <AccidentRegister
          onChangeAccident={(accident) => {
            setAccident(accident);
          }}
          onChangeStatus={setPaneStatus}
        />
      )}
    </>
  );
}

export default AccidentLeftPane;
