import React, { useEffect } from 'react';
import MainContainer from '@/components/common/MainContainer';
import LayoutContainer from '@/components/common/LayoutContainer';
import TopNavbar from '@/components/common/TopNavbar';
import StatusNavbar from '@/components/common/StatusNavbar';
import { useTranslation } from 'react-i18next';
import LayoutColumn from '@/components/common/LayoutColumn';
import LeftSidebar from '@/components/common/LeftSidebar';
import { PANE_STATUS_REGISTER } from '@/utils/constants/common';
import {
  useOpenLayers,
  useProjectPane,
  useProjectRegister,
} from '@/modules/project/hook';
import ProjectRegisterOnboarding from '@/components/project/ProjectRegisterOnboarding';
import { useSpaceRegister } from '@/modules/space/hook';
import ProjectMapContainer from '@/components/project/ProjectMapContainer';
import BottomToolbar from '@/components/common/BottomToolbar';
import JapanAddressPane from '@/components/address/JapanAddressPane';
import JapanAddressSearch from '@/components/address/JapanAddressSearch';
import ProjectFloorPlanOpacity from '@/components/project/ProjectFloorPlanOpacity';
import LeftMenu from '@/components/common/LeftMenu';
import { useActiveMenu, useFloatPane } from '@/modules/setup/hook';
import FloorControl from '@/components/common/FloorControl';
import ExcelUpload from '@/components/project/AssetRegisterTab/AssetList/ExcelUpload';
import { MENU_IDX_PROJECT_MANAGEMENT } from '@/modules/setup/types';
import _ from 'lodash';
import LayoutColumnGroup from '@/components/common/LayoutColumnGroup';

function Project() {
  const { paneStatus } = useProjectPane();
  const { showPane } = useSpaceRegister();
  const { menuIdx, handleMenuActive } = useActiveMenu();
  const { show: showFloatPane, handleChangeShow } = useFloatPane();
  const {
    map,
    geofencingLayer,
    geoImage: { adjustment },
  } = useOpenLayers();

  useEffect(() => {
    handleMenuActive(MENU_IDX_PROJECT_MANAGEMENT);
    handleChangeShow(false);
  }, []);

  return (
    <MainContainer>
      <LayoutContainer position={'top'}>
        <TopNavbar>
          {paneStatus === PANE_STATUS_REGISTER && <TopProjectName />}
          <StatusNavbar />
        </TopNavbar>
      </LayoutContainer>
      <LayoutContainer position={'cover'}>
        {typeof adjustment !== 'undefined' && adjustment === true && (
          <ProjectFloorPlanOpacity />
        )}
        <LayoutColumn show={true} align={'left'}>
          <LeftSidebar
            leftPaneMenuIdx={menuIdx}
            customPane={
              <JapanAddressPane show={showPane}>
                <JapanAddressSearch
                  map={map}
                  geofencingLayer={geofencingLayer}
                  show={showPane}
                />
              </JapanAddressPane>
            }
          >
            <LeftMenu
              titleKey={'프로젝트'}
              iconName={'grid_view'}
              active={true}
            />
          </LeftSidebar>
        </LayoutColumn>
        <LayoutColumnGroup className={'contents-column'}>
          {showFloatPane ? (
            <LayoutColumn align={'mid'} show={showFloatPane}>
              <LayoutColumn align={'float'}>
                <ExcelUpload />
              </LayoutColumn>
            </LayoutColumn>
          ) : (
            <ContentContainer />
          )}
        </LayoutColumnGroup>
      </LayoutContainer>
    </MainContainer>
  );
}

function TopProjectName() {
  const { t } = useTranslation();
  const {
    projectInfo,
    registerStep,
    handleSetProjectInfo,
  } = useProjectRegister();

  const handleChangeProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSetProjectInfo({
      ...projectInfo,
      projectName: e.target.value,
    });
  };

  return (
    <>
      {registerStep === 1 && (
        <div className="d-flex ml-4">
          <input
            type="text"
            className="form-line line-none"
            placeholder={t('프로젝트명을 입력해 주세요.')}
            value={projectInfo.projectName}
            onChange={handleChangeProjectName}
          />
        </div>
      )}
      {registerStep !== 1 && (
        <h4 className="project-title">{projectInfo.projectName}</h4>
      )}
    </>
  );
}

function ContentContainer() {
  const { paneStatus } = useProjectPane();
  const { map } = useOpenLayers();
  const {
    projectInfo: { produceEndFlag },
    floorInfo: { list, selectedFloorId },
    handleChangeSelectedFloorId,
  } = useProjectRegister();

  return (
    <>
      {paneStatus === PANE_STATUS_REGISTER && !produceEndFlag && (
        <ProjectRegisterOnboarding />
      )}
      <FloorControl
        floorList={_.cloneDeep(list)
          .reverse()
          .map(({ map_id, map_floor }) => {
            return {
              floorId: map_id,
              floorValue: map_floor,
            };
          })}
        selectedFloorId={selectedFloorId}
        onClickFloor={(floorId) => handleChangeSelectedFloorId(floorId)}
      />
      <ProjectMapContainer />
      {map && <BottomToolbar map={map} />}
    </>
  );
}

export default Project;
