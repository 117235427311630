import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidUtils } from '@/utils';
import { fetchUserId, postAuthNumberMail } from '@/api/user';
import classNames from 'classnames';
import { getLanguageCode } from '@/locales/i18n';

type SignUpEmailAuthenticationProps = {
  onChangeSendMail: () => void;
  onChangeSignUpEmail: (email: string) => void;
};

function SignUpEmailAuthentication({
  onChangeSendMail,
  onChangeSignUpEmail,
}: SignUpEmailAuthenticationProps) {
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [duplicateCheck, setDuplicateCheck] = useState<{
    checkedEmail: boolean;
    emailPossible: boolean;
  }>({
    checkedEmail: false,
    emailPossible: false,
  });

  useEffect(() => {
    setDuplicateCheck({
      checkedEmail: false,
      emailPossible: false,
    });
  }, [email]);

  const handleClickDuplicateCheck = async () => {
    if (!email) {
      setFeedbackMessage(t('이메일을 입력해 주세요'));
    } else {
      const isEmail =
        email.length >= 5 &&
        email.length <= 45 &&
        ValidUtils.validateEmail(email);
      if (!isEmail) {
        setFeedbackMessage(
          t('5~45자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다.')
        );
      } else {
        setFeedbackMessage('');
        const duplicateUserId = await fetchUserId(email);
        const emailPossible = duplicateUserId.result;

        if (duplicateUserId.detail) {
          setFeedbackMessage(t('초대받은 사용자입니다. 메일을 확인해 주세요.'));
        } else if (!emailPossible) {
          setFeedbackMessage(t('사용 가능 한 이메일입니다'));
        } else {
          setFeedbackMessage(t('사용 중인 이메일입니다.'));
        }

        setDuplicateCheck({
          checkedEmail: true,
          emailPossible: !emailPossible,
        });
      }
    }
  };

  const handleClickSend = async () => {
    if (!loading) {
      if (!email) {
        setFeedbackMessage(t('이메일을 입력해 주세요'));
        return;
      }

      const isEmail =
        email.length >= 5 &&
        email.length <= 45 &&
        ValidUtils.validateEmail(email);
      if (!isEmail) {
        setFeedbackMessage(
          t('5~45자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다.')
        );
        return;
      }

      if (!duplicateCheck.checkedEmail) {
        setFeedbackMessage(t('중복 확인해 주세요.'));
        return;
      }

      if (!duplicateCheck.emailPossible) {
        setFeedbackMessage(t('다른 이메일로 시도해 주세요'));
        return;
      }

      setFeedbackMessage('');
      setLoading(true);
      const result = await postAuthNumberMail(email, getLanguageCode());

      if (result) {
        onChangeSendMail();
        onChangeSignUpEmail(email);
      }
    }
  };

  return (
    <>
      <div className="title-group mb-5">
        <h3 className="m-0">{t('이메일 인증')}</h3>
      </div>
      <div className="form-group over-txt">
        <div className="over-txt">
          <label className="form-label text-50 essential" htmlFor="email">
            {t('이메일 인증')}
          </label>
          <input
            id="email"
            type="email"
            className="form-line"
            style={{
              paddingRight: '5rem',
            }}
            placeholder={t('이메일을 입력해 주세요.')}
            autoComplete={'off'}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <a
            className="btn-abs btn-outline-secondary btn-sm btn-rounded"
            onClick={handleClickDuplicateCheck}
          >
            {t('중복 확인')}
          </a>
        </div>
      </div>
      {feedbackMessage && (
        <div className="invalid-feedback">{feedbackMessage}</div>
      )}
      <div className="form-group text-center mt-5 mb-32pt">
        <button
          className={classNames('btn btn-block btn-lg btn-accent', {
            'is-loading': loading,
          })}
          type="button"
          onClick={handleClickSend}
        >
          {t('인증번호 전송')}
        </button>
      </div>
    </>
  );
}

export default SignUpEmailAuthentication;
