import React, { useState } from 'react';
import {
  PANE_STATUS_EDIT,
  PANE_STATUS_LIST,
  PANE_STATUS_PROJECT_CONNECT_TO_SPACE,
  PANE_STATUS_REGISTER,
  PaneStatus,
} from '@/utils/constants/common';
import ProjectBuildingList from '@/components/project/ProjectBuildingList';
import ProjectBuildingRegister from '@/components/project/ProjectBuildingRegister';
import ProjectBuildingEdit from '@/components/project/ProjectBuildingEdit';
import { ConnectedSpaceInfo } from '@/modules/space/types';
import ProjectBuildingConnect from '@/components/project/ProjectBuildingConnect';

type ProjectBuildingProps = {
  loadSpaceInfoList: boolean;
  spaceInfoList: ConnectedSpaceInfo[];
  onReloadSpaceInfoList: () => void;
};

function ProjectBuilding({
  loadSpaceInfoList,
  spaceInfoList,
  onReloadSpaceInfoList,
}: ProjectBuildingProps) {
  const [paneStatus, setPaneStatus] = useState<PaneStatus>(PANE_STATUS_LIST);
  const [spaceInfo, setSpaceInfo] = useState<ConnectedSpaceInfo | null>(null);

  return (
    <>
      {paneStatus === PANE_STATUS_LIST && (
        <ProjectBuildingList
          loadSpaceInfoList={loadSpaceInfoList}
          spaceInfoList={spaceInfoList}
          onReloadSpaceInfoList={onReloadSpaceInfoList}
          onChangeSpaceInfo={setSpaceInfo}
          onChangeStatus={setPaneStatus}
        />
      )}
      {paneStatus === PANE_STATUS_PROJECT_CONNECT_TO_SPACE && (
        <ProjectBuildingConnect
          spaceInfoList={spaceInfoList}
          onReloadSpaceInfoList={onReloadSpaceInfoList}
          onChangeStatus={setPaneStatus}
        />
      )}
      {paneStatus === PANE_STATUS_REGISTER && (
        <ProjectBuildingRegister
          onReloadSpaceInfoList={onReloadSpaceInfoList}
          onChangeStatus={setPaneStatus}
        />
      )}
      {paneStatus === PANE_STATUS_EDIT && (
        <ProjectBuildingEdit
          spaceInfo={spaceInfo}
          onReloadSpaceInfoList={onReloadSpaceInfoList}
          onChangeStatus={setPaneStatus}
        />
      )}
    </>
  );
}

export default ProjectBuilding;
