import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { InviteUser } from '@/modules/group/types';
import {
  fetchInviteUsers,
  fetchValidInviteUser,
  postInviteUsers,
  postRetryInviteUser,
} from '@/api/group';
import ListSearch from '@/components/ListSearch';
import { useDropdown } from '@/modules/common';
import {
  FUNCTION_ADD_USER_TO_GROUP,
  FUNCTION_CONTROL_ACCESS,
  FUNCTION_DETAIL,
} from '@/utils/constants/common';
import { ValidUtils } from '@/utils';
import moment from 'moment';
import { Alert, Dropdown } from 'react-bootstrap';
import MaterialIcon from '@/components/common/MaterialIcon';
import _ from 'lodash';
import FormLabel from '@/components/common/FormLabel';
import { useUser } from '@/modules/user/hook';
import ProfileAvatar from '@/components/common/ProfileAvatar';
import { UserManagementAccessData } from '@/components/UserManagementAccessModal';
import { UserManagementAddGroupData } from '@/components/UserManagementAddGroupModal';
import classNames from 'classnames';
import AlertModal from '@/components/common/modal/AlertModal';
import { getLanguageCode } from '@/locales/i18n';
import Pagination from '@/components/common/Pagination';

type UserManagementListProps = {
  reloadFlag: boolean;
  onClickItem: (email: string) => void;
  onClickAccessControl: (data: UserManagementAccessData) => void;
  onClickAddGroup: (data: UserManagementAddGroupData) => void;
};

function UserManagementList({
  reloadFlag,
  onClickItem,
  onClickAccessControl,
  onClickAddGroup,
}: UserManagementListProps) {
  const { i18n, t } = useTranslation();
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [emailList, setEmailList] = useState<string[]>(['']);
  const [load, setLoad] = useState(false);
  const [inviteUserList, setInviteUserList] = useState<InviteUser[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setPage(1);
  }, [searchKeyword]);

  useEffect(() => {
    handleFetchInviteUserList();
  }, [page, searchKeyword, reloadFlag]);

  const handleFetchInviteUserList = async () => {
    setLoad(false);
    const result = await fetchInviteUsers(page, searchKeyword);
    setInviteUserList(result.content);
    setTotalCount(result.totalElements);
    setLoad(true);
  };

  const handleClickSend = async ({
    companyName,
    department,
    type,
    scale,
    field,
  }: {
    companyName: string;
    department: string;
    type: string;
    scale: string;
    field: string;
  }) => {
    const result = await postInviteUsers({
      users: emailList,
      companyName,
      department,
      businessType: type,
      companyScale: scale,
      businessField: field,
      langCd: getLanguageCode(),
    });
    if (result) {
      handleFetchInviteUserList();
      setEmailList(['']);
      setStep(3);
    }
  };

  const handleSubmitSearch = (text: string) => {
    setSearchKeyword(text);
  };

  const handleClickItem = async (email: string) => {
    onClickItem(email);
  };

  return (
    <>
      <div className="col-md-4">
        <div className="cell">
          <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-5 sort-wrap">
            <div className="flex title-row">
              <h3 className="m-0">{t('사용자 초대')}</h3>
              <small className="text-muted text-headings text-uppercase">
                {t('프로젝트를 함께할 팀원을 초대해 보세요.')}
              </small>
            </div>
          </div>
          {step === 1 && (
            <UserInviteEmail
              initialEmailList={emailList}
              onChangeEmailList={setEmailList}
              onChangeStep={setStep}
            />
          )}
          {step === 2 && (
            <UserInviteCompanyInfo
              onClickSend={handleClickSend}
              onChangeStep={setStep}
            />
          )}
          {step === 3 && <UserInviteComplete onChangeStep={setStep} />}
        </div>
      </div>
      <div className="col-md-8">
        <div className="cell">
          <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt sort-wrap">
            <div className="flex title-row">
              <h3 className="mb-0">{t('사용자 리스트')}</h3>
              <small className="text-muted text-headings text-uppercase">
                {t('그룹 사용자에 대한 액세스를 권한을 부여합니다')}
              </small>
            </div>
            <ListSearch onSubmit={handleSubmitSearch} />
          </div>
          <table className="table mb-4 table-nowrap">
            <thead>
              <tr>
                <th>
                  <a>{t('사용자')}</a>
                </th>
                <th>
                  <a>{t('마지막 활성 상태')}</a>
                </th>
                <th>
                  <a>{t('상태')}</a>
                </th>
                <th>
                  <a>{t('작업')}</a>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="list">
              {!load && <></>}
              {load && inviteUserList.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <div className="text-center py-4">
                      <span className="material-icons-outlined text-50 font-size-32pt">
                        info
                      </span>
                      <p className="m-0 text-50">
                        {t('아직 초대된 사용자가 없습니다.')}
                      </p>
                    </div>
                  </td>
                </tr>
              )}
              {load &&
                inviteUserList.length > 0 &&
                inviteUserList.map((inviteUser) => (
                  <UserItem
                    key={inviteUser.invitationSeq}
                    {...inviteUser}
                    onClick={handleClickItem}
                    onAccess={onClickAccessControl}
                    onAddGroup={onClickAddGroup}
                  />
                ))}
            </tbody>
          </table>
          {load && inviteUserList.length > 0 && (
            <Pagination
              page={page}
              totalCount={totalCount}
              onChangePage={setPage}
            />
          )}
        </div>
      </div>
    </>
  );
}

type UserItemProps = InviteUser & {
  onClick: (email: string) => void;
  onAccess: (data: UserManagementAccessData) => void;
  onAddGroup: (data: UserManagementAddGroupData) => void;
};

function UserItem({
  invitationSeq,
  userId,
  userName,
  latestAccessDate,
  accountBeingFlag,
  profileImgUrl,
  activeFlag,
  onClick,
  onAccess,
  onAddGroup,
}: UserItemProps) {
  const { i18n, t } = useTranslation();
  const dropdown = useRef<HTMLDivElement>(null);
  const { handleToggle } = useDropdown(dropdown);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleClick = () => {
    if (accountBeingFlag) {
      onClick(userId);
    }
  };

  const handleReSendInvitationMail = async () => {
    if (!accountBeingFlag) {
      const result = await postRetryInviteUser(userId, getLanguageCode());
      if (result) {
        setShowAlertModal(true);
      }
    } else {
      onClick(userId);
    }
  };

  const handleOptions = (eventKey: string | null) => {
    switch (eventKey) {
      case FUNCTION_DETAIL:
        onClick(userId);
        break;
      case FUNCTION_ADD_USER_TO_GROUP:
        onAddGroup({
          userId,
          invitationSeq,
        });
        break;
      case FUNCTION_CONTROL_ACCESS:
        onAccess({
          userName,
          userId,
          profileImgUrl,
          activeFlag,
          invitationSeq,
        });
        break;
    }
  };

  return (
    <>
      <tr
        className={classNames({
          disabled: accountBeingFlag && !activeFlag,
        })}
      >
        <td>
          <a
            className="media flex-nowrap align-items-center"
            onClick={handleClick}
          >
            <span className="avatar avatar-sm mr-2">
              <ProfileAvatar
                profileImgUrl={profileImgUrl}
                userName={userName}
              />
            </span>
            <div className="media-body">
              <strong className="text-dark">{userName || ''}</strong>
              <div className="text-muted small">{userId}</div>
            </div>
          </a>
        </td>
        <td>
          <span
            className={classNames({
              'text-30': accountBeingFlag && !activeFlag,
            })}
          >
            {latestAccessDate && moment(latestAccessDate).format('YYYY.MM.DD')}
          </span>
        </td>
        <td>
          <span
            className={classNames({
              'text-30': accountBeingFlag && !activeFlag,
            })}
          >
            {!accountBeingFlag && t('초대 완료')}
            {accountBeingFlag && activeFlag && t('활성')}
            {accountBeingFlag && !activeFlag && t('액세스 일시 중단')}
          </span>
        </td>
        <td>
          <a
            className={classNames('text-underline', {
              'text-primary': accountBeingFlag,
              'text-accent': !accountBeingFlag,
            })}
            onClick={handleReSendInvitationMail}
          >
            {!accountBeingFlag && t('초대 재전송')}
            {accountBeingFlag && t('세부사항 보기')}
          </a>
        </td>
        <td className="text-right">
          {accountBeingFlag && (
            <Dropdown
              onToggle={handleToggle}
              onSelect={handleOptions}
              className={'ml-auto'}
            >
              <Dropdown.Toggle
                as={'a'}
                data-caret="false"
                className="text-muted"
              >
                <MaterialIcon name={'more_vert'} />
              </Dropdown.Toggle>
              <Dropdown.Menu align={'right'} ref={dropdown}>
                <Dropdown.Item eventKey={FUNCTION_DETAIL}>
                  {t('세부사항 보기')}
                </Dropdown.Item>
                <Dropdown.Item eventKey={FUNCTION_ADD_USER_TO_GROUP}>
                  {t('그룹에 사용자 추가')}
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger"
                  eventKey={FUNCTION_CONTROL_ACCESS}
                >
                  {activeFlag && t('액세스 일시 중단')}
                  {!activeFlag && t('액세스 허용')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </td>
      </tr>
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        <div className="py-4">
          <div className="text-center">
            <h3>{t('선택하신 사용자에게 초대 메일 재전송 완료했습니다.')}</h3>
          </div>
          <div className="d-flex justify-content-center">
            <div className="media flex-nowrap align-items-center border-1 p-3">
              <div className="media-body">
                <div className="text-muted small">{userId}</div>
              </div>
            </div>
          </div>
        </div>
      </AlertModal>
    </>
  );
}

type UserInviteProps = {
  onChangeStep: (step: 1 | 2 | 3) => void;
};

type UserInviteEmailProps = UserInviteProps & {
  initialEmailList: string[];
  onChangeEmailList: (emailList: string[]) => void;
};

function UserInviteEmail({
  initialEmailList,
  onChangeEmailList,
  onChangeStep,
}: UserInviteEmailProps) {
  const { t } = useTranslation();
  const [emailList, setEmailList] = useState<string[]>(initialEmailList);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const handleAddInviteEmail = () => {
    setEmailList([...emailList, '']);
  };

  const handleRemoveInviteEmail = (i: number) => {
    const removeEmailList = [...emailList];
    removeEmailList.splice(i, 1);
    setEmailList(removeEmailList);
  };

  const handleChangeEmail = (email: string, i: number) => {
    const changeEmailList = [...emailList];
    changeEmailList.splice(i, 1, email);
    setEmailList(changeEmailList);
  };

  const handleClickNextStep = async () => {
    let sendEmailList = emailList.filter((email) => email !== '');
    if (!sendEmailList.length) {
      setFeedbackMessage(t('하나 이상의 이메일을 입력해 주세요'));
    } else {
      sendEmailList = _.uniq(sendEmailList);
      const promise: Promise<boolean>[] = [];
      let isPass = true;
      sendEmailList.some((email, i) => {
        const isEmail = ValidUtils.validateEmail(email);
        if (!isEmail) {
          isPass = false;
          setFeedbackMessage(t('올바른 이메일 형식이 아닙니다.'));
          return true;
        } else {
          promise.push(fetchValidInviteUser(email));
        }
      });

      const validResultList = await Promise.all(promise);
      validResultList.some((validResult, i) => {
        if (!validResult) {
          isPass = false;
          try {
            const email = sendEmailList[i];
            if (email) {
              setFeedbackMessage(
                t(
                  '{{email}}은 이미 초대되었거나 초대할 수 없는 이메일입니다.',
                  {
                    replace: {
                      email: email,
                    },
                  }
                )
              );
            }
          } catch (e) {
            //
          }
          return true;
        }
      });

      if (!isPass) {
        return;
      }

      setFeedbackMessage('');
      onChangeEmailList(sendEmailList);
      onChangeStep(2);
    }
  };

  return (
    <>
      <div className="form-group mb-3">
        <FormLabel textKey={'이메일'} essential={true} />
        {emailList.map((email, i) => (
          <InviteEmailItem
            key={i}
            email={email}
            deleteFlag={i !== emailList.length - 1}
            onChangeEmail={(email) => handleChangeEmail(email, i)}
            onClickAdd={handleAddInviteEmail}
            onClickRemove={() => handleRemoveInviteEmail(i)}
          />
        ))}
      </div>
      {feedbackMessage && (
        <Alert className="alert-soft-accent">
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-8pt">
              <MaterialIcon name={'error_outline'} />
            </div>
            <div className="flex" style={{ minWidth: '180px' }}>
              <small className="text-black-100">{feedbackMessage}</small>
            </div>
          </div>
        </Alert>
      )}
      <div className="mb-32pt">
        <a
          className="btn btn-block btn-lg btn-accent"
          onClick={handleClickNextStep}
        >
          {t('다음 단계')}
        </a>
      </div>
    </>
  );
}

type InviteEmailItemProps = {
  email: string;
  deleteFlag: boolean;
  onChangeEmail: (email: string) => void;
  onClickAdd: () => void;
  onClickRemove: () => void;
};

function InviteEmailItem({
  email,
  deleteFlag,
  onChangeEmail,
  onClickAdd,
  onClickRemove,
}: InviteEmailItemProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="file-controls">
        <div className="multi-file d-flex flex mb-4">
          <input
            type="email"
            className="form-line"
            placeholder={t('초대할 구성원의 이메일을 입력해 주세요.')}
            value={email}
            onChange={(e) => onChangeEmail(e.target.value)}
          />
          <a
            className="btn-file-add align-items-center circle-pin"
            onClick={() => (deleteFlag ? onClickRemove() : onClickAdd())}
          >
            <span className="material-icons">
              {deleteFlag ? 'delete_outline' : 'add'}
            </span>
          </a>
        </div>
      </div>
    </>
  );
}

type UserInviteCompanyInfoProps = UserInviteProps & {
  onClickSend: (companyInfo: {
    companyName: string;
    department: string;
    type: string;
    scale: string;
    field: string;
  }) => void;
};

function UserInviteCompanyInfo({
  onChangeStep,
  onClickSend,
}: UserInviteCompanyInfoProps) {
  const { user } = useUser();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState<{
    companyName: string;
    department: string;
    type: string;
    scale: string;
    field: string;
  }>({
    companyName: user.companyName,
    department: user.department,
    type: user.businessType,
    scale: user.companyScale,
    field: user.businessField,
  });

  const [validInputs, setValidInputs] = useState<{
    companyName: boolean;
    scale: boolean;
  }>({
    companyName: true,
    scale: true,
  });

  const validForm = () => {
    let companyName = true;
    let scale = true;

    if (!inputs.companyName) {
      companyName = false;
    }
    if (!inputs.scale) {
      scale = false;
    }

    setValidInputs({
      companyName,
      scale,
    });

    return companyName && scale;
  };

  const handleClickComplete = () => {
    if (!loading) {
      setLoading(false);
      const isValid = validForm();

      if (isValid) {
        setLoading(true);
        onClickSend(inputs);
      }
    }
  };

  return (
    <>
      <div className="form-group mb-4">
        <FormLabel
          textKey={'회사명'}
          essential={true}
          htmlFor={'companyName'}
        />
        <input
          id="companyName"
          type="text"
          className="form-line pr-6"
          placeholder={t('회사명을 입력해 주세요.')}
          autoComplete={'off'}
          value={inputs.companyName}
          onChange={(e) => {
            setInputs({
              ...inputs,
              [e.target.id]: e.target.value,
            });
          }}
        />
        {!validInputs.companyName && (
          <div className="invalid-feedback">{t('회사명을 입력해 주세요.')}</div>
        )}
      </div>
      <div className="form-group mb-4">
        <FormLabel textKey={'규모'} essential={true} htmlFor={'scale'} />
        <input
          id="scale"
          type="text"
          className="form-line"
          placeholder={t('규모를 입력해 주세요.')}
          autoComplete={'off'}
          value={inputs.scale}
          onChange={(e) => {
            setInputs({
              ...inputs,
              [e.target.id]: e.target.value,
            });
          }}
        />
        {!validInputs.scale && (
          <div className="invalid-feedback">{t('규모를 입력해 주세요.')}</div>
        )}
      </div>
      <div className="form-group mb-4">
        <FormLabel textKey={'부서'} essential={false} htmlFor={'department'} />
        <input
          id="department"
          type="text"
          className="form-line"
          placeholder={t('부서를 입력해 주세요.')}
          autoComplete={'off'}
          value={inputs.department}
          onChange={(e) => {
            setInputs({
              ...inputs,
              [e.target.id]: e.target.value,
            });
          }}
        />
      </div>
      <div className="form-group mb-4">
        <FormLabel textKey={'유형'} essential={false} htmlFor={'type'} />
        <input
          id="type"
          type="text"
          className="form-line pr-6"
          placeholder={t('유형을 입력해 주세요.')}
          autoComplete={'off'}
          value={inputs.type}
          onChange={(e) => {
            setInputs({
              ...inputs,
              [e.target.id]: e.target.value,
            });
          }}
        />
      </div>
      <div className="form-group mb-5">
        <FormLabel textKey={'분야'} essential={false} htmlFor={'field'} />
        <input
          id="field"
          type="text"
          className="form-line"
          placeholder={t('분야를 입력해 주세요.')}
          autoComplete={'off'}
          value={inputs.field}
          onChange={(e) => {
            setInputs({
              ...inputs,
              [e.target.id]: e.target.value,
            });
          }}
        />
      </div>
      <div className="mb-32pt">
        <a
          className={classNames('btn btn-block btn-lg btn-accent', {
            'is-loading': loading,
          })}
          onClick={handleClickComplete}
        >
          {t('초대장 보내기')}
        </a>
      </div>
      <div className="text-50 text-center">
        <a className="text-body text-underline" onClick={() => onChangeStep(1)}>
          {t('이메일 다시 입력')}
        </a>
      </div>
    </>
  );
}

function UserInviteComplete({ onChangeStep }: UserInviteProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="msg-board">
        <h2>{t('초대장 발송 완료')}</h2>
        <img src="/static/images/icon_invitation.svg" alt="" />
        <em>
          {t('초대장이 발송되었습니다.')}
          <br />
          {t('이제 구성원들과 함께 프로젝트를 만들어 보세요.')}
        </em>
      </div>
      <div className="mb-32pt">
        <a
          className="btn btn-block btn-lg btn-accent"
          onClick={() => onChangeStep(1)}
        >
          {t('완료')}
        </a>
      </div>
    </>
  );
}

export default UserManagementList;
