import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LeftPaneBreadcrumb from '@/components/common/LeftPaneBreadcrumb';
import Pagination from '@/components/common/Pagination';
import Preloader from '@/components/common/Preloader';
import { useHomeSelected } from '@/modules/home/hook';
import { Event, EventStatus } from '@/modules/event/types';
import EventFilter from '@/components/common/EventFilter';
import { fetchEvents } from '@/api/event';
import EventRegisterModal from '@/components/event/EventRegisterModal';
import { useEventText } from '@/modules/event/hook';
import EventDetailModal from '@/components/event/EventDetailModal';
import TableEmptyRow from '@/components/common/TableEmptyRow';
import {
  useOpenLayersGeofence,
  useOpenLayersResource,
} from '@/modules/openlayers/hook';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Select } from 'ol/interaction';
import { Feature } from 'ol';
import _ from 'lodash';

function EventList() {
  const { t } = useTranslation();
  const { project, building, floor } = useHomeSelected();
  const { map } = useOpenLayersResource();
  const { geofence, getGeofenceStyle } = useOpenLayersGeofence();
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [filterEventStatusList, setFilterEventStatusList] = useState<
    EventStatus[]
  >([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [eventList, setEventList] = useState<Event[]>([]);
  const eventListRef = useRef<Event[]>([]);
  const [event, setEvent] = useState<Event | null>(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [reload, setReload] = useState(false);
  const clickedGeofenceIdListRef = useRef<string[]>([]);
  const [selectedEventList, setSelectedEventList] = useState<Event[]>([]);
  const mountedRef = useRef(false);

  useEffect(() => {
    return () => handleInitFeature();
  }, []);

  useEffect(() => {
    let select: Select;
    const geofenceLayer = geofence.layer;
    if (geofenceLayer && map) {
      select = new Select({
        layers: [geofenceLayer],
        style: (featureLike) => getGeofenceStyle(featureLike as Feature, true),
      });
      select.getFeatures().on('add', (e) => {
        const feature = e.element as Feature;
        if (feature) {
          handleClickGeofenceFeature(feature);
        }
        select.getFeatures().clear();
      });

      map.addInteraction(select);
    }

    return () => {
      map && select && map.removeInteraction(select);
    };
  }, [map, geofence]);

  useEffect(() => {
    handleInitFeature();
    handleFetchEventList();
  }, [page, floor, searchKeyword, filterEventStatusList]);

  const handleFetchEventList = async () => {
    setLoad(false);
    if (project && building && floor) {
      const data = await fetchEvents({
        page,
        projectId: project.projectId,
        mappingId: building.mappingId,
        mapId: floor.mapId,
        searchKeyword,
        showType2: filterEventStatusList.join(','),
      });

      if (data) {
        setTotalCount(data.totalElements);
        setEventList(data.content);
        eventListRef.current = data.content;
      }
    }
    setLoad(true);
    if (mountedRef.current) {
      setReload(!reload);
    } else {
      mountedRef.current = true;
    }
  };

  const handleClickItem = (event: Event) => {
    setEvent(event);
    setShowDetailModal(true);
  };

  const handleClickTarget = (event: Event) => {
    const selectedEvent = selectedEventList.find(
      ({ eventId }) => eventId === event.eventId
    );
    let list: Event[];
    if (!selectedEvent) {
      list = [...selectedEventList, event];
    } else {
      list = selectedEventList.filter(
        ({ eventId }) => eventId !== event.eventId
      );
    }
    setSelectedEventList(list);
    const targetZoneId = event.targetZoneId;
    handleChangeClickedGeofenceIdList(
      targetZoneId,
      !list.filter(({ targetZoneId }) => targetZoneId === event.targetZoneId)
        .length
    );

    if (
      !Boolean(
        list.find(
          ({ eventId, targetZoneId }) =>
            eventId !== event.eventId && targetZoneId === event.targetZoneId
        )
      )
    ) {
      handleChangeStyleFeature(targetZoneId, !Boolean(selectedEvent));
    }
  };

  const handleClickGeofenceFeature = (feature: Feature) => {
    const id = String(feature.getId());
    const isInclude = handleChangeClickedGeofenceIdList(id, true);
    handleChangeStyleFeature(id, !isInclude);
    const list = eventListRef.current;
    const targetGeofenceEventList = list.filter(
      ({ targetZoneId }) => targetZoneId === id
    );
    if (isInclude) {
      setSelectedEventList(
        selectedEventList.filter(({ targetZoneId }) => targetZoneId !== id)
      );
    } else {
      setSelectedEventList(
        _.uniqBy([...targetGeofenceEventList, ...selectedEventList], 'eventId')
      );
    }
  };

  const handleChangeClickedGeofenceIdList = (
    geofenceId: string,
    isExcept: boolean
  ) => {
    let clickedGeofenceIdList = clickedGeofenceIdListRef.current;
    const isInclude = clickedGeofenceIdList.includes(geofenceId);
    if (isInclude) {
      if (isExcept) {
        clickedGeofenceIdList = clickedGeofenceIdList.filter(
          (clickedGeofenceId) => clickedGeofenceId !== geofenceId
        );
      }
    } else {
      clickedGeofenceIdList = [...clickedGeofenceIdList, geofenceId];
    }
    clickedGeofenceIdListRef.current = clickedGeofenceIdList;

    return isInclude;
  };

  const handleChangeStyleFeature = (id: string, isSelected: boolean) => {
    const source = geofence.layer?.getSource();
    if (source) {
      const feature = source.getFeatureById(id);
      setTimeout(() => {
        feature && feature.setStyle(getGeofenceStyle(feature, isSelected));
      });
    }
  };

  const handleInitFeature = () => {
    eventListRef.current = [];
    clickedGeofenceIdListRef.current = [];
    setSelectedEventList([]);
    const source = geofence.layer?.getSource();
    if (source) {
      source.getFeatures().forEach((paintedFeature) => {
        paintedFeature.setStyle(getGeofenceStyle(paintedFeature, false));
      });
    }
  };

  return (
    <>
      <div className="container-fluid border-bottom-1 py-4">
        <LeftPaneBreadcrumb />
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <h3 className="mb-0">{t('알림 설정')}</h3>
          </div>
        </div>
      </div>
      <EventFilter
        eventMainCategory={'DETECTION'}
        filterEventStatusList={filterEventStatusList}
        reload={reload}
        showSearch={false}
        onChangeFilterEventStatusList={setFilterEventStatusList}
        onSubmitSearchKeyword={setSearchKeyword}
      />
      <div className="table-bumper">
        <table className="table table-high table-line-none table-sticky mb-0">
          <thead>
            <tr>
              <th className="text-center" style={{ width: '33%' }}>
                {t('알림명')}
              </th>
              <th className="text-center" style={{ width: '33%' }}>
                {t('대상')}
              </th>
              <th className="text-center" style={{ width: '33%' }}>
                {t('조건')}
              </th>
            </tr>
          </thead>
          <tbody>
            {!load && (
              <tr>
                <td colSpan={3}>
                  <Preloader />
                </td>
              </tr>
            )}
            {load && (
              <>
                {!eventList.length && (
                  <TableEmptyRow
                    colSpan={3}
                    textKey={'등록된 알림이 없습니다.'}
                  />
                )}
                {eventList.map((event) => (
                  <EventItem
                    key={event.eventId}
                    {...event}
                    selected={Boolean(
                      selectedEventList.find(
                        ({ eventId }) => eventId === event.eventId
                      )
                    )}
                    onClickItem={() => handleClickItem(event)}
                    onClickTarget={() => handleClickTarget(event)}
                  />
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      <Pagination page={page} totalCount={totalCount} onChangePage={setPage} />
      <div className="pub-con">
        <div className="pub-inner">
          <div className="btn-wrap">
            <a
              className="btn btn-accent w-100"
              onClick={() => setShowRegisterModal(true)}
            >
              {t('알림 등록')}
            </a>
          </div>
        </div>
      </div>
      {showDetailModal && event && (
        <EventDetailModal
          show={showDetailModal}
          event={event}
          onHide={() => setShowDetailModal(false)}
          onReload={() => {
            handleFetchEventList();
          }}
          onDelete={() => handleInitFeature()}
        />
      )}
      {showRegisterModal && (
        <EventRegisterModal
          show={showRegisterModal}
          onHide={() => setShowRegisterModal(false)}
          onReload={() => {
            handleFetchEventList();
          }}
        />
      )}
    </>
  );
}

type EventItemProps = Event & {
  selected: boolean;
  onClickItem: () => void;
  onClickTarget: () => void;
};

function EventItem({
  eventTitle,
  eventDetailCategory,
  eventCondition,
  selected,
  onClickItem,
  onClickTarget,
}: EventItemProps) {
  const { t } = useTranslation();
  const { getEventConditionText } = useEventText();

  return (
    <tr className={selected ? `selected` : ``}>
      <td onClick={onClickItem} width="33%">
        <a className="d-inline-flex">
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="event-title">{eventTitle}</Tooltip>}
          >
            <span className="ellipsis">{eventTitle}</span>
          </OverlayTrigger>
        </a>
      </td>
      <td onClick={onClickTarget} width="33%">
        <a className={`badge-basics m-0 ${selected ? `active` : ``}`}>
          <span className="ellipsis">{t('지오펜스')}</span>
        </a>
      </td>
      <td width="33%">
        {getEventConditionText(eventDetailCategory, eventCondition)}
      </td>
    </tr>
  );
}

export default EventList;
