import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import Access from '@/page/Access';
import { UserContextProvider } from '@/modules/user/context';
import { SetupContextProvider } from '@/modules/setup/context';
import { SESSION_STORAGE_KEY_USER_INFO } from '@/utils/constants/common';
import { useUser } from '@/modules/user/hook';
import { SpaceContextProvider } from '@/modules/space/context';
import { ProjectContextProvider } from '@/modules/project/context';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { fetchUserInfoMySelf, refreshAccessToken } from '@/api/user';
import { useLogout } from '@/modules/access/logout/hook';
import { HomeContextProvider } from '@/modules/home/context';
import Project from '@/page/Project';
import Home from '@/page/Home';
import { MonitoringContextProvider } from '@/modules/monitoring/context';
import { OpenLayersContextProvider } from '@/modules/openlayers/context';
import { FloorContextProvider } from '@/modules/floor/context';
import { AssetContextProvider } from './modules/assets/context';
import { SensorContextProvider } from './modules/sensor/context';
import AlarmLocation from '@/page/AlarmLocation';

function AuthenticationRoute() {
  const { handleRefresh, handleSetUser } = useUser();
  const { handleClearUser } = useLogout();
  const history = useHistory();

  const handleInitUserInfo = async () => {
    const item = sessionStorage.getItem(SESSION_STORAGE_KEY_USER_INFO);
    if (item) {
      handleRefresh();
      const userInfo = await fetchUserInfoMySelf();
      if (userInfo) {
        handleSetUser({
          userName: userInfo.username,
          ...userInfo,
        });
      }
    }
  };

  useEffect(() => {
    axios.defaults.withCredentials = true;
    handleInitUserInfo();
  }, []);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response) {
        if (
          error.response.status === StatusCodes.UNAUTHORIZED &&
          !originalRequest._retry
        ) {
          const sessionObj = sessionStorage.getItem(
            SESSION_STORAGE_KEY_USER_INFO
          );
          const userInfo = sessionObj ? JSON.parse(sessionObj) : null;
          if (userInfo) {
            try {
              const data = await refreshAccessToken();
              if (data) {
                const accessToken = data.accessToken;
                originalRequest.headers[
                  'Authorization'
                ] = `Bearer ${accessToken}`;
                axios.defaults.headers.common[
                  'Authorization'
                ] = `Bearer ${accessToken}`;
                userInfo.accessToken = accessToken;
                sessionStorage.setItem(
                  SESSION_STORAGE_KEY_USER_INFO,
                  JSON.stringify(userInfo)
                );
              } else {
                handleClearUser();
                history.replace('/signin');
                return;
              }
            } catch (e) {
              handleClearUser();
              history.replace('/signin');
              return;
            }
          }
          return axios(originalRequest);
        }
        return Promise.reject(error);
      }
    }
  );

  if (
    matchPath(location.pathname, {
      path: ['/reset/password/:key', '/invite/:key'],
      exact: true,
    })
  ) {
    return <></>;
  }

  let redirectTo = '';
  const userInfo = sessionStorage.getItem(SESSION_STORAGE_KEY_USER_INFO);
  if (!userInfo) {
    redirectTo = '/signin';
  } else {
    matchPath(location.pathname, {
      path: '/signin',
      exact: true,
    }) && (redirectTo = '/home');
  }

  return redirectTo ? <Redirect to={redirectTo} /> : <></>;
}

function NotFoundRoute() {
  let redirectTo = '/home';
  const userInfo = sessionStorage.getItem(SESSION_STORAGE_KEY_USER_INFO);
  if (!userInfo) {
    redirectTo = '/signin';
  }
  return <Redirect to={redirectTo} />;
}

function App(): React.ReactElement {
  return (
    <UserContextProvider>
      <HomeContextProvider>
        <SetupContextProvider>
          <ProjectContextProvider>
            <SpaceContextProvider>
              <MonitoringContextProvider>
                <OpenLayersContextProvider>
                  <FloorContextProvider>
                    <AssetContextProvider>
                      <SensorContextProvider>
                        <Router>
                          <AuthenticationRoute />
                          <Switch>
                            <Route
                              path={[
                                '/signin',
                                '/signup',
                                '/find/password',
                                '/reset/password/:key',
                                '/invite/:key',
                              ]}
                              component={Access}
                            />
                            <Route
                              path={[
                                '/home',
                                '/home/:project_id',
                                '/:menu_name/:project_id/:mapping_id/:map_id',
                              ]}
                              component={Home}
                              exact={true}
                            />
                            <Route
                              path={['/project', '/project/:project_id']}
                              component={Project}
                              exact={true}
                            />
                            <Route
                              path={
                                '/alarm/location/:project_id/:mapping_id/:map_id'
                              }
                              component={AlarmLocation}
                              exact={true}
                            />
                            <Route path={'*'} component={NotFoundRoute} />
                          </Switch>
                        </Router>
                      </SensorContextProvider>
                    </AssetContextProvider>
                  </FloorContextProvider>
                </OpenLayersContextProvider>
              </MonitoringContextProvider>
            </SpaceContextProvider>
          </ProjectContextProvider>
        </SetupContextProvider>
      </HomeContextProvider>
    </UserContextProvider>
  );
}

export default App;
