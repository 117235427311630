import { useAssetSearchOption, usePagedAssetList } from '@/modules/assets/hook';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

function AssetLeftPanePagination(): ReactElement {
  const { t } = useTranslation();
  const { pagedAssetList } = usePagedAssetList();
  const { assetSearchOption, setAssetSearchOption } = useAssetSearchOption();

  // 페이지 변경 시
  const onChangePage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAssetSearchOption({
      ...assetSearchOption,
      page: Number(e.target.value),
    });
  };

  return (
    <>
      {pagedAssetList.totalPages !== 0 && (
        <div className="pagination align-items-center justify-content-center">
          {assetSearchOption.page !== 1 && (
            <a
              className="page-link btn-prev"
              aria-label="Previous"
              onClick={() =>
                setAssetSearchOption({
                  ...assetSearchOption,
                  page: assetSearchOption.page - 1,
                })
              }
            >
              <span aria-hidden="true" className="material-icons">
                chevron_left
              </span>
              <span>{t('이전')}</span>
            </a>
          )}
          <div className="d-flex align-items-center justify-content-center px-3">
            <select
              className="form-line"
              value={assetSearchOption.page}
              onChange={onChangePage}
            >
              {[...Array(pagedAssetList.totalPages)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
            <em>{pagedAssetList.totalPages}</em>
          </div>
          {assetSearchOption.page !== pagedAssetList.totalPages && (
            <a
              className="page-link btn-next"
              aria-label="Next"
              onClick={() =>
                setAssetSearchOption({
                  ...assetSearchOption,
                  page: assetSearchOption.page + 1,
                })
              }
            >
              <span>{t('다음')}</span>
              <span aria-hidden="true" className="material-icons">
                chevron_right
              </span>
            </a>
          )}
        </div>
      )}
    </>
  );
}

export default AssetLeftPanePagination;
