import { useContext } from 'react';
import { SensorDispatchContext, SensorStateContext } from './context';
import {
  PagedSensorListType,
  SensorSearchOptionType,
  SENSOR_SET_PAGED_SENSOR_LIST,
  SENSOR_SET_SEARCH_OPTION,
  SENSOR_SET_SELECTED_SENSOR,
} from './types';

function useSensorState() {
  const state = useContext(SensorStateContext);
  if (!state) throw new Error('SensorState is not found');
  return state;
}

function useSensorDispatch() {
  const dispatch = useContext(SensorDispatchContext);
  if (!dispatch) throw new Error('SensorDispatch is not found');
  return dispatch;
}

// 센서 검색 조건 관련 custom hook
export function useSensorSearchOption(): {
  sensorSearchOption: SensorSearchOptionType;
  setSensorSearchOption: (sensorSearchOption: SensorSearchOptionType) => void;
} {
  const { sensorSearchOption } = useSensorState();
  const dispatch = useSensorDispatch();

  const setSensorSearchOption = (
    sensorSearchOption: SensorSearchOptionType
  ) => {
    dispatch({
      type: SENSOR_SET_SEARCH_OPTION,
      sensorSearchOption,
    });
  };

  return {
    sensorSearchOption,
    setSensorSearchOption,
  };
}

// 검색된 센서 관련 custom hook
export function usePagedSensorList(): {
  pagedSensorList: PagedSensorListType;
  setPagedSensorList: (pagedSensorList: PagedSensorListType) => void;
} {
  const { pagedSensorList } = useSensorState();
  const dispatch = useSensorDispatch();

  const setPagedSensorList = (pagedSensorList: PagedSensorListType) => {
    dispatch({
      type: SENSOR_SET_PAGED_SENSOR_LIST,
      pagedSensorList,
    });
  };

  return {
    pagedSensorList,
    setPagedSensorList,
  };
}

// 선택된 센서 관련 custom hook
export function useSelectedSensor(): {
  selectedSensor: string;
  setSelectedSensor: (selectedSensor: string) => void;
} {
  const { selectedSensor } = useSensorState();
  const dispatch = useSensorDispatch();

  const setSelectedSensor = (selectedSensor: string) => {
    dispatch({
      type: SENSOR_SET_SELECTED_SENSOR,
      selectedSensor,
    });
  };

  return {
    selectedSensor,
    setSelectedSensor,
  };
}
