import React, { useEffect } from 'react';
import { useUser } from '@/modules/user/hook';
import { useTranslation } from 'react-i18next';
import { Config } from '@/config';
import { getLanguageCode } from '@/locales/i18n';
import { useWindowPopup } from '@/modules/setup/hook';

type MapEditButtonProps = {
  projectId: string;
  metaId: string;
  mapId: string;
};

function MapEditButton({ projectId, metaId, mapId }: MapEditButtonProps) {
  const { t } = useTranslation();
  const { user } = useUser();
  const { handleSetWindowPopup } = useWindowPopup();

  const handleClickButton = () => {
    const path = `${Config.map_server.indoor_edit_uri}/drawMap.jsp?userid=${
      user.userId
    }&projectid=${projectId}&metaid=${metaId}&mapid=${mapId}&lang=${getLanguageCode()}`;

    const windowPopup = window.open(path);

    windowPopup && handleSetWindowPopup(windowPopup);
  };

  return (
    <a
      className="btn-ico-sm"
      onClick={(e) => {
        e.stopPropagation();
        handleClickButton();
      }}
    >
      <span className="icon material-icons-outlined">open_in_browser</span>
      <span className="text">
        <span>{t('지도 편집')}</span>
      </span>
    </a>
  );
}

export default MapEditButton;
