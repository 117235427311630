import React, { useEffect, useState } from 'react';
import RangeSlider from '@/components/common/RangeSlider';
import Draggable from 'react-draggable';
import { useOpenLayers } from '@/modules/project/hook';
import { useTranslation } from 'react-i18next';

function ProjectFloorPlanOpacity() {
  const { t } = useTranslation();
  const {
    geoImage: { layer },
    transform,
    handleChangeGeoImageAdjustment,
  } = useOpenLayers();
  const [beforeData, setBeforeData] = useState<{
    center: [number, number];
    rotation: number;
    scale: [number, number];
    opacity: number;
  }>({
    center: [0, 0],
    rotation: 0,
    scale: [0, 0],
    opacity: 0.7,
  });

  useEffect(() => {
    if (layer) {
      const source = layer.getSource();
      const center = source.getCenter();
      const rotation = source.getRotation();
      const scale = source.getScale();
      const opacity = layer.getOpacity();

      setBeforeData({
        center,
        rotation,
        scale,
        opacity,
      });
    }
  }, [layer]);

  const handleChangeOpacity = (value: number) => {
    layer.setOpacity(value * 0.01);
  };

  const handleClickCancel = () => {
    const { center, rotation, scale, opacity } = beforeData;
    const source = layer.getSource();
    source.setCenter(center);
    source.setRotation(rotation);
    source.setScale(scale);
    layer.setOpacity(opacity);

    handleFinishAdjustment();
  };

  const handleFinishAdjustment = () => {
    transform.remove?.call(null);
    handleChangeGeoImageAdjustment(false);
  };

  return (
    <Draggable handle=".draggable-handle">
      <div className="trans-box">
        <div className="trans-body">
          <h5
            className="mb-2 draggable-handle"
            style={{
              cursor: 'move',
            }}
          >
            {t('평면도 조정')}
          </h5>
          <p className="text-64">{t('이미지 투명도 조절')}</p>
          <RangeSlider
            min={0}
            max={100}
            from={layer?.getOpacity() * 100 || 0}
            onChange={(data) => {
              handleChangeOpacity(data.from);
            }}
          />
        </div>
        <div className="trans-footer">
          <a className="btn btn-link" onClick={handleClickCancel}>
            {t('취소')}
          </a>
          <a className="btn btn-accent" onClick={handleFinishAdjustment}>
            {t('적용')}
          </a>
        </div>
      </div>
    </Draggable>
  );
}

export default React.memo(ProjectFloorPlanOpacity);
