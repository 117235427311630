import React, { useEffect, useRef, useState } from 'react';
import { Feature, Map, View } from 'ol';
import { Image, Tile, Vector as VectorLayer } from 'ol/layer';
import { ImageWMS, TileJSON, Vector as VectorSource } from 'ol/source';
import {
  MAP_TILE_KEY,
  OPEN_LAYERS_RESOLUTIONS,
} from '@/utils/constants/common';
import { defaults as defaultControls } from 'ol/control';
import { Config } from '@/config';
import { fetchPoi } from '@/api/space';
import { Point } from 'ol/geom';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { defaults as defaultInteractions } from 'ol/interaction';
import { OpenLayersUtils } from '@/utils/OpenLayersUtils';

type OpenLayersMapProps = {
  center: number[];
  mapId?: string;
  onInitMap: (map: Map) => void;
};

function OpenLayersMap({ center, mapId, onInitMap }: OpenLayersMapProps) {
  const container = useRef<HTMLDivElement>(null);
  const mapRef = useRef<Map | null>(null);
  const [{ floorLayer, poiIconLayer, poiTextLayer }, setLayers] = useState<{
    floorLayer: Image | null;
    poiIconLayer: VectorLayer | null;
    poiTextLayer: VectorLayer | null;
  }>({
    floorLayer: null,
    poiIconLayer: null,
    poiTextLayer: null,
  });

  useEffect(() => {
    if (!mapRef.current) {
      const tileLayer = new Tile({
        source: new TileJSON({
          url: `https://api.maptiler.com/maps/streets/tiles.json?key=${MAP_TILE_KEY}`,
          tileSize: 512,
        }),
      });

      const floorSource = new ImageWMS({
        url: `${Config.map_server.geo_server_uri}/geoserver/watta/wms`,
        params: {
          LAYERS: 'watta:lms_view_area',
          VERSION: '1.1.1',
          FORMAT: 'image/png',
          EXCEPTIONS: 'application/vnd.ogc.se_inimage',
        },
      });

      const floorLayer = new Image({
        source: floorSource,
        visible: false,
      });

      const poiIconSource = new VectorSource({ wrapX: false });
      const poiIconLayer = new VectorLayer({
        source: poiIconSource,
      });

      const poiTextSource = new VectorSource({ wrapX: false });
      const poiTextLayer = new VectorLayer({
        source: poiTextSource,
      });

      setLayers({
        floorLayer: floorLayer,
        poiIconLayer: poiIconLayer,
        poiTextLayer: poiTextLayer,
      });

      const map = new Map({
        layers: [tileLayer, floorLayer, poiIconLayer, poiTextLayer],
        target: container.current!,
        interactions: defaultInteractions({
          doubleClickZoom: false,
        }),
        controls: defaultControls({
          zoom: false,
          attribution: false,
          rotate: true,
          rotateOptions: {
            label: '',
          },
        }),
        view: new View({
          resolutions: OPEN_LAYERS_RESOLUTIONS,
          maxResolution: OPEN_LAYERS_RESOLUTIONS[0],
          center,
          zoom: 13,
          projection: 'EPSG:3857',
          zoomFactor: 1,
          constrainResolution: true,
        }),
      });

      map.on('click', (e) => {
        console.log(
          OpenLayersUtils.convertCoordinates(
            e.coordinate,
            'EPSG:3857',
            'EPSG:4326'
          )
        );
      });

      mapRef.current = map;
      onInitMap(map);
    }
  }, []);

  useEffect(() => {
    if (floorLayer) {
      const floorSource = floorLayer.getSource() as ImageWMS;
      let viewParams = '';
      if (mapId) {
        floorLayer?.setVisible(true);
        viewParams = `mapid:'${mapId}'`;
      } else {
        floorLayer?.setVisible(false);
      }
      const floorParams = floorSource.getParams();
      floorParams['viewparams'] = viewParams;
      floorSource.refresh();
    }
  }, [floorLayer, mapId]);

  useEffect(() => {
    if (poiIconLayer && poiTextLayer && mapId) {
      handleFetchPoi(poiIconLayer.getSource(), poiTextLayer.getSource(), mapId);
    }
  }, [poiIconLayer, poiTextLayer, mapId]);

  const handleFetchPoi = async (
    poiIconSource: VectorSource,
    poiTextSource: VectorSource,
    mapId: string
  ) => {
    poiIconSource.clear();
    poiTextSource.clear();

    const poiList = await fetchPoi({
      mapid: mapId,
    });

    if (poiList) {
      poiList.forEach((poi) => {
        const point = new Point([poi.xcoord, poi.ycoord]);
        const iconFeature = new Feature(point);
        iconFeature.setStyle(
          new Style({
            image: new Icon({
              src: `${Config.map_server.indoor_edit_uri}/edit/images/poi/comm_poi_icon/${poi.filenm}`,
            }),
          })
        );
        poiIconSource.addFeature(iconFeature);
        const textFeature = new Feature(point);
        textFeature.setStyle(
          new Style({
            text: new Text({
              text: poi.poi_name,
              scale: 1,
              textAlign: 'center',
              offsetY: 20,
              fill: new Fill({
                color: '#000000',
              }),
              stroke: new Stroke({
                color: '#FFFFFF',
                width: 2,
              }),
              font: 'bold 12px roboto',
            }),
          })
        );
        poiTextSource.addFeature(textFeature);
      });
    }
  };

  return (
    <>
      <div
        ref={container}
        className="map-container"
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      />
    </>
  );
}

export default React.memo(OpenLayersMap);
