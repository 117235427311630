import React, { useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import MaterialIcon from '@/components/common/MaterialIcon';
import {
  FUNCTION_COPY,
  FUNCTION_DELETE,
  FUNCTION_EDIT,
} from '@/utils/constants/common';
import { useTranslation } from 'react-i18next';
import { useDropdown } from '@/modules/common';

type ListDropdownProps = {
  onSelect: (eventKey: string | null) => void;
  showEdit?: boolean;
  showCopy?: boolean;
};

function ListDropdown({
  onSelect,
  showEdit = true,
  showCopy = false,
}: ListDropdownProps) {
  const { t } = useTranslation();
  const dropdown = useRef<HTMLDivElement>(null);
  const { handleToggle } = useDropdown(dropdown);

  return (
    <Dropdown onToggle={handleToggle} onSelect={onSelect}>
      <Dropdown.Toggle as={'a'} data-caret="false" className="text-muted">
        <MaterialIcon name={'more_vert'} />
      </Dropdown.Toggle>
      <Dropdown.Menu ref={dropdown} align={'right'}>
        {showCopy && (
          <>
            <Dropdown.Item eventKey={FUNCTION_COPY}>{t('복사')}</Dropdown.Item>
            <Dropdown.Divider />
          </>
        )}
        {showEdit && (
          <Dropdown.Item eventKey={FUNCTION_EDIT}>
            <span className="material-icons-outlined icon--left">edit</span>{' '}
            {t('수정')}
          </Dropdown.Item>
        )}
        <Dropdown.Item eventKey={FUNCTION_DELETE}>
          <span className="material-icons-outlined icon--left">delete</span>{' '}
          {t('삭제')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ListDropdown;
