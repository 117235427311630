import {
  TOAST_ALERT_TYPE,
  TOAST_PRIMARY_TYPE,
  ToastType,
} from '@/modules/common';
import React, { ReactElement, useEffect } from 'react';
import { Alert, Fade } from 'react-bootstrap';

type ToastAlertItemProps = {
  fcmMessageId: string;
  // 메시지 내용
  message: string;
  // 버튼에 들어갈 text
  buttonText?: string;
  lidarEventDetail: 'IN' | 'OUT' | 'CROSS_LINE' | 'OVER_SPEED' | 'CRASH';
  // 버튼 입력 시 작동할 function
  onClickButton?: (fcmMessageId: string) => void;
};
const getIconClassName = (
  lidarEventDetail: 'IN' | 'OUT' | 'CROSS_LINE' | 'OVER_SPEED' | 'CRASH'
) => {
  switch (lidarEventDetail) {
    case 'IN':
      return 'material-icons text-deep-blue';
    case 'OUT':
      return 'material-icons text-64';
    default:
      return 'material-icons-outlined';
  }
};

const getIconTypeName = (
  lidarEventDetail: 'IN' | 'OUT' | 'CROSS_LINE' | 'OVER_SPEED' | 'CRASH'
) => {
  switch (lidarEventDetail) {
    case 'IN':
      return 'login';
    case 'OUT':
      return 'logout';
    case 'OVER_SPEED':
      return 'warning_amber';
    case 'CROSS_LINE':
      return 'remove_road';
    default:
      return '';
  }
};

const translateMessage = (message: string) => {
  let output = message;
  if (message.indexOf('충돌') > -1) {
    output = '衝突が探知されました';
  } else if (message.indexOf('이탈') > -1) {
    output = '離脱しました';
  } else if (message.indexOf('출입') > -1) {
    output = '出入りしました';
  } else if (message.indexOf('퇴장') > -1) {
    output = '退場しました';
  } else if (message.indexOf('과속') > -1) {
    output = 'スピードを出しすぎました';
  }

  return output;
};

function ToastAlertItem({
  fcmMessageId,
  message,
  lidarEventDetail,
  onClickButton,
}: ToastAlertItemProps): ReactElement {
  let toastType: ToastType;
  const toastIconType: string = getIconTypeName(lidarEventDetail);

  if (lidarEventDetail === 'IN' || lidarEventDetail === 'OUT') {
    toastType = TOAST_PRIMARY_TYPE;
  } else {
    toastType = TOAST_ALERT_TYPE;
  }

  useEffect(() => {
    // 푸시 알람인 경우에만 5초 이후 사라지게 처리
    if (toastType !== TOAST_ALERT_TYPE) {
      setTimeout(() => {
        onClickButton?.call(null, fcmMessageId);
      }, 5000);
    }
  }, [fcmMessageId]);

  return (
    // fade 효과를 주기 위해 사용
    <Fade in={true}>
      <Alert
        className={`alert map-alert 
      ${toastType === TOAST_ALERT_TYPE ? `bg-accent text-white` : `bg-white`}
      `}
        show={true}
      >
        <div className="d-flex align-items-center">
          <div className="d-flex mr-8pt">
            {lidarEventDetail === 'CRASH' ? (
              <img src="/static/images/icon/ic_collision_white.svg" />
            ) : (
              <span className={getIconClassName(lidarEventDetail)}>
                {toastIconType}
              </span>
            )}
          </div>
          <div className="map-alert-title">
            <strong>{translateMessage(message)}</strong>
          </div>
        </div>
        <div className="d-flex flex justify-content-end align-items-center">
          {toastType === TOAST_ALERT_TYPE && (
            <button
              type={'button'}
              className="close"
              onClick={() => onClickButton && onClickButton(fcmMessageId)}
            >
              <span>
                <i className="material-icons">close</i>
              </span>
            </button>
          )}
        </div>
      </Alert>
    </Fade>
  );
}

export default ToastAlertItem;
