import React, { ReactElement, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import '@firebase/messaging';
import { FcmNotification, LidarFcmData } from '@/modules/alert/types';
import { usePush } from '@/modules/setup/hook';
import ToastAlertItem from './ToastAlertItem';
import { FIREBASE_APP_NAME } from '@/utils/constants/common';

let list: {
  fcmMessageId: string;
  title: string;
  body: string;
  lidarEventDetail: 'IN' | 'OUT' | 'CROSS_LINE' | 'OVER_SPEED' | 'CRASH';
}[] = [];
function MapToastAlert(): ReactElement {
  const { handleChangeReceiveFlag } = usePush();
  const [infoAlertList, setInfoAlertList] = useState<
    {
      fcmMessageId: string;
      title: string;
      body: string;
      lidarEventDetail: 'IN' | 'OUT' | 'CROSS_LINE' | 'OVER_SPEED' | 'CRASH';
    }[]
  >([]);

  useEffect(() => {
    list = infoAlertList;
  }, [infoAlertList]);

  useEffect(() => {
    if (firebase.apps.length) {
      const firebaseApp = firebase.apps.find(
        (app) => app.name === FIREBASE_APP_NAME
      );

      if (firebaseApp && firebase.messaging.isSupported()) {
        const messaging = firebaseApp.messaging();

        messaging &&
          messaging.onMessage((payload) => {
            handleChangeReceiveFlag();
            const notification = payload.notification as FcmNotification;
            const data = payload.data as LidarFcmData;
            const fcmMessageId = payload.fcmMessageId as string;
            const newInfoAlertList = [...list];
            if (newInfoAlertList.length + 1 > 5) {
              newInfoAlertList.splice(0, 1);
            }
            newInfoAlertList.push({
              fcmMessageId,
              ...notification,
              ...data,
            });
            setInfoAlertList(newInfoAlertList);
          });
      }
    }
  }, [firebase.apps.length]);

  return (
    <>
      {infoAlertList.map(({ fcmMessageId, lidarEventDetail, body }, i) => (
        <ToastAlertItem
          key={i}
          fcmMessageId={fcmMessageId}
          message={body}
          lidarEventDetail={lidarEventDetail}
          onClickButton={(fcmMessageId) => {
            const newInfoAlertList = [...list];
            setInfoAlertList(
              newInfoAlertList.filter(
                (infoAlert) => infoAlert.fcmMessageId !== fcmMessageId
              )
            );
          }}
        />
      ))}
    </>
  );
}

export default MapToastAlert;
