import LeftPaneBreadcrumb from '@/components/common/LeftPaneBreadcrumb';
import { Config } from '@/config';
import {
  useHomeBuilding,
  useHomeFloor,
  useHomeProject,
} from '@/modules/home/hook';
import { useUser } from '@/modules/user/hook';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

function SensorLeftPaneHeader(): ReactElement {
  const { t } = useTranslation();
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const {
    user: { userId },
  } = useUser();

  // 센서 등록 버튼 클릭 시
  const onClickRegister = () => {
    const lang = localStorage.getItem('language') || 'ko';
    project?.projectId &&
      building?.mappingId &&
      floor?.mapId &&
      window.open(
        `${Config.map_server.indoor_edit_uri}/drawMap.jsp?userid=${userId}&projectid=${project.projectId}&metaid=${building.metaId}&mapid=${floor.mapId}&lang=${lang}&sensor=new`
      );
  };

  return (
    <div className="container-fluid border-bottom-1 py-4">
      <div className="mb-3">
        <LeftPaneBreadcrumb showFloor />
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <h3 className="mb-0">{t('센서 현황')}</h3>
        </div>
        <a
          target="_blank"
          className="btn-add-item text-identity ml-auto mr-n3"
          onClick={onClickRegister}
        >
          <span className="material-icons">add</span>
          {t('센서 등록')}
        </a>
      </div>
    </div>
  );
}

export default SensorLeftPaneHeader;
