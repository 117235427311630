import axios from 'axios';
import { Config } from '@/config';
import { ApiResult, ListResult } from '@/modules/common';
import {
  PROJECT_ONBOARDING_CATEGORY_PROJECT_INFO,
  ProjectDetail,
  ProjectInfo,
  ProjectOnboarding,
  ProjectProduceStep,
  Projects,
  ProjectSpaceLocation,
  ProjectStepData,
} from '@/modules/project/types';
import { StatusCodes } from 'http-status-codes';
import { PAGING_SIZE } from '@/utils/constants/common';

export async function fetchRecentProjects() {
  let projects: Projects = [];

  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/projects/latest/3`
    );

    if (response && response.data) {
      const result: ApiResult = response.data;
      if (result.status === StatusCodes.OK) {
        projects = result.data as Projects;
      }
    }
  } catch (e) {}

  return projects;
}

export async function fetchProjects(page: number, searchKey: string) {
  let listResult: ListResult<Projects> = {
    content: [],
    totalElements: 0,
  };

  try {
    const response = await axios.get(`${Config.platform_api.uri}/projects`, {
      params: {
        pageSize: PAGING_SIZE,
        page,
        usePaging: true,
        searchKey,
      },
    });
    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      listResult = {
        content: result.data.content,
        totalElements: result.data.totalElements,
      };
    }
  } catch (e) {}

  return listResult;
}

export async function fetchAllProjects() {
  let projects: Projects = [];

  try {
    const response = await axios.get(`${Config.platform_api.uri}/projects`, {
      params: {
        usePaging: false,
      },
    });
    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      projects = result.data as Projects;
    }
  } catch (e) {}

  return projects;
}

export async function fetchProject(projectId: string) {
  const response = await axios.get(
    `${Config.platform_api.uri}/projects/${projectId}`
  );
  try {
    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      return result.data as ProjectDetail;
    }
  } catch (e) {
    //
  }

  return null;
}

export async function fetchProjectsAllSpace() {
  const response = await axios.get(
    `${Config.platform_api.uri}/projects/all-buildings`
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return result.data as ProjectSpaceLocation[];
  }

  return [] as ProjectSpaceLocation[];
}

export async function postProject(data: ProjectInfo) {
  const produceStepData: ProjectProduceStep = {
    step: 2,
  };
  const onboardingData: ProjectOnboarding = [
    PROJECT_ONBOARDING_CATEGORY_PROJECT_INFO,
  ];

  const response = await axios.post(`${Config.platform_api.uri}/projects`, {
    ...data,
    produceStepData: JSON.stringify(produceStepData),
    onboardingData: JSON.stringify(onboardingData),
  });

  let projectId = '';
  const result: ApiResult = response.data;
  if (result.status === StatusCodes.OK) {
    projectId = result.data as string;
  }

  return projectId;
}

export async function postProjectConnectSpace(
  projectId: string,
  metaId: string
) {
  try {
    const response = await axios.post(
      `${Config.platform_api.uri}/projects/${projectId}/buildings/${metaId}`
    );
    const result: ApiResult = response.data;
    return result.data as boolean;
  } catch (e) {
    return false;
  }
}

export async function putProject(projectId: string, data: ProjectInfo) {
  try {
    const response = await axios.put(
      `${Config.platform_api.uri}/projects/${projectId}`,
      data
    );

    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      return result.data as boolean;
    }
  } catch (e) {}

  return false;
}

export async function putProjectStepData(
  projectId: string,
  data: ProjectStepData
) {
  try {
    const response = await axios.put(
      `${Config.platform_api.uri}/projects/${projectId}/step-data`,
      data
    );
    const result: ApiResult = response.data;
    return result.data as boolean;
  } catch (e) {}

  return false;
}

export async function deleteProject(projectId: string) {
  try {
    const response = await axios.delete(
      `${Config.platform_api.uri}/projects/${projectId}`
    );

    if (response.status === StatusCodes.OK) {
      const result: ApiResult = response.data;
      return result.data as boolean;
    }
  } catch (e) {}

  return false;
}

export async function deleteProjectDisconnectSpace(
  projectId: string,
  metaId: string
) {
  try {
    const response = await axios.delete(
      `${Config.platform_api.uri}/projects/${projectId}/mapping/${metaId}`
    );
    const result: ApiResult = response.data;
    return result.data as boolean;
  } catch (e) {}

  return false;
}

export async function postProjectPushToken(
  projectId: string,
  pushTokenId: string
) {
  axios.post(`${Config.platform_api.uri}/projects/${projectId}/leave-trace`, {
    deviceType: 'web',
    pushTokenId,
  });
}
