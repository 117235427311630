import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

function AssetTableHeader(): ReactElement {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <th className="text-center pl-4" style={{ width: '33%' }}>
          {t('식별값')}
        </th>
        <th className="text-center" style={{ width: '33%' }}>
          {t('자산명')}
        </th>
        <th className="text-center pr-4" style={{ width: '33%' }}>
          {t('유형')}
        </th>
      </tr>
    </thead>
  );
}

export default AssetTableHeader;
