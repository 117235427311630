import { components, MultiValueProps } from 'react-select';
import React from 'react';

function SelectMultiValue({ children, data, ...rest }: MultiValueProps<any>) {
  return (
    <components.MultiValue {...rest} {...data}>
      {data.name}
    </components.MultiValue>
  );
}

export default SelectMultiValue;
