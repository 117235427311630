import { Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import GeometryType from 'ol/geom/GeometryType';
import { Draw, Interaction } from 'ol/interaction';

export type DrawResource = {
  drawLayer: VectorLayer;
  drawGeometry: (geometry: GeometryType) => Draw;
};

export type OpenLayersState = {
  map: Map | null;
  draw: {
    interaction: Interaction | null;
    resource: DrawResource | null;
  };
  geofence: {
    layer: VectorLayer | null;
  };
  monitoring: {
    lidar: {
      personLayer: VectorLayer | null;
    };
  };
};

export const OPEN_LAYERS_SET_MAP = 'OPEN_LAYERS/OPEN_LAYERS_SET_MAP' as const;
export const OPEN_LAYERS_SET_DRAW_RESOURCE = 'OPEN_LAYERS/SET_DRAW_RESOURCE' as const;
export const OPEN_LAYERS_SET_DRAW_INTERACTION = 'OPEN_LAYERS/SET_DRAW_INTERACTION' as const;
export const OPEN_LAYERS_SET_INIT_STATE = 'OPEN_LAYERS/SET_INIT_STATE' as const;
export const OPEN_LAYERS_SET_GEOFENCE_RESOURCE = 'OPEN_LAYERS/SET_GEOFENCE_RESOURCE' as const;
export const OPEN_LAYERS_SET_MONITORING_LIDAR_RESOURCE = 'OPEN_LAYERS/SET_MONITORING_LIDAR_RESOURCE' as const;

export type Action =
  | {
      type: typeof OPEN_LAYERS_SET_MAP;
      map: Map | null;
    }
  | {
      type: typeof OPEN_LAYERS_SET_DRAW_RESOURCE;
      drawResource: DrawResource | null;
    }
  | {
      type: typeof OPEN_LAYERS_SET_DRAW_INTERACTION;
      drawInteraction: Draw | null;
    }
  | {
      type: typeof OPEN_LAYERS_SET_INIT_STATE;
    }
  | {
      type: typeof OPEN_LAYERS_SET_GEOFENCE_RESOURCE;
      layer: VectorLayer | null;
    }
  | {
      type: typeof OPEN_LAYERS_SET_MONITORING_LIDAR_RESOURCE;
      personLayer: VectorLayer | null;
    };
