import React from 'react';
import FormLabel from '@/components/common/FormLabel';
import FormGroup from '@/components/common/FormGroup';
import { useTranslation } from 'react-i18next';
import InputCustomRadio from '@/components/common/InputCustomRadio';
import EventCategoryForm from '@/components/event/EventCategoryForm';
import EventConditionForm from '@/components/event/EventConditionForm';
import EventTargetOptionForm from '@/components/event/EventTargetOptionForm';
import {
  EVENT_TARGET_TYPE_GEO,
  EventDetailCategory,
  EventInput,
} from '@/modules/event/types';
import { useFloorObject } from '@/modules/floor/hook';

type EventInfoFormProps = {
  eventInput: EventInput;
  onChangeEventInput: (eventInput: EventInput) => void;
};

function EventInfoForm({ eventInput, onChangeEventInput }: EventInfoFormProps) {
  const { t } = useTranslation();
  const { geofenceList } = useFloorObject();

  let isShowOption = false;
  if (eventInput.eventDetailCategory === 'MORE_OR_LESS') {
    isShowOption = true;
  }

  const handleChangeEventDetailCategory = (
    eventDetailCategory: EventDetailCategory
  ) => {
    //

    if (eventDetailCategory === 'MORE_OR_LESS') {
      onChangeEventInput({
        ...eventInput,
        eventDetailCategory,
        eventCondition: 'MORE',
      });
    } else if (eventDetailCategory === 'IN_OR_OUT') {
      onChangeEventInput({
        ...eventInput,
        eventDetailCategory,
        eventCondition: 'IN',
        eventOption: '',
      });
    }
  };

  return (
    <>
      <FormGroup marginBottomClassName={'mb-4'}>
        <FormLabel textKey={'알림 유형'} essential={true} />
        <div className="d-flex px-3 py-2">
          <InputCustomRadio
            textKey={'공간'}
            radioKey={EVENT_TARGET_TYPE_GEO}
            radioName={'eventTargetType'}
            checked={eventInput.eventTargetType === EVENT_TARGET_TYPE_GEO}
          />
          {/*
          <InputCustomRadio
            textKey={'자산'}
            radioKey={EVENT_TARGET_TYPE_ASSET}
            radioName={'eventTargetType'}
            checked={eventInput.targetType === 'ASSET'}
          />
          */}
        </div>
      </FormGroup>
      <FormGroup marginBottomClassName={'mb-4'}>
        <FormLabel textKey={'알림명'} essential={true} />
        <input
          type="text"
          className="form-line"
          placeholder={t('알림명을 입력해 주세요')}
          value={eventInput.eventTitle}
          onChange={(e) => {
            onChangeEventInput({
              ...eventInput,
              eventTitle: e.target.value,
            });
          }}
        />
      </FormGroup>
      <FormGroup marginBottomClassName={'mb-4'}>
        <FormLabel textKey={'지오펜스'} essential={true} />
        <div className="row">
          <div className="col-12">
            <select
              className="form-line"
              value={eventInput.zoneId}
              onChange={(e) =>
                onChangeEventInput({
                  ...eventInput,
                  zoneId: e.target.value,
                })
              }
            >
              <option value={''}>{t('지오펜스을 선택해 주세요')}</option>
              {geofenceList.map(({ zone_id, zone_name }) => (
                <option key={zone_id} value={zone_id}>
                  {zone_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </FormGroup>
      <FormGroup marginBottomClassName={'mb-4'}>
        <FormLabel textKey={'알림 분류'} essential={true} />
        <div className="d-flex px-3 py-2">
          <EventCategoryForm
            eventTargetType={eventInput.eventTargetType}
            eventDetailCategory={eventInput.eventDetailCategory}
            onChangeEventDetailCategory={handleChangeEventDetailCategory}
          />
        </div>
      </FormGroup>
      {isShowOption && (
        <EventTargetOptionForm
          eventDetailCategory={'MORE_OR_LESS'}
          eventCondition={'MORE'}
          eventOption={eventInput.eventOption || ''}
          onChangeEventOption={(eventOption) =>
            onChangeEventInput({
              ...eventInput,
              eventOption,
            })
          }
        />
      )}
      <FormGroup marginBottomClassName={'mb-4'}>
        <FormLabel textKey={'알림 유형'} essential={true} />
        <div className="d-flex px-3 py-2">
          <EventConditionForm
            eventDetailCategory={eventInput.eventDetailCategory}
            eventCondition={eventInput.eventCondition}
            onChangeEventCondition={(eventCondition) =>
              onChangeEventInput({
                ...eventInput,
                eventCondition,
              })
            }
          />
        </div>
      </FormGroup>
    </>
  );
}

export default EventInfoForm;
