import { useTranslation } from 'react-i18next';
import FormGroup from '@/components/common/FormGroup';
import FormLabel from '@/components/common/FormLabel';
import React, { useRef, useState } from 'react';
import {
  PROJECT_POSITIONING_TYPE_HYBRID,
  PROJECT_POSITIONING_TYPE_LIDAR,
  PROJECT_VIEW_TYPE_2D,
  PROJECT_VIEW_TYPE_2D_3D,
  ProjectPositioningType,
  ProjectThumbnail,
  ProjectViewType,
} from '@/modules/project/types';
import { postFile } from '@/api/common';
import { useProjectRegister } from '@/modules/project/hook';
import { CommonUtils } from '@/utils';
import UploadProgressStatusIndicator from '../common/UploadProgressStatusIndicator';
import _ from 'lodash';

type ProjectInfoForm = {
  thumbnail: ProjectThumbnail;
  note: string;
  positioningType: ProjectPositioningType[];
  viewType: ProjectViewType;
  onChangeThumbnail: (thumbnail: ProjectThumbnail) => void;
  onChangeNote: (note: string) => void;
  onChangePositioningType: (positioningType: ProjectPositioningType[]) => void;
  onChangeViewType: (viewType: ProjectViewType) => void;
};

function ProjectInfoForm({
  thumbnail,
  note,
  positioningType,
  viewType,
  onChangeThumbnail,
  onChangeNote,
  onChangePositioningType,
  onChangeViewType,
}: ProjectInfoForm) {
  const { t } = useTranslation();
  const { projectInfo, handleSetProjectInfo } = useProjectRegister();
  const fileInput = useRef<HTMLInputElement>(null);
  const [progressStatus, setProgressStatus] = useState<number>(0);

  const handlePostImage = async (file: File) => {
    const imgId = await postFile(
      file,
      CommonUtils.uploadProgressConfig(setProgressStatus)
    );

    if (imgId) {
      onChangeThumbnail({
        imgId,
        projectImgFileName: file.name,
      });
    }
  };

  const handleCancelImage = () => {
    const current = fileInput.current;
    if (current) {
      current.value = '';
    }
    onChangeThumbnail({
      imgId: '',
      projectImgFileName: '',
    });
    setProgressStatus(0);
  };

  const handleChangeProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSetProjectInfo({
      ...projectInfo,
      projectName: e.target.value,
    });
  };

  const handleChangePositioningType = (
    checkedPositioningType: ProjectPositioningType
  ) => {
    let newPositioningTypeList = _.clone(positioningType);
    if (newPositioningTypeList.includes(checkedPositioningType)) {
      newPositioningTypeList = newPositioningTypeList.filter(
        (newPositioningType) => newPositioningType !== checkedPositioningType
      );
    } else {
      newPositioningTypeList = [
        ...newPositioningTypeList,
        checkedPositioningType,
      ];
    }

    onChangePositioningType(newPositioningTypeList);
  };

  return (
    <div className="container-fluid">
      <FormGroup>
        <FormLabel
          textKey={'프로젝트명'}
          htmlFor={'projectName'}
          essential={true}
        />
        <input
          type="text"
          className="form-line"
          id="projectName"
          placeholder={t('프로젝트명을 입력해 주세요.')}
          value={projectInfo.projectName}
          maxLength={70}
          onChange={handleChangeProjectName}
          autoComplete={'off'}
        />
      </FormGroup>
      <FormGroup className={'over-txt'}>
        <FormLabel textKey={'프로젝트 썸네일'} />
        {!thumbnail.imgId && (
          <>
            {progressStatus ? (
              <UploadProgressStatusIndicator progressStatus={progressStatus} />
            ) : (
              <>
                <input type="text" className="form-line" disabled={true} />
                <div className="btn-abs btn-file-att">
                  <label
                    className="btn-light btn-sm m-0"
                    htmlFor="thumbnailFile"
                  >
                    {t('파일 선택')}
                  </label>
                  <input
                    type="file"
                    id="thumbnailFile"
                    accept={'image/*'}
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length) {
                        handlePostImage(e.target.files[0]);
                      } else {
                        handleCancelImage();
                      }
                    }}
                    ref={fileInput}
                  />
                </div>
              </>
            )}
          </>
        )}
        {thumbnail.imgId && (
          <div className="form-line">
            <div className="att-file">
              <p className="file-name">{thumbnail.projectImgFileName}</p>
              <div className="bundle">
                <a onClick={handleCancelImage}>
                  <span className="material-icons ml-2">close</span>
                </a>
              </div>
            </div>
          </div>
        )}
      </FormGroup>
      <FormGroup>
        <FormLabel textKey={'측위 방식'} essential={true} />
        <div className="d-flex px-3 py-2">
          <div className="custom-control custom-checkbox flex">
            <input
              id="lidar"
              name="positioningType"
              type="checkbox"
              className="custom-control-input"
              checked={positioningType.includes(PROJECT_POSITIONING_TYPE_LIDAR)}
              onChange={() =>
                handleChangePositioningType(PROJECT_POSITIONING_TYPE_LIDAR)
              }
            />
            <label htmlFor="lidar" className="custom-control-label">
              LiDAR
            </label>
          </div>
          <div className="custom-control custom-checkbox flex">
            <input
              id="hybrid"
              name="positioningType"
              type="checkbox"
              className="custom-control-input"
              checked={positioningType.includes(
                PROJECT_POSITIONING_TYPE_HYBRID
              )}
              onChange={() =>
                handleChangePositioningType(PROJECT_POSITIONING_TYPE_HYBRID)
              }
            />
            <label htmlFor="hybrid" className="custom-control-label">
              {t('하이브리드')}
            </label>
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <FormLabel textKey={'시점'} essential={true} />
        <div className="d-flex px-3 py-2">
          <div className="custom-control custom-radio flex">
            <input
              id="2D"
              name="viewType"
              type="radio"
              className="custom-control-input"
              checked={viewType === PROJECT_VIEW_TYPE_2D}
              onChange={(e) =>
                e.target.checked && onChangeViewType(PROJECT_VIEW_TYPE_2D)
              }
            />
            <label htmlFor="2D" className="custom-control-label">
              2D
            </label>
          </div>
          <div className="custom-control custom-radio flex">
            <input
              id="2D_3D"
              name="viewType"
              type="radio"
              className="custom-control-input"
              checked={viewType === PROJECT_VIEW_TYPE_2D_3D}
              onChange={(e) =>
                e.target.checked && onChangeViewType(PROJECT_VIEW_TYPE_2D_3D)
              }
            />
            <label htmlFor="2D_3D" className="custom-control-label">
              2D + 3D
            </label>
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <FormLabel textKey={'프로젝트 설명'} htmlFor={'note'} />
        <textarea
          rows={5}
          placeholder={t('프로젝트 설명을 입력해 주세요.')}
          value={note}
          onChange={(e) => {
            onChangeNote(e.target.value);
          }}
        />
      </FormGroup>
    </div>
  );
}

export default ProjectInfoForm;
