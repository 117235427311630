import React, { useEffect } from 'react';
import Map from 'ol/Map';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Draw } from 'ol/interaction';
import GeometryType from 'ol/geom/GeometryType';
import { useOpenLayersResource } from '@/modules/openlayers/hook';

type DrawOpenLayersMapProps = {
  map: Map | null;
};

function DrawOpenLayersMap({ map }: DrawOpenLayersMapProps) {
  const { handleSetDrawResource } = useOpenLayersResource();

  useEffect(() => {
    if (map) {
      const drawSource = new VectorSource({ wrapX: false });
      const drawLayer = new VectorLayer({
        source: drawSource,
      });
      map.addLayer(drawLayer);

      const handleDrawGeometry = (geometry: GeometryType) => {
        return new Draw({
          type: geometry,
          source: drawSource,
        });
      };

      handleSetDrawResource({
        drawLayer,
        drawGeometry: handleDrawGeometry,
      });
    }
  }, [map]);

  return <></>;
}

export default DrawOpenLayersMap;
