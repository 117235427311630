import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LeftPaneBreadcrumb from '@/components/common/LeftPaneBreadcrumb';
import Pagination from '@/components/common/Pagination';
import { PaneProps } from '@/modules/common';
import {
  PANE_STATUS_DETAIL,
  PANE_STATUS_REGISTER,
} from '@/utils/constants/common';
import { Accident } from '@/modules/accident/types';
import Preloader from '@/components/common/Preloader';
import { fetchAccidents } from '@/api/accident';
import { useHomeSelected } from '@/modules/home/hook';
import AccidentIcon from '@/components/accident/AccidentIcon';
import MaterialIcon from '@/components/common/MaterialIcon';
import classNames from 'classnames';
import moment from 'moment';
import { EVENT_STATUS_ACTIVE, EventStatus } from '@/modules/event/types';
import EventFilter from '@/components/common/EventFilter';
import TableEmptyRow from '@/components/common/TableEmptyRow';
import {
  useOpenLayersDraw,
  useOpenLayersEvent,
  useOpenLayersResource,
} from '@/modules/openlayers/hook';
import { WKT } from 'ol/format';
import { Select } from 'ol/interaction';
import { Feature } from 'ol';

type AccidentListProps = PaneProps & {
  onClickItem: (accident: Accident) => void;
};

function AccidentList({
  onClickItem,
  onChangeStatus,
}: AccidentListProps): ReactElement {
  const { t } = useTranslation();
  const { project, building, floor } = useHomeSelected();
  const { map } = useOpenLayersResource();
  const { drawLayer } = useOpenLayersDraw();
  const { getAccidentStyle, getEndedEventStyle } = useOpenLayersEvent();
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [filterEventStatusList, setFilterEventStatusList] = useState<
    EventStatus[]
  >([EVENT_STATUS_ACTIVE]);
  const [accidentList, setAccidentList] = useState<Accident[]>([]);
  const [reload, setReload] = useState(false);
  const [clickedFeatureId, setClickedFeatureId] = useState('');
  const mountedRef = useRef(false);

  useEffect(() => {
    let select: Select;
    if (drawLayer && map) {
      select = new Select({
        layers: [drawLayer],
        style: getAccidentStyle(false),
      });
      select.getFeatures().on('add', (e) => {
        const feature = e.element as Feature;
        feature && setClickedFeatureId(String(feature.getId()));
        select.getFeatures().clear();
      });
      map.addInteraction(select);
    }

    return () => {
      handleClearDrawLayer();
      map && select && map.removeInteraction(select);
    };
  }, [map, drawLayer]);

  useEffect(() => {
    handleClearDrawLayer();
    handleFetchAccidentList();
  }, [page, floor, filterEventStatusList]);

  useEffect(() => {
    if (drawLayer) {
      handleAllDisplayFeature();
    }
  }, [drawLayer, accidentList]);

  const handleClearDrawLayer = () => {
    drawLayer?.getSource().clear();
  };

  const handleFetchAccidentList = async () => {
    setLoad(false);
    if (project && building && floor) {
      const data = await fetchAccidents({
        page,
        projectId: project.projectId,
        mappingId: building.mappingId,
        mapId: floor.mapId,
        showType2: filterEventStatusList.join(','),
      });

      if (data) {
        setTotalCount(data.totalElements);
        setAccidentList(data.content);
      }
    }
    setLoad(true);
    if (mountedRef.current) {
      setReload(!reload);
    } else {
      mountedRef.current = true;
    }
  };

  const handleClickItem = (accident: Accident) => {
    onClickItem(accident);
    onChangeStatus(PANE_STATUS_DETAIL);
  };

  const handleAllDisplayFeature = () => {
    accidentList.forEach((accident) => {
      handleDisplayFeature(accident);
    });
  };

  const handleDisplayFeature = (
    { eventId, geom, activeFlag }: Accident,
    isSelected = false
  ) => {
    const source = drawLayer?.getSource();
    if (source) {
      const feature = source.getFeatureById(eventId);
      if (feature) {
        activeFlag
          ? feature.setStyle(getAccidentStyle(isSelected))
          : feature.setStyle(getEndedEventStyle(isSelected));
      } else {
        const drawFeature = geom && new WKT().readFeature(geom);
        if (drawFeature) {
          drawFeature.setId(eventId);
          activeFlag
            ? drawFeature.setStyle(getAccidentStyle(isSelected))
            : drawFeature.setStyle(getEndedEventStyle(isSelected));
          source.addFeature(drawFeature);
        }
      }
    }
  };

  return (
    <>
      <div className="container-fluid border-bottom-1 py-4">
        <LeftPaneBreadcrumb />
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <h3 className="mb-0">{t('사고 관리')}</h3>
          </div>
        </div>
      </div>
      <EventFilter
        eventMainCategory={'ACCIDENT'}
        filterEventStatusList={filterEventStatusList}
        reload={reload}
        showSearch={false}
        onChangeFilterEventStatusList={setFilterEventStatusList}
      />
      <div className="table-bumper">
        <table className="table table-high table-line-none table-sticky mb-0">
          <thead>
            <tr>
              <th className="text-center">{t('사고 유형')}</th>
              <th className="text-center">{t('일시')}</th>
              <th className="text-center">{t('상태')}</th>
            </tr>
          </thead>
          <tbody>
            {!load && (
              <tr>
                <td colSpan={3}>
                  <Preloader />
                </td>
              </tr>
            )}
            {load && (
              <>
                {!accidentList.length && (
                  <TableEmptyRow
                    colSpan={3}
                    textKey={'등록된 내역이 없습니다.'}
                  />
                )}
                {accidentList.map((accident) => (
                  <AccidentItem
                    key={accident.eventId}
                    {...accident}
                    clickedFeatureId={clickedFeatureId}
                    onInitClickedFeatureId={(selected) => {
                      handleDisplayFeature(accident, selected);
                      setClickedFeatureId('');
                    }}
                    onClickItem={() => handleClickItem(accident)}
                    onClickTarget={(selected) =>
                      handleDisplayFeature(accident, selected)
                    }
                  />
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      <Pagination page={page} totalCount={totalCount} onChangePage={setPage} />
      <div className="pub-con">
        <div className="pub-inner">
          <div className="btn-wrap">
            <a
              className="btn btn-accent w-100"
              onClick={() => onChangeStatus(PANE_STATUS_REGISTER)}
            >
              {t('사고 등록')}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

type AccidentItemProps = Accident & {
  clickedFeatureId: string;
  onInitClickedFeatureId: (selected: boolean) => void;
  onClickItem: () => void;
  onClickTarget: (selected: boolean) => void;
};

function AccidentItem({
  eventId,
  activeFlag,
  eventDetailCategory,
  registDate,
  clickedFeatureId,
  onInitClickedFeatureId,
  onClickItem,
  onClickTarget,
}: AccidentItemProps) {
  const { t } = useTranslation();
  const [isSelected, setSelected] = useState(false);

  useEffect(() => {
    if (clickedFeatureId && eventId === clickedFeatureId) {
      onInitClickedFeatureId(!isSelected);
      setSelected(!isSelected);
    }
  }),
    [clickedFeatureId];

  return (
    <tr
      className={classNames({
        'text-40': !activeFlag,
        selected: isSelected,
      })}
    >
      <td>
        <a onClick={onClickItem}>
          <AccidentIcon
            accidentCategory={eventDetailCategory}
            className={activeFlag ? 'text-identity' : ''}
          />
        </a>
      </td>
      <td>{moment(registDate).format('YYYY.MM.DD')}</td>
      <td className="text-center">
        <ul className="state-item">
          <li
            className={classNames('arry01', {
              'text-identity': activeFlag,
            })}
          >
            {activeFlag ? t('진행중') : t('종료됨')}
          </li>
          <li className="arry02">
            <a
              className="d-flex"
              onClick={() => {
                onClickTarget(!isSelected);
                setSelected(!isSelected);
              }}
            >
              <MaterialIcon
                name={'place'}
                className={
                  isSelected
                    ? 'material-icons-sharp'
                    : 'material-icons-outlined'
                }
              />
            </a>
          </li>
        </ul>
      </td>
    </tr>
  );
}

export default AccidentList;
