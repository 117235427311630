export type Accident = {
  eventId: string;
  eventDetailCategory: AccidentCategory;
  eventTitle: string;
  registDate: string;
  updateDate: string;
  activeFlag: boolean;
  geom?: string;
  geomType?: string;
  lng?: number;
  lat?: number;
  radius?: number;
  areaName: string;
  areaId: string;
  areaGeom: string;
};

export const ACCIDENT_EMERGENCY = 'EMERGENCY';
export const ACCIDENT_FIRE = 'FIRE';
export const ACCIDENT_RESCUE = 'SOS';
export const ACCIDENT_INFECTIOUS_DISEASE = 'DISEASES';

export type AccidentCategory =
  | typeof ACCIDENT_EMERGENCY
  | typeof ACCIDENT_FIRE
  | typeof ACCIDENT_RESCUE
  | typeof ACCIDENT_INFECTIOUS_DISEASE;

export const ACCIDENT_LOCATION_CIRCLE = 'CIRCLE';
export const ACCIDENT_LOCATION_POLYGON = 'POLYGON';
export const ACCIDENT_LOCATION_AREA = 'AREA';

export type AccidentLocation =
  | typeof ACCIDENT_LOCATION_CIRCLE
  | typeof ACCIDENT_LOCATION_POLYGON
  | typeof ACCIDENT_LOCATION_AREA;
