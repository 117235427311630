import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSpaceRegister } from '@/modules/space/hook';
import { useLeftSidebar } from '@/modules/setup/hook';

type LeftCustomPaneProps = {
  show: boolean;
  children?: ReactNode;
};

function JapanAddressPane({ show, children }: LeftCustomPaneProps) {
  const { expand } = useLeftSidebar();
  const { handleChangeShowAddressPane } = useSpaceRegister();
  const [paneStyle, setPaneStyle] = useState<CSSProperties>({});
  const divElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      handleChangeShowAddressPane(false);
    };
  }, []);

  useEffect(() => {
    const divElement = divElementRef.current;

    if (divElement) {
      if (show) {
        divElement.style.visibility = 'visible';
        divElement.style.transform = 'translateZ(0)';
        divElement.style.boxShadow =
          '0 3px 3px -2px rgba(39, 44, 51, .1), 0 3px 4px 0 rgba(39, 44, 51, .04), 0 1px 8px 0 rgba(39, 44, 51, .02)';
      } else {
        divElement.style.transform = '';
        divElement.style.boxShadow = '';
      }
    }
  }, [show]);

  useEffect(() => {
    let style: CSSProperties = {};

    if (expand) {
      style = {
        left: '200px',
      };
    } else {
      style = {
        left: '64px',
      };
    }

    setPaneStyle(style);
  }, [expand]);

  return (
    <div
      className="side-contents japan-address-pane position-absolute"
      style={paneStyle}
      ref={divElementRef}
    >
      {children}
    </div>
  );
}

export default JapanAddressPane;
