import {
  useHomeBuilding,
  useHomeFloor,
  useHomeProject,
} from '@/modules/home/hook';
import React, { ReactElement, useState } from 'react';
import SensorLeftPaneHeader from './SensorLeftPaneHeader';
import SensorLeftPaneSearchBar from './SensorLeftPaneSearchBar';
import SensorLeftPaneTable from './SensorLeftPaneTable';

function SensorLeftPane(): ReactElement {
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      {project && building && floor && (
        <>
          <SensorLeftPaneHeader />
          <SensorLeftPaneSearchBar setLoading={setLoading} />
          <SensorLeftPaneTable loading={loading} />
          {/* Todo: 페이징 기능 개발 이후 진행 */}
          {/* <SensorLeftPanePagination /> */}
        </>
      )}
    </>
  );
}

export default SensorLeftPane;
