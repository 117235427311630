import axios from 'axios';
import { Config } from '@/config';
import { StatusCodes } from 'http-status-codes';
import { ApiResult } from '@/modules/common';
import {
  MonitoringFloorAppEndPointUserStatus,
  MonitoringFloorLidarAccessStatus,
  MonitoringFloorLidarStatus,
  MonitoringGeoObjectGroup,
} from '@/modules/monitoring/types';

export async function fetchMonitoringObjects(params: {
  mapid: string;
  searchnm: string;
  area?: boolean;
  geofence?: boolean;
  poi?: boolean;
  util?: boolean;
}) {
  let monitoringGeoObject: MonitoringGeoObjectGroup = {
    area_group: [],
    geofence_group: [],
    geofences: [],
    remains: [],
  };

  try {
    const axiosInstance = axios.create({
      withCredentials: false,
    });
    const response = await axiosInstance.get(
      `${Config.space_api.uri}/geoDatasList`,
      {
        params,
      }
    );

    if (response.status === StatusCodes.OK) {
      monitoringGeoObject = response.data as MonitoringGeoObjectGroup;
    }
  } catch (e) {}

  return monitoringGeoObject;
}

export async function fetchMonitoringFloorAppEndPointUserStatus(
  projectId: string,
  mappingId: string,
  mapId: string
) {
  let floorAppEndPointUserStatus: MonitoringFloorAppEndPointUserStatus | null = null;
  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/realtime/projects/${projectId}/buildings/${mappingId}/floors/${mapId}/endpoint-users`
    );
    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      floorAppEndPointUserStatus = result.data as MonitoringFloorAppEndPointUserStatus;
    }
  } catch (e) {}

  return floorAppEndPointUserStatus;
}

export async function initMonitoringLidarWebSocket(
  projectId: string,
  mappingId: string,
  mapId: string
) {
  try {
    const response = await axios.get(
      `${Config.trace_api.uri}/sub/realtime/monitoring/${projectId}/${mappingId}/${mapId}`
    );
    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      return Boolean(result.data);
    }
  } catch (e) {}

  return false;
}

export async function fetchMonitoringFloorLidarStatus(params: {
  projectId: string;
  mappingId: string;
  mapId: string;
}) {
  let floorLidarStatus: MonitoringFloorLidarStatus | null = null;

  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/factory/realtime/lidar`,
      {
        params,
      }
    );
    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      floorLidarStatus = result.data as MonitoringFloorLidarStatus;
    }
  } catch (e) {}

  return floorLidarStatus;
}

export async function fetchMonitoringFloorLidarAccessStatus(params: {
  projectId: string;
  mappingId: string;
  mapId: string;
}) {
  let floorLidarAccessStatus: MonitoringFloorLidarAccessStatus | null = null;

  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/factory/realtime/lidar/event-count`,
      {
        params,
      }
    );
    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      floorLidarAccessStatus = result.data as MonitoringFloorLidarAccessStatus;
    }
  } catch (e) {}

  return floorLidarAccessStatus;
}
