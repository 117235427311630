import React, { ReactNode } from 'react';
import LeftPane from '@/components/common/LeftPane';
import classNames from 'classnames';
import { useLeftSidebar } from '@/modules/setup/hook';
import { MenuIdx } from '@/modules/setup/types';

type LeftSidebarProps = {
  showPane?: boolean;
  customPane?: ReactNode;
  children?: ReactNode;
  leftPaneMenuIdx?: MenuIdx;
};

function LeftSidebar({
  showPane = true,
  customPane,
  leftPaneMenuIdx,
  children,
}: LeftSidebarProps) {
  const { expand } = useLeftSidebar();

  return (
    <>
      <div
        className={classNames('side-navbar', {
          expander: expand,
        })}
      >
        <nav className="nav-container">
          <div className="nav-inner">
            <div className="nav__list">{children}</div>
          </div>
        </nav>
      </div>
      {showPane && (
        <>
          <LeftPane
            {...(leftPaneMenuIdx ? { menuIdx: leftPaneMenuIdx } : {})}
          />
          {customPane}
        </>
      )}
    </>
  );
}

export default LeftSidebar;
