import axios from 'axios';
import { Config } from '@/config';
import { ApiResult } from '@/modules/common';
import { StatusCodes } from 'http-status-codes';
import { SignIn } from '@/modules/access/signin/types';
import { SignUp } from '@/modules/access/signup/types';
import { Logout } from '@/modules/access/logout/types';
import { SESSION_STORAGE_KEY_USER_INFO } from '@/utils/constants/common';
import { DataResultMessage } from '@/api/type';
import { UserInfo } from '@/modules/user/types';

export async function fetchUser(data: SignIn) {
  const axiosInstance = axios.create();
  const response = await axiosInstance.post(
    `${Config.platform_api.uri}/auth/signin`,
    data
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    const accessToken = result.data.accessToken;
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  return result.data;
}

export async function fetchMatchingPassword(data: SignIn) {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${Config.platform_api.uri}/auth/signin`,
      data
    );
    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      const accessToken = result.data.accessToken;
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }

    return result.data;
  } catch (e) {}

  return null;
}

export async function fetchUserInfoMySelf() {
  const response = await axios.get(`${Config.platform_api.uri}/users/info`);
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return result.data as UserInfo;
  }

  return null;
}

export async function fetchUserInfo(userId: string) {
  const response = await axios.get(
    `${Config.platform_api.uri}/users/info/${userId}`
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return result.data as UserInfo;
  }

  return null;
}

export async function fetchUserId(userId: string) {
  const axiosInstance = axios.create();
  const response = await axiosInstance.get(
    `${Config.platform_api.uri}/auth/duplicate`,
    {
      params: {
        userId,
      },
    }
  );

  let result: DataResultMessage = {
    result: false,
    detail: '',
  };

  const apiResult: ApiResult = response.data;
  if (apiResult.status === StatusCodes.OK) {
    result = apiResult.data as DataResultMessage;
  }

  return result;
}

export async function postUser(data: SignUp) {
  const response = await axios.post(
    `${Config.platform_api.uri}/auth/signup`,
    data
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return true;
  }

  return false;
}

export async function deleteAccessToken(data: Logout) {
  const response = await axios.post(
    `${Config.platform_api.uri}/auth/signout`,
    data
  );
  const result: ApiResult = response.data;
  return result.status;
}

export async function refreshAccessToken() {
  const sessionObj = sessionStorage.getItem(SESSION_STORAGE_KEY_USER_INFO);
  const userInfo = sessionObj ? JSON.parse(sessionObj) : null;

  const axiosInstance = axios.create();
  const response = await axiosInstance.post(
    `${Config.platform_api.uri}/auth/renewal`,
    {
      userId: userInfo.userId,
      refreshToken: userInfo.refreshToken,
    },
    {
      withCredentials: false,
    }
  );

  if (response.status === StatusCodes.OK) {
    const result: ApiResult = response.data;
    return result.data;
  } else {
    return null;
  }
}

export async function postAuthNumberMail(userId: string, langCd: string) {
  const axiosInstance = axios.create();
  const response = await axiosInstance.post(
    `${Config.platform_api.uri}/user-manages/auth`,
    {
      userId,
      langCd,
    }
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return true;
  }

  return false;
}

export async function getAuthNumber(userId: string, authNumber: string) {
  const axiosInstance = axios.create();
  const response = await axiosInstance.get(
    `${Config.platform_api.uri}/auth/ac/${userId}/${authNumber}`
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    const data = result.data as DataResultMessage;
    return data.result;
  }

  return false;
}

export async function postResetPasswordMail(userId: string, langCd: string) {
  const axiosInstance = axios.create();
  const response = await axiosInstance.post(
    `${Config.platform_api.uri}/user-manages/reset`,
    {
      userId,
      langCd,
    }
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return true;
  }

  return false;
}

export async function fetchResetPasswordAuthNumber(authNumber: string) {
  const axiosInstance = axios.create();
  const response = await axiosInstance.get(
    `${Config.platform_api.uri}/auth/r/${authNumber}`
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    const data = result.data as DataResultMessage;

    if (data.result) {
      return data.detail;
    }
  }

  return null;
}

export async function putResetPassword(data: {
  userId: string;
  newPassword: string;
}) {
  const axiosInstance = axios.create();
  const response = await axiosInstance.put(
    `${Config.platform_api.uri}/users/reset/password`,
    data
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    const data = result.data as DataResultMessage;
    return data.result === 'true';
  }

  return false;
}

export async function putMyInfo(data: {
  profileImgId?: string;
  companyName?: string;
  department?: string;
  businessType?: string;
  companyScale?: string;
  businessField?: string;
  userName?: string;
}) {
  try {
    const response = await axios.put(
      `${Config.platform_api.uri}/users/info`,
      data
    );
    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      return result.data as boolean;
    }
  } catch (e) {}

  return false;
}

export async function putMyInfoPassword(data: {
  password: string;
  newPassword: string;
}) {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.put(
      `${Config.platform_api.uri}/users/password`,
      data
    );
    const result: ApiResult = response.data;

    if (result.status === StatusCodes.OK) {
      const data = result.data as DataResultMessage;
      return data.result === 'true';
    }
  } catch (e) {}

  return false;
}
