import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Feature, Map } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { fetchMonitoringFloorAppEndPointUserStatus } from '@/api/monitoring';
import { useHomeMenu, useHomeSelected } from '@/modules/home/hook';
import {
  MONITORING_APP_INTERVAL,
  OPEN_LAYERS_Z_INDEX_DRAWING,
} from '@/utils/constants/common';
import {
  MONITORING_TYPE_APP,
  MonitoringAppEndPointUser,
} from '@/modules/monitoring/types';
import { OpenLayersUtils } from '@/utils/OpenLayersUtils';
import * as TWEEN from '@tweenjs/tween.js';
import { Point } from 'ol/geom';
import { Icon, Style } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { useMonitoring, useMonitoringApp } from '@/modules/monitoring/hook';
import { Select } from 'ol/interaction';
import MonitoringAppEndPointUserDetailModal from '@/components/monitoring/MonitoringAppEndPointUserDetailModal';
import { MENU_IDX_MONITORING } from '@/modules/setup/types';

let paintedAppEndPointUserList: MonitoringAppEndPointUser[] = [];
let timer: ReturnType<typeof setInterval> | null = null;
let isRequestAnimationFrame = false;
let isTimerPause = true;

type MonitoringAppEndPointUserDrawingProps = {
  map: Map | null;
};

function MonitoringAppEndPointUserDrawing({
  map,
}: MonitoringAppEndPointUserDrawingProps) {
  const { project, building, floor } = useHomeSelected();
  const { activeMenuIdx } = useHomeMenu();
  const { monitoringType } = useMonitoring();
  const { handleSetAppEndPointUserData } = useMonitoringApp();
  const monitoringAppLayerRef = useRef<VectorLayer | null>(null);
  const [
    appEndPointUser,
    setAppEndPointUser,
  ] = useState<MonitoringAppEndPointUser | null>(null);

  useEffect(() => {
    if (map) {
      const monitoringAppSource = new VectorSource({ wrapX: false });
      const monitoringAppLayer = new VectorLayer({
        source: monitoringAppSource,
        visible: true,
        zIndex: OPEN_LAYERS_Z_INDEX_DRAWING,
      });
      monitoringAppLayerRef.current = monitoringAppLayer;
      map.addLayer(monitoringAppLayer);
      const select = new Select({
        layers: [monitoringAppLayer],
        style: getAppEndPointUserStyle(),
      });
      select.getFeatures().on('add', (e) => {
        const feature = e.element as Feature;
        if (feature.get('app_end_point_user')) {
          setAppEndPointUser(
            feature.get('app_end_point_user') as MonitoringAppEndPointUser
          );
        }
        select.getFeatures().clear();
      });
      map.addInteraction(select);
    }
  }, [map]);

  useEffect(() => {
    activeMenuIdx === MENU_IDX_MONITORING && setAppEndPointUser(null);
  }, [activeMenuIdx]);

  useEffect(() => {
    handleStopInterval();
    if (monitoringType === MONITORING_TYPE_APP) {
      handleStartInterval();
    } else {
      handleStopInterval();
    }

    return () => {
      handleStopInterval();
    };
  }, [monitoringType, floor]);

  const handleInitData = (isStart: boolean) => {
    if (!isStart) {
      handleSetAppEndPointUserData([], 0);
    }
    const monitoringAppLayer = monitoringAppLayerRef.current;
    if (monitoringAppLayer) {
      monitoringAppLayer.getSource().clear();
      monitoringAppLayer.setVisible(isStart);
    }
  };

  const handleTweenAnimate = () => {
    if (isRequestAnimationFrame) {
      requestAnimationFrame(handleTweenAnimate);
    }
    TWEEN.update();
  };

  const handleStartInterval = () => {
    if (!timer) {
      isTimerPause = false;
      isRequestAnimationFrame = true;
      requestAnimationFrame(handleTweenAnimate);
      handleInitData(true);
      handleFetchAppEndPointUserList();
      timer = setInterval(
        handleFetchAppEndPointUserList,
        MONITORING_APP_INTERVAL
      );
    }
  };

  const handleStopInterval = () => {
    if (timer) {
      isTimerPause = true;
      isRequestAnimationFrame = false;
      clearInterval(timer);
    }
    handleInitData(false);
    timer = null;
  };

  const handleFetchAppEndPointUserList = async () => {
    if (project && building && floor && !isTimerPause) {
      const floorAppEndPointUserStatus = await fetchMonitoringFloorAppEndPointUserStatus(
        project.projectId,
        building.mappingId,
        floor.mapId
      );

      if (floorAppEndPointUserStatus) {
        const floorAppEndPointUser =
          floorAppEndPointUserStatus.activeUsersByFloor.data;
        const appEndPointUserList: MonitoringAppEndPointUser[] = [];
        for (const accessKey in floorAppEndPointUser) {
          const appEndPointUser = floorAppEndPointUser[accessKey];
          if (appEndPointUser) {
            const [lng, lat] = OpenLayersUtils.convertCoordinates([
              appEndPointUser.lng,
              appEndPointUser.lat,
            ]);
            appEndPointUser.lng = lng;
            appEndPointUser.lat = lat;
            appEndPointUserList.push(appEndPointUser);
          }
        }
        handleRemoveFeatureAppEndPointUser(appEndPointUserList);
        handleDrawAppEndPointUser(appEndPointUserList);
        handleSetAppEndPointUserData(
          appEndPointUserList,
          floorAppEndPointUserStatus.totalUsersByFloor.count
        );
      }
    }
  };

  const handleRemoveFeatureAppEndPointUser = (
    appEndPointUserList: MonitoringAppEndPointUser[]
  ) => {
    const monitoringAppLayer = monitoringAppLayerRef.current;
    const monitoringAppSource = monitoringAppLayer?.getSource();
    const removeAppEndPointUserList = paintedAppEndPointUserList.filter(
      ({ accessKey: paintedAccessKey }) =>
        !appEndPointUserList.find(
          ({ accessKey }) => paintedAccessKey === accessKey
        )
    );

    removeAppEndPointUserList.forEach(({ accessKey }) => {
      const featureById = monitoringAppSource?.getFeatureById(accessKey);
      if (featureById) {
        monitoringAppSource?.removeFeature(featureById);
      }
    });
  };

  const handleDrawAppEndPointUser = (
    appEndPointUserList: MonitoringAppEndPointUser[]
  ) => {
    const monitoringAppLayer = monitoringAppLayerRef.current;
    const monitoringAppSource = monitoringAppLayer?.getSource();
    if (monitoringAppSource) {
      appEndPointUserList.forEach((appEndPointUser) => {
        const accessKey = appEndPointUser.accessKey;
        const featureById = monitoringAppSource.getFeatureById(accessKey);
        const coordinates = {
          x: appEndPointUser.lng,
          y: appEndPointUser.lat,
        };
        if (featureById) {
          const point = featureById.getGeometry() as Point;
          const curCoordinates = point.getCoordinates();
          new TWEEN.Tween({ x: curCoordinates[0], y: curCoordinates[1] })
            .to(coordinates, 2000)
            .onUpdate(({ x, y }) => featureById.setGeometry(new Point([x, y])))
            .start();
        } else {
          const iconFeature = new Feature(
            new Point([coordinates.x, coordinates.y])
          );
          iconFeature.setId(accessKey);
          iconFeature.set('app_end_point_user', appEndPointUser);
          iconFeature.setStyle(getAppEndPointUserStyle());
          monitoringAppSource.addFeature(iconFeature);
        }
      });
    }

    paintedAppEndPointUserList = appEndPointUserList;
  };

  const getAppEndPointUserStyle = useCallback(() => {
    return new Style({
      image: new Icon({
        src: `/static/images/person_pin_mo.svg`,
        anchor: [0.5, 34], // 0.5 default, 34 image height
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.PIXELS,
      }),
    });
  }, []);

  return (
    <>
      {activeMenuIdx === MENU_IDX_MONITORING && appEndPointUser && (
        <MonitoringAppEndPointUserDetailModal
          show={true}
          onHide={() => setAppEndPointUser(null)}
          appEndPointUser={appEndPointUser}
        />
      )}
    </>
  );
}

export default React.memo(MonitoringAppEndPointUserDrawing);
