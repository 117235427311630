import React from 'react';

type NewlineTextProps = {
  text: string;
};

function NewlineText(props: NewlineTextProps) {
  return (
    <>
      {props.text.split('\n').map((str, index) => (
        <span
          style={{
            display: 'block',
            wordBreak: 'break-all',
          }}
          key={index}
        >
          {str}
        </span>
      ))}
    </>
  );
}

export default NewlineText;
