import {
  SensorState,
  SENSOR_SET_SEARCH_OPTION,
  Action,
  SENSOR_SET_PAGED_SENSOR_LIST,
  SENSOR_SET_SELECTED_SENSOR,
} from './types';

export function sensorReducer(state: SensorState, action: Action): SensorState {
  switch (action.type) {
    case SENSOR_SET_SEARCH_OPTION:
      return {
        ...state,
        sensorSearchOption: action.sensorSearchOption,
      };
    case SENSOR_SET_PAGED_SENSOR_LIST:
      return {
        ...state,
        pagedSensorList: action.pagedSensorList,
      };
    case SENSOR_SET_SELECTED_SENSOR:
      return {
        ...state,
        selectedSensor: action.selectedSensor,
      };
  }
}
