import React from 'react';
import { Map } from 'ol';
import MonitoringAppEndPointUserDrawing from '@/components/monitoring/MonitoringAppEndPointUserDrawing';
import MonitoringLidarDrawing from '@/components/monitoring/MonitoringLidarDrawing';

type MonitoringOpenLayersMapProps = {
  map: Map | null;
};

function MonitoringOpenLayersMap({ map }: MonitoringOpenLayersMapProps) {
  return (
    <>
      <MonitoringAppEndPointUserDrawing map={map} />
      <MonitoringLidarDrawing map={map} />
    </>
  );
}

export default React.memo(MonitoringOpenLayersMap);
