import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ZoomControlProps = {
  onClickPlus: () => void;
  onClickMinus: () => void;
};

function ZoomControl({ onClickPlus, onClickMinus }: ZoomControlProps) {
  return (
    <div className="btn-group-vertical">
      <a className="widget-btn" onClick={onClickPlus}>
        <FontAwesomeIcon icon={['fas', 'plus']} />
      </a>
      <a className="widget-btn" onClick={onClickMinus}>
        <FontAwesomeIcon icon={['fas', 'minus']} />
      </a>
    </div>
  );
}

export default ZoomControl;
