import {
  useHomeBuilding,
  useHomeFloor,
  useHomeProject,
} from '@/modules/home/hook';
import React, { ReactElement, useState } from 'react';
import AssetLeftPaneHeader from './AssetLeftPaneHeader';
import AssetLeftPanePagination from './AssetLeftPanePagination';
import AssetLeftPaneSearchBar from './AssetLeftPaneSearchBar';
import AssetLeftPaneTable from './AssetLeftPaneTable';

function AssetLeftPane(): ReactElement {
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      {project && building && floor && (
        <>
          <AssetLeftPaneHeader />
          <AssetLeftPaneSearchBar setLoading={setLoading} />
          <AssetLeftPaneTable loading={loading} />
          <AssetLeftPanePagination />
        </>
      )}
    </>
  );
}

export default AssetLeftPane;
