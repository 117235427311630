import React, { useEffect, useRef, useState } from 'react';
import ProjectInfoRegister from '@/components/project/ProjectInfoRegister';
import { useProjectRegister } from '@/modules/project/hook';
import ProjectBuilding from '@/components/project/ProjectBuilding';
import { fetchSpaceInfo } from '@/api/space';
import { fetchProject } from '@/api/project';
import { ConnectedSpaceInfo } from '@/modules/space/types';
import ProjectFloorPlan from '@/components/project/ProjectFloorPlan';
import { useParams } from 'react-router';
import { ProjectDetail, ProjectSpace } from '@/modules/project/types';
import { ParamsProjectRoute } from '@/modules/param/types';
import AssetRegisterTab from './AssetRegisterTab';
import { useOpenLayers } from '@/modules/project/hook';

let curStep = 0;
function ProjectRegister() {
  const { project_id: paramProjectId } = useParams<ParamsProjectRoute>();
  const { map } = useOpenLayers();
  const [loadProject, setLoadProject] = useState(false);
  const [loadSpaceInfoList, setLoadSpaceInfoList] = useState(false);
  const [spaceInfoList, setSpaceInfoList] = useState<ConnectedSpaceInfo[]>([]);
  const {
    projectInfo: { projectId },
    registerStep,
    handleChangeRegisterStep,
    handleSetProjectInfo,
    handleSetRegisterInitialState,
  } = useProjectRegister();
  const firstBuildingRef = useRef<ProjectSpace | null>(null);

  useEffect(() => {
    if (paramProjectId && !projectId) {
      handleFetchProject(paramProjectId);
    } else if (!paramProjectId && projectId) {
      handleFetchSpaceInfoList();
      setLoadProject(true);
    } else {
      setLoadProject(true);
    }
  }, [paramProjectId, projectId]);

  useEffect(() => {
    return () => {
      handleSetRegisterInitialState();
    };
  }, []);

  useEffect(() => {
    curStep = registerStep;
  }, [registerStep]);

  useEffect(() => {
    const firstBuilding = firstBuildingRef.current;
    if (map && firstBuilding) {
      const { lng, lat } = firstBuilding;
      const view = map.getView();
      if (view) {
        view.setCenter([lng, lat]);
        view.setZoom(13);
      }
    }
  }, [map, firstBuildingRef.current]);

  const handleFetchProject = async (projectId: string) => {
    const project = await fetchProject(projectId);
    if (project) {
      const {
        projectId,
        projectName,
        note,
        imgId,
        projectImgFileName,
        produceEndFlag,
        produceStep,
        onboarding,
        buildings,
        positioningType,
        viewType,
      } = project;

      handleSetProjectInfo({
        projectId,
        projectName,
        note,
        imgId,
        projectImgFileName,
        produceEndFlag,
        produceStepData: produceStep,
        onboardingData: onboarding,
        positioningType,
        viewType,
      });

      if (buildings.length) {
        firstBuildingRef.current = buildings[0];
      }

      produceStep?.step && handleChangeRegisterStep(produceStep.step);
      handleProcessSpaceInfoList(project);
      setLoadProject(true);
    }
  };

  const handleFetchSpaceInfoList = async () => {
    if (projectId) {
      const project = await fetchProject(projectId);
      if (project) {
        handleProcessSpaceInfoList(project);
      }
    }
  };

  const handleProcessSpaceInfoList = async (project: ProjectDetail) => {
    setLoadSpaceInfoList(false);
    if (project) {
      const list: ConnectedSpaceInfo[] = [];
      for (const { metaId, mappingId } of project.buildings) {
        const spaceInfo = await fetchSpaceInfo(metaId);
        if (spaceInfo) {
          list.push({
            ...spaceInfo,
            mappingId,
          });
        }
      }
      setSpaceInfoList(list);
    }
    setLoadSpaceInfoList(true);
  };

  if (!loadProject) {
    return <></>;
  }

  return (
    <>
      {registerStep === 1 && <ProjectInfoRegister />}
      {registerStep === 2 && (
        <ProjectBuilding
          loadSpaceInfoList={loadSpaceInfoList}
          spaceInfoList={spaceInfoList}
          onReloadSpaceInfoList={handleFetchSpaceInfoList}
        />
      )}
      {registerStep === 3 && (
        <ProjectFloorPlan
          loadSpaceInfoList={loadSpaceInfoList}
          spaceInfoList={spaceInfoList}
        />
      )}
      {registerStep === 4 && <AssetRegisterTab />}
    </>
  );
}

export default ProjectRegister;
