import { Feature } from 'ol';

export const FLOOR_OBJECT_TYPE_GEOFENCE = 'GEOFENCE';
export const FLOOR_OBJECT_TYPE_AREA = 'AREA';

export type FloorObjectType =
  | typeof FLOOR_OBJECT_TYPE_GEOFENCE
  | typeof FLOOR_OBJECT_TYPE_AREA;

export type AreaInfo = {
  id: string;
  areaname: string;
  geomstr: string;
  feature: Feature | null;
};

export type GeofenceInfo = {
  zone_id: string;
  zone_name: string;
  geomstr: string;
  area_color: string;
  line_color: string;
  feature: Feature | null;
};

export type FloorState = {
  areaList: AreaInfo[];
  geofenceList: GeofenceInfo[];
};

export const FLOOR_SET_AREA_LIST = 'FLOOR/SET_AREA_LIST' as const;
export const FLOOR_SET_GEOFENCE_LIST = 'FLOOR/SET_GEOFENCE_LIST' as const;

export type Action =
  | {
      type: typeof FLOOR_SET_AREA_LIST;
      areaList: AreaInfo[];
    }
  | {
      type: typeof FLOOR_SET_GEOFENCE_LIST;
      geofenceList: GeofenceInfo[];
    };
