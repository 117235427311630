import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LeftPaneBreadcrumb from '@/components/common/LeftPaneBreadcrumb';
import Pagination from '@/components/common/Pagination';
import Preloader from '@/components/common/Preloader';
import { useHomeSelected } from '@/modules/home/hook';
import MaterialIcon from '@/components/common/MaterialIcon';
import classNames from 'classnames';
import { fetchCampaigns } from '@/api/campaign';
import {
  Campaign,
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_INACTIVE,
  CAMPAIGN_STATUS_SCHEDULE,
} from '@/modules/campaign/types';
import moment from 'moment';
import CampaignRegisterModal from '@/components/campaign/CampaignRegisterModal';
import CampaignDetailModal from '@/components/campaign/CampaignDetailModal';
import CampaignEditModal from '@/components/campaign/CampaignEditModal';
import { useInfoPane } from '@/modules/setup/hook';
import { INFO_PANE_CAMPAIGN_REGISTER } from '@/modules/setup/types';
import {
  useOpenLayersDraw,
  useOpenLayersEvent,
  useOpenLayersResource,
} from '@/modules/openlayers/hook';
import GeometryType from 'ol/geom/GeometryType';
import { Feature } from 'ol';
import { GEOMETRY_TYPE_CIRCLE } from '@/utils/constants/common';
import { Circle } from 'ol/geom';
import EventFilter from '@/components/common/EventFilter';
import { EventStatus } from '@/modules/event/types';
import TableEmptyRow from '@/components/common/TableEmptyRow';
import { WKT } from 'ol/format';
import { Select } from 'ol/interaction';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function CampaignList(): ReactElement {
  const { t } = useTranslation();
  const { project, building, floor } = useHomeSelected();
  const { map } = useOpenLayersResource();
  const { drawLayer, handleDrawGeometry } = useOpenLayersDraw();
  const { getCampaignStyle, getEndedEventStyle } = useOpenLayersEvent();
  const { show: showInfo, handleChangeShow } = useInfoPane();
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [filterEventStatusList, setFilterEventStatusList] = useState<
    EventStatus[]
  >([]);
  const [campaignList, setCampaignList] = useState<Campaign[]>([]);
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [feature, setFeature] = useState<Feature | null>(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [clickedFeatureId, setClickedFeatureId] = useState('');
  const mountedRef = useRef(false);

  useEffect(() => {
    return () => handleChangeShow(false);
  }, []);

  useEffect(() => {
    let select: Select;
    if (drawLayer && map) {
      select = new Select({
        layers: [drawLayer],
        style: getCampaignStyle(false),
      });
      select.getFeatures().on('add', (e) => {
        const feature = e.element as Feature;
        feature && setClickedFeatureId(String(feature.getId()));
        select.getFeatures().clear();
      });
      map.addInteraction(select);
    }

    return () => {
      handleClearDrawLayer();
      map && select && map.removeInteraction(select);
    };
  }, [map, drawLayer]);

  useEffect(() => {
    if (!showInfo) {
      if (feature) {
        setFeature(feature.clone());
      } else {
        handleAllDisplayFeature();
      }
    }
  }, [showInfo]);

  useEffect(() => {
    if (showDetailModal) {
      handleClearDrawLayer();
    } else if (!showDetailModal && !showRegisterModal && !showEditModal) {
      handleAllDisplayFeature();
    }
  }, [showDetailModal, showRegisterModal, showEditModal]);

  useEffect(() => {
    handleClearDrawLayer();
    handleFetchCampaignList();
  }, [page, floor, filterEventStatusList]);

  useEffect(() => {
    if (drawLayer) {
      handleAllDisplayFeature();
    }
  }, [drawLayer, campaignList]);

  const handleClearDrawLayer = () => {
    drawLayer?.getSource().clear();
  };

  const handleFetchCampaignList = async () => {
    setLoad(false);
    if (project && building && floor) {
      const data = await fetchCampaigns({
        page,
        projectId: project.projectId,
        mappingId: building.mappingId,
        mapId: floor.mapId,
        showType2: filterEventStatusList.join(','),
      });

      if (data) {
        setTotalCount(data.totalElements);
        setCampaignList(data.content);
      }
    }
    setLoad(true);
    if (mountedRef.current) {
      setReload(!reload);
    } else {
      mountedRef.current = true;
    }
  };

  const handleClickItem = (campaign: Campaign) => {
    setCampaign(campaign);
    setShowDetailModal(true);
  };

  const handleClickRegister = () => {
    handleDrawGeometry(GeometryType.CIRCLE, (feature) => {
      setFeature(feature);
      handleChangeShow(false);
      if (feature) {
        setShowRegisterModal(true);
      }
    });
    handleChangeShow(true, INFO_PANE_CAMPAIGN_REGISTER);
  };

  const handleClickEdit = () => {
    if (campaign) {
      if (campaign.geom) {
        if (
          campaign.geomType === GEOMETRY_TYPE_CIRCLE &&
          campaign.lng &&
          campaign.lat &&
          campaign.radius
        ) {
          setFeature(
            new Feature(
              new Circle([campaign.lng, campaign.lat], campaign.radius)
            )
          );
        }
      }
      setShowEditModal(true);
    }
  };

  const handleRedrawFeature = () => {
    handleDrawGeometry(GeometryType.CIRCLE, (redrawFeature) => {
      if (redrawFeature) {
        setFeature(redrawFeature);
      }
      handleChangeShow(false);
    });
    handleChangeShow(true, INFO_PANE_CAMPAIGN_REGISTER);
  };

  const handleAllDisplayFeature = () => {
    campaignList.forEach((campaign) => {
      handleDisplayFeature(campaign);
    });
  };

  const handleDisplayFeature = (
    { eventId, geom, eventStatus }: Campaign,
    isSelected = false
  ) => {
    const source = drawLayer?.getSource();
    if (source) {
      const feature = source.getFeatureById(eventId);
      if (feature) {
        eventStatus === CAMPAIGN_STATUS_INACTIVE
          ? feature.setStyle(getEndedEventStyle(isSelected))
          : feature.setStyle(getCampaignStyle(isSelected));
      } else {
        const drawFeature = geom && new WKT().readFeature(geom);
        if (drawFeature) {
          drawFeature.setId(eventId);
          eventStatus === CAMPAIGN_STATUS_INACTIVE
            ? drawFeature.setStyle(getEndedEventStyle(isSelected))
            : drawFeature.setStyle(getCampaignStyle(isSelected));
          source.addFeature(drawFeature);
        }
      }
    }
  };

  return (
    <>
      <div className="container-fluid border-bottom-1 py-4">
        <LeftPaneBreadcrumb />
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <h3 className="mb-0">{t('캠페인 관리')}</h3>
          </div>
        </div>
      </div>
      <EventFilter
        eventMainCategory={'POSITION'}
        filterEventStatusList={filterEventStatusList}
        reload={reload}
        showSearch={false}
        onChangeFilterEventStatusList={setFilterEventStatusList}
      />
      <div className="table-bumper">
        <table className="table table-high table-line-none table-sticky mb-0">
          <colgroup>
            <col width="40%" />
            <col width="30%" />
            <col width="30%" />
          </colgroup>
          <thead>
            <tr>
              <th className="text-center">{t('캠페인명')}</th>
              <th className="text-center">{t('기간')}</th>
              <th className="text-center">{t('상태')}</th>
            </tr>
          </thead>
          <tbody>
            {!load && (
              <tr>
                <td colSpan={3}>
                  <Preloader />
                </td>
              </tr>
            )}
            {load && (
              <>
                {!campaignList.length && (
                  <TableEmptyRow
                    colSpan={3}
                    textKey={'등록된 캠페인이 없습니다.'}
                  />
                )}
                {campaignList.map((campaign) => (
                  <CampaignItem
                    key={campaign.eventId}
                    {...campaign}
                    clickedFeatureId={clickedFeatureId}
                    onInitClickedFeatureId={(selected) => {
                      handleDisplayFeature(campaign, selected);
                      setClickedFeatureId('');
                    }}
                    onClickItem={() => handleClickItem(campaign)}
                    onClickTarget={(selected) =>
                      handleDisplayFeature(campaign, selected)
                    }
                  />
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      <Pagination page={page} totalCount={totalCount} onChangePage={setPage} />
      <div className="pub-con">
        <div className="pub-inner">
          <div className="btn-wrap">
            <a className="btn btn-accent w-100" onClick={handleClickRegister}>
              {t('캠페인 등록')}
            </a>
          </div>
        </div>
      </div>
      {showDetailModal && campaign && (
        <CampaignDetailModal
          show={showDetailModal}
          campaign={campaign}
          onClickEdit={handleClickEdit}
          onHide={() => setShowDetailModal(false)}
          onReload={() => handleFetchCampaignList()}
          onDelete={() => handleClearDrawLayer()}
        />
      )}
      {showRegisterModal && feature && (
        <CampaignRegisterModal
          show={showRegisterModal}
          feature={feature}
          onChangeFeature={handleRedrawFeature}
          onHide={() => {
            drawLayer?.getSource().clear();
            setShowRegisterModal(false);
          }}
          onReload={() => handleFetchCampaignList()}
        />
      )}
      {showEditModal && campaign && feature && (
        <CampaignEditModal
          show={showEditModal}
          campaign={campaign}
          feature={feature}
          onChangeFeature={handleRedrawFeature}
          onHide={() => {
            drawLayer?.getSource().clear();
            setShowEditModal(false);
          }}
          onReload={() => handleFetchCampaignList()}
        />
      )}
    </>
  );
}

type CampaignItemProps = Campaign & {
  clickedFeatureId: string;
  onInitClickedFeatureId: (selected: boolean) => void;
  onClickItem: () => void;
  onClickTarget: (selected: boolean) => void;
};

function CampaignItem({
  eventId,
  eventTitle,
  eventStatus,
  startDate,
  endDate,
  activeFlag,
  clickedFeatureId,
  onInitClickedFeatureId,
  onClickItem,
  onClickTarget,
}: CampaignItemProps) {
  const { t } = useTranslation();
  const [isSelected, setSelected] = useState(false);

  useEffect(() => {
    if (clickedFeatureId && eventId === clickedFeatureId) {
      onInitClickedFeatureId(!isSelected);
      setSelected(!isSelected);
    }
  }),
    [clickedFeatureId];

  return (
    <tr
      className={classNames({
        'text-40': !activeFlag,
        selected: isSelected,
      })}
    >
      <td width="37%">
        <a className="d-inline-flex" onClick={onClickItem}>
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="event-title">{eventTitle}</Tooltip>}
          >
            <span className="ellipsis">{eventTitle}</span>
          </OverlayTrigger>
        </a>
      </td>
      <td width="33%">{`${moment(startDate).format('YYYY.MM.DD')} ~ ${moment(
        endDate
      ).format('YYYY.MM.DD')}`}</td>
      <td className="text-center" width="30%">
        <ul className="state-item">
          <li
            className={classNames('arry01', {
              'text-identity': activeFlag,
            })}
          >
            {eventStatus === CAMPAIGN_STATUS_ACTIVE && t('진행중')}
            {eventStatus === CAMPAIGN_STATUS_INACTIVE && t('종료됨')}
            {eventStatus === CAMPAIGN_STATUS_SCHEDULE && t('예정')}
          </li>
          <li className="arry02">
            <a
              className="d-flex"
              onClick={() => {
                onClickTarget(!isSelected);
                setSelected(!isSelected);
              }}
            >
              <MaterialIcon
                name={'place'}
                className={
                  isSelected
                    ? 'material-icons-sharp'
                    : 'material-icons-outlined'
                }
              />
            </a>
          </li>
        </ul>
      </td>
    </tr>
  );
}

export default CampaignList;
