import React, { useEffect, useState } from 'react';
import MainContainer from '@/components/common/MainContainer';
import TopNavbar from '@/components/common/TopNavbar';
import StatusNavbar from '@/components/common/StatusNavbar';
import LayoutContainer from '@/components/common/LayoutContainer';
import HomeProjectSelect from '@/components/home/HomeProjectSelect';
import { useUser } from '@/modules/user/hook';
import LayoutColumn from '@/components/common/LayoutColumn';
import LeftSidebar from '@/components/common/LeftSidebar';
import {
  useHomeBuilding,
  useHomeFloor,
  useHomeMenu,
  useHomeProject,
} from '@/modules/home/hook';
import LeftMenu from '@/components/common/LeftMenu';
import LayoutColumnGroup from '@/components/common/LayoutColumnGroup';
import HomeContent from '@/components/home/HomeContent';
import AlertModal from '@/components/common/modal/AlertModal';
import { useTranslation } from 'react-i18next';
import {
  MENU_IDX_ACCOUNT,
  MENU_IDX_DASHBOARD,
  MENU_IDX_GROUP_SETTINGS_GROUP,
  MENU_IDX_GROUP_SETTINGS_USER,
  MENU_IDX_MONITORING,
  MENU_IDX_STATISTICS,
  MENU_MAPPING,
  MenuIdx,
  MenuName,
} from '@/modules/setup/types';
import { useParams } from 'react-router';
import { ParamsHomeRoute } from '@/modules/param/types';
import { useHistory } from 'react-router-dom';
import RightPane from '@/components/common/RightPane';
import LeftExpandMenu from '@/components/common/LeftExpandMenu';
import InfoPane from '@/components/common/InfoPane';
import { CommonUtils } from '@/utils';
import SidebarButton from '@/components/common/SidebarButton';
import { useLeftPane } from '@/modules/setup/hook';

function Home() {
  const {
    menu_name: paramMenuName,
    project_id: paramProjectId,
    mapping_id: paramMappingId,
    map_id: paramMapId,
  } = useParams<ParamsHomeRoute>();
  const { t } = useTranslation();
  const { user } = useUser();
  const history = useHistory();
  const {
    project,
    handleChangeProject: handleChangeMonitoringProject,
  } = useHomeProject();
  const { building, handleChangeBuilding } = useHomeBuilding();
  const { floor, handleChangeFloor } = useHomeFloor();
  const { menus, activeMenu, activeMenuIdx, handleSetMenu } = useHomeMenu();
  const { show: showLeftPane } = useLeftPane();
  const [showMenuLeftPane, setShowMenuLeftPane] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    return () => handleSetMenu(MENU_IDX_DASHBOARD);
  }, []);

  useEffect(() => {
    if (user.userId) {
      let menuIdx: MenuIdx = MENU_IDX_DASHBOARD;
      if (
        activeMenuIdx === MENU_IDX_DASHBOARD ||
        activeMenuIdx === MENU_IDX_ACCOUNT ||
        activeMenuIdx === MENU_IDX_GROUP_SETTINGS_USER ||
        activeMenuIdx === MENU_IDX_GROUP_SETTINGS_GROUP
      ) {
        menuIdx = activeMenuIdx;
      }
      handleChangeMonitoringProject(paramProjectId, menuIdx);
    }
  }, [user, paramProjectId]);

  useEffect(() => {
    if (project && paramMappingId) {
      const building = project.buildings.find(
        ({ mappingId }) => mappingId === paramMappingId
      );
      if (building) {
        handleChangeBuilding(building);
      } else {
        handleRouteHome('replace');
      }
    }
  }, [project, paramMappingId]);

  useEffect(() => {
    if (building && paramMapId) {
      const floor = building.floors.find(({ mapId }) => mapId === paramMapId);
      if (floor) {
        handleChangeFloor(floor);
      } else {
        handleRouteHome('replace');
      }
    }
  }, [building, paramMapId]);

  useEffect(() => {
    if (activeMenu && activeMenu.menuIdx !== MENU_IDX_DASHBOARD) {
      const menuName = CommonUtils.getKeyByValue(
        MENU_MAPPING,
        activeMenu.menuIdx
      );

      menuName &&
        project &&
        building &&
        floor &&
        history.push(
          `/${menuName}/${project.projectId}/${building.mappingId}/${floor.mapId}`
        );
    }
  }, [project, building, floor, activeMenu]);

  useEffect(() => {
    const menuName = paramMenuName as MenuName;
    const isExistMenu = MENU_MAPPING.hasOwnProperty(menuName);
    if (isExistMenu) {
      const menuIdx = MENU_MAPPING[menuName];
      if (menuIdx !== MENU_IDX_STATISTICS) {
        handleSetMenu(menuIdx);
      }
    } else {
      handleChangeBuilding(null);
    }
  }, [paramMenuName]);

  useEffect(() => {
    let isShow = false;
    activeMenu && (isShow = activeMenu.showLeftPane);
    setShowMenuLeftPane(isShow);
  }, [activeMenu]);

  const handleClickMenu = (
    menuIdx: MenuIdx,
    outer: boolean,
    needProject: boolean
  ) => {
    if (needProject && !project) {
      setShowAlertModal(true);
    } else {
      handleSetMenu(menuIdx);
      if (outer) {
        handleRouteHome('push');
      }
    }
  };

  const handleRouteHome = (method: 'replace' | 'push') => {
    history[method]('/home');
  };

  if (user.userId) {
    return (
      <>
        <MainContainer>
          <LayoutContainer position={'top'}>
            <TopNavbar home={true}>
              <div className="project-select">
                {project && <HomeProjectSelect />}
              </div>
              <StatusNavbar />
            </TopNavbar>
          </LayoutContainer>
          <LayoutContainer position={'cover'}>
            <LayoutColumn show={showLeftPane} align={'left'}>
              <LeftSidebar
                showPane={
                  Boolean(project && (!building || !floor))
                    ? true
                    : showMenuLeftPane
                }
                leftPaneMenuIdx={activeMenu?.menuIdx}
              >
                {menus.map(
                  ({
                    menuIdx,
                    titleKey,
                    iconName,
                    needProject,
                    subMenus,
                    inside,
                    outer,
                  }) => {
                    if (
                      outer &&
                      !(outer && inside) &&
                      project &&
                      building &&
                      floor
                    ) {
                      return <React.Fragment key={menuIdx} />;
                    }

                    if (
                      inside &&
                      !(outer && inside) &&
                      (!project || !building || !floor)
                    ) {
                      return <React.Fragment key={menuIdx} />;
                    }

                    if (subMenus.length) {
                      return (
                        <LeftExpandMenu
                          key={menuIdx}
                          titleKey={titleKey}
                          iconName={iconName}
                          subMenuList={subMenus}
                          onClick={(clickMenuIdx: MenuIdx) =>
                            handleClickMenu(clickMenuIdx, outer, needProject)
                          }
                        />
                      );
                    } else {
                      return (
                        <LeftMenu
                          key={menuIdx}
                          titleKey={titleKey}
                          iconName={iconName}
                          active={menuIdx === activeMenu?.menuIdx}
                          onClick={() =>
                            handleClickMenu(menuIdx, outer, needProject)
                          }
                        />
                      );
                    }
                  }
                )}
              </LeftSidebar>
            </LayoutColumn>
            <LayoutColumnGroup>
              <LayoutColumn align={'mid'}>
                <HomeContent />
                <InfoPane />
                {project && building && floor && (
                  <SidebarButton
                    showLeftSidebarButton={
                      activeMenuIdx === MENU_IDX_MONITORING
                    }
                    showRightSidebarButton={
                      activeMenuIdx === MENU_IDX_MONITORING
                    }
                  />
                )}
              </LayoutColumn>
              {project && building && floor && <RightPane />}
            </LayoutColumnGroup>
          </LayoutContainer>
        </MainContainer>
        <AlertModal
          show={showAlertModal}
          onHide={() => setShowAlertModal(false)}
        >
          {t('프로젝트를 선택해 주세요.')}
        </AlertModal>
      </>
    );
  }

  return <></>;
}

export default Home;
