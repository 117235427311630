import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import MaterialIcon from '@/components/common/MaterialIcon';
import { useTranslation } from 'react-i18next';

type TermModalProps = {
  show: boolean;
  title: string;
  content: string;
  positiveButton?: boolean;
  positiveButtonText?: string;
  onPositiveButtonClick?: () => void;
  negativeButton?: boolean;
  negativeButtonText?: string;
  onNegativeButtonClick?: () => void;
  onHide?: () => void;
};

function TermModal(props: TermModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      show={props.show}
      centered={true}
      onHide={props.onHide}
      dialogClassName={'col-mg-wide'}
    >
      <ModalHeader>
        <ModalTitle as={'h5'}>{props.title}</ModalTitle>
        <button type="button" className="close" onClick={props.onHide}>
          <MaterialIcon name={'clear'} />
        </button>
      </ModalHeader>
      <ModalBody>
        <div
          className={'position-relative p-3 overflow-auto'}
          style={{ height: '600px' }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: props.content,
            }}
          ></div>
        </div>
      </ModalBody>
      {(props.positiveButton || props.negativeButton) && (
        <ModalFooter>
          {props.positiveButton && (
            <button
              type="button"
              className="btn btn-accent"
              onClick={props.onPositiveButtonClick}
            >
              {props.positiveButtonText || t('확인')}
            </button>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
}

export default TermModal;
