import React, { ReactElement } from 'react';
import VisitStatus from '@/components/statistics/StatisticsContent/VisitStatus';

function StatisticsContent(): ReactElement {
  return (
    <div className="contents-section">
      <div className="cell">
        <VisitStatus />
      </div>
    </div>
  );
}

export default StatisticsContent;
