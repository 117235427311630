import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import Map from 'ol/Map';
import { DragPan, DragRotate } from 'ol/interaction';
import { mouseOnly } from 'ol/events/condition';

type RotateControlProps = {
  map: Map;
};

function RotateControl({ map }: RotateControlProps) {
  const [isRotate, setRotate] = useState(false);
  const dragRotateRef = useRef<DragRotate>(
    new DragRotate({
      condition: mouseOnly,
    })
  );

  const handleClickControl = () => {
    const dragRotate = dragRotateRef.current;
    const dragPan = map
      .getInteractions()
      .getArray()
      .find((interaction) => interaction instanceof DragPan);
    dragPan && dragPan.setActive(isRotate);
    if (!isRotate) {
      map.addInteraction(dragRotate);
    } else {
      map.removeInteraction(dragRotate);
    }
    setRotate(!isRotate);
  };

  return (
    <a
      className={classNames('widget-btn', {
        active: isRotate,
      })}
      onClick={handleClickControl}
    >
      <img
        src={
          isRotate
            ? '/static/images/icon/map_rotate_white.svg'
            : '/static/images/icon/map_rotate.svg'
        }
      />
    </a>
  );
}

export default RotateControl;
