import LeftPaneBreadcrumb from '@/components/common/LeftPaneBreadcrumb';
import {
  useHomeBuilding,
  useHomeFloor,
  useHomeProject,
} from '@/modules/home/hook';
import {
  useProjectRegister,
  useSelectedBuilding,
  useSelectedFloorPlanId,
} from '@/modules/project/hook';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

function AssetLeftPaneHeader(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const { handleChangeRegisterStep } = useProjectRegister();
  const { setSelectedBuilding } = useSelectedBuilding();
  const { setSelectedFloorPlanId } = useSelectedFloorPlanId();

  return (
    <div className="container-fluid border-bottom-1 py-4">
      <div className="mb-3">
        <LeftPaneBreadcrumb showFloor />
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <h3 className="mb-0">{t('자산 현황')}</h3>
        </div>
        <a
          className="btn-add-item text-identity ml-auto mr-n3"
          onClick={() => {
            handleChangeRegisterStep(4);
            building &&
              setSelectedBuilding({
                mappingId: building.mappingId,
                spaceId: building.metaId,
                spaceName: building.metaName,
                lng: building.lng,
                lat: building.lat,
                // Todo: 실제 countryCode로 수정 필요
                countryCode: 81,
                country: '',
                city: '',
                address: '',
                floorCnt: 0,
                floorList: [],
              });
            floor && setSelectedFloorPlanId(floor.mapId);
            history.push(`/project/${project?.projectId}`);
          }}
        >
          <span className="material-icons">add</span>
          {t('자산 등록')}
        </a>
      </div>
    </div>
  );
}

export default AssetLeftPaneHeader;
