export const STAT_RANK_VISIT = 'STAT_RANK_VISIT';
export const STAT_RANK_STAY = 'STAT_RANK_STAY';

export type StatAppVisitStatus = {
  hour: string;
  countAverage: number;
  todayCount: number;
};

export type StatAppRankStatus = {
  areaName: string;
  totalCount: number;
  residenceAverage: number;
};

export type StatLidarForkliftOperationStatus = {
  hour: string;
  operationCount: number;
  overSpeedCount: number;
};

export type StatLidarVisitCount = {
  hour: string;
  visitorCount: number;
};

export type StatLidarVisitStatus = {
  inCount: number;
  outCount: number;
  overSpeedCount: number;
  crashCount: number;
};

export type StatLidarVisitStatusByHour = {
  hour: string;
  inCount: number;
  outCount: number;
};
