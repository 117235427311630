import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FUNCTION_DELETE,
  FUNCTION_EDIT,
  PANE_STATUS_EDIT,
  PANE_STATUS_PROJECT_CONNECT_TO_SPACE,
  PANE_STATUS_REGISTER,
  PaneStatus,
} from '@/utils/constants/common';
import ProjectRegisterHeader from '@/components/project/ProjectRegisterHeader';
import ListDropdown from '@/components/common/ListDropdown';
import Preloader from '@/components/common/Preloader';
import { deleteProjectDisconnectSpace } from '@/api/project';
import { useOpenLayers, useProjectRegister } from '@/modules/project/hook';
import NewlineText from '@/components/common/NewlineText';
import AlertModal from '@/components/common/modal/AlertModal';
import { ConnectedSpaceInfo } from '@/modules/space/types';
import classNames from 'classnames';
import { Feature } from 'ol';
import { Icon, Style } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { Point } from 'ol/geom';

const iconFeature = new Feature();
iconFeature.setId('building_marker');
const iconStyle = new Style({
  image: new Icon({
    src: '/static/images/ic-marker-24-px.svg',
    anchor: [0.5, 45], // 0.5 default, 45 image height
    anchorXUnits: IconAnchorUnits.FRACTION,
    anchorYUnits: IconAnchorUnits.PIXELS,
  }),
});
iconFeature.setStyle(iconStyle);

type ProjectBuildingListProps = {
  loadSpaceInfoList: boolean;
  spaceInfoList: ConnectedSpaceInfo[];
  onReloadSpaceInfoList: () => void;
  onChangeSpaceInfo: (spaceInfo: ConnectedSpaceInfo) => void;
  onChangeStatus: (status: PaneStatus) => void;
};

function ProjectBuildingList({
  loadSpaceInfoList,
  spaceInfoList,
  onReloadSpaceInfoList,
  onChangeSpaceInfo,
  onChangeStatus,
}: ProjectBuildingListProps) {
  const { t } = useTranslation();
  const {
    projectInfo: { projectId },
  } = useProjectRegister();
  const { map, drawSource } = useOpenLayers();
  const [clickedSpaceId, setClickedSpaceId] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    return () => {
      drawSource?.clear();
    };
  }, []);

  const handleClickBuildingConnect = () => {
    onChangeStatus(PANE_STATUS_PROJECT_CONNECT_TO_SPACE);
  };

  const handleClickBuildingRegister = () => {
    onChangeStatus(PANE_STATUS_REGISTER);
  };

  const handleClickItem = ({ spaceId, lng, lat }: ConnectedSpaceInfo) => {
    if (clickedSpaceId !== spaceId) {
      setClickedSpaceId(spaceId);
      const coordinates = [lng, lat];
      iconFeature.setGeometry(new Point(coordinates));
      map?.getView().setCenter(coordinates);
      map?.getView().setZoom(13);

      !drawSource?.getFeatureById('building_marker') &&
        drawSource?.addFeature(iconFeature);
    } else {
      drawSource?.removeFeature(iconFeature);
      setClickedSpaceId('');
    }
  };

  const handleClickEdit = (spaceInfo: ConnectedSpaceInfo) => {
    onChangeSpaceInfo(spaceInfo);
    onChangeStatus(PANE_STATUS_EDIT);
  };

  const handleClickDisconnect = async (mappingId: string, spaceId: string) => {
    if (projectId) {
      const result = await deleteProjectDisconnectSpace(projectId, mappingId);
      if (result) {
        onReloadSpaceInfoList();
        if (spaceId === clickedSpaceId) {
          drawSource?.removeFeature(iconFeature);
        }
      } else {
        setShowAlertModal(true);
      }
    }
  };

  return (
    <>
      <ProjectRegisterHeader />
      <div className="plf-floor-add">
        <div className="btn-cell">
          <a className="btn-add-item" onClick={handleClickBuildingConnect}>
            <span className="material-icons">import_export</span>
            {t('건물 가져오기')}
          </a>
          <a
            className="btn-add-item text-identity"
            onClick={handleClickBuildingRegister}
          >
            <span className="material-icons">add</span>
            {t('새 건물 등록')}
          </a>
        </div>
      </div>
      <div className="bg-light bg-light-h-100">
        <div className="plf-floor-card p-4 position-relative">
          {!loadSpaceInfoList && <Preloader />}
          {loadSpaceInfoList && (
            <>
              {spaceInfoList.length === 0 && (
                <a
                  className="plf-area-card add-item"
                  onClick={handleClickBuildingRegister}
                >
                  <p>{t('건물 정보를 등록해 주세요.')}</p>
                </a>
              )}
              {spaceInfoList.map((spaceInfo) => (
                <ProjectBuildingItem
                  key={spaceInfo.spaceId}
                  clickedSpaceId={clickedSpaceId}
                  onClickItem={() => handleClickItem(spaceInfo)}
                  spaceInfo={spaceInfo}
                  onClickEdit={() => handleClickEdit(spaceInfo)}
                  onClickDisconnect={() =>
                    handleClickDisconnect(
                      spaceInfo.mappingId,
                      spaceInfo.spaceId
                    )
                  }
                />
              ))}
            </>
          )}
        </div>
      </div>
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        <NewlineText text={t('서버와 통신 중 오류가 발생했습니다')} />
      </AlertModal>
    </>
  );
}

type ProjectBuildingItemProps = {
  clickedSpaceId: string;
  spaceInfo: ConnectedSpaceInfo;
  onClickItem: () => void;
  onClickEdit: () => void;
  onClickDisconnect: () => void;
};

function ProjectBuildingItem({
  clickedSpaceId,
  spaceInfo: { spaceId, spaceName, country, city, address, floorCnt },
  onClickItem,
  onClickEdit,
  onClickDisconnect,
}: ProjectBuildingItemProps) {
  const handleSelectFunction = (eventKey: string | null) => {
    switch (eventKey) {
      case FUNCTION_EDIT:
        onClickEdit();
        break;
      case FUNCTION_DELETE:
        onClickDisconnect();
        break;
    }
  };

  return (
    <div
      className={classNames('plf-area-card', {
        active: spaceId === clickedSpaceId,
      })}
      onClick={onClickItem}
    >
      <div className="plf-area-top">
        <h4>{spaceName}</h4>
        <div className="left-cell">
          <ListDropdown onSelect={handleSelectFunction} />
        </div>
      </div>
      <div className="plf-area-content">
        <p className="desc">{`${country} ${city} ${address}`}</p>
        <div className="d-flex align-items-center">
          <span className="material-icons-outlined font-size-16pt mr-1">
            map
          </span>
          {floorCnt}
        </div>
      </div>
    </div>
  );
}

export default ProjectBuildingList;
