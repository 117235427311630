import React, { ReactElement, useEffect } from 'react';

import HomeFloorSelect from '@/components/home/HomeFloorSelect';
import HomeMapOpenLayers from '@/components/home/HomeOpenLayersMap';
import {
  useHomeBuilding,
  useHomeFloor,
  useHomeMenu,
} from '@/modules/home/hook';
import { MENU_IDX_MESSAGE, MENU_IDX_STATISTICS } from '@/modules/setup/types';
import StatisticsContent from '../statistics/StatisticsContent';
import HomeMessage from '@/components/home/Message';
import { MONITORING_TYPE_LIDAR } from '@/modules/monitoring/types';
import { useMonitoring } from '@/modules/monitoring/hook';

function HomeMap(): ReactElement {
  const { activeMenuIdx } = useHomeMenu();
  const { building, handleChangeBuilding } = useHomeBuilding();
  const { floor } = useHomeFloor();
  const { handleChangeMonitoringType } = useMonitoring();
  const isSelected = Boolean(building && floor);

  useEffect(() => {
    return () => {
      handleChangeBuilding(null);
      handleChangeMonitoringType(MONITORING_TYPE_LIDAR);
    };
  }, []);

  const isStatistics = activeMenuIdx === MENU_IDX_STATISTICS ? true : false;

  return (
    <>
      {!isSelected && <HomeFloorSelect />}
      {isSelected && (
        <>
          {!isStatistics ? (
            <>
              {activeMenuIdx === MENU_IDX_MESSAGE ? (
                <HomeMessage />
              ) : (
                <HomeMapOpenLayers />
              )}
            </>
          ) : (
            <StatisticsContent />
          )}
        </>
      )}
    </>
  );
}

export default HomeMap;
