import React from 'react';

import HomeBuildingSelect from '@/components/home/HomeBuildingSelect';
import MonitoringLeftPane from '@/components/monitoring/MonitoringLeftPane';
import {
  useHomeBuilding,
  useHomeFloor,
  useHomeMenu,
  useHomeProject,
} from '@/modules/home/hook';
import {
  MENU_IDX_ACCIDENT,
  MENU_IDX_ASSETS,
  MENU_IDX_CAMPAIGN,
  MENU_IDX_EVENT,
  MENU_IDX_MONITORING,
  MENU_IDX_SENSOR,
} from '@/modules/setup/types';
import AccidentLeftPane from '@/components/accident/AccidentLeftPane';
import CampaignLeftPane from '@/components/campaign/CampaignLeftPane';
import EventLeftPane from '@/components/event/EventLeftPane';
import AssetLeftPane from './Asset/AssetLeftPane';
import SensorLeftPane from './Sensor/SensorLeftPane';

function HomeLeftPane() {
  const { activeMenuIdx } = useHomeMenu();
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { floor } = useHomeFloor();

  if (!project) {
    return <></>;
  }

  let isSelected = false;
  if (building && floor) {
    isSelected = true;
  }

  return (
    <>
      {!isSelected && <HomeBuildingSelect />}
      {isSelected && (
        <>
          {activeMenuIdx === MENU_IDX_MONITORING && <MonitoringLeftPane />}
          {activeMenuIdx === MENU_IDX_EVENT && <EventLeftPane />}
          {activeMenuIdx === MENU_IDX_CAMPAIGN && <CampaignLeftPane />}
          {activeMenuIdx === MENU_IDX_ACCIDENT && <AccidentLeftPane />}
          {activeMenuIdx === MENU_IDX_ASSETS && <AssetLeftPane />}
          {activeMenuIdx === MENU_IDX_SENSOR && <SensorLeftPane />}
        </>
      )}
    </>
  );
}

export default HomeLeftPane;
