import React, {
  RefObject,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { AsyncAction, AsyncState, PromiseFn } from '@/modules/common/types';
import {
  ASYNC_DEFAULT_STATE,
  ASYNC_ERROR_STATE,
  ASYNC_LOADING_STATE,
  ASYNC_SUCCESS_STATE,
} from '@/utils/constants/common';

export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: () => void
) => {
  const handleClick = (e: MouseEvent) => {
    const target: EventTarget | null = e.target;
    if (ref.current && target && !ref.current.contains(target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export const useDropdown = (ref: RefObject<HTMLDivElement>) => {
  const [isShowDropdown, setShowDropdown] = useState(false);

  const handleClose = () => {
    setShowDropdown(false);
  };

  const handleToggle = useCallback(
    (
      isOpen: boolean,
      event: React.SyntheticEvent<Dropdown>,
      metadata: {
        source: 'select' | 'click' | 'rootClose' | 'keydown';
      }
    ) => {
      document.body.click();
      if (event.stopPropagation) {
        event.stopPropagation();
      }

      const { current } = ref;
      if (current) {
        current.style.display = 'block';
        if (isShowDropdown) {
          current.style.display = 'none';
        }
      }

      setShowDropdown(!isShowDropdown);
    },
    [isShowDropdown]
  );

  return {
    handleToggle,
    handleClose,
    isShowDropdown,
  };
};

function asyncReducer<T>(
  state: AsyncState<T>,
  action: AsyncAction<T>
): AsyncState<T> {
  switch (action.type) {
    case 'LOADING':
      return {
        ...ASYNC_LOADING_STATE,
      };
    case 'SUCCESS':
      return {
        ...ASYNC_SUCCESS_STATE(action.data),
      };
    case 'ERROR':
      return {
        ...ASYNC_ERROR_STATE(action.error),
      };
  }
}

export function useAsync<T, F extends PromiseFn<T>>(promiseFn: F) {
  const [state, dispatch] = useReducer(asyncReducer, {
    ...ASYNC_DEFAULT_STATE,
  } as AsyncState<T>);

  async function run(...params: Parameters<F>) {
    dispatch({ type: 'LOADING' });
    try {
      const data = await promiseFn(...params);
      dispatch({
        type: 'SUCCESS',
        data,
      });
    } catch (e) {
      const error = e as Error;
      dispatch({
        type: 'ERROR',
        error,
      });
    }
  }

  return [state, run] as const;
}

export function useAsyncEffect<T, F extends PromiseFn<T>>(
  promiseFn: F,
  params: Parameters<F>,
  deps: any[]
) {
  const [state, run] = useAsync(promiseFn);
  useEffect(() => {
    run(...params);
  }, deps);

  return [state, run] as const;
}

export function useDrag() {
  const [clicked, setClicked] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);
  const position = React.useRef(0);
  const unmountedRef = useRef(false);

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const dragStart = React.useCallback((ev: React.MouseEvent) => {
    position.current = ev.clientX;
    setClicked(true);
  }, []);

  const dragStop = React.useCallback(() => {
    window.requestAnimationFrame(() => {
      const unmounted = unmountedRef.current;
      if (!unmounted) {
        setDragging(false);
        setClicked(false);
      }
    });
  }, []);

  const dragMove = (ev: React.MouseEvent, cb: (posDif: number) => void) => {
    const newDiff = position.current - ev.clientX;

    const movedEnough = Math.abs(newDiff) > 5;

    if (clicked && movedEnough) {
      setDragging(true);
    }

    if (dragging && movedEnough) {
      position.current = ev.clientX;
      cb(newDiff);
    }
  };

  return {
    dragStart,
    dragStop,
    dragMove,
    dragging,
    position,
    setDragging,
  };
}
