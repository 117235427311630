import { useCallback, useContext } from 'react';

import { PANE_STATUS_REGISTER, PaneStatus } from '@/utils/constants/common';
import {
  ProjectDispatchContext,
  ProjectStateContext,
} from '@/modules/project/context';
import {
  OpenLayers,
  PROJECT_CHANGE_EDIT_RELOAD_FLAG,
  PROJECT_CHANGE_OPEN_LAYERS_GEO_IMAGE_ADJUSTMENT,
  PROJECT_CHANGE_PANE_STATUS,
  PROJECT_CHANGE_REGISTER_SELECTED_FLOOR_ID,
  PROJECT_CHANGE_REGISTER_STEP,
  PROJECT_SET_BUILDING_INFO_LIST_STATE,
  PROJECT_SET_EDIT_INITIAL_STATE,
  PROJECT_SET_FLOOR_PLAN_LIST_STATE,
  PROJECT_SET_OPEN_LAYERS_INSTANCE,
  PROJECT_SET_REGISTER_FLOOR_LIST,
  PROJECT_SET_REGISTER_INITIAL_STATE,
  PROJECT_SET_REGISTER_ONBOARDING,
  PROJECT_SET_REGISTER_PRODUCE_STEP,
  PROJECT_SET_REGISTER_PROJECT_INFO,
  PROJECT_SET_SELECTED_BUILDING_STATE,
  PROJECT_SET_SELECTED_FLOOR_PLAN_ID_STATE,
  PROJECT_SET_UPLOADED_ASSET_LIST_STATE,
  ProjectInfo,
  ProjectOnboarding,
  ProjectProduceStep,
  PROJECT_SET_LOADING_STATE,
  PROJECT_SET_AFTER_ASSET_UPLOAD_STATE,
} from '@/modules/project/types';
import { useHistory } from 'react-router-dom';
import { ResponseFloorInfo } from '@/api/space';
import { useHomeMenu, useHomeProject } from '@/modules/home/hook';
import { MENU_IDX_MESSAGE, MENU_IDX_MONITORING } from '@/modules/setup/types';
import { useMonitoringMenu } from '@/modules/monitoring/hook';
import { AssetExcelType } from '../assets/types';
import { ConnectedSpaceInfo } from '../space/types';

function useProjectState() {
  const state = useContext(ProjectStateContext);
  if (!state) throw new Error('ProjectProvider not found');
  return state;
}

function useProjectDispatch() {
  const dispatch = useContext(ProjectDispatchContext);
  if (!dispatch) throw new Error('ProjectProvider not found');
  return dispatch;
}

export function useProjectPane() {
  const state = useProjectState();
  const dispatch = useProjectDispatch();

  const { paneStatus } = state;

  const handleChangePaneStatus = (paneStatus: PaneStatus) => {
    dispatch({
      type: PROJECT_CHANGE_PANE_STATUS,
      paneStatus,
    });
  };

  return {
    paneStatus,
    handleChangePaneStatus,
  };
}

export function useOpenLayers() {
  const state = useProjectState();
  const dispatch = useProjectDispatch();

  const {
    map,
    drawSource,
    geofencingLayer,
    draw,
    geoImage,
    transform,
  } = state.openLayers;

  const handleSetOpenLayers = (openLayers: OpenLayers) => {
    dispatch({
      type: PROJECT_SET_OPEN_LAYERS_INSTANCE,
      openLayers,
    });
  };

  const handleClearDrawSource = () => {
    drawSource?.clear();
  };

  const handleAllClear = () => {
    geoImage.remove();
  };

  const handleChangeGeoImageAdjustment = (adjustment: boolean | undefined) => {
    dispatch({
      type: PROJECT_CHANGE_OPEN_LAYERS_GEO_IMAGE_ADJUSTMENT,
      adjustment,
    });
  };

  return {
    map,
    drawSource,
    draw,
    geoImage,
    transform,
    geofencingLayer,
    handleSetOpenLayers,
    handleClearDrawSource,
    handleAllClear,
    handleChangeGeoImageAdjustment,
  };
}

export function useProjectRoute() {
  const history = useHistory();
  const { handleChangePaneStatus } = useProjectPane();
  const { activeMenuIdx, handleSetMenu } = useHomeMenu();
  const { handleChangeDirectFlag } = useMonitoringMenu();
  const { project } = useHomeProject();

  const handleRouteProject = useCallback(
    (projectId: string, edit = false) => {
      if (edit) {
        handleChangePaneStatus(PANE_STATUS_REGISTER);
        history.push(`/project/${projectId}`);
      } else {
        if (projectId === project?.projectId) {
          handleSetMenu(MENU_IDX_MONITORING);
        } else {
          handleChangeDirectFlag(true);
          let path = '/home';
          projectId && (path = `/home/${projectId}`);
          history.push(path);
        }
      }
    },
    [project, activeMenuIdx]
  );

  return {
    handleRouteProject,
  };
}

export function useProjectRegister() {
  const state = useProjectState();
  const dispatch = useProjectDispatch();

  const handleChangeRegisterStep = (registerStep: number) => {
    dispatch({
      type: PROJECT_CHANGE_REGISTER_STEP,
      registerStep,
    });
  };

  const handleSetProjectInfo = (projectInfo: ProjectInfo) => {
    dispatch({
      type: PROJECT_SET_REGISTER_PROJECT_INFO,
      projectInfo,
    });
  };

  const handleSetProduceStep = (produceStep: ProjectProduceStep) => {
    dispatch({
      type: PROJECT_SET_REGISTER_PRODUCE_STEP,
      produceStep,
    });
  };

  const handleSetOnboarding = (onboarding: ProjectOnboarding) => {
    dispatch({
      type: PROJECT_SET_REGISTER_ONBOARDING,
      onboarding,
    });
  };

  const handleChangeSelectedFloorId = (selectedFloorId: string) => {
    dispatch({
      type: PROJECT_CHANGE_REGISTER_SELECTED_FLOOR_ID,
      selectedFloorId,
    });
  };

  const handleSetFloorList = (list: ResponseFloorInfo[]) => {
    dispatch({
      type: PROJECT_SET_REGISTER_FLOOR_LIST,
      list,
    });
  };

  const handleSetRegisterInitialState = () => {
    dispatch({
      type: PROJECT_SET_REGISTER_INITIAL_STATE,
    });
  };

  const {
    step: registerStep,
    info: projectInfo,
    floor: floorInfo,
  } = state.register;

  return {
    registerStep,
    projectInfo,
    floorInfo,
    handleChangeRegisterStep,
    handleSetProjectInfo,
    handleSetRegisterInitialState,
    handleSetProduceStep,
    handleSetOnboarding,
    handleChangeSelectedFloorId,
    handleSetFloorList,
  };
}

export function useProjectEdit() {
  const state = useProjectState();
  const dispatch = useProjectDispatch();
  const { reloadFlag } = state.edit;

  const handleChangeReloadFlag = (reloadFlag: undefined | boolean) => {
    dispatch({
      type: PROJECT_CHANGE_EDIT_RELOAD_FLAG,
      reloadFlag,
    });
  };

  const handleSetEditInitialState = () => {
    dispatch({
      type: PROJECT_SET_EDIT_INITIAL_STATE,
    });
  };

  return {
    reloadFlag,
    handleChangeReloadFlag,
    handleSetEditInitialState,
  };
}

// 자산 엑셀 업로드 관련 custom hook
export const useUploadAssetListExcel = (): {
  uploadedAssetList: AssetExcelType[];
  setUploadedAssetList: (uploadedAssetList: AssetExcelType[]) => void;
} => {
  const { uploadedAssetList } = useProjectState();
  const dispatch = useProjectDispatch();

  const setUploadedAssetList = (uploadedAssetList: AssetExcelType[]) => {
    dispatch({
      type: PROJECT_SET_UPLOADED_ASSET_LIST_STATE,
      uploadedAssetList,
    });
  };

  return { uploadedAssetList, setUploadedAssetList };
};

// 선택된 건물 관련 custom hook
export const useSelectedBuilding = (): {
  selectedBuilding: ConnectedSpaceInfo | null;
  setSelectedBuilding: (selectedBuilding: ConnectedSpaceInfo | null) => void;
} => {
  const { selectedBuilding } = useProjectState();
  const dispatch = useProjectDispatch();

  const setSelectedBuilding = (selectedBuilding: ConnectedSpaceInfo | null) => {
    dispatch({
      type: PROJECT_SET_SELECTED_BUILDING_STATE,
      selectedBuilding,
    });
  };

  return { selectedBuilding, setSelectedBuilding };
};

// 선택된 평면도 관련 custom hook
export const useSelectedFloorPlanId = (): {
  selectedFloorPlanId: string;
  setSelectedFloorPlanId: (selectedFloorPlanId: string) => void;
} => {
  const { selectedFloorPlanId } = useProjectState();
  const dispatch = useProjectDispatch();

  const setSelectedFloorPlanId = (selectedFloorPlanId: string) => {
    dispatch({
      type: PROJECT_SET_SELECTED_FLOOR_PLAN_ID_STATE,
      selectedFloorPlanId,
    });
  };

  return { selectedFloorPlanId, setSelectedFloorPlanId };
};

// 건물 관련 custom hook
export function useBuildingInfoList(): {
  buildingInfoList: ConnectedSpaceInfo[];
  setBuildingInfoList: (buildingInfoList: ConnectedSpaceInfo[]) => void;
} {
  const { buildingInfoList } = useProjectState();
  const dispatch = useProjectDispatch();

  const setBuildingInfoList = (buildingInfoList: ConnectedSpaceInfo[]) => {
    dispatch({
      type: PROJECT_SET_BUILDING_INFO_LIST_STATE,
      buildingInfoList,
    });
  };

  return {
    buildingInfoList,
    setBuildingInfoList,
  };
}

// 평면도 관련 custom hook
export function useFloorPlanList(): {
  floorPlanList: ResponseFloorInfo[];
  setFloorPlanList: (floorPlanList: ResponseFloorInfo[]) => void;
} {
  const { floorPlanList } = useProjectState();
  const dispatch = useProjectDispatch();

  const setFloorPlanList = (floorPlanList: ResponseFloorInfo[]) => {
    dispatch({
      type: PROJECT_SET_FLOOR_PLAN_LIST_STATE,
      floorPlanList,
    });
  };

  return {
    floorPlanList,
    setFloorPlanList,
  };
}

// API 호출 이후 로딩 상태 관련 custom hook
export function useLoading(): {
  loading: boolean;
  setLoading: (loading: boolean) => void;
} {
  const { loading } = useProjectState();
  const dispatch = useProjectDispatch();

  const setLoading = (loading: boolean) => {
    dispatch({
      type: PROJECT_SET_LOADING_STATE,
      loading,
    });
  };

  return {
    loading,
    setLoading,
  };
}

// 자산 엑셀 업로드 이후 상태값 관련 custom hook
export function useAfterAssetUpload(): {
  afterAssetUpload: boolean;
  setAfterAssetUpload: (afterAssetUpload: boolean) => void;
} {
  const { afterAssetUpload } = useProjectState();
  const dispatch = useProjectDispatch();

  const setAfterAssetUpload = (afterAssetUpload: boolean) => {
    dispatch({
      type: PROJECT_SET_AFTER_ASSET_UPLOAD_STATE,
      afterAssetUpload,
    });
  };

  return {
    afterAssetUpload,
    setAfterAssetUpload,
  };
}
