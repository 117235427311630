import React, { ReactElement, useEffect, useState } from 'react';
import ProjectRegisterHeader from '@/components/project/ProjectRegisterHeader';
import BuildingList from '@/components/project/AssetRegisterTab/AssetList/BuildingList';
import FloorPlanList from '@/components/project/AssetRegisterTab/AssetList/FloorPlanList';
import RegisterButtonContainer from '@/components/project/AssetRegisterTab/AssetList/RegisterButtonContainer';
import AssetTable from '@/components/project/AssetRegisterTab/AssetList/AssetTable';
import { PaneStatus } from '@/utils/constants/common';
import { ConnectedSpaceInfo } from '@/modules/space/types';
import {
  useAfterAssetUpload,
  useBuildingInfoList,
  useProjectRegister,
  useSelectedBuilding,
  useSelectedFloorPlanId,
} from '@/modules/project/hook';
import { fetchProject } from '@/api/project';
import { fetchSpaceInfo } from '@/api/space';
import { fetchAssetList } from '@/api/assets';
import { AssetType } from '@/modules/assets/types';

type AssetListProps = {
  setPaneStatus: (paneStatus: PaneStatus) => void;
};

function AssetList({ setPaneStatus }: AssetListProps): ReactElement {
  const { setBuildingInfoList } = useBuildingInfoList();
  const { selectedBuilding } = useSelectedBuilding();
  const { selectedFloorPlanId } = useSelectedFloorPlanId();
  const [fetchedAssetList, setFetchedAssetList] = useState<AssetType[]>([]);
  const { afterAssetUpload, setAfterAssetUpload } = useAfterAssetUpload();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    projectInfo: { projectId },
  } = useProjectRegister();

  // 건물정보 조회
  const fetchBuildingInfoList = async () => {
    setLoading(true);
    const result = projectId && (await fetchProject(projectId));
    const list: ConnectedSpaceInfo[] = [];
    if (result) {
      for (const { metaId, mappingId } of result.buildings) {
        const spaceInfo = await fetchSpaceInfo(metaId);
        spaceInfo && list.push({ ...spaceInfo, mappingId });
      }
    }
    setBuildingInfoList(list);
    setLoading(false);
  };

  // 자산 목록 조회
  const handleFetchAssetList = async () => {
    if (projectId && selectedBuilding?.spaceId) {
      setLoading(true);
      const data: AssetType[] = await fetchAssetList(
        projectId,
        selectedBuilding.mappingId,
        selectedFloorPlanId
      );
      data && setFetchedAssetList(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBuildingInfoList();
  }, [projectId]);

  useEffect(() => {
    selectedFloorPlanId ? handleFetchAssetList() : setFetchedAssetList([]);
  }, [selectedBuilding, selectedFloorPlanId]);

  useEffect(() => {
    handleFetchAssetList();
    setAfterAssetUpload(false);
  }, [afterAssetUpload]);

  return (
    <>
      <ProjectRegisterHeader />
      <div className="plf-floor-add border-bottom-0">
        <div className="form-cell">
          <BuildingList />
          {selectedBuilding?.spaceId && <FloorPlanList />}
        </div>
        <RegisterButtonContainer setPaneStatus={setPaneStatus} />
      </div>
      <AssetTable fetchedAssetList={fetchedAssetList} loading={loading} />
    </>
  );
}

export default AssetList;
