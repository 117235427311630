import { useCallback, useContext } from 'react';
import {
  MonitoringDispatchContext,
  MonitoringStateContext,
} from '@/modules/monitoring/context';
import {
  MONITORING_CHANGE_DIRECT_FLAG,
  MONITORING_CHANGE_MONITORING_TYPE,
  MONITORING_INIT_SELECTED_GEOFENCE_FEATURE_LIST,
  MONITORING_INIT_SELECTED_LIDAR_OBJECT_LIST,
  MONITORING_REMOVE_SELECTED_LIDAR_OBJECT,
  MONITORING_SET_APP_END_POINT_USER_DATA,
  MONITORING_SET_LIDAR_OBJECT_LIST,
  MONITORING_SET_SELECTED_GEOFENCE_FEATURE,
  MONITORING_SET_SELECTED_GEOFENCE_FEATURE_LIST,
  MONITORING_SET_SELECTED_LIDAR_OBJECT,
  MonitoringAppEndPointUser,
  MonitoringLidar,
  MonitoringType,
} from '@/modules/monitoring/types';
import { Feature } from 'ol';

function useMonitoringState() {
  const state = useContext(MonitoringStateContext);
  if (!state) throw new Error('MonitoringProvider not found');
  return state;
}

function useMonitoringDispatch() {
  const dispatch = useContext(MonitoringDispatchContext);
  if (!dispatch) throw new Error('MonitoringProvider not found');
  return dispatch;
}

export function useMonitoring() {
  const { monitoringType } = useMonitoringState();
  const dispatch = useMonitoringDispatch();

  const handleChangeMonitoringType = (monitoringType: MonitoringType) => {
    dispatch({
      type: MONITORING_CHANGE_MONITORING_TYPE,
      monitoringType,
    });
  };

  return {
    monitoringType,
    handleChangeMonitoringType,
  };
}

export function useMonitoringMenu() {
  const { directFlag } = useMonitoringState();
  const dispatch = useMonitoringDispatch();

  const handleChangeDirectFlag = useCallback((directFlag: boolean) => {
    dispatch({
      type: MONITORING_CHANGE_DIRECT_FLAG,
      directFlag,
    });
  }, []);

  return {
    directFlag,
    handleChangeDirectFlag,
  };
}

export function useMonitoringApp() {
  const {
    appEndPointUserList,
    appEndPointUserAccumulateNumber,
  } = useMonitoringState();
  const dispatch = useMonitoringDispatch();

  const handleSetAppEndPointUserData = (
    appEndPointUserList: MonitoringAppEndPointUser[],
    accumulateNumber: number
  ) => {
    dispatch({
      type: MONITORING_SET_APP_END_POINT_USER_DATA,
      appEndPointUserList,
      accumulateNumber,
    });
  };

  return {
    appEndPointUserList,
    appEndPointUserAccumulateNumber,
    handleSetAppEndPointUserData,
  };
}

export function useMonitoringLidar() {
  const { lidarObjectList, selectedLidarObjectList } = useMonitoringState();
  const dispatch = useMonitoringDispatch();

  const handleSetLidarObjectList = (lidarObjectList: MonitoringLidar[]) => {
    dispatch({
      type: MONITORING_SET_LIDAR_OBJECT_LIST,
      lidarObjectList,
    });
  };

  const handleSelectLidarObject = (lidarObject: MonitoringLidar) => {
    dispatch({
      type: MONITORING_SET_SELECTED_LIDAR_OBJECT,
      lidarObject,
    });
  };

  const handleRemoveSelectedLidarObject = (lidarObject: MonitoringLidar) => {
    dispatch({
      type: MONITORING_REMOVE_SELECTED_LIDAR_OBJECT,
      lidarObject,
    });
  };

  const handleInitSelectedLidarObjectList = () => {
    dispatch({
      type: MONITORING_INIT_SELECTED_LIDAR_OBJECT_LIST,
    });
  };

  return {
    lidarObjectList,
    selectedLidarObjectList,
    handleSetLidarObjectList,
    handleSelectLidarObject,
    handleRemoveSelectedLidarObject,
    handleInitSelectedLidarObjectList,
  };
}

export function useMonitoringGeofence() {
  const { selectedGeofenceFeatureList } = useMonitoringState();
  const dispatch = useMonitoringDispatch();

  const handleSetGeofenceFeature = (feature: Feature) => {
    dispatch({
      type: MONITORING_SET_SELECTED_GEOFENCE_FEATURE,
      selectedGeofenceFeature: feature,
    });
  };

  const handleSetGeofenceFeatureList = (featureList: Feature[]) => {
    dispatch({
      type: MONITORING_SET_SELECTED_GEOFENCE_FEATURE_LIST,
      selectedGeofenceFeatureList: featureList,
    });
  };

  const handleInitSelectedGeofenceFeatureList = () => {
    dispatch({
      type: MONITORING_INIT_SELECTED_GEOFENCE_FEATURE_LIST,
    });
  };

  return {
    selectedGeofenceFeatureList,
    handleSetGeofenceFeature,
    handleSetGeofenceFeatureList,
    handleInitSelectedGeofenceFeatureList,
  };
}
