import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type SignUpStepProps = {
  step: number;
};

function SignUpStep({ step }: SignUpStepProps) {
  const { t } = useTranslation();

  const stepTags: ReactElement[] = [];
  for (let i = 1; i < 5; i++) {
    stepTags.push(
      <li
        key={i}
        className={classNames({
          active: step === i,
        })}
      >
        <span>{t('STEP')}</span>
        {i}
      </li>
    );
  }

  return <ol className="step-group">{stepTags}</ol>;
}

export default SignUpStep;
