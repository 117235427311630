import { fetchAssetDetail } from '@/api/assets';
import {
  AssetCategoryType,
  ASSETS_EQUIPMENT_CATEGORY_ID,
  ASSETS_HUMAN_CATEGORY_ID,
  ASSETS_LOGISTICS_CATEGORY_ID,
  AssetType,
} from '@/modules/assets/types';
import React, { ReactElement, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { useTranslation } from 'react-i18next';
// import AssetLeftPaneMessageModal from '../AssetLeftPaneMessageModal';

type AssetLeftPaneDetailModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  assetInfoId: string;
};

function AssetLeftPaneDetailModal({
  show,
  setShow,
  assetInfoId,
}: AssetLeftPaneDetailModalProps): ReactElement {
  const { t } = useTranslation();
  const [assetDetail, setAssetDetail] = useState<AssetType>();
  const [category, setCategory] = useState<AssetCategoryType>();
  // Todo: 기능 개발 이후 진행
  const [tabIndex, _] = useState<number>(1);
  // const [showMessageModal, setShowMessageModal] = useState<boolean>(false);

  // 자산 상세 정보 조회
  const handleFetchAssetDetail = async () => {
    const fetchedAssetDetail = await fetchAssetDetail(assetInfoId);
    fetchedAssetDetail && setAssetDetail(fetchedAssetDetail);
  };

  useEffect(() => {
    // Todo: 기능 개발 이후 진행
    // setTabIndex(1);
    setAssetDetail({
      projectId: '',
      mappingId: '',
      mapId: '',
      fileKey: '',
      fileUrl: '',
      originalFileName: '',
      imgId: '',
      assetId: '',
      serialId: '',
      assetInfoId: '',
      assetInfoName: '',
      positionGeom: '',
      lat: 0,
      lng: 0,
      registId: '',
      updateId: '',
      registDate: '',
      updateDate: '',
      activeFlag: false,
      deleteFlag: false,
      categories: [
        {
          projectId: '',
          mappingId: '',
          mapId: '',
          fileKey: '',
          fileUrl: '',
          originalFileName: '',
          imgId: '',
          seq: 0,
          categoryId: '',
          assetInfoId: '',
          categoryName: '',
          depth: 1,
          count: 0,
          parentId: '',
          registId: '',
          updateId: '',
          registDate: '',
          updateDate: '',
          activeFlag: false,
          deleteFlag: false,
          subCategories: [],
        },
        {
          projectId: '',
          mappingId: '',
          mapId: '',
          fileKey: '',
          fileUrl: '',
          originalFileName: '',
          imgId: '',
          seq: 0,
          categoryId: '',
          assetInfoId: '',
          categoryName: '',
          depth: 2,
          count: 0,
          parentId: '',
          registId: '',
          updateId: '',
          registDate: '',
          updateDate: '',
          activeFlag: false,
          deleteFlag: false,
          subCategories: [],
        },
      ],
    });
    assetInfoId && handleFetchAssetDetail();
  }, [assetInfoId]);

  useEffect(() => {
    assetDetail?.categories &&
      setCategory(
        assetDetail.categories.filter((category) => category.depth === 2)[0]
      );
  }, [assetDetail]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-dialog-scrollable"
      >
        <ModalHeader>
          <div className="d-flex">
            <span className="material-icons-outlined text-40 mr-2">
              {category &&
                category.categoryId === ASSETS_HUMAN_CATEGORY_ID &&
                'person'}
              {category &&
                category.categoryId === ASSETS_EQUIPMENT_CATEGORY_ID &&
                'handyman'}
              {category &&
                category.categoryId === ASSETS_LOGISTICS_CATEGORY_ID &&
                'inventory_2'}
              {/* Todo: 카테고리 확장 이후 해당 categoryId에 맵핑 */}
              {/* {category && category.categoryId === '환경센서' && 'air'}
              {category && category.categoryId === '온습도센서' && 'thermostat'}
              {category && category.categoryId === '배터리' && 'battery_0_bar'} */}
            </span>
            <h4 className="modal-title" id="sdetailItemLabel">
              {assetDetail && assetDetail.assetInfoName}
            </h4>
          </div>
          {/* Todo: 기능 개발 이후 진행
        <a href="#n" className="btn-ico-sm">
          <span className="icon material-icons-outlined">open_in_browser</span>
          <span className="text">
            <span>{t('수정')}</span>
          </span>
        </a> */}
        </ModalHeader>
        <div className="nav tab-menu the-num02" role="tablist">
          <a
            className={`btn-style_underline ${tabIndex === 1 ? `active` : ``}`}
            data-toggle="tab"
            role="tab"
            aria-selected="true"
          >
            <span className="menu-cover">
              <span>{t('자산 정보')}</span>
            </span>
          </a>
          {/* Todo: 기능 개발 이후 진행 */}
          {/* <a
            className={tabIndex === 2 ? `active` : ``}
            data-toggle="tab"
            role="tab"
            aria-selected="false"
            onClick={() => setTabIndex(2)}
          >
            <span className="menu-cover">
              <span>{t('히스토리')}</span>
            </span>
          </a> */}
        </div>
        <ModalBody>
          <div className="tab-content" style={{ height: '550px' }}>
            {tabIndex === 1 ? (
              <div
                id="tab01"
                className={`tab-pane ${tabIndex === 1 ? `active` : ``}`}
                role="tabpanel"
              >
                <div className="mb-4">
                  <small className="d-flex text-64 mb-2">
                    {t('자산 유형')}
                  </small>
                  <p className="m-0">
                    {assetDetail?.categories &&
                      assetDetail.categories.filter(
                        (category) => category.depth === 1
                      )[0].categoryName}
                  </p>
                </div>
                <div className="mb-4">
                  <small className="d-flex text-64 mb-2">{t('분류')}</small>
                  <p className="m-0">
                    {category &&
                      category.categoryId === ASSETS_HUMAN_CATEGORY_ID &&
                      t('사람')}
                    {category &&
                      category.categoryId === ASSETS_EQUIPMENT_CATEGORY_ID &&
                      t('장비')}
                    {category &&
                      category.categoryId === ASSETS_LOGISTICS_CATEGORY_ID &&
                      t('물류')}
                  </p>
                </div>
                <div className="mb-4">
                  <small className="d-flex text-64 mb-2">{t('식별값')}</small>
                  <p className="m-0">{assetDetail && assetDetail.serialId}</p>
                </div>
                <div className="mb-4">
                  {/* Todo: 기능 개발 이후 진행 */}
                  <small className="d-flex text-64 mb-2">{t('위치')}</small>
                  {/* <p className="m-0">Check-In</p>
                  <p className="m-0 text-40">Check-Out</p> */}
                  <p className="m-0">-</p>
                </div>
                {/* Todo: 기능 개발 이후 진행 */}
                <div className="mb-4">
                  <small className="d-flex text-64 mb-2">
                    {t('현재 위치')}
                  </small>
                  {/* <p className="m-0">R&D Center</p> */}
                  <p className="m-0">-</p>
                </div>
                {/* Todo: 기능 개발 이후 진행 */}
                <div className="mb-4">
                  <small className="d-flex text-64 mb-2">
                    {t('수집 시간')}
                  </small>
                  {/* <p className="m-0">3분 전</p> */}
                  <p className="m-0">-</p>
                </div>
                <div className="mb-4">
                  <small className="d-flex text-64 mb-2">{t('이벤트')}</small>
                  {/* <a href="#n">
                    <small className="badge-basics">{t('층 이탈')}</small>
                  </a> */}
                  <p className="m-0">-</p>
                </div>
              </div>
            ) : (
              <>
                {/* <div
                  id="tab02"
                  className={`tab-pane ${tabIndex === 2 ? `active` : ``}`}
                  role="tabpanel"
                >
                  <div className="d-flex justify-content-end">
                    <a href="#n" className="btn-ico-sm">
                      <span className="icon material-icons-outlined">
                        timeline
                      </span>
                      <span className="text">
                        <span>{t('지도 보기')}</span>
                      </span>
                    </a>
                  </div>
                  <div className="route-list mb-4">
                    <ul className="route-user01">
                      <li className="line-blue">
                        <div className="route-inner">
                          <div className="route-title">
                            <span className="text">In</span>
                            <h5 className="title">지오펜스 23</h5>
                          </div>
                          <span className="text-64 ml-auto">3분 전</span>
                        </div>
                      </li>
                      <li className="line-dark">
                        <div className="route-inner">
                          <div className="route-title">
                            <span className="text">Out</span>
                            <h5 className="title">지오펜스 24</h5>
                          </div>
                          <span className="text-64 ml-auto">
                            2022.01.29 12:23
                          </span>
                        </div>
                      </li>
                      <li className="line-blue">
                        <div className="route-inner">
                          <div className="route-title">
                            <span className="text">In</span>
                            <h5 className="title">지오펜스 24</h5>
                          </div>
                          <span className="text-64 ml-auto">
                            2022.01.29 12:20
                          </span>
                        </div>
                      </li>
                      <li className="line-dark">
                        <div className="route-inner">
                          <div className="route-title">
                            <span className="text">Out</span>
                            <h5 className="title">지오펜스 25</h5>
                          </div>
                          <span className="text-64 ml-auto">
                            2022.01.29 12:15
                          </span>
                        </div>
                      </li>
                      <li className="line-blue">
                        <div className="route-inner">
                          <div className="route-title">
                            <span className="text">In</span>
                            <h5 className="title">지오펜스 25</h5>
                          </div>
                          <span className="text-64 ml-auto">
                            2022.01.29 12:10
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-light"
            data-dismiss="modal"
            onClick={() => setShow(false)}
          >
            {t('닫기')}
          </button>
          {/* Todo: 기능 개발 이후 진행 */}
          {/* <button
            type="button"
            className="btn btn-accent"
            data-dismiss="modal"
            onClick={() => setShowMessageModal(true)}
          >
            {t('메세지 발송')}
          </button>
          <button type="button" className="btn btn-accent" data-dismiss="modal">
            {t('이벤트 설정')}
          </button> */}
        </ModalFooter>
      </Modal>
      {/* Todo: 기능 개발 이후 진행 */}
      {/* <AssetLeftPaneMessageModal
        show={showMessageModal}
        setShow={setShowMessageModal}
      /> */}
    </>
  );
}

export default AssetLeftPaneDetailModal;
