import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidUtils } from '@/utils';
import FormLabel from '@/components/common/FormLabel';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { fetchResetPasswordAuthNumber, putResetPassword } from '@/api/user';
import classNames from 'classnames';
import { Alert } from 'react-bootstrap';
import MaterialIcon from '@/components/common/MaterialIcon';

type Params = {
  key: string;
};

function ResetPasswordForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { key } = useParams<Params>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [inputs, setInputs] = useState<{
    email: string;
    password: string;
    rePassword: string;
  }>({
    email: '',
    password: '',
    rePassword: '',
  });

  const [validInputs, setValidInputs] = useState<{
    password: boolean;
    rePassword: boolean;
  }>({
    password: true,
    rePassword: true,
  });

  useEffect(() => {
    if (key) {
      handleCheckAuthNumber();
    } else {
      history.replace('/signin');
    }
  }, [key]);

  const handleCheckAuthNumber = async () => {
    const email = await fetchResetPasswordAuthNumber(key);
    if (!email) {
      history.replace('/signin');
    } else {
      setInputs({
        ...inputs,
        email,
      });
    }
  };

  const validForm = () => {
    let password = true;
    let rePassword = true;

    if (!inputs.password || !ValidUtils.validatePassword(inputs.password)) {
      password = false;
    }

    if (!inputs.rePassword || inputs.password !== inputs.rePassword) {
      rePassword = false;
    }

    setValidInputs({
      password,
      rePassword,
    });

    return password && rePassword;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = validForm();

    if (isValid) {
      setLoading(true);
      const result = await putResetPassword({
        userId: inputs.email,
        newPassword: inputs.password,
      });

      if (result) {
        setSuccess(true);
        setTimeout(() => {
          history.replace('/signin');
        }, 1000);
      }
    }
  };

  return (
    <>
      <div className="title-group mb-5">
        <h3 className="m-0">{t('비밀번호 재설정')}</h3>
        <p className="mt-2 text-50 font-size-16pt">
          {t('비밀번호 변경 후 다시 로그인해 주세요.')}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-4">
          <FormLabel
            textKey={'비밀번호'}
            htmlFor={'password'}
            essential={true}
          />
          <input
            id="password"
            type="password"
            className="form-line"
            placeholder={t('비밀번호를 입력하세요.')}
            value={inputs.password}
            onChange={(e) => {
              setInputs({
                ...inputs,
                [e.target.id]: e.target.value,
              });
            }}
          />
          {!validInputs.password && (
            <div className="invalid-feedback">
              {t('8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.')}
            </div>
          )}
        </div>
        <div className="form-group mb-5">
          <FormLabel
            textKey={'비밀번호 확인'}
            htmlFor={'rePassword'}
            essential={true}
          />
          <input
            id="rePassword"
            type="password"
            className="form-line"
            placeholder={t('비밀번호를 입력하세요.')}
            value={inputs.rePassword}
            onChange={(e) => {
              setInputs({
                ...inputs,
                [e.target.id]: e.target.value,
              });
            }}
          />
          {!validInputs.rePassword && (
            <div className="invalid-feedback">
              {t('비밀번호가 일치하지 않습니다.')}
            </div>
          )}
        </div>
        {success && (
          <Alert className="alert-soft-success">
            <div className="d-flex flex-wrap align-items-center">
              <div className="mr-8pt">
                <MaterialIcon name={'done'} />
              </div>
              <div className="flex" style={{ minWidth: '180px' }}>
                <small className="text-black-100">
                  {t('비밀번호 변경을 완료했습니다.')}
                </small>
              </div>
            </div>
          </Alert>
        )}
        <div className="form-group text-center mb-32pt">
          <button
            className={classNames('btn btn-block btn-lg btn-accent', {
              'is-loading': loading,
            })}
            type="submit"
          >
            {t('비밀번호 변경')}
          </button>
        </div>
        <ul className="signin-help">
          <li>
            {t('문의')}
            <a
              className="text-body text-underline"
              href="mailto:info@watanow.com"
            >
              info@watanow.com
            </a>
          </li>
        </ul>
      </form>
    </>
  );
}

export default ResetPasswordForm;
