import React, { useRef } from 'react';
import MaterialIcon from '@/components/common/MaterialIcon';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import { Dropdown } from 'react-bootstrap';
import { useDropdown } from '@/modules/common';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import { useTranslation } from 'react-i18next';
import { useFloorObject } from '@/modules/floor/hook';
import { Feature } from 'ol';
import _ from 'lodash';
import { useMonitoringGeofence } from '@/modules/monitoring/hook';

type MonitoringGeofenceFilterProps = {
  objectFeatureFilterList: Feature[];
  onChangeObjectFeatureFilterList: (objectFeatureFilterList: Feature[]) => void;
};

function MonitoringRealtimeFilter({
  objectFeatureFilterList,
  onChangeObjectFeatureFilterList,
}: MonitoringGeofenceFilterProps) {
  const { t } = useTranslation();
  const { handleSetGeofenceFeature } = useMonitoringGeofence();
  const dropdown = useRef<HTMLDivElement>(null);
  const { handleToggle } = useDropdown(dropdown);
  const { geofenceList, areaList } = useFloorObject();

  const handleCheckObjectFilter = (
    checked: boolean,
    feature: Feature | null
  ) => {
    if (!feature) {
      onChangeObjectFeatureFilterList([]);
    } else {
      if (objectFeatureFilterList.includes(feature)) {
        onChangeObjectFeatureFilterList(
          objectFeatureFilterList.filter(
            (filterObjectFeature) => !_.isEqual(filterObjectFeature, feature)
          )
        );
      } else {
        onChangeObjectFeatureFilterList([...objectFeatureFilterList, feature]);
      }
    }
  };

  return (
    <>
      <div className="geofence-select">
        <div className="form-group mb-0 over-txt">
          <div className="form-line">
            <Dropdown onToggle={handleToggle}>
              <DropdownToggle as={'a'} data-caret="false">
                <div className="att-file">
                  <p className="file-name">
                    {!objectFeatureFilterList.length && t('전체')}
                    {objectFeatureFilterList.length > 0 && (
                      <>
                        {objectFeatureFilterList.length === 1
                          ? objectFeatureFilterList[0].get('name')
                          : t('선택된 (%)개의 영역', {
                              replace: {
                                '%': objectFeatureFilterList.length,
                              },
                            })}
                      </>
                    )}
                  </p>
                  <div className="bundle">
                    <MaterialIcon name={'expand_more'} className={'ml-2'} />
                  </div>
                </div>
              </DropdownToggle>
              <DropdownMenu align={'left'} ref={dropdown}>
                <div className={'dropdown-item'}>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={'all'}
                      checked={!objectFeatureFilterList.length}
                      onChange={(e) => handleCheckObjectFilter(true, null)}
                    />
                    <label
                      className="custom-control-label font-size-14pt"
                      htmlFor={'all'}
                    >
                      {t('전체')}
                    </label>
                  </div>
                </div>
                {geofenceList.map(({ zone_id, zone_name, feature }) => (
                  <ObjectItem
                    key={zone_id}
                    id={zone_id}
                    name={zone_name}
                    checked={Boolean(
                      objectFeatureFilterList.find(
                        (objectFeature) =>
                          objectFeature.getId() === feature?.getId()
                      )
                    )}
                    onChangeCheck={(checked) => {
                      if (feature) {
                        handleSetGeofenceFeature(feature);
                        handleCheckObjectFilter(checked, feature);
                      }
                    }}
                  />
                ))}
                {areaList.map(({ id, areaname, feature }) => (
                  <ObjectItem
                    key={id}
                    id={id}
                    name={areaname}
                    checked={Boolean(
                      objectFeatureFilterList.find(
                        (objectFeature) =>
                          objectFeature.getId() === feature?.getId()
                      )
                    )}
                    onChangeCheck={(checked) =>
                      handleCheckObjectFilter(checked, feature)
                    }
                  />
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
}

type ObjectItemProps = {
  id: string;
  name: string;
  checked: boolean;
  onChangeCheck: (checked: boolean) => void;
};

function ObjectItem({ id, name, checked, onChangeCheck }: ObjectItemProps) {
  return (
    <>
      <div className={'dropdown-item'}>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={id}
            checked={checked}
            onChange={(e) => onChangeCheck(e.target.checked)}
          />
          <label className="custom-control-label font-size-14pt" htmlFor={id}>
            {name}
          </label>
        </div>
      </div>
    </>
  );
}

export default MonitoringRealtimeFilter;
