import React from 'react';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import MaterialIcon from '@/components/common/MaterialIcon';
import { useTranslation } from 'react-i18next';
import { ModalAlertProps } from '@/modules/common';

type AlertModalProps = ModalAlertProps & {
  titleKey?: string;
};

function AlertModal({
  show,
  titleKey = '알림',
  children,
  onHide,
}: AlertModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={() => {
        //
      }}
      centered={true}
    >
      <ModalHeader>
        <ModalTitle as={'h5'}>{t(titleKey)}</ModalTitle>
        <button type="button" className="close custom-close" onClick={onHide}>
          <span>
            <MaterialIcon name={'clear'} />
          </span>
        </button>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-accent" onClick={onHide}>
          {t('확인')}
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default AlertModal;
