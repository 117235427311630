import React, { ReactElement, useEffect, useRef } from 'react';
import LayoutColumn from '@/components/common/LayoutColumn';
import { useHomeMenu } from '@/modules/home/hook';
import MonitoringRightPane from '@/components/monitoring/MonitoringRightPane';
import {
  MENU_IDX_MESSAGE,
  MENU_IDX_MONITORING,
  MenuIdx,
  RIGHT_PANE_VIEW_MESSAGE,
  MENU_IDX_STATISTICS,
} from '@/modules/setup/types';
import { useRightPane } from '@/modules/setup/hook';
import MessageRightPane from '@/components/message/MessageRightPane';
import classNames from 'classnames';
import {
  useMonitoringGeofence,
  useMonitoringLidar,
} from '@/modules/monitoring/hook';
import { useOpenLayersGeofence } from '@/modules/openlayers/hook';

function RightPane(): ReactElement {
  const { show: showRightPane, view, handleChangeShow } = useRightPane();
  const { geofence, getGeofenceStyle } = useOpenLayersGeofence();
  const { handleInitSelectedGeofenceFeatureList } = useMonitoringGeofence();
  const { handleInitSelectedLidarObjectList } = useMonitoringLidar();
  const { activeMenuIdx } = useHomeMenu();
  const prevActiveMenuIdxRef = useRef<MenuIdx>();

  let isMessageView = false;
  if (view === RIGHT_PANE_VIEW_MESSAGE) {
    isMessageView = true;
  }

  useEffect(() => {
    return () => {
      handleChangeShow(false);
    };
  }, []);

  useEffect(() => {
    if (prevActiveMenuIdxRef.current === MENU_IDX_MONITORING) {
      handleInitSelectedLidarObjectList();
      handleChangeShow(false);
      handleInitFeature();
    }

    if (activeMenuIdx === MENU_IDX_MONITORING) {
      handleChangeShow(true);
    } else if (activeMenuIdx === MENU_IDX_MESSAGE || MENU_IDX_STATISTICS) {
      handleChangeShow(false);
    }

    prevActiveMenuIdxRef.current = activeMenuIdx;
  }, [activeMenuIdx]);

  const handleInitFeature = () => {
    const geofenceLayer = geofence.layer;
    if (geofenceLayer) {
      const source = geofenceLayer.getSource();
      source.getFeatures().forEach((paintedFeature) => {
        paintedFeature.setStyle(getGeofenceStyle(paintedFeature, false));
      });
    }
    handleInitSelectedGeofenceFeatureList();
  };

  return (
    <LayoutColumn align={'right'} show={showRightPane}>
      <div className="cd-panel__container">
        <div className="cd-panel__content">
          {isMessageView && <MessageRightPane />}
          <div
            className={classNames({
              'd-none': isMessageView,
            })}
          >
            {activeMenuIdx === MENU_IDX_MONITORING && <MonitoringRightPane />}
          </div>
        </div>
      </div>
    </LayoutColumn>
  );
}

export default React.memo(RightPane);
