import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flatpickr from 'react-flatpickr';
import { key as LocaleKey } from 'flatpickr/dist/types/locale';
import moment from 'moment';
import 'flatpickr/dist/l10n/ko';
import 'flatpickr/dist/l10n/ja';
import Pagination from '@/components/common/Pagination';
import { fetchPagingMessages } from '@/api/message';
import { useHomeProject } from '@/modules/home/hook';
import { MessageHistory } from '@/modules/message/types';
import PaginationSize from '@/components/common/PaginationSize';
import Preloader from '@/components/common/Preloader';
import TableEmptyRow from '@/components/common/TableEmptyRow';
import classNames from 'classnames';

type HomeMessageListProps = {
  page: number;
  pageSize: number;
  period: string[];
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onChangePeriod: (period: string[]) => void;
  onClickMessage: (messageId: string) => void;
};

function HomeMessageList({
  page,
  pageSize,
  period,
  onChangePage,
  onChangePageSize,
  onChangePeriod,
  onClickMessage,
}: HomeMessageListProps) {
  const { t, i18n } = useTranslation();
  const { project } = useHomeProject();
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [messageHistoryList, setMessageHistoryList] = useState<
    MessageHistory[]
  >([]);
  const flatpickrRef = useRef<Flatpickr>(null);
  const mountedRef = useRef(false);

  useEffect(() => {
    if (mountedRef.current && page === 1) {
      handleFetchMessages();
    }

    if (!mountedRef.current) {
      mountedRef.current = true;
    }
  }, [period]);

  useEffect(() => {
    handleFetchMessages();
  }, [page, pageSize]);

  const handleFetchMessages = async () => {
    setLoading(true);
    if (project) {
      const { content, totalElements } = await fetchPagingMessages({
        projectId: project.projectId,
        page,
        pageSize,
        parentType: 'LIDAR_DETECTION',
        searchStartDates: period[0],
        searchEndDates: period[1],
      });
      setTotalCount(totalElements);
      setMessageHistoryList(content);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center mb-4">
        <div className="flex">
          <h3 className="d-flex align-items-center mb-0">{t('알림')}</h3>
        </div>
      </div>
      <div className="d-flex align-items-center mb-3">
        <div className="form-group mb-0 w-50">
          <div className="date-picker-cover">
            <Flatpickr
              options={{
                mode: 'range',
                altInput: true,
                altInputClass: 'form-line flatpickr-input',
                altFormat: 'Y-m-d',
                locale: i18n.language as LocaleKey,
              }}
              ref={flatpickrRef}
              value={period}
              onChange={(dates) => {
                if (dates.length === 2) {
                  onChangePeriod(
                    dates.map((date) => moment(date).format('YYYY-MM-DD'))
                  );
                  onChangePage(1);
                }
              }}
              onClose={(dates) => {
                if (dates.length < 2) {
                  flatpickrRef.current?.flatpickr.setDate(period);
                }
              }}
            />
          </div>
        </div>
      </div>
      <table className="table mb-0 table-center table-higher table-head-light">
        <thead>
          <tr>
            <th>{t('알림명')}</th>
            <th>{t('알림 내용')}</th>
            <th>{t('일시')}</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={3}>
                <Preloader />
              </td>
            </tr>
          )}
          {!loading && (
            <>
              {!messageHistoryList.length && (
                <TableEmptyRow
                  colSpan={6}
                  textKey={'아직 알림 내역이 없습니다.'}
                />
              )}
              {messageHistoryList.map(
                ({
                  messageId,
                  messageTitle,
                  messageContent,
                  linkUrl,
                  registDate,
                  parentType,
                }) => (
                  <tr key={messageId}>
                    <td>
                      <a
                        className={classNames({
                          'text-identity':
                            messageTitle.indexOf('과속') > -1 ||
                            messageTitle.indexOf('충돌') > -1,
                        })}
                        onClick={() => onClickMessage(messageId)}
                      >
                        {parentType === 'SENSOR'
                          ? t('센서 알림')
                          : messageTitle}
                      </a>
                    </td>
                    <td className="text-left">
                      <a
                        className={''}
                        onClick={() => onClickMessage(messageId)}
                      >
                        {messageContent || linkUrl}
                      </a>
                    </td>
                    <td>{moment(registDate).format('YYYY.MM.DD HH:mm:ss')}</td>
                  </tr>
                )
              )}
            </>
          )}
        </tbody>
      </table>
      <div className="pagination-group">
        <Pagination
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          onChangePage={onChangePage}
        />
        <PaginationSize
          show={totalCount !== 0}
          pageSize={pageSize}
          onChangePageSize={onChangePageSize}
        />
      </div>
    </>
  );
}

export default HomeMessageList;
