import React from 'react';
import FormGroup from '@/components/common/FormGroup';
import FormLabel from '@/components/common/FormLabel';
import { useTranslation } from 'react-i18next';
import { MessageInfo } from '@/modules/message/types';
import InputFile from '@/components/common/InputFile';

type AccidentMessageFormProps = {
  message: MessageInfo;
  onChangeInput: (message: MessageInfo) => void;
};

function AccidentMessageForm({
  message,
  onChangeInput,
}: AccidentMessageFormProps) {
  const { t } = useTranslation();

  return (
    <>
      <FormGroup marginBottomClassName={'mb-4'}>
        <FormLabel textKey={'내용'} essential={true} fontSizeApply={false} />
        <textarea
          id=""
          rows={5}
          placeholder={t('내용을 입력해 주세요')}
          value={message.content}
          onChange={(e) =>
            onChangeInput({
              ...message,
              content: e.target.value,
            })
          }
        ></textarea>
      </FormGroup>
      <FormGroup marginBottomClassName={'mb-4'}>
        <FormLabel textKey={'URL'} essential={false} fontSizeApply={false} />
        <input
          type="text"
          className="form-line"
          placeholder={t('URL 주소를 입력해 주세요.')}
          value={message.linkUrl}
          onChange={(e) =>
            onChangeInput({
              ...message,
              linkUrl: e.target.value,
            })
          }
        />
      </FormGroup>
      <InputFile
        titleKey={'파일 등록'}
        accept={'image/*'}
        onSuccessUpload={(fileId) => {
          onChangeInput({
            ...message,
            imgId: fileId,
          });
        }}
      />
    </>
  );
}

export default AccidentMessageForm;
