import createAsyncDispatcher from '@/utils/asyncActionUtils';
import { fetchUser } from '@/api/user';
import {
  ACCESS_SIGN_IN,
  ACCESS_SIGN_IN_ERROR,
  ACCESS_SIGN_IN_INPUT_CHANGE,
  ACCESS_SIGN_IN_REMEMBER_EMAIL_CHECK_CHANGE,
  ACCESS_SIGN_IN_REMEMBER_EMAIL_SET,
  ACCESS_SIGN_IN_SUCCESS,
  ACCESS_SIGN_IN_VALIDATION_SET,
  Action,
  SignInState,
} from '@/modules/access/signin/types';
import {
  ASYNC_ERROR_STATE,
  ASYNC_LOADING_STATE,
  ASYNC_SUCCESS_STATE,
} from '@/utils/constants/common';
import { User } from '@/modules/user/types';

export function signInReducer(state: SignInState, action: Action) {
  switch (action.type) {
    case ACCESS_SIGN_IN:
      return {
        ...state,
        api: {
          ...ASYNC_LOADING_STATE,
        },
      };
    case ACCESS_SIGN_IN_SUCCESS:
      const data: User = action.data;
      return {
        ...state,
        api: { ...ASYNC_SUCCESS_STATE(data) },
      };
    case ACCESS_SIGN_IN_ERROR:
      return {
        ...state,
        api: {
          ...ASYNC_ERROR_STATE(action.error),
        },
      };
    case ACCESS_SIGN_IN_REMEMBER_EMAIL_SET:
      return {
        ...state,
        inputs: {
          ...state.inputs,
          email: action.email,
        },
        remember: true,
      };
    case ACCESS_SIGN_IN_VALIDATION_SET:
      return {
        ...state,
        validInputs: {
          email: action.validEmail,
          password: action.validPassword,
        },
      };
    case ACCESS_SIGN_IN_REMEMBER_EMAIL_CHECK_CHANGE:
      return {
        ...state,
        remember: action.checked,
      };
    case ACCESS_SIGN_IN_INPUT_CHANGE:
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.key]: action.value,
        },
      };
  }
}

export const getUser = createAsyncDispatcher(ACCESS_SIGN_IN, fetchUser);
