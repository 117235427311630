import axios from 'axios';
import { Config } from '@/config';
import { ApiResult } from '@/modules/common';
import { StatusCodes } from 'http-status-codes';
import {
  StatAppRankStatus,
  StatAppVisitStatus,
  StatLidarForkliftOperationStatus,
  StatLidarVisitCount,
  StatLidarVisitStatus,
  StatLidarVisitStatusByHour,
} from '@/modules/statistics/types';

export async function fetchAppVisitStatus(params: {
  projectId: string;
  mappingId: string;
  mapId: string;
  searchStartDates: string;
  searchEndDates: string;
}) {
  let visitStatusList: StatAppVisitStatus[] = [];

  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/statistics/visits/counts/hour`,
      {
        params,
      }
    );

    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      visitStatusList = result.data as StatAppVisitStatus[];
    }
  } catch (e) {}

  return visitStatusList;
}

export async function fetchAppRankStatus(
  type: 'visits' | 'residences',
  params: {
    projectId: string;
    mappingId: string;
    mapId: string;
    searchStartDates?: string;
  },
  rankCount = 3
) {
  let rankList: StatAppRankStatus[] = [];

  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/statistics/${type}/top`,
      {
        params,
      }
    );

    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      rankList = result.data as StatAppRankStatus[];
      rankList = rankList.slice(0, rankCount);
    }
  } catch (e) {}

  return rankList;
}

export async function fetchLidarForkliftOperationStatus(params: {
  projectId: string;
  mappingId: string;
  mapId: string;
  searchStartDates: string;
  searchEndDates: string;
}) {
  let operationStatusList: StatLidarForkliftOperationStatus[] = [];

  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/statistics/lidar/forklifts/operation-status`,
      {
        params,
      }
    );

    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      operationStatusList = result.data as StatLidarForkliftOperationStatus[];
    }
  } catch (e) {}

  return operationStatusList;
}

export async function fetchLidarVisitCount(params: {
  projectId: string;
  mappingId: string;
  mapId: string;
  searchStartDates: string;
  searchEndDates: string;
}) {
  let visitCountList: StatLidarVisitCount[] = [];

  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/statistics/lidar/visitor-counts/hour`,
      {
        params,
      }
    );

    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      visitCountList = result.data as StatLidarVisitCount[];
    }
  } catch (e) {}

  return visitCountList;
}

export async function fetchLidarVisitStatus(params: {
  projectId: string;
  mappingId: string;
  mapId: string;
  searchStartDates: string;
  searchEndDates: string;
}) {
  let visitStatus: StatLidarVisitStatus = {
    inCount: 0,
    outCount: 0,
    overSpeedCount: 0,
    crashCount: 0,
  };

  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/statistics/lidar/visitor-status`,
      {
        params,
      }
    );

    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      visitStatus = result.data as StatLidarVisitStatus;
    }
  } catch (e) {}

  return visitStatus;
}

export async function fetchLidarVisitStatusByHour(params: {
  projectId: string;
  mappingId: string;
  mapId: string;
  searchStartDates: string;
  searchEndDates: string;
}) {
  let visitStatusByHourList: StatLidarVisitStatusByHour[] = [];

  try {
    const response = await axios.get(
      `${Config.platform_api.uri}/statistics/lidar/visitor-status/hour`,
      {
        params,
      }
    );

    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      visitStatusByHourList = result.data as StatLidarVisitStatusByHour[];
    }
  } catch (e) {}

  return visitStatusByHourList;
}
