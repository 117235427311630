import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '@/components/common/MaterialIcon';
import HistoryButton from '@/components/home/Message/Detail/Info/HistoryButton';
import LocationButton from '@/components/home/Message/Detail/Info/LocationButton';
import { MessageHistoryDetail } from '@/modules/message/types';
import moment from 'moment';

type AccidentInfoProps = {
  messageDetail: MessageHistoryDetail;
};

function AccidentInfo({
  messageDetail: {
    lidarEvent: { eventType, lidarId, velocity, logDate },
  },
}: AccidentInfoProps) {
  const { t } = useTranslation();

  let accidentText = '';
  if (eventType === 'CROSS_LINE') {
    accidentText = t('이탈');
  } else if (eventType === 'OVER_SPEED') {
    accidentText = t('과속');
  }

  return (
    <div className="row mb-4">
      <div className="col-12 col-xl-6">
        <dl className="basic-info">
          <dt>{t('대상')}</dt>
          <dd>{lidarId}</dd>
          {/*<dt>{t('장소/위치')}</dt>*/}
          {/*<dd>Safety Zone 1</dd>*/}
          {/*<dt>{t(`${accidentText} 장소/위치`)}</dt>*/}
          {/*<dd>*/}
          {/*  <LocationButton />*/}
          {/*</dd>*/}
          <dt>{t('속도')}</dt>
          <dd>
            {eventType === 'OVER_SPEED' ? (
              <>
                <span className="text-identity mr-1">{velocity}</span>km/h
              </>
            ) : (
              <>{velocity} km/h</>
            )}
          </dd>
        </dl>
      </div>
      <div className="col-12 col-xl-6">
        <dl className="basic-info">
          <dt>{t(`${accidentText} 시간`)}</dt>
          <dd>{moment(logDate).format('YYYY-MM-DD HH:mm:ss')}</dd>
          {/*
          <dt>{t('현장 알림 시간')}</dt>
          <dd>2022.04.13 18:32:24 (1초)</dd>
          <dt>{t('플랫폼 알림 시간')}</dt>
          <dd>2022.04.13 18:32:26 (3초)</dd>
          <dt>{t('주변 인원')}</dt>
          <dd>3명</dd>
          */}
        </dl>
      </div>
    </div>
  );
}

export default AccidentInfo;
