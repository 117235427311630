import { fetchFloors } from '@/api/space';
import {
  useFloorPlanList,
  useSelectedBuilding,
  useSelectedFloorPlanId,
} from '@/modules/project/hook';
import { useUser } from '@/modules/user/hook';
import React, { ReactElement, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

function FloorPlanList(): ReactElement {
  const { floorPlanList, setFloorPlanList } = useFloorPlanList();
  const { selectedBuilding } = useSelectedBuilding();
  const {
    selectedFloorPlanId,
    setSelectedFloorPlanId,
  } = useSelectedFloorPlanId();
  const { t } = useTranslation();
  const {
    user: { userId },
  } = useUser();
  const directFlagRef = useRef(false);

  useEffect(() => {
    const directFlag = directFlagRef.current;
    if (selectedFloorPlanId) {
      if (!directFlag) {
        directFlagRef.current = true;
      }
    } else if (!directFlag && floorPlanList.length) {
      setSelectedFloorPlanId(floorPlanList[0].map_id);
    }
  }, [floorPlanList]);

  // 평면도 조회
  const fetchFloorList = async () => {
    const result =
      selectedBuilding && (await fetchFloors(userId, selectedBuilding.spaceId));
    result && setFloorPlanList(result);
  };

  // 평면도 선택 이후
  const onChangeFloor = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    setSelectedFloorPlanId(id);
  };

  useEffect(() => {
    fetchFloorList();
  }, [selectedBuilding]);

  return (
    <select
      className="form-line"
      onChange={onChangeFloor}
      value={selectedFloorPlanId}
    >
      <option value="">{t('평면도를 선택해 주세요')}</option>
      {floorPlanList &&
        floorPlanList
          // 평면도의 층이 순서에 맞게 표기되게 처리하기 위해 sort 이후 표기
          .sort(
            (prevFloor, nextFloor) => prevFloor.map_floor - nextFloor.map_floor
          )
          .map((floor) => (
            <option value={floor.map_id} key={floor.map_id}>
              {floor.map_name}
            </option>
          ))}
    </select>
  );
}

export default FloorPlanList;
