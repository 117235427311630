import ModalHeader from 'react-bootstrap/ModalHeader';
import MaterialIcon from '@/components/common/MaterialIcon';
import DaumPostcode, { AddressData } from 'react-daum-postcode';
import { Modal } from 'react-bootstrap';
import React from 'react';
import { LngLat } from '@/modules/common/types';
import { fetchGoogleLocation } from '@/api/common';

type KakaoPostcodeModalCompleteData = {
  address: string;
  extra: string;
  lngLat: LngLat | null;
};

type KakaoPostcodeModalProps = {
  show: boolean;
  onHide: () => void;
  onComplete: (data: KakaoPostcodeModalCompleteData) => void;
};

function KakaoPostcodeModal({
  show,
  onHide,
  onComplete,
}: KakaoPostcodeModalProps) {
  const handleCompletePostcode = async (data: AddressData) => {
    const address = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      extraAddress = extraAddress !== '' ? `(${extraAddress})` : '';
    }

    onHide();
    const lngLat = await fetchGoogleLocation(address);
    onComplete({
      address: data.address,
      extra: extraAddress,
      lngLat,
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        //
      }}
      centered={true}
    >
      <ModalHeader>
        <button type="button" className="close custom-close" onClick={onHide}>
          <span>
            <MaterialIcon name={'clear'} />
          </span>
        </button>
      </ModalHeader>
      <DaumPostcode onComplete={handleCompletePostcode} />
    </Modal>
  );
}

export default KakaoPostcodeModal;
