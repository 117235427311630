export const EVENT_INFO_SETTING = 'EVENT_INFO_SETTING';
export const EVENT_RECEIVE_SETTING = 'EVENT_RECEIVE_SETTING';

export type EventMainCategory = 'POSITION' | 'DETECTION' | 'ACCIDENT';
export const EVENT_STATUS_ACTIVE = 'active';
export const EVENT_STATUS_INACTIVE = 'inactive';
export const EVENT_STATUS_WILL = 'will';
export type EventStatus =
  | typeof EVENT_STATUS_ACTIVE
  | typeof EVENT_STATUS_INACTIVE
  | typeof EVENT_STATUS_WILL;
export type EventStatusCount = {
  eventStatus: EventStatus;
  targetCount: number;
};

export type EventTargetTypeCount = {
  eventTargetType: EventTargetType;
  targetCount: number;
};

export type Event = {
  eventId: string;
  eventTitle: string;
  targetZoneName: string;
  targetZoneId: string;
  eventTargetType: EventTargetType;
  eventDetailCategory: EventDetailCategory;
  eventCondition: EventCondition;
  targetCount: number;
  activeFlag: boolean;
};

export type EventInput = {
  eventTargetType: EventTargetType;
  eventTitle: string;
  zoneId: string;
  eventDetailCategory: EventDetailCategory;
  eventCondition: EventCondition;
  eventOption?: string | number;
};

export const EVENT_TARGET_TYPE_GEO = 'GEO';
export const EVENT_TARGET_TYPE_ASSET = 'ASSET';

export type EventTargetType =
  | typeof EVENT_TARGET_TYPE_GEO
  | typeof EVENT_TARGET_TYPE_ASSET;
export type EventDetailCategory =
  | 'MORE_OR_LESS'
  | 'IN_OR_OUT'
  | 'CHECK_IN_OR_OUT';
export type EventCondition = 'MORE' | 'LESS' | 'IN' | 'OUT';
