import {
  Action,
  PROJECT_CHANGE_EDIT_RELOAD_FLAG,
  PROJECT_CHANGE_OPEN_LAYERS_GEO_IMAGE_ADJUSTMENT,
  PROJECT_CHANGE_PANE_STATUS,
  PROJECT_CHANGE_REGISTER_STEP,
  PROJECT_SET_EDIT_INITIAL_STATE,
  PROJECT_SET_OPEN_LAYERS_INSTANCE,
  PROJECT_SET_REGISTER_FLOOR_LIST,
  PROJECT_SET_REGISTER_INITIAL_STATE,
  PROJECT_SET_REGISTER_ONBOARDING,
  PROJECT_SET_REGISTER_PRODUCE_STEP,
  PROJECT_SET_REGISTER_PROJECT_INFO,
  PROJECT_CHANGE_REGISTER_SELECTED_FLOOR_ID,
  ProjectState,
  PROJECT_SET_UPLOADED_ASSET_LIST_STATE,
  PROJECT_SET_SELECTED_BUILDING_STATE,
  PROJECT_SET_SELECTED_FLOOR_PLAN_ID_STATE,
  PROJECT_SET_BUILDING_INFO_LIST_STATE,
  PROJECT_SET_FLOOR_PLAN_LIST_STATE,
  PROJECT_SET_LOADING_STATE,
  PROJECT_SET_AFTER_ASSET_UPLOAD_STATE,
} from '@/modules/project/types';

export function projectReducer(
  state: ProjectState,
  action: Action
): ProjectState {
  switch (action.type) {
    case PROJECT_CHANGE_PANE_STATUS:
      return {
        ...state,
        paneStatus: action.paneStatus,
      };
    case PROJECT_CHANGE_REGISTER_STEP:
      return {
        ...state,
        register: {
          ...state.register,
          step: action.registerStep,
        },
      };
    case PROJECT_SET_REGISTER_PROJECT_INFO:
      return {
        ...state,
        register: {
          ...state.register,
          info: {
            ...action.projectInfo,
          },
        },
      };
    case PROJECT_SET_REGISTER_INITIAL_STATE:
      return {
        ...state,
        register: {
          step: 1,
          info: {
            projectName: '',
            note: '',
            produceEndFlag: true,
            produceStepData: null,
            onboardingData: [],
          },
          floor: {
            selectedFloorId: '',
            list: [],
          },
        },
      };
    case PROJECT_SET_REGISTER_PRODUCE_STEP:
      return {
        ...state,
        register: {
          ...state.register,
          info: {
            ...state.register.info,
            produceStepData: action.produceStep,
          },
        },
      };
    case PROJECT_SET_REGISTER_ONBOARDING:
      return {
        ...state,
        register: {
          ...state.register,
          info: {
            ...state.register.info,
            onboardingData: action.onboarding,
          },
        },
      };
    case PROJECT_CHANGE_REGISTER_SELECTED_FLOOR_ID:
      return {
        ...state,
        register: {
          ...state.register,
          floor: {
            ...state.register.floor,
            selectedFloorId:
              action.selectedFloorId === state.register.floor.selectedFloorId
                ? ''
                : action.selectedFloorId,
          },
        },
      };
    case PROJECT_SET_REGISTER_FLOOR_LIST:
      return {
        ...state,
        register: {
          ...state.register,
          floor: {
            ...state.register.floor,
            list: action.list,
          },
        },
      };
    case PROJECT_CHANGE_EDIT_RELOAD_FLAG:
      return {
        ...state,
        edit: {
          ...state.edit,
          reloadFlag: action.reloadFlag,
        },
      };
    case PROJECT_SET_EDIT_INITIAL_STATE:
      return {
        ...state,
        edit: {
          reloadFlag: undefined,
        },
      };
    case PROJECT_SET_OPEN_LAYERS_INSTANCE:
      return {
        ...state,
        openLayers: action.openLayers,
      };
    case PROJECT_CHANGE_OPEN_LAYERS_GEO_IMAGE_ADJUSTMENT:
      return {
        ...state,
        openLayers: {
          ...state.openLayers,
          geoImage: {
            ...state.openLayers.geoImage,
            adjustment: action.adjustment,
          },
        },
      };
    case PROJECT_SET_UPLOADED_ASSET_LIST_STATE:
      return {
        ...state,
        uploadedAssetList: [...action.uploadedAssetList],
      };
    case PROJECT_SET_SELECTED_BUILDING_STATE:
      return {
        ...state,
        selectedBuilding: action.selectedBuilding,
      };
    case PROJECT_SET_SELECTED_FLOOR_PLAN_ID_STATE:
      return {
        ...state,
        selectedFloorPlanId: action.selectedFloorPlanId,
      };
    case PROJECT_SET_BUILDING_INFO_LIST_STATE:
      return {
        ...state,
        buildingInfoList: action.buildingInfoList,
      };
    case PROJECT_SET_FLOOR_PLAN_LIST_STATE:
      return {
        ...state,
        floorPlanList: action.floorPlanList,
      };
    case PROJECT_SET_LOADING_STATE:
      return {
        ...state,
        loading: action.loading,
      };
    case PROJECT_SET_AFTER_ASSET_UPLOAD_STATE:
      return {
        ...state,
        afterAssetUpload: action.afterAssetUpload,
      };
  }
}
