import { useEffect, useReducer } from 'react';
import {
  ASYNC_DEFAULT_STATE,
  COOKIE_KEY_WHETHER_TO_SIGN_IN,
  SESSION_STORAGE_KEY_USER_INFO,
} from '@/utils/constants/common';
import { useCookies } from 'react-cookie';
import {
  logoutReducer,
  removeAccessToken,
} from '@/modules/access/logout/reducer';
import axios from 'axios';
import { useUser } from '@/modules/user/hook';
import { useHomeMenu } from '@/modules/home/hook';
import { MENU_IDX_DASHBOARD } from '@/modules/setup/types';
import { User } from '@/modules/user/types';
import { useWindowPopup } from '@/modules/setup/hook';

export function useLogout() {
  const initialState = ASYNC_DEFAULT_STATE;
  const [state, dispatch] = useReducer(logoutReducer, initialState);
  const { user, handleSetUser } = useUser();
  const { handleSetMenu } = useHomeMenu();
  const { handleCloseWindowPopup } = useWindowPopup();
  const { loading, data: logoutStatusCode, error } = state;

  const [cookies, setCookie, removeCookie] = useCookies([
    COOKIE_KEY_WHETHER_TO_SIGN_IN,
  ]);

  const handleLogout = () => {
    removeAccessToken(dispatch, {
      userId: user?.userId,
    });
  };

  useEffect(() => {
    if (logoutStatusCode) {
      handleClearUser();
    }
  }, [logoutStatusCode]);

  const handleClearUser = () => {
    handleCloseWindowPopup();
    removeCookie(COOKIE_KEY_WHETHER_TO_SIGN_IN);
    sessionStorage.removeItem(SESSION_STORAGE_KEY_USER_INFO);
    delete axios.defaults.headers.common['Authorization'];
    handleSetUser({} as User);
    handleSetMenu(MENU_IDX_DASHBOARD);
  };

  return {
    loading,
    logoutStatusCode,
    error,
    handleLogout,
    handleClearUser,
  };
}
