export const CAMPAIGN_STATUS_ACTIVE = '진행';
export const CAMPAIGN_STATUS_INACTIVE = '비활성';
export const CAMPAIGN_STATUS_SCHEDULE = '예정';
export type CampaignStatus =
  | typeof CAMPAIGN_STATUS_ACTIVE
  | typeof CAMPAIGN_STATUS_INACTIVE
  | typeof CAMPAIGN_STATUS_SCHEDULE;

export type Campaign = {
  eventId: string;
  eventTitle: string;
  eventSubject: string;
  eventContent: string;
  eventStatus: CampaignStatus;
  startDate: string;
  endDate: string;
  activeFlag: boolean;
  registDate: string;
  geom?: string;
  geomType?: string;
  lng?: number;
  lat?: number;
  radius?: number;
  areaName: string;
  areaId: string;
  areaGeom: string;
  imgId: string;
  originalFileName: string;
  fileUrl: string;
  fileVolume: number;
  linkUrl: string;
};

export type CampaignInput = {
  title: string;
  period: string[];
  content: string;
  imgId: string;
  zoneId: string;
  campaignType: CampaignType;
  linkUrl: string;
  originalFileName?: string;
};

export type CampaignType = 'message' | 'url';
