import React, { useState } from 'react';
import MaterialIcon from '@/components/common/MaterialIcon';
import { useTranslation } from 'react-i18next';

type ListSearchProps = {
  onSubmit: (searchKeyword: string) => void;
};

function ListSearch({ onSubmit }: ListSearchProps) {
  const { t } = useTranslation();
  const [searchKeyword, setSearchKeyword] = useState('');

  const handleSubmit = () => {
    onSubmit(searchKeyword);
  };

  return (
    <form
      className="search-box"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <input
        type="text"
        className="form-control"
        placeholder={t('Search')}
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
      />
      <a className="btn-search" onClick={handleSubmit}>
        <MaterialIcon name={'search'} />
      </a>
    </form>
  );
}

export default ListSearch;
