import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMapMarkerAlt,
  faSearch,
  faTransgender,
  faMobileAlt,
  faArrowsAltH,
  faCrosshairs,
  faPlus,
  faMinus,
  faServer,
  faBuilding,
  faHospitalAlt,
  faCity,
  faStore,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendarAlt,
  faClock,
  faFlag,
  faMap,
  faBuilding as farBuilding,
  faFolder,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faMapMarkerAlt,
  faSearch,
  faTransgender,
  faMobileAlt,
  faArrowsAltH,
  faCalendarAlt,
  faClock,
  faFlag,
  faCrosshairs,
  faPlus,
  faMinus,
  faMap,
  faServer,
  faBuilding,
  farBuilding,
  faHospitalAlt,
  faCity,
  faStore,
  faFolder
);
