import RightFloatingPane from '@/components/common/RightFloatingPane';
import MapToastAlert from '@/components/common/MapToastAlert';
import { useRightPane } from '@/modules/setup/hook';
import React, { ReactElement, useEffect } from 'react';
import { useHomeProject } from '@/modules/home/hook';
import firebase from 'firebase/app';
import '@firebase/messaging';
import { postProjectPushToken } from '@/api/project';
import {
  FIREBASE_APP_NAME,
  FIREBASE_APP_OPTIONS,
} from '@/utils/constants/common';

let firebaseApp: firebase.app.App;

function MapAlertContainer(): ReactElement {
  const { show: showRightPane } = useRightPane();
  const { project } = useHomeProject();

  const projectId = project?.projectId;

  const handlePostPushToken = async () => {
    if (projectId) {
      if (!firebase.apps.length) {
        firebaseApp = firebase.initializeApp(
          FIREBASE_APP_OPTIONS,
          FIREBASE_APP_NAME
        );
      }

      if (firebaseApp && firebase.messaging.isSupported()) {
        const messaging = firebaseApp.messaging();

        if (messaging) {
          try {
            // await messaging.deleteToken();
          } catch (e) {
          } finally {
            const token = await messaging.getToken();
            if (token) {
              await postProjectPushToken(projectId, token);
            }
          }
        }
      }
    }
  };

  const handleDeletePushToken = async () => {
    if (firebase.apps.length) {
      const firebaseApp = firebase.apps.find(
        (app) => app.name === 'WATA_CONTROL_PLATFORM_APP'
      );

      if (firebaseApp && firebase.messaging.isSupported()) {
        const messaging = firebaseApp.messaging();
        try {
          messaging && (await messaging.deleteToken());
        } catch (e) {}
      }
    }
  };

  useEffect(() => {
    handlePostPushToken();

    return () => {
      handleDeletePushToken();
    };
  }, []);

  return (
    <div className="map-alert-container">
      <MapToastAlert />
      {!showRightPane && <RightFloatingPane />}
    </div>
  );
}

export default MapAlertContainer;
