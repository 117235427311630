import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import MaterialIcon from '@/components/common/MaterialIcon';
import FormLabel from '@/components/common/FormLabel';
import InvalidAlert from '@/components/InvalidAlert';
import { ModalProps } from '@/modules/common';
import { Group } from '@/modules/group/types';
import { putGroup } from '@/api/group';

type GroupManagementEditModalProps = ModalProps & {
  group: Group | null;
};

function GroupManagementEditModal({
  group,
  show,
  onHide,
  onReload,
}: GroupManagementEditModalProps) {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<{
    groupSeq: number;
    groupName: string;
    groupDescription: string;
  }>({
    groupSeq: -1,
    groupName: '',
    groupDescription: '',
  });

  useEffect(() => {
    if (group) {
      setInputs({
        groupSeq: group.groupSeq,
        groupName: group.groupName,
        groupDescription: group.groupDesc,
      });
    }
  }, [group]);

  const [showInvalidMessage, setShowInvalidMessage] = useState(false);

  useEffect(() => {
    if (inputs.groupName && inputs.groupDescription) {
      setShowInvalidMessage(false);
    }
  }, [inputs]);

  const handleSubmit = async () => {
    if (!inputs.groupName || !inputs.groupDescription) {
      setShowInvalidMessage(true);
      return;
    }

    const result = await putGroup(inputs.groupSeq, {
      groupName: inputs.groupName,
      groupDesc: inputs.groupDescription,
    });

    if (result) {
      onReload?.call(null);
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onExited={() => {
        setInputs({
          groupSeq: -1,
          groupName: '',
          groupDescription: '',
        });
      }}
      onHide={() => {
        //
      }}
      centered={true}
      dialogClassName={'modal-custom'}
    >
      <ModalHeader>
        <button type="button" className="close custom-close" onClick={onHide}>
          <span>
            <MaterialIcon name={'clear'} />
          </span>
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="title-group mb-5">
          <h5 className="modal-title">{t('그룹 수정')}</h5>
          <p className="m-0">
            {t('그룹명 또는 설명에 대한 수정을 할 수 있습니다.')}
          </p>
        </div>

        <div className="form-group mb-4">
          <FormLabel
            textKey={'그룹 이름'}
            essential={true}
            htmlFor={'groupName'}
          />
          <input
            id={'groupName'}
            type="text"
            className="form-line pr-6"
            placeholder={t('그룹 이름을 입력해 주세요')}
            value={inputs.groupName}
            onChange={(e) => {
              setInputs({
                ...inputs,
                [e.target.id]: e.target.value,
              });
            }}
            autoComplete={'off'}
          />
        </div>
        <div className="form-group mb-4">
          <FormLabel
            textKey={'그룹 설명'}
            essential={true}
            htmlFor={'groupDescription'}
          />
          <input
            id={'groupDescription'}
            type="text"
            className="form-line pr-6"
            placeholder={t('설명을 입력해 주세요')}
            value={inputs.groupDescription}
            onChange={(e) => {
              setInputs({
                ...inputs,
                [e.target.id]: e.target.value,
              });
            }}
            autoComplete={'off'}
          />
        </div>
      </ModalBody>
      {showInvalidMessage && <InvalidAlert />}
      <ModalFooter>
        <button type="button" className="btn btn-link" onClick={onHide}>
          {t('취소')}
        </button>
        <button type="button" className="btn btn-accent" onClick={handleSubmit}>
          {t('수정')}
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default GroupManagementEditModal;
