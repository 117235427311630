import React, { useState } from 'react';
import { ModalProps } from '@/modules/common';
import { Modal, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import InvalidAlert from '@/components/InvalidAlert';
import AlertModal from '@/components/common/modal/AlertModal';
import { useTranslation } from 'react-i18next';
import EventInfoModalBody from '@/components/event/EventInfoModalBody';
import { EVENT_TARGET_TYPE_GEO, EventInput } from '@/modules/event/types';
import { useHomeSelected } from '@/modules/home/hook';
import { postEvent } from '@/api/event';

type EventRegisterModalProps = ModalProps;

function EventRegisterModal({ onHide, onReload }: EventRegisterModalProps) {
  const { t } = useTranslation();
  const { project, building, floor } = useHomeSelected();
  const [eventInput, setEventInput] = useState<EventInput>({
    eventTargetType: EVENT_TARGET_TYPE_GEO,
    eventTitle: '',
    zoneId: '',
    eventDetailCategory: 'MORE_OR_LESS',
    eventCondition: 'MORE',
  });
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleSubmit = async () => {
    setShowInvalidMessage(false);
    const {
      eventTitle,
      zoneId,
      eventDetailCategory,
      eventCondition,
      eventOption,
    } = eventInput;

    if (!eventTitle || !zoneId) {
      setShowInvalidMessage(true);
      return;
    }

    let result = false;
    if (project && building && floor) {
      result = await postEvent({
        projectId: project.projectId,
        targetMappingId: building.mappingId,
        targetMapId: floor.mapId,
        eventTitle,
        targetZoneId: zoneId,
        eventDetailCategory,
        eventCondition,
        targetCount: Number(eventOption),
      });
    }

    if (result) {
      onReload?.call(null);
      onHide();
    } else {
      setShowAlertModal(true);
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          //
        }}
        centered={true}
      >
        <ModalHeader>
          <ModalTitle as={'h4'}>{t('알림 등록')}</ModalTitle>
        </ModalHeader>
        <EventInfoModalBody
          eventInput={eventInput}
          onChangeEventInput={setEventInput}
        />
        {showInvalidMessage && (
          <InvalidAlert messageKey={'필수 값을 입력해 주세요'} />
        )}
        <ModalFooter>
          <button type="button" className="btn btn-light" onClick={onHide}>
            {t('닫기')}
          </button>
          <button
            type="button"
            className="btn btn-accent"
            onClick={handleSubmit}
          >
            {t('등록')}
          </button>
        </ModalFooter>
      </Modal>
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {t('서버와 통신 중 오류가 발생했습니다')}
      </AlertModal>
    </>
  );
}

export default EventRegisterModal;
