import React, { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { floorReducer } from '@/modules/floor/reducer';
import { Action, FloorState } from '@/modules/floor/types';

export const FloorStateContext = createContext<FloorState | null>(null);
type FloorDispatch = Dispatch<Action>;
export const FloorDispatchContext = createContext<FloorDispatch | null>(null);

export const initialState: FloorState = {
  areaList: [],
  geofenceList: [],
};

export function FloorContextProvider({ children }: { children: ReactNode }) {
  const [floor, dispatch] = useReducer(floorReducer, initialState);

  return (
    <FloorDispatchContext.Provider value={dispatch}>
      <FloorStateContext.Provider value={floor}>
        {children}
      </FloorStateContext.Provider>
    </FloorDispatchContext.Provider>
  );
}
