import React, { useEffect, useRef } from 'react';
import { ionRangeSlider } from '@/static/js/rangeslider';

type RangeSliderProps = {
  type?: 'single' | 'double';
  min?: number;
  max?: number;
  from?: number;
  to?: number;
  grid?: boolean;
  onChange?: (data: { from: number; to: number }) => void;
};

function RangeSlider(props: RangeSliderProps) {
  const input = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (input.current) {
      ionRangeSlider(input.current, {
        ...props,
      });
    }
  }, []);

  return <input type={'text'} ref={input} />;
}

export default RangeSlider;
