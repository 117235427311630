import axios from 'axios';
import { Config } from '@/config';
import { ApiResult, ExcelCode, LngLat } from '@/modules/common';
import { StatusCodes } from 'http-status-codes';
import { saveAs } from 'file-saver';
import { Client } from '@googlemaps/google-maps-services-js';
import { GOOGLE_API_KEY } from '@/utils/constants/common';
import { OpenLayersUtils } from '@/utils/OpenLayersUtils';

export async function postFile(
  file: File,
  onUploadProgress?: (progressEvent: any) => void
): Promise<string | null> {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(
      `${Config.platform_api.uri}/common/images/single`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      }
    );

    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      return result.data as string;
    }
  } catch (e) {}

  return null;
}

export async function fetchImageDownload(imgId: string, fileName: string) {
  const response = await axios.get(
    `${Config.platform_api.uri}/common/images/${imgId}`,
    {
      responseType: 'blob',
    }
  );

  if (response.status === StatusCodes.OK) {
    saveAs(response.data, fileName);
  }
}

export async function fetchImage(imgId: string) {
  const response = await axios.get(
    `${Config.platform_api.uri}/common/images/view/${imgId}`
  );

  if (response.status === StatusCodes.OK) {
    return response.data;
  }
}

export async function fetchGoogleLocation(address: string) {
  const client = new Client();

  const geocodeResponse = await client.geocode({
    params: {
      key: GOOGLE_API_KEY,
      address,
    },
  });

  if (geocodeResponse.status === StatusCodes.OK) {
    const data = geocodeResponse.data;
    if (data.status === 'OK' && data.results.length) {
      const result = data.results[0];
      const location = result.geometry.location;
      const convertLngLat = OpenLayersUtils.convertCoordinates([
        location.lng,
        location.lat,
      ]);

      return {
        lng: convertLngLat[0],
        lat: convertLngLat[1],
      } as LngLat;
    }
  }

  return null;
}

export async function postExcelUpload<T>(code: ExcelCode, file: File) {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response = await axios.post(
      `${Config.platform_api.uri}/common/excel-uploader`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          code,
        },
      }
    );

    const result: ApiResult = response.data;
    if (result.status === StatusCodes.OK) {
      return result.data as T;
    }
  } catch (e) {}

  return null;
}
