import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonUtils } from '@/utils';

type AccidentTimeProps = {
  activeFlag: boolean;
  registDate: string;
  updateDate: string;
};

function AccidentTime({
  activeFlag,
  registDate,
  updateDate,
}: AccidentTimeProps) {
  const { t } = useTranslation();
  const [elapsedTime, setElapsedTime] = useState('00:00:00');

  const calcElapsedTime = useCallback(() => {
    let time = '';
    if (activeFlag) {
      time = CommonUtils.calcElapsedTime(registDate);
    } else {
      time = CommonUtils.calcElapsedTime(registDate, updateDate);
    }
    setElapsedTime(time);
  }, [registDate]);

  useEffect(() => {
    calcElapsedTime();

    let interval: ReturnType<typeof setInterval>;
    if (activeFlag) {
      interval = setInterval(() => {
        calcElapsedTime();
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [registDate]);

  return (
    <div className="emg-con text-center">
      <em className="time mb-2">{elapsedTime}</em>
      <p className="desc m-0">
        {activeFlag && t('경과 중')}
        {!activeFlag && t('종료됨')}
      </p>
    </div>
  );
}

export default AccidentTime;
