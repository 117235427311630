import React, { useEffect, useState } from 'react';
import { Nav, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import {
  MONITORING_TYPE_APP,
  MONITORING_TYPE_LIDAR,
  MonitoringType,
} from '@/modules/monitoring/types';
import { useTranslation } from 'react-i18next';
import {
  useMonitoring,
  useMonitoringGeofence,
} from '@/modules/monitoring/hook';
import MonitoringRealtimeFilter from '@/components/monitoring/MonitoringRealtimeFilter';
import { Feature } from 'ol';
import {
  FLOOR_OBJECT_TYPE_AREA,
  FLOOR_OBJECT_TYPE_GEOFENCE,
} from '@/modules/floor/types';
import _ from 'lodash';
import {
  useOpenLayersArea,
  useOpenLayersDraw,
  useOpenLayersGeofence,
} from '@/modules/openlayers/hook';
import MonitoringAppEndPointUserRealtimeStatus from '@/components/monitoring/MonitoringAppEndPointUserRealtimeStatus';
import MonitoringLidarRealtimeStatus from '@/components/monitoring/MonitoringLidarRealtimeStatus';

function MonitoringRightFloatingPane() {
  const { t } = useTranslation();
  const { monitoringType, handleChangeMonitoringType } = useMonitoring();
  const { selectedGeofenceFeatureList } = useMonitoringGeofence();
  const { getAreaStyle } = useOpenLayersArea();
  const { geofence, getGeofenceStyle } = useOpenLayersGeofence();
  const { drawLayer } = useOpenLayersDraw();
  const [objectFeatureFilterList, setObjectFeatureFilterList] = useState<
    Feature[]
  >([]);

  useEffect(() => {
    const areaObjectFeatureList = objectFeatureFilterList.filter(
      (objectFeature) => {
        return objectFeature.get('type') === FLOOR_OBJECT_TYPE_AREA;
      }
    );

    setObjectFeatureFilterList(
      _.uniqBy(
        [...areaObjectFeatureList, ...selectedGeofenceFeatureList],
        (e) => {
          return e.getId();
        }
      )
    );
  }, [selectedGeofenceFeatureList]);

  useEffect(() => {
    const geofenceLayer = geofence.layer;
    if (drawLayer && geofenceLayer) {
      handleInitFeature();
      const drawSource = drawLayer.getSource();
      const geofenceSource = geofenceLayer.getSource();
      objectFeatureFilterList.forEach((objectFeature) => {
        const objectType = objectFeature.get('type');
        const id = objectFeature.getId();
        if (objectType === FLOOR_OBJECT_TYPE_AREA) {
          objectFeature.setStyle(getAreaStyle());
          drawSource.addFeature(objectFeature);
        } else if (objectType == FLOOR_OBJECT_TYPE_GEOFENCE) {
          const feature = id && geofenceSource.getFeatureById(id);
          feature && feature.setStyle(getGeofenceStyle(feature, true));
        }
      });
    }
  }, [objectFeatureFilterList]);

  const handleInitFeature = () => {
    const geofenceLayer = geofence.layer;
    drawLayer?.getSource().clear();
    if (geofenceLayer) {
      const source = geofenceLayer.getSource();
      source.getFeatures().forEach((paintedFeature) => {
        paintedFeature.setStyle(getGeofenceStyle(paintedFeature, false));
      });
    }
  };

  const handleSelectTab = (eventKey: string | null) => {
    eventKey && handleChangeMonitoringType(eventKey as MonitoringType);
  };

  return (
    <>
      <TabContainer
        defaultActiveKey={monitoringType}
        onSelect={handleSelectTab}
      >
        <div className="alert alert-white alert-dismissible p-0 mb-0 mt-3 ml-auto">
          <Nav className="nav tab-menu the-num01 border-bottom-1 mb-2">
            <Nav.Link
              eventKey={MONITORING_TYPE_LIDAR}
              className="btn-style_underline py-3"
            >
              <span className="menu-cover">
                <span>{t('라이다 센서')}</span>
              </span>
            </Nav.Link>
            {/*
            <Nav.Link
              eventKey={MONITORING_TYPE_APP}
              className="btn-style_underline py-3"
            >
              <span className="menu-cover">
                <span>{t('앱 측위')}</span>
              </span>
            </Nav.Link>
            */}
          </Nav>
          <TabContent>
            <MonitoringRealtimeFilter
              objectFeatureFilterList={objectFeatureFilterList}
              onChangeObjectFeatureFilterList={setObjectFeatureFilterList}
            />
            <TabPane eventKey={MONITORING_TYPE_LIDAR} mountOnEnter={true}>
              <MonitoringLidarRealtimeStatus
                className={'p-3'}
                objectFeatureFilterList={objectFeatureFilterList}
              />
            </TabPane>
            <TabPane eventKey={MONITORING_TYPE_APP} mountOnEnter={true}>
              <MonitoringAppEndPointUserRealtimeStatus
                className={'p-3'}
                objectFeatureFilterList={objectFeatureFilterList}
              />
            </TabPane>
          </TabContent>
        </div>
      </TabContainer>
    </>
  );
}

export default MonitoringRightFloatingPane;
