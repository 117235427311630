import React, { useEffect } from 'react';
import { ProjectSpace } from '@/modules/project/types';
import classNames from 'classnames';
import { useHomeBuilding, useHomeProject } from '@/modules/home/hook';

function HomeBuildingSelect() {
  const { project } = useHomeProject();
  const {
    building: monitoringBuilding,
    handleChangeBuilding,
  } = useHomeBuilding();

  useEffect(() => {
    project?.buildings.length && handleChangeBuilding(project?.buildings[0]);
  }, [project]);

  return (
    <>
      <div className="container-fluid border-bottom-1 py-4">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row align-items-center mb-1">
            <span className="material-icons cp-icon">factory</span>
            <p className="m-0 text-64">-</p>
          </div>
          <h3 className="mb-0">{project?.projectName}</h3>
        </div>
      </div>
      <div className="plf-floor-card p-4 position-relative">
        {project?.buildings.map((building) => (
          <HomeBuildingItem
            key={building.metaId}
            building={building}
            selectedBuildingId={monitoringBuilding?.metaId || ''}
            onClickItem={() => handleChangeBuilding(building)}
          />
        ))}
      </div>
    </>
  );
}

type HomeBuildingItemProps = {
  building: ProjectSpace;
  selectedBuildingId: string;
  onClickItem: () => void;
};

function HomeBuildingItem({
  building: { metaId, metaName, floors },
  selectedBuildingId,
  onClickItem,
}: HomeBuildingItemProps) {
  return (
    <div
      className={classNames('plf-area-card', {
        active: metaId === selectedBuildingId,
      })}
      onClick={onClickItem}
    >
      <div className="plf-area-top">
        <h4>{metaName}</h4>
      </div>
      <div className="plf-area-content">
        <div className="d-flex align-items-center">
          <span className="material-icons-outlined font-size-16pt mr-1">
            map
          </span>
          {floors.length}
        </div>
      </div>
    </div>
  );
}

export default HomeBuildingSelect;
