import _ from 'lodash';
import BaseConfig from './default';

const Config = _.cloneDeep(BaseConfig);

// development, production
if (process.env.REACT_APP_ENV) {
  try {
    (async () => {
      const EnvConfig = await import(`./${process.env.REACT_APP_ENV}`);
      _.merge(Config, EnvConfig.default);
    })();
  } catch (e) {
    console.warn(`Cannot find configs for env=${process.env.REACT_APP_ENV}`);
  }
}

export { Config };
