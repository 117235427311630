import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postFile } from '@/api/common';
import FormLabel from '@/components/common/FormLabel';
import FormGroup from '@/components/common/FormGroup';
import MaterialIcon from '@/components/common/MaterialIcon';
import classNames from 'classnames';
import { CommonUtils } from '@/utils';
import UploadProgressStatusIndicator from './UploadProgressStatusIndicator';

type InputFileProps = {
  titleKey?: string;
  accept?: string;
  formGroupClassName?: string;
  onSuccessUpload: (fileId: string) => void;
  originalFileName?: string;
};

function InputFile({
  titleKey,
  accept,
  formGroupClassName,
  onSuccessUpload,
  originalFileName,
}: InputFileProps): ReactElement {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [progressStatus, setProgressStatus] = useState<number>(0);

  const handlePostFile = async (file: File) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileId = await postFile(
      file,
      CommonUtils.uploadProgressConfig(setProgressStatus)
    );

    if (fileId) {
      setFile(file);
      onSuccessUpload(fileId);
    }
  };

  const handleCancelFile = () => {
    setFile(null);
    onSuccessUpload('');
    setProgressStatus(0);
  };

  return (
    <FormGroup className={classNames('over-txt', formGroupClassName)}>
      {titleKey && <FormLabel textKey={titleKey} />}
      {originalFileName && (
        <InputFileView
          fileName={originalFileName}
          onClickCancel={handleCancelFile}
        />
      )}
      {!originalFileName && (
        <>
          {!file && (
            <>
              {progressStatus ? (
                <UploadProgressStatusIndicator
                  progressStatus={progressStatus}
                />
              ) : (
                <>
                  <input type="text" className="form-line" disabled={true} />
                  <div className="btn-abs btn-file-att">
                    <label className="btn-light btn-sm m-0" htmlFor="inputFile">
                      {t('파일 선택')}
                    </label>
                    <input
                      type="file"
                      id="inputFile"
                      accept={accept}
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length) {
                          handlePostFile(e.target.files[0]);
                        } else {
                          handleCancelFile();
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
          {file && (
            <InputFileView
              fileName={file.name}
              onClickCancel={handleCancelFile}
            />
          )}
        </>
      )}
    </FormGroup>
  );
}

type InputFileViewProps = {
  fileName: string;
  onClickCancel: () => void;
};

function InputFileView({ fileName, onClickCancel }: InputFileViewProps) {
  return (
    <>
      <div className="form-line">
        <div className="att-file">
          <p className="file-name">{fileName}</p>
          <div className="bundle">
            <a onClick={onClickCancel}>
              <MaterialIcon name={'close'} className={'ml-2'} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default InputFile;
