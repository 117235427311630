import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '@/components/common/MaterialIcon';
import classNames from 'classnames';
import {
  MONITORING_GEO_OBJECT_TYPE_AREA,
  MONITORING_GEO_OBJECT_TYPE_UTIL,
  MONITORING_GEO_OBJECT_TYPE_GEOFENCE,
  MONITORING_GEO_OBJECT_TYPE_POI,
  MonitoringGeoObjectType,
} from '@/modules/monitoring/types';
import { useHomeFloor } from '@/modules/home/hook';

type MonitoringGeoObjectFilterProps = {
  filterList: MonitoringGeoObjectType[];
  onChangeFilterList: (filterGeoObjectType: MonitoringGeoObjectType[]) => void;
  onSubmit: (searchKeyword: string) => void;
};

function MonitoringGeoObjectFilter({
  filterList,
  onChangeFilterList,
  onSubmit,
}: MonitoringGeoObjectFilterProps) {
  const { t } = useTranslation();
  const { floor } = useHomeFloor();
  const [searchKeyword, setSearchKeyword] = useState('');
  const mountedRef = useRef(false);
  const filterObjectListRef = useRef<
    { filterKey: MonitoringGeoObjectType | ''; textKey: string }[]
  >([
    {
      filterKey: '',
      textKey: '전체',
    },
    {
      filterKey: MONITORING_GEO_OBJECT_TYPE_GEOFENCE,
      textKey: '지오펜스',
    },
    {
      filterKey: MONITORING_GEO_OBJECT_TYPE_AREA,
      textKey: '구역',
    },
    {
      filterKey: MONITORING_GEO_OBJECT_TYPE_POI,
      textKey: 'POI',
    },
    {
      filterKey: MONITORING_GEO_OBJECT_TYPE_UTIL,
      textKey: '장비',
    },
  ]);

  useEffect(() => {
    onChangeFilterList(filterList);
  }, [filterList]);

  useEffect(() => {
    const mounted = mountedRef.current;
    if (mounted) {
      setSearchKeyword('');
      onChangeFilterList([]);
      onSubmit('');
    } else {
      mountedRef.current = true;
    }
  }, [floor]);

  const handleChangeFilterGeoObject = (
    filterGeoObjectKey: MonitoringGeoObjectType | ''
  ) => {
    if (!filterGeoObjectKey) {
      onChangeFilterList([]);
    } else {
      let newFilterObjectList: MonitoringGeoObjectType[];
      if (filterList.includes(filterGeoObjectKey)) {
        newFilterObjectList = filterList.filter(
          (filterKey) => filterGeoObjectKey !== filterKey
        );
      } else {
        newFilterObjectList = [...filterList, filterGeoObjectKey];
      }

      onChangeFilterList(newFilterObjectList);
    }
  };

  const handleSubmit = () => {
    onSubmit(searchKeyword);
  };

  return (
    <>
      <div className="plf-floor-add border-bottom-0">
        <div className="d-flex align-items-sm-center pb-3">
          <div className="swipe-wrap">
            <ul className="swipe-list">
              {filterObjectListRef.current.map((filterObject) => (
                <MonitoringGeoObjectFilterItem
                  key={filterObject.filterKey}
                  {...filterObject}
                  filterList={filterList}
                  onClickItem={() =>
                    handleChangeFilterGeoObject(filterObject.filterKey)
                  }
                />
              ))}
            </ul>
          </div>
        </div>
        <div className="search-box mb-0">
          <input
            type="text"
            className="form-line"
            placeholder={t('오브젝트명을 입력해 주세요')}
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
          <a className="btn-search circle-pin" onClick={handleSubmit}>
            <MaterialIcon name={'search'} />
          </a>
        </div>
      </div>
    </>
  );
}

type MonitoringGeoObjectFilterItemProps = {
  filterKey: any;
  textKey: string;
  filterList: any[];
  onClickItem: () => void;
};

function MonitoringGeoObjectFilterItem({
  filterKey,
  textKey,
  filterList,
  onClickItem,
}: MonitoringGeoObjectFilterItemProps) {
  const { t } = useTranslation();

  return (
    <li>
      <a
        className={classNames({
          active: filterKey
            ? filterList.includes(filterKey)
            : !filterList.length,
        })}
        onClick={onClickItem}
      >
        {t(textKey)}
      </a>
    </li>
  );
}

export default MonitoringGeoObjectFilter;
