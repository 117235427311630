import { PaneStatus, PANE_STATUS_REGISTER } from '@/utils/constants/common';
import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFloatPane } from '@/modules/setup/hook';
import { postExcelUpload } from '@/api/common';
import { AssetExcelType } from '@/modules/assets/types';
import {
  useLoading,
  useSelectedBuilding,
  useSelectedFloorPlanId,
  useUploadAssetListExcel,
} from '@/modules/project/hook';

type RegisterButtonContainerProps = {
  setPaneStatus: (paneStatus: PaneStatus) => void;
};

function RegisterButtonContainer({
  setPaneStatus,
}: RegisterButtonContainerProps): ReactElement {
  const { t } = useTranslation();
  const { setUploadedAssetList } = useUploadAssetListExcel();
  const { selectedBuilding } = useSelectedBuilding();
  const { selectedFloorPlanId } = useSelectedFloorPlanId();
  const { show: showFloatPane, handleChangeShow } = useFloatPane();
  const { setLoading } = useLoading();
  const excelUploadFileRef = useRef<HTMLInputElement>(null);

  // Excel 업로드 관련 로직 처리
  const handleExcelUpload = async (file: File) => {
    const excelUploadFile = excelUploadFileRef.current;
    excelUploadFile && (excelUploadFile.value = '');
    setLoading(true);
    const result = await postExcelUpload('ASSET', file);
    if (result) {
      const assetListUploadResult = result as AssetExcelType[];
      setUploadedAssetList(
        assetListUploadResult
          .filter((asset) => asset.assetInfoName)
          .map((asset, index) => ({
            ...asset,
            id: index,
            field: index,
            assetTypeErrorMessage: '',
            assetClassificationErrorMessage: '',
            assetNameErrorMessage: '',
            serialIdErrorMessage: '',
          }))
      );
      setLoading(false);
    }
  };

  // 엑셀 업로드 시
  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length) {
      handleExcelUpload(files[0]);
    }
    handleChangeShow(true);
  };

  return (
    <div className="btn-cell">
      <a
        className="btn-add-item text-green"
        onClick={() => {
          selectedBuilding?.mappingId && selectedFloorPlanId
            ? excelUploadFileRef.current?.click()
            : alert(t('건물과 평면도를 모두 선택해주세요'));
        }}
      >
        <span className="material-icons">note_add</span>
        {t('엑셀 업로드')}
        <input
          type="file"
          className="d-none"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ref={excelUploadFileRef}
          onChange={onChangeFile}
        />
      </a>
      <a
        className="btn-add-item text-identity"
        onClick={() => {
          if (showFloatPane) {
            alert(t('일괄 등록 중에는 단건으로 등록할 수 없습니다'));
          } else {
            selectedBuilding?.mappingId && selectedFloorPlanId
              ? setPaneStatus(PANE_STATUS_REGISTER)
              : alert(t('건물과 평면도를 모두 선택해주세요'));
          }
        }}
      >
        <span className="material-icons">add</span>
        {t('자산 등록')}
      </a>
    </div>
  );
}

export default RegisterButtonContainer;
