export class ValidUtils {
  /**
   * @description 이메일 형식 허용
   * @param input
   */
  static validateEmail(input: string) {
    const regEx = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    return regEx.test(input);
  }

  /**
   * @description 문자, 숫자, 특수문자, 길이 8~16 허용
   * @param input
   */
  static validatePassword(input: string) {
    const regEx = /^[A-Za-z0-9!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/]{8,16}$/;
    return regEx.test(input);
  }

  /**
   * @description 한글인지 판단
   * @param input
   */
  static validateHangul(input: string) {
    const regEx = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]$/;
    return regEx.test(input);
  }

  static validProjectName(input: string) {
    const regEx = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|ぁ-ゔァ-ヴー々〆〤一-龥|]+$/;
    return regEx.test(input);
  }

  static validBuildingName(input: string) {
    const regEx = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|ぁ-ゔァ-ヴー々〆〤一-龥|\-#&()]+$/;
    return regEx.test(input);
  }
}
