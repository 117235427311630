import { PaneStatus } from '@/utils/constants/common';
import { Feature, Map } from 'ol';
import { Image } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Interaction } from 'ol/interaction';
import { ConnectedSpaceInfo, DrawGeoImage } from '@/modules/space/types';
import { ResponseFloorInfo } from '@/api/space';
import { AssetExcelType } from '../assets/types';

export type OpenLayers = {
  map: Map | null;
  drawSource: VectorSource | null;
  geofencingLayer: Image | null;
  geoImage: {
    layer: any | null;
    draw: (options: DrawGeoImage, callback?: () => void) => void;
    drawFromSquare: (options: DrawGeoImage, squareFeature: Feature) => void;
    remove: () => void;
    adjustment: boolean | undefined;
  };
  transform: {
    draw: (() => void) | undefined;
    remove: (() => void) | undefined;
  };
  draw: {
    square: Interaction | null;
  };
};

export type ProjectSpaceLocation = {
  projectId: string;
  projectName: string;
  lng: number;
  lat: number;
};

export type ProjectStepData = {
  produceStepData?: string | null;
  onboardingData?: string | null;
};

export type ProjectProduceStep = {
  step?: number;
  metaId?: string;
};

export type ProjectInfo = {
  projectId?: string;
  projectName?: string;
  note?: string;
  imgId?: string;
  projectImgFileName?: string;
  produceEndFlag?: boolean;
  produceStepData?: ProjectProduceStep | null;
  onboardingData?: ProjectOnboarding;
  positioningType?: string;
  viewType?: string;
};

export type ProjectSpaceFloor = {
  mapId: string;
  mapName: string;
  mapFloor: number;
  cx?: number;
  cy?: number;
  filename?: string;
  rotation?: number;
  scalex?: number;
  scaley?: number;
  opacity?: number;
};

export type ProjectSpace = {
  mappingId: string;
  metaId: string;
  metaName: string;
  lng: number;
  lat: number;
  floors: ProjectSpaceFloor[];
  registDate: string;
};

export type ProjectDetail = ProjectThumbnail & {
  projectId: string;
  projectName: string;
  note: string;
  buildings: ProjectSpace[];
  produceStep: ProjectProduceStep | null;
  onboarding: ProjectOnboarding;
  produceEndFlag: boolean;
  positioningType: string;
  viewType: ProjectViewType;
};

export type Project = {
  projectId: string;
  projectName: string;
  registDate: string;
  updateDate: string;
  produceEndFlag: boolean;
  imgUrl: string;
  buildingCount: number;
  assetCount: number;
};

export type ProjectThumbnail = {
  imgId?: string;
  imgUrl?: string;
  projectImgFileName?: string;
};

export const PROJECT_POSITIONING_TYPE_HYBRID = 'HYBRID';
export const PROJECT_POSITIONING_TYPE_LIDAR = 'LIDAR';

export type ProjectPositioningType =
  | typeof PROJECT_POSITIONING_TYPE_HYBRID
  | typeof PROJECT_POSITIONING_TYPE_LIDAR;

export const PROJECT_VIEW_TYPE_2D = 'A';
export const PROJECT_VIEW_TYPE_2D_3D = 'B';

export type ProjectViewType =
  | typeof PROJECT_VIEW_TYPE_2D
  | typeof PROJECT_VIEW_TYPE_2D_3D;

export type Projects = Project[];

export type ProjectState = {
  paneStatus: PaneStatus;
  register: {
    step: number;
    info: ProjectInfo;
    floor: {
      selectedFloorId: string;
      list: ResponseFloorInfo[];
    };
  };
  edit: {
    reloadFlag: undefined | boolean;
  };
  openLayers: OpenLayers;
  uploadedAssetList: AssetExcelType[];
  selectedBuilding: ConnectedSpaceInfo | null;
  selectedFloorPlanId: string;
  buildingInfoList: ConnectedSpaceInfo[];
  floorPlanList: ResponseFloorInfo[];
  loading: boolean;
  afterAssetUpload: boolean;
};

export const PROJECT_ONBOARDING_CATEGORY_PROJECT_INFO = 'PROJECT_INFO';
export const PROJECT_ONBOARDING_CATEGORY_BUILDING_INFO = 'BUILDING_INFO';
export const PROJECT_ONBOARDING_CATEGORY_FLOOR_PLAN_IMAGE = 'FLOOR_PLAN_IMAGE';
export const PROJECT_ONBOARDING_CATEGORY_EDITOR_PLACE_OBJECT =
  'EDITOR_PLACE_OBJECT';

export type ProjectOnboardingCategory =
  | typeof PROJECT_ONBOARDING_CATEGORY_PROJECT_INFO
  | typeof PROJECT_ONBOARDING_CATEGORY_BUILDING_INFO
  | typeof PROJECT_ONBOARDING_CATEGORY_FLOOR_PLAN_IMAGE
  | typeof PROJECT_ONBOARDING_CATEGORY_EDITOR_PLACE_OBJECT;

export type ProjectOnboarding = ProjectOnboardingCategory[];

export const PROJECT_CHANGE_PANE_STATUS = 'PROJECT/CHANGE_PANE_STATUS' as const;
export const PROJECT_CHANGE_REGISTER_STEP = 'PROJECT/CHANGE_REGISTER_STEP' as const;
export const PROJECT_SET_REGISTER_PROJECT_INFO = 'PROJECT/SET_REGISTER_PROJECT_INFO' as const;
export const PROJECT_SET_REGISTER_INITIAL_STATE = 'PROJECT/SET_REGISTER_INITIAL_STATE' as const;
export const PROJECT_SET_REGISTER_PRODUCE_STEP = 'PROJECT/SET_REGISTER_PRODUCE_STEP' as const;
export const PROJECT_SET_REGISTER_ONBOARDING = 'PROJECT/SET_REGISTER_ONBOARDING' as const;
export const PROJECT_CHANGE_REGISTER_SELECTED_FLOOR_ID = 'PROJECT/CHANGE_REGISTER_SELECTED_FLOOR_ID' as const;
export const PROJECT_SET_REGISTER_FLOOR_LIST = 'PROJECT/SET_REGISTER_FLOOR_LIST' as const;
export const PROJECT_CHANGE_EDIT_RELOAD_FLAG = 'PROJECT/CHANGE_EDIT_RELOAD_FLAG' as const;
export const PROJECT_SET_EDIT_INITIAL_STATE = 'PROJECT/SET_EDIT_INITIAL_STATE' as const;
export const PROJECT_SET_OPEN_LAYERS_INSTANCE = 'PROJECT/SET_OPEN_LAYERS_INSTANCE' as const;
export const PROJECT_CHANGE_OPEN_LAYERS_GEO_IMAGE_ADJUSTMENT = 'PROJECT/CHANGE_OPEN_LAYERS_GEO_IMAGE_ADJUSTMENT' as const;
export const PROJECT_SET_UPLOADED_ASSET_LIST_STATE = 'PROJECT/PROJECT_SET_UPLOADED_ASSET_LIST_STATE' as const;
export const PROJECT_SET_SELECTED_BUILDING_STATE = 'PROJECT/PROJECT_SET_SELECTED_BUILDING_STATE' as const;
export const PROJECT_SET_SELECTED_FLOOR_PLAN_ID_STATE = 'PROJECT/PROJECT_SET_SELECTED_FLOOR_PLAN_ID_STATE' as const;
export const PROJECT_SET_BUILDING_INFO_LIST_STATE = 'PROJECT/PROJECT_SET_BUILDING_INFO_LIST_STATE' as const;
export const PROJECT_SET_FLOOR_PLAN_LIST_STATE = 'PROJECT/PROJECT_SET_FLOOR_PLAN_LIST_STATE' as const;
export const PROJECT_SET_LOADING_STATE = 'PROJECT/PROJECT_SET_LOADING_STATE' as const;
export const PROJECT_SET_AFTER_ASSET_UPLOAD_STATE = 'PROJECT/PROJECT_SET_AFTER_ASSET_UPLOAD_STATE' as const;

export type Action =
  | {
      type: typeof PROJECT_CHANGE_PANE_STATUS;
      paneStatus: PaneStatus;
    }
  | {
      type: typeof PROJECT_CHANGE_REGISTER_STEP;
      registerStep: number;
    }
  | {
      type: typeof PROJECT_SET_REGISTER_PROJECT_INFO;
      projectInfo: ProjectInfo;
    }
  | {
      type: typeof PROJECT_SET_REGISTER_INITIAL_STATE;
    }
  | {
      type: typeof PROJECT_SET_REGISTER_PRODUCE_STEP;
      produceStep: ProjectProduceStep;
    }
  | {
      type: typeof PROJECT_SET_REGISTER_ONBOARDING;
      onboarding: ProjectOnboarding;
    }
  | {
      type: typeof PROJECT_CHANGE_REGISTER_SELECTED_FLOOR_ID;
      selectedFloorId: string;
    }
  | {
      type: typeof PROJECT_SET_REGISTER_FLOOR_LIST;
      list: ResponseFloorInfo[];
    }
  | {
      type: typeof PROJECT_CHANGE_EDIT_RELOAD_FLAG;
      reloadFlag: undefined | boolean;
    }
  | {
      type: typeof PROJECT_SET_EDIT_INITIAL_STATE;
    }
  | {
      type: typeof PROJECT_SET_OPEN_LAYERS_INSTANCE;
      openLayers: OpenLayers;
    }
  | {
      type: typeof PROJECT_CHANGE_OPEN_LAYERS_GEO_IMAGE_ADJUSTMENT;
      adjustment: boolean | undefined;
    }
  | {
      type: typeof PROJECT_SET_UPLOADED_ASSET_LIST_STATE;
      uploadedAssetList: AssetExcelType[];
    }
  | {
      type: typeof PROJECT_SET_SELECTED_BUILDING_STATE;
      selectedBuilding: ConnectedSpaceInfo | null;
    }
  | {
      type: typeof PROJECT_SET_SELECTED_FLOOR_PLAN_ID_STATE;
      selectedFloorPlanId: string;
    }
  | {
      type: typeof PROJECT_SET_BUILDING_INFO_LIST_STATE;
      buildingInfoList: ConnectedSpaceInfo[];
    }
  | {
      type: typeof PROJECT_SET_FLOOR_PLAN_LIST_STATE;
      floorPlanList: ResponseFloorInfo[];
    }
  | {
      type: typeof PROJECT_SET_LOADING_STATE;
      loading: boolean;
    }
  | {
      type: typeof PROJECT_SET_AFTER_ASSET_UPLOAD_STATE;
      afterAssetUpload: boolean;
    };
