import {
  Action,
  FLOOR_SET_AREA_LIST,
  FLOOR_SET_GEOFENCE_LIST,
  FloorState,
} from '@/modules/floor/types';

export function floorReducer(state: FloorState, action: Action) {
  switch (action.type) {
    case FLOOR_SET_AREA_LIST:
      return {
        ...state,
        areaList: action.areaList,
      };
    case FLOOR_SET_GEOFENCE_LIST:
      return {
        ...state,
        geofenceList: action.geofenceList,
      };
  }
}
