import React from 'react';
import { useHomeMenu } from '@/modules/home/hook';
import {
  MENU_IDX_ACCOUNT,
  MENU_IDX_DASHBOARD,
  MENU_IDX_GROUP_SETTINGS_GROUP,
  MENU_IDX_GROUP_SETTINGS_USER,
} from '@/modules/setup/types';
import HomeDashboard from '@/components/home/HomeDashboard';
import HomeMap from '@/components/home/HomeMap';
import HomeAccount from '@/components/home/HomeAccount';
import GroupManagement from '@/components/GroupManagement';
import UserManagement from '@/components/UserManagement';

function HomeContent() {
  const { activeMenuIdx } = useHomeMenu();

  let isMap = true;
  switch (activeMenuIdx) {
    case MENU_IDX_DASHBOARD:
    case MENU_IDX_ACCOUNT:
    case MENU_IDX_GROUP_SETTINGS_USER:
    case MENU_IDX_GROUP_SETTINGS_GROUP:
      isMap = false;
      break;
  }

  return (
    <>
      {activeMenuIdx === MENU_IDX_DASHBOARD && <HomeDashboard />}
      {activeMenuIdx === MENU_IDX_ACCOUNT && <HomeAccount />}
      {activeMenuIdx === MENU_IDX_GROUP_SETTINGS_USER && <UserManagement />}
      {activeMenuIdx === MENU_IDX_GROUP_SETTINGS_GROUP && <GroupManagement />}
      {isMap && <HomeMap />}
    </>
  );
}

export default HomeContent;
