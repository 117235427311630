import React, { useEffect, useState } from 'react';
import { Nav, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  MONITORING_TYPE_APP,
  MONITORING_TYPE_LIDAR,
  MonitoringType,
} from '@/modules/monitoring/types';
import {
  useMonitoring,
  useMonitoringGeofence,
} from '@/modules/monitoring/hook';
import MonitoringAppRightPane from '@/components/monitoring/MonitoringAppRightPane';
import MonitoringLidarRightPane from '@/components/monitoring/MonitoringLidarRightPane';
import MonitoringRealtimeFilter from '@/components/monitoring/MonitoringRealtimeFilter';
import { Feature } from 'ol';
import {
  FLOOR_OBJECT_TYPE_AREA,
  FLOOR_OBJECT_TYPE_GEOFENCE,
} from '@/modules/floor/types';
import {
  useOpenLayersArea,
  useOpenLayersDraw,
  useOpenLayersGeofence,
} from '@/modules/openlayers/hook';
import _ from 'lodash';

export type MonitoringRightChildPaneProps = {
  objectFeatureFilterList: Feature[];
};

function MonitoringRightPane() {
  const { t } = useTranslation();
  const { monitoringType, handleChangeMonitoringType } = useMonitoring();
  const { selectedGeofenceFeatureList } = useMonitoringGeofence();
  const { getAreaStyle } = useOpenLayersArea();
  const { geofence, getGeofenceStyle } = useOpenLayersGeofence();
  const { drawLayer } = useOpenLayersDraw();
  const [objectFeatureFilterList, setObjectFeatureFilterList] = useState<
    Feature[]
  >([]);

  useEffect(() => {
    const areaObjectFeatureList = objectFeatureFilterList.filter(
      (objectFeature) => {
        return objectFeature.get('type') === FLOOR_OBJECT_TYPE_AREA;
      }
    );

    setObjectFeatureFilterList(
      _.uniqBy(
        [...areaObjectFeatureList, ...selectedGeofenceFeatureList],
        (e) => {
          return e.getId();
        }
      )
    );
  }, [selectedGeofenceFeatureList]);

  useEffect(() => {
    const geofenceLayer = geofence.layer;
    if (drawLayer && geofenceLayer) {
      handleInitFeature();
      const drawSource = drawLayer.getSource();
      const geofenceSource = geofenceLayer.getSource();
      objectFeatureFilterList.forEach((objectFeature) => {
        const objectType = objectFeature.get('type');
        const id = objectFeature.getId();
        if (objectType === FLOOR_OBJECT_TYPE_AREA) {
          objectFeature.setStyle(getAreaStyle());
          drawSource.addFeature(objectFeature);
        } else if (objectType == FLOOR_OBJECT_TYPE_GEOFENCE) {
          const feature = id && geofenceSource.getFeatureById(id);
          feature && feature.setStyle(getGeofenceStyle(feature, true));
        }
      });
    }
  }, [objectFeatureFilterList]);

  const handleInitFeature = () => {
    const geofenceLayer = geofence.layer;
    drawLayer?.getSource().clear();
    if (geofenceLayer) {
      const source = geofenceLayer.getSource();
      source.getFeatures().forEach((paintedFeature) => {
        paintedFeature.setStyle(getGeofenceStyle(paintedFeature, false));
      });
    }
  };

  const handleSelectTab = (eventKey: string | null) => {
    eventKey && handleChangeMonitoringType(eventKey as MonitoringType);
  };

  return (
    <>
      <TabContainer
        defaultActiveKey={monitoringType}
        onSelect={handleSelectTab}
      >
        <Nav className="tab-menu the-num01 border-bottom-1">
          <Nav.Link
            eventKey={MONITORING_TYPE_LIDAR}
            className="btn-style_underline"
          >
            <span className="menu-cover">
              <span>{t('라이다 센서')}</span>
            </span>
          </Nav.Link>
          {/*
          <Nav.Link
            eventKey={MONITORING_TYPE_APP}
            className="btn-style_underline"
          >
            <span className="menu-cover">
              <span>{t('앱 측위')}</span>
            </span>
          </Nav.Link>
          */}
        </Nav>
        <TabContent>
          <MonitoringRealtimeFilter
            objectFeatureFilterList={objectFeatureFilterList}
            onChangeObjectFeatureFilterList={setObjectFeatureFilterList}
          />
          <TabPane eventKey={MONITORING_TYPE_LIDAR} mountOnEnter={true}>
            <MonitoringLidarRightPane
              objectFeatureFilterList={objectFeatureFilterList}
            />
          </TabPane>
          <TabPane eventKey={MONITORING_TYPE_APP} mountOnEnter={true}>
            <MonitoringAppRightPane
              objectFeatureFilterList={objectFeatureFilterList}
            />
          </TabPane>
        </TabContent>
      </TabContainer>
    </>
  );
}

export default MonitoringRightPane;
