import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Project, Projects } from '@/modules/project/types';
import Preloader from '@/components/common/Preloader';
import { fetchRecentProjects } from '@/api/project';
import { PANE_STATUS_REGISTER } from '@/utils/constants/common';
import {
  useProjectPane,
  useProjectRegister,
  useProjectRoute,
} from '@/modules/project/hook';
import { useHistory } from 'react-router-dom';

type HomeRecentProjectProps = {
  reloadFlag: boolean;
};

function HomeRecentProjectList({ reloadFlag }: HomeRecentProjectProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { handleChangePaneStatus } = useProjectPane();
  const { handleRouteProject } = useProjectRoute();
  const { projectInfo, handleSetProjectInfo } = useProjectRegister();
  const [load, setLoad] = useState(false);
  const [recentProjectList, setRecentProjectList] = useState<Projects>([]);
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    handleFetchRecentProjectList();
  }, [reloadFlag]);

  const handleFetchRecentProjectList = async () => {
    setLoad(false);
    const data = await fetchRecentProjects();
    if (!mountedRef.current) {
      return;
    }
    setRecentProjectList(data);
    setLoad(true);
  };

  const handleClickAdd = () => {
    handleSetProjectInfo({
      ...projectInfo,
      produceEndFlag: false,
    });
    handleChangePaneStatus(PANE_STATUS_REGISTER);
    history.push('/project');
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center mb-4">
        <div className="flex">
          <h3 className="d-flex align-items-center mb-0">
            {t('최근 프로젝트 목록')}
          </h3>
        </div>
      </div>
      <div className="row card-group-row">
        <div
          className="col-3 card-group-row__col"
          style={{
            minHeight: '209px',
          }}
        >
          <a className="card card-latest add-project" onClick={handleClickAdd}>
            <span className="material-icons">add</span>
            {t('새 프로젝트 추가')}
          </a>
        </div>
        {!load && <Preloader />}
        {recentProjectList.map((recentProject) => (
          <RecentProjectItem
            key={recentProject.projectId}
            {...recentProject}
            onClickItem={() =>
              handleRouteProject(
                recentProject.projectId,
                !recentProject.produceEndFlag
              )
            }
          />
        ))}
      </div>
    </>
  );
}

type RecentProjectItemProps = Project & {
  onClickItem: () => void;
};

function RecentProjectItem({
  projectName,
  buildingCount,
  assetCount,
  produceEndFlag,
  onClickItem,
}: RecentProjectItemProps) {
  const { t } = useTranslation();

  return (
    <div className="col-3 card-group-row__col">
      <a className="card card-latest" onClick={onClickItem}>
        <div className="card-caption">
          <span className="material-icons cp-icon">factory</span>
          <h5 className="m-0">-</h5>
          {!produceEndFlag && <small className="ml-auto">{t('편집중')}</small>}
        </div>
        <div className="card-title">
          <h4 className="mb-2">{projectName}</h4>
        </div>
        <ul className="list-unstyled">
          <li>
            <span>{t('건물')}</span>
            <em>{buildingCount}</em>
          </li>
          <li>
            <span>{t('자산')}</span>
            <em>{assetCount}</em>
          </li>
        </ul>
      </a>
    </div>
  );
}

export default HomeRecentProjectList;
