import React from 'react';
import { EventCondition, EventDetailCategory } from '@/modules/event/types';
import FormLabel from '@/components/common/FormLabel';
import FormGroup from '@/components/common/FormGroup';
import InputNumber from '@/components/common/InputNumber';
import { useTranslation } from 'react-i18next';

type EventTargetOptionFormProps = {
  eventDetailCategory: EventDetailCategory;
  eventCondition: EventCondition;
  eventOption: string | number;
  onChangeEventOption: (eventOption: string | number) => void;
};

function EventTargetOptionForm({
  eventDetailCategory,
  eventCondition,
  eventOption,
  onChangeEventOption,
}: EventTargetOptionFormProps) {
  const { t } = useTranslation();

  if (
    (eventDetailCategory === 'MORE_OR_LESS' && eventCondition === 'MORE') ||
    eventCondition === 'LESS'
  ) {
    return (
      <FormGroup marginBottomClassName={'mb-4'}>
        <FormLabel textKey={'인원수'} essential={true} />
        <div className="d-flex align-items-center">
          <InputNumber
            className={'form-line'}
            min={0}
            value={Number(eventOption)}
            onChange={(value) => onChangeEventOption(value)}
          />
          <span className="px-2">{t('명')}</span>
        </div>
      </FormGroup>
    );
  }

  return <></>;
}

export default EventTargetOptionForm;
