import React, { useState } from 'react';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MonitoringAppEndPointUser } from '@/modules/monitoring/types';
import { ModalProps } from '@/modules/common';
import FormGroup from '@/components/common/FormGroup';
import FormLabel from '@/components/common/FormLabel';
import InputFile from '@/components/common/InputFile';
import { postMessages } from '@/api/message';
import { useHomeProject } from '@/modules/home/hook';
import AlertModal from '@/components/common/modal/AlertModal';
import InvalidAlert from '@/components/InvalidAlert';
import classNames from 'classnames';
import MaterialIcon from '@/components/common/MaterialIcon';

type MonitoringMessageSendModalProps = ModalProps & {
  appEndPointUser: MonitoringAppEndPointUser;
};

function MonitoringMessageSendModal({
  onHide,
  appEndPointUser: { accessKey, siteUserName },
}: MonitoringMessageSendModalProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { project } = useHomeProject();
  const [inputs, setInputs] = useState<{
    title: string;
    content: string;
    imgId: string;
  }>({
    title: '',
    content: '',
    imgId: '',
  });
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [isSendSuccess, setSendSuccess] = useState(false);

  const handleSendMessage = async () => {
    if (loading) {
      return;
    }
    setSendSuccess(false);
    setShowInvalidMessage(false);
    const { title, content, imgId } = inputs;
    if (!title || !content) {
      setShowInvalidMessage(true);
      return;
    }
    setLoading(true);
    let result = false;
    project &&
      (result = await postMessages({
        projectId: project.projectId,
        parentId: '',
        parentType: 'message',
        messageTitle: title,
        messageContent: content,
        targetIds: [accessKey],
        imgId,
      }));

    setSendSuccess(result);
    let message = '메세지가 전송되었습니다.';
    if (!result) {
      message = '서버와 통신 중 오류가 발생했습니다';
    }

    setAlertMessage(message);
    setShowAlertModal(true);
    setLoading(false);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        dialogClassName="modal-dialog-scrollable"
      >
        <ModalHeader>
          <div className="d-flex">
            <MaterialIcon
              outlined={true}
              className={'text-40 mr-2'}
              name={'person'}
            />
            <ModalTitle as={'h4'}>{siteUserName}</ModalTitle>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormGroup marginBottomClassName={'mb-4'}>
            <FormLabel textKey={'메세지 제목'} essential={true} />
            <input
              type="text"
              className="form-line"
              placeholder={t('제목을 입력해 주세요')}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  title: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup marginBottomClassName={'mb-4'}>
            <FormLabel textKey={'메세지 내용'} essential={true} />
            <textarea
              rows={5}
              placeholder={t('내용을 입력해 주세요')}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  content: e.target.value,
                })
              }
            ></textarea>
          </FormGroup>
          <FormGroup marginBottomClassName={'mb-4'}>
            <FormLabel textKey={'파일 등록'} />
            <InputFile
              onSuccessUpload={(fileId) =>
                setInputs({
                  ...inputs,
                  imgId: fileId,
                })
              }
            />
          </FormGroup>
        </ModalBody>
        {showInvalidMessage && (
          <InvalidAlert messageKey={'필수 값을 입력해 주세요'} />
        )}
        <ModalFooter>
          <button type="button" className="btn btn-light" onClick={onHide}>
            {t('취소')}
          </button>
          <button
            type="button"
            className={classNames('btn btn-accent', {
              'is-loading': loading,
            })}
            onClick={handleSendMessage}
          >
            {t('발송')}
          </button>
        </ModalFooter>
      </Modal>
      <AlertModal
        show={showAlertModal}
        onHide={() => {
          if (isSendSuccess) {
            onHide();
          } else {
            setShowAlertModal(false);
          }
        }}
      >
        {t(alertMessage)}
      </AlertModal>
    </>
  );
}

export default MonitoringMessageSendModal;
