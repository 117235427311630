import React from 'react';
import { CommonUtils } from '@/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';

type FloorControlProps = {
  floorList: { floorId: string; floorValue: number }[];
  selectedFloorId: string;
  onClickFloor: (floorId: string) => void;
};

function FloorControl({
  floorList,
  selectedFloorId,
  onClickFloor,
}: FloorControlProps) {
  if (!floorList.length) {
    return <></>;
  }

  return (
    <>
      <div className="btn-mid-holder-floor">
        <div className="floor-inner">
          <Swiper
            className="swiper-container floor-item"
            direction={'vertical'}
            allowTouchMove={false}
            slidesPerView={8}
            slidesPerGroup={8}
            spaceBetween={0}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
            }}
            navigation={{
              nextEl: '.floor-button-next',
              prevEl: '.floor-button-prev',
            }}
          >
            {floorList.map(({ floorId, floorValue }) => (
              <SwiperSlide key={floorId}>
                <a
                  className={classNames('btn-floor', {
                    active: floorId === selectedFloorId,
                  })}
                  onClick={() => onClickFloor(floorId)}
                >
                  {CommonUtils.convertFloorFormat(floorValue)}
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {floorList.length > 8 && (
          <>
            <div className="floor-button floor-button-next">
              <span className="material-icons">expand_more</span>
            </div>
            <div className="floor-button floor-button-prev">
              <span className="material-icons">expand_less</span>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default FloorControl;
