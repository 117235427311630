import React from 'react';

type LocationControlProps = {
  onClickControl: () => void;
};

function LocationControl({ onClickControl }: LocationControlProps) {
  return (
    <a className="widget-btn" onClick={onClickControl}>
      <img src="/static/images/icon/map_location.svg" />
    </a>
  );
}

export default LocationControl;
