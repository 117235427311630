import React from 'react';
import MaterialIcon from '@/components/common/MaterialIcon';
import { useLayerPopup } from '@/modules/setup/hook';
import { Config } from '@/config';
import { useHomeSelected } from '@/modules/home/hook';

function LayerPopup() {
  const { project, building, floor } = useHomeSelected();
  const { handleChangeShow } = useLayerPopup();

  return (
    <>
      <div className="layer-popup open">
        <a
          className="btn_layerpopup btn_close_layer"
          onClick={() => handleChangeShow(false)}
        >
          <MaterialIcon name={'close'} />
        </a>
        <div className="layer-map">
          <iframe
            style={{
              width: '100%',
              height: '100%',
            }}
            frameBorder={0}
            src={`${Config.map_server.indoor_edit_uri}/map3D.jsp?projectId=${project?.projectId}&mappingId=${building?.mappingId}&mapId=${floor?.mapId}`}
          />
        </div>
      </div>
    </>
  );
}

export default React.memo(LayerPopup);
