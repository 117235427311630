import React, { useState } from 'react';
import {
  PANE_STATUS_DETAIL,
  PANE_STATUS_LIST,
  PaneStatus,
} from '@/utils/constants/common';
import HomeMessageList from '@/components/home/Message/List';
import HomeMessageDetail from '@/components/home/Message/Detail';
import moment from 'moment';

function HomeMessage() {
  const [paneStatus, setPaneStatus] = useState<PaneStatus>(PANE_STATUS_LIST);
  const [messageId, setMessageId] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [period, setPeriod] = useState<string[]>([
    moment().format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ]);

  const handleClickMessage = (clickedMessageId: string) => {
    setMessageId(clickedMessageId);
    setPaneStatus(PANE_STATUS_DETAIL);
  };

  const handleClickList = () => {
    setMessageId('');
    setPaneStatus(PANE_STATUS_LIST);
  };

  return (
    <div className="contents-section">
      <div className="cell">
        {paneStatus === PANE_STATUS_LIST && (
          <HomeMessageList
            page={page}
            pageSize={pageSize}
            period={period}
            onChangePage={setPage}
            onChangePageSize={setPageSize}
            onChangePeriod={setPeriod}
            onClickMessage={handleClickMessage}
          />
        )}
        {paneStatus === PANE_STATUS_DETAIL && (
          <HomeMessageDetail
            messageId={messageId}
            onClickList={handleClickList}
          />
        )}
      </div>
    </div>
  );
}

export default HomeMessage;
