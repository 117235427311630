import React, { useState } from 'react';
import { SignUpFormProps } from '@/components/SignUpForm';
import SignUpEmailAuthentication from '@/components/SignUpEmailAuthentication';
import SignUpNumberAuthentication from '@/components/SignUpNumberAuthentication';

type SignUpAuthenticationProps = SignUpFormProps & {
  signUpEmail: string;
  onChangeSignUpEmail: (email: string) => void;
};

function SignUpAuthentication({
  signUpEmail,
  onChangeStep,
  onChangeSignUpEmail,
}: SignUpAuthenticationProps) {
  const [isSendMail, setSendMail] = useState(false);

  return (
    <>
      {!isSendMail && (
        <SignUpEmailAuthentication
          onChangeSignUpEmail={onChangeSignUpEmail}
          onChangeSendMail={() => {
            setSendMail(true);
          }}
        />
      )}
      {isSendMail && (
        <SignUpNumberAuthentication
          signUpEmail={signUpEmail}
          onMatchNumber={() => onChangeStep(3)}
        />
      )}
    </>
  );
}

export default SignUpAuthentication;
