import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SignUpMemberProps } from '@/components/SignUpMember';

function SignUpComplete() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <div className="msg-board">
        <h2>{t('회원가입 완료')}</h2>
        <em>{t('회원가입이 완료되었습니다.')}</em>
      </div>
      <div className="form-group text-center mb-32pt">
        <button
          className="btn btn-block btn-lg btn-accent"
          type="button"
          onClick={() => history.replace('/signin')}
        >
          {t('로그인 하기')}
        </button>
      </div>
    </>
  );
}

export default SignUpComplete;
