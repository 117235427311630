import React from 'react';
import { MonitoringGeoObjectGroup } from '@/modules/monitoring/types';
import MonitoringGeoObjectAreaGroupItem from '@/components/monitoring/MonitoringGeoObjectAreaGroupItem';
import MonitoringGeoObjectGeofenceGroupItem from '@/components/monitoring/MonitoringGeoObjectGeofenceGroupItem';
import MonitoringGeoObjectGeofenceItem from '@/components/monitoring/MonitoringGeoObjectGeofenceItem';
import MonitoringGeoObjectRemainItem from '@/components/monitoring/MonitoringGeoObjectRemainItem';
import TableEmptyRow from '@/components/common/TableEmptyRow';

type MonitoringObjectGroupProps = {
  geoObjectGroup: MonitoringGeoObjectGroup;
};

function MonitoringGeoObjectGroupItem({
  geoObjectGroup: { area_group, geofence_group, geofences, remains },
}: MonitoringObjectGroupProps) {
  return (
    <>
      {!area_group.length &&
        !geofence_group.length &&
        !geofences.length &&
        !remains.length && (
          <TableEmptyRow colSpan={2} textKey={'등록된 데이터가 없습니다.'} />
        )}
      {area_group.map((areaGroup, i) => (
        <MonitoringGeoObjectAreaGroupItem
          key={i}
          geoObjectAreaGroup={areaGroup}
        />
      ))}
      {geofence_group.map((geofenceGroup, i) => (
        <MonitoringGeoObjectGeofenceGroupItem
          key={i}
          geoObjectGeofenceGroup={geofenceGroup}
        />
      ))}
      {geofences.map((geofence, i) => (
        <MonitoringGeoObjectGeofenceItem key={i} geoObjectGeofence={geofence} />
      ))}
      {remains.map((remain, i) => (
        <MonitoringGeoObjectRemainItem key={i} geoObjectRemain={remain} />
      ))}
    </>
  );
}

export default MonitoringGeoObjectGroupItem;
