import React, { useEffect } from 'react';
import { CommonUtils } from '@/utils';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type PaginationSizeProps = {
  show?: boolean;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
};

function PaginationSize({
  show = true,
  pageSize,
  onChangePageSize,
}: PaginationSizeProps) {
  const { t } = useTranslation();

  if (!show) {
    return <></>;
  }

  return (
    <div className="d-flex view-page">
      <select
        className="form-line"
        value={pageSize}
        onChange={(e) => onChangePageSize(Number(e.target.value))}
      >
        <option value="10">{t('10개씩 보기')}</option>
        <option value="20">{t('20개씩 보기')}</option>
        <option value="30">{t('30개씩 보기')}</option>
        <option value="40">{t('40개씩 보기')}</option>
        <option value="50">{t('50개씩 보기')}</option>
      </select>
    </div>
  );
}

export default React.memo(PaginationSize);
