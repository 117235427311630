import { useTranslation } from 'react-i18next';
import {
  EVENT_TARGET_TYPE_ASSET,
  EVENT_TARGET_TYPE_GEO,
  EventCondition,
  EventDetailCategory,
  EventTargetType,
} from '@/modules/event/types';
import { useCallback } from 'react';

export function useEventText() {
  const { t } = useTranslation();

  const getEventTargetTypeText = useCallback(
    (eventTargetType: EventTargetType) => {
      let text = '';

      if (eventTargetType === EVENT_TARGET_TYPE_GEO) {
        text = t('공간');
      } else if (eventTargetType === EVENT_TARGET_TYPE_ASSET) {
        text = t('자산');
      }

      return text;
    },
    []
  );

  const getEventDetailCategoryText = useCallback(
    (eventDetailCategory: EventDetailCategory) => {
      let text = '';

      if (eventDetailCategory === 'MORE_OR_LESS') {
        text = t('인원수 알림');
      } else if (eventDetailCategory === 'IN_OR_OUT') {
        text = t('공간 출입 알림');
      }

      return text;
    },
    []
  );

  const getEventConditionText = useCallback(
    (
      eventDetailCategory: EventDetailCategory,
      eventCondition: EventCondition
    ) => {
      let text = '';

      if (eventDetailCategory === 'MORE_OR_LESS') {
        eventCondition === 'MORE' && (text = t('이상'));
        eventCondition === 'LESS' && (text = t('이하'));
      } else if (eventDetailCategory === 'IN_OR_OUT') {
        eventCondition === 'IN' && (text = t('출입 알림'));
      }

      return text;
    },
    []
  );

  return {
    getEventTargetTypeText,
    getEventDetailCategoryText,
    getEventConditionText,
  };
}
