import { Feature } from 'ol';

export const MONITORING_GEO_OBJECT_TYPE_AREA = 'AREA';
export const MONITORING_GEO_OBJECT_TYPE_GEOFENCE = 'GEOFENCE';
export const MONITORING_GEO_OBJECT_TYPE_POI = 'POI';
export const MONITORING_GEO_OBJECT_TYPE_UTIL = 'UTIL';

export type MonitoringGeoObjectType =
  | typeof MONITORING_GEO_OBJECT_TYPE_AREA
  | typeof MONITORING_GEO_OBJECT_TYPE_GEOFENCE
  | typeof MONITORING_GEO_OBJECT_TYPE_POI
  | typeof MONITORING_GEO_OBJECT_TYPE_UTIL;

export type MonitoringGeoObjectGroup = {
  area_group: MonitoringGeoObjectAreaGroup[];
  geofence_group: MonitoringGeoObjectGeofenceGroup[];
  geofences: MonitoringGeoObjectGeofence[];
  remains: MonitoringGeoObjectRemains[];
};

export type MonitoringGeoObjectRemains = {
  drawstyle: string;
  id: string;
  gtype: 'poi' | 'util';
  gname: string;
};

export type MonitoringGeoObjectGeofence = {
  zone_id: string;
  zone_name: string;
  event_count: number;
  geomstr: string;
};

export type MonitoringGeoObjectAreaGroup = {
  areatype_name: string;
  values: {
    [category: string]: MonitoringGeoObjectAreaChild[];
  };
};

export type MonitoringGeoObjectGeofenceGroup = {
  geofence_name: string;
  values: {
    [category: string]: MonitoringGeoObjectGeofenceChild[];
  };
};

export type MonitoringGeoObjectAreaChild = {
  id: string;
  areaname: string;
  gname: string;
  imgsrc: string;
};

export type MonitoringGeoObjectGeofenceChild = {
  id: string;
  zone_name: string;
  gname: string;
  drawstyle: string;
};

export const MONITORING_TYPE_LIDAR = 'LIDAR';
export const MONITORING_TYPE_APP = 'APP';

export type MonitoringType =
  | typeof MONITORING_TYPE_LIDAR
  | typeof MONITORING_TYPE_APP;

export type MonitoringFloorAppEndPointUserStatus = {
  activeUsersByFloor: {
    data: MonitoringFloorAppEndPointUser;
    size: number;
  };
  totalUsersByFloor: {
    count: number;
  };
};

export type MonitoringFloorAppEndPointUser = {
  [accessKey: string]: MonitoringAppEndPointUser;
};

export type MonitoringAppEndPointUser = {
  userId: string;
  accessKey: string;
  zoneId: string;
  zoneName: string;
  lng: number;
  lat: number;
  siteUserId: string;
  siteUserName: string;
};

export type MonitoringFloorLidarAccessStatus = {
  summary: {
    zone1In: number;
    zone1Total: number;
    zone2In: number;
    zone2Total: number;
  };
};

export type MonitoringWebSocketData = {
  realtimeData: MonitoringLidar[];
};

export type MonitoringFloorLidarStatus = {
  counting: MonitoringLidarCounting;
  realtimeLIDARPosition: MonitoringLidarPosition;
  realtimeUsersByLIDARForFloor: MonitoringFloorLidar;
};

export type MonitoringFloorLidar = {
  [lidarKey: string]: MonitoringLidar;
};

export type MonitoringLidarPosition = {
  lidarLng: number | null;
  lidarLat: number | null;
};

export type MonitoringLidarCounting = {
  CAR: number;
  CYCLE: number;
  PED: number;
  FORKLIFT: number;
  tagTotalCount: number;
};

export type MonitoringLidar = {
  lng: number;
  lat: number;
  serialId: string;
  height: number;
  velocity: number;
  lidarType: MonitoringLidarType;
  lidarLng: number;
  lidarLat: number;
};

export type MonitoringLidarType = 'PED' | 'CAR' | 'CYCLE' | 'FORKLIFT';

export type MonitoringState = {
  directFlag: boolean;
  monitoringType: MonitoringType;
  appEndPointUserList: MonitoringAppEndPointUser[];
  appEndPointUserAccumulateNumber: number;
  lidarObjectList: MonitoringLidar[];
  selectedGeofenceFeatureList: Feature[];
  selectedLidarObjectList: MonitoringLidar[];
};

export const MONITORING_CHANGE_DIRECT_FLAG = 'MONITORING/CHANGE_DIRECT_FLAG' as const;
export const MONITORING_CHANGE_MONITORING_TYPE = 'MONITORING/CHANGE_MONITORING_TYPE' as const;
export const MONITORING_SET_APP_END_POINT_USER_DATA = 'MONITORING/SET_APP_END_POINT_USER_DATA' as const;
export const MONITORING_SET_LIDAR_OBJECT_LIST = 'MONITORING/SET_LIDAR_OBJECT_LIST' as const;
export const MONITORING_SET_SELECTED_GEOFENCE_FEATURE = 'MONITORING/SET_SELECTED_GEOFENCE_FEATURE' as const;
export const MONITORING_SET_SELECTED_GEOFENCE_FEATURE_LIST = 'MONITORING/SET_SELECTED_GEOFENCE_FEATURE_LIST' as const;
export const MONITORING_INIT_SELECTED_GEOFENCE_FEATURE_LIST = 'MONITORING/INIT_SELECTED_GEOFENCE_FEATURE_LIST' as const;
export const MONITORING_SET_SELECTED_LIDAR_OBJECT = 'MONITORING/SET_SELECTED_LIDAR_OBJECT' as const;
export const MONITORING_REMOVE_SELECTED_LIDAR_OBJECT = 'MONITORING/REMOVE_SELECTED_LIDAR_OBJECT' as const;
export const MONITORING_INIT_SELECTED_LIDAR_OBJECT_LIST = 'MONITORING/INIT_SELECTED_LIDAR_OBJECT_LIST' as const;

export type Action =
  | {
      type: typeof MONITORING_CHANGE_DIRECT_FLAG;
      directFlag: boolean;
    }
  | {
      type: typeof MONITORING_CHANGE_MONITORING_TYPE;
      monitoringType: MonitoringType;
    }
  | {
      type: typeof MONITORING_SET_APP_END_POINT_USER_DATA;
      appEndPointUserList: MonitoringAppEndPointUser[];
      accumulateNumber: number;
    }
  | {
      type: typeof MONITORING_SET_LIDAR_OBJECT_LIST;
      lidarObjectList: MonitoringLidar[];
    }
  | {
      type: typeof MONITORING_SET_SELECTED_GEOFENCE_FEATURE;
      selectedGeofenceFeature: Feature;
    }
  | {
      type: typeof MONITORING_SET_SELECTED_GEOFENCE_FEATURE_LIST;
      selectedGeofenceFeatureList: Feature[];
    }
  | {
      type: typeof MONITORING_INIT_SELECTED_GEOFENCE_FEATURE_LIST;
    }
  | {
      type: typeof MONITORING_SET_SELECTED_LIDAR_OBJECT;
      lidarObject: MonitoringLidar;
    }
  | {
      type: typeof MONITORING_REMOVE_SELECTED_LIDAR_OBJECT;
      lidarObject: MonitoringLidar;
    }
  | {
      type: typeof MONITORING_INIT_SELECTED_LIDAR_OBJECT_LIST;
    };
