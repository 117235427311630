import React, { ReactNode } from 'react';

type TopContainerProps = {
  position: 'top' | 'cover';
  children?: ReactNode;
};

function LayoutContainer({ position, children }: TopContainerProps) {
  return <div className={`pl-${position}`}>{children}</div>;
}

export default LayoutContainer;
