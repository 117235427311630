import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Alert, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import MaterialIcon from '@/components/common/MaterialIcon';
import classNames from 'classnames';
import { GroupAddUser, InviteUserDetail } from '@/modules/group/types';
import { ModalProps, ReactSelectOption } from '@/modules/common';
import SelectMulti from '@/components/SelectMulti';
import ProfileAvatar from '@/components/common/ProfileAvatar';
import { postGroupAddInviteUsers } from '@/api/group';

type GroupManagementAddUserModalProps = ModalProps & {
  groupSeq: number;
  inviteUserList: InviteUserDetail[];
};

function GroupManagementAddUserModal({
  groupSeq,
  inviteUserList,
  show,
  onHide,
  onReload,
}: GroupManagementAddUserModalProps) {
  const { t } = useTranslation();
  const [options, setOptions] = useState<ReactSelectOption[]>([]);
  const [selectedValue, setSelectedValue] = useState<number[]>([]);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const newOptions: ReactSelectOption[] = [];
    inviteUserList.forEach((inviteUser) => {
      const newOption: ReactSelectOption = {
        value: inviteUser.invitationSeq.toString(),
        name: inviteUser.userName,
        label: (
          <>
            <span className="avatar avatar-xs mr-2">
              <ProfileAvatar
                profileImgUrl={inviteUser.profileImgUrl}
                userName={inviteUser.userName}
              />
            </span>
            <span>{inviteUser.userName}</span>
          </>
        ),
      };
      newOptions.push(newOption);
    });

    setOptions(newOptions);
  }, [inviteUserList]);

  const handleSubmit = async () => {
    const selectedInviteUserList: GroupAddUser[] = [];
    for (const inviteUser of inviteUserList) {
      if (selectedValue.includes(inviteUser.invitationSeq)) {
        selectedInviteUserList.push({
          userId: inviteUser.userId,
          invitationSeq: inviteUser.invitationSeq,
        });
      }
    }

    setShowAlert(false);

    const result = await postGroupAddInviteUsers(
      groupSeq,
      selectedInviteUserList
    );

    if (result) {
      onReload?.call(null);
      onHide();
    } else {
      setShowAlert(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        //
      }}
      onExited={() => {
        setShowAlert(false);
        setSelectedValue([]);
      }}
      centered={true}
      dialogClassName={'modal-custom'}
    >
      <ModalHeader>
        <button type="button" className="close custom-close" onClick={onHide}>
          <span>
            <MaterialIcon name={'clear'} />
          </span>
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="title-group mb-4">
          <h5 className="modal-title">{t('구성원 추가')}</h5>
          <p className="m-0">
            {t('그룹의 프로젝트 액세스 및 역할을 구성할 수 있습니다.')}
          </p>
        </div>
        <div className="form-group mb-5">
          <label className="form-label">{t('구성원 검색')}</label>
          <SelectMulti
            options={options}
            onChangeValue={(changeValue) => {
              setSelectedValue(changeValue.map((value) => Number(value)));
            }}
          />
        </div>
        {showAlert && (
          <Alert className="alert-soft-accent">
            <div className="d-flex flex-wrap align-items-center">
              <div className="mr-8pt">
                <MaterialIcon name={'error_outline'} />
              </div>
              <div className="flex" style={{ minWidth: '180px' }}>
                <small className="text-black-100">
                  {t('허용 가능한 그룹 구성원 수를 초과했습니다.')}
                </small>
              </div>
            </div>
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-light" onClick={onHide}>
          {t('취소')}
        </button>
        <button
          type="button"
          className={classNames('btn', {
            'btn-light': selectedValue.length === 0,
            'btn-accent': selectedValue.length > 0,
          })}
          disabled={selectedValue.length === 0}
          onClick={handleSubmit}
        >
          {t('확인')}
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default GroupManagementAddUserModal;
