import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import MaterialIcon from '@/components/common/MaterialIcon';
import { ModalProps } from '@/modules/common';
import { Group } from '@/modules/group/types';
import { deleteGroup } from '@/api/group';

type GroupManagementDeleteModalProps = ModalProps & {
  group: Group | null;
};

function GroupManagementDeleteModal({
  group,
  show,
  onHide,
  onReload,
}: GroupManagementDeleteModalProps) {
  const { t } = useTranslation();

  const handleSubmit = async () => {
    if (group) {
      const result = await deleteGroup(group.groupSeq);
      if (result) {
        onReload?.call(null);
        onHide();
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        //
      }}
      centered={true}
    >
      <ModalHeader>
        <ModalTitle as={'h5'}>{t('알림')}</ModalTitle>
        <button type="button" className="close custom-close" onClick={onHide}>
          <span>
            <MaterialIcon name={'clear'} />
          </span>
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="text-center py-4">
          <h3>{t('그룹을 삭제하시겠습니까?')}</h3>
          <p className="m-0 text-50 font-size-16pt">
            <mark>{group?.groupName}</mark>
            {t('그룹을 삭제하시겠습니까?')}
            <br />
            {t('삭제된 그룹은 복구가 불가능합니다.')}
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-link" onClick={onHide}>
          {t('취소')}
        </button>
        <button type="button" className="btn btn-accent" onClick={handleSubmit}>
          {t('확인')}
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default GroupManagementDeleteModal;
