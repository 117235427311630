import React, { useRef } from 'react';
import FormGroup from '@/components/common/FormGroup';
import FormLabel from '@/components/common/FormLabel';
import { useTranslation } from 'react-i18next';
import Flatpickr from 'react-flatpickr';
import { key as LocaleKey } from 'flatpickr/dist/types/locale';
import 'flatpickr/dist/l10n/ko';
import 'flatpickr/dist/l10n/ja';
import InputFile from '@/components/common/InputFile';
import { CampaignInput, CampaignType } from '@/modules/campaign/types';
import moment from 'moment';
import { useFloorObject } from '@/modules/floor/hook';
import { Feature } from 'ol';
import { Circle } from 'ol/geom';
import { OpenLayersUtils } from '@/utils/OpenLayersUtils';

type CampaignInfoFormProps = {
  campaignInput: CampaignInput;
  feature?: Feature;
  onChangeCampaignInput: (campaignInput: CampaignInput) => void;
  onChangeFeature?: () => void;
};

function CampaignInfoForm({
  campaignInput,
  feature,
  onChangeCampaignInput,
  onChangeFeature,
}: CampaignInfoFormProps) {
  const { t, i18n } = useTranslation();
  const { geofenceList } = useFloorObject();
  const flatpickrRef = useRef<Flatpickr>(null);

  const handleChangeCampaignType = (campaignType: CampaignType) => {
    if (campaignType === 'message') {
      onChangeCampaignInput({
        ...campaignInput,
        linkUrl: '',
        campaignType: 'message',
      });
    } else if (campaignType === 'url') {
      onChangeCampaignInput({
        ...campaignInput,
        content: '',
        imgId: '',
        originalFileName: '',
        campaignType: 'url',
      });
    }
  };

  // TODO: 우선 원만 제공
  const circleGeometry = feature?.getGeometry() as Circle;

  return (
    <>
      <FormGroup marginBottomClassName={'mb-4'}>
        <FormLabel textKey={'캠페인명'} essential={true} />
        <input
          type="text"
          className="form-line"
          placeholder={t('캠페인명을 입력해 주세요')}
          value={campaignInput.title}
          onChange={(e) => {
            onChangeCampaignInput({
              ...campaignInput,
              title: e.target.value,
            });
          }}
        />
      </FormGroup>
      <FormGroup marginBottomClassName={'mb-4'}>
        <FormLabel textKey={'기간'} essential={true} />
        <div className="date-picker-cover">
          <Flatpickr
            options={{
              mode: 'range',
              altInput: true,
              altInputClass: 'form-line',
              altFormat: 'Y-m-d',
              locale: i18n.language as LocaleKey,
            }}
            ref={flatpickrRef}
            value={campaignInput.period}
            onChange={(dates) => {
              if (dates.length === 2) {
                onChangeCampaignInput({
                  ...campaignInput,
                  period: dates.map((date) =>
                    moment(date).format('YYYY-MM-DD')
                  ),
                });
              }
            }}
            onClose={(dates) => {
              if (dates.length < 2) {
                flatpickrRef.current?.flatpickr.setDate(campaignInput.period);
              }
            }}
          />
        </div>
      </FormGroup>
      {/*
      <FormGroup marginBottomClassName={'mb-4'}>
        <FormLabel textKey={'지오펜스'} essential={true} />
        <div className="row">
          <div className="col-12">
            <select
              className="form-line"
              value={campaignInput.zoneId}
              onChange={(e) =>
                onChangeCampaignInput({
                  ...campaignInput,
                  zoneId: e.target.value,
                })
              }
            >
              <option value={''}>{t('지오펜스을 선택해 주세요')}</option>
              {geofenceList.map(({ zone_id, zone_name }) => (
                <option key={zone_id} value={zone_id}>
                  {zone_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </FormGroup>
      */}
      {feature && (
        <>
          <FormGroup marginBottomClassName={'mb-4'}>
            <FormLabel textKey={'캠페인 위치'} essential={true} />
            <input
              type="text"
              className="form-line"
              placeholder={OpenLayersUtils.convertCoordinates(
                circleGeometry.getCenter(),
                'EPSG:3857',
                'EPSG:4326',
                true
              ).join(',')}
              disabled={true}
            />
            <a
              className="btn-abs btn-light btn-sm border-1"
              onClick={onChangeFeature}
            >
              {t('변경')}
            </a>
          </FormGroup>
          <FormGroup marginBottomClassName={'mb-4'}>
            <FormLabel textKey={'범위'} essential={true} />
            <input
              type="text"
              className="form-line"
              value={circleGeometry.getRadius()}
              disabled={true}
            />
          </FormGroup>
        </>
      )}
      <FormGroup marginBottomClassName={'mb-4'}>
        <FormLabel textKey={'발송 형태'} essential={true} />
        <div className="d-flex px-3 py-2">
          <div className="custom-control custom-radio flex">
            <input
              id="campaignMessage"
              name="campaign-type"
              type="radio"
              className="custom-control-input"
              checked={campaignInput.campaignType === 'message'}
              onChange={(e) =>
                e.target.checked && handleChangeCampaignType('message')
              }
            />
            <label htmlFor="campaignMessage" className="custom-control-label">
              {t('메세지 발송')}
            </label>
          </div>
          <div className="custom-control custom-radio flex">
            <input
              id="campaignUrl"
              name="campaign-type"
              type="radio"
              className="custom-control-input"
              checked={campaignInput.campaignType === 'url'}
              onChange={(e) =>
                e.target.checked && handleChangeCampaignType('url')
              }
            />
            <label htmlFor="campaignUrl" className="custom-control-label">
              {t('URL 발송')}
            </label>
          </div>
        </div>
      </FormGroup>
      {campaignInput.campaignType === 'message' ? (
        <>
          <FormGroup marginBottomClassName={'mb-4'}>
            <FormLabel textKey={'캠페인 내용'} essential={true} />
            <textarea
              rows={5}
              placeholder={t('내용을 입력해 주세요')}
              value={campaignInput.content}
              onChange={(e) => {
                onChangeCampaignInput({
                  ...campaignInput,
                  content: e.target.value,
                });
              }}
            ></textarea>
          </FormGroup>
          <InputFile
            titleKey={'파일 등록'}
            accept={'image/*'}
            originalFileName={campaignInput.originalFileName}
            onSuccessUpload={(fileId) => {
              onChangeCampaignInput({
                ...campaignInput,
                imgId: fileId,
                originalFileName: '',
              });
            }}
          />
        </>
      ) : (
        <FormGroup marginBottomClassName={'mb-4'}>
          <FormLabel textKey={'캠페인 URL'} essential={true} />
          <input
            type="text"
            className="form-line"
            placeholder={t('캠페인 URL 입력해 주세요')}
            value={campaignInput.linkUrl}
            onChange={(e) => {
              onChangeCampaignInput({
                ...campaignInput,
                linkUrl: e.target.value,
              });
            }}
          />
        </FormGroup>
      )}
    </>
  );
}

export default CampaignInfoForm;
