import React, { useEffect, useState } from 'react';
import { ModalProps } from '@/modules/common';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import MaterialIcon from '@/components/common/MaterialIcon';
import { useTranslation } from 'react-i18next';
import ProfileAvatar from '@/components/common/ProfileAvatar';
import { putInviteUserAccess } from '@/api/group';
import classNames from 'classnames';

export type UserManagementAccessData = {
  profileImgUrl: string;
  userName: string;
  userId: string;
  activeFlag: boolean;
  invitationSeq: number;
};

type UserManagementAccessModalProps = ModalProps & {
  data: UserManagementAccessData | null;
};

function UserManagementAccessModal({
  show,
  onHide,
  onReload,
  data,
}: UserManagementAccessModalProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    if (data && !loading) {
      setLoading(true);
      const result = await putInviteUserAccess({
        invitationSeq: data.invitationSeq,
        userId: data.userId,
        activeFlag: !data.activeFlag,
      });

      if (result) {
        onHide();
        onReload?.call(null);
      }
    }
  };

  return (
    <Modal
      show={show}
      onExited={() => {
        setLoading(false);
      }}
      onHide={() => {
        //
      }}
      centered={true}
    >
      <ModalHeader>
        <ModalTitle as={'h5'}>{t('알림')}</ModalTitle>
        <button type="button" className="close custom-close" onClick={onHide}>
          <span>
            <MaterialIcon name={'clear'} />
          </span>
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="py-4">
          <div className="text-center">
            <h3>
              {data?.activeFlag &&
                t('해당 사용자의 액세스 권한을 일시 중단하시겠습니까?')}
              {!data?.activeFlag &&
                t('해당 사용자의 액세스 권한을 허용하시겠습니까?')}
            </h3>
          </div>
          <div className="d-flex justify-content-center">
            <div className="media flex-nowrap align-items-center border-1 p-3">
              <span className="avatar avatar-sm mr-2">
                <ProfileAvatar
                  profileImgUrl={data?.profileImgUrl || ''}
                  userName={data?.userName || ''}
                />
              </span>
              <div className="media-body">
                <strong className="text-dark">{data?.userName}</strong>
                <div className="text-muted small">{data?.userId}</div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-link" onClick={onHide}>
          {t('취소')}
        </button>
        <button
          type="button"
          className={classNames('btn btn-accent', {
            'is-loading is-loading-sm': loading,
          })}
          onClick={handleConfirm}
        >
          {t('확인')}
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default UserManagementAccessModal;
