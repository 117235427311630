import React, { useEffect, useRef, useState } from 'react';
import MaterialIcon from '@/components/common/MaterialIcon';
import { useSpaceRegister } from '@/modules/space/hook';
import { useTranslation } from 'react-i18next';
import { LngLat, useAsync, useAsyncEffect } from '@/modules/common';
import {
  fetchDo,
  fetchJapanAddressByZipCode,
  fetchSubDistrict,
} from '@/api/address';
import Preloader from '@/components/common/Preloader';
import { Extent } from 'ol/extent';
import { ImageWMS } from 'ol/source';
import ImageLayer from 'ol/layer/Image';
import { Map } from 'ol';
import FormLabel from '@/components/common/FormLabel';
import FormGroup from '@/components/common/FormGroup';
import InvalidAlert from '@/components/InvalidAlert';
import classNames from 'classnames';
import { fetchGoogleLocation } from '@/api/common';

type JapanAddressSearchProps = {
  show: boolean;
  map: Map | null;
  geofencingLayer: ImageLayer | null;
};

function JapanAddressSearch({
  show,
  map,
  geofencingLayer,
}: JapanAddressSearchProps) {
  const { handleChangeShowAddressPane, handleSetAddress } = useSpaceRegister();
  const [selectedDo, setSelectDo] = useState('');
  const [selectedSubDistrict, setSelectSubDistrict] = useState('');
  const [selectedLngLat, setSelectLngLat] = useState<LngLat>({
    lng: 0,
    lat: 0,
  });
  const [detailedAddress, setDetailedAddress] = useState('');

  useEffect(() => {
    if (!show) {
      handleResetAddress();
    }
  }, [show]);

  const handleResetAddress = () => {
    setSelectDo('');
    setSelectSubDistrict('');
    setDetailedAddress('');
    handleClearJapanAddressLayer();
  };

  const handleClosePane = () => {
    setSelectDo('');
    setSelectSubDistrict('');
    setDetailedAddress('');
    handleChangeShowAddressPane(false);
    handleClearJapanAddressLayer();
  };

  const handleSubmit = () => {
    handleSetAddress({
      selectedDo,
      selectedSubDistrict,
      selectedLngLat,
      detailedAddress,
    });
    handleClosePane();
  };

  const handleClearJapanAddressLayer = () => {
    geofencingLayer?.setVisible(false);
    const source = geofencingLayer?.getSource() as ImageWMS;
    if (source) {
      const params = source.getParams();
      params['LAYERS'] = '';
      params['CQL_FILTER'] = ``;
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <Header
        onClose={handleClosePane}
        selectedDo={selectedDo}
        selectedSubDistrict={selectedSubDistrict}
        setSelectDo={setSelectDo}
        setSelectSubDistrict={setSelectSubDistrict}
      />
      <Body
        show={show}
        map={map}
        geofencingLayer={geofencingLayer}
        selectedDo={selectedDo}
        selectedSubDistrict={selectedSubDistrict}
        detailedAddress={detailedAddress}
        onSelectDo={setSelectDo}
        onSelectSubDistrict={setSelectSubDistrict}
        onSelectLngLat={setSelectLngLat}
        onChangeDetailedAddress={setDetailedAddress}
      />
      {selectedSubDistrict && (
        <Footer onReset={handleResetAddress} onSubmit={handleSubmit} />
      )}
    </div>
  );
}

type HeaderProps = {
  selectedDo: string;
  selectedSubDistrict: string;
  onClose: () => void;
  setSelectDo: (selectedDo: string) => void;
  setSelectSubDistrict: (selectedSubDistrict: string) => void;
};

function Header({
  selectedDo,
  selectedSubDistrict,
  onClose,
  setSelectDo,
  setSelectSubDistrict,
}: HeaderProps) {
  const { t } = useTranslation();

  return (
    <div className="container-fluid d-flex py-4">
      <div className="flex d-flex">
        <div className="mr-24pt">
          <h3 className="mb-2">{t('주소 검색')}</h3>
          <ol className="breadcrumb p-0 mb-2">
            <li className="breadcrumb-item">
              <a
                className="text-primary"
                onClick={() => (setSelectDo(''), setSelectSubDistrict(''))}
              >
                日本
              </a>
            </li>
            {selectedDo && (
              <a
                className="breadcrumb-item active"
                onClick={() => setSelectSubDistrict('')}
              >
                {selectedDo}
              </a>
            )}
            {selectedSubDistrict && (
              <li className="breadcrumb-item active">{selectedSubDistrict}</li>
            )}
          </ol>
        </div>
      </div>
      <a onClick={onClose}>
        <MaterialIcon name={'close'} />
      </a>
    </div>
  );
}

type BodyProps = {
  show: boolean;
  map: Map | null;
  geofencingLayer: ImageLayer | null;
  selectedDo: string;
  selectedSubDistrict: string;
  detailedAddress: string;
  onSelectDo: (selectedDo: string) => void;
  onSelectSubDistrict: (selectedSubDistrict: string) => void;
  onSelectLngLat: (selectedLngLat: LngLat) => void;
  onChangeDetailedAddress: (detailedAddress: string) => void;
};

function Body({
  show,
  map,
  geofencingLayer,
  selectedDo,
  selectedSubDistrict,
  detailedAddress,
  onSelectDo,
  onSelectSubDistrict,
  onSelectLngLat,
  onChangeDetailedAddress,
}: BodyProps) {
  return (
    <div className="container-fluid">
      {!selectedDo && !selectedSubDistrict && (
        <ZipCode
          onSelectDo={onSelectDo}
          onSelectSubDistrict={onSelectSubDistrict}
          onSelectLngLat={onSelectLngLat}
          onChangeDetailedAddress={onChangeDetailedAddress}
        />
      )}
      {!selectedDo && (
        <Do
          show={show}
          map={map}
          geofencingLayer={geofencingLayer}
          onSelectDo={onSelectDo}
        />
      )}
      {selectedDo && !selectedSubDistrict && (
        <SubDistrict
          map={map}
          geofencingLayer={geofencingLayer}
          selectedDo={selectedDo}
          onSelectSubDistrict={onSelectSubDistrict}
          onSelectLngLat={onSelectLngLat}
        />
      )}
      {selectedDo && selectedSubDistrict && (
        <Confirm
          selectedDo={selectedDo}
          selectedSubDistrict={selectedSubDistrict}
          detailedAddress={detailedAddress}
        />
      )}
    </div>
  );
}

type ZipCodeProps = {
  onSelectDo: (selectedDo: string) => void;
  onSelectSubDistrict: (selectedSubDistrict: string) => void;
  onSelectLngLat: (selectedLngLat: LngLat) => void;
  onChangeDetailedAddress: (detailedAddress: string) => void;
};

function ZipCode({
  onSelectDo,
  onSelectSubDistrict,
  onSelectLngLat,
  onChangeDetailedAddress,
}: ZipCodeProps) {
  const { showPane } = useSpaceRegister();
  const { t } = useTranslation();
  const [zipCode, setZipCode] = useState('');
  const [invalidMessage, setInvalidMessage] = useState('msg_enter_zip_code');
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (zipCode.length) {
      setShowInvalidMessage(false);
    }
  }, [zipCode]);

  useEffect(() => {
    if (!showPane) {
      setZipCode('');
      setLoading(false);
    }
  }, [showPane]);

  const handleSearchZipCode = async () => {
    if (loading) {
      return;
    }
    if (!zipCode.length) {
      setInvalidMessage('msg_enter_zip_code');
      setShowInvalidMessage(true);
      return;
    }
    const numberZipCode = zipCode.replace(/[^\d]+/g, '');
    const result = await fetchJapanAddressByZipCode(numberZipCode);

    setLoading(true);
    if (!result) {
      setInvalidMessage('msg_invalid_zip_code');
      setShowInvalidMessage(true);
    } else {
      const lngLat = await fetchGoogleLocation(
        result.pre + result.city + result.addr
      );
      if (lngLat) {
        onSelectLngLat({
          lng: lngLat.lng,
          lat: lngLat.lat,
        });
      }
      onSelectDo(result.pre);
      onSelectSubDistrict(result.city);
      onChangeDetailedAddress(result.addr);
    }
    setLoading(false);
  };

  return (
    <>
      <FormGroup className={'over-text'}>
        <FormLabel textKey={'우편번호 검색'} />
        <input
          type="text"
          className="form-line pr-6"
          placeholder={t('예) 123-4567')}
          value={zipCode}
          onChange={(e) => {
            setZipCode(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              if (zipCode) {
                handleSearchZipCode();
              }
            }
          }}
          autoComplete={'off'}
        />
        <a
          className={classNames('btn-abs btn-light btn-sm', {
            'is-loading is-loading-sm': loading,
          })}
          onClick={handleSearchZipCode}
        >
          {t('검색')}
        </a>
      </FormGroup>
      {showInvalidMessage && (
        <InvalidAlert
          messageKey={invalidMessage}
          alertContainerPadding={false}
        />
      )}
    </>
  );
}

type Do = {
  tfk_nm: string;
  xpos: number;
  ypos: number;
  stx: number;
  sty: number;
  edx: number;
  edy: number;
};

type DoProps = {
  show: boolean;
  map: Map | null;
  geofencingLayer: ImageLayer | null;
  onSelectDo: (selectedDo: string) => void;
};

function Do({ show, map, geofencingLayer, onSelectDo }: DoProps) {
  const [state, api] = useAsync(fetchDo);
  const { loading, data } = state;
  const [doData, setDoData] = useState<Do[]>([]);
  const mountedRef = useRef(false);

  useEffect(() => {
    const mounted = mountedRef.current;

    if (!mounted && show) {
      api();
      mountedRef.current = true;
    }
  }, [show]);

  useEffect(() => {
    if (data) {
      setDoData(data as Do[]);
    }
  }, [data]);

  const handleSelectDo = (doDatum: Do) => {
    onSelectDo(doDatum.tfk_nm);

    const view = map?.getView();
    if (view) {
      const extent: Extent = [
        doDatum.stx,
        doDatum.sty,
        doDatum.edx,
        doDatum.edy,
      ];

      view.fit(extent, {
        size: map?.getSize(),
      });

      const source = geofencingLayer?.getSource() as ImageWMS;
      if (source) {
        const params = source.getParams();
        params['LAYERS'] = 'watta:jp_admdt2';
        params['CQL_FILTER'] = `nl_name_1 = '${doDatum.tfk_nm}'`;
        geofencingLayer?.setVisible(true);
      }
    }
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <div className="address-wrap">
      <div className="place-cover">
        <ul className="place-list">
          {doData.map((doDatum) => (
            <li key={doDatum.tfk_nm} onClick={() => handleSelectDo(doDatum)}>
              <a>{doDatum.tfk_nm}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

type SubDistrict = {
  city_nm: string;
  xpos: number;
  ypos: number;
  stx: number;
  sty: number;
  edx: number;
  edy: number;
};

type SubDistrictProps = {
  map: Map | null;
  geofencingLayer: ImageLayer | null;
  selectedDo: string;
  onSelectSubDistrict: (selectedSubDistrict: string) => void;
  onSelectLngLat: (selectedLngLat: LngLat) => void;
};

function SubDistrict({
  map,
  geofencingLayer,
  selectedDo,
  onSelectSubDistrict,
  onSelectLngLat,
}: SubDistrictProps) {
  const [state] = useAsyncEffect(fetchSubDistrict, [selectedDo], [selectedDo]);
  const { data } = state;
  const [subDistrict, setSubDistrict] = useState<SubDistrict[]>([]);

  useEffect(() => {
    if (data) {
      setSubDistrict(data as SubDistrict[]);
    }
  }, [data]);

  const handleSelectSubDistrict = (subDistrict: SubDistrict) => {
    onSelectLngLat({
      lng: Number(subDistrict.xpos),
      lat: Number(subDistrict.ypos),
    });
    onSelectSubDistrict(subDistrict.city_nm);

    const view = map?.getView();
    if (view) {
      const extent: Extent = [
        subDistrict.stx,
        subDistrict.sty,
        subDistrict.edx,
        subDistrict.edy,
      ];

      view.fit(extent, {
        size: map?.getSize(),
      });

      const source = geofencingLayer?.getSource() as ImageWMS;
      if (source) {
        const params = source.getParams();
        params['LAYERS'] = 'watta:jp_admdt';
        params[
          'CQL_FILTER'
        ] = `nl_name_1 = '${selectedDo}' and nl_name_2 = '${subDistrict.city_nm}'`;
        geofencingLayer?.setVisible(true);
      }
    }
  };

  return (
    <div className="address-wrap">
      <div className="place-cover">
        <ul className="place-list">
          {subDistrict.map((subDistrictDatum) => (
            <li
              key={subDistrictDatum.city_nm}
              onClick={() => {
                handleSelectSubDistrict(subDistrictDatum);
              }}
            >
              <a>{subDistrictDatum.city_nm}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

type ConfirmProps = {
  selectedDo: string;
  selectedSubDistrict: string;
  detailedAddress: string;
};

function Confirm({
  selectedDo,
  selectedSubDistrict,
  detailedAddress,
}: ConfirmProps) {
  return (
    <div className="address-wrap">
      <div className="address-fullName">
        <em className="">{selectedDo + selectedSubDistrict}</em>
        {detailedAddress && (
          <p className="address-fullName2">{detailedAddress}</p>
        )}
      </div>
    </div>
  );
}

type FooterProps = {
  onReset: () => void;
  onSubmit: () => void;
};

function Footer({ onReset, onSubmit }: FooterProps) {
  const { t } = useTranslation();

  return (
    <div className="my-32pt">
      <div className="d-flex align-items-center justify-content-center">
        <a className="btn btn-outline-secondary mr-8pt" onClick={onReset}>
          {t('처음으로')}
        </a>
        <a className="btn btn-accent ml-0" onClick={onSubmit}>
          {t('주소 등록')}
        </a>
      </div>
    </div>
  );
}

export default JapanAddressSearch;
