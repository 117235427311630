import React, { useEffect, useState } from 'react';
import HomeRecentProjectList from '@/components/home/HomeRecentProjectList';
import HomeProjectList from '@/components/home/HomeProjectList';
import {
  MENU_IDX_MONITORING,
  MENU_MAPPING,
  MenuIdx,
  MenuName,
} from '@/modules/setup/types';
import { useParams } from 'react-router';
import { ParamsHomeRoute } from '@/modules/param/types';
import {
  useHomeMenu,
  useHomeProject,
  useHomeSelected,
} from '@/modules/home/hook';

function HomeDashboard() {
  const { menu_name: paramMenuName } = useParams<ParamsHomeRoute>();
  const { project, building, floor } = useHomeSelected();
  const { handleChangeProject } = useHomeProject();
  const { handleSetMenu } = useHomeMenu();
  const [reloadFlag, setReloadFlag] = useState(false);

  useEffect(() => {
    if (paramMenuName) {
      const menuName = paramMenuName as MenuName;
      const isExistMenu = MENU_MAPPING.hasOwnProperty(menuName);
      if (isExistMenu) {
        if (project && building && floor) {
          handleSetMenu(MENU_MAPPING[menuName] as MenuIdx);
        }
      } else {
        handleChangeProject('');
      }
    } else {
      if (project) {
        handleSetMenu(MENU_IDX_MONITORING);
      }
    }
  }, [project, building, floor, paramMenuName]);

  if (paramMenuName) {
    return <></>;
  }

  return (
    <>
      <div className="contents-section">
        <div className="cell">
          <HomeRecentProjectList reloadFlag={reloadFlag} />
        </div>
      </div>
      <div className="contents-section">
        <div className="cell">
          <HomeProjectList onReload={() => setReloadFlag(!reloadFlag)} />
        </div>
      </div>
    </>
  );
}

export default HomeDashboard;
