import React, { ReactNode } from 'react';
import classNames from 'classnames';

type FormGroupProps = {
  className?: string;
  marginBottomClassName?: string;
  children?: ReactNode;
};

function FormGroup({
  className,
  marginBottomClassName = 'mb-32pt',
  children,
}: FormGroupProps) {
  return (
    <div className={classNames('form-group', marginBottomClassName, className)}>
      {children}
    </div>
  );
}

export default FormGroup;
