import {
  useBuildingInfoList,
  useSelectedBuilding,
  useSelectedFloorPlanId,
} from '@/modules/project/hook';
import React, { ReactElement, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

function BuildingList(): ReactElement {
  const { t } = useTranslation();
  const { buildingInfoList } = useBuildingInfoList();
  const { selectedBuilding, setSelectedBuilding } = useSelectedBuilding();
  const { setSelectedFloorPlanId } = useSelectedFloorPlanId();
  const directFlagRef = useRef(false);

  useEffect(() => {
    const directFlag = directFlagRef.current;
    if (selectedBuilding && selectedBuilding.spaceId) {
      if (!directFlag) {
        directFlagRef.current = true;
      }
    } else if (!directFlag && buildingInfoList.length) {
      const building = buildingInfoList[0];
      setSelectedBuilding(building);
    }
  }, [buildingInfoList]);

  // 빌딩 선택 이후
  const onChangeBuilding = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setSelectedBuilding(
        buildingInfoList.filter(
          (building) => building.mappingId === e.target.value
        )[0]
      );
      setSelectedFloorPlanId('');
    } else {
      setSelectedBuilding(null);
      setSelectedFloorPlanId('');
    }
  };

  return (
    <select
      className="form-line mb-3"
      onChange={onChangeBuilding}
      value={selectedBuilding ? selectedBuilding.mappingId : ''}
    >
      <option value="">{t('건물을 선택해 주세요.')}</option>
      {buildingInfoList.map((buildingInfo) => (
        <option key={buildingInfo.mappingId} value={buildingInfo.mappingId}>
          {buildingInfo.spaceName}
        </option>
      ))}
    </select>
  );
}

export default BuildingList;
