import React, { useState } from 'react';
import {
  MonitoringGeoObjectAreaChild,
  MonitoringGeoObjectAreaGroup,
} from '@/modules/monitoring/types';
import classNames from 'classnames';
import { Collapse } from 'react-bootstrap';

type MonitoringGeoObjectAreaGroupItemProps = {
  geoObjectAreaGroup: MonitoringGeoObjectAreaGroup;
};
function MonitoringGeoObjectAreaGroupItem({
  geoObjectAreaGroup: { areatype_name, values },
}: MonitoringGeoObjectAreaGroupItemProps) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <tr
        className={classNames('clickable', {
          collapsed: !isOpen,
        })}
      >
        <td className="text-center">
          <img src="/static/images/poi/object_area.svg" />
        </td>
        <td>
          {areatype_name}
          {/*
          <div className="d-flex mt-2">
            <a className="btn-ico-identity active">
              <span className="material-icons">warning</span>
            </a>
            <a className="btn-ico-identity">
              <span className="material-icons-outlined">event_note</span>
              <small>0</small>
            </a>
            <a className="btn-ico-identity">
              <span className="material-icons-outlined">redeem</span>
              <small>0</small>
            </a>
          </div>
          */}
        </td>
        <td>
          <div className="d-flex justify-content-center">
            <a className="ac-arrow d-flex" onClick={() => setOpen(!isOpen)}>
              <span className="material-icons-outlined font-size-24pt">
                expand_less
              </span>
            </a>
          </div>
        </td>
      </tr>
      <tr className="ac-body">
        <td colSpan={3}>
          <Collapse in={isOpen}>
            <div>
              <div className="border-top-1">
                <table className="table-sub">
                  <colgroup>
                    <col width="25%" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    {Object.values(values).map((children) =>
                      children.map((child, i) => (
                        <AreaPoiItem key={i} child={child} />
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Collapse>
        </td>
      </tr>
    </>
  );
}

type AreaPoiItemProps = {
  child: MonitoringGeoObjectAreaChild;
};

function AreaPoiItem({ child: { imgsrc, gname } }: AreaPoiItemProps) {
  return (
    <>
      <tr>
        <td className="text-center">
          <img src={imgsrc} />
        </td>
        <td>
          <a>{gname || '-'}</a>
        </td>
      </tr>
    </>
  );
}

export default MonitoringGeoObjectAreaGroupItem;
