import React, { ReactNode } from 'react';
import { PaneStatus } from '@/utils/constants/common';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

export const TOAST_PRIMARY_TYPE = 'primary';
export const TOAST_SECONDARY_TYPE = 'secondary';
export const TOAST_SUCCESS_TYPE = 'success';
export const TOAST_ALERT_TYPE = 'alert';

export const TOAST_OVER_SPEED_ICON = 'warning_amber';
export const TOAST_WRONG_PATH_ICON = 'remove_road';
export const TOAST_IN_ICON = 'login';
export const TOAST_OUT_ICON = 'logout';

export type AsyncState<T = any> = {
  loading: boolean;
  error: Error | null;
  data: T | null;
};

type LoadingAction = {
  type: 'LOADING';
};

type SuccessAction<T> = {
  type: 'SUCCESS';
  data: T;
};

type ErrorAction = {
  type: 'ERROR';
  error: Error;
};

export type AsyncAction<T> = LoadingAction | SuccessAction<T> | ErrorAction;

export type ApiResult<T = any> = {
  status: number;
  message: string;
  data: T | null;
};

export type ListResult<T> = {
  content: T;
  totalElements: number;
};

export type PromiseFn<T> = (...args: any) => Promise<T>;

export type ModalProps = {
  show: boolean;
  onHide: () => void;
  onReload?: () => void;
};

export type ModalContainerProps = ModalProps & {
  children: ReactNode;
  onConfirm: () => void;
};

export type ModalAlertProps = {
  show: boolean;
  children: ReactNode;
  onHide: () => void;
};

export type ReactSelectOption = {
  value: string;
  label: string | ReactNode;
  name: string;
};

export type ScrollVisibilityApiType = React.ContextType<
  typeof VisibilityContext
>;

export type PaneProps = {
  onChangeStatus: (status: PaneStatus) => void;
};

export type ExcelCode = 'ASSET';

export type LngLat = {
  lng: number;
  lat: number;
};

export type ToastType =
  | typeof TOAST_PRIMARY_TYPE
  | typeof TOAST_SECONDARY_TYPE
  | typeof TOAST_SUCCESS_TYPE
  | typeof TOAST_ALERT_TYPE;

export type ToastIconType =
  | typeof TOAST_OVER_SPEED_ICON
  | typeof TOAST_WRONG_PATH_ICON
  | typeof TOAST_IN_ICON
  | typeof TOAST_OUT_ICON;
