import {
  Action,
  OPEN_LAYERS_SET_DRAW_INTERACTION,
  OPEN_LAYERS_SET_DRAW_RESOURCE,
  OPEN_LAYERS_SET_GEOFENCE_RESOURCE,
  OPEN_LAYERS_SET_INIT_STATE,
  OPEN_LAYERS_SET_MAP,
  OPEN_LAYERS_SET_MONITORING_LIDAR_RESOURCE,
  OpenLayersState,
} from '@/modules/openlayers/types';
import { initialState } from '@/modules/openlayers/context';

export function openLayersReducer(state: OpenLayersState, action: Action) {
  switch (action.type) {
    case OPEN_LAYERS_SET_MAP:
      return {
        ...state,
        map: action.map,
      };
    case OPEN_LAYERS_SET_DRAW_RESOURCE:
      return {
        ...state,
        draw: {
          ...state.draw,
          resource: action.drawResource,
        },
      };
    case OPEN_LAYERS_SET_DRAW_INTERACTION:
      return {
        ...state,
        draw: {
          ...state.draw,
          interaction: action.drawInteraction,
        },
      };
    case OPEN_LAYERS_SET_GEOFENCE_RESOURCE:
      return {
        ...state,
        geofence: {
          layer: action.layer,
        },
      };
    case OPEN_LAYERS_SET_MONITORING_LIDAR_RESOURCE:
      return {
        ...state,
        monitoring: {
          ...state.monitoring,
          lidar: {
            personLayer: action.personLayer,
          },
        },
      };
    case OPEN_LAYERS_SET_INIT_STATE:
      return initialState;
  }
}
