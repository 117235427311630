import { useCallback, useContext, useMemo } from 'react';
import {
  SetupDispatchContext,
  SetupStateContext,
} from '@/modules/setup/context';
import {
  FloatPaneView,
  InfoPaneView,
  MenuIdx,
  RightPaneView,
  SET_UP_CHANGE_FLOAT_PANE_SHOW,
  SET_UP_CHANGE_INFO_PANE_SHOW,
  SET_UP_CHANGE_LAYER_POPUP_SHOW,
  SET_UP_CHANGE_LEFT_PANE_SHOW,
  SET_UP_CHANGE_LEFT_SIDE_BAR_EXPAND,
  SET_UP_CHANGE_PUSH_RECEIVE_FLAG,
  SET_UP_CHANGE_RIGHT_PANE_SHOW,
  SET_UP_SET_WINDOW_POPUP,
  SET_UP_INIT,
  SET_UP_MENU_ACTIVE,
  SET_UP_MENU_RESET_ACTIVE,
} from '@/modules/setup/types';

function useSetupState() {
  const state = useContext(SetupStateContext);
  if (!state) throw new Error('SetupProvider not found');
  return state;
}

function useSetupDispatch() {
  const dispatch = useContext(SetupDispatchContext);
  if (!dispatch) throw new Error('SetupProvider not found');
  return dispatch;
}

export function useLeftPane() {
  const {
    pane: {
      left: { show },
    },
  } = useSetupState();

  const dispatch = useSetupDispatch();

  const handleChangeShow = (show: boolean) => {
    dispatch({
      type: SET_UP_CHANGE_LEFT_PANE_SHOW,
      show,
    });
  };

  return {
    show,
    handleChangeShow,
  };
}

export function useFloatPane() {
  const { pane } = useSetupState();
  const dispatch = useSetupDispatch();
  const { show, view } = pane.float;

  const handleChangeShow = (show: boolean, view: FloatPaneView = undefined) => {
    dispatch({
      type: SET_UP_CHANGE_FLOAT_PANE_SHOW,
      show,
      view,
    });
  };

  return {
    show,
    view,
    handleChangeShow,
  };
}

export function useRightPane() {
  const { pane } = useSetupState();
  const dispatch = useSetupDispatch();
  const { show, view } = pane.right;

  const handleChangeShow = (show: boolean, view: RightPaneView = undefined) => {
    dispatch({
      type: SET_UP_CHANGE_RIGHT_PANE_SHOW,
      show,
      view,
    });
  };

  return {
    show,
    view,
    handleChangeShow,
  };
}

export function useLayerPopup() {
  const {
    layerPopup: { show },
  } = useSetupState();
  const dispatch = useSetupDispatch();

  const handleChangeShow = useCallback((show: boolean) => {
    dispatch({
      type: SET_UP_CHANGE_LAYER_POPUP_SHOW,
      show,
    });
  }, []);

  return {
    show,
    handleChangeShow,
  };
}

export function useActiveMenu() {
  const state = useSetupState();
  const dispatch = useSetupDispatch();
  const activeMenu = useMemo(
    () => state.menus.find((menuDatum) => menuDatum.active),
    [state.menus]
  );

  let menuIdx = -1 as MenuIdx;
  let show = false;
  let showWide = false;
  if (activeMenu) {
    menuIdx = activeMenu.menuIdx;
    if (activeMenu.wide) {
      showWide = true;
    } else {
      show = true;
    }
  }

  const handleMenuActive = (menuIdx: number) => {
    dispatch({
      type: SET_UP_MENU_ACTIVE,
      menuIdx: menuIdx,
    });
  };

  const handleMenuResetActive = () => {
    dispatch({
      type: SET_UP_MENU_RESET_ACTIVE,
    });
  };

  return {
    menuIdx,
    show,
    showWide,
    handleMenuActive,
    handleMenuResetActive,
  };
}

export function useSetupInit() {
  const dispatch = useSetupDispatch();

  const handleSetupInit = () => {
    dispatch({
      type: SET_UP_INIT,
    });
  };

  return {
    handleSetupInit,
  };
}

export function useLeftSidebar() {
  const {
    sidebar: {
      left: { expand },
    },
  } = useSetupState();
  const dispatch = useSetupDispatch();

  const handleChangeExpand = (expand: boolean) => {
    dispatch({
      type: SET_UP_CHANGE_LEFT_SIDE_BAR_EXPAND,
      expand,
    });
  };

  return {
    expand,
    handleChangeExpand,
  };
}

export function useInfoPane() {
  const { pane } = useSetupState();
  const dispatch = useSetupDispatch();
  const { show, view } = pane.info;

  const handleChangeShow = (show: boolean, view: InfoPaneView = undefined) => {
    dispatch({
      type: SET_UP_CHANGE_INFO_PANE_SHOW,
      show,
      view,
    });
  };

  return {
    show,
    view,
    handleChangeShow,
  };
}

export function usePush() {
  const { pushReceiveFlag } = useSetupState();
  const dispatch = useSetupDispatch();

  const handleChangeReceiveFlag = () => {
    dispatch({
      type: SET_UP_CHANGE_PUSH_RECEIVE_FLAG,
    });
  };

  return {
    pushReceiveFlag,
    handleChangeReceiveFlag,
  };
}

export function useWindowPopup() {
  const { windowPopupList } = useSetupState();
  const dispatch = useSetupDispatch();

  const handleSetWindowPopup = useCallback((windowPopup: Window) => {
    dispatch({
      type: SET_UP_SET_WINDOW_POPUP,
      windowPopup,
    });
  }, []);

  const handleCloseWindowPopup = useCallback(() => {
    try {
      for (const windowPopup of windowPopupList) {
        windowPopup.close();
      }
    } catch (e) {}
  }, [windowPopupList]);

  return {
    handleSetWindowPopup,
    handleCloseWindowPopup,
  };
}
