import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMonitoringLidar } from '@/modules/monitoring/hook';
import { useInfoPane } from '@/modules/setup/hook';
import { useOpenLayersMonitoring } from '@/modules/openlayers/hook';
import { Point } from 'ol/geom';
import { CommonUtils } from '@/utils';
import { OpenLayersUtils } from '@/utils/OpenLayersUtils';

function MonitoringLinkInfoPane() {
  const { t } = useTranslation();
  const { handleChangeShow } = useInfoPane();
  const {
    lidar: { personLayer },
  } = useOpenLayersMonitoring();
  const {
    lidarObjectList,
    selectedLidarObjectList,
    handleInitSelectedLidarObjectList,
  } = useMonitoringLidar();
  const [distance, setDistance] = useState(0);

  const handleClickCancel = () => {
    handleInitSelectedLidarObjectList();
    handleChangeShow(false);
  };

  useEffect(() => {
    if (personLayer && selectedLidarObjectList.length > 1) {
      const firstSelectedLidarObject = selectedLidarObjectList[0];
      const secondSelectedLidarObject = selectedLidarObjectList[1];
      const firstSelectedFeature = personLayer
        .getSource()
        .getFeatureById(firstSelectedLidarObject.serialId);
      const secondSelectedFeature = personLayer
        .getSource()
        .getFeatureById(secondSelectedLidarObject.serialId);
      if (firstSelectedFeature && secondSelectedFeature) {
        const point1 = firstSelectedFeature.getGeometry() as Point;
        const point2 = secondSelectedFeature.getGeometry() as Point;
        const [lng1, lat1] = OpenLayersUtils.convertCoordinates(
          point1.getCoordinates(),
          'EPSG:3857',
          'EPSG:4326'
        );
        const [lng2, lat2] = OpenLayersUtils.convertCoordinates(
          point2.getCoordinates(),
          'EPSG:3857',
          'EPSG:4326'
        );

        setDistance(
          CommonUtils.getDistanceFromLatLonInMeter(lat1, lng1, lat2, lng2)
        );
      }
    }
  }, [lidarObjectList]);

  return (
    <>
      <div className="title flex-column">
        <h4>
          <span>
            {selectedLidarObjectList.map(({ serialId }) => serialId).join(', ')}
          </span>
        </h4>
        <p className="text-center m-0">
          {t('간격')} <span className="text-identity mr-1">{distance}</span>m
        </p>
      </div>
      <div className="cover">
        <a className="btn-cancel" onClick={handleClickCancel}>
          {t('취소')}
        </a>
      </div>
    </>
  );
}

export default MonitoringLinkInfoPane;
