import React, { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { Action, HomeState } from '@/modules/home/types';
import { homeReducer } from '@/modules/home/reducer';
import {
  MENU_IDX_ACCIDENT,
  MENU_IDX_CAMPAIGN,
  MENU_IDX_ASSETS,
  MENU_IDX_DASHBOARD,
  MENU_IDX_EVENT,
  MENU_IDX_MONITORING,
  MENU_IDX_SENSOR,
  MENU_IDX_MESSAGE,
  MENU_IDX_ACCOUNT,
  MENU_IDX_GROUP_SETTINGS,
  MENU_IDX_GROUP_SETTINGS_USER,
  MENU_IDX_GROUP_SETTINGS_GROUP,
  MENU_IDX_STATISTICS,
} from '@/modules/setup/types';

export const HomeStateContext = createContext<HomeState | null>(null);
type HomeDispatch = Dispatch<Action>;
export const HomeDispatchContext = createContext<HomeDispatch | null>(null);

export const initialState: HomeState = {
  menus: [
    {
      menuIdx: MENU_IDX_DASHBOARD,
      titleKey: '프로젝트',
      iconName: 'grid_view',
      active: true,
      display: true,
      subMenus: [],
      allowableUserType: [],
      needProject: false,
      showLeftPane: false,
      inside: false,
      outer: true,
    },
    {
      menuIdx: MENU_IDX_GROUP_SETTINGS,
      titleKey: '사용자',
      iconName: 'people',
      active: false,
      display: true,
      subMenus: [
        {
          menuIdx: MENU_IDX_GROUP_SETTINGS_USER,
          titleKey: '사용자 관리',
          active: false,
          allowableUserType: [],
        },
        {
          menuIdx: MENU_IDX_GROUP_SETTINGS_GROUP,
          titleKey: '그룹 관리',
          active: false,
          allowableUserType: [],
        },
      ],
      allowableUserType: [],
      needProject: false,
      showLeftPane: false,
      inside: false,
      outer: true,
    },
    {
      menuIdx: MENU_IDX_MONITORING,
      titleKey: '모니터링',
      iconName: 'insert_chart_outlined',
      active: false,
      display: true,
      subMenus: [],
      allowableUserType: [],
      needProject: true,
      showLeftPane: true,
      inside: true,
      outer: false,
    },
    {
      menuIdx: MENU_IDX_ASSETS,
      titleKey: '자산',
      iconName: 'category',
      active: false,
      display: true,
      subMenus: [],
      allowableUserType: [],
      needProject: true,
      showLeftPane: true,
      inside: true,
      outer: false,
    },
    {
      menuIdx: MENU_IDX_SENSOR,
      titleKey: '센서',
      iconName: 'air',
      active: false,
      display: true,
      subMenus: [],
      allowableUserType: [],
      needProject: true,
      showLeftPane: true,
      inside: true,
      outer: false,
    },
    {
      menuIdx: MENU_IDX_EVENT,
      titleKey: '알림 설정',
      iconName: 'notifications',
      active: false,
      display: true,
      subMenus: [],
      allowableUserType: [],
      needProject: true,
      showLeftPane: true,
      inside: true,
      outer: false,
    },
    {
      menuIdx: MENU_IDX_CAMPAIGN,
      titleKey: '캠페인',
      iconName: 'redeem',
      active: false,
      display: true,
      subMenus: [],
      allowableUserType: [],
      needProject: true,
      showLeftPane: true,
      inside: true,
      outer: false,
    },
    {
      menuIdx: MENU_IDX_ACCIDENT,
      titleKey: '사고',
      iconName: 'warning_amber',
      active: false,
      display: true,
      subMenus: [],
      allowableUserType: [],
      needProject: true,
      showLeftPane: true,
      inside: true,
      outer: false,
    },
    {
      menuIdx: MENU_IDX_MESSAGE,
      titleKey: '',
      iconName: '',
      active: false,
      display: false,
      subMenus: [],
      allowableUserType: [],
      needProject: false,
      showLeftPane: false,
      inside: true,
      outer: false,
    },
    {
      menuIdx: MENU_IDX_ACCOUNT,
      titleKey: '',
      iconName: '',
      active: false,
      display: false,
      subMenus: [],
      allowableUserType: [],
      needProject: false,
      showLeftPane: false,
      inside: true,
      outer: false,
    },
    {
      menuIdx: MENU_IDX_STATISTICS,
      titleKey: '통계',
      iconName: 'bar_chart',
      active: false,
      display: true,
      subMenus: [],
      allowableUserType: [],
      needProject: true,
      showLeftPane: false,
      inside: true,
      outer: false,
    },
  ],
  selected: {
    project: null,
    building: null,
    floor: null,
  },
  data: {
    groupSettingUser: {
      userId: '',
      directFlag: false,
    },
    groupSettingGroup: {
      groupSeq: -1,
      directFlag: false,
    },
  },
};

export function HomeContextProvider({ children }: { children: ReactNode }) {
  const [home, dispatch] = useReducer(homeReducer, initialState);

  return (
    <HomeDispatchContext.Provider value={dispatch}>
      <HomeStateContext.Provider value={home}>
        {children}
      </HomeStateContext.Provider>
    </HomeDispatchContext.Provider>
  );
}
