import { useTranslation } from 'react-i18next';
import React from 'react';
import classNames from 'classnames';

type LeftMenuProps = {
  titleKey: string;
  iconName: string;
  active: boolean;
  onClick?: () => void;
};

function LeftMenu({ titleKey, iconName, active, onClick }: LeftMenuProps) {
  const { t } = useTranslation();

  return (
    <a
      className={classNames('nav__link', {
        active,
      })}
      onClick={() => onClick?.call(null)}
    >
      <span className="material-icons-outlined">{iconName}</span>
      <span className="nav_name">{t(titleKey)}</span>
    </a>
  );
}

export default LeftMenu;
