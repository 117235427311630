import axios from 'axios';
import { Config } from '@/config';
import { ApiResult, ListResult } from '@/modules/common';
import { StatusCodes } from 'http-status-codes';
import { PAGING_SIZE } from '@/utils/constants/common';
import {
  Group,
  GroupAddProject,
  GroupAddUser,
  GroupChangeRoleProject,
  GroupDetail,
  GroupProject,
  InviteUser,
  InviteUserDetail,
} from '@/modules/group/types';
import { DataResultMessage } from '@/api/type';
import { CompanyInfo } from '@/modules/user/types';
import { fetchImage } from '@/api/common';

export async function fetchInviteUsers(page: number, searchKey: string) {
  const response = await axios.get(
    `${Config.platform_api.uri}/user-manages/users`,
    {
      params: {
        pageSize: PAGING_SIZE,
        page,
        usePaging: true,
        searchKey,
      },
    }
  );
  const result: ApiResult = response.data;

  let processResult: ListResult<InviteUser[]> = {
    content: [],
    totalElements: 0,
  };

  if (result.status === StatusCodes.OK) {
    processResult = {
      content: result.data.content,
      totalElements: result.data.totalElements,
    };

    for (const inviteUser of processResult.content) {
      const profileImgId = inviteUser.profileImgId;
      if (profileImgId) {
        const imageUrl = await fetchImage(profileImgId);
        if (imageUrl) {
          inviteUser.profileImgUrl = imageUrl;
        }
      }
    }

    return processResult;
  }

  return processResult;
}

type ValidInviteUser = {
  validity: boolean;
  status: string;
};

export async function fetchValidInviteUser(userId: string) {
  const response = await axios.get(
    `${Config.platform_api.uri}/user-manages/invitation/validation`,
    {
      params: {
        userId,
      },
    }
  );

  const result: ApiResult = response.data;
  if (result.status === StatusCodes.OK) {
    const validInviteUser = result.data as ValidInviteUser;
    return validInviteUser.validity;
  }

  return false;
}

export async function postInviteUsers(
  data: CompanyInfo & {
    users: string[];
    langCd: string;
  }
) {
  const response = await axios.post(
    `${Config.platform_api.uri}/user-manages/invitation`,
    data
  );

  const result: ApiResult = response.data;
  if (result.status === StatusCodes.OK) {
    return true;
  }

  return false;
}

export async function postRetryInviteUser(userId: string, langCd: string) {
  const response = await axios.post(
    `${Config.platform_api.uri}/user-manages/invitation/retry`,
    {
      users: [userId],
      langCd,
    }
  );

  const result: ApiResult = response.data;
  if (result.status === StatusCodes.OK) {
    return true;
  }

  return false;
}

export async function fetchInviteAuthNumber(authNumber: string) {
  const axiosInstance = axios.create();
  const response = await axiosInstance.get(
    `${Config.platform_api.uri}/auth/i/${authNumber}`
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    const data = result.data as DataResultMessage;

    if (data.result) {
      return data.detail;
    }
  }

  return null;
}

export async function fetchInviteUser(userId: string) {
  const response = await axios.get(
    `${Config.platform_api.uri}/user-manages/users/${userId}`
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    if (result.data && result.data.detail) {
      const inviteUserDetail = result.data.detail as InviteUserDetail;
      if (inviteUserDetail.profileImgId) {
        const imageUrl = await fetchImage(inviteUserDetail.profileImgId);
        if (imageUrl) {
          inviteUserDetail.profileImgUrl = imageUrl;
        }
      }

      return inviteUserDetail;
    }

    return null;
  }

  return null;
}

export async function fetchInviteUserProjects(userId: string, page: number) {
  const response = await axios.get(
    `${Config.platform_api.uri}/user-manages/users/${userId}/projects`,
    {
      params: {
        pageSize: PAGING_SIZE,
        page,
        usePaging: true,
      },
    }
  );
  const result: ApiResult = response.data;

  let processResult: ListResult<GroupProject[]> = {
    content: [],
    totalElements: 0,
  };

  if (result.status === StatusCodes.OK) {
    processResult = {
      content: result.data.content,
      totalElements: result.data.totalElements,
    };

    processResult.content.forEach((project) => {
      project.thumbnail = `/static/images/slider0${Number(
        Math.floor(Math.random() * (4 - 1) + 1)
      )}.jpg`;
    });

    return processResult;
  }

  return processResult;
}

export async function fetchInviteUserGroups(userId: string, page: number) {
  const response = await axios.get(
    `${Config.platform_api.uri}/user-manages/users/${userId}/groups`,
    {
      params: {
        pageSize: PAGING_SIZE,
        page,
        usePaging: true,
      },
    }
  );
  const result: ApiResult = response.data;

  let processResult: ListResult<Group[]> = {
    content: [],
    totalElements: 0,
  };

  if (result.status === StatusCodes.OK) {
    processResult = {
      content: result.data.content,
      totalElements: result.data.totalElements,
    };

    return processResult;
  }

  return processResult;
}

export async function putInviteUserAccess(data: {
  userId: string;
  activeFlag: boolean;
  invitationSeq: number;
}) {
  const response = await axios.put(
    `${Config.platform_api.uri}/user-manages/users/access-control`,
    data
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return true;
  }

  return false;
}

export async function fetchInviteUserAddableGroups(userId: string) {
  const response = await axios.get(
    `${Config.platform_api.uri}/user-manages/joinable-groups`,
    {
      params: {
        userId,
      },
    }
  );
  const result: ApiResult = response.data;
  let addableGroupList: Group[] = [];
  if (result.status === StatusCodes.OK) {
    const dataResultMessage = result.data as DataResultMessage<Group[]>;
    if (dataResultMessage.result) {
      const data = dataResultMessage.data;
      if (typeof data !== 'undefined') {
        addableGroupList = data;
      }
    }
  }

  return addableGroupList;
}

export async function postInviteUserAddGroups(data: {
  userId: string;
  invitationSeq: number;
  groupSeqs: number[];
}) {
  const response = await axios.post(
    `${Config.platform_api.uri}/user-manages/user-to-groups`,
    data
  );

  const result: ApiResult = response.data;
  if (result.status === StatusCodes.OK) {
    for (const key in result.data) {
      const datum = result.data[key] as DataResultMessage;
      if (datum.result !== 'true') {
        return false;
      }
    }
    return true;
  }

  return false;
}

export async function deleteInviteUserDeleteGroup(data: {
  userId: string;
  groupSeq: number;
  seq: number;
}) {
  const response = await axios.delete(
    `${Config.platform_api.uri}/user-manages/user-to-group`,
    {
      data,
    }
  );

  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return true;
  }

  return false;
}

// Group --------------------------------------------------------------------------------------------------------------------
export async function fetchGroups(page: number, searchKey: string) {
  const response = await axios.get(
    `${Config.platform_api.uri}/group-manages/groups`,
    {
      params: {
        pageSize: PAGING_SIZE,
        page,
        usePaging: true,
        searchKey,
      },
    }
  );
  const result: ApiResult = response.data;

  let processResult: ListResult<Group[]> = {
    content: [],
    totalElements: 0,
  };

  if (result.status === StatusCodes.OK) {
    processResult = {
      content: result.data.content,
      totalElements: result.data.totalElements,
    };

    return processResult;
  }

  return processResult;
}

export async function fetchGroup(groupSeq: number) {
  const response = await axios.get(
    `${Config.platform_api.uri}/group-manages/groups/${groupSeq}`
  );
  const result: ApiResult = response.data;
  if (result.status === StatusCodes.OK) {
    if (result.data && result.data.detail) {
      return result.data.detail as GroupDetail;
    }
  }

  return null;
}

export async function postGroup(data: {
  groupName: string;
  groupDesc: string;
  maxCount: number;
}) {
  const response = await axios.post(
    `${Config.platform_api.uri}/group-manages/groups`,
    data
  );

  const result: ApiResult = response.data;
  if (result.status === StatusCodes.OK) {
    return true;
  }

  return false;
}

export async function putGroup(
  groupSeq: number,
  data: { groupName: string; groupDesc: string }
) {
  const response = await axios.put(
    `${Config.platform_api.uri}/group-manages/groups/${groupSeq}`,
    data
  );
  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return true;
  }

  return false;
}

export async function deleteGroup(groupSeq: number) {
  const response = await axios.delete(
    `${Config.platform_api.uri}/group-manages/groups/${groupSeq}`
  );

  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return true;
  }

  return false;
}

export async function fetchGroupInviteUsers(
  groupSeq: number,
  page: number,
  searchKey: string
) {
  const response = await axios.get(
    `${Config.platform_api.uri}/group-manages/groups/${groupSeq}/users`,
    {
      params: {
        pageSize: PAGING_SIZE,
        page,
        usePaging: true,
        searchKey,
      },
    }
  );

  const result: ApiResult = response.data;

  let processResult: ListResult<InviteUserDetail[]> = {
    content: [],
    totalElements: 0,
  };

  if (result.status === StatusCodes.OK) {
    processResult = {
      content: result.data.content,
      totalElements: result.data.totalElements,
    };

    for (const InviteUserDetail of processResult.content) {
      const profileImgId = InviteUserDetail.profileImgId;
      if (profileImgId) {
        const imageUrl = await fetchImage(profileImgId);
        if (imageUrl) {
          InviteUserDetail.profileImgUrl = imageUrl;
        }
      }
    }

    return processResult;
  }

  return processResult;
}

export async function fetchGroupAddableInviteUsers(groupSeq: number) {
  const response = await axios.get(
    `${Config.platform_api.uri}/group-manages/groups/joinable-users`,
    {
      params: {
        groupSeq,
      },
    }
  );
  const result: ApiResult = response.data;
  let addableInviteUserList: InviteUserDetail[] = [];
  if (result.status === StatusCodes.OK) {
    addableInviteUserList = result.data as InviteUserDetail[];
    for (const inviteUser of addableInviteUserList) {
      const profileImgId = inviteUser.profileImgId;
      if (profileImgId) {
        const imageUrl = await fetchImage(profileImgId);
        if (imageUrl) {
          inviteUser.profileImgUrl = imageUrl;
        }
      }
    }
  }

  return addableInviteUserList;
}

export async function postGroupAddInviteUsers(
  groupSeq: number,
  userInfo: GroupAddUser[]
) {
  const response = await axios.post(
    `${Config.platform_api.uri}/group-manages/groups/${groupSeq}/users`,
    {
      userInfo,
    }
  );

  const result: ApiResult = response.data;
  if (result.status === StatusCodes.OK) {
    const dataResultMessage = result.data as DataResultMessage;
    return dataResultMessage.result === 'true';
  }

  return false;
}

export async function fetchGroupProjects(
  groupSeq: number,
  page: number,
  searchKey: string
) {
  const response = await axios.get(
    `${Config.platform_api.uri}/group-manages/groups/${groupSeq}/projects`,
    {
      params: {
        pageSize: PAGING_SIZE,
        page,
        usePaging: true,
        searchKey,
      },
    }
  );

  const result: ApiResult = response.data;

  let processResult: ListResult<GroupProject[]> = {
    content: [],
    totalElements: 0,
  };

  if (result.status === StatusCodes.OK) {
    processResult = {
      content: result.data.content,
      totalElements: result.data.totalElements,
    };

    processResult.content.forEach((project) => {
      project.thumbnail = `/static/images/slider0${Number(
        Math.floor(Math.random() * (4 - 1) + 1)
      )}.jpg`;
    });

    return processResult;
  }

  return processResult;
}

export async function fetchGroupAddableProjects(
  groupSeq: number,
  page: number
) {
  const response = await axios.get(
    `${Config.platform_api.uri}/group-manages/groups/attachable-projects`,
    {
      params: {
        groupSeq,
        pageSize: PAGING_SIZE,
        page,
        usePaging: true,
      },
    }
  );
  const result: ApiResult = response.data;

  let processResult: ListResult<GroupProject[]> = {
    content: [],
    totalElements: 0,
  };

  if (result.status === StatusCodes.OK) {
    processResult = {
      content: result.data.content,
      totalElements: result.data.totalElements,
    };

    processResult.content.forEach((project) => {
      project.thumbnail = `/static/images/slider0${Number(
        Math.floor(Math.random() * (4 - 1) + 1)
      )}.jpg`;
    });

    return processResult;
  }

  return processResult;
}

export async function fetchGroupAddableSearchProjects(groupSeq: number) {
  const response = await axios.get(
    `${Config.platform_api.uri}/group-manages/groups/attachable-projects`,
    {
      params: {
        groupSeq,
        usePaging: false,
      },
    }
  );
  const result: ApiResult = response.data;
  let addableProject: GroupProject[] = [];
  if (result.status === StatusCodes.OK) {
    addableProject = result.data as GroupProject[];
  }

  return addableProject;
}

export async function postGroupAddProjects(
  groupSeq: number,
  projectInfo: GroupAddProject[]
) {
  const response = await axios.post(
    `${Config.platform_api.uri}/group-manages/groups/${groupSeq}/projects`,
    {
      projectInfo,
    }
  );

  const result: ApiResult = response.data;
  if (result.status === StatusCodes.OK) {
    return true;
  }

  return false;
}

export async function deleteGroupDeleteProject(data: {
  groupSeq: number;
  seq: number;
  projectId: string;
}) {
  const response = await axios.delete(
    `${Config.platform_api.uri}/group-manages/groups/${data.groupSeq}/detach-project`,
    {
      data,
    }
  );

  const result: ApiResult = response.data;

  if (result.status === StatusCodes.OK) {
    return true;
  }

  return false;
}

export async function putGroupProjectRoleType(
  groupSeq: number,
  groupChangeRoleProject: GroupChangeRoleProject
) {
  const response = await axios.put(
    `${Config.platform_api.uri}/group-manages/groups/${groupSeq}/projects`,
    {
      projectInfo: [groupChangeRoleProject],
    }
  );
  const result: ApiResult = response.data;
  if (result.status === StatusCodes.OK) {
    for (const datum of result.data) {
      if (datum.result !== 'success') {
        return false;
      }
    }
    return true;
  }

  return false;
}
