import {
  Action,
  SET_UP_CHANGE_FLOAT_PANE_SHOW,
  SET_UP_CHANGE_INFO_PANE_SHOW,
  SET_UP_CHANGE_LAYER_POPUP_SHOW,
  SET_UP_CHANGE_LEFT_PANE_SHOW,
  SET_UP_CHANGE_LEFT_SIDE_BAR_EXPAND,
  SET_UP_CHANGE_PUSH_RECEIVE_FLAG,
  SET_UP_CHANGE_RIGHT_PANE_SHOW,
  SET_UP_SET_WINDOW_POPUP,
  SET_UP_INIT,
  SET_UP_MENU_ACTIVE,
  SET_UP_MENU_RESET_ACTIVE,
  SetupState,
} from '@/modules/setup/types';

export function setupReducer(state: SetupState, action: Action) {
  switch (action.type) {
    case SET_UP_CHANGE_LEFT_PANE_SHOW:
      return {
        ...state,
        pane: {
          ...state.pane,
          left: {
            ...state.pane.left,
            show: action.show,
          },
        },
      };
    case SET_UP_MENU_ACTIVE:
      return {
        ...state,
        menus: state.menus.map((menu) =>
          menu.menuIdx === action.menuIdx
            ? { ...menu, active: true }
            : { ...menu, active: false }
        ),
      };
    case SET_UP_MENU_RESET_ACTIVE:
      return {
        ...state,
        menus: state.menus.map((menu) => ({
          ...menu,
          active: false,
        })),
      };
    case SET_UP_INIT:
      return {
        ...state,
        pane: {
          ...state.pane,
          left: {
            ...state.pane.left,
            show: true,
          },
          right: {
            ...state.pane.right,
            show: false,
            view: undefined,
          },
        },
      };
    case SET_UP_CHANGE_FLOAT_PANE_SHOW:
      return {
        ...state,
        pane: {
          ...state.pane,
          float: {
            ...state.pane.float,
            show: action.show,
            view: typeof action.view !== 'undefined' ? action.view : undefined,
          },
        },
      };
    case SET_UP_CHANGE_RIGHT_PANE_SHOW:
      return {
        ...state,
        pane: {
          ...state.pane,
          right: {
            ...state.pane.right,
            show: action.show,
            view: action.view,
          },
        },
      };
    case SET_UP_CHANGE_LEFT_SIDE_BAR_EXPAND:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          left: {
            ...state.sidebar.left,
            expand: action.expand,
          },
        },
      };
    case SET_UP_CHANGE_LAYER_POPUP_SHOW:
      return {
        ...state,
        layerPopup: {
          ...state.layerPopup,
          show: action.show,
        },
      };
    case SET_UP_CHANGE_INFO_PANE_SHOW:
      return {
        ...state,
        pane: {
          ...state.pane,
          info: {
            ...state.pane.info,
            show: action.show,
            view: typeof action.view !== 'undefined' ? action.view : undefined,
          },
        },
      };
    case SET_UP_CHANGE_PUSH_RECEIVE_FLAG:
      return {
        ...state,
        pushReceiveFlag: !state.pushReceiveFlag,
      };
    case SET_UP_SET_WINDOW_POPUP:
      return {
        ...state,
        windowPopupList: [...state.windowPopupList, action.windowPopup],
      };
  }
}
