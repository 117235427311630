import {
  Action,
  HOME_CHANGE_BUILDING,
  HOME_CHANGE_FLOOR,
  HOME_CHANGE_PROJECT,
  HOME_DATA_CLEAR,
  HOME_SET_DATA_GROUP,
  HOME_SET_DATA_USER,
  HOME_SET_MENU_ACTIVE,
  HomeState,
} from '@/modules/home/types';

export function homeReducer(state: HomeState, action: Action) {
  switch (action.type) {
    case HOME_SET_MENU_ACTIVE:
      let menuIdx = -1;
      const activeMenu = state.menus.find((menu) => menu.active);
      if (activeMenu) {
        menuIdx = activeMenu.menuIdx;
      }

      if (menuIdx === -1) {
        state.menus.some((menu) => {
          const activeSubMenu = menu.subMenus.find((subMenu) => subMenu.active);
          if (activeSubMenu) {
            menuIdx = activeSubMenu.menuIdx;
            return true;
          }
        });
      }

      if (menuIdx !== action.menuIdx) {
        return {
          ...state,
          menus: state.menus.map((menu) => {
            let active = false;
            if (menu.menuIdx === action.menuIdx) {
              active = true;
            }

            menu.subMenus = menu.subMenus.map((subMenu) => {
              let active = false;
              if (subMenu.menuIdx === action.menuIdx) {
                active = true;
              }

              return {
                ...subMenu,
                active,
              };
            });

            return {
              ...menu,
              active,
            };
          }),
        };
      } else {
        return state;
      }
    case HOME_CHANGE_PROJECT:
      return {
        ...state,
        selected: {
          project: action.project,
          building: null,
          floor: null,
        },
      };
    case HOME_CHANGE_BUILDING:
      return {
        ...state,
        selected: {
          ...state.selected,
          building: action.building,
          floor: null,
        },
      };
    case HOME_CHANGE_FLOOR:
      return {
        ...state,
        selected: {
          ...state.selected,
          floor: action.floor,
        },
      };
    case HOME_SET_DATA_USER:
      return {
        ...state,
        data: {
          ...state.data,
          groupSettingUser: {
            userId: action.userId,
            directFlag: !state.data.groupSettingUser.directFlag,
          },
        },
      };
    case HOME_SET_DATA_GROUP:
      return {
        ...state,
        data: {
          ...state.data,
          groupSettingGroup: {
            groupSeq: action.groupSeq,
            directFlag: !state.data.groupSettingGroup.directFlag,
          },
        },
      };
    case HOME_DATA_CLEAR:
      return {
        ...state,
        data: {
          groupSettingUser: {
            ...state.data.groupSettingUser,
            userId: '',
          },
          groupSettingGroup: {
            ...state.data.groupSettingGroup,
            groupSeq: -1,
          },
        },
      };
  }
}
