import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubMenu } from '@/modules/home/types';
import classNames from 'classnames';
import { useHomeMenu } from '@/modules/home/hook';
import { MenuIdx } from '@/modules/setup/types';

type LeftExpandMenuProps = {
  titleKey: string;
  iconName: string;
  subMenuList: SubMenu[];
  onClick?: (clickMenuIdx: MenuIdx) => void;
};

function LeftExpandMenu({
  titleKey,
  iconName,
  subMenuList,
  onClick,
}: LeftExpandMenuProps) {
  const { t } = useTranslation();
  const { activeMenuIdx } = useHomeMenu();
  const [isOpen, setOpen] = useState(false);
  const [isActiveChildMenu, setActiveChildMenu] = useState(false);

  useEffect(() => {
    const isCurrentSubMenu = subMenuList.find(
      ({ menuIdx }) => activeMenuIdx === menuIdx
    );
    if (isCurrentSubMenu) {
      setOpen(true);
    } else {
      setActiveChildMenu(false);
      setOpen(false);
    }
  }, [activeMenuIdx]);

  return (
    <div
      className={classNames('nav__link collapse-item', {
        active: isActiveChildMenu,
      })}
      onClick={() => setOpen(!isOpen)}
    >
      <div className="popover-menu">
        <a className="dropdown-side-toggle">
          <span className="material-icons-outlined">{iconName}</span>
        </a>
        <div className="popover-container">
          <ul>
            {subMenuList.map(({ menuIdx, titleKey }) => (
              <li key={menuIdx}>
                <a
                  className=""
                  onClick={() => {
                    setActiveChildMenu(true);
                    onClick?.call(null, menuIdx);
                  }}
                >
                  {t(titleKey)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <a className="dropdown-side-toggle">
        <span className="nav_name">{t(titleKey)}</span>
      </a>
      <span
        className={classNames('material-icons collapse__link', {
          rotate: isOpen,
        })}
      >
        expand_more
      </span>
      <div
        className={classNames('collapse__menu', {
          showCollapse: isOpen,
        })}
      >
        {subMenuList.map(({ menuIdx, titleKey, active }) => (
          <a
            className={classNames('collapse__sublink', {
              active: active,
            })}
            key={menuIdx}
            onClick={() => {
              setActiveChildMenu(true);
              onClick?.call(null, menuIdx);
            }}
          >
            {t(titleKey)}
          </a>
        ))}
      </div>
    </div>
  );
}

export default LeftExpandMenu;
