import React from 'react';
import { useTranslation } from 'react-i18next';
import AccessInfo from '@/components/home/Message/Detail/Info/Access';
import AccidentInfo from '@/components/home/Message/Detail/Info/Accident';
import { MessageHistoryDetail } from '@/modules/message/types';

type HomeMessageDetailInfoProps = {
  messageDetail: MessageHistoryDetail;
};

function HomeMessageDetailInfo({ messageDetail }: HomeMessageDetailInfoProps) {
  const { t } = useTranslation();

  let isInOrOut = false;
  if (
    messageDetail.lidarEvent.eventType === 'IN' ||
    messageDetail.lidarEvent.eventType === 'OUT'
  ) {
    isInOrOut = true;
  }

  return (
    <>
      <h4>{t('기본 정보')}</h4>
      {isInOrOut ? (
        <AccessInfo messageDetail={messageDetail} />
      ) : (
        <AccidentInfo messageDetail={messageDetail} />
      )}
    </>
  );
}

export default HomeMessageDetailInfo;
