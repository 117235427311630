import React, { ReactElement } from 'react';
import { Map } from 'ol';
import ZoomControl from '@/components/common/ZoomControl';
import LocationControl from '@/components/common/LocationControl';
import ThreeDimensionControl from '@/components/common/ThreeDimensionControl';
import { useLayerPopup } from '@/modules/setup/hook';
import FloorPlanControl from '@/components/common/FloorPlanControl';
import RotateControl from '@/components/common/RotateControl';

type BottomToolbarProps = {
  map: Map;
  showRotateControl?: boolean;
  showFloorPlanControl?: boolean;
  showLocationControl?: boolean;
  showThreeDimensionControl?: boolean;
  onClickLocationControl?: () => void;
};

function BottomToolbar({
  map,
  showRotateControl = false,
  showFloorPlanControl = false,
  showLocationControl = false,
  showThreeDimensionControl = false,
  onClickLocationControl,
}: BottomToolbarProps) {
  const { handleChangeShow } = useLayerPopup();

  const handleClickThreeDimension = () => {
    handleChangeShow(true);
  };

  const handleClickZoomPlus = () => {
    map.getView().animate({
      zoom: (map?.getView().getZoom() || 0) + 1,
      duration: 350,
    });
  };

  const handleClickZoomMinus = () => {
    map.getView().animate({
      zoom: (map?.getView().getZoom() || 0) - 1,
      duration: 350,
    });
  };

  return (
    <>
      <div className="btn-widget-holder z-index-0">
        <ul>
          {showFloorPlanControl && (
            <li className="mb-3">
              <FloorPlanControl map={map} />
            </li>
          )}
          {showRotateControl && (
            <li className="mb-3">
              <RotateControl map={map} />
            </li>
          )}
          {showThreeDimensionControl && (
            <li className="mb-3">
              <ThreeDimensionControl
                onClickControl={handleClickThreeDimension}
              />
            </li>
          )}
          <li>
            <ZoomControl
              onClickPlus={handleClickZoomPlus}
              onClickMinus={handleClickZoomMinus}
            />
          </li>
          {showLocationControl && (
            <li className="mt-3">
              <LocationControl
                onClickControl={() => onClickLocationControl?.call(null)}
              />
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

export default BottomToolbar;
