import React, { useState } from 'react';
import { SignUpFormProps } from '@/components/SignUpForm';
import { useTranslation } from 'react-i18next';
import FormLabel from '@/components/common/FormLabel';
import classNames from 'classnames';
import { ValidUtils } from '@/utils';
import { createUser } from '@/modules/access/signup/reducer';
import { postUser } from '@/api/user';

type SignUpInfoProps = SignUpFormProps & {
  signUpEmail: string;
};

function SignUpInfo({ signUpEmail, onChangeStep }: SignUpInfoProps) {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState<{
    name: string;
    password: string;
    rePassword: string;
  }>({
    name: '',
    password: '',
    rePassword: '',
  });

  const [validInputs, setValidInputs] = useState<{
    name: boolean;
    password: boolean;
    rePassword: boolean;
  }>({
    name: true,
    password: true,
    rePassword: true,
  });

  const validForm = () => {
    let name = true;
    let password = true;
    let rePassword = true;

    if (!inputs.name) {
      name = false;
    }

    if (!inputs.password || !ValidUtils.validatePassword(inputs.password)) {
      password = false;
    }

    if (!inputs.rePassword || inputs.password !== inputs.rePassword) {
      rePassword = false;
    }

    setValidInputs({
      name,
      password,
      rePassword,
    });

    return name && password && rePassword;
  };

  const handleClickNext = async () => {
    const isValid = validForm();

    if (isValid) {
      const result = await postUser({
        userId: signUpEmail,
        userName: inputs.name,
        password: inputs.password,
      });

      if (result) {
        onChangeStep(4);
      }
    }
  };

  return (
    <>
      <div className="title-group mb-5">
        <h3 className="m-0">{t('간단한 정보 입력')}</h3>
      </div>
      <div className="form-group mb-4">
        <FormLabel textKey={'이메일'} htmlFor={'email'} essential={true} />
        <input
          id="email"
          type="email"
          className="form-line pr-6"
          placeholder={t('이메일을 입력하세요.')}
          defaultValue={signUpEmail}
          disabled={true}
        />
      </div>
      <div className="form-group mb-4">
        <FormLabel textKey={'이름'} htmlFor={'name'} essential={true} />
        <input
          id="name"
          type="text"
          className="form-line"
          placeholder={t('이름을 입력하세요.')}
          value={inputs.name}
          onChange={(e) => {
            setInputs({
              ...inputs,
              [e.target.id]: e.target.value,
            });
          }}
          autoComplete={'off'}
        />
        {!validInputs.name && (
          <div className="invalid-feedback">{t('필수입력 항목입니다.')}</div>
        )}
      </div>
      <div className="form-group mb-4">
        <FormLabel textKey={'비밀번호'} htmlFor={'password'} essential={true} />
        <input
          id="password"
          type="password"
          className="form-line"
          placeholder={t('비밀번호를 입력하세요.')}
          value={inputs.password}
          onChange={(e) => {
            setInputs({
              ...inputs,
              [e.target.id]: e.target.value,
            });
          }}
        />
        {!validInputs.password && (
          <div className="invalid-feedback">
            {t('8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.')}
          </div>
        )}
      </div>
      <div className="form-group mb-5">
        <FormLabel
          textKey={'비밀번호 확인'}
          htmlFor={'rePassword'}
          essential={true}
        />
        <input
          id="rePassword"
          type="password"
          className="form-line"
          placeholder={t('비밀번호를 입력하세요.')}
          value={inputs.rePassword}
          onChange={(e) => {
            setInputs({
              ...inputs,
              [e.target.id]: e.target.value,
            });
          }}
        />
        {!validInputs.rePassword && (
          <div className="invalid-feedback">
            {t('비밀번호가 일치하지 않습니다.')}
          </div>
        )}
      </div>
      <div className="form-group text-center mb-32pt">
        <button
          className="btn btn-block btn-lg btn-accent"
          type="button"
          onClick={handleClickNext}
        >
          {t('다음')}
        </button>
      </div>
    </>
  );
}

export default SignUpInfo;
