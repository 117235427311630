import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

type LayoutColumnProps = {
  show?: boolean;
  align: 'left' | 'mid' | 'right' | 'float' | 'bottom';
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
};

function LayoutColumn({
  show = true,
  align,
  className,
  style,
  children,
}: LayoutColumnProps) {
  return (
    <div
      className={classNames(`pl-col-${align}`, className, {
        'pl-collapsed': !show,
      })}
      style={style}
    >
      {children}
    </div>
  );
}

export default LayoutColumn;
