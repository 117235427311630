import { Fill, Stroke, Style } from 'ol/style';
import GeoImageLayer from 'ol-ext/layer/GeoImage';
import GeoImageSource from 'ol-ext/source/GeoImage';
import Transform from 'ol-ext/interaction/Transform';
import { Vector as SourceVector } from 'ol/source';
import { Vector as LayerVector } from 'ol/layer';
import { boundingExtent, getCenter } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';

export function getGeoImageLayer(opacity = 1, zIndex = 300) {
  return new GeoImageLayer({
    opacity,
    zIndex,
  });
}

export function getGeoImageSource(options) {
  return new GeoImageSource(options);
}

export function getInteractionTransform() {
  const layer = new LayerVector({
    source: new SourceVector(),
    style: new Style({
      stroke: new Stroke({
        color: 'rgb(255, 174, 0)',
        width: 3,
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0)',
      }),
    }),
  });

  const transform = new Transform({
    enableRotatedTransform: true,
    translateFeature: true,
    noFlip: true,
    scale: true,
    rotate: true,
    translate: true,
    stretch: true,
    layers: [layer],
  });

  return [layer, transform];
}

export function calcGeoImage(geoImageSource) {
  const t = geoImageSource.getSource(),
    o = t.getCenter(),
    a = t.getScale(),
    n = t.getGeoImage().width * a[0],
    r = t.getGeoImage().height * a[1],
    i = [o[0] - n / 2, o[1] - r / 2],
    g = [o[0] + n / 2, o[1] + r / 2],
    l = boundingExtent([i, g]),
    c = fromExtent(l);
  return c.rotate(-t.getRotation(), o), c;
}

export function transformFromPolygon(e, t) {
  const o = t.getGeometry(),
    a = o.getExtent(),
    r = getCenter(a),
    g = o.getCoordinates(),
    l = ((e, t) => {
      let o = 0;
      o =
        e[1] == t[1]
          ? t[0] > e[0]
            ? Math.PI / 2
            : -Math.PI / 2
          : Math.atan2(t[0] - e[0], t[1] - e[1]);
      o < 0 && (o += 2 * Math.PI);
      return o;
    })(g[0][0], g[0][1]),
    c = calcDistance(g[0][0], g[0][1]),
    s = calcDistance(g[0][1], g[0][2]),
    u = e.getSource();
  u.setCenter(r), u.setRotation(l);
  const m = [s / u.getGeoImage().width, c / u.getGeoImage().height];
  u.setScale(m), t.setGeometry(calcGeoImage(e));
}

function calcDistance(e, t) {
  try {
    return Math.abs(
      Math.sqrt(Math.pow(t[0] - e[0], 2) + Math.pow(t[1] - e[1], 2))
    );
  } catch (e) {}
  return 0;
}
