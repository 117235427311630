import React from 'react';

type ThreeDimensionControlProps = {
  onClickControl: () => void;
};

function ThreeDimensionControl({ onClickControl }: ThreeDimensionControlProps) {
  return (
    <a className="widget-btn btn_layerpopup" onClick={onClickControl}>
      3D
    </a>
  );
}

export default ThreeDimensionControl;
