import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Preloader from './Preloader';

type UploadProgressStatusIndicatorProps = {
  progressStatus: number;
};

function UploadProgressStatusIndicator({
  progressStatus,
}: UploadProgressStatusIndicatorProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="form-line">
      <div className="att-file">
        <p className="file-name text-40">
          {t('파일 업로드 중')} ({progressStatus}%)
        </p>
        <div className="bundle">
          <Preloader width="20px" height="20px" deletePadding />
        </div>
      </div>
    </div>
  );
}

export default UploadProgressStatusIndicator;
