import { UserType } from '@/modules/user/types';
import { MenuIdx } from '@/modules/setup/types';
import {
  ProjectDetail,
  ProjectSpace,
  ProjectSpaceFloor,
} from '@/modules/project/types';

export type SubMenu = {
  menuIdx: MenuIdx;
  titleKey: string;
  active: boolean;
  allowableUserType: UserType[];
};

export type Menu = {
  menuIdx: MenuIdx;
  iconName: string;
  titleKey: string;
  active: boolean;
  display: boolean;
  subMenus: SubMenu[];
  allowableUserType: UserType[];
  needProject: boolean;
  showLeftPane: boolean;
  inside: boolean;
  outer: boolean;
};

export type GroupSettingUser = {
  userId: string;
  directFlag: boolean;
};

export type GroupSettingGroup = {
  groupSeq: number;
  directFlag: boolean;
};

export type HomeState = {
  menus: Menu[];
  selected: {
    project: ProjectDetail | null;
    building: ProjectSpace | null;
    floor: ProjectSpaceFloor | null;
  };
  data: {
    groupSettingUser: GroupSettingUser;
    groupSettingGroup: GroupSettingGroup;
  };
};

export const HOME_SET_MENU_ACTIVE = 'HOME/SET_MENU_ACTIVE' as const;
export const HOME_CHANGE_PROJECT = 'HOME/CHANGE_PROJECT' as const;
export const HOME_CHANGE_BUILDING = 'HOME/CHANGE_BUILDING' as const;
export const HOME_CHANGE_FLOOR = 'HOME/CHANGE_FLOOR' as const;
export const HOME_SET_DATA_USER = 'HOME/SET_DATA_USER' as const;
export const HOME_SET_DATA_GROUP = 'HOME/SET_DATA_GROUP' as const;
export const HOME_DATA_CLEAR = 'HOME/DATA_CLEAR' as const;

export type Action =
  | {
      type: typeof HOME_SET_MENU_ACTIVE;
      menuIdx: MenuIdx;
    }
  | {
      type: typeof HOME_CHANGE_PROJECT;
      project: ProjectDetail | null;
    }
  | {
      type: typeof HOME_CHANGE_BUILDING;
      building: ProjectSpace | null;
    }
  | {
      type: typeof HOME_CHANGE_FLOOR;
      floor: ProjectSpaceFloor | null;
    }
  | {
      type: typeof HOME_SET_DATA_USER;
      userId: string;
    }
  | {
      type: typeof HOME_SET_DATA_GROUP;
      groupSeq: number;
    }
  | {
      type: typeof HOME_DATA_CLEAR;
    };
