import React from 'react';
import { ProjectSpaceFloor } from '@/modules/project/types';
import classNames from 'classnames';
import { CommonUtils } from '@/utils';
import { Config } from '@/config';
import { useTranslation } from 'react-i18next';

import { useProjectRoute } from '@/modules/project/hook';
import EmptyData from '@/components/common/EmptyData';
import {
  useHomeBuilding,
  useHomeFloor,
  useHomeProject,
} from '@/modules/home/hook';
import MapEditButton from '@/components/common/MapEditButton';
import GeofenceEditButton from '@/components/common/GeofenceEditButton';
import { floor } from 'lodash';

function HomeFloorSelect() {
  const { t } = useTranslation();
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();
  const { handleChangeFloor } = useHomeFloor();
  const { handleRouteProject } = useProjectRoute();

  return (
    <>
      <div className="col-list">
        <div className="plf-floor-card">
          <div className="plf-area-title">
            <div className="inner">
              <h3 className="mb-2">{building?.metaName}</h3>
              <p className="text-64 m-0">{project?.note}</p>
            </div>
            <a
              className="btn btn-accent py-3 w-10 ml-auto"
              onClick={() => {
                project && handleRouteProject(project.projectId, true);
              }}
            >
              {t('수정')}
            </a>
          </div>
          {building?.floors.map((floor) => (
            <HomeFloorItem
              key={floor.mapId}
              floor={floor}
              onClickItem={() => handleChangeFloor(floor)}
            />
          ))}
        </div>
      </div>
      {!building && (
        <EmptyData
          fontAwesomeIcon={['far', 'building']}
          textKey={'건물을 선택해 주세요.'}
        />
      )}
      {building && building.floors.length === 0 && (
        <EmptyData
          fontAwesomeIcon={['far', 'map']}
          textKey={'등록된 평면도가 없습니다.'}
        />
      )}
    </>
  );
}

type HomeFloorItemProps = {
  floor: ProjectSpaceFloor;
  onClickItem: () => void;
};

function HomeFloorItem({
  floor: { mapId, mapName, mapFloor, filename },
  onClickItem,
}: HomeFloorItemProps) {
  const { t } = useTranslation();
  const { project } = useHomeProject();
  const { building } = useHomeBuilding();

  return (
    <div className="plf-area-card" onClick={onClickItem}>
      <div className="plf-area-top">
        <span
          className={classNames('material-icons-outlined mr-1', {
            'text-danger': filename,
          })}
        >
          upload_file
        </span>
        <h4>{mapName}</h4>
        <div className="left-cell">
          <span className="floor-num mr-3">
            {CommonUtils.convertFloorFormat(mapFloor)}
          </span>
          {project && building && (
            <>
              <GeofenceEditButton
                projectName={project.projectName}
                buildingName={building.metaName}
                floorName={mapName}
                floor={mapFloor}
                mapId={mapId}
              />
              <MapEditButton
                projectId={project.projectId}
                metaId={building.metaId}
                mapId={mapId}
              />
            </>
          )}
        </div>
      </div>
      <div className="plf-area-content">
        <ul className="plf-floor-card-info">
          <li>
            <a>
              <div className="thumb">
                {filename && (
                  <img src={`${Config.space_api.uri}/bprint/${filename}`} />
                )}
                {!filename && (
                  <span className="material-icons-outlined text-16">
                    upload_file
                  </span>
                )}
              </div>
            </a>
          </li>
          <li>
            <span className="icon material-icons-outlined">group</span>
            <span className="text-64 font-size-14pt">{t('고객')}</span>
            <em>0</em>
          </li>
          <li>
            <span className="icon material-icons-outlined">category</span>
            <span className="text-64 font-size-14pt">{t('자산')}</span>
            <em>0</em>
          </li>
          <li>
            <span className="icon material-icons-outlined">notifications</span>
            <span className="text-64 font-size-14pt">{t('알림')}</span>
            <em>0</em>
          </li>
          <li>
            <span className="icon material-icons-outlined">warning_amber</span>
            <span className="text-64 font-size-14pt">{t('사고')}</span>
            <em>0</em>
          </li>
          <li>
            <span className="icon material-icons-outlined">redeem</span>
            <span className="text-64 font-size-14pt">{t('캠페인')}</span>
            <em>0</em>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default HomeFloorSelect;
