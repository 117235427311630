import { AsyncState } from '@/modules/common';

export type Logout = {
  userId: string;
};

export type LogoutState = AsyncState<number>;

export const ACCESS_LOGOUT = 'ACCESS/LOGOUT' as const;
export const ACCESS_LOGOUT_SUCCESS = 'ACCESS/LOGOUT_SUCCESS' as const;
export const ACCESS_LOGOUT_ERROR = 'ACCESS/LOGOUT_ERROR' as const;

export type Action =
  | {
      type: typeof ACCESS_LOGOUT;
    }
  | {
      type: typeof ACCESS_LOGOUT_SUCCESS;
      data: number;
    }
  | {
      type: typeof ACCESS_LOGOUT_ERROR;
      error: Error | null;
    };
