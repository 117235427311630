import React, { useEffect, useRef, useState } from 'react';
import {
  GEO_IMAGE_DEFAULT_OPACITY,
  PANE_STATUS_LIST,
  PaneStatus,
} from '@/utils/constants/common';
import MaterialIcon from '@/components/common/MaterialIcon';
import { useTranslation } from 'react-i18next';
import { ConnectedSpaceInfo } from '@/modules/space/types';
import FormGroup from '@/components/common/FormGroup';
import FormLabel from '@/components/common/FormLabel';
import { fetchImage, postFile } from '@/api/common';
import { useOpenLayers, useProjectRegister } from '@/modules/project/hook';
import ManageFooter from '@/components/common/ManageFooter';
import InvalidAlert from '@/components/InvalidAlert';
import { postFloorFile, postUpdateFloor, ResponseFloorInfo } from '@/api/space';
import { useUser } from '@/modules/user/hook';
import AlertModal from '@/components/common/modal/AlertModal';
import { Config } from '@/config';
import {
  PROJECT_ONBOARDING_CATEGORY_FLOOR_PLAN_IMAGE,
  ProjectOnboarding,
} from '@/modules/project/types';
import { putProjectStepData } from '@/api/project';
import { CommonUtils } from '@/utils';
import UploadProgressStatusIndicator from '../common/UploadProgressStatusIndicator';

type ProjectFloorPlanEditProps = {
  spaceInfo: ConnectedSpaceInfo | null;
  floorInfo: ResponseFloorInfo | null;
  onChangeStatus: (status: PaneStatus) => void;
};

function ProjectFloorPlanEdit({
  spaceInfo,
  floorInfo,
  onChangeStatus,
}: ProjectFloorPlanEditProps) {
  const { t } = useTranslation();
  const { user } = useUser();
  const { projectInfo, handleSetOnboarding } = useProjectRegister();
  const {
    map,
    geoImage,
    transform,
    handleChangeGeoImageAdjustment,
  } = useOpenLayers();

  const [inputs, setInputs] = useState<{
    floorPlanName: string;
    floor: number;
  }>({
    floorPlanName: '',
    floor: 1,
  });
  const [file, setFile] = useState<File | null>(null);
  const [orgImg, setOrgImg] = useState<{
    fileName: string;
    oriFileName: string;
  }>({
    fileName: '',
    oriFileName: '',
  });
  const [invalidMessage, setInvalidMessage] = useState('');
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [progressStatus, setProgressStatus] = useState<number>(0);

  useEffect(() => {
    return () => handleInitialGeoImage();
  }, []);

  useEffect(() => {
    const view = map?.getView();
    if (spaceInfo && view) {
      view.setCenter([spaceInfo.lng, spaceInfo.lat]);
      view.setZoom(13);
    }
  }, [spaceInfo]);

  useEffect(() => {
    if (floorInfo) {
      const {
        map_name,
        map_floor,
        filename,
        orifilename,
        cx,
        cy,
        scalex,
        scaley,
        rotation,
        opacity,
      } = floorInfo;
      setInputs({
        floorPlanName: map_name,
        floor: map_floor,
      });
      if (filename && orifilename) {
        setOrgImg({
          fileName: filename,
          oriFileName: orifilename,
        });
        handleDrawGeoImage(
          `${Config.space_api.uri}/bprint/${filename}`,
          [Number(cx), Number(cy)],
          [Number(scalex), Number(scaley)],
          Number(rotation),
          Number(opacity),
          false
        );
      }
    }
  }, [floorInfo]);

  const handleInitialGeoImage = () => {
    handleChangeGeoImageAdjustment(undefined);
    geoImage.layer.setOpacity(GEO_IMAGE_DEFAULT_OPACITY);
    geoImage.remove();
  };

  const handlePostImage = async (file: File) => {
    const imgId = await postFile(
      file,
      CommonUtils.uploadProgressConfig(setProgressStatus)
    );
    if (imgId) {
      setFile(file);
      const imgUrl = await fetchImage(imgId);

      handleDrawGeoImage(
        imgUrl,
        [spaceInfo?.lng || 0, spaceInfo?.lat || 0],
        [1, 1],
        0,
        GEO_IMAGE_DEFAULT_OPACITY,
        true
      );
    }
  };

  const handleClickAdjustment = () => {
    handleChangeGeoImageAdjustment(true);
    transform.draw?.call(null);
  };

  const handleCancelImage = () => {
    if (orgImg.oriFileName) {
      setOrgImg({
        fileName: '',
        oriFileName: '',
      });
    } else {
      setFile(null);
    }
    handleInitialGeoImage();
    setProgressStatus(0);
  };

  const handleDrawGeoImage = (
    url: string,
    center: [number, number],
    scale: [number, number],
    rotate: number,
    opacity: number,
    drawTransform: boolean
  ) => {
    geoImage.draw(
      {
        imageCenter: center,
        imageScale: scale,
        imageRotate: rotate,
        opacity,
        url,
      },
      () => {
        map?.getView().fit(geoImage.layer.getExtent());
        if (drawTransform) {
          transform.draw?.call(null);
        }
        handleChangeGeoImageAdjustment(drawTransform);
      }
    );
  };

  const handleClickCancel = () => {
    onChangeStatus(PANE_STATUS_LIST);
  };

  const handleClickSubmit = async () => {
    setShowInvalidMessage(false);
    const { floorPlanName, floor } = inputs;
    let message = '';
    if (!floorPlanName) {
      message = t('평면도명을 입력해 주세요.');
    } else if (
      spaceInfo?.floorList.find(
        ({ floorId, floorValue }) =>
          floorId !== floorInfo?.map_id && floorValue === floor
      )
    ) {
      message = t('이미 등록된 평면도가 있습니다.');
    }

    if (message) {
      setInvalidMessage(message);
      setShowInvalidMessage(true);
      return;
    }

    let isComplete = false;
    const spaceId = spaceInfo?.spaceId;
    if (spaceId && floorInfo) {
      const layer = geoImage.layer;
      const source = layer.getSource();
      let center: number[] = [spaceInfo?.lng || 0, spaceInfo?.lat || 0];
      let rotation = 0;
      let scale = [1, 1];
      let opacity = 1;
      if (source) {
        center = source.getCenter();
        rotation = source.getRotation();
        scale = source.getScale();
        opacity = layer.getOpacity();
      }
      const { map_id } = floorInfo;
      const { fileName, oriFileName } = orgImg;
      if (!file) {
        isComplete = await postUpdateFloor({
          metaid: spaceId,
          floor: floor,
          mapid: map_id,
          mapname: floorPlanName,
          userid: user.userId,
          cx: center[0],
          cy: center[1],
          imgRotate: rotation,
          imgScalex: scale[0],
          imgScaley: scale[1],
          opacity,
          filename: fileName,
          originalname: oriFileName,
        });
      } else {
        const result = await postFloorFile({
          metaid: spaceId,
          floor: floor,
          mapid: map_id,
          mapname: floorPlanName,
          userid: user.userId,
          file,
          cx: center[0],
          cy: center[1],
          imgRotate: rotation,
          imgScalex: scale[0],
          imgScaley: scale[1],
          opacity,
          div: 'update',
        });

        if (result) {
          const projectId = projectInfo.projectId;
          if (
            projectId &&
            projectInfo.onboardingData &&
            !projectInfo.onboardingData.includes(
              PROJECT_ONBOARDING_CATEGORY_FLOOR_PLAN_IMAGE
            )
          ) {
            const updateOnboarding: ProjectOnboarding = [
              ...projectInfo.onboardingData,
              PROJECT_ONBOARDING_CATEGORY_FLOOR_PLAN_IMAGE,
            ];

            const putStepDataResult = await putProjectStepData(projectId, {
              onboardingData: JSON.stringify(updateOnboarding),
            });

            if (putStepDataResult) {
              handleSetOnboarding(updateOnboarding);
              isComplete = true;
            }
          } else {
            isComplete = true;
          }
        }
      }
    }

    if (isComplete) {
      onChangeStatus(PANE_STATUS_LIST);
    } else {
      setAlertMessage(t('서버와 통신 중 오류가 발생했습니다'));
      setShowAlertModal(true);
    }
  };

  return (
    <>
      <div className="container-fluid border-bottom-1 mb-4 py-4">
        <div className="d-flex align-items-center">
          <a className="circle-pin pr-2" onClick={handleClickCancel}>
            <MaterialIcon name={'arrow_back'} />
          </a>
          <h3 className="mb-0">{t('평면도 수정')}</h3>
        </div>
      </div>
      <div className="container-fluid">
        <FormGroup>
          <label className="form-label">
            <label className="form-label">{t('평면도 등록 건물')}</label>
          </label>
          <input
            type="text"
            className="form-line"
            placeholder={spaceInfo?.spaceName}
            disabled={true}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel
            essential={true}
            fontSizeApply={false}
            textKey={'평면도명'}
          />
          <input
            type="text"
            className="form-line"
            placeholder={t('평면도명을 입력해 주세요.')}
            value={inputs.floorPlanName}
            onChange={(e) =>
              setInputs({
                ...inputs,
                floorPlanName: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <FormLabel essential={true} fontSizeApply={false} textKey={'층'} />
          <input
            type="number"
            className="form-line"
            value={inputs.floor}
            onKeyDown={(e) => {
              const key = e.key;
              if (key === '.' || key === '0') {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let value = e.target.value;
              if (value.match(/\./)) {
                value = value.replace(/./g, '');
              }
              let number = 1;
              if (value) {
                number = Number(value);
                if (!number) {
                  if (inputs.floor > 0) {
                    number = -1;
                  } else {
                    number = 1;
                  }
                }
              }

              setInputs({
                ...inputs,
                floor: number,
              });
            }}
          />
        </FormGroup>
        <FormGroup className={'over-txt'}>
          <FormLabel textKey={'평면도 이미지'} />
          {!orgImg.oriFileName && (
            <>
              {!file && (
                <>
                  {progressStatus ? (
                    <UploadProgressStatusIndicator
                      progressStatus={progressStatus}
                    />
                  ) : (
                    <>
                      <input
                        type="text"
                        className="form-line"
                        disabled={true}
                      />
                      <div className="btn-abs btn-file-att">
                        <label
                          className="btn-light btn-sm m-0"
                          htmlFor="thumbnailFile"
                        >
                          {t('파일 선택')}
                        </label>
                        <input
                          type="file"
                          id="thumbnailFile"
                          accept={'image/*'}
                          onChange={(e) => {
                            if (e.target.files && e.target.files.length) {
                              handlePostImage(e.target.files[0]);
                            } else {
                              handleCancelImage();
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {file && (
                <>
                  <div className="form-line">
                    <div className="att-file">
                      <p className="file-name">{file.name}</p>
                      <div className="bundle">
                        {typeof geoImage.adjustment !== 'undefined' &&
                          geoImage.adjustment === false && (
                            <a
                              className="btn-light btn-sm"
                              onClick={handleClickAdjustment}
                            >
                              {t('조정')}
                            </a>
                          )}
                        <a onClick={handleCancelImage}>
                          <span className="material-icons ml-2">close</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {orgImg.oriFileName && (
            <>
              <div className="form-line">
                <div className="att-file">
                  <p className="file-name">{orgImg.oriFileName}</p>
                  <div className="bundle">
                    {typeof geoImage.adjustment !== 'undefined' &&
                      geoImage.adjustment === false && (
                        <a
                          className="btn-light btn-sm"
                          onClick={handleClickAdjustment}
                        >
                          {t('조정')}
                        </a>
                      )}
                    <a onClick={handleCancelImage}>
                      <span className="material-icons ml-2">close</span>
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
        </FormGroup>
      </div>
      {showInvalidMessage && <InvalidAlert messageKey={invalidMessage} />}
      <ManageFooter
        type={'edit'}
        onClickCancel={handleClickCancel}
        onClickConfirm={handleClickSubmit}
      />
      <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
        {alertMessage}
      </AlertModal>
    </>
  );
}

export default ProjectFloorPlanEdit;
