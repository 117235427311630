import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@/modules/user/hook';
import { fetchAllProjects } from '@/api/project';
import { Projects } from '@/modules/project/types';
import { useProjectEdit, useProjectRoute } from '@/modules/project/hook';
import { useHomeProject } from '@/modules/home/hook';

function HomeProjectSelect() {
  const { user } = useUser();
  const { handleRouteProject } = useProjectRoute();
  const { project } = useHomeProject();
  const { reloadFlag } = useProjectEdit();
  const [projectList, setProjectList] = useState<Projects>([]);
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (user.userId || typeof reloadFlag !== 'undefined') {
      handleFetchProjectList();
    }
  }, [user.userId, reloadFlag]);

  const handleFetchProjectList = async () => {
    const data = await fetchAllProjects();
    if (!mountedRef.current) {
      return;
    }
    setProjectList(data);
  };

  const handleChangeProject = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value) {
      const project = projectList.find(({ projectId }) => projectId === value);
      project && handleRouteProject(value, !project.produceEndFlag);
    } else {
      handleRouteProject('');
    }
  };

  return (
    <select
      className="form-line"
      value={project?.projectId || ''}
      onChange={handleChangeProject}
    >
      {projectList.map(({ projectId, projectName }) => (
        <option key={projectId} value={projectId}>
          {projectName}
        </option>
      ))}
    </select>
  );
}

export default HomeProjectSelect;
