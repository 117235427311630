import React, { useEffect, useState } from 'react';
import GroupManagementDeleteModal from '@/components/GroupManagementDeleteModal';
import { Group } from '@/modules/group/types';
import GroupManagementDetail from '@/components/GroupManagementDetail';
import GroupManagementList from '@/components/GroupManagementList';
import { useHomeData } from '@/modules/home/hook';

export type GroupManagementView = 'list' | 'detail';

function GroupManagement() {
  const [showView, setShowView] = useState<GroupManagementView>('list');
  const [showGroupDeleteModal, setShowGroupDeleteModal] = useState(false);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [group, setGroup] = useState<Group | null>(null);
  const [groupSeq, setGroupSeq] = useState(-1);

  const { groupSettingGroup, handleDataClear } = useHomeData();

  useEffect(() => {
    if (groupSettingGroup.groupSeq > -1) {
      setGroupSeq(groupSettingGroup.groupSeq);
      handleDataClear();
      setShowView('detail');
    }
  }, [groupSettingGroup.directFlag]);

  const handleClickGroupList = (data: number) => {
    setGroupSeq(data);
    setShowView('detail');
  };

  const handleShowDeleteModal = (data: Group) => {
    setGroup(data);
    setShowGroupDeleteModal(true);
  };

  const handleReload = () => {
    setReloadFlag(!reloadFlag);
  };

  return (
    <>
      <div className="contents-section col-md-16">
        <div className="row align-items-start">
          {showView === 'list' && (
            <GroupManagementList
              reloadFlag={reloadFlag}
              onClickItem={handleClickGroupList}
              onClickDelete={handleShowDeleteModal}
            />
          )}
          {showView === 'detail' && (
            <GroupManagementDetail
              groupSeq={groupSeq}
              reloadFlag={reloadFlag}
              onClickBack={setShowView}
              onClickDelete={handleShowDeleteModal}
            />
          )}
        </div>
      </div>
      <GroupManagementDeleteModal
        group={group}
        show={showGroupDeleteModal}
        onHide={() => setShowGroupDeleteModal(false)}
        onReload={handleReload}
      />
    </>
  );
}

export default GroupManagement;
